import { createSlice } from "@reduxjs/toolkit";
import { getMaintenanceHour, getMaintenanceHourbyID, saveRunningHour } from "./THUNK/runninHourPmsThunk"
import { toast } from "react-toastify";
const ValidateResponse = (arg) => {
    if (arg.data.result.status) {
        return arg.data.result.data
    } else {
        toast.error(arg.data.result.message)
        return []
    }
}
const AddEditResponse = (arg) => {
    if (arg.data.result.status) {
        toast.success(arg.data.result.message)
        return arg.data.result.data
    } else {
        toast.error(arg.data.result.message)
        return []
    }
}
const initialState = {
    running_hour: [],
    running_hour_detail: {},
    running_total: [],
    running_month: []
}
const runninHourPmsSlice = createSlice({
    name: 'RUNNING',
    initialState,
    reducers: {
        clearHourDetail:(state,action)=>{
            state.running_hour_detail={}
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getMaintenanceHour.fulfilled, (state, action) => {
                state.running_hour = ValidateResponse(action.payload)
            })
            .addCase(getMaintenanceHourbyID.fulfilled, (state, action) => {
                let response = ValidateResponse(action.payload)
                state.running_hour_detail = response[0]
            })
            .addCase(saveRunningHour.fulfilled, (state, action) => {
                AddEditResponse(action.payload)
            })
    }
})
export const {clearHourDetail}=runninHourPmsSlice.actions
export default runninHourPmsSlice.reducer