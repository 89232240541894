import { createSlice } from "@reduxjs/toolkit";
import { AddEditResponse, ValidateResponse } from "../Services/Support";
import { getWOList, getWObyId, saveWO } from "./THUNK/workOrderThunk";
import { ConvertJson } from "../helper";
const initialState = {
    list: [],
    orderDetails:{}
}
const maintenanceReportSlice = createSlice({
    name: 'WO',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getWOList.fulfilled, (state, action) => {
                state.list = ValidateResponse(action.payload)
            })
            .addCase(saveWO.fulfilled, (state, action) => {
                AddEditResponse(action.payload)
            })
            .addCase(getWObyId.fulfilled, (state, action) => {
                let response = ValidateResponse(action.payload)
                let obj = response[0]
                obj.repository = obj.repository !== null ? ConvertJson(obj.repository) : []
                obj.PODetail = obj.PODetail !== null ? ConvertJson(obj.PODetail) : []
                obj.maintenanceDetail = obj.maintenanceDetail !== null ? ConvertJson(obj.maintenanceDetail) : []
                state.orderDetails = obj
            })
    }
})
//export const { } = maintenanceReportSlice.actions
export default maintenanceReportSlice.reducer