import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { toast } from "react-toastify"
import { API_SERVICE } from "../Services/CommonApi"
import data from '../components/table/data.json'
import { dateTimeToDate } from "../helper/dateUtil";
import {
    GET_COMPANY_DETAILS, GET_NOTIFICATION,
    GET_USER,
    SAVE_COMPANY_DETAILS,
    SAVE_NOTIFICATION,
    SAVE_USER, SETTINGS
} from "../constant/CommonApiUrl";
import { setTypeFieldsIsUpdated } from "./typeFieldsSlice";
import { ConvertJson } from "../helper";

const ADD_USER = 'user/addUserApi'
const UPDATE_USER = 'user/updateUserApi'
const FETCH_USER = 'user/fetchUserApi'
const FETCH_USER_BY_ID = 'user/fetchUserApiById'
const DELETE_USER = 'user/deleteUserApi'
const COMPANY_LIST = 'user/companyList'
const APPROVAL_LEVEL = 'user/approvalLevel'
const USER_APPROVAL = 'user/USER_approval'
const NOTIFICATION_ACTION = 'user/NOTIFICATION_ACTION'
const SAVE_COMPANY = 'user/SAVE_COMPANY'
const SAVE_APPROVAL = 'user/SAVE_APPROVAL'
const SAVE_NOTIFICATION_ACTION = 'user/SAVE_NOTIFICATION'

export const addUserApi = createAsyncThunk(ADD_USER,
    async (args, thunkAPI) => {
        try {
            // Convert role id to a API understandable format - requires the values as [{id: value1}, {id: value2}]
            let mutablePayload = { ...args['data'] };
            mutablePayload.roleid = mutablePayload.roleid.map(role => ({ id: role }));
            mutablePayload.vesselid = mutablePayload.vesselid.map(v => ({ id: v }));

            // Make the post request
            let response = await API_SERVICE.post(SAVE_USER, mutablePayload);
            thunkAPI.dispatch(setTypeFieldsIsUpdated(true));
            thunkAPI.dispatch(fetchUserApi());
            return response;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.message)
        }
    }
)

export const updateUserApi = createAsyncThunk(UPDATE_USER,
    async (args, thunkAPI) => {
        try {
            // Convert role id to a API understandable format - requires the values as [{id: value1}, {id: value2}]
            let mutablePayload = { ...args['data'] };
            mutablePayload.roleid = mutablePayload.roleid.map(role => ({ id: role }));
            mutablePayload.vesselid = mutablePayload.vesselid.map(v => ({ id: v }));
            // Make the post request
            let response = await API_SERVICE.post(SAVE_USER, mutablePayload);
            thunkAPI.dispatch(setTypeFieldsIsUpdated(true));
            thunkAPI.dispatch(fetchUserApi());
            return response
        } catch (err) {
            return thunkAPI.rejectWithValue(err.message)
        }
    })

export const fetchUserApi = createAsyncThunk(FETCH_USER, async (arg, thunkAPI) => {
    try {
        let response = await API_SERVICE.get(GET_USER, { ...arg });
        response = response.data.result.data;
        response = response.map(usr => ({
            ...usr,
            vessel_id: usr?.vessel_id ? ConvertJson(usr.vessel_id) : [],
            roleid: usr.roleid ? JSON.parse(usr.roleid).map(roleObj => Number(roleObj.id)) : '',
            vesselid: usr.vessel_id ? JSON.parse(usr.vessel_id).map(roleObj => Number(roleObj.id)) : '',
            vessel_name: usr.vessel_id ? JSON.parse(usr.vessel_id).map(roleObj => roleObj?.vessel_name) : '',
            role_name: usr.roleid ? JSON.parse(usr.roleid).map(roleObj => roleObj.role_name) : '',
            dob: dateTimeToDate(usr.dob)
        }));
        return response;
    } catch (err) {
        return thunkAPI.rejectWithValue(err)
    }
})
export const fetchUserById = createAsyncThunk(FETCH_USER_BY_ID, async (arg, thunkAPI) => {
    try {
        let response = await API_SERVICE.get(GET_USER, { ...arg });
        response = response.data.result.data;
        response = response.map(usr => ({
            ...usr,
            vessel_id: usr?.vessel_id ? ConvertJson(usr.vessel_id) : [],
            roleid: usr.roleid ? JSON.parse(usr.roleid).map(roleObj => Number(roleObj.id)) : '',
            vesselid: usr.vessel_id ? JSON.parse(usr.vessel_id).map(roleObj => Number(roleObj.id)) : '',
            vessel_name: usr.vessel_id ? JSON.parse(usr.vessel_id).map(roleObj => Number(roleObj.vessel_name)) : '',
            role_name: usr.roleid ? JSON.parse(usr.roleid).map(roleObj => roleObj.role_name) : '',
            dob: dateTimeToDate(usr.dob)
        }));
        return response;
    } catch (err) {
        return thunkAPI.rejectWithValue(err)
    }
})

export const deleteUserApi = createAsyncThunk(DELETE_USER,
    async (args, thunkAPI) => {
        try {
            const toDelete = thunkAPI.getState().root.user.list.find(ele => ele.id === args['id']);
            let response = await API_SERVICE.post(SAVE_USER, {
                ...toDelete, isdisabled: "Y", roleid: toDelete.roleid.map(role => ({ id: role })), vesselid: toDelete.vesselid.map(role => ({ id: role }))});
                response = response.data.result.data[0];

                // This field has a reference at TypeFields, so we need to refetch it
                thunkAPI.dispatch(setTypeFieldsIsUpdated(true));
                thunkAPI.dispatch(fetchUserApi());

                return response;
            }catch (err) {
                return thunkAPI.rejectWithValue(err.message)
            }
        }
)
export const getCompanyList = createAsyncThunk(COMPANY_LIST,
    async (args, thunkAPI) => {
        try {
            const vessel_id = thunkAPI.getState().root.common.vessel_detailByID.id;
            return await API_SERVICE.get(GET_COMPANY_DETAILS, { vessel_id });
        } catch (err) {
            return thunkAPI.rejectWithValue(err.message)
        }
    }
)
export const getUserApprovalList = createAsyncThunk(USER_APPROVAL,
    async (args, thunkAPI) => {
        try {
            const vessel_id = thunkAPI.getState().root.common.vessel_detailByID.id;
            return await API_SERVICE.get(`${SETTINGS}GetUserApprovalDropDown`, { ...args, vessel_id });
        } catch (err) {
            return thunkAPI.rejectWithValue(err.message)
        }
    }
)
export const getApprovalLevel = createAsyncThunk(APPROVAL_LEVEL,
    async (args, thunkAPI) => {
        try {
            const vessel_id = thunkAPI.getState().root.common.vessel_detailByID.id;
            return await API_SERVICE.get(`${SETTINGS}GetApprovallevel`, { ...args, vessel_id });
        } catch (err) {
            return thunkAPI.rejectWithValue(err.message)
        }
    }
)
export const getNotification = createAsyncThunk(NOTIFICATION_ACTION,
    async (args, thunkAPI) => {
        try {
            const vessel_id = thunkAPI.getState().root.common.vessel_detailByID.id;
            return await API_SERVICE.get(GET_NOTIFICATION, { vessel_id });
        } catch (err) {
            return thunkAPI.rejectWithValue(err.message)
        }
    }
)
export const saveNotification = createAsyncThunk(SAVE_NOTIFICATION_ACTION,
    async (args, thunkAPI) => {
        try {
            const vessel_id = thunkAPI.getState().root.common.vessel_detailByID.id;
            return await API_SERVICE.post(SAVE_NOTIFICATION, { ...args });
        } catch (err) {
            return thunkAPI.rejectWithValue(err.message)
        }
    }
)

export const saveCompanyDetails = createAsyncThunk(SAVE_COMPANY, async (args, thunkAPI) => {
    try {
        const vessel_id = thunkAPI.getState().root.common.vessel_detailByID.id;
        return await API_SERVICE.post(SAVE_COMPANY_DETAILS, { ...args, vessel_id });
    } catch (err) {
        return thunkAPI.rejectWithValue(err.message)
    }
}
)
export const saveApprovalLevel = createAsyncThunk(SAVE_APPROVAL, async (args, thunkAPI) => {
    try {
        return await API_SERVICE.post(`${SETTINGS}AddEditApprovallevel`, { ...args });
    } catch (err) {
        return thunkAPI.rejectWithValue(err.message)
    }
}
)
const initialState = {
    dashboard: data,
    list: [],
    companyList: [],
    userApprovalList: [],
    edit: {},
    isLoading: false,
    isUpdated: true,
    isSuccess: false,
    notificationData: {},
    userApprovalData: {},
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        addUser: (state, action) => {
            state.list = [...state.list, action.payload]
        },
        delUser: (state, action) => {
            state.list = state.list.filter(x => x.id !== action.payload)
        },
        loadEditUser: (state, action) => {
            state.edit = action.payload
        },
        clearEditUser: (state, action) => {
            state.edit = {}
            state.userApprovalData = {}
            state.isSuccess = false
        },
        updateUser: (state, action) => {
            let index = state.list.findIndex(x => x.id === action.payload.id)
            state.list[index] = action.payload
        },
        resetUser: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(addUserApi.fulfilled, (state, { payload }) => {
                if (payload.data.result.status) {
                    state.isSuccess = true
                    toast.success(payload.data.result.message)
                } else
                    toast.error(payload.data.result.message)
            })
            .addCase(updateUserApi.fulfilled, (state, { payload }) => {
                if (payload.data.result.status) {
                    state.isSuccess = true
                    toast.success(payload.data.result.message)
                } else
                    toast.error(payload.data.result.message)
            })
            .addCase(fetchUserApi.fulfilled, (state, action) => {
                state.list = action.payload;
                state.isUpdated = false;
            }).addCase(getUserApprovalList.fulfilled, (state, { payload }) => {
                state.userApprovalList = payload.data.result.data
                state.isUpdated = false;
            }).addCase(getApprovalLevel.fulfilled, (state, { payload }) => {
                const data = payload.data.result.data[0]
                data.approveldetail = data?.approveldetail ? ConvertJson(data.approveldetail) : [],
                    state.userApprovalData = data
                state.isUpdated = false;
            }).addCase(fetchUserById.fulfilled, (state, action) => {
                state.edit = action.payload[0];
            })
            .addCase(deleteUserApi.fulfilled, (state, action) => {
                state.list = state.list.filter(x => x.id !== action.payload.id);
                toast.success('Successfully deleted');
            })
            .addCase(getCompanyList.fulfilled, (state, { payload }) => {
                state.getCompanyList = payload.data.result.data
            }).addCase(saveCompanyDetails.fulfilled, (state, { payload }) => {
                if (payload.data.result.status) {
                    state.isSuccess = true
                    toast.success(payload.data.result.message)
                } else
                    toast.error(payload.data.result.message)
            }).addCase(saveApprovalLevel.fulfilled, (state, { payload }) => {
                if (payload.data.result.status) {
                    state.isSuccess = true
                    toast.success(payload.data.result.message)
                } else
                    toast.error(payload.data.result.message)
            }).addCase(saveNotification.fulfilled, (state, { payload }) => {
                if (payload.data.result.status) {
                    state.isSuccess = true
                    toast.success(payload.data.result.message)
                } else
                    toast.error(payload.data.result.message)
            }).addCase(getNotification.fulfilled, (state, { payload }) => {
                if (payload.data.result.status)
                    state.notificationData = payload.data.result.data[0]
            })
    }
})

export const { addUser, delUser, loadEditUser, clearEditUser, updateUser, resetUser } = userSlice.actions
export default userSlice.reducer