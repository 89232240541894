import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { toast } from "react-toastify"
import { GET_STATUS, SAVE_STATUS } from "../constant/CommonApiUrl"
import { API_SERVICE } from "../Services/CommonApi";

const ADD_STATUS='status/addStatusApi'
const UPDATE_STATUS = 'status/updateStatusApi'
const FETCH_STATUS='status/fetchStatusApi'
const DELETE_STATUS = 'status/deleteStatusApi'

export const addStatusApi =createAsyncThunk(ADD_STATUS,
  async(args, thunkAPI)=>{
    try{
      let response = await API_SERVICE.post(SAVE_STATUS, args['data'], "CREATE");
      response = response.data.result.data[0];
      return response;
    }catch(err){
      return thunkAPI.rejectWithValue(err.message)
    }
  })

export const updateStatusApi = createAsyncThunk(UPDATE_STATUS,
  async(args, thunkAPI)=>{
    try{
      let response = await API_SERVICE.post(SAVE_STATUS, args['data'], "UPDATE");
      thunkAPI.dispatch(fetchStatusApi())
      response = response.data.result.data[0];
      return response;
    }catch(err){
      return thunkAPI.rejectWithValue(err.message)
    }
  })

export const deleteStatusApi = createAsyncThunk(DELETE_STATUS,
  async (args, thunkAPI) => {
    try{
      const toDelete = thunkAPI.getState().root.status.list.find(ele => ele.id === args['id']);
      const response = await API_SERVICE.post(SAVE_STATUS, {...toDelete, isdisabled: "Y"});
      return response.data.result.data[0];
    }catch(err){
      return thunkAPI.rejectWithValue(err.message)
    }
  }
)

export const fetchStatusApi = createAsyncThunk(FETCH_STATUS,
  async(arg,thunkAPI)=>{
    try{
      let response = await API_SERVICE.get(GET_STATUS);
      response = response.data.result.data;
      return response
    }catch(err){
      return thunkAPI.rejectWithValue(err.message)
    }
  }
)

const initialState={
  list:[],
  editStatus:{},
  isUpdated: true
}
export const statusSlice=createSlice({
  name:'status',
  initialState,
  reducers:{
    addStatus:(state,action)=>{
      state.list=[...state.list,action.payload]
    },
    delStatus:(state,action)=>{
      state.list=state.list.filter(x=>x.id!==action.payload)
    },
    loadEditStatus:(state,action)=>{
      state.editStatus=action.payload
    },
    clearEditStatus:(state,action)=>{
      state.editStatus={}
    },
    updateStatus:(state,action)=>{
      let index=state.list.findIndex(x=>x.id===action.payload.id)
      state.list[index]=action.payload
    },
    resetStatus:()=>initialState
  },
  extraReducers:(builder)=>{
    builder
      .addCase(addStatusApi.fulfilled,(state,action)=>{
        state.list=[...state.list,action.payload];
        toast.success('Successfully added');
      })
      .addCase(updateStatusApi.fulfilled,(state,action)=>{
        state.list = state.list.map(x => x.id === action.payload.id ? action.payload: x);
        toast.success('Successfully updated');
      })
      .addCase(fetchStatusApi.fulfilled, (state, action) => {
        state.list = action.payload;
        state.isUpdated = false;
      })
      .addCase(deleteStatusApi.fulfilled, (state, action) => {
        state.list=state.list.filter(x=>x.id!==action.payload.id);
        toast.success('Successfully deleted');
      })
  }
})
export const {addStatus,delStatus,loadEditStatus,clearEditStatus,updateStatus,resetStatus}=statusSlice.actions
export default statusSlice.reducer