import { createAsyncThunk } from "@reduxjs/toolkit";
import * as ACTION from "../../constant/Actions";
import * as APIS from "../../constant/CommonApiUrl"
import { API_SERVICE } from "../../Services/CommonApi";
import axios from "axios";

export const getVesselInspection = createAsyncThunk(ACTION.GET_VESSEL_INSPECTION,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(`${APIS.GET_VESSEL_INSPECTION}?vessel_id=${id}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getVesselInspectionbyId = createAsyncThunk(ACTION.GET_VESSEL_INSPECTION_ID,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(`${APIS.GET_VESSEL_INSPECTION}?id=${arg}&vessel_id=${id}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const saveVesselInspection = createAsyncThunk(ACTION.SAVE_VESSEL_INSPECTION,
    async (arg, thunkApi) => {
        try {
            const { id, vessel_name } = thunkApi.getState().root.common.vessel_detailByID
            return await API_SERVICE.post(APIS.SAVE_VESSEL_INSPECTION, arg)
        } catch (err) {
            thunkApi.rejectWithValue(err)
        }
    })
export const getVesselInspectionSub = createAsyncThunk(ACTION.GET_VESSEL_INSPECTION_SUB,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(`${APIS.GET_VESSEL_INSPECTION_SUB}?vessel_id=${id}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getVesselInspectionSubbyId = createAsyncThunk(ACTION.GET_VESSEL_INSPECTION_SUB_ID,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(`${APIS.GET_VESSEL_INSPECTION_SUB}?id=${arg}&vessel_id=${id}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const saveVesselInspectionSub = createAsyncThunk(ACTION.SAVE_VESSEL_INSPECTION_SUB,
    async (arg, thunkApi) => {
        try {
            const { id, vessel_name } = thunkApi.getState().root.common.vessel_detailByID
            return await API_SERVICE.post(APIS.SAVE_VESSEL_INSPECTION_SUB, arg)
        } catch (err) {
            thunkApi.rejectWithValue(err)
        }
    })
export const getVesselInspectionMaster = createAsyncThunk(ACTION.GET_VESSEL_INSPECTION_MASTER,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(`${APIS.GET_VESSEL_INSPECTION_MASTER}?vessel_id=${id}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getVesselInspectionMasterbyId = createAsyncThunk(ACTION.GET_VESSEL_INSPECTION_MASTER_ID,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(`${APIS.GET_VESSEL_INSPECTION_MASTER}?id=${arg}&vessel_id=${id}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const saveVesselInspectionMaster = createAsyncThunk(ACTION.SAVE_VESSEL_INSPECTION_MASTER,
    async (arg, thunkApi) => {
        try {
            const { id, vessel_name } = thunkApi.getState().root.common.vessel_detailByID
            return await API_SERVICE.post(APIS.SAVE_VESSEL_INSPECTION_MASTER, arg)
        } catch (err) {
            thunkApi.rejectWithValue(err)
        }
    })
export const getVesselInspectionReport = createAsyncThunk(ACTION.GET_VESSEL_INSPECTION_TRANS,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(`${APIS.GET_VESSEL_INSPECTION_TRANS}?vessel_id=${id}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getVesselInspectionReportbyId = createAsyncThunk(ACTION.GET_VESSEL_INSPECTION_TRANS_ID,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(`${APIS.GET_VESSEL_INSPECTION_TRANS}?id=${arg}&vessel_id=${id}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const saveVesselInspectionReport = createAsyncThunk(ACTION.SAVE_VESSEL_INSPECTION_TRANS,
    async (arg, thunkApi) => {
        try {
            const { id, vessel_name } = thunkApi.getState().root.common.vessel_detailByID
            return await API_SERVICE.post(APIS.SAVE_VESSEL_INSPECTION_TRANS, arg)
        } catch (err) {
            thunkApi.rejectWithValue(err)
        }
    })
export const getVesselInspectionCount = createAsyncThunk(ACTION.GET_VESSEL_INSPECTION_COUNT,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(`${APIS.GET_VESSEL_INSPECTION_COUNT}?vessel_id=${id}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getVesselConditionStatus = createAsyncThunk(ACTION.GET_VESSEL_INSPECTION_CONDITION,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(`${APIS.GET_VESSEL_INSPECTION_CONDITION}?${arg}&vessel_id=${id}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getVesselInspectionOpen = createAsyncThunk(ACTION.GET_VESSEL_INSPECTION_OPEN,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(arg ? `${APIS.GET_VESSEL_INSPECTION_OPEN}?${arg}&vessel_id=${id}` : `${APIS.GET_VESSEL_INSPECTION_OPEN}?vessel_id=${id}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })

