import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { toast } from "react-toastify"
import { API_SERVICE } from "../Services/CommonApi"
import { GET_USER_ACCESS, SAVE_USER_ACCESS } from "../constant/CommonApiUrl";

const ADD_USER_ACCESS='userAccess/addUserAccessApi'
const UPDATE_USER_ACCESS='userAccess/updateUserAccessApi'
const FETCH_USER_ACCESS='userAccess/fetchUserAccessApi'
const DELETE_USER_ACCESS='userAccess/deleteUserAccessApi'

export const addUserAccessApi =createAsyncThunk(ADD_USER_ACCESS,
    async(args, thunkAPI)=>{
        try{
            let response = await API_SERVICE.post(SAVE_USER_ACCESS, args['data']);
            response = response.data.result.data[0];
            thunkAPI.dispatch(fetchUserAccessApi())
            return response;
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
})

export const updateUserAccessApi = createAsyncThunk(UPDATE_USER_ACCESS,
    async(args, thunkAPI)=>{
        try{
            let response = await API_SERVICE.post(SAVE_USER_ACCESS, args['data']);
            response = response.data.result.data[0]
            thunkAPI.dispatch(fetchUserAccessApi())
            return response;
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
})

export const deleteUserAccessApi = createAsyncThunk(DELETE_USER_ACCESS, 
    async (args, thunkAPI) => {
        try{
            const toDelete = thunkAPI.getState().root.userAccess.list.find(ele => ele.id === args['id']);   
            const response = await API_SERVICE.post(SAVE_USER_ACCESS, {...toDelete, isdisabled: "Y"});
            thunkAPI.dispatch(fetchUserAccessApi())
            return response.data.result.data[0];
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
    }
)

export const fetchUserAccessApi = createAsyncThunk(FETCH_USER_ACCESS,
    async(arg,thunkAPI)=>{
        try{
            let response = await API_SERVICE.get(GET_USER_ACCESS);
            return response.data.result.data;
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
    },
    {
        condition: (arg, { getState, extra }) => {          
          const isUpdated = getState().root.userAccess.isUpdated                         
          if(!isUpdated){
            console.log('enable fetch condition in reducer')
            return false
          }
        },
      }
)

const initialState={
    list:[],
    editUserAccess:{},   
    isUpdated: true,
  isSaved: true,
}

export const userAccessSlice=createSlice({
    name:'userAccess',
    initialState,
    reducers:{
        addUserAccess:(state,action)=>{
            state.list=[...state.list,action.payload]
        },
        delUserAccess:(state,action)=>{
            state.list=state.list.filter(x=>x.id!==action.payload)
        },
        loadEditUserAccess:(state,action)=>{
            state.editUserAccess=action.payload
        },
        clearEditUserAccess:(state,action)=>{
            state.editUserAccess={}
        },
        updateUserAccess:(state,action)=>{
            let index=state.list.findIndex(x=>x.id===action.payload.id)
            state.list[index]=action.payload
        },               
        resetUserAccess:()=>initialState
    },
    extraReducers:(builder)=>{
        builder
        .addCase(addUserAccessApi.fulfilled,(state,action)=>{
          if (action.payload.Status === 'success') {
            state.isSaved= true;
            toast.success('Successfully added');
          } else {
            toast.error(action.payload.Status)
          }
        })
        .addCase(updateUserAccessApi.fulfilled,(state,action)=>{
            toast.success('Successfully updated');
        })
        .addCase(fetchUserAccessApi.fulfilled, (state, action) => {
            state.list = action.payload;
            state.isUpdated = false;
            state.isSaved = false;
        })
        .addCase(deleteUserAccessApi.fulfilled, (state, action) => {
            toast.success('Successfully deleted');
        })
    }   
})
export const {addUserAccess,delUserAccess,loadEditUserAccess,clearEditUserAccess,updateUserAccess,resetUserAccess}=userAccessSlice.actions
export default userAccessSlice.reducer