import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { toast } from "react-toastify"
import { GET_PRODUCT_SUPPLIER, SAVE_PRODUCT_SUPPLIER } from "../constant/CommonApiUrl"
import { dateTimeToDate } from "../helper/dateUtil";
import { API_SERVICE } from "../Services/CommonApi";

const ADD_PRODUCT_SUPPLIER = 'productsupplier/addProductSupplierApi'
const UPDATE_PRODUCT_SUPPLIER = 'productsupplier/updateProductSupplierApi'
const FETCH_PRODUCT_SUPPLIER = 'productsupplier/fetchProductSupplierApi'
const DELETE_PRODUCT_SUPPLIER = 'productsupplier/deleteProductSupplierApi'

export const addProductSupplierApi =createAsyncThunk(ADD_PRODUCT_SUPPLIER,
    async(args, thunkAPI)=>{
        try{
            let response = await API_SERVICE.post(SAVE_PRODUCT_SUPPLIER, args['data'], "CREATE");
            response = response.data.result.data[0];
            response.product_mfd =  dateTimeToDate(response.product_mfd); 
            response.product_exp =  dateTimeToDate(response.product_exp);
            thunkAPI.dispatch(fetchProductSupplierApi())
            return response;
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
})

export const updateProductSupplierApi = createAsyncThunk(UPDATE_PRODUCT_SUPPLIER,
    async(args, thunkAPI)=>{
        try{
            let response = await API_SERVICE.post(SAVE_PRODUCT_SUPPLIER, args['data'], "UPDATE");
            response = response.data.result.data[0];
            response.product_mfd =  dateTimeToDate(response.product_mfd); 
            response.product_exp =  dateTimeToDate(response.product_exp);
            thunkAPI.dispatch(fetchProductSupplierApi())
            return response;
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
})

export const deleteProductSupplierApi = createAsyncThunk(DELETE_PRODUCT_SUPPLIER, 
    async (args, thunkAPI) => {
        try{
            const toDelete = thunkAPI.getState().root.productSupplier.list.find(ele => ele.id === args['id']);   
            const response = await API_SERVICE.post(SAVE_PRODUCT_SUPPLIER, {...toDelete, isdisabled: "Y"});
            thunkAPI.dispatch(fetchProductSupplierApi())
            return response.data.result.data[0];
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
    }
)

export const fetchProductSupplierApi = createAsyncThunk(FETCH_PRODUCT_SUPPLIER,
    async(arg,thunkAPI)=>{
        try{
            let response = await API_SERVICE.get(GET_PRODUCT_SUPPLIER);
            response = response.data.result.data;
            response = response.map(ps => ({...ps, 
                product_mfd: dateTimeToDate(ps.product_mfd),
                product_exp: dateTimeToDate(ps.product_exp),
            }))
            return response
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
    }
)

const initialState={
    list:[],
    editProductSupplier:{},
    isUpdated: true    
}
export const ProductSupplierSlice = createSlice({
    name:'productSupplier',
    initialState,
    reducers:{
        loadEditProductSupplier:(state,action)=>{
            state.editProductSupplier=action.payload
        },
        clearEditProductSupplier:(state,action)=>{
            state.editProductSupplier={}
        },         
        resetProductSupplier:()=>initialState
    },
    extraReducers:(builder)=>{
        builder
        .addCase(addProductSupplierApi.fulfilled,(state,action)=>{
            toast.success('Successfully added');
        })
        .addCase(updateProductSupplierApi.fulfilled,(state,action)=>{
            toast.success('Successfully updated');
        })
        .addCase(fetchProductSupplierApi.fulfilled, (state, action) => {
            state.list = action.payload;
            state.isUpdated = false;
        })
        .addCase(deleteProductSupplierApi.fulfilled, (state, action) => {
            toast.success('Successfully deleted');
        })
    }   
})
export const {loadEditProductSupplier, clearEditProductSupplier, resetProductSupplier} = ProductSupplierSlice.actions
export default ProductSupplierSlice.reducer