import {createSlice} from "@reduxjs/toolkit";
import {AddEditResponse, ValidateResponse} from "../Services/Support";
import {
    getDrillNameById,
    getDrillNameMasterList,
    GetDrillRecord,
    GetDrillRecordById,
    getDrillScheduledById,
    getDrillScheduledList,
    getMeetingAgendaMasterById,
    getMeetingAgendaMasterList,
    getMeetingNameById,
    getMeetingNameMasterList,
    GetMeetingRecord,
    GetMeetingRecordById,
    getMeetingScheduledById,
    getMeetingSCheduledList,
    saveDrillName,
    saveDrillRecord,
    saveDrillScheduled,
    saveMeetingAgendaMaster,
    saveMeetingName,
    saveMeetingRecord,
    saveMeetingScheduled
} from "./THUNK/MeetingThunk";
import {ConvertJson} from "../helper";

const initialState = {
    meetingNameList: [],
    drillnameList: [],
    meetingRecordList: [],
    drillscheduledList: [],
    meetingscheduledList: [],
    meetingagendaList: [],
    drillRecordList: [],
    editRecord: {},
    dashboardData: {}
}


const meetingSlice = createSlice({
    name: 'meetingSlice',
    initialState,
    reducers: {
        setViewMeetingDetails: (state, { payload }) => {
            state.viewData = payload
        },
        setDataFromDashboard: (state, { payload }) => {
            const { id, ...rest} = payload
            state.editRecord = rest
        },
        clearEditFields: (state, {payload}) => {
            state.editRecord = {};
            state.dashboardData = {}
        }
    },

    extraReducers: (builder) => {
        builder.addCase(getMeetingNameMasterList.fulfilled, (state, action) => {
            state.meetingNameList = ValidateResponse(action.payload)
        }).addCase(getMeetingNameById.fulfilled, (state, action) => {
            let response = ValidateResponse(action.payload)
            state.editRecord = response[0]
        }).addCase(saveMeetingName.fulfilled, (state, action) => {
            AddEditResponse(action.payload)
        }).addCase(saveMeetingRecord.fulfilled, (state, action) => {
            AddEditResponse(action.payload)
        }).addCase(saveDrillRecord.fulfilled, (state, action) => {
            AddEditResponse(action.payload)
        }).addCase(GetDrillRecord.fulfilled, (state, action) => {
            state.drillRecordList = AddEditResponse(action.payload)
        }).addCase(GetMeetingRecord.fulfilled, (state, action) => {
            state.meetingRecordList = AddEditResponse(action.payload)
        }).addCase(GetMeetingRecordById.fulfilled, (state, action) => {
            let response = ValidateResponse(action.payload)
            let payload = response[0]
            payload['repository'] = payload?.repository ? ConvertJson(payload.repository) : []
            state.editRecord = payload
        }).addCase(GetDrillRecordById.fulfilled, (state, action) => {
            let response = ValidateResponse(action.payload)
            state.editRecord = response[0]
        }).addCase(getDrillNameMasterList.fulfilled, (state, action) => {
            state.drillnameList = ValidateResponse(action.payload)
        }).addCase(getDrillNameById.fulfilled, (state, action) => {
            let response = ValidateResponse(action.payload)
            state.editRecord = response[0]
        }).addCase(saveDrillName.fulfilled, (state, action) => {
            AddEditResponse(action.payload)
        }).addCase(getDrillScheduledList.fulfilled, (state, action) => {
            state.drillscheduledList = ValidateResponse(action.payload)
        }).addCase(getDrillScheduledById.fulfilled, (state, action) => {
            let response = ValidateResponse(action.payload)
            let payload = response[0]
            payload['repository'] = payload?.repository ? ConvertJson(payload.repository) : []
            state.editRecord = payload
        }).addCase(saveDrillScheduled.fulfilled, (state, action) => {
            AddEditResponse(action.payload)
        }).addCase(getMeetingSCheduledList.fulfilled, (state, action) => {
            state.meetingscheduledList = ValidateResponse(action.payload)
        }).addCase(getMeetingScheduledById.fulfilled, (state, action) => {
            let response = ValidateResponse(action.payload)
            let payload = response[0]
            payload['repository'] = payload?.repository ? ConvertJson(payload.repository) : []
            state.editRecord = payload
        }).addCase(saveMeetingScheduled.fulfilled, (state, action) => {
            AddEditResponse(action.payload)
        }).addCase(getMeetingAgendaMasterList.fulfilled, (state, action) => {
            state.meetingagendaList = ValidateResponse(action.payload)
        }).addCase(getMeetingAgendaMasterById.fulfilled, (state, action) => {
            let response = ValidateResponse(action.payload)
            state.editRecord = response[0]
        }).addCase(saveMeetingAgendaMaster.fulfilled, (state, action) => {
            AddEditResponse(action.payload)
        })
    }
})

export const { setViewMeetingDetails, clearEditFields, setDataFromDashboard } = meetingSlice.actions
export default meetingSlice.reducer