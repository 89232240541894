import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { toast } from "react-toastify"
import { API_SERVICE } from "../Services/CommonApi"
import { GET_APPROVE, SAVE_APPROVE } from "../constant/CommonApiUrl";
import { dateTimeToDate } from "../helper/dateUtil"
import { setTypeFieldsIsUpdated } from "./typeFieldsSlice"

const ADD_APPROVAL = 'approval/addApprovalApi'
const UPDATE_APPROVAL = 'approval/updateApprovalApi'
const FETCH_APPROVAL='approval/fetchApprovalApi'
const DELETE_APPROVAL = 'approval/deleteApprovalApi'

export const addApprovalApi =createAsyncThunk(ADD_APPROVAL,
    async(args, thunkAPI)=>{
        try{
            let response = await API_SERVICE.post(SAVE_APPROVE, args['data'], "CREATE");
            response = response.data.result.data[0];
            thunkAPI.dispatch(fetchApprovalApi())
            thunkAPI.dispatch(setTypeFieldsIsUpdated(true));
            return response;
        }catch(err){
            return thunkAPI.rejectWithValue(err)
        }
})

export const updateApprovalApi = createAsyncThunk(UPDATE_APPROVAL,
    async(args, thunkAPI)=>{
        try{
            let response = await API_SERVICE.post(SAVE_APPROVE, args['data'], "UPDATE");
            response = response.data.result.data[0]
            thunkAPI.dispatch(fetchApprovalApi())
            thunkAPI.dispatch(setTypeFieldsIsUpdated(true));
            return response;
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
})

export const deleteApprovalApi = createAsyncThunk(DELETE_APPROVAL, 
    async (args, thunkAPI) => {
        try{
            const toDelete = thunkAPI.getState().root.approval.list.find(ele => ele.id === args['id']);   
            let response = await API_SERVICE.post(SAVE_APPROVE, {...toDelete, isdisabled: "Y"});
            response = response.data.result.data[0]
            thunkAPI.dispatch(fetchApprovalApi())
            thunkAPI.dispatch(setTypeFieldsIsUpdated(true));
            return response;
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
    }
)

export const fetchApprovalApi = createAsyncThunk(FETCH_APPROVAL,
    async(arg,thunkAPI)=>{
        try{
            let response = await API_SERVICE.get(GET_APPROVE);
            response = response.data.result.data;
            response = response.map(appr => ({...appr, 
                aprroved_date: dateTimeToDate(appr.aprroved_date),
                aprroved_update: dateTimeToDate(appr.aprroved_update)
            }))
            return response
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
    }
)

const initialState={
    list:[],
    editapproval:{},   
    isLoading:false,
    isUpdated:true, 
}

export const approvalSlice=createSlice({
    name:'approval',
    initialState,
    reducers:{
        addApproval:(state,action)=>{
            console.log(action.payload)
            state.list=[...state.list,action.payload]
        },
        delApproval:(state,action)=>{
            state.list=state.list.filter(x=>x.id!==action.payload)
        },
        loadEditApproval:(state,action)=>{
            state.editapproval=action.payload
        },
        clearEditApproval:(state,action)=>{
            state.editapproval={}
        },
        updateApproval:(state,action)=>{
            let index=state.list.findIndex(x=>x.id===action.payload.id)
            state.list[index]=action.payload
        },               
        resetApproval:()=>initialState
    },
    extraReducers:(builder)=>{
        builder
        .addCase(addApprovalApi.fulfilled,(state,action)=>{
            toast.success('Successfully added');
        })
        .addCase(addApprovalApi.rejected,(state,action)=>{            
            if(action.payload.response.data.status===400){
                toast.error(action.payload.response.data.title)
                state.localPartsDetail=[]
            }  
        })
        .addCase(updateApprovalApi.fulfilled,(state,action)=>{
            toast.success('Successfully updated');
        })
        .addCase(fetchApprovalApi.fulfilled, (state, action) => {
            state.list = action.payload;
            state.isUpdated = false;
        })
        .addCase(deleteApprovalApi.fulfilled, (state, action) => {
            toast.success('Successfully deleted');
        })
        /* .addMatcher(isPendingAction,(state, action) => {
            state.isLoading=true
            //console.log(state.isLoading,'add matcher pending')
        })          
        .addMatcher(isRejectedAction,(state, action) => {
            state.isLoading=false
           // approvalAdapter.addOne(state,{id:1,name:'mouse',type:'animal'})
            console.log(action.payload,'add matcher rejected',state.isLoading,'isloding')
            toast.error(action.payload)
        }) */
    } 
})
export const {addApproval,delApproval,loadEditApproval,clearEditApproval,updateApproval,resetApproval}=approvalSlice.actions
export default approvalSlice.reducer