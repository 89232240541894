import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as APIS from '../constant/CommonApiUrl';
import * as ACTIONS from  '../constant/Actions'
import { API_SERVICE } from "../Services/CommonApi"
import { toast } from "react-toastify";
const initialState={
    rollback_option:['PR NEW','PR APPROVED','RFQ NEW','RFQ APPROVED','QUOTE NEW','QC NEW','QC VERIFIED','QC APPROVED','PO NEW','PO ISSUED','MR NEW','MR DISCREPANCY','MR RECEIVED','INVOICED'],
    selected_rollback:[]
}
export const saveRollback=createAsyncThunk(ACTIONS.SAVE_ROLLBACK,
    async(arg,thunkApi)=>{
        try{
            return await API_SERVICE.post(APIS.SAVE_ROLLBACK,arg)
        }catch(err){
            return thunkApi.rejectWithValue(err)
        }
    })
export const rollbackSlice=createSlice({
    name:'rollback',
    initialState,
    reducers:({
        setRollbackReducer:(state,action)=>{
            let index=state.rollback_option.indexOf(action.payload)
            state.selected_rollback=state.rollback_option.slice(0,index)
        },
        clearRollbackItem:(state,action)=>{
            state.selected_rollback=[]
        }
    }),
    extraReducers:(builder)=>{
        (builder)
        .addCase(saveRollback.fulfilled,(state,action)=>{
            if(action.payload.data.result.status){
                toast.success(action.payload.data.result.message)
            }else{
                toast.error(action.payload.data.result.message)
            }
        })
    }
})
export const {setRollbackReducer,clearRollbackItem}=rollbackSlice.actions
export default rollbackSlice.reducer