import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { toast } from "react-toastify"
import { GET_MENU, SAVE_MENU } from "../constant/CommonApiUrl";
import { API_SERVICE } from "../Services/CommonApi"
import { setTypeFieldsIsUpdated } from "./typeFieldsSlice"

const ADD_MENU='menu/addMenuApi'
const UPDATE_MENU = 'menu/updateMenuApi'
const FETCH_MENU='menu/fetchMenuApi'
const DELETE_MENU = 'menu/deleteMenuApi'

export const addMenuApi =createAsyncThunk(ADD_MENU,
  async(args, thunkAPI)=>{
    try{
      let response = await API_SERVICE.post(SAVE_MENU, args['data'], "CREATE");
      response = response.data.result.data[0];
      thunkAPI.dispatch(setTypeFieldsIsUpdated(true));
      return response;
    }catch(err){
      return thunkAPI.rejectWithValue(err.message)
    }
  })

export const updateMenuApi = createAsyncThunk(UPDATE_MENU,
  async(args, thunkAPI)=>{
    try{
      let response = await API_SERVICE.post(SAVE_MENU, args['data'], "UPDATE");
      response = response.data.result.data[0];
      thunkAPI.dispatch(setTypeFieldsIsUpdated(true));
      return response;
    }catch(err){
      return thunkAPI.rejectWithValue(err.message)
    }
  })

export const deleteMenuApi = createAsyncThunk(DELETE_MENU,
  async (args, thunkAPI) => {
    try{
      const toDelete = thunkAPI.getState().root.menu.list.find(ele => ele.id === args['id']);
      let response = await API_SERVICE.post(SAVE_MENU, {...toDelete, isdisabled: "Y"});
      response = response.data.result.data[0];
      thunkAPI.dispatch(setTypeFieldsIsUpdated(true));
      return response;
    }catch(err){
      return thunkAPI.rejectWithValue(err.message)
    }
  }
)

export const fetchMenuApi = createAsyncThunk(FETCH_MENU,
  async(arg,thunkAPI)=>{
    try{
      let response = await API_SERVICE.get(GET_MENU);
      response = response.data.result.data;
      return response
    }catch(err){
      return thunkAPI.rejectWithValue(err.message)
    }
  },
  {
    condition: (arg, { getState, extra }) => {
      const isUpdated = getState().root.menu.isUpdated
      if(!isUpdated){
        console.log('enable fetch condition in reducer')
        return false
      }
    },
  }
)

const initialState={
  list:[],
  editMenu:{},
  isUpdated: true,
}

export const menuSlice=createSlice({
  name:'menu',
  initialState,
  reducers:{
    addMenu:(state,action)=>{
      state.list=[...state.list,action.payload]
    },
    delMenu:(state,action)=>{
      state.list=state.list.filter(x=>x.id!==action.payload)
    },
    loadEditMenu:(state,action)=>{
      state.editMenu=action.payload
    },
    clearEditMenu:(state,action)=>{
      state.editMenu={}
    },
    updateMenu:(state,action)=>{
      let index=state.list.findIndex(x=>x.id===action.payload.id)
      state.list[index]=action.payload
    },
    resetMenu:()=>initialState
  },
  extraReducers:(builder)=>{
    builder
      .addCase(addMenuApi.fulfilled,(state,action)=>{
        if (action.payload.status === 'success') {
          state.list=[...state.list,action.payload];
          toast.success('Successfully added');
        } else {
          toast.error('Please try with different menu code');
        }
      })
      .addCase(updateMenuApi.fulfilled,(state,action)=>{
        state.list = state.list.map(x => x.id === action.payload.id ? action.payload: x);
        toast.success('Successfully updated');
      })
      .addCase(fetchMenuApi.fulfilled, (state, action) => {
        state.list = action.payload;
        state.isUpdated = false;
      })
      .addCase(deleteMenuApi.fulfilled, (state, action) => {
        state.list=state.list.filter(x=>x.id!==action.payload.id);
        toast.success('Successfully deleted');
      })
  }
})
export const {addMenu,delMenu,loadEditMenu,clearEditMenu,updateMenu,resetMenu}=menuSlice.actions
export default menuSlice.reducer