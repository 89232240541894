import { createSlice } from "@reduxjs/toolkit";
import { ValidateResponse } from "../Services/Support";
import { ConvertJson } from "../helper";
import { getPMSanalysisReport, getPMSanalysisVessel } from "./THUNK/pmsAnalysisThunk";
import { getMissingCylinderMonth, getMissingWeek } from "../pages/PMS/ANALYSIS/Support";
const initialState = {
    completion_summary: {},
    completion_details: [],
    unscheduled_summary: {},
    maintenance_weekly_details: [],
    unscheduled_weekly_details: [],
    maintenance_weekly_summary: {},
    unscheduled_weekly_summary: {},
    vessel_chart: [],
    department_wise_data: [],
    department_wise_data1: [],
    department_overdue_data:[],
    multiple_vessel:[],
    multiple_data:[]
}
const pmsAnalysisSlice = createSlice({
    name: 'ANALYSIS',
    initialState,
    reducers: {
        clearAnalysisReport: (state, action) => {
            state.completion_summary = {}
            state.completion_details = []
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPMSanalysisReport.fulfilled, (state, action) => {
                let response = ValidateResponse(action.payload)
                let obj = response[0]
                obj.completion_summary = obj.completion_summary ? ConvertJson(obj.completion_summary) : []
                obj.completion_details = obj.completion_details ? ConvertJson(obj.completion_details) : []
                obj.unscheduled_summary = obj.unscheduled_summary ? ConvertJson(obj.unscheduled_summary) : []
                obj.maintenance_weekly_summary = obj.maintenance_weekly_summary ? ConvertJson(obj.maintenance_weekly_summary) : []
                obj.unscheduled_weekly_summary = obj.unscheduled_weekly_summary ? ConvertJson(obj.unscheduled_weekly_summary) : []
                obj.maintenance_weekly_details = obj.maintenance_weekly_details ? ConvertJson(obj.maintenance_weekly_details) : []
                obj.unscheduled_weekly_details = obj.unscheduled_weekly_details ? ConvertJson(obj.unscheduled_weekly_details) : []
                const month_size = new Array(12).fill().map((_, i) => ++i)
                const received_month = obj.completion_details.map(el => el.job_month)
                const missing_month = month_size.filter(el => received_month.includes(el) === false && el)
                const missing_month_data = missing_month.map(el => getMissingCylinderMonth(el))
                let all_month_data = [...missing_month_data, ...obj.completion_details].sort((a, b) => a.job_month - b.job_month)
                state.completion_details = all_month_data
                state.completion_summary = obj.completion_summary[0]
                state.unscheduled_summary = obj.unscheduled_summary[0]                
                state.maintenance_weekly_summary = obj.maintenance_weekly_summary[0]
                state.unscheduled_weekly_summary = obj.unscheduled_weekly_summary[0]
                state.maintenance_weekly_details = getMissingWeek(obj.maintenance_weekly_details)
                state.unscheduled_weekly_details = getMissingWeek(obj.unscheduled_weekly_details)
            })
            .addCase(getPMSanalysisVessel.fulfilled, (state, action) => {
                let response = ValidateResponse(action.payload)
                let obj = response[0]
                state.vessel_chart = obj?.chart_data ? ConvertJson(obj?.chart_data) : []
                /* state.department_wise_data = obj?.department_wise_data ? ConvertJson(obj?.department_wise_data) : [] */
                //console.log(obj.department_wise_data,'obj')
                state.department_wise_data1=JSON.parse(obj.department_wise_data)
                state.department_overdue_data = obj?.department_overdue_data ? ConvertJson(obj?.department_overdue_data) : []
                state.multiple_vessel = obj?.multiple_vessel ? ConvertJson(obj?.multiple_vessel) : []
                state.multiple_data = obj?.multiple_data ? ConvertJson(obj?.multiple_data) : []
                //console.log(obj.department_wise_data,'data')
            })
    }
})
export const { clearAnalysisReport } = pmsAnalysisSlice.actions
export default pmsAnalysisSlice.reducer