import { createSlice } from "@reduxjs/toolkit";
import { ValidateResponse } from "../Services/Support";
import {
    getImmediateCauseOfDamagePG,
    getImmediateCauseOfDamagePGReason,
    getImmediateCauseOfDamageSec,
    getImmediateCauseOfDamageSecReason,
    getBasicCausePersonalFactoryGroup,
    getBasicCausePersonalFactoryGroupReason,
    getBasicCauseJobFactoryGroup,
    getBasicCauseJobFactoryGroupReason,
    getIncidentDetailsOfItem,
    getIncidentDamageProgramme,
    getIncidentDamageProgrammeReason,
    getIncidentDamagePerformanceStd,
    getIncidentDamagePerformanceStdReason,
    getIncidentDamageComplianceExitStd,
    getIncidentDamageComplianceExitStdReason,
    getIncidentDamageMachineryBreakdown,
    getIncidentDamageDPA,
    getIncidentDamageLossSeverity,
    getIncidentDamageClosedOut,
    getInjuryPortDept,
    getInjuryPortDesig,
    getInjuryManualRef,
    getInjuryProbability,
    getNMUnsafe,
    getNMImmedidatePrim,
    getNMImmedidatePrimReason,
    getNMImmedidateSec,
    getNMImmedidateSecReason,
    getNMBasicPersFact,
    getNMBasicPersFactReason,
    getNMBasicJobFact,
    getNMBasicJobFactReason,
} from "./THUNK/IncidentDropdownThunk";
import { incident_damage_closed_out } from "../constant/Actions";

const initialState = {
    immediate_cause_of_damage_pg: [],
    immediate_cause_of_damage_pg_reason: [],
    immediate_cause_of_damage_sec: [],
    immediate_cause_of_damage_sec_reason: [],
    basic_cause_personal_factory_group: [],
    basic_cause_personal_factory_group_reason: [],
    basic_cause_job_factory_group: [],
    basic_cause_job_factory_group_reason: [],
    incident_details_of_item: [],
    incident_damage_programme: [],
    incident_damage_programme_reason: [],
    incident_damage_performance_std: [],
    incident_damage_performance_std_reason: [],
    incident_damage_compliance_exit_std: [],
    incident_damage_compliance_exit_std_reason: [],
    incident_damage_machinery_breakdown: [],
    incident_damage_Loss_Severity: [],
    incident_damage_dpa_action: [],
    incident_damage_closed_out: [],
    injury_port_depature: [],
    injury_port_destination: [],
    injury_manual_code_ref: [],
    injury_probability_of_reoccurrence: [],
    nm_unsafe: [],
    nm_immediate_cause_primary: [],
    nm_immediate_cause_primary_reason: [],
    nm_immediate_cause_secondary: [],
    nm_immediate_cause_secondary_reason: [],
    nm_basic_cause_personal_factor: [],
    nm_basic_cause_personal_factor_reason: [],
    nm_basic_cause_job_factor: [],
    nm_basic_cause_job_factor_reason: []
};

export const incidentDropdownSlice = createSlice({
    name: 'incident_dropdown',
    initialState,
    reducers: ({

    }),
    extraReducers: (builder) => {
        builder
            .addCase(getImmediateCauseOfDamagePG.fulfilled, (state, action) => {
                state.immediate_cause_of_damage_pg = ValidateResponse(action.payload);
            })
            .addCase(getImmediateCauseOfDamagePGReason.fulfilled, (state, action) => {
                state.immediate_cause_of_damage_pg_reason = ValidateResponse(action.payload);
            })
            .addCase(getImmediateCauseOfDamageSec.fulfilled, (state, action) => {
                state.immediate_cause_of_damage_sec = ValidateResponse(action.payload);
            })
            .addCase(getImmediateCauseOfDamageSecReason.fulfilled, (state, action) => {
                state.immediate_cause_of_damage_sec_reason = ValidateResponse(action.payload);
            })
            .addCase(getBasicCausePersonalFactoryGroup.fulfilled, (state, action) => {
                state.basic_cause_personal_factory_group = ValidateResponse(action.payload);
            })
            .addCase(getBasicCausePersonalFactoryGroupReason.fulfilled, (state, action) => {
                state.basic_cause_personal_factory_group_reason = ValidateResponse(action.payload);
            })
            .addCase(getBasicCauseJobFactoryGroup.fulfilled, (state, action) => {
                state.basic_cause_job_factory_group = ValidateResponse(action.payload);
            })
            .addCase(getBasicCauseJobFactoryGroupReason.fulfilled, (state, action) => {
                state.basic_cause_job_factory_group_reason = ValidateResponse(action.payload);
            })
            .addCase(getIncidentDetailsOfItem.fulfilled, (state, action) => {
                state.incident_details_of_item = ValidateResponse(action.payload);
            })
            .addCase(getIncidentDamageProgramme.fulfilled, (state, action) => {
                state.incident_damage_programme = ValidateResponse(action.payload);
            })
            .addCase(getIncidentDamageProgrammeReason.fulfilled, (state, action) => {
                state.incident_damage_programme_reason = ValidateResponse(action.payload);
            })
            .addCase(getIncidentDamagePerformanceStd.fulfilled, (state, action) => {
                state.incident_damage_performance_std = ValidateResponse(action.payload);
            })
            .addCase(getIncidentDamagePerformanceStdReason.fulfilled, (state, action) => {
                state.incident_damage_performance_std_reason = ValidateResponse(action.payload);
            })
            .addCase(getIncidentDamageComplianceExitStd.fulfilled, (state, action) => {
                state.incident_damage_compliance_exit_std = ValidateResponse(action.payload);
            })
            .addCase(getIncidentDamageComplianceExitStdReason.fulfilled, (state, action) => {
                state.incident_damage_compliance_exit_std_reason = ValidateResponse(action.payload);
            })
            .addCase(getIncidentDamageMachineryBreakdown.fulfilled, (state, action) => {
                state.incident_damage_machinery_breakdown = ValidateResponse(action.payload);
            })
            .addCase(getIncidentDamageLossSeverity.fulfilled, (state, action) => {
                state.incident_damage_Loss_Severity = ValidateResponse(action.payload);
            })
            .addCase(getIncidentDamageDPA.fulfilled, (state, action) => {
                state.incident_damage_dpa_action = ValidateResponse(action.payload);
            })
            .addCase(getIncidentDamageClosedOut.fulfilled, (state, action) => {
                state.incident_damage_closed_out = ValidateResponse(action.payload);
            })
            .addCase(getInjuryPortDept.fulfilled, (state, action) => {
                state.injury_port_depature = ValidateResponse(action.payload);
            })
            .addCase(getInjuryPortDesig.fulfilled, (state, action) => {
                state.injury_port_destination = ValidateResponse(action.payload);
            })
            .addCase(getInjuryManualRef.fulfilled, (state, action) => {
                state.injury_manual_code_ref = ValidateResponse(action.payload);
            })
            .addCase(getInjuryProbability.fulfilled, (state, action) => {
                state.injury_probability_of_reoccurrence = ValidateResponse(action.payload)
            })

            .addCase(getNMUnsafe.fulfilled, (state, action) => {
                state.nm_unsafe = ValidateResponse(action.payload)
            })
            .addCase(getNMImmedidatePrim.fulfilled, (state, action) => {
                state.nm_immediate_cause_primary = ValidateResponse(action.payload)
            })
            .addCase(getNMImmedidatePrimReason.fulfilled, (state, action) => {
                state.nm_immediate_cause_primary_reason = ValidateResponse(action.payload)
            })
            .addCase(getNMImmedidateSec.fulfilled, (state, action) => {
                state.nm_immediate_cause_secondary = ValidateResponse(action.payload)
            })
            .addCase(getNMImmedidateSecReason.fulfilled, (state, action) => {
                state.nm_immediate_cause_secondary_reason = ValidateResponse(action.payload)
            })
            .addCase(getNMBasicPersFact.fulfilled, (state, action) => {
                state.nm_basic_cause_personal_factor = ValidateResponse(action.payload)
            })
            .addCase(getNMBasicPersFactReason.fulfilled, (state, action) => {
                state.nm_basic_cause_personal_factor_reason = ValidateResponse(action.payload)
            })
            .addCase(getNMBasicJobFact.fulfilled, (state, action) => {
                state.nm_basic_cause_job_factor = ValidateResponse(action.payload)
            })
            .addCase(getNMBasicJobFactReason.fulfilled, (state, action) => {
                state.nm_basic_cause_job_factor_reason = ValidateResponse(action.payload)
            })
    }
});

export default incidentDropdownSlice.reducer;
