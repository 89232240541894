import { createSlice } from '@reduxjs/toolkit'
function isPendingAction(action) {
  return action.type.endsWith('pending')
}
function isFulfilledAction(action) {
  return action.type.endsWith('fulfilled')
}
function isRejectedAction(action) {
  return action.type.endsWith('rejected')
}
const loaderSlice=createSlice({
  name: 'LOADER',
  initialState:{
    loading:false
  },  
  extraReducers: (builder) => {
    builder            
      .addMatcher(
        isPendingAction,        
        (state, action) => {state.loading=true}
      )      
      .addMatcher(
        isFulfilledAction,        
        (state, action) => {state.loading=false}
      )      
      .addMatcher(
        isRejectedAction,        
        (state, action) => {state.loading=false}
      )      
  },
})
export default loaderSlice.reducer