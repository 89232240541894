import { createAsyncThunk,createEntityAdapter, createSlice } from "@reduxjs/toolkit"
import { toast } from "react-toastify"
import { GET_DEPARTMENT, SAVE_DEPARTMENT } from "../constant/CommonApiUrl"
import { API_SERVICE } from "../Services/CommonApi"

const ADD_DEPARTMENT='department/addDepartmentApi'
const UPDATE_DEPARTMENT = 'department/udpateDepartmentApi'
const FETCH_DEPARTMENT='department/fetchDepartmentApi'
const DELETE_DEPARTMENT = 'department/deleteDepartmentApi'

export const addDepartmentApi =createAsyncThunk(ADD_DEPARTMENT,
    async(args, thunkAPI)=>{
        try{
            let response = await API_SERVICE.post(SAVE_DEPARTMENT, args['data'], "CREATE");
            response = response.data.result.data[0];
            return response;
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
})

export const updateDepartmentApi = createAsyncThunk(UPDATE_DEPARTMENT,
    async(args, thunkAPI)=>{
        try{
            let response = await API_SERVICE.post(SAVE_DEPARTMENT, args['data'], "UPDATE");
            response = response.data.result.data[0]
            return response;
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
})

export const deleteDepartmentApi = createAsyncThunk(DELETE_DEPARTMENT,
    async (args, thunkAPI) => {
        try{
            const toDelete = thunkAPI.getState().root.department.list.find(ele => ele.id === args['id']);   
            const response = await API_SERVICE.post(SAVE_DEPARTMENT, {...toDelete, isdisabled: "Y"});
            return response.data.result.data[0];
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
    }
)

export const fetchDepartmentApi = createAsyncThunk(FETCH_DEPARTMENT,
    async(arg,thunkAPI)=>{
        try{
            let response = await API_SERVICE.get(GET_DEPARTMENT);
            response = response.data.result.data;
            return response
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
    }/* ,
    {
        condition: (arg, { getState, extra }) => {          
          const isUpdated = getState().root.department.isUpdated                         
          if(!isUpdated){
            console.log('enable fetch condition in reducer')
            return false
          }
        },
      } */
)

const initialState={
    list:[],
    editdepartment:{},   
    isUpdated: true 
}

export const departmentSlice=createSlice({
    name:'department',
    initialState,
    reducers:{
        addDepartment:(state,action)=>{
            state.list=[...state.list,action.payload]
        },
        delDepartment:(state,action)=>{
            state.list=state.list.filter(x=>x.id!==action.payload)
        },
        loadEditDepartment:(state,action)=>{
            state.editdepartment=action.payload
        },
        clearEditDepartment:(state,action)=>{
            state.editdepartment={}
        },
        updateDepartment:(state,action)=>{
            let index=state.list.findIndex(x=>x.id===action.payload.id)
            state.list[index]=action.payload
        },               
        resetDepartment:()=>initialState
    },
    extraReducers:(builder)=>{
        builder
        .addCase(addDepartmentApi.fulfilled,(state,action)=>{
            state.list=[...state.list,action.payload];
            toast.success('Succesfully added');
        })
        .addCase(updateDepartmentApi.fulfilled,(state,action)=>{
            state.list = state.list.map(x => x.id === action.payload.id ? action.payload: x);
            toast.success('Succesfully updated');
        })
        .addCase(fetchDepartmentApi.fulfilled, (state, action) => {
            state.list = action.payload;
            state.isUpdated = false;
        })
        .addCase(deleteDepartmentApi.fulfilled, (state, action) => {
            state.list=state.list.filter(x=>x.id!==action.payload.id);
            toast.success('Succesfully deleted');
        })
    } 
})
export const {addDepartment,delDepartment,loadEditDepartment,clearEditDepartment,updateDepartment,resetDepartment}=departmentSlice.actions
export default departmentSlice.reducer