import { createSlice } from "@reduxjs/toolkit";
import { getCertificateReportGroup, getCertificateReportId, getPMSCertificateReport } from "./THUNK/pmsReportThunk";
import { ValidateResponse } from "../Services/Support";
import { ConvertJson } from "../helper";

const certificateReportSlice = createSlice({
    name: 'CERTIFICATE_REPORT',
    initialState: {
        certificate_list: [],
        certificate_group:[],
        certificate_detail:{}
    },
    reducers: {
        clearCertificateReport: (state, action) => {
            state.certificate_list = []
        }
    },
    extraReducers: (builder) =>
        builder
            .addCase(getPMSCertificateReport.fulfilled, (state, action) => {
                state.certificate_list = ValidateResponse(action.payload)
            })
            .addCase(getCertificateReportGroup.fulfilled, (state, action) => {
                state.certificate_group = ValidateResponse(action.payload)
            })
            .addCase(getCertificateReportId.fulfilled, (state, action) => {
                let result = ValidateResponse(action.payload)[0]
                result.history=result?.history?ConvertJson(result.history):[]
                state.certificate_detail=result
            })
})
export const { clearCertificateReport } = certificateReportSlice.actions
export default certificateReportSlice.reducer