import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_SERVICE } from "../Services/CommonApi";
import * as APIS from "../constant/CommonApiUrl"
import { toast } from "react-toastify";
import {getAuditInspection, SAFETY_INCIDENT_REPORTS} from "../constant/CommonApiUrl";
import {
    fetchAuditNcReportById,
    fetchInspectionNCReportById,
    fetchSafetyReports,
    fetchSafetyReportsById
} from "./THUNK/SafetyReportThunk";

// Define an initial state for your slice
const initialState = {
    data: [],
    loading: false,
    error: null,
};

// Define an asynchronous thunk action to fetch data from the API

export const fetchAuditInspectionSchedule = createAsyncThunk(
    'safetyReports/fetchAuditInspectionScheduleReports',
    async (type, thunkAPI) => {
        try {
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            return await API_SERVICE.get(`${APIS.SAFETY_AUDIT_INSPECTION_SCHEDULE_REPORTS}`, { ...type, vessel_id: vessel_name==="OFFICE"?0:id });
        } catch (error) {
            thunkAPI.rejectWithValue(error)
        }
    }
);
export const fetchIncidentReportSchedule = createAsyncThunk(
    'safetyReports/fetchIncidentReportSchedule',
    async (type, thunkAPI) => {
        try {
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            return await API_SERVICE.get(`${APIS.SAFETY_INCIDENT_REPORTS}`, { ...type, vessel_id: vessel_name==="OFFICE"?0:id });
        } catch (error) {
            thunkAPI.rejectWithValue(error)
        }
    }
);

// Create a slice with reducers and the async thunk
const safetyReportsSlice = createSlice({
    name: 'safetyReports',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchSafetyReports.fulfilled, (state, action) => {
                if (action.payload.data.result.status) {
                    state.data = action.payload.data.result.data
                } else {
                    toast.error(action.payload.data.result.message)
                }
            }).addCase(fetchSafetyReportsById.fulfilled, (state, action) => {
                if (action.payload.data.result.status) {
                    state.viewData = action.payload.data.result.data
                } else {
                    toast.error(action.payload.data.result.message)
                }
            }).addCase(fetchAuditNcReportById.fulfilled, (state, action) => {
                if (action.payload.data.result.status) {
                    state.viewData = action.payload.data.result.data
                } else {
                    toast.error(action.payload.data.result.message)
                }
            }).addCase(fetchInspectionNCReportById.fulfilled, (state, action) => {
                if (action.payload.data.result.status) {
                    state.viewData = action.payload.data.result.data
                } else {
                    toast.error(action.payload.data.result.message)
                }
            }).addCase(fetchAuditInspectionSchedule.fulfilled, (state, action) => {
                if (action.payload.data.result.status) {
                    state.loading = false;
                    state.data = action.payload.data.result.data
                } else {
                    toast.error(action.payload.data.result.message)
                }
            }).addCase(fetchIncidentReportSchedule.fulfilled, (state, action) => {
                if (action.payload.data.result.status) {
                    state.loading = false;
                    state.data = action.payload.data.result.data
                } else {
                    toast.error(action.payload.data.result.message)
                }
            })

    },
});

// Export the async thunk for use in components
export const { setReportsPage } = safetyReportsSlice.actions
export default safetyReportsSlice.reducer
