import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { toast } from "react-toastify"
import { GET_ROLE_ACCESS, SAVE_ROLE_ACCESS } from "../constant/CommonApiUrl";
import { API_SERVICE } from "../Services/CommonApi"

const ADD_ROLESACCESS = 'rolesAccess/addRolesAccessApi'
const UPDATE_ROLESACCESS = 'rolesAccesss/updateRolesAccessApi'
const FETCH_ROLESACCESS = 'rolesAccess/fetchRolesAccessApi'
const DELETE_ROLESACCESS = 'rolesAccess/deleteRolesAccessApi'

export const addRolesAccessApi =createAsyncThunk(ADD_ROLESACCESS,
    async(args, thunkAPI)=>{
        try{
            let response = await API_SERVICE.post(SAVE_ROLE_ACCESS, args['data'], "CREATE");
            response = response.data.result.data[0];
            return response;
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
})

export const updateRolesAccessApi = createAsyncThunk(UPDATE_ROLESACCESS,
    async(args, thunkAPI)=>{
        try{
            let response = await API_SERVICE.post(SAVE_ROLE_ACCESS, args['data'], "UPDATE");
          thunkAPI.dispatch(fetchRolesAccessApi())
            response = response.data.result.data[0]
            return response;
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
})

export const deleteRolesAccessApi = createAsyncThunk(DELETE_ROLESACCESS, 
    async (args, thunkAPI) => {
        try{
            const toDelete = thunkAPI.getState().root.rolesAccess.list.find(ele => ele.id === args['id']);   
            const response = await API_SERVICE.post(SAVE_ROLE_ACCESS, {...toDelete, isdisabled: "Y"});
            return response.data.result.data[0];
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
    }
)

export const fetchRolesAccessApi = createAsyncThunk(FETCH_ROLESACCESS,
    async(arg,thunkAPI)=>{
        try{
            let response = await API_SERVICE.get(GET_ROLE_ACCESS);
            response = response.data.result.data;
            return response
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
    }
)

const initialState={
    list:[],
    editRolesAccess:{},
    isUpdated: true
}

export const rolesAccessSlice=createSlice({
    name:'rolesAccess',
    initialState,
    reducers:{
        addRolesAccess:(state,action)=> {
            state.list=[...state.list,action.payload]
        },
        delRolesAccess:(state,action)=>{
            state.list=state.list.filter(x=>x.id!==action.payload)
        },
        loadEditRolesAccess:(state,action)=>{
            state.editRolesAccess=action.payload
        },
        clearEditRolesAccess:(state,action)=>{
            state.editRolesAccess={}
        },
        updateRolesAccess:(state,action)=>{
            let index=state.list.findIndex(x=>x.id===action.payload.id)
            state.list[index]=action.payload
        },
        resetRolesAccess:()=>initialState
    },
    extraReducers:(builder)=>{
        builder       
        .addCase(addRolesAccessApi.fulfilled,(state,action)=>{
            state.list=[...state.list,action.payload];
            toast.success('Successfully added');
        })
        .addCase(updateRolesAccessApi.fulfilled,(state,action)=>{
            state.list = state.list.map(x => x.id === action.payload.id ? action.payload: x);
            toast.success('Successfully updated');
        })
        .addCase(fetchRolesAccessApi.fulfilled, (state, action) => {
            state.list = action.payload;
            state.isUpdated = false;
        })
        .addCase(deleteRolesAccessApi.fulfilled, (state, action) => {
            state.list=state.list.filter(x=>x.id!==action.payload.id);
            toast.success('Successfully deleted');
        })        
    }        
})

export const {addRolesAccess,delRolesAccess,loadEditRolesAccess,clearEditRolesAccess,updateRolesAccess,resetRolesAccess} = rolesAccessSlice.actions
export default rolesAccessSlice.reducer