import { createAsyncThunk } from "@reduxjs/toolkit";
import * as ACTION from "../../constant/Actions";
import * as APIS from "../../constant/CommonApiUrl";
import { API_SERVICE } from "../../Services/CommonApi";
import axios from "axios";

export const getChecklist = createAsyncThunk(ACTION.GET_CHECKLIST,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(`${APIS.GET_CHECKLIST}?vessel_id=${vessel_name === 'OFFICE' ? 0 : id}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getChecklistbyId = createAsyncThunk(ACTION.GET_CHECKLIST_ID,
    async (arg, thunkAPI) => {
        try {
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(`${APIS.GET_CHECKLIST}?id=${arg}&vessel_id=${id}`)
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const saveCheckList = createAsyncThunk(ACTION.SAVE_CHECKLIST,
    async (arg, thunkApi) => {
        try {
            const { id, vessel_name } = thunkApi.getState().root.common.vessel_detailByID
            return await API_SERVICE.post(APIS.SAVE_CHECKLIST, arg)
        } catch (err) {
            thunkApi.rejectWithValue(err)
        }
    })
export const getAuditMasterList = createAsyncThunk(ACTION.GET_AUDIT,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(arg ? `${APIS.GET_AUDIT}?${arg}&vessel_id=${id}` : `${APIS.GET_AUDIT}?vessel_id=${id}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getInspectionMasterList = createAsyncThunk(ACTION.GET_INSPECTION,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            const payload = arg?.searchObj ? { vessel_id: id, ...arg.searchObj } : { vessel_id: id }
            return await API_SERVICE.get(`${APIS.GET_INSPECTION}`, payload, "", { cancelToken: source.token })
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getAuditMasterbyId = createAsyncThunk(ACTION.GET_AUDIT_MASTER_ID,
    async (arg, thunkAPI) => {
        try {
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(`${APIS.GET_AUDIT}?id=${arg}&vessel_id=${id}`)
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getInspectionMasterById = createAsyncThunk(ACTION.GET_INSPECTION_MASTER_ID,
    async (arg, thunkAPI) => {
        try {
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            return await API_SERVICE.get(`${APIS.GET_INSPECTION}`, { id: arg, vessel_id: id })
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const saveAuditMaster = createAsyncThunk(ACTION.SAVE_AUDIT_MASTER,
    async (arg, thunkApi) => {
        try {
            const { id, vessel_name } = thunkApi.getState().root.common.vessel_detailByID
            return await API_SERVICE.post(APIS.SAVE_AUDIT_MASTER, arg)
        } catch (err) {
            thunkApi.rejectWithValue(err)
        }
    })
export const saveInspectionMaster = createAsyncThunk(ACTION.SAVE_INSPECTION_MASTER,
    async (arg, thunkApi) => {
        try {
            const { id, vessel_name } = thunkApi.getState().root.common.vessel_detailByID
            return await API_SERVICE.post(APIS.SAVE_INSPECTION_MASTER, arg)
        } catch (err) {
            thunkApi.rejectWithValue(err)
        }
    })
export const getCategory = createAsyncThunk(ACTION.GET_CATEGORY,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(`${APIS.GET_CATEGORY}?category=${arg}&vessel_id=${id}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getCategorybyId = createAsyncThunk(ACTION.GET_CATEGORY_ID,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(`${APIS.GET_CATEGORY}?category=${arg.category}&id=${arg.id}&vessel_id=${id}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const saveCategory = createAsyncThunk(ACTION.SAVE_CATEGORY,
    async (arg, thunkApi) => {
        try {
            const { id, vessel_name } = thunkApi.getState().root.common.vessel_detailByID
            return await API_SERVICE.post(APIS.SAVE_CATEGORY, arg)
        } catch (err) {
            thunkApi.rejectWithValue(err)
        }
    })
export const getSubCategory = createAsyncThunk(ACTION.GET_SUB_CATEGORY,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(`${APIS.GET_SUB_CATEGORY}?vessel_id=${id}&category=${arg}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getSubCategorybyId = createAsyncThunk(ACTION.GET_SUB_CATEGORY_ID,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(`${APIS.GET_SUB_CATEGORY}?category=${arg.category}&id=${arg.id}&vessel_id=${id}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const saveSubCategory = createAsyncThunk(ACTION.SAVE_SUB_CATEGORY,
    async (arg, thunkApi) => {
        try {
            const { id, vessel_name } = thunkApi.getState().root.common.vessel_detailByID
            return await API_SERVICE.post(APIS.SAVE_SUB_CATEGORY, arg)
        } catch (err) {
            thunkApi.rejectWithValue(err)
        }
    })
export const getISM = createAsyncThunk(ACTION.GET_ISM,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(`${APIS.GET_ISM}?vessel_id=${id}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getISMbyId = createAsyncThunk(ACTION.GET_ISM_ID,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(`${APIS.GET_ISM}?id=${arg}&vessel_id=${id}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const saveISM = createAsyncThunk(ACTION.SAVE_ISM,
    async (arg, thunkApi) => {
        try {
            const { id, vessel_name } = thunkApi.getState().root.common.vessel_detailByID
            return await API_SERVICE.post(APIS.SAVE_ISM, arg)
        } catch (err) {
            thunkApi.rejectWithValue(err)
        }
    })
export const getNCType = createAsyncThunk(ACTION.GET_NC_TYPE,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(`${APIS.GET_NC_TYPE}?vessel_id=${id}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getNCTypebyId = createAsyncThunk(ACTION.GET_NC_TYPE_ID,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(`${APIS.GET_NC_TYPE}?id=${arg}&vessel_id=${id}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const saveNCType = createAsyncThunk(ACTION.SAVE_NC_TYPE,
    async (arg, thunkApi) => {
        try {
            const { id, vessel_name } = thunkApi.getState().root.common.vessel_detailByID
            return await API_SERVICE.post(APIS.SAVE_NC_TYPE, arg)
        } catch (err) {
            thunkApi.rejectWithValue(err)
        }
    })
export const getNCcategoryList = createAsyncThunk(ACTION.GET_NC_CATEGORY,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(`${APIS.GET_NC_CATEGORY}?vessel_id=${id}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getNCcategorybyId = createAsyncThunk(ACTION.GET_NC_CATEGORY_ID,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(`${APIS.GET_NC_CATEGORY}?id=${arg}&vessel_id=${id}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const saveNCcategory = createAsyncThunk(ACTION.SAVE_NC_CATEGORY,
    async (arg, thunkApi) => {
        try {
            const { id, vessel_name } = thunkApi.getState().root.common.vessel_detailByID
            return await API_SERVICE.post(APIS.SAVE_NC_CATEGORY, arg)
        } catch (err) {
            thunkApi.rejectWithValue(err)
        }
    })
export const getSafetyStatus = createAsyncThunk(ACTION.SAFETY_STATUS_CONDITION,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(`${APIS.SAFETY_STATUS_CONDITION}&vessel_id=${id}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getSafetyColor = createAsyncThunk(ACTION.STATUS_COLOR,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(`${APIS.STATUS_COLOR}&vessel_id=${id}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getSafetyConditionList = createAsyncThunk(ACTION.GET_STATUS_CONDITION,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(`${APIS.GET_STATUS_CONDITION}?vessel_id=${id}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getSafetyConditionbyId = createAsyncThunk(ACTION.GET_STATUS_CONDITION_ID,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(`${APIS.GET_STATUS_CONDITION}?id=${arg}&vessel_id=${id}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const saveSafetyCondition = createAsyncThunk(ACTION.SAFETY_SAVE_CONDITION,
    async (arg, thunkApi) => {
        try {
            const { id, vessel_name } = thunkApi.getState().root.common.vessel_detailByID
            return await API_SERVICE.post(APIS.SAFETY_SAVE_CONDITION, arg)
        } catch (err) {
            thunkApi.rejectWithValue(err)
        }
    })
export const getAuditDropdown = createAsyncThunk(ACTION.SAFETY_AUDIT_DROPDOWN,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(`${APIS.SAFETY_AUDIT_DROPDOWN}?vessel_id=${id}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getInspectionDropdown = createAsyncThunk(ACTION.SAFETY_INSPECTION_DROPDOWN,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(`${APIS.SAFETY_INSPECTION_DROPDOWN}?vessel_id=${id}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getAuditTypeDropdown = createAsyncThunk(ACTION.AUDIT_TYPE_DROPDOWN,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(`${APIS.AUDIT_TYPE_DROPDOWN}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getInspectionTypeDropdown = createAsyncThunk(ACTION.INSPECTION_TYPE_DROPDOWN,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(`${APIS.INSPECTION_TYPE_DROPDOWN}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const saveSafetyChecklistGroup = createAsyncThunk(ACTION.SAVE_CHECKLIST_GROUP,
    async (arg, thunkApi) => {
        try {
            const { id, vessel_name } = thunkApi.getState().root.common.vessel_detailByID
            return await API_SERVICE.post(APIS.SAVE_CHECKLIST_GROUP, arg)
        } catch (err) {
            thunkApi.rejectWithValue(err)
        }
    })
export const getChecklistTransaction = createAsyncThunk(ACTION.GET_CHECKLIST_TRANSACTION,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(arg ? `${APIS.GET_CHECKLIST_TRANSACTION}?vessel_id=${vessel_name === 'OFFICE' ? 0 : id}&${arg}` : `${APIS.GET_CHECKLIST_TRANSACTION}?vessel_id=${vessel_name === 'OFFICE' ? 0 : id}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getChecklistTransactionbyId = createAsyncThunk(ACTION.GET_CHECKLIST_TRANSACTION_ID,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(`${APIS.GET_CHECKLIST_TRANSACTION}?id=${arg}&vessel_id=${vessel_name==='OFFICE'?0:id}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const saveChecklistTransaction = createAsyncThunk(ACTION.SAVE_CHECKLIST_TRANSACTION,
    async (arg, thunkApi) => {
        try {
            const { id, vessel_name } = thunkApi.getState().root.common.vessel_detailByID
            return await API_SERVICE.post(APIS.SAVE_CHECKLIST_TRANSACTION, arg)
        } catch (err) {
            thunkApi.rejectWithValue(err)
        }
    })
export const autoSearchChecklistGroup = createAsyncThunk(ACTION.CHECKLIST_AUTOSEARCH,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(`${APIS.CHECKLIST_AUTOSEARCH}?vessel_id=${id}&keyword=${arg}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getChecklistBaseAuditId = createAsyncThunk(ACTION.CHECKLIST_BASE_AUDIT,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(`${APIS.CHECKLIST_BASE_AUDIT}?vessel_id=${id}&${arg}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getDataBasedChecklist = createAsyncThunk(ACTION.GET_DATA_BASED_CHECKLIST,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(`${APIS.GET_DATA_BASED_CHECKLIST}?${arg}&vessel_id=${vessel_name === 'OFFICE' ? 0 : id}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getChecklistGroupbyId = createAsyncThunk(ACTION.GET_CHECKLIST_GROUP_ID,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(`${APIS.GET_CHECKLIST_GROUP}?vessel_id=${id}&id=${arg}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getChecklistGroup = createAsyncThunk(ACTION.GET_CHECKLIST_GROUP,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(`${APIS.GET_CHECKLIST_GROUP}?vessel_id=${id}&${arg}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getAuditScheduleList = createAsyncThunk(ACTION.GET_AUDIT_SCHEDULE,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(arg ? `${APIS.GET_AUDIT_SCHEDULE}?vessel_id=${vessel_name === 'OFFICE' ? 0 : id}&${arg}` : `${APIS.GET_AUDIT_SCHEDULE}?vessel_id=${vessel_name === 'OFFICE' ? 0 : id}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getAuditSchedulebyId = createAsyncThunk(ACTION.GET_AUDIT_SCHEDULE_ID,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(`${APIS.GET_AUDIT_SCHEDULE}?id=${arg}&vessel_id=${id}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const saveAuditSchedule = createAsyncThunk(ACTION.SAVE_AUDIT_SCHEDULE,
    async (arg, thunkApi) => {
        try {
            const { id, vessel_name } = thunkApi.getState().root.common.vessel_detailByID
            return await API_SERVICE.post(APIS.SAVE_AUDIT_SCHEDULE, arg)
        } catch (err) {
            thunkApi.rejectWithValue(err)
        }
    })
export const getAuditCompletionList = createAsyncThunk(ACTION.GET_AUDIT_COMPLETION,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(arg ? `${APIS.GET_AUDIT_COMPLETION}?vessel_id=${id}&${arg}` : `${APIS.GET_AUDIT_COMPLETION}?vessel_id=${id}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getAuditCompletionbyId = createAsyncThunk(ACTION.GET_AUDIT_COMPLETION_ID,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(`${APIS.GET_AUDIT_COMPLETION}?id=${arg}&vessel_id=${id}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const saveAuditCompletion = createAsyncThunk(ACTION.SAVE_AUDIT_COMPLETION,
    async (arg, thunkApi) => {
        try {
            const { id, vessel_name } = thunkApi.getState().root.common.vessel_detailByID
            return await API_SERVICE.post(APIS.SAVE_AUDIT_COMPLETION, arg)
        } catch (err) {
            thunkApi.rejectWithValue(err)
        }
    })
export const getVesselBasedSchedule = createAsyncThunk(ACTION.GET_AUDIT_VESSEL_SCHEDULE,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(arg ? `${APIS.GET_AUDIT_VESSEL_SCHEDULE}?vessel_id=${vessel_name === 'OFFICE' ? 0 : id}&${arg}` : `${APIS.GET_AUDIT_VESSEL_SCHEDULE}?vessel_id=${vessel_name === 'OFFICE' ? 0 : id}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getInspectionVesselBasedSchedule = createAsyncThunk(ACTION.GET_INSPECTION_VESSEL_SCHEDULE,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(arg ? `${APIS.GET_INSPECTION_VESSEL_SCHEDULE}?vessel_id=${vessel_name === 'OFFICE' ? 0 : id}&${arg}` : `${APIS.GET_INSPECTION_VESSEL_SCHEDULE}?vessel_id=${vessel_name === 'OFFICE' ? 0 : id}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getInspectionChecklistTransaction = createAsyncThunk(ACTION.GET_CHECKLIST_INSPECTION,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(arg ? `${APIS.GET_CHECKLIST_INSPECTION}?vessel_id=${vessel_name === 'OFFICE' ? 0 : id}&${arg}` : `${APIS.GET_CHECKLIST_INSPECTION}?vessel_id=${vessel_name === 'OFFICE' ? 0 : id}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getInspectionChecklistTransactionbyId = createAsyncThunk(ACTION.GET_CHECKLIST_ID_INSPECTION,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(`${APIS.GET_CHECKLIST_INSPECTION}?id=${arg}&vessel_id=${id}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const saveInspectionChecklistTransaction = createAsyncThunk(ACTION.SAVE_CHECKLIST_INSPECTION,
    async (arg, thunkApi) => {
        try {
            const { id, vessel_name } = thunkApi.getState().root.common.vessel_detailByID
            return await API_SERVICE.post(APIS.SAVE_CHECKLIST_INSPECTION, arg)
        } catch (err) {
            thunkApi.rejectWithValue(err)
        }
    })
export const getInspectionScheduleList = createAsyncThunk(ACTION.GET_INSPECTION_SCHEDULE,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(arg ? `${APIS.GET_INSPECTION_SCHEDULE}?vessel_id=${vessel_name === 'OFFICE' ? 0 : id}&${arg}` : `${APIS.GET_INSPECTION_SCHEDULE}?vessel_id=${vessel_name === 'OFFICE' ? 0 : id}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getInspectionSchedulebyId = createAsyncThunk(ACTION.GET_INSPECTION_SCHEDULE_ID,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(`${APIS.GET_INSPECTION_SCHEDULE}?id=${arg}&vessel_id=${id}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const saveInspectionSchedule = createAsyncThunk(ACTION.SAVE_INSPECTION_SCHEDULE,
    async (arg, thunkApi) => {
        try {
            const { id, vessel_name } = thunkApi.getState().root.common.vessel_detailByID
            return await API_SERVICE.post(APIS.SAVE_INSPECTION_SCHEDULE, arg)
        } catch (err) {
            thunkApi.rejectWithValue(err)
        }
    })
export const getInspectionCompletionList = createAsyncThunk(ACTION.GET_INSPECTION_COMPLETION,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(arg ? `${APIS.GET_INSPECTION_COMPLETION}?vessel_id=${id}&${arg}` : `${APIS.GET_INSPECTION_COMPLETION}?vessel_id=${id}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getInspectionCompletionbyId = createAsyncThunk(ACTION.GET_INSPECTION_COMPLETION_ID,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(`${APIS.GET_INSPECTION_COMPLETION}?id=${arg}&vessel_id=${id}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const saveInspectionCompletion = createAsyncThunk(ACTION.SAVE_INSPECTION_COMPLETION,
    async (arg, thunkApi) => {
        try {
            const { id, vessel_name } = thunkApi.getState().root.common.vessel_detailByID
            return await API_SERVICE.post(APIS.SAVE_INSPECTION_COMPLETION, arg)
        } catch (err) {
            thunkApi.rejectWithValue(err)
        }
    })
export const saveAuditRecord = createAsyncThunk(ACTION.SAVE_AUDIT_RECORD,
    async (arg, thunkApi) => {
        try {
            const { id, vessel_name } = thunkApi.getState().root.common.vessel_detailByID
            return await API_SERVICE.post(APIS.SAVE_AUDIT_RECORD, arg)
        } catch (err) {
            thunkApi.rejectWithValue(err)
        }
    })
export const getAuditRecordList = createAsyncThunk(ACTION.GET_AUDIT_RECORD_LIST,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(arg ? `${APIS.GET_AUDIT_RECORD_LIST}?vessel_id=${vessel_name==='OFFICE'?0:id}&${arg}` : `${APIS.GET_AUDIT_RECORD_LIST}?vessel_id=${vessel_name==='OFFICE'?0:id}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getAuditRecordById = createAsyncThunk(ACTION.GET_AUDIT_RECORD_ID,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(`${APIS.GET_AUDIT_RECORD_LIST}?vessel_id=${id}&id=${arg}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const saveNotesFollowup = createAsyncThunk(ACTION.SAVE_NOTES_FOLLOWUP,
    async (arg, thunkApi) => {
        try {
            const { id, vessel_name } = thunkApi.getState().root.common.vessel_detailByID
            return await API_SERVICE.post(APIS.SAVE_NOTES_FOLLOWUP, arg)
        } catch (err) {
            thunkApi.rejectWithValue(err)
        }
    })
export const getNotesFollowup = createAsyncThunk(ACTION.GET_NOTES_FOLLOWUP,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(arg ? `${APIS.GET_NOTES_FOLLOWUP}?vessel_id=${id}&${arg}` : `${APIS.GET_NOTES_FOLLOWUP}?vessel_id=${id}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })