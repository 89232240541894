import React from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import RoutesControl from "./router";
import { useState } from "react";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import 'react-loading-skeleton/dist/skeleton.css'
import { GoCheck } from "react-icons/go";
/* import * as themes from './theme/schema.json';
import { setToLS } from './helper/storage'; */
//https://theme-builder.netlify.app/
//https://github.com/atapas/theme-builder
function App() {
  const [theme, setTheme] = useState("default")
  /* setToLS('all-themes', themes.default);
  ; 
    const toggleTheme = event => {
    const btn_color = event.currentTarget.id;
    if (btn_color === "green_btn") { setTheme("firstTheme") }
    if (btn_color === "red_btn") { setTheme("secondTheme") }
    if (btn_color === "blue_btn") { setTheme("thirdTheme") }
    if (btn_color === "voilet_btn") { setTheme("fourthTheme") }
  };
*/
  //https://blog.logrocket.com/complete-guide-authentication-with-react-router-v6/
  return (
    <>
      <ToastContainer
        position="bottom-left"
        autoClose={700}
        pauseOnHover
        theme='colored'
        limit={1}
        icon={<GoCheck />}
      />            
      <div className="App" data-theme={theme}>
        <RoutesControl />
        {/* <div className="button-container">
          <button onClick={toggleTheme} id="green_btn" data-color="firstTheme"></button>
          <button onClick={toggleTheme} id="red_btn" data-color="secondTheme"></button>
          <button onClick={toggleTheme} id="blue_btn" data-color="thirdTheme"></button>
          <button onClick={toggleTheme} id="voilet_btn" data-color="fourthTheme"></button>
        </div> */}
      </div>
    </>
  )
}

export default App;
