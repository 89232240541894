import { createSlice } from "@reduxjs/toolkit";
import { INITIAL_VALUES } from "../constant/CommonInitialvalue";
import themesSchema from '../theme/schema.json'
const theme=localStorage.getItem('theme')!==null?JSON.parse(localStorage.getItem('theme')):INITIAL_VALUES.defaultTheme
const initialState={
    allThemes:themesSchema,
    selectedTheme:theme
}
export const themeSlice=createSlice({
    name:'theme',
    initialState,
    reducers:{
        setTheme:(state,action)=>{
            state.selectedTheme=action.payload
            localStorage.setItem('theme',JSON.stringify(action.payload))
        },
    }
})
export const {setTheme}=themeSlice.actions
export default themeSlice.reducer