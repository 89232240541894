import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit"
import { API_SERVICE } from "../Services/CommonApi";
import * as APIS from "../constant/CommonApiUrl"
import * as ACTIONS from "../constant/Actions";
import { toast } from "react-toastify";
import { changeItemModalRFQ, ConvertJson } from "../helper/storage";

const initialState = {
    rfqList: [],
    rfqAllList: [],
    rfqAllList_backup: [],
    rfqDetails: [],
    supplierList: [],
    RFQ_PR_List: [],
    RFQ_PR_List_backup: [],
    selectedApprovedItem: [],
    purchaseReqItemModelState: [],
    split_list: [],
    split_list_backup: [],
    rfqQUOTdetail: {},
    supplierDropdown: [],
    editrfqQCid: '',
    isUpdated: false,
    isEditDone: false,
    successStatus: 0,
    isDeleteQuotaion: false,
    approvedStatus: 0,
    quoteStatusCheck: {}
}
export const fetchRFQList = createAsyncThunk(ACTIONS.FETCH_RFQ_LIST,
    async (arg, thunkAPI) => {
        try {
            return API_SERVICE.get(APIS.GET_ALL_REQ)
        } catch (err) {
            return thunkAPI.rejectWithValue(err)
        }
    }
)
export const fetchSupplier = createAsyncThunk(ACTIONS.FETCH_SUPPLIER_LIST,
    async (arg, thunkAPI) => {
        try {
            return API_SERVICE.get(APIS.GET_SUPPLIER)
        } catch (err) {
            return thunkAPI.rejectWithValue(err)
        }
    }
)
export const saveRFQ = createAsyncThunk(ACTIONS.RFQ_SAVE_ACTION,
    async (arg, thunkAPI) => {
        try {
            return API_SERVICE.post(APIS.SAVE_REQ, arg)
        } catch (err) {
            return thunkAPI.rejectWithValue(err)
        }
    }
)
export const deleteRFQ = createAsyncThunk(ACTIONS.RFQ_DELETE_ACTION,
    async (arg, thunkAPI) => {
        try {
            return API_SERVICE.post(APIS.SAVE_REQ, arg)
        } catch (err) {
            return thunkAPI.rejectWithValue(err)
        }
    }
)
export const saveRFQquotation = createAsyncThunk(ACTIONS.SAVE_RFQ_QUOTATION,
    async (arg, thunkAPI) => {
        try {
            return API_SERVICE.post(APIS.SAVE_QUOTATION_DETAIL, arg)
        } catch (err) {
            return thunkAPI.rejectWithValue(err)
        }
    }
)
export const deleteRFQquotation = createAsyncThunk(ACTIONS.DELETE_RFQ_QUOTATION,
    async (arg, thunkAPI) => {
        try {
            thunkAPI.dispatch(getAllRFQList())
            return API_SERVICE.post(APIS.SAVE_QUOTATION_DETAIL, arg)
        } catch (err) {
            return thunkAPI.rejectWithValue(err)
        }
    }
)
export const getRFQList = createAsyncThunk(ACTIONS.RFQ_LIST_ACTION,
    async (arg, thunkAPI) => {
        try {
            return API_SERVICE.get(APIS.GET_ALL_REQ)
        } catch (err) {
            return thunkAPI.rejectWithValue(err)
        }
    }
)
export const getAllRFQList = createAsyncThunk(ACTIONS.RFQ_ALL_LIST_ACTION,
    async (arg, thunkAPI) => {
        try {
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            return API_SERVICE.get(arg ? `${APIS.GET_ALL_REQ_LIST}?status=${arg}&vessel_id=${vessel_name === 'OFFICE' ? 0 : id}` : `${APIS.GET_ALL_REQ_LIST}?vessel_id=${vessel_name === 'OFFICE' ? 0 : id}`)
        } catch (err) {
            return thunkAPI.rejectWithValue(err)
        }
    }
)
export const filterRFQList = createAsyncThunk(ACTIONS.RFQ_FILTER_LIST_ACTION,
    async (arg, thunkAPI) => {
        try {
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            return API_SERVICE.get(arg ? `${APIS.GET_ALL_REQ_LIST}?${arg}&vessel_id=${vessel_name === 'OFFICE' ? 0 : id}` : `${APIS.GET_ALL_REQ_LIST}?vessel_id=${vessel_name === 'OFFICE' ? 0 : id}`)
        } catch (err) {
            return thunkAPI.rejectWithValue(err)
        }
    }
)
export const getSplitRFQList = createAsyncThunk('RFQ/splitList',
    async (arg, thunkAPI) => {
        try {
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            return API_SERVICE.get(`${APIS.GET_ALL_REQ_LIST}?status=${arg}&is_quote_split=1&vessel_id=${vessel_name === 'OFFICE' ? 0 : id}`)
        } catch (err) {
            return thunkAPI.rejectWithValue(err)
        }
    }
)
export const getRFQ = createAsyncThunk(ACTIONS.RFQ_GET_ACTION,
    async (arg, thunkAPI) => {
        try {
            return API_SERVICE.get(`${APIS.GET_REQ}?id=${arg}`)
        } catch (err) {
            return thunkAPI.rejectWithValue(err)
        }
    }
)
export const getRFQquotaionDetail = createAsyncThunk(ACTIONS.RFQ_GET_QUOTAION_DETAIL,
    async (arg, thunkAPI) => {
        try {
            return API_SERVICE.get(`${APIS.GET_REQ}?id=${arg}`)
        } catch (err) {
            return thunkAPI.rejectWithValue(err)
        }
    }
)
export const getSupplierDropdown = createAsyncThunk(ACTIONS.SUPPLIER_DROPDOWN,
    async (arg, thunkAPI) => {
        try {
            return API_SERVICE.get(`${APIS.GET_SUPPLIER_DROPDOWN}?id=${arg}`)
        } catch (err) {
            return thunkAPI.rejectWithValue(err)
        }
    }
)
export const viewQuotationDetails = createAsyncThunk(ACTIONS.VIEW_RFQ_QUOT,
    async (arg, thunkAPI) => {
        try {
            return API_SERVICE.get(`${APIS.GET_PURCHASE_QUO_DETAIL}?id=${arg}`)
        } catch (err) {
            return thunkAPI.rejectWithValue(err)
        }
    }
)
export const fetchPurchaseRequisitionList = createAsyncThunk(ACTIONS.GET_RFQ_PR_LIST,
    async (arg, thunkAPI) => {
        try {
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            return API_SERVICE.get(arg ? `${APIS.GET_PURCHASE_REQUISITION_LIST}?status=${arg}&vessel_id=${vessel_name === 'OFFICE' ? 0 : id}` : `${APIS.GET_PURCHASE_REQUISITION_LIST}?vessel_id=${vessel_name === 'OFFICE' ? 0 : id}`)
        } catch (err) {
            return thunkAPI.rejectWithValue(err)
        }
    }
)

export const approveRFQ = createAsyncThunk(ACTIONS.RFQ_APPROVE_ACTION,
    async (arg, thunkAPI) => {
        try {
            const response = API_SERVICE.post(APIS.APPROVE_REQ, arg)
            thunkAPI.dispatch(getAllRFQList())
            return response
        } catch (err) {
            return thunkAPI.rejectWithValue(err)
        }
    }
)
export const getDataForQuoteStatusCheck = createAsyncThunk(ACTIONS.RFQ_GET_QUOTATION_STATUS,
    async (arg, thunkAPI) => {
        try {
            return API_SERVICE.get(`${APIS.GET_QUOTATION_STATUS_CHECK}?${arg}`)
        } catch (err) {
            return thunkAPI.rejectWithValue(err)
        }
    }
)

export const RFQSlice = createSlice({
    name: 'RFQ',
    initialState,
    reducers: {
        setPurchaseData: (state, action) => {
            if (state.selectedApprovedItem.every(x => x.id !== action.payload.id)) {
                state.selectedApprovedItem = [...state.selectedApprovedItem, action.payload]
            }
            else if (state.selectedApprovedItem.some(x => x.id === action.payload.id)) {
                state.selectedApprovedItem = state.selectedApprovedItem.filter(x => x.id !== action.payload.id)
            }
            else {
                return state
            }
        },
        setPrice: (state, action) => {
            state.purchaseReqItemModelState = state.purchaseReqItemModelState.map((x) => x.Did === action.payload.Did ? action.payload : x)
        },
        setDeleteQuotat: (state, action) => {
            state.isDeleteQuotaion = !state.isDeleteQuotaion
        },
        searchRFQList: (state, action) => {
            switch (action.payload.type) {
                case 'quot':
                    if (action.payload !== '') {
                        let searchResult = []
                        state.rfqAllList_backup.map(el => ((el.pr_name.toLowerCase().search(action.payload.term.toLowerCase()) !== -1) || (el.RFQNo.toLowerCase().search(action.payload.term.toLowerCase()) !== -1)) && searchResult.push(el))
                        state.rfqAllList = searchResult
                    } else {
                        state.rfqAllList = state.rfqAllList_backup
                    }
                    break;
                case 'rfq':
                    if (action.payload !== '') {
                        let searchResult = []
                        state.RFQ_PR_List_backup.map(el => ((el.pr_name.toLowerCase().search(action.payload.term.toLowerCase()) !== -1) || (el.pr_id.toLowerCase().search(action.payload.term.toLowerCase()) !== -1)) && searchResult.push(el))
                        state.RFQ_PR_List = searchResult
                    } else {
                        state.RFQ_PR_List = state.RFQ_PR_List_backup
                    }
                    break;
                case 'QC':
                    if (action.payload !== '') {
                        let searchResult = []
                        state.rfqAllList_backup.map(el => ((el.pr_name.toLowerCase().search(action.payload.term.toLowerCase()) !== -1) || (el.RFQNo.toLowerCase().search(action.payload.term.toLowerCase()) !== -1)) && searchResult.push(el))
                        state.rfqAllList = searchResult
                    } else {
                        state.rfqAllList = state.rfqAllList_backup
                    }
                    break;
                case 'splitQC':
                    if (action.payload !== '') {
                        let searchResult = []
                        state.split_list_backup.map(el => ((el.pr_name.toLowerCase().search(action.payload.term.toLowerCase()) !== -1) || (el.RFQNo.toLowerCase().search(action.payload.term.toLowerCase()) !== -1)) && searchResult.push(el))
                        state.split_list = searchResult
                    } else {
                        state.split_list = state.split_list_backup
                    }
                    break;
                default:
                    return null
            }
        },
        clearSelectedItem: (state, action) => {
            state.selectedApprovedItem = []
            state.purchaseReqItemModelState = []
            state.successStatus = 0
        },
        clearQuotEditID: (state, action) => {
            state.editrfqQCid = ''
            state.isEditDone = false
        },
        clearAfterApproveRfq: (state, action) => {
            state.rfqDetails = []
            state.approvedStatus = 0
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(`${ACTIONS.FETCH_RFQ_LIST}/fulfilled`, (state, action) => {
                state.isLoading = false
                if (action.payload.data.result.status) {
                    state.rfqList = action.payload.data.result.data
                    state.isUpdated = true
                }
                if (action.payload.data.isFaulted) {
                    console.log(action.payload.data.result.message);
                }
            })
            .addCase(getAllRFQList.fulfilled, (state, action) => {
                state.isLoading = false
                if (action.payload.data.result.status) {
                    state.rfqAllList = action.payload.data.result.data
                    state.rfqAllList_backup = action.payload.data.result.data
                    state.isUpdated = true
                }
                if (action.payload.data.isFaulted) {
                    console.log(action.payload.data.result.message);
                }
            })
            .addCase(filterRFQList.fulfilled, (state, action) => {
                state.isLoading = false
                if (action.payload.data.result.status) {
                    state.rfqAllList = action.payload.data.result.data
                    state.isUpdated = true
                }
                if (action.payload.data.isFaulted) {
                    console.log(action.payload.data.result.message);
                }
            })
            .addCase(fetchSupplier.fulfilled, (state, action) => {
                state.isLoading = false
                if (action.payload.data.result.status) {
                    state.supplierList = action.payload.data.result.data
                    // state.isUpdated = true
                }
                if (action.payload.data.isFaulted) {
                    console.log(action.payload.data.result.message);
                }
            }).addCase(saveRFQ.fulfilled, (state, action) => {
                state.isLoading = false
                if (action.payload.data.result.status) {
                    toast.success('RFQ saved successfully')
                    // debugger // eslint-disable-line
                    state.successStatus = action.payload.data.result.messageCode
                }
                if (action.payload.data.isFaulted) {
                    console.log(action.payload.data.result.message);
                }
            }).addCase(deleteRFQ.fulfilled, (state, action) => {
                state.isLoading = false
                if (action.payload.data.result.status) {
                    toast.success('RFQ delete successfully')
                    state.successStatus = action.payload.data.result.messageCode
                }
                if (action.payload.data.isFaulted) {
                    console.log(action.payload.data.result.message);
                }
            }).addCase(getRFQ.fulfilled, (state, action) => {
                state.isLoading = false
                if (action.payload.data.result.status) {
                    const payload = action.payload.data.result.data[0]
                    // debugger // eslint-disable-line
                    let convertData = ConvertJson(action.payload.data.result.data[0].supplier_id)
                    let purchaseItems = ConvertJson(action.payload.data.result.data[0].purchaseReqItemModel)
                    Object.assign(payload, { supplier_id: convertData, purchaseReqItemModel: purchaseItems })
                    // debugger // eslint-disable-line
                    state.rfqDetails = payload
                }
                if (action.payload.data.isFaulted) {
                    console.log(action.payload.data.result.message);
                }
            }).addCase(fetchPurchaseRequisitionList.fulfilled, (state, action) => {
                state.isLoading = false
                if (action.payload.data.result.status) {
                    state.RFQ_PR_List = action.payload.data.result.data
                    state.RFQ_PR_List_backup = action.payload.data.result.data
                }
                if (action.payload.data.isFaulted) {
                    console.log(action.payload.data.result.message);
                }
            }).addCase(getRFQquotaionDetail.fulfilled, (state, action) => {
                state.isLoading = false
                if (action.payload.data.result.status) {
                    const payload = action.payload.data.result.data[0]
                    if (action.payload.data.result.data[0].purchaseReqItemModel) {
                        let convertData = ConvertJson(action.payload.data.result.data[0].purchaseReqItemModel)
                        payload.purchaseReqItemModel = convertData
                        state.purchaseReqItemModelState = convertData
                        convertData = ConvertJson(action.payload.data.result.data[0].supplier_id)
                        payload.supplier_id = convertData
                        state.rfqQUOTdetail = payload
                    }
                }
                if (action.payload.data.isFaulted) {
                    console.log(action.payload.data.result.message);
                }
            }).addCase(getSupplierDropdown.fulfilled, (state, action) => {
                if (action.payload.data.result.status) {
                    state.supplierDropdown = action.payload.data.result.data
                }
                if (action.payload.data.isFaulted) {
                    console.log(action.payload.data.result.message);
                }
            }).addCase(getSplitRFQList.fulfilled, (state, action) => {
                if (action.payload.data.result.status) {
                    state.split_list = action.payload.data.result.data
                    state.split_list_backup = action.payload.data.result.data
                }
                if (action.payload.data.isFaulted) {
                    console.log(action.payload.data.result.message);
                }
            }).addCase(getSplitRFQList.rejected, (state, action) => {
                if (!action.payload.data.result.status) {
                    toast.error('bad request')
                }
                if (action.payload.data.isFaulted) {
                    console.log(action.payload.data.result.message);
                }
            }).addCase(saveRFQquotation.fulfilled, (state, action) => {
                if (action.payload.data.result.status) {
                    toast.success('successfully saved')
                    if (action.payload.data.result.data)
                        return {
                            ...state,
                            editrfqQCid: action.payload.data.result.data[0].id,
                            isEditDone: true
                        }
                }
                if (action.payload.data.isFaulted) {
                    console.log(action.payload.data.result.message);
                }
            }).addCase(getDataForQuoteStatusCheck.fulfilled, (state, action) => {
                if (action.payload.data.result.status) {
                    if (action.payload.data.result.data)
                        state.quoteStatusCheck = action.payload.data.result.data[0]
                }
            }).addCase(deleteRFQquotation.fulfilled, (state, action) => {
                if (action.payload.data.result.status) {
                    toast.success('successfully deleted')
                }
                if (action.payload.data.isFaulted) {
                    console.log(action.payload.data.result.message);
                }
            }).addCase(approveRFQ.fulfilled, (state, action) => {
                // eslint-disable-line
                /* if(payload.data.result.status){
                    toast.success('RFQ approved successfully')
                    state.approvedStatus = payload.data.result.messageCode
                }
                if(payload.data.isFaulted){
                    console.log(payload.data.result.message);
                } */
                if (!action.payload.data.result.status) {
                    toast.error(action.payload.data.result.message)
                } else {
                    // eslint-disable-line
                    //toast.success(action.payload.data.result.message)
                    if (action.payload.data.result.status && action.payload.data.result.data[0].status === 'success') {
                        state.book_qty = []
                        toast.success(action.payload.data.result.message)
                    } else {
                        toast.error(action.payload.data.result.data[0].status)
                    }
                }
                if (action.payload.data.isFaulted) {
                    console.log(action.payload.data.result.message);
                }
            }).addCase(viewQuotationDetails.fulfilled, (state, action) => {
                state.isLoading = false
                if (action.payload.data.result.status) {
                    const payload = action.payload.data.result.data[0]
                    if (payload && action.payload.data.result.data[0].QuotationDetail) {
                        let convertData = ConvertJson(action.payload.data.result.data[0].QuotationDetail)
                        payload.QuotationDetail = convertData
                        state.purchaseReqItemModelState = changeItemModalRFQ(convertData)
                        state.rfqQUOTdetail = payload
                    }
                }
                if (action.payload.data.isFaulted) {
                    console.log(action.payload.data.result.message);
                }
            })
    }
})
export const { setPurchaseData, searchRFQList, clearAfterApproveRfq, setPrice, setDeleteQuotat, clearSelectedItem, clearQuotEditID } = RFQSlice.actions
export default RFQSlice.reducer