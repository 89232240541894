import React from "react";

import { PieChart, Pie, Label, Cell, ResponsiveContainer } from "recharts";



function NormalPieChart(props) {
    const { totalJobs, pendingJobs, completedJobs } = props
    const data = [
        { name: "completed job", value: completedJobs },
        { name: "pending job", value: pendingJobs }
    ];
    function CustomLabel(props) {
        const { cx, cy } = props.viewBox;
        return (
            <>
                <text
                    x={cx}
                    y={cy + 20}
                    fill="rgba(0, 0, 0, 0.87)"
                    className="recharts-text recharts-label title"
                    textAnchor="middle"
                    dominantBaseline="central"
                >
                    <tspan
                        alignmentBaseline="middle"
                        fontSize="14px"
                        fontFamily="Poppins"
                    >
                        Total Job
                    </tspan>
                </text>
                <text
                    x={cx}
                    y={cy - 5}
                    fill="rgba(0, 0, 0, 0.54)"
                    className="recharts-text recharts-label count"
                    textAnchor="middle"
                    dominantBaseline="central"
                >
                    <tspan
                        fontSize="29px"
                        fontFamily="Poppins"
                        className="pe-auto"
                        fontWeight={700}
                        color="#154276"
                        role="presentation"
                    /* onClick={() => onFetch(values.label)} */
                    >
                        {totalJobs}
                    </tspan>
                </text>
            </>
        );
    }
    return (
        <>
            <ResponsiveContainer width='100%' height={250}>
                <PieChart
                    width={250}
                    height={250}
                    margin={{
                        top: 5, right: 10, left: 10, bottom: 5,
                    }}
                >
                    {/*<defs>*/}
                    {/*    <linearGradient id="colorUv" x1="1" y1="1" x2="0" y2="0">*/}
                    {/*        <stop offset="30%" stopColor="#6584FF" stopOpacity={0.5} />*/}
                    {/*        <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0.5} />*/}
                    {/*    </linearGradient>*/}
                    {/*</defs>*/}
                    <Pie
                        data={data}
                        cx="50%"
                        cy="50%"
                        dataKey="value"
                        innerRadius={45}
                        outerRadius={55}
                        blendStroke={'0'}
                    >
                        {data.map((entry, index) => {
                            if (index === 1) {
                                return <Cell key={`cell-${index}`} values={50} fill="#1ED6FF" />;
                            }
                            return <Cell key={`cell-${index}`} values={50} fill="#1F51B3" />;
                        })}
                        <Label
                            //value={data[0].value}
                            //value={totalJobs}
                            position="center"
                            fill="grey"
                            style={{
                                fontSize: "12px",
                                fontWeight: "bold",
                                fontFamily: "Poppins"
                            }}
                        />
                        <Label width={20} position="center" content={<CustomLabel />} />
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
        </>
    );
}

export default NormalPieChart;
