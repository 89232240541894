import { createAsyncThunk } from "@reduxjs/toolkit";
import * as ACTION from "../../constant/Actions";
import * as APIS from "../../constant/CommonApiUrl";
import { API_SERVICE } from "../../Services/CommonApi";
import axios from "axios";
export const getImmediateCauseOfDamagePG = createAsyncThunk(ACTION.immediate_cause_of_damage_pg,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const response = await API_SERVICE.get(APIS.immediate_cause_of_damage_pg, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })

export const getImmediateCauseOfDamagePGReason = createAsyncThunk(ACTION.immediate_cause_of_damage_pg_reason,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const response = await API_SERVICE.get(APIS.immediate_cause_of_damage_pg_reason, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })

export const getImmediateCauseOfDamageSec = createAsyncThunk(ACTION.immediate_cause_of_damage_sec,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const response = await API_SERVICE.get(APIS.immediate_cause_of_damage_sec, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })

export const getImmediateCauseOfDamageSecReason = createAsyncThunk(ACTION.immediate_cause_of_damage_sec_reason,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const response = await API_SERVICE.get(APIS.immediate_cause_of_damage_sec_reason, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })

export const getBasicCausePersonalFactoryGroup = createAsyncThunk(ACTION.basic_cause_personal_factory_group,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const response = await API_SERVICE.get(APIS.basic_cause_personal_factory_group, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })

export const getBasicCausePersonalFactoryGroupReason = createAsyncThunk(ACTION.basic_cause_personal_factory_group_reason,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const response = await API_SERVICE.get(APIS.basic_cause_personal_factory_group_reason, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })

export const getBasicCauseJobFactoryGroup = createAsyncThunk(ACTION.basic_cause_job_factory_group,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const response = await API_SERVICE.get(APIS.basic_cause_job_factory_group, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })

export const getBasicCauseJobFactoryGroupReason = createAsyncThunk(ACTION.basic_cause_job_factory_group_reason,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const response = await API_SERVICE.get(APIS.basic_cause_job_factory_group_reason, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })

export const getIncidentDetailsOfItem = createAsyncThunk(ACTION.incident_details_of_item,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const response = await API_SERVICE.get(APIS.incident_details_of_item, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })

export const getIncidentDamageProgramme = createAsyncThunk(ACTION.incident_damage_programme,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const response = await API_SERVICE.get(APIS.incident_damage_programme, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })

export const getIncidentDamageProgrammeReason = createAsyncThunk(ACTION.incident_damage_programme_reason,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const response = await API_SERVICE.get(APIS.incident_damage_programme_reason, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })

export const getIncidentDamagePerformanceStd = createAsyncThunk(ACTION.incident_damage_performance_std,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const response = await API_SERVICE.get(APIS.incident_damage_performance_std, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })

export const getIncidentDamagePerformanceStdReason = createAsyncThunk(ACTION.incident_damage_performance_std_reason,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const response = await API_SERVICE.get(APIS.incident_damage_performance_std_reason, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })

export const getIncidentDamageComplianceExitStd = createAsyncThunk(ACTION.incident_damage_compliance_exit_std,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const response = await API_SERVICE.get(APIS.incident_damage_compliance_exit_std, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })

export const getIncidentDamageComplianceExitStdReason = createAsyncThunk(ACTION.incident_damage_compliance_exit_std_reason,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const response = await API_SERVICE.get(APIS.incident_damage_compliance_exit_std_reason, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })

export const getIncidentDamageMachineryBreakdown = createAsyncThunk(ACTION.incident_damage_machinery_breakdown,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const response = await API_SERVICE.get(APIS.incident_damage_machinery_breakdown, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })

export const getIncidentDamageLossSeverity = createAsyncThunk(ACTION.incident_damage_Loss_Severity,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const response = await API_SERVICE.get(APIS.incident_damage_Loss_Severity, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getIncidentDamageDPA = createAsyncThunk(ACTION.incident_damage_dpa_action,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const response = await API_SERVICE.get(APIS.incident_damage_dpa_action, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getIncidentDamageClosedOut = createAsyncThunk(ACTION.incident_damage_closed_out,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const response = await API_SERVICE.get(APIS.incident_damage_closed_out, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getInjuryPortDept = createAsyncThunk(ACTION.injury_port_depature,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const response = await API_SERVICE.get(APIS.injury_port_depature, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })

export const getInjuryPortDesig = createAsyncThunk(ACTION.injury_port_destination,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const response = await API_SERVICE.get(APIS.injury_port_destination, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })

export const getInjuryManualRef = createAsyncThunk(ACTION.injury_manual_code_ref,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const response = await API_SERVICE.get(APIS.injury_manual_code_ref, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getInjuryProbability = createAsyncThunk(ACTION.injury_probability_of_reoccurrence,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const response = await API_SERVICE.get(APIS.injury_probability_of_reoccurrence, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getNMUnsafe = createAsyncThunk(ACTION.nm_unsafe,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const response = await API_SERVICE.get(APIS.nm_unsafe, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getNMImmedidatePrim = createAsyncThunk(ACTION.nm_immediate_cause_primary,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const response = await API_SERVICE.get(APIS.nm_immediate_cause_primary, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getNMImmedidatePrimReason = createAsyncThunk(ACTION.nm_immediate_cause_primary_reason,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const response = await API_SERVICE.get(APIS.nm_immediate_cause_primary_reason, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getNMImmedidateSec = createAsyncThunk(ACTION.nm_immediate_cause_secondary,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const response = await API_SERVICE.get(APIS.nm_immediate_cause_secondary, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getNMImmedidateSecReason = createAsyncThunk(ACTION.nm_immediate_cause_secondary_reason,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const response = await API_SERVICE.get(APIS.nm_immediate_cause_secondary_reason, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getNMBasicPersFact = createAsyncThunk(ACTION.nm_basic_cause_personal_factor,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const response = await API_SERVICE.get(APIS.nm_basic_cause_personal_factor, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getNMBasicPersFactReason = createAsyncThunk(ACTION.nm_basic_cause_personal_factor_reason,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const response = await API_SERVICE.get(APIS.nm_basic_cause_personal_factor_reason, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getNMBasicJobFact = createAsyncThunk(ACTION.nm_basic_cause_job_factor,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const response = await API_SERVICE.get(APIS.nm_basic_cause_job_factor, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getNMBasicJobFactReason = createAsyncThunk(ACTION.nm_basic_cause_job_factor_reason,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const response = await API_SERVICE.get(APIS.nm_basic_cause_job_factor_reason, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })