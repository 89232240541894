import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { toast } from "react-toastify"
import { API_SERVICE } from "../Services/CommonApi"
import { GET_STORE, SAVE_STORE } from "../constant/CommonApiUrl";
import { dateTimeToDate } from "../helper/dateUtil"
import * as ACTION from '../constant/Actions'
import * as APIS from '../constant/CommonApiUrl'
import { ConvertJson } from "../helper";
const ADD_STORE = 'store/addStoreApi'
const UPDATE_STORE = 'store/updateStoreApi'
const FETCH_STORE = 'store/fetchStoreApi'
const DELETE_STORE = 'store/deleteStoreApi'

export const addStoreApi = createAsyncThunk(ADD_STORE,
    async (args, thunkAPI) => {
        try {
            let response = await API_SERVICE.post(SAVE_STORE, args['data'], "CREATE");
            //response = response.data.result.data[0];
            thunkAPI.dispatch(fetchStoreApi())
            return response;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.message)
        }
    })

export const updateStoreApi = createAsyncThunk(UPDATE_STORE,
    async (args, thunkAPI) => {
        try {
            let response = await API_SERVICE.post(SAVE_STORE, args['data'], "UPDATE");
            //response = response.data.result.data[0]
            thunkAPI.dispatch(fetchStoreApi())
            return response;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.message)
        }
    })

export const deleteStoreApi = createAsyncThunk(SAVE_STORE,
    async (args, thunkAPI) => {
        try {
            const toDelete = thunkAPI.getState().root.store.list.find(ele => ele.id === args['id']);
            const data = Object.assign({}, { ...toDelete, repository: [] })
            let response = await API_SERVICE.post(SAVE_STORE, { ...data, isdisabled: "Y" });
            // response = response.data.result.data[0]
            thunkAPI.dispatch(fetchStoreApi())
            return response;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.message)
        }
    }
)

export const fetchStoreApi = createAsyncThunk(FETCH_STORE,
    async (arg, thunkAPI) => {
        try {
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(`${GET_STORE}?vessel_id=${vessel_name === 'OFFICE' ? 0 : id}`);
            response = response.data.result.status ? response.data.result.data : toast.error(response.data.result.message);
            response = response.map(store => ({
                ...store,
                robreceiveddate: dateTimeToDate(store.robreceiveddate),
                robexpairationdate: dateTimeToDate(store.robexpairationdate)
            }))
            return response
        } catch (err) {
            return thunkAPI.rejectWithValue(err.message)
        }
    }
)
export const getStoreSearch = createAsyncThunk(ACTION.GET_STORE_SEARCH,
    async (arg, thunkAPI) => {
        try {
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(`${APIS.GET_STORE_SEARCH}?vessel_id=${vessel_name === 'OFFICE' ? 0 : id}`, {...arg});
            response = response.data.result.data;
            response = response.map(store => ({
                ...store,
                robreceiveddate: dateTimeToDate(store.robreceiveddate),
                robexpairationdate: dateTimeToDate(store.robexpairationdate)
            }))
            return response
        } catch (err) {
            return thunkAPI.rejectWithValue(err.message)
        }
    }
)
export const getConsumptionStorelHead = createAsyncThunk(ACTION.CONSUMPTION_STORE_HEAD,
    async (arg, thunkAPI) => {
        try {
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            return await API_SERVICE.get(`${APIS.GET_STORE}?${arg}&vessel_id=${id}`)
        } catch (err) {
            return thunkAPI.rejectWithValue(err)
        }
    })

const initialState = {
    list: [],
    editStore: {},
    consumption_store_head: {},
    isUpdated: true,
    robDetails: {
        stock_type_id: "", 
        id: ""
    }
}

export const storeSlice = createSlice({
    name: 'store',
    initialState,
    reducers: {
        addStore: (state, action) => {
            console.log(action.payload)
            state.list = [...state.list, action.payload]
        },
        delStore: (state, action) => {
            state.list = state.list.filter(x => x.id !== action.payload)
        },
        loadEditStore: (state, { payload }) => {
            state.editStore = { ...payload,
                repository: Array.isArray(payload.repository) ? payload.repository : payload.repository ? ConvertJson(payload.repository) : []
         }
        },
        setRobdetail: (state, {payload}) => {
            state.robDetails = payload
        },
        clearEditStore: (state, action) => {
            state.editStore = {}
        },
        updateStore: (state, action) => {
            let index = state.list.findIndex(x => x.id === action.payload.id)
            state.list[index] = action.payload
        },
        resetStore: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(addStoreApi.fulfilled, (state, action) => {
                if (action.payload.data.result.status) {
                    toast.success(action.payload.data.result.message)
                } else {
                    toast.error(action.payload.data.result.message)
                }
            })
            .addCase(updateStoreApi.fulfilled, (state, action) => {
                if (action.payload.data.result.status) {
                    toast.success(action.payload.data.result.message)
                } else {
                    toast.error(action.payload.data.result.message)
                }
            })
            .addCase(fetchStoreApi.fulfilled, (state, action) => {
                state.list = action.payload;
                state.isUpdated = false;
            })
            .addCase(getStoreSearch.fulfilled, (state, action) => {
                state.list = action.payload;
                state.isUpdated = !state.isUpdated;
            })
            .addCase(getConsumptionStorelHead.fulfilled, (state, action) => {
                if (action.payload.data.result.status) {
                    state.consumption_store_head = action.payload.data.result.data[0]
                } else toast.error(action.payload.status);
            })
            .addCase(deleteStoreApi.fulfilled, (state, action) => {
                if (action.payload.data.result.status) {
                    toast.success(action.payload.data.result.message)
                } else {
                    toast.error(action.payload.data.result.message)
                }
            })
    }
})
export const { addStore, delStore, loadEditStore, clearEditStore, updateStore, resetStore, setRobdetail } = storeSlice.actions
export default storeSlice.reducer