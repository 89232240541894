import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { GET_DESIGNATION, SAVE_DESIGNATION } from "../constant/CommonApiUrl"
import { API_SERVICE } from "../Services/CommonApi";
import { toast } from "react-toastify"
import * as ACTIONS from "../constant/Actions";
import * as APIS from "../constant/CommonApiUrl";

const ADD_DESIGNATION = 'designation/addDesignationApi'
const UPDATE_DESIGNATION = 'designation/updateDesignationApi'
const FETCH_DESIGNATION = 'designation/fetchDesignationApi'
const DELETE_DESIGNATION = 'designation/deleteDesignationApi'
const FETCH_DESIGNATION_SIDE=`designation/getDesignationBasedSide`

export const addDesignationApi = createAsyncThunk(ADD_DESIGNATION,
    async (args, thunkAPI) => {
        try {
            let response = await API_SERVICE.post(SAVE_DESIGNATION, args['data'], "CREATE");
            thunkAPI.dispatch(fetchDesignationApi())
            return response.data.result.data[0];
        } catch (err) {
            return thunkAPI.rejectWithValue(err.message)
        }
    })

export const updateDesignationApi = createAsyncThunk(UPDATE_DESIGNATION,
    async (args, thunkAPI) => {
        try {
            let response = await API_SERVICE.post(SAVE_DESIGNATION, args['data'], "UPDATE");
            thunkAPI.dispatch(fetchDesignationApi())
            return response.data.result.data[0];
        } catch (err) {
            return thunkAPI.rejectWithValue(err.message)
        }
    })

export const deleteDesignationApi = createAsyncThunk(DELETE_DESIGNATION,
    async (args, thunkAPI) => {
        try {
            const toDelete = thunkAPI.getState().root.designation.list.find(ele => ele.id === args['id']);
            const response = await API_SERVICE.post(SAVE_DESIGNATION, { ...toDelete, isdisabled: "Y" });
            thunkAPI.dispatch(fetchDesignationApi())
            return response.data.result.data[0];
        } catch (err) {
            return thunkAPI.rejectWithValue(err.message)
        }
    }
)
export const fetchDesignationById = createAsyncThunk(ACTIONS.DESIGNATION_BY_ID,
    async (arg, thunkAPI) => {
        try {
            return API_SERVICE.get(`${APIS.GET_DESIGNATION_BY_ID}?department_id=${arg.department_id}`)
        } catch (err) {
            return thunkAPI.rejectWithValue(err)
        }
    }
)

export const fetchDesignationApi = createAsyncThunk(FETCH_DESIGNATION,
    async (arg, thunkAPI) => {
        try {
            let response = await API_SERVICE.get(GET_DESIGNATION);
            return response.data.result.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.message)
        }
    }
)
export const getDesignationbasedOnSide = createAsyncThunk(FETCH_DESIGNATION_SIDE,
    async (arg, thunkAPI) => {
        try {
            let response = await API_SERVICE.get(`${APIS.DESIGNATION_BASED_ON_SIDE}?side=${arg}`);
            return response
        } catch (err) {
            return thunkAPI.rejectWithValue(err.message)
        }
    }
)

const initialState = {
    list: [],
    designation_list: [],
    designationList: [],
    editdesignation: {},
    designation_based_side: [],
    isUpdated: true,
}

export const designationSlice = createSlice({
    name: 'designation',
    initialState,
    reducers: {
        addDesignation: (state, action) => {
            state.list = [...state.list, action.payload]
        },
        delDesignation: (state, action) => {
            state.list = state.list.filter(x => x.id !== action.payload)
        },
        loadEditDesignation: (state, action) => {
            state.editdesignation = action.payload
        },
        clearEditDesignation: (state, action) => {
            state.editdesignation = {}
        },
        updateDesignation: (state, action) => {
            let index = state.list.findIndex(x => x.id === action.payload.id)
            state.list[index] = action.payload
        },
        resetDesignation: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(addDesignationApi.fulfilled, (state, action) => {
                toast.success('Successfully added');
            })
            .addCase(updateDesignationApi.fulfilled, (state, action) => {
                toast.success('Successfully updated');
            })
            .addCase(fetchDesignationApi.fulfilled, (state, action) => {
                state.list = action.payload;
                state.designation_list = action.payload;
                state.isUpdated = false;
            }).addCase(fetchDesignationById.fulfilled, (state, action) => {
                state.designationList = action.payload.data.result.data;
                state.list = action.payload.data.result.data;
                state.isUpdated = false;
            })
            .addCase(deleteDesignationApi.fulfilled, (state, action) => {
                toast.success('Successfully deleted');
            })
            .addCase(getDesignationbasedOnSide.fulfilled, (state, action) => {
                if (action.payload.data.result.status) {
                    state.designation_based_side = action.payload.data.result.data
                } else {
                    toast.error(action.payload.data.result.message)
                }
            })
    }
})
export const { addDesignation, delDesignation, loadEditDesignation, clearEditDesignation, updateDesignation, resetDesignation } = designationSlice.actions
export default designationSlice.reducer