import React from "react"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { API_SERVICE } from "../Services/CommonApi"
import * as APIS from "../constant/CommonApiUrl"
import * as ACTIONS from "../constant/Actions"
import { toast } from "react-toastify"
import { dashboardEvent } from "../helper/Support"
import { ConvertJson } from "../helper";
import { ValidateResponse } from "../Services/Support"

const vesselInfo = localStorage.getItem('vesselInfo') && localStorage.getItem('vesselInfo') !== null ? JSON.parse(localStorage.getItem('vesselInfo')) : {}
const initialState = {
    rfq_status: ['APPROVED', 'Awaiting for PO Creation', 'NEW', 'QUOTE SPLIT', 'READY FOR QC', 'REJECTED', 'VERIFIED'],
    goods_status: ['CLOSED', 'DRAFT', 'NEW', 'NOT DELIVERED', 'PARTIAL', 'RECEIVED', 'REJECTED'],
    po_status: ['APPROVED', 'BULK', 'CLOSED', 'ISSUED', 'NEW', 'PARTIAL', 'PO Invoiced', 'RECEIVED', 'SPLIT'],
    qc_status: ['APPROVED', 'NEW', 'PO Created', 'VERIFIED'],
    quot_status: ['QC Done', 'NEW'],
    pms_location_all: [],
    part_critical_all: [],
    department_list: [],
    currency_list: [],
    currency_rate: {},
    goods_location: [],
    country_list: [],
    country_byId: [],
    dept_based_desig: [],
    vessel_detailByID: vesselInfo,
    events: [],
    current_rob: null,
    isUpdated: false,
    error: false,
    isEdit: false,
    isSubmenuExpand: false,
    isSaved: false,
    purchaseDashboardDetails: {},
    designation_based_users:[]
}
export const fetchDeptDropdown = createAsyncThunk(ACTIONS.DEPT_DROPDOWN,
    async (arg, thunkAPI) => {
        try {
            return API_SERVICE.get(APIS.GET_DEPT_DROPDOWN)
        } catch (err) {
            return thunkAPI.rejectWithValue(err)
        }
    }
)
export const getUserBasedDesignation = createAsyncThunk(ACTIONS.GET_USER_BASED_DESIGNATION,
    async (arg, thunkAPI) => {
        try {
            return await API_SERVICE.get(`${APIS.GET_USER_BASED_DESIGNATION}?designation_id=${arg}`)
        } catch (err) {
            return thunkAPI.rejectWithValue(err)
        }
    }
)
export const fetchCurrencyDropdown = createAsyncThunk(ACTIONS.CURRENCY_DROPDOWN_ACITON,
    async (arg, thunkAPI) => {
        try {
            return API_SERVICE.get(APIS.CURRENCY_DROPDOWN)
        } catch (err) {
            return thunkAPI.rejectWithValue(err)
        }
    }
)
export const fetchPRDashboard = createAsyncThunk(ACTIONS.PURCHASE_DASHBOARD,
    async (arg, thunkAPI) => {
        try {
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            return API_SERVICE.get(`${APIS.PURCHASE_DASHBOARD}?vessel_id=${vessel_name === 'OFFICE' ? 0 : id}`)
        } catch (err) {
            return thunkAPI.rejectWithValue(err)
        }
    }
)
export const fetchlocationDropdown = createAsyncThunk(ACTIONS.GOODS_LOCATION_DROPDOWN,
    async (arg, thunkAPI) => {
        try {
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            return API_SERVICE.get(`${APIS.GET_LOCATION}?vessel_id=${vessel_name === 'OFFICE' ? 0 : id}`)
        } catch (err) {
            return thunkAPI.rejectWithValue(err)
        }
    }
)
export const fetchCountryDropdown = createAsyncThunk(ACTIONS.COUNTRY_DROPDOWN,
    async (arg, thunkAPI) => {
        try {
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            return API_SERVICE.get(`${APIS.COUNTRY_DROPDOWN}?vessel_id=${vessel_name === 'OFFICE' ? 0 : id}`)
        } catch (err) {
            return thunkAPI.rejectWithValue(err)
        }
    }
)

export const getCurrencyRate = createAsyncThunk(ACTIONS.CURRENCY_DROPDOWN,
    async (arg, thunkAPI) => {
        try {
            return API_SERVICE.get(`${APIS.GET_CURRENCY_RATE}`)
        } catch (err) {
            return thunkAPI.rejectWithValue(err)
        }
    }
)

export const getCountryById = createAsyncThunk(ACTIONS.GET_COUNTRY_ID,
    async (arg, thunkAPI) => {
        try {
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            return API_SERVICE.get(`${APIS.GET_COUNTRY_ID}?id=${arg}`)
        } catch (err) {
            return thunkAPI.rejectWithValue(err)
        }
    }
)
export const onSaveCurrencyMaster = createAsyncThunk(ACTIONS.SAVE_CURRENCY_ACTION,
    async (arg, thunkAPI) => {
        try {
            return API_SERVICE.post(`${APIS.SAVE_CURRENCY_RATE}`, arg.payload)
        } catch (err) {
            return thunkAPI.rejectWithValue(err)
        }
    }
)
export const getConsumptionROB = createAsyncThunk(ACTIONS.GET_ROB,
    async (arg, thunkAPI) => {
        try {
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            return API_SERVICE.get(`${APIS.GET_ROB}?${new URLSearchParams(arg)}&vessel_id=${vessel_name === 'OFFICE' ? 0 : id}`)
        } catch (err) {
            return thunkAPI.rejectWithValue(err)
        }
    }
)
export const getDesignationBasedDepartment = createAsyncThunk(ACTIONS.DEPT_BASED_DESIGNATION,
    async (arg, thunkAPI) => {
        try {
            return API_SERVICE.get(`${APIS.DEPT_BASED_DESIGNATION}?id=${arg}`)
        } catch (err) {
            return thunkAPI.rejectWithValue(err)
        }
    }
)
export const getAllLocationPMS = createAsyncThunk(ACTIONS.PMS_LOCATION_ALL,
    async (arg, thunkAPI) => {
        try {
            return await API_SERVICE.get(APIS.PMS_LOCATION_COMPLETION)
        } catch (err) {
            return thunkAPI.rejectWithValue(err)
        }
    })
export const getAllCriticalPART = createAsyncThunk(ACTIONS.CRITICAL_FOR_ALL,
    async (arg, thunkAPI) => {
        try {
            return await API_SERVICE.get(APIS.GET_PART_CRITICAL)
        } catch (err) {
            return thunkAPI.rejectWithValue(err)
        }
    })
export const CommonSlice = createSlice({
    name: "common",
    initialState,
    reducers: {
        setCalendarEvent: (state, action) => {
            state.events = action.payload.map((el, i) => el.is_contract !== 1 && ({
                ...el,
                id: el.id,
                title: dashboardEvent({ id: el.id, pr_id: el.pr_id, dashboard: el.dashboard }),
                start: new Date(`${el.pr_date}Z`),
                end: new Date(`${el.pr_date}Z`),
                allDay: true
            }))
        },
        setStatus: (state, action) => {
            state.isEdit = action.payload
        },
        toggleSubmenu: (state, action) => {
            state.isSubmenuExpand = !state.isSubmenuExpand
        },
        putVesselDetail: (state, action) => {
            state.vessel_detailByID = action.payload
            state.vessel_detailByID && Object.keys(state.vessel_detailByID).length ? localStorage.setItem("vesselInfo", JSON.stringify(state.vessel_detailByID)) : localStorage.setItem("vesselInfo", JSON.stringify({}))
        },
        clearCommonROB: (state, action) => {
            state.current_rob = null
        },
        onChangeExchangeRate: (state, { payload }) => {
            const data = payload.data
            const currencyData = state.currency_rate
            const exchanges = []
            if (data.inputValue) {
                currencyData.countryid.map((v) => {
                    if (v.currency_id === data.currency_id) {
                        exchanges.push({ ...v, exchange_rate: (data.inputValue) })
                    } else {
                        exchanges.push(v)
                    }
                })
                state.currency_rate = { ...state.currency_rate, countryid: exchanges }
            } else {
                console.log('')
            }
        },
        onChangeDefaultCountry: (state, { payload }) => {
            const currencyData = state.currency_rate
            let header = {}
            if (payload) {
                currencyData.countryid.map((v) => {
                    if (v.currency_id === Number(payload)) {
                        header = { id: v.currency_id, country_code: v.country_code, country_name: v.country_name }
                    }
                })
                state.currency_rate = {
                    ...header, countryid: currencyData.countryid
                }
            }
        },
        clearDepartBasedDesignation: (state, action) => {
            state.dept_based_desig = []
        },
        resetStatus: () => Object.assign({},
            {
                ...initialState,
                vessel_detailByID: localStorage.getItem('vesselInfo') !== null ? JSON.parse(localStorage.getItem('vesselInfo')) : {}
            })
    },
    extraReducers: (builder) => {
        (builder)
            .addCase(fetchDeptDropdown.fulfilled, (state, { meta, payload }) => {
                if (payload.data.result.status) {
                    state.department_list = payload.data.result.data
                    state.isUpdated = true
                }
                if (payload.data.isFaulted) {
                    toast.error(payload.data.result.message);
                }
            })
            .addCase(getUserBasedDesignation.fulfilled, (state, action) => {
                state.designation_based_users = ValidateResponse(action.payload)
            })
            .addCase(getDesignationBasedDepartment.fulfilled, (state, { meta, payload }) => {
                if (payload.data.result.status) {
                    state.dept_based_desig = payload.data.result.data
                }
                if (payload.data.isFaulted) {
                    toast.error(payload.data.result.message);
                }
            })
            .addCase(getAllLocationPMS.fulfilled, (state, { meta, payload }) => {
                if (payload.data.result.status) {
                    state.pms_location_all = payload.data.result.data
                }
                if (payload.data.isFaulted) {
                    toast.error(payload.data.result.message);
                }
            })
            .addCase(getAllCriticalPART.fulfilled, (state, { meta, payload }) => {
                if (payload.data.result.status) {
                    state.part_critical_all = payload.data.result.data
                }
                if (payload.data.isFaulted) {
                    toast.error(payload.data.result.message);
                }
            })
            .addCase(fetchCountryDropdown.fulfilled, (state, { meta, payload }) => {
                if (payload.data.result.status) {
                    state.country_list = payload.data.result.data
                }
                if (payload.data.isFaulted) {
                    toast.error(payload.data.result.message);
                }
            }).addCase(getCurrencyRate.fulfilled, (state, { meta, payload }) => {
                if (payload.data.result.status) {
                    const response = payload.data.result.data[0]
                    response.countryid = payload.data.result.data[0].countryid ? ConvertJson(payload.data.result.data[0].countryid) : []
                    state.currency_rate = response
                }
                if (payload.data.isFaulted) {
                    toast.error(payload.data.result.message);
                }
            })
            .addCase(fetchlocationDropdown.fulfilled, (state, { meta, payload }) => {
                if (payload.data.result.status) {
                    state.goods_location = payload.data.result.data
                }
                if (payload.data.isFaulted) {
                    toast.error(payload.data.result.message);
                }
            }).addCase(onSaveCurrencyMaster.fulfilled, (state, { meta, payload }) => {
                if (payload.data.result.status) {
                    state.isSaved = !state.isSaved
                    toast.success(payload.data.result.message)
                }
                else toast.error(payload.data.result.message)
            })
            .addCase(getConsumptionROB.fulfilled, (state, { meta, payload }) => {
                if (payload.data.result.status) {
                    state.current_rob = payload.data.result.data[0].current_rob
                }
                if (payload.data.isFaulted) {
                    toast.error(payload.data.result.message);
                }
            })
            .addCase(fetchCurrencyDropdown.fulfilled, (state, { meta, payload }) => {
                if (payload.data.result.status) {
                    //console.log(payload,'curr');
                    state.currency_list = payload.data.result.data
                    state.isUpdated = true
                }
                if (payload.data.isFaulted) {
                    toast.error(payload.data.result.message);
                }
            })
            .addCase(fetchDeptDropdown.rejected, (state, { meta, payload }) => {
                if (payload.response.data.status === 400) {
                    toast.error(payload.response.data.title)
                }
            }).addCase(fetchCurrencyDropdown.rejected, (state, { meta, payload }) => {
                if (payload.response.data.status === 400) {
                    toast.error(payload.response.data.title)
                }
            }).addCase(fetchlocationDropdown.rejected, (state, { meta, payload }) => {
                if (payload.response.data.status === 400) {
                    toast.error(payload.response.data.title)
                }
            }).addCase(getConsumptionROB.rejected, (state, { meta, payload }) => {
                if (payload.response.data.status === 400) {
                    toast.error(payload.response.data.title)
                }
            }).addCase(fetchPRDashboard.fulfilled, (state, { meta, payload }) => {
                if (payload.data.result.status) {
                    state.purchaseDashboardDetails = {
                        info: { ...payload.data.result.data.dashBoardInfo[0] },
                        rowData: [...payload.data.result.data.dashBoardDetail]
                    }
                }
            }).addCase(getCountryById.fulfilled, (state, { meta, payload }) => {
                if (payload.data.result.status) {
                    state.country_byId = payload.data.result.data
                } else {
                    toast.error(payload.data.result.message)
                }
            })
    },
});

export const { setStatus, toggleSubmenu, clearDepartBasedDesignation, setCalendarEvent, clearCommonROB, resetStatus,
    onChangeExchangeRate, onChangeDefaultCountry, putVesselDetail } = CommonSlice.actions
export default CommonSlice.reducer