import React from 'react';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { BsChevronLeft, BsChevronRight, BsChevronDoubleLeft, BsChevronDoubleRight } from 'react-icons/bs';
import styled from 'styled-components';

// Pagination Container
const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

// Rows Per Page Selector
const RowsPerPageSelector = styled.select`
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
`;

// Page Navigation Buttons
const PageNavigationButton = styled.button`
  padding: 5px;
  margin: 0 3px;
  border: 1px solid #eaeaea !important;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;

  &:hover {
    border-color: #ccc;
  }
`;

// Pagination Component
const Pagination = ({ currentPage, totalPages, rowsPerPage, onPageChange, onRowsPerPageChange,setCheckedAll }) => {
    const handleNextPage = () => {
        if (currentPage < totalPages) {
            onPageChange(currentPage + 1);
            setCheckedAll(false)
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            onPageChange(currentPage - 1);
            setCheckedAll(false)
        }
    };

    const handleFirstPage = () => {        
        onPageChange(1);
        setCheckedAll(false)
    };

    const handleLastPage = () => {        
        onPageChange(totalPages);
        setCheckedAll(false)

    };

    return (
        <PaginationContainer>
            <span style={{color:'#787878'}}>0 of {rowsPerPage} Row(s) Selected</span>
            <div >
                <span className='me-2' style={{color:'#474646'}}>Rows per page</span>
                <RowsPerPageSelector style={{color:'#474646'}} value={rowsPerPage} onChange={(e) => onRowsPerPageChange(Number(e.target.value))}>
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                </RowsPerPageSelector>
            </div>
            <div style={{color:'#474646'}}>Page {currentPage} of {totalPages}</div>
            <div>
                <PageNavigationButton onClick={()=>handleFirstPage()}>
                    <BsChevronDoubleLeft />
                </PageNavigationButton>
                <PageNavigationButton onClick={()=>handlePrevPage()}>
                    <BsChevronLeft />
                </PageNavigationButton>
                <PageNavigationButton onClick={()=>handleNextPage()}>
                    <BsChevronRight />
                </PageNavigationButton>
                <PageNavigationButton onClick={()=>handleLastPage()}>
                    <BsChevronDoubleRight />
                </PageNavigationButton>
            </div>
        </PaginationContainer>
    );
};

export default Pagination;
