
import { createSlice } from "@reduxjs/toolkit";
import { AddEditResponse, ValidateResponse } from "../Services/Support";
import { getCheckItemGroup, getDeficiencyCode, getDeficiencyType, getIDNType, getIncidentCatType, getIncidentDamageById, getIncidentDamageList, getIncidentInjuryById, getIncidentInjuryList, getIncidentMasterById, getIncidentMasterList, getIncidentMissId, getIncidentMissList, getIncidentReportBy, getNatureOfInjuryCat, getReportBy, saveIncidentDamage, saveIncidentInjury, saveIncidentMaster, saveIncidentMiss } from "./THUNK/IncidentThunk";
import { ConvertJson } from "../helper";
import { damageControlActionModel, damageOfficeRemarksModel, damagePlaceModel, injuryCheckList, injuryControlAction, injuryOfficeRemarks, nearMissControlAction, nearMissOfficeRemarks } from "../constant/CommonInitialvalue";
const initialState = {
    list: [],
    editRecord: {},
    category_type: [],
    nature_injury: [],
    report_by: [],
    report_by_AI: [],
    deficiency_type: [],
    deficiency_code: [],
    item_group: [],
    idn_type: [],
    category_dropdown: [],
    report_by_dropdown: [],
    audit_inspect_dropdown: [],
    incident_ship_board: [],
    incident_sea_area: [],
    incident_type_contact: [],
    incident_damage: [],
    location: [],
    deficiency: [],
    idn: [],
    port: [],
    designation: []
}
export const incidentMasterSlice = createSlice({
    name: 'incident',
    initialState,
    reducers: ({
        clearIncidentMasters: (state, action) => {
            state.editRecord = {}
            state.list = []
        },
        clearIncidentEdit: (state, action) => {
            state.editRecord = {}
        },
        setIncidentChecklist: (state, action) => {
            state.idn = state.idn.map(el => el.id === action.payload.id ? { ...el, checklist_item_group: action.payload.id, status: action.payload.status } : el)

        },
        setIncidentDropDown: (state, action) => {
            switch (action.payload?.section) {
                case 'category':
                    state.category_dropdown = action.payload.data
                    break;
                case 'report_by':
                    state.report_by_dropdown = action.payload.data
                    break;
                case 'audit_inspect':
                    state.audit_inspect_dropdown = action.payload.data
                    break;
                case 'incident_ship_board':
                    state.incident_ship_board = action.payload.data
                    break;
                case 'incident_sea_area':
                    state.incident_sea_area = action.payload.data
                    break;
                case 'incident_type_contact':
                    state.incident_type_contact = action.payload.data
                    break;
                case 'incident_damage':
                    state.incident_damage = action.payload.data
                    break;
                case 'location':
                    state.location = action.payload.data
                    break;
                case 'deficiency':
                    state.deficiency = action.payload.data
                    break;
                case 'idn':
                    state.idn = action.payload.data
                    break;
                case 'port':
                    state.port = action.payload.data
                    break;
                case 'designation':
                    state.designation = action.payload.data
                    break;
                case 'ALL':
                    state.category_dropdown = []
                    state.report_by_dropdown = []
                    state.audit_inspect_dropdown = []
                    state.incident_ship_board = []
                    state.incident_sea_area = []
                    state.incident_type_contact = []
                    state.incident_damage = []
                    state.location = []
                    state.deficiency = []
            }
        }

    }),
    extraReducers: (builder) => {
        builder
            .addCase(getIncidentMasterList.fulfilled, (state, action) => {
                state.list = ValidateResponse(action.payload)
            })
            .addCase(getIncidentMasterById.fulfilled, (state, action) => {
                state.editRecord = AddEditResponse(action.payload)[0]
            })
            .addCase(saveIncidentMaster.fulfilled, (state, action) => {
                AddEditResponse(action.payload)
            })
            .addCase(getIncidentCatType.fulfilled, (state, action) => {
                state.category_type = ValidateResponse(action.payload)
            })
            .addCase(getNatureOfInjuryCat.fulfilled, (state, action) => {
                state.nature_injury = ValidateResponse(action.payload)
            })
            .addCase(getIncidentReportBy.fulfilled, (state, action) => {
                state.report_by = ValidateResponse(action.payload)
            })
            .addCase(getReportBy.fulfilled, (state, action) => {
                state.report_by_AI = ValidateResponse(action.payload)
            })
            .addCase(getDeficiencyType.fulfilled, (state, action) => {
                state.deficiency_type = ValidateResponse(action.payload)
            })
            .addCase(getDeficiencyCode.fulfilled, (state, action) => {
                state.deficiency_code = ValidateResponse(action.payload)
            })
            .addCase(getCheckItemGroup.fulfilled, (state, action) => {
                state.item_group = ValidateResponse(action.payload)
            })
            .addCase(getIDNType.fulfilled, (state, action) => {
                state.idn_type = ValidateResponse(action.payload)
            })
            .addCase(getIncidentDamageList.fulfilled, (state, action) => {
                state.list = ValidateResponse(action.payload)
            })
            .addCase(getIncidentDamageById.fulfilled, (state, action) => {
                let obj = AddEditResponse(action.payload)[0]
                obj['damagePlaceModel'] = obj?.damagePlaceModel ? ConvertJson(obj.damagePlaceModel)[0] : { ...damagePlaceModel }
                obj['damageControlActionModel'] = obj?.damageControlActionModel ? ConvertJson(obj.damageControlActionModel)[0] : { ...damageControlActionModel }
                obj['damageOfficeRemarksModel'] = obj?.damageOfficeRemarksModel ? ConvertJson(obj.damageOfficeRemarksModel)[0] : { ...damageOfficeRemarksModel }
                obj['repository'] = obj?.repository ? ConvertJson(obj.repository) : []
                state.editRecord = obj
            })
            .addCase(saveIncidentDamage.fulfilled, (state, action) => {
                AddEditResponse(action.payload)
            })
            .addCase(getIncidentInjuryList.fulfilled, (state, action) => {
                state.list = ValidateResponse(action.payload)
            })
            .addCase(getIncidentInjuryById.fulfilled, (state, action) => {
                let obj = AddEditResponse(action.payload)[0]
                obj['injuryCheckList'] = obj?.injuryCheckList ? ConvertJson(obj.injuryCheckList) : []
                obj['injuryControlAction'] = obj?.injuryControlAction ? ConvertJson(obj.injuryControlAction)[0] : { ...injuryControlAction }
                obj['injuryOfficeRemarks'] = obj?.injuryOfficeRemarks ? ConvertJson(obj.injuryOfficeRemarks)[0] : { ...injuryOfficeRemarks }
                obj['repository'] = obj?.repository ? ConvertJson(obj.repository) : []
                state.idn = obj.injuryCheckList
                state.editRecord = obj
            })
            .addCase(saveIncidentInjury.fulfilled, (state, action) => {
                AddEditResponse(action.payload)
            })
            .addCase(getIncidentMissList.fulfilled, (state, action) => {
                state.list = ValidateResponse(action.payload)
            })
            .addCase(getIncidentMissId.fulfilled, (state, action) => {
                let obj = AddEditResponse(action.payload)[0]
                obj['nearMissCheckList'] = obj?.nearMissCheckList ? ConvertJson(obj.nearMissCheckList) : []
                obj['nearMissControlAction'] = obj?.nearMissControlAction ? ConvertJson(obj.nearMissControlAction)[0] : { ...nearMissControlAction }
                obj['nearMissOfficeRemarks'] = obj?.nearMissOfficeRemarks ? ConvertJson(obj.nearMissOfficeRemarks)[0] : { ...nearMissOfficeRemarks }
                obj['repository'] = obj?.repository ? ConvertJson(obj.repository) : []
                state.idn = obj.nearMissCheckList
                state.editRecord = obj
            })
            .addCase(saveIncidentMiss.fulfilled, (state, action) => {
                AddEditResponse(action.payload)
            })
    }
})
export const { clearIncidentMasters, setIncidentChecklist, clearIncidentEdit, setIncidentDropDown } = incidentMasterSlice.actions
export default incidentMasterSlice.reducer
