import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit"
import { API_SERVICE } from "../Services/CommonApi";
import * as APIS from "../constant/CommonApiUrl";
import * as ACTIONS from "../constant/Actions";
import { toast } from "react-toastify"
import { ConvertJson } from "../helper"

export const fetchPRCategoryApi = createAsyncThunk(ACTIONS.FETCH_PR_CATEGORY,
  async (arg, thunkAPI) => {
    try {
      return await API_SERVICE.get(APIS.GET_PRCATEGORY)
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  },
  {
    condition: (arg, { getState, extra }) => {
      const isUpdated = getState().root.pr_requisition.isUpdatedCategory
      if (isUpdated) {
        console.log('enable fetch condition in reducer')
        return false
      }
    },
  }
)

export const fetchRequisitionItem = createAsyncThunk(ACTIONS.GET_PR_ITEMS,
  async (arg, thunkAPI) => {
    try {
      return await API_SERVICE.get(`${APIS.GET_REQUISITION_DETAIL}?ID=${arg.id}`)
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  }
)
export const fetchContractBySupplier = createAsyncThunk(ACTIONS.GET_ITEMS_BY_SUPPLIER,
  async (arg, thunkAPI) => {
    try {
      return await API_SERVICE.get(`${APIS.PURCHASE_CONTRACT_BY_SUPPLIER}?id=${arg.id}&supplierid=${arg.supplierId}`)
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  }
)
export const fetchPRGeneralApi = createAsyncThunk(ACTIONS.FETCH_PR_GENERAL,
  async (arg, thunkAPI) => {
    try {
      const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
      return await API_SERVICE.get(`${APIS.GET_PRODUCT_GENERAL_BY_TYPE}?${arg}&vessel_id=${vessel_name === 'OFFICE' ? 0 : id}`)
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  }
)
export const fetchBulkPRGeneralApi = createAsyncThunk(ACTIONS.GET_BULK_PR_PRODUCT,
  async (arg, thunkAPI) => {
    try {
      return await API_SERVICE.post(APIS.GET_PR_BULK_ITEMS, arg)
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message)
    }
  }
)
export const fetchProjectList = createAsyncThunk(ACTIONS.GET_PR_PROJECT,
  async (arg, thunkAPI) => {
    try {
      return await API_SERVICE.get(`${APIS.GET_PROJECT}`)
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  }
)
export const fetchPurchaseRequisition = createAsyncThunk(ACTIONS.FETCH_PR_LIST,
  async (arg, thunkAPI) => {
    try {
      return await API_SERVICE.get(arg ? `${APIS.GET_PURCHASE_REQUISITION}?${arg}` : APIS.GET_PURCHASE_REQUISITION)
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  }
)

export const addRequistionItems = createAsyncThunk(ACTIONS.ADD_PR_ITEMS,
  async (arg, thunkAPI) => {
    try {
      return await API_SERVICE.post(APIS.SAVE_REQUISITION_ITEMS, arg)
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  })
export const deleteRequistionItems = createAsyncThunk(ACTIONS.DEL_PR_ITEMS,
  async (arg, thunkAPI) => {
    try {
      return await API_SERVICE.post(APIS.SAVE_REQUISITION_ITEMS, arg)
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  })
export const requisitionApproval = createAsyncThunk(ACTIONS.PR_APPROVAL_STATUS,
  async (arg, thunkAPI) => {
    try {
      return await API_SERVICE.post(APIS.SAVE_REQUISITION_APPROVAL, arg)
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  })

export const fetchPurchaseRequisitionList = createAsyncThunk(ACTIONS.GET_PR_LIST,
  async (arg, thunkAPI) => {
    try {
      const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
      return await API_SERVICE.get(arg ? `${APIS.GET_PURCHASE_REQUISITION_LIST}?status=${arg}&vessel_id=${vessel_name === 'OFFICE' ? 0 : id}` : `${APIS.GET_PURCHASE_REQUISITION_LIST}?vessel_id=${vessel_name === 'OFFICE' ? 0 : id}`)
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  }
)
export const filterPurchaseRequisitionList = createAsyncThunk(ACTIONS.FILTER_PR_LIST,
  async (arg, thunkAPI) => {
    try {
      const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
      return await API_SERVICE.get(arg ? `${APIS.GET_PURCHASE_REQUISITION_LIST}?${arg}&vessel_id=${vessel_name === 'OFFICE' ? 0 : id}` : `${APIS.GET_PURCHASE_REQUISITION_LIST}?vessel_id=${vessel_name === 'OFFICE' ? 0 : id}`)
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  }
)
export const fetchAccountList = createAsyncThunk(ACTIONS.GET_ACCOUNT_CODE_ACTION,
  async (arg, thunkAPI) => {
    try {
      return await API_SERVICE.get(`${APIS.GET_ACCOUNT_CODE}`)
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  }
)
export const getPRViewDetails = createAsyncThunk(ACTIONS.GET_PR_VIEW,
  async (arg, thunkApi) => {
    try {
      return await API_SERVICE.get(`${APIS.GET_PR_VIEW}?id=${arg.poId}`)
    } catch (err) {
      thunkApi.rejectWithValue(err)
    }
  })
const initialState = {
  search_status: ['APPROVED', 'Awaiting For Quote', 'Awating for QC', 'Awating for QC Approve', 'BULK PO Created', 'BULK PO Rejected', 'Closed', 'MERGED', 'NEW', 'PO Created', 'PO ISSUED', 'QC Approved', 'QC VERIFIED', 'QUOTE SPLITED', 'Ready for PO', 'REJECTED', 'RFQ SENT'],
  category: [],
  pmsQuantity: '',
  product_general: [],
  bulk_Total: 0,
  purchaseList: [],
  purchase_list_backup: [],
  book_qty: [],
  projectList: [],
  req_id_details: {},
  req_editId: '',
  selected_vesselList: [],
  bulk_editDetail: {},
  prDetails: {},
  accountList: [],
  isUpdated: false,
  isUpdatedCategory: false,
  isUpdateClick: false,
  isDeleted: false
}
export const purchaseRequisitionSlice = createSlice({
  name: 'pr_requisition',
  initialState,
  reducers: {
    storeBookQty: (state, { payload }) => {
      const regex = /^\d*[1-9]\d*$/
      if (state.book_qty.every(x => x.id !== payload.id) && payload.quantity) {
        if (regex.test(payload.quantity)) {
          state.book_qty = [...state.book_qty, payload]
        }
        else {
          toast.error('Quantity must be number')
        }
      }
      else if (state.book_qty.some(x => x.id === payload.id) && payload.quantity) {
        if (regex.test(payload.quantity)) {
          state.book_qty = state.book_qty.filter(x => x.id !== payload.id)
          state.book_qty = [...state.book_qty, payload]
        }
        else {
          toast.error('Quantity must be number')
        }
      }
      else if (state.book_qty.some(x => x.id === payload.id) && payload.quantity === '') {
        state.book_qty = state.book_qty.filter(x => x.id !== payload.id)
      }
      const data = state.product_general.map(v => v.id === payload.id ? Object.assign({}, { ...v, quantity: payload.quantity }) : v)
      state.product_general = [...data]
    },
    deleteItems: (state, { payload }) => {
      // console.log(current(state.req_id_details))
      
      

      const data = state.req_id_details
      let products = state.product_general
      const book_qty = state.book_qty
      data.purchaseReqItemModel = data?.purchaseReqItemModel && data?.purchaseReqItemModel?.length > 0 && data.purchaseReqItemModel.map((x) => {
        return (x.id === payload.id) ? { ...x, isdisabled: "Y" } : x
      })
      products = products.map((x) => {
        return (x.id === payload.id) ? { ...x, isdisabled: "Y" } : x
      })
      state.book_qty = book_qty.map((x) => (x.id === payload.id) ? { ...x, isdisabled: "Y" } : x)
      state.req_id_details = data
      state.product_general = products
      console.log(book_qty,'products')
      // const data = state.product_general.map(v => v.id === payload.id ? Object.assign({}, {...v, isdisabled: 'Y'}) : v)
      // state.product_general=[...data]
    },
    splitPRQuantity: (state, action) => {
      const regex = /^\d*[0-9]\d*$/;
      let splitQty = action.payload.quantity ? action.payload.quantity : "0";
      const currentBookQty = state.req_id_details.purchaseReqItemModel.find(ele => ele.id === action.payload.id);

      if (regex.test(splitQty) && currentBookQty && Number(splitQty) >= 0 && Number(currentBookQty.quantity) >= Number(splitQty)) {
        const updatedQty = String(currentBookQty.quantity - splitQty);
        state.book_qty = state.book_qty.map(ele => ele.id === action.payload.id ? { ...ele, quantity: updatedQty, split_quantity: splitQty } : ele);
      } else if (splitQty) {
        toast.error("Entered split amount is invalid");
        state.book_qty = state.book_qty.map(ele => ele.id === action.payload.id ? { ...ele, quantity: currentBookQty.quantity } : ele);
      }

      return state;

    },
    splitCheck: (state, action) => {
      if (action.payload.quantity === 0) {
        state.book_qty = state.book_qty.map(el => el.id === action.payload.id ? Object.assign({}, { ...action.payload, quantity: action.payload.split_quantity, split_quantity: 0 }) : el)
      } else {
        state.book_qty = state.book_qty.map(el => el.id === action.payload.id ? Object.assign({}, { ...action.payload, quantity: 0, split_quantity: action.payload.quantity }) : el)
      }
    },
    bulkPRquantity: (state, action) => {
      let total = 0
      switch (action.payload.type) {
        case 'QUANTITY':
          state.product_general = state.product_general.map(x => x.id === action.payload.id ? { ...x, quantity: Number(action.payload.quantity), department_id: String(action.payload.department_id)/* ,...commonPayloadFields,isdisabled:'N' */ } : x)
          break;
        case 'PRICE':
          state.product_general = state.product_general.map(x => x.id === action.payload.id ? { ...x, net_Unit_cost: Number(action.payload.net_Unit_cost), subtotal: (Number(x.quantity) * Number(action.payload.net_Unit_cost))/* ,...commonPayloadFields,isdisabled:'N' */ } : x)
          break;
        default:
          return null
      }
      state.product_general.map(x => { x.subtotal && (total = Number(x.subtotal) + Number(total)) })
      state.bulk_Total = total
    },
    searchStatusList: (state, action) => {
      state.purchaseList = state.purchaseList.filter(el => action.payload.includes(el.pr_status))
    },
    dashboardPRSearch: (state, action) => {
      if (action.payload !== "") {
        let filterData = []
        state.purchase_list_backup.map(el => el.pr_name.toLowerCase().search(action.payload.toLowerCase()) !== -1 && filterData.push(el))
        state.purchaseList = filterData
      } else {
        state.purchaseList = state.purchase_list_backup
      }
    },
    clearBulkPR: (state, action) => {
      state.bulk_Total = 0
      state.product_general = []
      state.selected_vesselList = []
      state.bulk_editDetail = {}
    },
    addBulkVesselID: (state, action) => {
      state.selected_vesselList = action.payload
    },
    setUpdateClick: (state, action) => {
      state.isUpdateClick = !state.isUpdateClick
    },
    setDeletedFlag: (state, action) => {
      state.isDeleted = !state.isDeleted
    },
    clearBookQty: (state, action) => {
      state.book_qty = []
    },
    setPMSQuantity: (state, {payload}) => {
      state.pmsQuantity = payload
    },
    clearEditid: (state, action) => {
      state.req_editId = ''
    },
    clearIdDetails: (state, action) => {
      state.req_id_details = {}
    },
    clearProduct: (state, action) => {
      state.product_general = []
    },
    clearPurchaseList: (state, action) => {
      state.purchaseList = []
    },
    resetPRrequisition: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPurchaseRequisitionList.fulfilled, (state, action) => {
        state.isLoading = false
        if (action.payload.data.result.status) {
          state.purchaseList = action.payload.data.result.data
          state.purchase_list_backup = action.payload.data.result.data
        }
        if (action.payload.data.isFaulted) {
          console.log(action.payload.data.result.message);
        }
      })
      .addCase(filterPurchaseRequisitionList.fulfilled, (state, action) => {
        state.isLoading = false
        if (action.payload.data.result.status) {
          state.purchaseList = action.payload.data.result.data
        }
        if (action.payload.data.isFaulted) {
          console.log(action.payload.data.result.message);
        }
      })
      .addCase(fetchPRCategoryApi.fulfilled, (state, action) => {
        state.isLoading = false
        if (action.payload.data.result.status) {
          state.category = action.payload.data.result.data
          state.isUpdated = true
        }
        if (action.payload.data.isFaulted) {
          console.log(action.payload.data.result.message);
        }
      })
      .addCase(`${ACTIONS.FETCH_PR_GENERAL}/fulfilled`, (state, action) => {
        state.isLoading = false
        if (action.payload.data.result.status) {
          //console.log(action.payload.data.result.data, '-------')
          state.product_general = action.payload.data.result.data
          if (state.product_general.every(el => el.main_type === 'WORKORDER' && state.pmsQuantity)) {
            let arr = []
            state.product_general.map(el => arr.push({
              id:el.id,
              product_code: el.product_code,
              product_name: el.product_name,
              product_type: el.product_type,
              main_type: el.main_type,
              unit: el.unit,
              rob: 0,
              is_contract: 0,
              quantity: state.pmsQuantity ? 1 : '',
            }))
            state.book_qty=arr
            //state.product_general=arr
          }
          state.isUpdated = true
        }
        if (action.payload.data.isFaulted) {
          console.log(action.payload.data.result.message);
        }
      }).addCase(`${ACTIONS.FETCH_PR_LIST}/fulfilled`, (state, action) => {
        state.isLoading = false
        if (action.payload.data.result.status) {
          state.purchaseList = action.payload.data.result.data
          state.isUpdated = true

        }
        if (action.payload.data.isFaulted) {
          console.log(action.payload.data.result.message);
        }
      }).addCase(fetchRequisitionItem.fulfilled, (state, action) => {
        state.isLoading = false
        if (action.payload.data.result.status) {
          let arr = action.payload.data.result.data[0].purchaseReqItemModel ? ConvertJson(action.payload.data.result.data[0].purchaseReqItemModel) : []
          let repository = action.payload.data.result.data[0]?.repository ? ConvertJson(action.payload.data.result.data[0].repository) : []
          let payload = action.payload.data.result.data[0]
          state.book_qty = arr
          payload['purchaseReqItemModel'] = arr
          payload['repository'] = repository
          state.req_id_details = payload
          state.bulk_editDetail = payload
          state.product_general = arr
        }
        if (action.payload.data.isFaulted) {
          console.log(action.payload.data.result.message);
        }
      }).addCase(fetchContractBySupplier.fulfilled, (state, action) => {
        state.isLoading = false
        if (action.payload.data.result.status) {
          let arr = ConvertJson(action.payload.data.result.data[0].purchaseReqItemModel)
          let repository = action.payload.data.result.data[0]?.repository ? ConvertJson(action.payload.data.result.data[0].repository) : []
          let payload = action.payload.data.result.data[0]
          state.book_qty = arr
          payload['purchaseReqItemModel'] = arr
          payload['repository'] = repository
          state.req_id_details = payload
          state.bulk_editDetail = payload
          state.product_general = arr
        }
        if (action.payload.data.isFaulted) {
          console.log(action.payload.data.result.message);
        }
      }).addCase(addRequistionItems.fulfilled, (state, action) => {
        state.isLoading = false
        if (action.payload.data.result.status) {
          toast.success(action.payload.data.result.message)
          if (action.payload.data.result.data.length > 0) {
            state.req_editId = action.payload.data.result.data[0].id
            if (action.payload.data.result.data[0].is_bulk) {
              let obj = action.payload.data.result.data[0]
              let purchaseReqItemModel = ConvertJson(action.payload.data.result.data[0].purchaseReqItemModel)
              obj.repository = obj.repository && obj.repository !== null ? ConvertJson(obj.repository) : []
              obj.purchaseReqItemModel = purchaseReqItemModel
              state.product_general = purchaseReqItemModel
              state.bulk_editDetail = obj
            }
            state.isUpdateClick = !state.isUpdateClick
            state.book_qty = []
            state.req_id_details = {}
            //state.product_general=[]
          }
        } else { toast.error(action.payload.data.result.message) }
        if (action.payload.data.isFaulted) {
          console.log(action.payload.data.result.message);
        }
      })
      .addCase(deleteRequistionItems.fulfilled, (state, action) => {
        state.isLoading = false
        if (action.payload.data.result.status) {
          toast.success('successfully deleted')
        }
        if (action.payload.data.isFaulted) {
          toast.error(action.payload.data.result.message);
        }
      }).addCase(requisitionApproval.fulfilled, (state, action) => {
        state.isLoading = false
        /* if(action.payload.data.result.status){
          toast.success(action.payload.data.result.message)
          state.book_qty=[]
          //state.product_general=[]
        }else{
          toast.error(action.payload.data.result.message)
        } */
        if (action.payload.data.result.status && action.payload.data.result.data[0].status === 'success') {
          state.book_qty = []
          toast.success(action.payload.data.result.message)
        } else {
          toast.error(action.payload.data.result.data[0].status)
        }
        if (action.payload.data.isFaulted) {
          console.log(action.payload.data.result.message);
        }
      }).addCase(fetchProjectList.fulfilled, (state, action) => {
        if (action.payload.data.result.status) {
          state.projectList = action.payload.data.result.data.map(el => (Object.assign({}, { id: el.id, project_id: el.project_id, project_name: el.project_name })))
        } else {
          toast.success(action.payload.data.result.message)
        }
      }).addCase(fetchAccountList.fulfilled, (state, action) => {
        if (action.payload.data.result.status) {
          state.accountList = action.payload.data.result.data.map(el => (Object.assign({}, { id: el.id, account_code: el.account_code, account_name: el.account_name })))
        } else {
          toast.success(action.payload.data.result.message)
        }
      }).addCase(fetchBulkPRGeneralApi.fulfilled, (state, action) => {
        if (action.payload.data.result.status) {
          state.product_general = action.payload.data.result.data
          state.bulk_Total = 0
          //console.log(action.payload,'a');
        } else {
          toast.success(action.payload.data.result.message)
        }
      })
      .addCase(fetchBulkPRGeneralApi.rejected, (state, action) => {
        toast.error(action.payload)
      }).addCase(getPRViewDetails.fulfilled, (state, action) => {
        if (action.payload.data.result.status) {
          state.prDetails = action.payload.data.result.data
        } else {
          toast.error(action.payload.data.result.message)
        }
      })
  }
})
export const {
  resetPRrequisition, dashboardPRSearch, clearBulkPR, searchStatusList,
  storeBookQty, deleteItems, bulkPRquantity, splitCheck, addBulkVesselID,
  setDeletedFlag, clearBookQty, clearPurchaseList, clearEditid, clearIdDetails,
  clearProduct, setUpdateClick, splitPRQuantity,
  setPMSQuantity
} = purchaseRequisitionSlice.actions
export default purchaseRequisitionSlice.reducer