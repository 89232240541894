import React, { useState, useEffect, useRef } from 'react'
import { Button, Col, FormControl, FormGroup, FormLabel, FormSelect, Row } from 'react-bootstrap'
import { ATTACHMENTS } from '../../constant/CommonInitialvalue'
import { useDispatch, useSelector } from 'react-redux'
import { fetchPMSDropdown, getPMSCompletionLocation, getPMSMaintenanceID } from '../../features/pmsSlice'
import { fetchUserApi } from '../../features/userSlice'
import { fetchPortApi } from '../../features/portSlice'
import ReactDatePicker from 'react-datepicker'
import { addAttach, deleteAttachment, removeAttach } from '../../features/attachmentsSlice'
import { FaTrashAlt } from 'react-icons/fa'
import { nanoid } from '@reduxjs/toolkit'
import { toBase64 } from '../../helper/Support'
import { commonPayloadFields } from '../../helper/storage'
import SimpleBar from 'simplebar-react'
import WOSupplierRate from './WOSupplierRate'
import {hasPermission} from "../../helper/permissions";
import AttachmentCard from '../../components/common/AttachmentCard'
function WOCompletion(props) {
    const { poId, state, setState } = props
    const [show, setShow] = useState(false)
    const dispatch = useDispatch()
    const image_ref = useRef()
    const { supplier_rating_id, supplier_rating, maintenanceDetail,repository } = useSelector((state) => state.root.WO.orderDetails)
    const vessel_id = useSelector((state) => state.root.common.vessel_detailByID.id)
    const port_name_option = useSelector((state) => state.root.port.list.map(el => <option key={el.id} value={el.port_name}>{el.port_name}</option>))
    const completed_by_option = useSelector((state) => state.root.PMS.fields.completed_by.map((el) => <option key={el.id} value={el.id}>{el.typename}</option>))
    const location_option = useSelector((state) => state.root.PMS.pms_complete_location.map((el) => <option key={el.id} value={el.typename}>{el.typename}</option>))
    const status_option = useSelector((state) => state.root.PMS.fields.status.map((el) => <option key={el.id} value={el.typename}>{el.typename}</option>))
    const {Process} = useSelector((state) => state.root.login.loginDemo);
    const permissions = Process.find((v) => v.menuname === 'Purchase' && v.modulename === 'WO Completion')

    const attachment_list = useSelector((state) => state.root.ATTACHMENT.repository.map((el) =>
        <li key={el.nanoID} className='d-flex flex-row'>
            <div className='textOverflow'>{el.filename}</div>
            <span className='text-danger' role="presentation" onClick={() => { dispatch(removeAttach(el.nanoID)) }}><FaTrashAlt /></span>
        </li>))
    useEffect(() => {
        const pmsDropdown = dispatch(fetchPMSDropdown())
        dispatch(fetchUserApi())
        dispatch(getPMSCompletionLocation())
        dispatch(fetchPortApi())
        maintenanceDetail?.length>0?
        setState({...state,...maintenanceDetail[0],repository,completion_date: new Date(`${maintenanceDetail[0].completion_date}Z`) })
        :setState({...state,head_id:poId})
        /* const fetchData = () => {
            dispatch(getPMSMaintenanceID(id)).unwrap().then(res => {
                if (res.data.result.status) {
                    let maintenance_detail_header = res.data.result.data[0]
                    let maintainDetail_response = res.data.result.data[0].maintenanceDetail
                    if (maintainDetail_response.length && maintenance_detail_header.due_status_id === "IN PROGRESS") {
                        setState({ ...maintainDetail_response[0], completion_date: new Date(`${maintainDetail_response[0].completion_date}Z`) })
                    } else {
                        setState({ ...state, head_id: id })
                    }
                }
            })
        }
        fetchData() */
        return () => {
            pmsDropdown.abort()
        }
    }, [maintenanceDetail])
    const handleInputChange = (e) => {
        const { name, value } = e.target
        setState({ ...state, [name]: value })
    }
    const handleAttachment = (e) => {
        /* let trans_id = receive_attachment && receive_attachment.length > 0 ? receive_attachment[0].trans_id : 0 */
        const files = e.target.files[0]
        toBase64(files).then(res => {
            dispatch(addAttach({ ...ATTACHMENTS, trans_id: 0, format: files.type, attachment: res, module: "PMS", screen: "WORKORDER", filename: files.name, isdisabled: 'N', ...commonPayloadFields, nanoID: nanoid() }))
        })
    }
    const onDeleteFile = (x) => {
        const payload = {
          id: x,
          module: "PMS",
          screen: "WORKORDER",
          trans_id: 0,
        };
        dispatch(deleteAttachment({ payload }))
          .unwrap()
          .then((r) => {
            props.repositoryReset()
          });
      };
    const handleShow = () => setShow(!show)
    return (
        <>
            <WOSupplierRate show={show} handleShow={handleShow} />
            {
                hasPermission('add', permissions?.permission) && <Row>
                    <Col md={12} className='d-flex justify-content-end'>
                        <Button onClick={() => handleShow()} className={`btn btn-primary ${supplier_rating_id ? 'btnApprove ' : 'btnSave'}`}  >Supplier&nbsp;Rating</Button>
                    </Col>
                </Row>
            }

            <Row className='d-flex align-items-center mb-1'>
                <Col md={3} >
                    <FormLabel className=''>Completion Details</FormLabel>
                </Col>
                <Col md={9}>
                    <hr />
                </Col>
            </Row>
            {/* <Row className='mb-2 align-items-center position-relative'> */}

            <Row className='mb-2'>
                <Col md={3}>
                    <FormGroup>
                        <FormLabel className='text-muted label-grey'>Completion Date<span className='text-danger'>*</span></FormLabel>
                        <ReactDatePicker
                            className='form-control content-grey'
                            selected={state.completion_date}
                            onChange={(val) => setState({ ...state, completion_date: val })}
                        />
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <FormLabel className='text-muted label-grey'>Location<span className='text-danger'>*</span></FormLabel>
                        <FormSelect className={'content-grey'} name={'location'} value={state.location} onChange={(e) => {
                            handleInputChange(e)
                        }}>
                            <option>select</option>
                            {location_option}
                        </FormSelect>
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <FormLabel className='text-muted label-grey'>Port Name<span className='text-danger'>*</span></FormLabel>
                        {/* <FormControl name={'port_name'} value={state.port_name} onChange={(e) => handleInputChange(e)} /> */}
                        <FormSelect className={'content-grey'} name={'port_name'} value={state.port_name} onChange={(e) => handleInputChange(e)} disabled={state.location === 'PORT' ? false : true} >
                            <option value=''>select</option>
                            {port_name_option}
                        </FormSelect>
                    </FormGroup>
                </Col>
                {/* <Col md={2}>
                            <FormGroup>
                                <FormLabel className='text-muted label-grey'>Status<span className='text-danger'>*</span></FormLabel>
                                <FormSelect className={'content-grey'} name={'status_id'} value={state.status_id} onChange={(e) => handleInputChange(e)}>
                                    <option>select</option>
                                    {status_option}
                                </FormSelect>
                            </FormGroup>
                        </Col> */}
                <Col md={3}>
                    <FormGroup>
                        <FormLabel className='text-muted label-grey'>Completed By<span className='text-danger'>*</span></FormLabel>
                        <FormSelect className={'content-grey'} name={'completed_by'} value={state.completed_by} onChange={(e) => handleInputChange(e)}>
                            <option>select</option>
                            {completed_by_option}
                        </FormSelect>
                    </FormGroup>
                </Col>
            </Row>
            <Row className='mb-2 align-items-center'>
                <Col md={3}>
                    <FormGroup>
                        <FormLabel className='text-muted label-grey'>Remarks<span className='text-danger'>*</span></FormLabel>
                        <FormControl className={'content-grey'} name={'remarks'} value={state.remarks} onChange={(e) => handleInputChange(e)} />
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <FormLabel className='text-muted label-grey'>Service done detail<span className='text-danger'>*</span></FormLabel>
                        <FormControl className={'content-grey'} name={'service_detail'} value={state.service_detail} onChange={(e) => handleInputChange(e)} />
                    </FormGroup>
                </Col>
                {/* <Col md={2}>
                            <FormGroup>
                                <FormLabel className='text-muted label-grey'>Actual Man Hour(s)</FormLabel>
                                <FormControl className={'content-grey'} type="number" name={'actual_man_hour'} value={state.actual_man_hour} onChange={(e) => handleInputChange(e)} />
                            </FormGroup>
                        </Col> */}
                {/* <Col md={3}>
                            <FormGroup>
                                <FormLabel className='text-muted label-grey'>Defect<span className='text-danger'>*</span></FormLabel>
                                
                                <FormSelect className={'content-grey'} name={'defect'} value={state.defect} onChange={(e) => handleInputChange(e)}>
                                    <option value=''>select</option>
                                    <option value='YES'>YES</option>
                                    <option value='NO'>NO</option>
                                </FormSelect>
                            </FormGroup>
                        </Col> */}
                <Col md={3}>
                    <FormGroup>
                        <FormLabel className='text-muted label-grey'>Defect details<span className='text-danger'>*</span></FormLabel>
                        <FormControl className={'content-grey'} name={'defect_details'} value={state.defect_details} onChange={(e) => handleInputChange(e)} />
                    </FormGroup>
                </Col>
                {/* <Col md={3}>
                            <Button className='mt-4' disabled={state.defect === 'NO' || state.defect === ''} onClick={() => sentToWO()} >Sent to WO</Button>
                        </Col> */}
            </Row>

            <Row className='mt-3 mb-3'>
                <Col md={4}>
                    <input type='file' style={{ display: 'none' }} ref={image_ref} onChange={(e) => handleAttachment(e)} />
                    {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                    <div className={'attachment-label'} onClick={() => { image_ref.current.click() }}>Attachments <span className='text-danger'>*</span></div>
                    <AttachmentCard repositoryList={state?.repository} attachment_list={attachment_list} onDeleteFile={onDeleteFile}  />
                </Col>
                {/* <Col md={4} >
                    <div className='d-flex justify-content-between align-items-center bg-white'>
                        <FormLabel>Attachments</FormLabel>
                        <input type='file' style={{ display: 'none' }} ref={image_ref} onChange={(e) => handleAttachment(e)} />
                        <Button className={'attach-btn'} size='sm' onClick={() => { image_ref.current.click() }}>Attachments</Button>
                    </div>                    
                    <div className="w-100 h-75 border p-3 bg-white">
                        <ul style={{ listStyle: 'none', padding: '0px' }}>
                            <SimpleBar style={{ maxHeight: 180 }}>
                                {state?.repository?.length > 0 && state.repository.map((el) => (
                                    <li key={el.id}>
                                        <div className='d-flex align-item-center'>
                                            <a href={`${el.fileurl}`} rel='noreferrer' target='_blank' download>{el.filename}</a>
                                            <span className='text-danger' role="presentation" onClick={() => { onDeleteFile(el.id) }}>&nbsp; &nbsp;<FaTrashAlt /></span>
                                        </div>
                                    </li>))}
                                {attachment_list}
                            </SimpleBar>
                        </ul>
                    </div>
                </Col> */}
            </Row>
        </>
    )
}

export default WOCompletion