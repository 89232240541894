import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import {
    GET_LOCATION,
    GET_TYPE_FOLIO,
    GET_TYPE_INOILCAT,
    GET_TYPE_INOILTYPE,
    GET_TYPE_PGBOOKTYPE,
    GET_TYPE_PGCHARTTYPE,
    GET_TYPE_PGINTYPE,
    GET_TYPE_REGION,
    GET_TYPE_UNIT,
    GET_ROLE,
    GET_USER,
    GET_MODULE,
    GET_TYPE_BUDGET,
    GET_TYPE_STORECAT,
    GET_MENU,
    GET_GRADE,
    GET_PURCHASE_PRODUCT,
    GET_APPROVE,
    GET_APPROVE_LEVEL,
    GET_TYPE_PR_TYPE,
    GET_TYPE_ROB_UNIT,
    GET_TYPE_STORETYPE,
    GET_DESIGNATION_SIDE,
    GET_EDOC_VERSION_TYPE,
    CONSUMPTION_STOCK,
    MEETING_FREQUENCY,
    AGENDA_TYPE,
    VESSELFLAG,
    VESSELCAOACITY,
    VESSELGROSSTONNAGE,
    VESSELSHORTNAME,
    VESSELPORTOFREGISTRY
} from "../constant/CommonApiUrl"

import { API_SERVICE } from "../Services/CommonApi";
import * as ACTIONS from "../constant/Actions";
import * as APIS from "../constant/CommonApiUrl"
import { toast } from "react-toastify";

const FETCH_TYPE_FIELDS = 'typeFields/fetchTypeFields'

const TYPE_FIELDS = {
    PGINTYPE: GET_TYPE_PGINTYPE,
    FOLIO: GET_TYPE_FOLIO,
    REGION: GET_TYPE_REGION,
    LOCATION: GET_LOCATION,
    PGCHARTTYPE: GET_TYPE_PGCHARTTYPE,
    PGBOOKTYPE: GET_TYPE_PGBOOKTYPE,
    INOILCAT: GET_TYPE_INOILCAT,
    INOILTYPE: GET_TYPE_INOILTYPE,
    UNIT: GET_TYPE_UNIT,
    BUDGETS: GET_TYPE_BUDGET,
    STORE_CATS: GET_TYPE_STORECAT,
    STORE_TYPES: GET_TYPE_STORETYPE,
    ROLES: GET_ROLE,
    USERS: GET_USER,
    MODULES: GET_MODULE,
    MENUS: GET_MENU,
    APPROVALS: GET_APPROVE,
    APPROVAL_LEVELS: GET_APPROVE_LEVEL,
    PURCHASE_PRODUCTS: GET_PURCHASE_PRODUCT,
    GRADES: GET_GRADE,
    PR_TYPE: GET_TYPE_PR_TYPE,
    ROB_UNIT: GET_TYPE_ROB_UNIT,
    DESIGNATION_SIDE: GET_DESIGNATION_SIDE,
    DIFFERENT_TYPE: APIS.DIFFERENT_TYPE,
    EDOC_VERSIONTYPE: GET_EDOC_VERSION_TYPE,
    STOCKTYPE: CONSUMPTION_STOCK,
    MEETING_FREQUENCY: MEETING_FREQUENCY,
    VESSELFLAG: VESSELFLAG,
    VESSELCAOACITY: VESSELCAOACITY,
    VESSELGROSSTONNAGE: VESSELGROSSTONNAGE,
    VESSELSHORTNAME: VESSELSHORTNAME,
    VESSELPORTOFREGISTRY: VESSELPORTOFREGISTRY,
    AGENDA_TYPE: AGENDA_TYPE,
}

export const fetchTypeFields = createAsyncThunk(FETCH_TYPE_FIELDS,
    async (arg, thunkAPI) => {
        try {
            let types = {}
            arg?.type && arg?.type.map((e) => {
                types = Object.assign({}, { ...types, [e]: TYPE_FIELDS[e] })
            })
            const objData = arg && arg?.type ? types : TYPE_FIELDS
            const typeFieldEntries = Object.entries(objData);
            const typeFieldUrls = typeFieldEntries.map(([_, url]) => url);
            const result = await Promise.all(
                typeFieldUrls.map((url) => API_SERVICE.get(url))
            );
            const typeFieldsData = result.map((res, index) => ({
                [typeFieldEntries[index][0]]: res.data.result.data,
            }));
            return Object.assign({}, ...typeFieldsData);
        } catch (err) {
            return thunkAPI.rejectWithValue(err.message)
        }
    }
)
export const fetchProductType = createAsyncThunk(ACTIONS.GET_PURCHASE_PRODUCT_TYPE,
    async (arg, thunkAPI) => {
        try {
            return API_SERVICE.get(APIS.GET_PURCHASE_PRODUCT_TYPE)
        } catch (err) {
            return thunkAPI.rejectWithValue(err)
        }
    }
)

const initialState = {
    fields: Object.assign({}, ...Object.keys(TYPE_FIELDS).map(ele => ({ [ele]: [] }))),
    isUpdated: true,
    productType: []
}

export const typeFieldsSlice = createSlice({
    name: 'typeFields',
    initialState,
    reducers: {
        setTypeFieldsIsUpdated: (state, action) => {
            state.isFieldsUpdated = action.payload;
        },
        resetTypeFields: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchTypeFields.fulfilled, (state, action) => {
                state.fields = action.payload;
                state.isUpdated = false;
            })
            .addCase(fetchProductType.fulfilled, (state, { meta, payload }) => {
                if (payload.data.result.status) {
                    state.productType = payload.data.result.data
                }
            })
            .addCase(fetchTypeFields.rejected, (state, { meta, payload }) => {
                toast.error(payload)
            })
    }
})
export const { resetTypeFields, setTypeFieldsIsUpdated } = typeFieldsSlice.actions
export default typeFieldsSlice.reducer