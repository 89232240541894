import { createSlice } from "@reduxjs/toolkit";
import { AddEditResponse, ValidateResponse } from "../Services/Support";
import { getVesselInspection, getVesselInspectionMaster, getVesselInspectionMasterbyId, getVesselInspectionReport, getVesselInspectionReportbyId, getVesselInspectionSub, getVesselInspectionSubbyId, getVesselInspectionbyId, saveVesselInspection, saveVesselInspectionMaster, saveVesselInspectionReport, saveVesselInspectionSub } from "./THUNK/vesselInspectionThunk";
import { ConvertJson } from "../helper";
import { getTypeBasedOnType } from "./THUNK/typeMasterThunk";

const initialState = {
    field: {}
}
const typeMasterSlice = createSlice({
    name: 'typemaster',
    initialState,
    reducers: ({

    }),
    extraReducers: (builder) => {
        builder
            .addCase(getTypeBasedOnType.fulfilled, (state, action) => {
                state.field = action.payload
            })
    }
})
export default typeMasterSlice.reducer