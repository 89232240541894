export const FETCH_PR_CATEGORY = 'pr_requisition/fetchCategory'
export const FETCH_PR_GENERAL = 'pr_requisition/fetchProductGeneral'
export const FETCH_CONTRACT_GENERAL = 'contract/fetchProductGeneral'
export const FETCH_PR_LIST = 'pr_requisition/fetchProductList'
export const GET_PR_LIST = 'pr_requisition/fetchPurchaseRequisitionList'
export const FILTER_PR_LIST = 'pr_requisition/filterPurchaseRequisitionList'
export const GET_PR_PROJECT = 'pr_requisition/projectList'
export const GET_RFQ_PR_LIST = 'RFQ/fetchPurchaseRequisitionList'
export const GET_PR_ITEMS = 'pr_requisition/getRequisitionItems'
export const GET_ITEMS_BY_SUPPLIER = 'pr_requisition/getItemsBySupplier'
export const DEL_PR_ITEMS = 'pr_requisition/delRequisitionItems'
export const GET_ACCOUNT_CODE_ACTION = 'pr_requisition/getAccountCode'
export const FETCH_RFQ_CATEGORY = 'RFQ/fetchCategory'
export const FETCH_RFQ_GENERAL = 'RFQ/fetchProductGeneral'
export const FETCH_RFQ_LIST = 'RFQ/fetchList'
export const FETCH_SUPPLIER_LIST = 'RFQ/supplierList'
export const RFQ_SAVE_ACTION = 'RFQ/save'
export const RFQ_APPROVE_ACTION = 'RFQ/approve'
export const RFQ_DELETE_ACTION = 'RFQ/Delete'
export const RFQ_LIST_ACTION = 'RFQ/getList'
export const RFQ_ALL_LIST_ACTION = 'RFQ/getListAll'
export const RFQ_FILTER_LIST_ACTION = 'RFQ/getListfilter'
export const RFQ_GET_ACTION = 'RFQ/getById'
export const RFQ_GET_QUOTAION_DETAIL = 'RFQ/getRFQQuotaionDetail'
export const RFQ_GET_QUOTATION_STATUS = 'RFQ/RFQ_GET_QUOTATION_STATUS'
export const ADD_PR_ITEMS = 'pr_requisition/addRequistionItems'
export const PR_APPROVAL_STATUS = 'pr_requisition/approvalRequisitionStatus'
export const SUPPLIER_DROPDOWN = 'RFQ/supplierDropdown'
export const DEPT_DROPDOWN = 'common/getDeptDropdown'
export const DESIGNATION_BY_ID = 'designation/GetDesignationDropdown'
export const CURRENCY_DROPDOWN_ACITON = 'common/getCurrencyDropdown'
export const GET_PURCHASE_PRODUCT_TYPE = 'common/GET_PURCHASE_PRODUCT_TYPE'
export const PO_ALL_LIST_ACTION = 'PO/getListAll'
export const PO_STATUS_LIST_ACTION = 'PO/getListSTATUS'
export const GOODS_STATUS_LIST_ACTION = 'Goods/getListSTATUS'
export const SAVE_RFQ_QUOTATION = 'RFQ/saveRFQquotaion'
export const DELETE_RFQ_QUOTATION = 'RFQ/deleteRFQquotaion'
export const VIEW_RFQ_QUOT = 'RFQ/viewRFQquot'
export const QUOTATION_LIST_ACTION = 'Quotation/viewList'
export const QUOTATION_FILTER_ACTION = 'Quotation/filterList'
export const QUOTATION_COMPARE_LIST_ACTION = 'Quotation/compareViewList'
export const QUOTATION_COMPARE_FILTER_ACTION = 'Quotation/compareFilterList'
export const SINGLE_QUOTATION_ACTION = 'Quotation/singleQuotation'
export const SAVE_COMP_QUOTATION_ACTION = 'Quotation/saveCompareQuotation'
export const APPROVE_COMP_QUOTATION_ACTION = 'Quotation/approveCompareQuotation'
export const GET_QCLIST_ACTION = 'qc/summaryList'
export const SAVE_PO_ACTION = 'PO/savePO'
export const GET_QC_SUMMARY_LIST_ACTION = 'po/qcSummaryList'
export const GET_QC_SUMMARY_ID_ACTION = 'po/qcSummaryById'
export const GET_PO_DETAILS_ACTION = 'po/poDetails'
export const GET_PO_CREATION_DATA_ACTION = 'po/getPoCreationData'
export const SAVE_PARTS_ACTION = 'PARTS/savePartAction'//part
export const GET_PARTS_ACTION = 'PARTS/getPartAction'//part
export const GET_EQUIPMENT_LIST = 'PARTS/equipmentList'//part
export const GET_EQUIP_DROPDOWN_ACTION = 'PARTS/getEquipDropdown'//equip dropdown
export const GET_PART_DETAILS_byID_ACTION = 'PARTS/getpartsByID' //part detail by id
export const FETCH_PART_DROPDOWN_ACTION = 'PARTS/fetchDropdownData'//part dropdown
export const LOGIN_CHECK_ACTION = 'login/loginCheck'
export const GET_BULK_PR_PRODUCT = 'pr_requisition/getBulkProductPR'//bull pr
export const PURCHASE_DASHBOARD = 'dashboard/purchase'
export const BULK_PO_APPROVE_ACTION = 'po/approveBulkPO'//po
export const GET_EQUIP_byPART_ACTION = 'equipment/equipmentByPartDetail'//equip
export const SAVE_INVENTORY_TREE_ACTION = 'equipment/saveInventoryTree'//equip
export const SAVE_E_DOC_TREE_ACTION = 'equipment/saveEDocTree'//equip
export const SAVE_INITIAL_REQUEST = 'equipment/INITIAL_REQUEST'//edoc
export const SAVE_REVIEW_REQUEST_ACTION = 'EDOC/SAVE_REVIEW_REQUEST_ACTION'//edoc
export const GET_INITIAL_REQUEST = 'equipment/GET_INITIAL_REQUEST'//edoc
export const GET_COMMENTS_ACTION = 'edoc/GET_COMMENTS'//edoc
export const SAVE_COMMENTS_ACTION = 'edoc/SAVE_COMMENTS_ACTION'//edoc
export const GET_REVIEW_DOC_ACTION = 'edoc/GET_REVIEW_DOC_ACTION'//edoc
export const GET_REVIEW_REQUEST_ACTION = 'edoc/GET_REVIEW_REQUEST_ACTION'//edoc
export const GET_REVIEW_REQUEST_LIST_ACTION = 'edoc/GET_REVIEW_REQUEST_LIST_ACTION'//edoc
export const GET_EDOC_DASHBOARD_ACTION = 'edoc/GET_EDOC_DASHBOARD'//edoc
export const EDOC_DASHBOARD_HISTORY_ACTION = 'edoc/EDOC_DASHBOARD_HISTORY'//edoc
export const EDOC_TYPE_LIST_ACTION = 'edoc/EDOC_TYPE_LIST'//edoc
export const SAVE_DOCUMENT_ACTION = 'equipment/saveDocument'//equip
export const SAVE_CONTRACT_ACTION = 'contract/saveContract'//contract
export const LIST_CONTRACT_ACTION = 'contract/listContract'//contract
export const GET_CONTRACTbyID_ACTION = 'contract/getContractbyID'//contract
export const PO_CONTRACT_SUPPLIER = 'po/getSupplierContract'//po
export const APPROVE_PO = 'po/approvePO'
export const GET_CONTACT_CATTYPE = 'contract/categoryType'//contract
export const PO_ISSUED_LIST = 'po/issuedList'//po
export const SAVE_GOODS = 'Goods/saveGoods'
export const GOODS_LIST = 'Goods/listGoods'
export const SUPPLIER_RATING = 'Goods/supplierRating'
export const GOODS_DETAIL = 'po/goodsdetailbyId'
export const SAVE_INVOICE = 'invoice/saveInvoice'
export const INVOICE_LIST = 'invoice/listInvoice'
export const INVOICE_DETAILS = 'invoice/getInvoice'
export const E_INVOICE_DETAILS = 'invoice/getEInvoice'
export const INVOICE_STATUS = 'invoice/statusCheck'
export const SUPPLIER_RATING_DETAIL = 'Goods/RatingDetails'
export const GET_MR_VIEW = 'Goods/GET_MR_VIEW'
export const GET_PR_VIEW = 'Goods/GET_PR_VIEW'
export const CONSUMPTION_PART_HEAD = 'PARTS/consumptionPartHead'
export const SAVE_CONSUMPTION_PART = 'PARTS/saveConsumptionPart'
export const LIST_CONSUMPTION_PART = 'PARTS/listConsumptionPart'
export const CONSUMPTION_GENERAL_HEAD = 'productGeneral/consumptionGeneralHead'
export const GET_DASHBOARD_GOOD_RECEIVED = 'productGeneral/GET_DASHBOARD_GOOD_RECEIVED'
export const GET_DASHBOARD_STOCK = 'productGeneral/GET_DASHBOARD_STOCK'
export const GET_DASHBOARD = 'productGeneral/GET_DASHBOARD'
export const GET_DASHBOARD_CONSUMPTION = 'productGeneral/GET_DASHBOARD_CONSUMPTION'
export const GET_DASHBOARD_GENERAL_REPORT = 'productGeneral/GET_DASHBOARD_GENERAL_REPORT'
export const GET_DASHBOARD_MEDICINE_EXPIRY = 'productGeneral/GET_DASHBOARD_MEDICINE_EXPIRY'
export const CONSUMPTION_STORE_HEAD = 'store/consumptionStoreHead'
export const CONSUMPTION_OIL_HEAD = 'oilProduct/consumptionOilHead'
export const GOODS_LOCATION_DROPDOWN = 'common/goodsLocation'
export const PMS_LOCATION_COMPLETION = 'PMS/PmsLocation'
export const COUNTRY_DROPDOWN = 'common/country'
export const CURRENCY_DROPDOWN = 'common/currency'
export const SAVE_ROLLBACK = 'rollback/saveRollback'
export const GET_ROB = 'common/getConsumptionRob'
export const GET_AMENDMENT_LIST = 'PO/getamendmentList'
export const GET_AMENDMENT_ID = 'PO/getamendmentID'
export const PR_REPORT_LIST = 'REPORTS/PRReportList'
export const EQUIPMENT_REPORT = 'REPORTS/EQUIPMENT'
export const EQUIPMENT_REPORT_BY_ID = 'REPORTS/EQUIPMENT_REPORT_BY_ID'
export const GOODS_REPORT_LIST = `REPORTS/Goods`
export const INVOICE_REPORT_LIST = `REPORTS/Invoice`
export const REPORT_HISTORY = `REPORTS/REPORT_HISTORY`
export const REPORT_STATUS = `REPORTS/REPORT_STATUS`
export const REPORT_USER_LIST = `REPORTS/REPORT_USER_LIST`
export const REPORT_USER_LIST_ID = `REPORTS/REPORT_USER_LIST_ID`
export const REPORT_REQUEST = `REPORTS/REPORT_REQUEST`
export const PO_REPORT_LIST = `REPORTS/purchaeOrder`
export const QC_REPORT_LIST = `REPORTS/QCList`
export const QUOT_REPORT_LIST = `REPORTS/quotationList`
export const PURCHASE_ITEMS_REPORT_LIST = `REPORTS/purchaseItems`
export const PURCHASE_QUERY_REPORT_LIST = `REPORTS/purchaseQuery`
export const OUTSTANDING_PO_REPORT_LIST = `REPORTS/outstanding`
export const SUPPLIER_RATING_REPORT_LIST = `REPORTS/supplierRating`
export const PURCHASE_QUERY_BY_ID = `REPORTS/PURCHASE_QUERY_BY_ID`
export const RFQ_REPORT_LIST = `REPORTS/rfqreport`
export const GET_COUNTRY_ID = `common/getcountryByid`
export const FETCH_PMS_DROPDOWN_ACTION = 'PMS/fetchDropdownData'
export const CREATE_PMS_COMPONENT = `PMS/cretePMScomponent`
export const PMS_MAINTENANCE_SUMMARY = `PMS/maintenanceSummary`
export const PMS_GENERAL_DASHBOARD = `PMS/dashboardGeneralList`
export const PMS_DEFECT_DASHBOARD = `PMS/dashboardDefectList`
export const GET_PMS_MAINTENANCE = `PMS/maintenanceById`
export const GET_STOCK_PMS = `PMS/getStockPMS`
export const PR_AUDIT_HISTORY = `REPORTS/prAudit`
export const SAVE_CURRENCY_ACTION = `common/SAVE_CURRENCY_ACTION`
export const GET_CURENCY_RPT = `REPORTS/currencyRpt`
export const BASED_TYPE = `PMS/basedType`
export const PMS_SEARCH_TRANSFER = `PMS/transferSearch`
export const PMS_VESSEL_TRANSFER = `PMS/vesselListTransfer`
export const SAVE_PMS_TRANSFER = `PMS/savePmsTransfer`
export const CHECK_PMS_ALLOCATION = `PMS/checkPmsAlocation`
export const DEPT_BASED_DESIGNATION = `common/getDesignationbasedDept`
export const GET_VESSEL_TYPE = `vessel/getVesselType`
export const VESSEL_TYPE_LIST = `vessel/VESSEL_TYPE_LIST`
export const VESSEL_TYPE_BY_ID = `vessel/VESSEL_TYPE_BY_ID`
export const COMPLETION_PMS_HISTORY = `PMS/completionHistory`
export const PMS_RESCHEDULE_REASON = `PMS/getscheduleReason`
export const PMS_COM_STATUS = `PMS/fetchStatusCompletion`
export const GET_DESIGNATION_SHIP = `PMS/getjobcardDesignation`
export const MAINTENANCE_HOUR_SUMMARY = `RUNNING/getMaintenanceHour`
export const MAINTENANCE_HOUR_BYID = `RUNNING/getMaintenanceHourbyID`
export const SAVE_MAINTENANCE_HOUR = `RUNNING/saveRunningHour`
export const PMS_LOCATION_ALL = `common/pmsLocationforAll`
export const CRITICAL_FOR_ALL = `common/criticalforAll` //yes or no
export const GET_STORE_SEARCH = `store/getStoreSearch`
export const PMS_MAINTENANCE_REPORT = `MAINTENANCE_REPORT/getPmsMaintenanceReport`
export const PMS_MAINTENANCE_REPORT_ID = `MAINTENANCE_REPORT/getMaintenanceReportID`
export const PMS_CERTIFICATE_REPORT = `MAINTENANCE_REPORT/getCertificate`
export const PMS_CERTIFICATE_GROUP = `MAINTENANCE_REPORT/certificateGroupDropdown`
export const PMS_CERTIFICATE_REPORT_ID = `MAINTENANCE_REPORT/getCertificateById`
export const PMS_ANALYSIS = `ANALYSIS/getAnalysis`
export const PMS_ANALYSIS_VESSEL = `ANALYSIS/getVesselAnalysis`
export const WO_LIST = 'WO/getWOList'
export const WO_SAVE = 'WO/save'
export const GET_CHECKLIST = `safety/getChecklist`
export const GET_CHECKLIST_ID = `safety/getChecklistbyId`
export const SAVE_CHECKLIST = `safety/addChecklist`
export const GET_AUDIT = `safety/getAudit`
export const GET_INSPECTION = `safety/getInspection`
export const GET_AUDIT_MASTER_ID = `safety/getAuditMasterbyId`
export const GET_INSPECTION_MASTER_ID = `safety/getInspectionMasterbyId`
export const SAVE_AUDIT_MASTER = `safety/saveAudit`
export const SAVE_INSPECTION_MASTER = `safety/saveInspection`
export const SAVE_CATEGORY = `safety/saveCategory`
export const GET_CATEGORY = `safety/getCategory`
export const GET_CATEGORY_ID = `safety/getCategorybyId`
export const GET_SUB_CATEGORY = `safety/getSubCategory`
export const GET_SUB_CATEGORY_ID = `safety/getSubCategorybyId`
export const SAVE_SUB_CATEGORY = `safety/saveSubCategory`
export const GET_ISM = `safety/getISM`
export const GET_ISM_ID = `safety/getISMbyId`
export const SAVE_ISM = `safety/getsaveISM`
export const GET_NC_TYPE = `safety/GetNCType`
export const GET_NC_TYPE_ID = `safety/GetNCTypebyId`
export const SAVE_NC_TYPE = `safety/saveNCType`
export const GET_NC_CATEGORY = `safety/getNCcategory`
export const GET_NC_CATEGORY_ID = `safety/getNCcategorybyId`
export const SAVE_NC_CATEGORY = `safety/saveNCcategory`
export const SAFETY_STATUS_CONDITION = `safety/statusOfCondition`
export const STATUS_COLOR = `safety/statusOfColor`
export const GET_STATUS_CONDITION = `safety/getStatusConditionList`
export const GET_STATUS_CONDITION_ID = `safety/getStatusConditionbyId`
export const SAFETY_SAVE_CONDITION = `safety/saveCondition`
export const GET_WO_COMPLETION = `safety/GetWorkOrderCompletionByID`
export const SAFETY_AUDIT_DROPDOWN = `safety/auditDropdown`
export const SAVE_CHECKLIST_GROUP = `safety/saveChecklistGroup`
export const CHECKLIST_AUTOSEARCH = `safety/autoSearchChecklist`
export const CHECKLIST_BASE_AUDIT = `safety/checklistBaseAudit`
export const SAVE_CHECKLIST_TRANSACTION = `safety/saveChecklistTransaction`
export const GET_CHECKLIST_GROUP_ID = `safety/getChecklistGroupbyId`
export const GET_CHECKLIST_GROUP = `safety/getChecklistGroup`
export const GET_CHECKLIST_TRANSACTION = `safety/getChecklistTransaction`
export const GET_CHECKLIST_TRANSACTION_ID = `safety/getChecklistTransactionID`
export const SAVE_AUDIT_SCHEDULE = `safety/saveAuditSchedule`
export const GET_AUDIT_SCHEDULE = `safety/getAuditSchedule`
export const GET_AUDIT_SCHEDULE_ID = `safety/getAuditSchedulebyId`
export const GET_DOC_CATEGORY = `EDOC/GET_DOC_CATEGORY`
export const SAVE_DOC_CATEGORY = `EDOC/SAVE_DOC_CATEGORY`
export const GET_DOC_CATEGORY_BY_ID = `EDOC/GET_DOC_CATEGORY_BY_ID`
export const GET_CHANGE_REQUEST = `EDOC/GET_CHANGE_REQUEST`
export const SAVE_CHANGE_REQUEST = `EDOC/SAVE_CHANGE_REQUEST`
export const GET_CHANGE_REQUEST_BY_ID = `EDOC/GET_CHANGE_REQUEST_BY_ID`
export const AUDIT_TYPE_DROPDOWN = `safety/getAuditTypeDropdown`
export const INSPECTION_TYPE_DROPDOWN = `safety/getInspectionTypeDropdown`
export const SAVE_AUDIT_COMPLETION = `safety/saveAuditCompletion`
export const GET_AUDIT_COMPLETION = `safety/getAuditCompletion`
export const GET_AUDIT_COMPLETION_ID = `safety/getAuditCompletionbyId`
export const GET_AUDIT_VESSEL_SCHEDULE = `safety/getAuditVesselSchedule`
export const PMS_DASHBOARD_DETAIL = `PMS/getDashboardDetails`
export const GET_CHECKLIST_INSPECTION = `safety/getInpectionChecklist`
export const GET_CHECKLIST_ID_INSPECTION = `safety/getInspectionChecklistbyId`
export const SAVE_CHECKLIST_INSPECTION = `safety/addInspectionChecklist`
export const SAVE_INSPECTION_SCHEDULE = `safety/saveInspectionSchedule`
export const GET_INSPECTION_SCHEDULE = `safety/getInpsectionSchedule`
export const GET_INSPECTION_SCHEDULE_ID = `safety/getInspectionSchedulebyId`
export const GET_INSPECTION_COMPLETION = `safety/getInspectionCompletion`
export const GET_INSPECTION_COMPLETION_ID = `safety/getInspectionCompletionbyId`
export const SAVE_INSPECTION_COMPLETION = `safety/saveInspectionCompletion`
export const SAFETY_INSPECTION_DROPDOWN = `safety/InspectionDropdown`
export const GET_INSPECTION_VESSEL_SCHEDULE = `safety/getInsepectionVesselSchedule`
export const SAVE_AUDIT_RECORD = `safety/saveAuditNCOBS`
export const GET_AUDIT_RECORD_LIST = `safety/getAuditNCOBS`
export const GET_AUDIT_RECORD_ID = `safety/getAuditRecordbyId`
export const GET_PART_REPORT = `REPORTS/getPart`
export const ROB_VIEW_REPORT = `REPORTS/getROBViewbyId`
export const GET_STORE_REPORT = `REPORTS/getStore`
export const STORE_REPORT_VIEW = `REPORTS/getStoreView`
export const STORE_ROB_REPORT_VIEW = `REPORTS/getStoreRobView`
export const GET_OIL_REPORT = `REPORTS/getOilReport`
export const OIL_REPORT_VIEW = `REPORTS/getOilView`
export const OIL_ROB_VIEW = `REPORTS/getOilROBView`
export const GET_DATA_BASED_CHECKLIST = `safety/getDatabasedChecklist`
export const SAVE_NOTES_FOLLOWUP = `safety/saveNotesFollowup`
export const GET_NOTES_FOLLOWUP = `safety/getNotesFollowup`
export const GET_USER_BASED_DESIGNATION = `common/getUserBasedDesignation`
export const MEETING_NAME_MASTER_ACTION = 'meetting/MEETING_NAME_MASTER_ACTION'
export const DRILL_NAME_MASTER_ACTION = 'meetting/DRILL_NAME_MASTER_ACTION'
export const DRILL_SCHEDULE_ACTION = 'meetting/DRILL_SCHEDULE_ACTION'
export const MEETING_AGENDA_MASTER_ACTION = 'meetting/MEETING_AGENDA_MASTER_ACTION'
export const MEETING_SCHEDULE_ACTION = 'meetting/MEETING_SCHEDULE_ACTION'
export const MEETING_NAME_BY_ID_ACTION = 'meetting/MEETING_NAME_BY_ID_ACTION'
export const DRILL_NAME_BY_ID_ACTION = 'meetting/DRILL_NAME_BY_ID_ACTION'
export const DRILL_SCHEDULE_BY_ID_ACTION = 'meetting/DRILL_SCHEDULE_BY_ID_ACTION'
export const MEETING_SCHEDULE_BY_ID_ACTION = 'meetting/MEETING_SCHEDULE_BY_ID_ACTION'
export const MEETING_AGENDA_BY_ID_ACTION = 'meetting/MEETING_AGENDA_BY_ID_ACTION'
export const SAVE_MEETING_NAME_ACTION = 'meetting/SAVE_MEETING_NAME'
export const SAVE_MEETING_RECORD_ACTION = 'meetting/SAVE_MEETING_RECORD'
export const GET_MEETING_RECORD_ACTION = 'meetting/GET_MEETING_RECORD'
export const GET_MEETING_RECORD_ID_ACTION = 'meetting/GET_MEETING_RECORD_ID'
export const SAVE_DRILL_NAME_ACTION = 'meetting/SAVE_DRILL_NAME'
export const SAVE_DRILL_RECORD_ACTION = 'meetting/SAVE_DRILL_RECORD'
export const GET_DRILL_RECORD_ACTION = 'meetting/GET_DRILL_RECORD'
export const GET_DRILL_RECORD_ID_ACTION = 'meetting/GET_DRILL_RECORD_ID'
export const SAVE_DRILL_SCHEDULE_ACTION = 'meetting/SAVE_DRILL_SCHEDULE_ACTION'
export const SAVE_MEETING_SCHEDULE_ACTION = 'meetting/SAVE_MEETING_SCHEDULE_ACTION'
export const SAVE_MEETING_AGENDA_ACTION = 'meetting/SAVE_MEETING_AGENDA_ACTION'
export const SAVE_INCIDENT_MASTER = `incident/saveIncidentMasters`
export const SAVE_INCIDENT_DAMAGE = `incident/saveIncidentDamage`
export const GET_INCIDENT_DAMAGE = `incident/getIncidentDamage`
export const GET_INCIDENT_DAMAGE_ID = `incident/getIncidentDamageId`
export const SAVE_INCIDENT_INJURY = `incident/saveIncidentINJURY`
export const GET_INCIDENT_INJURY = `incident/getIncidentINJURY`
export const GET_INCIDENT_INJURY_ID = `incident/getIncidentINJURYId`
export const GET_INCIDENT_MISS = 'incident/getIncidentMissList'
export const GET_INCIDENT_MISS_ID = 'incident/getIncidentMissId'
export const SAVE_INCIDENT_MISS = 'incident/saveIncidentMiss'
export const GET_INCIDENT_MASTER = `incident/getIncidentMasters`
export const GET_REPORT_DROPDOWN = `incident/getReportBy`
export const GET_INCIDENT_MASTER_ID = `incident/getIncidentMastersbyId`
export const INCIDENT_CATEGORY_TYPE = `incdient/getIncidentCatType`
export const NATURE_OF_INJURY_CATEGOR = `incdient/getNatureInjury`
export const INCIDENT_REPORTED_BY_CATEGOR = `incident/getIncidentReportBy`
export const INCIDENT_DEFICIENCY_TYPE = `incident/getDeficiencyType`
export const INCIDENT_DEFICIENCY_CODE = `incident/getDeficiencyCode`
export const INCIDENT_ITEM_GROUP = `incident/getItemGroup`
export const IDN_TYPE = `incident/getIDNType`
export const immediate_cause_of_damage_pg = 'incident/immediate_cause_of_damage_pg';
export const immediate_cause_of_damage_pg_reason = 'incident/immediate_cause_of_damage_pg_reason';
export const immediate_cause_of_damage_sec = 'incident/immediate_cause_of_damage_sec';
export const immediate_cause_of_damage_sec_reason = 'incident/immediate_cause_of_damage_sec_reason';
export const basic_cause_personal_factory_group = 'incident/basic_cause_personal_factory_group';
export const basic_cause_personal_factory_group_reason = 'incident/basic_cause_personal_factory_group_reason';
export const basic_cause_job_factory_group = 'incident/basic_cause_job_factory_group';
export const basic_cause_job_factory_group_reason = 'incident/basic_cause_job_factory_group_reason';
export const incident_details_of_item = 'incident/incident_details_of_item';
export const incident_damage_programme = 'incident/incident_damage_programme';
export const incident_damage_programme_reason = 'incident/incident_damage_programme_reason';
export const incident_damage_performance_std = 'incident/incident_damage_performance_std';
export const incident_damage_performance_std_reason = 'incident/incident_damage_performance_std_reason';
export const incident_damage_compliance_exit_std = 'incident/incident_damage_compliance_exit_std';
export const incident_damage_compliance_exit_std_reason = 'incident/incident_damage_compliance_exit_std_reason';
export const incident_damage_machinery_breakdown = 'incident/incident_damage_machinery_breakdown';
export const incident_damage_Loss_Severity = 'incident/incident_damage_Loss_Severity';
export const incident_damage_dpa_action = 'incident/incident_damage_dpa_action';
export const incident_damage_closed_out = 'incident/incident_damage_closed_out';
export const injury_port_depature = `incident/injury_port_depature`
export const injury_port_destination = `incident/injury_port_destination`
export const injury_manual_code_ref = `incident/injury_manual_code_ref`
export const injury_probability_of_reoccurrence = `incident/injury_probability_of_reoccurrence`
export const SAVE_VESSEL_TYPE_ACTION = 'settings/SAVE_VESSEL_TYPE_ACTION'
export const VESSEL_TYPE_LIST_ACTION = 'settings/VESSEL_TYPE_LIST_ACTION'
export const nm_unsafe = `incident/nm_unsafe`
export const nm_immediate_cause_primary = `incident/nm_immediate_cause_primary`
export const nm_immediate_cause_primary_reason = `incident/nm_immediate_cause_primary_reason`
export const nm_immediate_cause_secondary = `incident/nm_immediate_cause_secondary`
export const nm_immediate_cause_secondary_reason = `incident/nm_immediate_cause_secondary_reason`
export const nm_basic_cause_personal_factor = `incident/nm_basic_cause_personal_factor`
export const nm_basic_cause_personal_factor_reason = `incident/nm_basic_cause_personal_factor_reason`
export const nm_basic_cause_job_factor = `incident/nm_basic_cause_job_factor`
export const nm_basic_cause_job_factor_reason = `incident/nm_basic_cause_job_factor_reason`

export const SAVE_VESSEL_INSPECTION = `vessel_inspection/saveVesselInspection`
export const GET_VESSEL_INSPECTION = `vessel_inspection/getVesselInspection`
export const GET_VESSEL_INSPECTION_ID = `vessel_inspection/getVesselInspectionId`
export const SAVE_VESSEL_INSPECTION_SUB = `vessel_inspection/saveSubGroup`
export const GET_VESSEL_INSPECTION_SUB = `vessel_inspection/getSubGroup`
export const GET_VESSEL_INSPECTION_SUB_ID = `vessel_inspection/getSubGroupId`
export const SAVE_VESSEL_INSPECTION_MASTER = `vessel_inspection/saveMaster`
export const GET_VESSEL_INSPECTION_MASTER = `vessel_inspection/getMaster`
export const GET_VESSEL_INSPECTION_MASTER_ID = `vessel_inspection/getMasterbyId`
export const SAVE_VESSEL_INSPECTION_TRANS = `vessel_inspection/saveReport`
export const GET_VESSEL_INSPECTION_TRANS = `vessel_inspection/getReport`
export const GET_VESSEL_INSPECTION_TRANS_ID = `vessel_inspection/getReportbyId`
export const GET_VESSEL_INSPECTION_COUNT = `vessel_inspection/getVesselCount`
export const GET_VESSEL_INSPECTION_CONDITION = `vessel_inspection/getConditionStatus`
export const GET_VESSEL_INSPECTION_OPEN = `vessel_inspection/getOpenItem`