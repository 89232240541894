import { createAsyncThunk } from "@reduxjs/toolkit";
import * as ACTION from "../../constant/Actions";
import * as APIS from "../../constant/CommonApiUrl";
import { API_SERVICE } from "../../Services/CommonApi";
import axios from "axios";
export const getIncidentMasterList = createAsyncThunk(ACTION.GET_INCIDENT_MASTER,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(`${APIS.GET_INCIDENT_MASTER}?${arg}&vessel_id=${vessel_name === 'OFFICE' ? 0 : id}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getIncidentMasterById = createAsyncThunk(ACTION.GET_INCIDENT_MASTER_ID,
    async (arg, thunkAPI) => {
        try {
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(`${APIS.GET_INCIDENT_MASTER}?id=${arg}&vessel_id=${id}`)
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const saveIncidentMaster = createAsyncThunk(ACTION.SAVE_INCIDENT_MASTER,
    async (arg, thunkApi) => {
        try {
            const { id, vessel_name } = thunkApi.getState().root.common.vessel_detailByID
            return await API_SERVICE.post(APIS.SAVE_INCIDENT_MASTER, arg)
        } catch (err) {
            thunkApi.rejectWithValue(err)
        }
    })
export const getIncidentDamageList = createAsyncThunk(ACTION.GET_INCIDENT_DAMAGE,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(arg ? `${APIS.GET_INCIDENT_DAMAGE}?${arg}&vessel_id=${vessel_name === 'OFFICE' ? 0 : id}` : `${APIS.GET_INCIDENT_DAMAGE}?vessel_id=${vessel_name === 'OFFICE' ? 0 : id}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getIncidentDamageById = createAsyncThunk(ACTION.GET_INCIDENT_DAMAGE_ID,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(`${APIS.GET_INCIDENT_DAMAGE}?id=${arg}&vessel_id=${vessel_name === 'OFFICE' ? 0 : id}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const saveIncidentDamage = createAsyncThunk(ACTION.SAVE_INCIDENT_DAMAGE,
    async (arg, thunkApi) => {
        try {
            const { id, vessel_name } = thunkApi.getState().root.common.vessel_detailByID
            return await API_SERVICE.post(APIS.SAVE_INCIDENT_DAMAGE, arg)
        } catch (err) {
            thunkApi.rejectWithValue(err)
        }
    })
export const getIncidentInjuryList = createAsyncThunk(ACTION.GET_INCIDENT_INJURY,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(arg ? `${APIS.GET_INCIDENT_INJURY}?${arg}&vessel_id=${vessel_name === 'OFFICE' ? 0 : id}` : `${APIS.GET_INCIDENT_INJURY}?vessel_id=${vessel_name === 'OFFICE' ? 0 : id}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getIncidentInjuryById = createAsyncThunk(ACTION.GET_INCIDENT_INJURY_ID,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(`${APIS.GET_INCIDENT_INJURY}?id=${arg}&vessel_id=${vessel_name === 'OFFICE' ? 0 : id}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const saveIncidentInjury = createAsyncThunk(ACTION.SAVE_INCIDENT_INJURY,
    async (arg, thunkApi) => {
        try {
            const { id, vessel_name } = thunkApi.getState().root.common.vessel_detailByID
            return await API_SERVICE.post(APIS.SAVE_INCIDENT_INJURY, arg)
        } catch (err) {
            thunkApi.rejectWithValue(err)
        }
    })
export const getIncidentMissList = createAsyncThunk(ACTION.GET_INCIDENT_MISS,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(arg ? `${APIS.GET_INCIDENT_MISS}?${arg}&vessel_id=${vessel_name === 'OFFICE' ? 0 : id}` : `${APIS.GET_INCIDENT_MISS}?vessel_id=${vessel_name === 'OFFICE' ? 0 : id}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getIncidentMissId = createAsyncThunk(ACTION.GET_INCIDENT_MISS_ID,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(`${APIS.GET_INCIDENT_MISS}?id=${arg}&vessel_id=${vessel_name === 'OFFICE' ? 0 : id}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const saveIncidentMiss = createAsyncThunk(ACTION.SAVE_INCIDENT_MISS,
    async (arg, thunkApi) => {
        try {
            const { id, vessel_name } = thunkApi.getState().root.common.vessel_detailByID
            return await API_SERVICE.post(APIS.SAVE_INCIDENT_MISS, arg)
        } catch (err) {
            thunkApi.rejectWithValue(err)
        }
    })
export const getIncidentCatType = createAsyncThunk(ACTION.INCIDENT_CATEGORY_TYPE,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(`${APIS.INCIDENT_CATEGORY_TYPE}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getNatureOfInjuryCat = createAsyncThunk(ACTION.NATURE_OF_INJURY_CATEGOR,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(`${APIS.NATURE_OF_INJURY_CATEGOR}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getDeficiencyType = createAsyncThunk(ACTION.INCIDENT_DEFICIENCY_TYPE,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(`${APIS.INCIDENT_DEFICIENCY_TYPE}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getIncidentReportBy = createAsyncThunk(ACTION.INCIDENT_REPORTED_BY_CATEGOR,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(`${APIS.INCIDENT_REPORTED_BY_CATEGOR}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getIDNType = createAsyncThunk(ACTION.IDN_TYPE,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(`${APIS.IDN_TYPE}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getReportBy = createAsyncThunk(ACTION.GET_REPORT_DROPDOWN,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(`${APIS.GET_INCIDENT_MASTER}?master_name=reported_by&vessel_id=${vessel_name === 'OFFICE' ? 0 : id}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getDeficiencyCode = createAsyncThunk(ACTION.INCIDENT_DEFICIENCY_CODE,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(`${APIS.GET_INCIDENT_MASTER}?master_name=deficiency&vessel_id=${vessel_name === 'OFFICE' ? 0 : id}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getCheckItemGroup = createAsyncThunk(ACTION.INCIDENT_ITEM_GROUP,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(`${APIS.GET_INCIDENT_MASTER}?master_name=incident_checklist_item_group&vessel_id=${vessel_name === 'OFFICE' ? 0 : id}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })