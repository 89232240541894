import React, { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom"
// import { Navigate } from "react-router"
// import ProtectedRoute from "./ProtectedRoute";
import Protected from "./Protected";
import { MASTERS_SCREENS, REPORTS } from "../constant/CommonInitialvalue";
import Layout from "../components/Layout";
import WithoutNav from "../components/Layout/WithoutNav";
// const Search = lazy(() => import("../pages/search/Search"));
const Purchase = lazy(() => import("../pages/purchase"));
const PurchaseUi = lazy(() => import("../pages/purchase/ui"));
import Test from "../theme/Test";
/* import PurchaseRequisition from "../pages/purchase/purchase_requisition"; */
const PurchaseReqNew = lazy(() => import("../pages/purchase/purchase_requisition/purchaseReqNew"));
const Login = lazy(() => import("../pages/root/Login"));
const SaasLogin = lazy(() => import("../pages/root/SaasLogin"));
const RFQList = lazy(() => import("../pages/RFQ"));
const CreateRFQ = lazy(() => import("../pages/RFQ/CreateRFQ"))
const PurchaseReqEdit = lazy(() => import("../pages/purchase/purchase_requisition/PurchaseReqEdit"))
const PurchaseReqSplit = lazy(() => import("../pages/purchase/purchase_requisition/PurchaseReqSplit"));
const EditRFQ = lazy(() => import("../pages/RFQ/EditRFQ"));
const PoList = lazy(() => import("../pages/PO"));
const CreatePo = lazy(() => import("../pages/PO/CreatePo"));
const QCList = lazy(() => import("../pages/QC"));
const AddQC = lazy(() => import("../pages/QC/AddQC"));
const CreateQC = lazy(() => import("../pages/RFQ/CreateQC"));
const SaveQC = lazy(() => import("../pages/RFQ/SaveQC"));
const StoreUi = lazy(() => import("../pages/inventory/StoreUi"));
const ComparisonQC = lazy(() => import("../pages/QC/ComparisonQC"));
const POList = lazy(() => import("../pages/PO/POList"));
const EditQC = lazy(() => import("../pages/QC/EditQC"));

const Part = lazy(() => import("../pages/inventory/part"));
const PartPreview = lazy(() => import("../pages/inventory/partPreview"));
const Equipment = lazy(() => import("../pages/inventory/equipment"));
const StoreForm = lazy(() => import("../pages/inventory/StoreForm"));
const PurchaseBulkNew = lazy(() => import("../pages/purchase/purchase_requisition/PurchaseBulkNew"));
const PurchaseBulkEdit = lazy(() => import("../pages/purchase/purchase_requisition/PurchaseBulkEdit"));
const ContractRate = lazy(() => import("../pages/inventory/ContractRate"));
const ContractForm = lazy(() => import("../pages/inventory/ContractForm"));
const ContractFormEdit = lazy(() => import("../pages/inventory/ContractFormEdit"));
const GoodsList = lazy(() => import("../pages/Goods/GoodsList"));
const AddGoods = lazy(() => import("../pages/Goods/AddGoods"));
// const JsonTable = lazy(() => import("../components/common/JsonTable"));
const InvoiceList = lazy(() => import("../pages/invoices/InvoiceList"));
const EditPo = lazy(() => import("../pages/PO/EditPo"));
const Invoice = lazy(() => import("../pages/invoices/Invoice"));
const EditGoods = lazy(() => import("../pages/Goods/EditGoods"));
const ConsumptionPART = lazy(() => import("../pages/inventory/ConsumptionPART"));
const ConsumptionGENERAL = lazy(() => import("../pages/inventory/ConsumptionGENERAL"));
const ConsumptionStore = lazy(() => import("../pages/inventory/ConsumpitonStore"));
const Requisition = lazy(() => import("../pages/Reports/Requisition"));
const SplitQC = lazy(() => import("../pages/QC/SplitQC"));
const EditSplitQC = lazy(() => import("../pages/QC/EditSplitQC"));
const ConsumptionOil = lazy(() => import("../pages/inventory/ConsumptionOil"));
// const JsonToPdf = lazy(() => import("../helper/JsonToPdf"));
const SplitPO = lazy(() => import("../pages/PO/SplitPO"));
const Quotation = lazy(() => import("../pages/Reports/Quotation"));
const QC = lazy(() => import("../pages/Reports/QC"));
const Order = lazy(() => import("../pages/Reports/Order"));
const InvoiceReport = lazy(() => import("../pages/Reports/InvoiceReport"));
const GoodsReport = lazy(() => import("../pages/Reports/GoodsReport"));
const RFQReport = lazy(() => import("../pages/Reports/RFQReport"));
const EInvoice = lazy(() => import("../pages/invoices/E_InvoiceList"));
const CurrencyReport = lazy(() => import("../pages/Reports/CurrencyReport"));

// import Certificate from "../pages/Masters/Forms/Certificate";

const PurchaseItemHistory = lazy(() => import("../pages/Reports/PurchaseItemHistory"));
// const PurchaseQuery = lazy(() => import("../pages/Reports/PurchaseQuery"));
const OutstandingPo = lazy(() => import("../pages/Reports/OutstandingPo"));
const PurchaseQueryReports = lazy(() => import("../pages/Reports/PurchaseQueryReports"));
const SupplierRating = lazy(() => import("../pages/Reports/SupplierRating"));
const InvReport = lazy(() => import("../pages/Reports/InvReport"));

const EDocDashboard = lazy(() => import("../pages/EDOC/DashBoard"));
const EDoc = lazy(() => import("../pages/EDOC"));
const DocumentInitiatedList = lazy(() => import("../pages/EDOC/List/DocumentInitiated"));
const CheckoutList = lazy(() => import("../pages/EDOC/List"));
const CheckinList = lazy(() => import("../pages/EDOC/List"));
const ReviewRequestApproveList = lazy(() => import("../pages/EDOC/List"));
const RejectedDocumentList = lazy(() => import("../pages/EDOC/List"));
const NewReviewedDocumentList = lazy(() => import("../pages/EDOC/List"));
const AllDocumentsList = lazy(() => import("../pages/EDOC/List"));
const InitialList = lazy(() => import("../pages/EDOC/List"));
const InitiateRequest = lazy(() => import("../pages/EDOC/InitiateRequest"));
const ReviewRequest = lazy(() => import("../pages/EDOC/ReviewRequest"));
const DocCategoryMaster = lazy(() => import("../pages/EDOC/Masters/DocCategoryMaster"));
const ChangeRequestType = lazy(() => import("../pages/EDOC/Masters/ChangeRequestType"));
const Create = lazy(() => import("../pages/EDOC/Create"));
// const StoreCategoryTree = lazy(() => import("../pages/inventory/STORE/StoreCategoryTree"))

const PMS = lazy(() => import("../pages/PMS"));
const CreatePMS = lazy(() => import("../pages/PMS/CreatePMS"));
const Jobcard = lazy(() => import("../pages/PMS/Jobcard"));
const CompletionPMS = lazy(() => import("../pages/PMS/CompletionPMS"));
const TaskList = lazy(() => import("../pages/PMS/TaskList"));
const Schedule = lazy(() => import("../pages/PMS/SCHEDULE"))
const UnsheduleForm = lazy(() => import("../pages/PMS/SCHEDULE/UnsheduleForm"))
const CertificatePMS = lazy(() => import("../pages/PMS/CERTIFICATE"))
const Hour = lazy(() => import("../pages/PMS/RUNNING"));
const Speedknot = lazy(() => import("../pages/PMS/SPEEDGROUND/Speedknot"))
const PMSReport = lazy(() => import("../pages/PMS/REPORTS/MAINTENANCE"))
const PMSCertificateReport = lazy(() => import("../pages/PMS/REPORTS/CERTIFICATE"))
const WorkOrder = lazy(() => import("../pages/WO"))

import Analysis from "../pages/PMS/ANALYSIS";
import Charts from "../pages/PMS/PMSWave";
import WOForm from "../pages/WO/WOForm";
import NotFound from "./NotFound";
const HistoryTrack = lazy(() => import("../pages/Reports/EDoc/HistoryTrack"));
const ReportStatus = lazy(() => import("../pages/Reports/EDoc/StatusTrack"));
const ReportUserList = lazy(() => import("../pages/Reports/EDoc/ReportUserList"));
const ReportRequest = lazy(() => import("../pages/Reports/EDoc/ReportRequestList"));
const InspectionMaster = lazy(() => import("../pages/SAFETY/MASTER/InspectionMaster"));
const CheckListFor = lazy(() => import("../pages/SAFETY/MASTER/CheckListFor"));
const ChecklistItemGroup = lazy(() => import("../pages/SAFETY/MASTER/ChecklistItemGroup"));
const AuditMaster = lazy(() => import('../pages/SAFETY/MASTER/AuditMaster'))
const NCobs = lazy(() => import('../pages/SAFETY/MASTER/NCobs'))
const RootCause = lazy(() => import('../pages/SAFETY/MASTER/RootCause'))
const NCobsSub = lazy(() => import('../pages/SAFETY/MASTER/NCobsSub'))
const ISM = lazy(() => import('../pages/SAFETY/MASTER/ISM'))
const NCType = lazy(() => import('../pages/SAFETY/MASTER/NCType'))
const NCcategory = lazy(() => import('../pages/SAFETY/MASTER/NCcategory'))
const PortState = lazy(() => import('../pages/SAFETY/MASTER/PortState'))
const CaseClose = lazy(() => import('../pages/SAFETY/MASTER/CaseClose'))
const StatusCondition = lazy(() => import('../pages/SAFETY/MASTER/StatusCondition'))
const AuditChecklist = lazy(() => import("../pages/SAFETY/AUDIT/CHECKLIST"))
const CreateChecklist = lazy(() => import("../pages/SAFETY/AUDIT/CHECKLIST/CreateChecklist"))
const AuditScheduleList = lazy(() => import("../pages/SAFETY/AUDIT/SCHEDULE"))
const AuditScheduleForm = lazy(() => import("../pages/SAFETY/AUDIT/SCHEDULE/AuditSchedule"))
const AuditPlan = lazy(() => import("../pages/SAFETY/AUDIT/PLANNING"))
const AuditCompletion = lazy(() => import("../pages/SAFETY/AUDIT/COMPLETION"))
const CompletionEntry = lazy(() => import("../pages/SAFETY/AUDIT/COMPLETION/CompletionEntry"))
const PlannerCompletion = lazy(() => import("../pages/SAFETY/AUDIT/PLANNING/PlannerCompletion"))
const PmsDashboard = lazy(() => import("../pages/PMS/Dashborad"))
const AllCertificate = lazy(() => import("../pages/PMS/Dashborad/CertificateList"))
const AllDefect = lazy(() => import("../pages/PMS/Dashborad/DefectList"))
const InspectionChecklist = lazy(() => import("../pages/SAFETY/INSPECTION/CHECKLIST"))
const InspectionCreateChecklist = lazy(() => import("../pages/SAFETY/INSPECTION/CHECKLIST/CreateChecklist"))
const InspectionScheduleList = lazy(() => import("../pages/SAFETY/INSPECTION/SCHEDULE"))
const InspectionScheduleForm = lazy(() => import("../pages/SAFETY/INSPECTION/SCHEDULE/InspectionSchedule"))
const InspectionPlan = lazy(() => import("../pages/SAFETY/INSPECTION/PLANNING"))
const InspectionPlannerCompletion = lazy(() => import("../pages/SAFETY/INSPECTION/PLANNING/PlannerCompletion"))
const InspectionCompletion = lazy(() => import("../pages/SAFETY/INSPECTION/COMPLETION"))
const InspectionCompletionEntry = lazy(() => import("../pages/SAFETY/INSPECTION/COMPLETION/CompletionEntry"))
const MeetingNameMasterList = lazy(() => import("../pages/SAFETY/MEETING/MASTER/MeetingNameMaster"))
const DrillNameMasterList = lazy(() => import("../pages/SAFETY/MEETING/MASTER/DrillNameMaster"))
const MeetingScheduleList = lazy(() => import("../pages/SAFETY/MEETING/MASTER/MeetingSchedule"))
const DrillScheduleList = lazy(() => import("../pages/SAFETY/MEETING/MASTER/Drill"))
const MeetingAgendaMasterList = lazy(() => import("../pages/SAFETY/MEETING/MASTER/MeetingAgendaMaster"))
const MeetingRecordList = lazy(() => import("../pages/SAFETY/MEETING/index"))
const EquipmentPartReport = lazy(() => import('../pages/Reports/INVENTORY'))
const AuditRecordList = lazy(() => import("../pages/SAFETY/NCOBS/AUDIT RECORD"))
const InspectionRecordList = lazy(() => import("../pages/SAFETY/NCOBS/INSPECTION RECORD"))
const AuditInfo = lazy(() => import("../pages/SAFETY/NCOBS/AUDIT RECORD/AuditInfo"))
const InspectionInfo = lazy(() => import("../pages/SAFETY/NCOBS/INSPECTION RECORD/AuditInfo"))
const DamageMaster = lazy(() => import("../pages/SAFETY/INCIDENT/MASTER"))
const IncidentTrans = lazy(() => import("../pages/SAFETY/INCIDENT/TRANSACTION"))
const IncidentDamageForm = lazy(() => import("../pages/SAFETY/INCIDENT/TRANSACTION/DAMAGE"))
const IncidentInjuryForm = lazy(() => import("../pages/SAFETY/INCIDENT/TRANSACTION/INJURY"))
const IncidentNearMissForm = lazy(() => import("../pages/SAFETY/INCIDENT/TRANSACTION/NEAR MISS"))
const VesselItemGroup = lazy(() => import('../pages/SAFETY/MASTER/VesselItemGroup'));
const VesselItemSubGroup = lazy(() => import('../pages/SAFETY/MASTER/VesselItemSubGroup'));
const VesselInspectionMaster = lazy(() => import('../pages/SAFETY/MASTER/VesselInspectionMaster'));
const VesselInspectionReport = lazy(() => import('../pages/SAFETY/REPORT'));
const VesselInspectionList = lazy(() => import('../pages/SAFETY/REPORT/VesselInspectionList'));
// import { useSelector } from "react-redux";
// const AuthComponent = lazy(() => import("../pages/root/Auth"));
/* const User = lazy(() => import("../pages/dashboard/register")); */
const Dashboard = lazy(() => import("../pages/dashboard"));
const InvDashboard = lazy(() => import("../pages/inventory/Dashboard"));
const Masters = lazy(() => import('../pages/Masters'));
const MeetingRecord = lazy(() => import('../pages/SAFETY/MEETING/MeetingRecord'));
const DrillRecord = lazy(() => import('../pages/SAFETY/MEETING/DrillRecord'));
const AuditReport = lazy(() => import("../pages/Reports/AUDIT_INSPECTION/Audit"));
const InspectionReport = lazy(() => import("../pages/Reports/AUDIT_INSPECTION/Inspection"));
const MeetingReport = lazy(() => import("../pages/Reports/AUDIT_INSPECTION/Meeting"));
const AuditCheckListReport = lazy(() => import("../pages/Reports/AUDIT_INSPECTION/AuditCheckList"));
const BlankInspectionReport = lazy(() => import("../pages/Reports/AUDIT_INSPECTION/BlankInspection"));
const AuditScheduleReport = lazy(() => import("../pages/Reports/AUDIT_INSPECTION_SCHEDULE/Audit"));
const InspectionScheduleReport = lazy(() => import("../pages/Reports/AUDIT_INSPECTION_SCHEDULE/Inspection"));
const DamageReport = lazy(() => import("../pages/Reports/ACCIDENT_INJURY_MISS/Damage"));
const InjuryReport = lazy(() => import("../pages/Reports/ACCIDENT_INJURY_MISS/Injury"));
const NearMissReport = lazy(() => import("../pages/Reports/ACCIDENT_INJURY_MISS/NearMiss"));
const SummaryReport = lazy(() => import("../pages/Reports/ACCIDENT_INJURY_MISS/Summary"));
const WorkOrderReport = lazy(() => import("../pages/Reports/WorkOrder"));
const DrillDueDoneReport = lazy(() => import("../pages/Reports/DRILL_DUE_DONE/DrillTask"));
const SafetyDashboard = lazy(() => import("../pages/SAFETY/DASHBOARD"))
const SafetyDashboardTable = lazy(() => import("../pages/SAFETY/DASHBOARD/TitleCardTable"))
const InvProductGeneral = lazy(() => import("../pages/inventory/PRODUCT"));
const CreateProductGeneral = lazy(() => import("../pages/inventory/PRODUCT/CreateProduct"));
const OilProduct = lazy(() => import("../pages/inventory/OilProduct"));


// const Department = lazy(() => import('../pages/Masters/DepForm'));
const Profile = lazy(() => import('../pages/Settings'));
const UserRoles = lazy(() => import('../pages/Settings/UserRoles'));
const AddUser = lazy(() => import('../pages/Settings/User/AddUser'));
const AddRoles = lazy(() => import("../pages/Settings/User/AddRoles"));
const Vessel = lazy(() => import("../pages/Settings/Vessel"));
const AddVessel = lazy(() => import("../pages/Settings/Vessel/AddVessel"));
const UOM_CURRENCY = lazy(() => import("../pages/Settings/UOM_CURRENCY"));
const LocationSetting = lazy(() => import("../pages/Settings/LocationSetting"));
const ApproveLevel = lazy(() => import("../pages/Settings/ApproveLevel"));
const CompanySetting = lazy(() => import("../pages/Settings/CompanyDetails"));
const AddCompany = lazy(() => import("../pages/Settings/AddCompany"));
const AuditNc = lazy(() => import("../pages/Reports/AUDIT_NC/AuditNc"));
const InspectionNC = lazy(() => import("../pages/Reports/AUDIT_NC/InspectionNC"));
const NCOBS = lazy(() => import("../pages/Reports/AUDIT_NC/NCOBS"));
// import PDFViewer from "../components/PDFViewer";
const AddApproval = lazy(() => import("../pages/Settings/ApproveLevel/Create"));
const DevUnderProcess = lazy(() => import("./DevUnderProcess"));
const Unauthorize = lazy(() => import("./Unauthorize"));
const TypePms = lazy(() => import("../pages/PMS/Settings/TypePms"));
const WelcomeComponent = lazy(() => import("../pages/root/WelcomeComponent"));
const InProgress = lazy(() => import("../SaaS/SignUp/InProgress"));
const CreateOilProduct = lazy(() => import("../pages/inventory/CreateOilProduct"));
const InviteUser = lazy(() => import("../pages/Settings/InviteUser"));
const SaasRegisterUser = lazy(() => import("../pages/Settings/SaasRegisterUser"));
const CheckEmailMessage = lazy(() => import("../SaaS/SignUp/CheckEmailMessage"));
const RestrictedURL = lazy(() => import("./RestrictedURL"));
const ResetPassword = lazy(() => import("../pages/Settings/ResetPassword"));
const EmailResetPassword = lazy(() => import("../pages/root/EmailResetPassword"));
const SaasBilling = lazy(() => import("../SaaS/Billing/SaasBilling"));
const AdminBilling = lazy(() => import("../SaaS/Billing/AdminBilling"));
const AdminLogin = lazy(() => import("../pages/root/AdminLogin"));

//SaaS
const SignUp = lazy(() => import("../SaaS/SignUp"));
const EmailValidation = lazy(() => import("../SaaS/SignUp/EmailValidation"));
// const CreateAccount = lazy(() => import("../SaaS/SignUp/VesselDetails"));
// const CRMDetails1 = lazy(() => import("../SaaS/SignUp/CRMDetails3"));

function RoutesControl() {
    return (
        <BrowserRouter>
            <Suspense fallback={<div className='d-flex min-vh-100 justify-content-center align-items-center text-primary'>Loading....</div>}>
                <Routes>
                    <Route element={<WithoutNav />}>
                        {/* <Route path="/" element={<AuthComponent />} /> */}
                        <Route path='/' element={<Login />} />
                        <Route path='/emailCheck' element={<CheckEmailMessage />} />
                        <Route path='/saas/resetPassword' element={<EmailResetPassword />} />
                        <Route path='/login' element={<Login />} />
                        <Route path='/saas-login' element={<SaasLogin />} />
                        <Route path='/admin-login' element={<AdminLogin />} />
                        <Route path='/sass/new-user' element={<SaasRegisterUser />} />
                        <Route path='/supplier_quotation/:id' element={<CreateQC />} />
                        <Route path='/supplier_invoice/' element={<Invoice />} />
                        <Route path='/unauthorize' element={<Unauthorize />} />
                        {/* <Route path='/saas-demo' element={<SignUp />} /> */}
                        <Route path='/saas/mail-confirmation' element={<SignUp />} />
                        <Route path='/saas-demo' element={<EmailValidation />} />
                        <Route path='/progress' element={<InProgress />} />
                        {/* <Route path='/CreateAccount' element={<CreateAccount />} /> */}
                        {/* <Route path='/CRM1' element={<CRMDetails1 />} /> */}
                    </Route>
                    {<Route element={<Layout />}>
                        <Route path='/welcome' element={<WelcomeComponent />} />
                        {/* <Route path='/tree-select' element={<StoreCategoryTree />} /> */}

                        {/* <Route path='/search' element={<PDFViewer />} /> */}
                        <Route path='/purchase_code' element={<Purchase />} />
                        <Route path='/purchase-order' element={<Protected><RestrictedURL access={'view'} menuname='Purchase' modulename="Order" type="process"><PoList /></RestrictedURL></Protected>} />
                        <Route path='/purchase-order/edit/:id' element={<Protected><RestrictedURL access={'view'} menuname='Purchase' modulename="Order" type="process"><EditPo /></RestrictedURL></Protected>} />
                        <Route path='/purchase-order/split/:id' element={<Protected><RestrictedURL access={'view'} menuname='Purchase' modulename="Order" type="process"><SplitPO /></RestrictedURL></Protected>} />
                        <Route path='/purchase-order/create/:id' element={<Protected><RestrictedURL access={'view'} menuname='Purchase' modulename="Order" type="process"><CreatePo /></RestrictedURL></Protected>} />
                        <Route path='/purchase-order/list' element={<Protected><RestrictedURL access={'view'} menuname='Purchase' modulename="Order" type="process"><POList /></RestrictedURL></Protected>} />
                        <Route path='/inventory/oil-product' element={<Protected><RestrictedURL access={'view'} menuname='Inventory' modulename="OIL PRODUCT" type="process"><OilProduct /></RestrictedURL></Protected>} />
                        {/* <Route path='/oilProduct' element={<Protected><Masters /></Protected>} /> */}
                        <Route path='/productGeneral' element={<Protected><Masters /></Protected>} />
                        <Route path='/rfq' element={<Protected><RestrictedURL access={'view'} menuname='Purchase' modulename="RFQ" type="process"><RFQList /></RestrictedURL></Protected>} />
                        <Route path='/rfq/create/:id' element={<Protected><RestrictedURL access={'view'} menuname='Purchase' modulename="RFQ" type="process"><CreateRFQ /></RestrictedURL></Protected>} />
                        <Route path='/rfq/:id' element={<Protected><RestrictedURL access={'view'} menuname='Purchase' modulename="RFQ" type="process"><EditRFQ /></RestrictedURL></Protected>} />
                        <Route path='/quotation/crate/:id' element={<Protected><RestrictedURL access={'view'} menuname='Purchase' modulename="Quotation" type="process"><CreateQC /></RestrictedURL></Protected>} />
                        <Route path='/quotation/update/:id' element={<Protected><RestrictedURL access={'view'} menuname='Purchase' modulename="Quotation" type="process"><SaveQC /></RestrictedURL></Protected>} />
                        <Route path='/quotation' element={<Protected><RestrictedURL access={'view'} menuname='Purchase' modulename="Quotation" type="process"><QCList /></RestrictedURL></Protected>} />

                        {/* <Route path='/theme_test' element={<Test />} /> */}
                        <Route path='/inventory' element={<Protected><InvDashboard /></Protected>} />
                        {/* <Route path='/purchase_requisition1' element={<PurchaseRequisition/>}/> */}
                        <Route path='/purchase_requisition' element={<Protected><RestrictedURL access={'view'} menuname='Purchase' modulename="Requisition" type="process"><PurchaseUi /></RestrictedURL></Protected>} />
                        <Route path='/purchase_requisition/new' element={<Protected><RestrictedURL access={'view'} menuname='Purchase' modulename="Requisition" type="process"><PurchaseReqNew /></RestrictedURL></Protected>} />
                        <Route path='/purchase_requisition/split/:id' element={<Protected><RestrictedURL access={'view'} menuname='Purchase' modulename="Requisition" type="process"><PurchaseReqSplit /></RestrictedURL></Protected>} />
                        <Route path='/purchase_requisition/bulk/new' element={<Protected><RestrictedURL access={'view'} menuname='Purchase' modulename="Bulk Requisition" type="process"><PurchaseBulkNew /></RestrictedURL></Protected>} />
                        <Route path='/purchase_requisition/bulk/edit/:id' element={<Protected><RestrictedURL access={'view'} menuname='Purchase' modulename="Bulk Requisition" type="process"><PurchaseBulkEdit /></RestrictedURL></Protected>} />
                        <Route path='/purchase_requisition/:id' element={<Protected><RestrictedURL access={'view'} menuname='Purchase' modulename="Requisition" type="process"><PurchaseReqEdit /></RestrictedURL></Protected>} />

                        <Route path='/qc/comparison' element={<Protected><RestrictedURL access={'view'} menuname='Purchase' modulename="Quotation Comparison" type="process"><ComparisonQC /></RestrictedURL></Protected>} />
                        <Route path='/qc/add/:id' element={<Protected><RestrictedURL access={'view'} menuname='Purchase' modulename="Quotation Comparison" type="process"><AddQC /></RestrictedURL></Protected>} />
                        <Route path='/qc/edit/:id' element={<Protected><RestrictedURL access={'view'} menuname='Purchase' modulename="Quotation Comparison" type="process"><EditQC /></RestrictedURL></Protected>} />
                        <Route path='/qc/split/:id' element={<Protected><RestrictedURL access={'view'} menuname='Purchase' modulename="Quotation Comparison" type="process"><SplitQC /></RestrictedURL></Protected>} />
                        <Route path='/qc/split/edit/:id' element={<Protected><RestrictedURL access={'view'} menuname='Purchase' modulename="Quotation Comparison" type="process"><EditSplitQC /></RestrictedURL></Protected>} />
                        <Route path='/inventory/store' element={<Protected><RestrictedURL access={'view'} menuname='Inventory' modulename="STORE" type="process"><StoreUi /></RestrictedURL></Protected>} />
                        <Route path='/inventory/store/:id' element={<Protected><RestrictedURL access={'view'} menuname='Inventory' modulename="STORE" type="process"><StoreForm /></RestrictedURL></Protected>} />
                        <Route path='/PMS' element={<Protected><RestrictedURL access={'view'} menuname='DashBoard' modulename="PMS" type="process"><PMS /></RestrictedURL></Protected>} />
                        <Route path='/PMS/dashboard' element={<Protected><PmsDashboard /></Protected>} />
                        <Route path='/PMS/createpms' element={<Protected><RestrictedURL access={'view'} menuname='PMS' modulename="Create PMS" type="process"><CreatePMS /></RestrictedURL></Protected>} />
                        <Route path='/PMS/tasklist' element={<Protected><RestrictedURL access={'view'} menuname='PMS' modulename="Task List" type="process"><TaskList /></RestrictedURL></Protected>} />
                        <Route path='/PMS/jobcard/:id' element={<Protected><RestrictedURL access={'view'} menuname='PMS' modulename="Task List" type="process"><Jobcard /></RestrictedURL></Protected>} />
                        <Route path='/PMS/completion' element={<Protected><RestrictedURL access={'view'} menuname='PMS' modulename="Task List" type="process"><CompletionPMS /></RestrictedURL></Protected>} />
                        <Route path='/PMS/schedule' element={<Protected><RestrictedURL access={'view'} menuname='PMS' modulename="Unscheduled/Resch" type="process"><Schedule /></RestrictedURL></Protected>} />
                        <Route path='/PMS/unschedule/:id' element={<Protected><RestrictedURL access={'view'} menuname='PMS' modulename="Unscheduled/Resch" type="process"><UnsheduleForm /></RestrictedURL></Protected>} />
                        <Route path='/PMS/unschedule' element={<Protected><RestrictedURL access={'view'} menuname='PMS' modulename="Unscheduled/Resch" type="process"><UnsheduleForm /></RestrictedURL></Protected>} />
                        <Route path='/PMS/certificate' element={<Protected><RestrictedURL access={'view'} menuname='PMS' modulename="Certificate List" type="process"><CertificatePMS /></RestrictedURL></Protected>} />
                        <Route path='/PMS/maintenance_hour' element={<Protected><RestrictedURL access={'view'} menuname='PMS' modulename="Maintenance Hour" type="process"><Hour /></RestrictedURL></Protected>} />
                        <Route path='/PMS/speed_ground_knot' element={<Protected><Speedknot /></Protected>} />
                        <Route path='/PMS/reports/maintenance' element={<Protected><RestrictedURL access={'view'} menuname='PMS' modulename="Maintenance Report" type="report"><PMSReport /></RestrictedURL></Protected>} />
                        <Route path='/PMS/reports/certificate' element={<Protected><RestrictedURL access={'view'} menuname='PMS' modulename="Certificate Report" type="report"><PMSCertificateReport /></RestrictedURL></Protected>} />
                        <Route path='/PMS/dashboard/certificate' element={<Protected><AllCertificate /></Protected>} />
                        <Route path='/PMS/dashboard/defect' element={<Protected><AllDefect /></Protected>} />
                        <Route path='/PMS/type' element={<Protected><TypePms /></Protected>} />

                        <Route path='/goods' element={<Protected><RestrictedURL access={'view'} menuname='Purchase' modulename="Good Receive" type="process"><GoodsList /></RestrictedURL></Protected>} />
                        <Route path='/goods/new/:id' element={<Protected><RestrictedURL access={'view'} menuname='Purchase' modulename="Good Receive" type="process"><AddGoods /></RestrictedURL></Protected>} />
                        <Route path='/goods/:id' element={<Protected><RestrictedURL access={'view'} menuname='Purchase' modulename="Good Receive" type="process"><EditGoods /></RestrictedURL></Protected>} />

                        <Route path='/invoice' element={<Protected><RestrictedURL access={'view'} menuname='Purchase' modulename="Invoice" type="process"><InvoiceList /></RestrictedURL></Protected>} />
                        <Route path='/e-invoice' element={<Protected><RestrictedURL access={'view'} menuname='Purchase' modulename="E-Invoice" type="process"><EInvoice /></RestrictedURL></Protected>} />
                        <Route path='/invoice/new/:id' element={<Protected><RestrictedURL access={'view'} menuname='Purchase' modulename="Invoice" type="process"><Invoice /></RestrictedURL></Protected>} />

                        <Route path='/inventory/consumption_part' element={<Protected><RestrictedURL access={'view'} menuname='Inventory' modulename="PART" type="process"><ConsumptionPART /></RestrictedURL></Protected>} />
                        <Route path='/inventory/consumption_part/:id' element={<Protected><RestrictedURL access={'view'} menuname='Inventory' modulename="PART" type="process"><ConsumptionPART /></RestrictedURL></Protected>} />
                        <Route path='/inventory/consumption_general' element={<Protected><RestrictedURL access={'view'} menuname='Inventory' modulename="PRODUCT GENERAL" type="process"><ConsumptionGENERAL /></RestrictedURL></Protected>} />
                        <Route path='/inventory/consumption_general/:id' element={<Protected><RestrictedURL access={'view'} menuname='Inventory' modulename="PRODUCT GENERAL" type="process"><ConsumptionGENERAL /></RestrictedURL></Protected>} />
                        <Route path='/inventory/consumption_store' element={<Protected><RestrictedURL access={'view'} menuname='Inventory' modulename="STORE" type="process"><ConsumptionStore /></RestrictedURL></Protected>} />
                        <Route path='/inventory/consumption_store/:id' element={<Protected><RestrictedURL access={'view'} menuname='Inventory' modulename="STORE" type="process"><ConsumptionStore /></RestrictedURL></Protected>} />
                        <Route path='/inventory/consumption_oil/:id' element={<Protected><RestrictedURL access={'view'} menuname='Inventory' modulename="OIL PRODUCT" type="process"><ConsumptionOil /></RestrictedURL></Protected>} />

                        <Route path='/inventory/part' element={<Protected><RestrictedURL access={'view'} menuname='Inventory' modulename="PART" type="process"><Part /></RestrictedURL></Protected>} />
                        <Route path='/inventory/part/:equipmentNo/:partId' element={<Protected><RestrictedURL access={'view'} menuname='Inventory' modulename="PART" type="process"><Part /></RestrictedURL></Protected>} />
                        <Route path='/inventory/part/:equipmentNo' element={<Protected><RestrictedURL access={'view'} menuname='Inventory' modulename="PART" type="process"><Part /></RestrictedURL></Protected>} />
                        <Route path='/inventory/part/edit/:partId' element={<Protected><RestrictedURL access={'view'} menuname='Inventory' modulename="PART" type="process"><Part /></RestrictedURL></Protected>} />
                        <Route path='/inventory/partPreview' element={<Protected><RestrictedURL access={'view'} menuname='Inventory' modulename="PART" type="process"><PartPreview /></RestrictedURL></Protected>} />
                        <Route path='/inventory/equipment' element={<Protected><RestrictedURL access={'view'} menuname='Inventory' modulename="EQUIPMENT" type="process"><Equipment /></RestrictedURL></Protected>} />
                        <Route path='/inventory/contract_rate' element={<Protected><RestrictedURL access={'view'} menuname='Purchase' modulename="Contract Rate" type="process"><ContractRate /></RestrictedURL></Protected>} />
                        <Route path='/inventory/contract_rate/create' element={<Protected><RestrictedURL access={'view'} menuname='Purchase' modulename="Contract Rate" type="process"><ContractForm /></RestrictedURL></Protected>} />
                        <Route path='/inventory/contract_rate/edit/:id' element={<Protected><RestrictedURL access={'view'} menuname='Purchase' modulename="Contract Rate" type="process"><ContractFormEdit /></RestrictedURL></Protected>} />
                        <Route path='/inventory/productGeneral' element={<Protected><RestrictedURL access={'view'} menuname='Inventory' modulename="PRODUCT GENERAL" type="process"><InvProductGeneral /></RestrictedURL></Protected>} />
                        <Route path='/inventory/productGeneral/new' element={<Protected><RestrictedURL access={'view'} menuname='Inventory' modulename="PRODUCT GENERAL" type="process"><CreateProductGeneral /></RestrictedURL></Protected>} />
                        <Route path='/inventory/productGeneral/:id' element={<Protected><RestrictedURL access={'view'} menuname='Inventory' modulename="PRODUCT GENERAL" type="process"><CreateProductGeneral /></RestrictedURL></Protected>} />
                        <Route path='/inventory/oil-product/new' element={<Protected><RestrictedURL access={'view'} menuname='Inventory' modulename="OIL PRODUCT" type="process"><CreateOilProduct /></RestrictedURL></Protected>} />
                        <Route path='/inventory/oil-product/:id' element={<Protected><RestrictedURL access={'view'} menuname='Inventory' modulename="OIL PRODUCT" type="process"><CreateOilProduct /></RestrictedURL></Protected>} />

                        <Route path='/e-doc' element={<Protected><EDocDashboard /></Protected>} />
                        {/*<Route path='/e-doc' element={<EDoc />} />*/}
                        <Route path='/e-doc/document-initiated' element={<DocumentInitiatedList />} />
                        <Route path='/e-doc/checkin' element={<Protected><RestrictedURL access={'view'} menuname='E-Doc' modulename="Checkin" type="process"><CheckinList /></RestrictedURL></Protected>} />
                        <Route path='/e-doc/checkout' element={<Protected><RestrictedURL access={'view'} menuname='E-Doc' modulename="Checkout" type="process"><CheckoutList /></RestrictedURL></Protected>} />
                        <Route path='/e-doc/Document_to_Checkout' element={<Protected><RestrictedURL access={'view'} menuname='E-Doc' modulename="Checkout" type="process"><CheckoutList /></RestrictedURL></Protected>} />
                        <Route path='/e-doc/initiated' element={<InitialList />} />
                        <Route path='/e-doc/reviewed_request_to_approve' element={<ReviewRequestApproveList />} />
                        <Route path='/e-doc/new_reviewed' element={<NewReviewedDocumentList />} />
                        <Route path='/e-doc/rejected' element={<RejectedDocumentList />} />
                        <Route path='/e-doc/all' element={<AllDocumentsList />} />
                        <Route path='/e-doc/manage-document' element={<Protected><RestrictedURL access={'view'} menuname='E-Doc' modulename="Manage Document" type="process"><EDoc /></RestrictedURL></Protected>} />
                        <Route path='/e-doc/initiate-request' element={<Protected><RestrictedURL access={'view'} menuname='E-Doc' modulename="Initiate Request" type="process"><InitiateRequest /></RestrictedURL></Protected>} />
                        <Route path='/e-doc/review-request' element={<Protected><RestrictedURL access={'view'} menuname='E-Doc' modulename="Review Request" type="process"><ReviewRequest /></RestrictedURL></Protected>} />
                        <Route path='/e-doc/doc-category' element={<DocCategoryMaster />} />
                        <Route path='/e-doc/change-request' element={<ChangeRequestType />} />
                        <Route path='/e-doc/create/:folderId' element={<Protected><RestrictedURL access={'view'} menuname='E-Doc' modulename="Manage Document" type="process"><Create /></RestrictedURL></Protected>} />
                        <Route path='/e-doc/edit/:folderId/:editId' element={<Protected><RestrictedURL access={'view'} menuname='E-Doc' modulename="Manage Document" type="process"><Create /></RestrictedURL></Protected>} />

                        {/* <Route path='/jsontopdf' element={<JsonToPdf />} /> */}
                        {/* <Route path='/reports' element={<JsonTable />} /> */}
                        <Route path='/reports/requisition' element={<Protected><RestrictedURL access={'view'} menuname='Purchase' modulename="Requisition" type="report"><Requisition /></RestrictedURL></Protected>} />
                        <Route path='/reports/quotation' element={<Protected><RestrictedURL access={'view'} menuname='Purchase' modulename="Quotation" type="report"><Quotation /></RestrictedURL></Protected>} />
                        <Route path='/reports/qc' element={<Protected><RestrictedURL access={'view'} menuname='Purchase' modulename="Requisition" type="report"><QC /></RestrictedURL></Protected>} />
                        <Route path='/reports/purchase-order' element={<Protected><RestrictedURL access={'view'} menuname='Purchase' modulename="Purchase Order" type="report"><Order /></RestrictedURL></Protected>} />
                        <Route path='/reports/invoice' element={<Protected><RestrictedURL access={'view'} menuname='Purchase' modulename="Invoice" type="report"><InvoiceReport /></RestrictedURL></Protected>} />
                        <Route path='/reports/goods' element={<Protected><RestrictedURL access={'view'} menuname='Purchase' modulename="Goods" type="report"><GoodsReport /></RestrictedURL></Protected>} />
                        <Route path='/reports/rfq' element={<Protected><RestrictedURL access={'view'} menuname='Purchase' modulename="RFQ" type="report"><RFQReport /></RestrictedURL></Protected>} />
                        <Route path='/reports/currency' element={<Protected><RestrictedURL access={'view'} menuname='Purchase' modulename="Requisition" type="report"><CurrencyReport /></RestrictedURL></Protected>} />
                        <Route path='/reports/purchase-item-history' element={<Protected><RestrictedURL access={'view'} menuname='Purchase' modulename="Purchase Item History" type="report"><PurchaseItemHistory /></RestrictedURL></Protected>} />
                        <Route path='/reports/purchase-query' element={<Protected><RestrictedURL access={'view'} menuname='Purchase' modulename="Purchase Query" type="report"><PurchaseQueryReports /></RestrictedURL></Protected>} />
                        <Route path='/reports/outstanding-po' element={<Protected><RestrictedURL access={'view'} menuname='Purchase' modulename="Outstanding Po" type="report"><OutstandingPo /></RestrictedURL></Protected>} />
                        {/* <Route path='/reports/supplier-rating' element={<PurchaseQuery/>}/> */}
                        <Route path='/reports/supplier-rating' element={<Protected><RestrictedURL access={'view'} menuname='Purchase' modulename="Supplier Rating" type="report"><SupplierRating /></RestrictedURL></Protected>} />
                        <Route path='/reports/history-track' element={<Protected><RestrictedURL access={'view'} menuname='E-Doc' modulename="History Track" type="report"><HistoryTrack /></RestrictedURL></Protected>} />
                        <Route path='/reports/report-status' element={<Protected><RestrictedURL access={'view'} menuname='E-Doc' modulename="Status Track" type="report"><ReportStatus /></RestrictedURL></Protected>} />
                        <Route path='/reports/report-user' element={<Protected><RestrictedURL access={'view'} menuname='E-Doc' modulename="User Listing" type="report"><ReportUserList /></RestrictedURL></Protected>} />
                        <Route path='/reports/report-request' element={<Protected><RestrictedURL access={'view'} menuname='E-Doc' modulename="Request List" type="report"><ReportRequest /></RestrictedURL></Protected>} />
                        <Route path='/reports/equipment' element={<Protected><RestrictedURL access={'view'} menuname='Inventory' modulename="Equipment Report" type="report"><InvReport /></RestrictedURL></Protected>} />
                        {/* <Route path='/reports/parts' element={<InvReport />} /> */}
                        {/* <Route path='/reports/oil-product' element={<InvReport />} /> */}
                        <Route path='/reports/product-general' element={<Protected><RestrictedURL access={'view'} menuname='Inventory' modulename="Product General" type="report"><InvReport /></RestrictedURL></Protected>} />
                        {/* <Route path='/reports/store' element={<InvReport />} />   */}


                        {/* {
                            REPORTS.map((path, i) => <Route path={`/reports${path.path}`} key={i} element={<Protected><JsonTable /></Protected>} />)
                        } */}
                        {/* <Route path='/PurchaseReqNew' element={<PurchaseReqNew/>}/> */}
                        {/* <Route path='/user' element={<User/>}/> */}
                        <Route path='/dashboard' element={<Protected><Dashboard /></Protected>} />
                        {
                            MASTERS_SCREENS.map((path, i) => <Route path={path} key={i} element={<Protected><Masters /></Protected>} />)
                        }
                        <Route path='/analysis' element={<Protected><RestrictedURL access={'view'} menuname='PMS' modulename="Maintenance Analysis" type="report"><Analysis /></RestrictedURL></Protected>} />
                        <Route path='/pms/analysis' element={<Protected><RestrictedURL access={'view'} menuname='PMS' modulename="PMS Analysis" type="report"><Charts /></RestrictedURL></Protected>} />
                        <Route path='/work_order' element={<Protected><RestrictedURL access={'view'} menuname='Purchase' modulename="WO Completion" type="process"><WorkOrder /></RestrictedURL></Protected>} />
                        <Route path='/work_order/form/:id' element={<Protected><RestrictedURL access={'view'} menuname='Purchase' modulename="WO Completion" type="process"><WOForm /></RestrictedURL></Protected>} />
                        <Route path='/safety/checklist_for' element={<Protected><CheckListFor /></Protected>} />
                        <Route path='/safety/checklistItemGroup' element={<Protected><ChecklistItemGroup /></Protected>} />
                        <Route path='/safety/audit_master' element={<Protected><AuditMaster /></Protected>} />
                        <Route path='/safety/ncobs' element={<Protected><NCobs /></Protected>} />
                        <Route path='/safety/root_cause' element={<Protected><RootCause /></Protected>} />
                        <Route path='/safety/sub_ncobs' element={<Protected><NCobsSub /></Protected>} />
                        <Route path='/safety/ism' element={<Protected><ISM /></Protected>} />
                        <Route path='/safety/nc_type' element={<Protected><NCType /></Protected>} />
                        <Route path='/safety/nc_category' element={<Protected><NCcategory /></Protected>} />
                        <Route path='/safety/port_state' element={<Protected><PortState /></Protected>} />
                        <Route path='/safety/case_close' element={<Protected><CaseClose /></Protected>} />
                        <Route path='/safety/vesselItemGroup' element={<Protected><VesselItemGroup /></Protected>} />
                        <Route path='/safety/VesselItemSubGroup' element={<Protected><VesselItemSubGroup /></Protected>} />
                        <Route path='/safety/VesselInspectionMaster' element={<Protected><VesselInspectionMaster /></Protected>} />
                        <Route path='/safety/VesselInspectionReport' element={<Protected><RestrictedURL access={'view'} menuname='Safety' modulename="Vessel Inspection Report" type="process"><VesselInspectionReport /></RestrictedURL></Protected>} />
                        <Route path='/safety/VesselInspectionList' element={<Protected><RestrictedURL access={'view'} menuname='Safety' modulename="Vessel Inspection Report" type="process"><VesselInspectionList /></RestrictedURL></Protected>} />

                        <Route path='/safety/status_condition' element={<Protected><StatusCondition /></Protected>} />
                        <Route path='/safety/inspection_master' element={<Protected><InspectionMaster /></Protected>} />
                        <Route path='/safety/audit/checklist' element={<Protected><RestrictedURL access={'view'} menuname='Safety' modulename="Audit Checklist" type="process"><AuditChecklist /></RestrictedURL></Protected>} />
                        <Route path='/safety/audit/checklist/create' element={<Protected><RestrictedURL access={'view'} menuname='Safety' modulename="Audit Checklist" type="process"><CreateChecklist /></RestrictedURL></Protected>} />
                        <Route path='/safety/audit/checklist/edit/:id' element={<Protected><RestrictedURL access={'view'} menuname='Safety' modulename="Audit Checklist" type="process"><CreateChecklist /></RestrictedURL></Protected>} />
                        <Route path='/safety/audit/schedule' element={<Protected><RestrictedURL access={'view'} menuname='Safety' modulename="Audit Schedule" type="process"><AuditScheduleList /></RestrictedURL></Protected>} />
                        <Route path='/safety/audit/schedule/create' element={<Protected><RestrictedURL access={'view'} menuname='Safety' modulename="Audit Schedule" type="process"><AuditScheduleForm /></RestrictedURL></Protected>} />
                        <Route path='/safety/audit/schedule/edit/:id' element={<Protected><RestrictedURL access={'view'} menuname='Safety' modulename="Audit Schedule" type="process"><AuditScheduleForm /></RestrictedURL></Protected>} />
                        <Route path='/safety/audit/completion' element={<Protected><RestrictedURL access={'view'} menuname='Safety' modulename="Audit Completion" type="process"><AuditCompletion /></RestrictedURL></Protected>} />
                        <Route path='/safety/audit/completion/entry' element={<Protected><RestrictedURL access={'view'} menuname='Safety' modulename="Audit Completion" type="process"><CompletionEntry /></RestrictedURL></Protected>} />
                        <Route path='/safety/audit/completion/:id' element={<Protected><RestrictedURL access={'view'} menuname='Safety' modulename="Audit Completion" type="process"><CompletionEntry /></RestrictedURL></Protected>} />
                        <Route path='/safety/audit/plan' element={<Protected><RestrictedURL access={'view'} menuname='Safety' modulename="Audit Planning" type="process"><AuditPlan /></RestrictedURL></Protected>} />
                        <Route path='/safety/audit/plan/completion/:id' element={<Protected><RestrictedURL access={'view'} menuname='Safety' modulename="Audit Planning" type="process"><PlannerCompletion /></RestrictedURL></Protected>} />
                        <Route path='/safety/inspection/checklist' element={<Protected><RestrictedURL access={'view'} menuname='Safety' modulename="Inspection Checklist" type="process"><InspectionChecklist /></RestrictedURL></Protected>} />
                        <Route path='/safety/inspection/checklist/create' element={<Protected><RestrictedURL access={'view'} menuname='Safety' modulename="Inspection Checklist" type="process"><InspectionCreateChecklist /></RestrictedURL></Protected>} />
                        <Route path='/safety/inspection/checklist/edit/:id' element={<Protected><RestrictedURL access={'view'} menuname='Safety' modulename="Inspection Checklist" type="process"><InspectionCreateChecklist /></RestrictedURL></Protected>} />
                        <Route path='/safety/inspection/schedule' element={<Protected><RestrictedURL access={'view'} menuname='Safety' modulename="Inspection Schedule" type="process"><InspectionScheduleList /></RestrictedURL></Protected>} />
                        <Route path='/safety/inspection/schedule/create' element={<Protected><RestrictedURL access={'view'} menuname='Safety' modulename="Inspection Schedule" type="process"><InspectionScheduleForm /></RestrictedURL></Protected>} />
                        <Route path='/safety/inspection/schedule/edit/:id' element={<Protected><RestrictedURL access={'view'} menuname='Safety' modulename="Inspection Schedule" type="process"><InspectionScheduleForm /></RestrictedURL></Protected>} />
                        <Route path='/safety/inspection/plan' element={<Protected><RestrictedURL access={'view'} menuname='Safety' modulename="Inspection Planning" type="process"><InspectionPlan /></RestrictedURL></Protected>} />
                        <Route path='/safety/inspection/plan/completion/:id' element={<Protected><RestrictedURL access={'view'} menuname='Safety' modulename="Inspection Planning" type="process"><InspectionPlannerCompletion /></RestrictedURL></Protected>} />
                        <Route path='/safety/inspection/completion' element={<Protected><RestrictedURL access={'view'} menuname='Safety' modulename="Inspection Completion" type="process"><InspectionCompletion /></RestrictedURL></Protected>} />
                        <Route path='/safety/inspection/completion/entry' element={<Protected><RestrictedURL access={'view'} menuname='Safety' modulename="Inspection Completion" type="process"><InspectionCompletionEntry /></RestrictedURL></Protected>} />
                        <Route path='/safety/inspection/completion/:id' element={<Protected><RestrictedURL access={'view'} menuname='Safety' modulename="Inspection Completion" type="process"><InspectionCompletionEntry /></RestrictedURL></Protected>} />

                        <Route path='/safety' element={<Protected><SafetyDashboard /></Protected>} />
                        <Route path='/safety/dashboard' element={<Protected><SafetyDashboardTable /></Protected>} />

                        <Route path='/safety/meeting/meeting_name_master' element={<Protected><MeetingNameMasterList /></Protected>} />
                        <Route path='/safety/meeting/drill_name_master' element={<Protected><DrillNameMasterList /></Protected>} />
                        <Route path='/safety/meeting/meeting_schedule' element={<Protected><MeetingScheduleList /></Protected>} />
                        <Route path='/safety/meeting/drill_schedule' element={<Protected><DrillScheduleList /></Protected>} />
                        <Route path='/safety/meeting/meeting_agenda_master' element={<Protected><MeetingAgendaMasterList /></Protected>} />
                        <Route path='/safety/meeting/meeting_record' element={<Protected><RestrictedURL access={'view'} menuname='Safety' modulename="Meeting Record" type="process"><MeetingRecordList /></RestrictedURL></Protected>} />
                        {/*start- all inventry report restricted url component is inside of <EquipmentPartReport /> ,based on common component */}
                        <Route path='/reports/equipment_part' element={<Protected><EquipmentPartReport /></Protected>} />
                        <Route path='/reports/rob_part' element={<Protected><EquipmentPartReport /></Protected>} />
                        <Route path='/reports/adjustment_part' element={<Protected><EquipmentPartReport /></Protected>} />
                        <Route path='/reports/consumption_part' element={<Protected><EquipmentPartReport /></Protected>} />
                        <Route path='/reports/receipt_part' element={<Protected><EquipmentPartReport /></Protected>} />
                        <Route path='/reports/critical_part' element={<Protected><EquipmentPartReport /></Protected>} />
                        <Route path='/reports/report_store' element={<Protected><EquipmentPartReport /></Protected>} />
                        <Route path='/reports/rob_store' element={<Protected><EquipmentPartReport /></Protected>} />
                        <Route path='/reports/adjustment_store' element={<Protected><EquipmentPartReport /></Protected>} />
                        <Route path='/reports/consumption_store' element={<Protected><EquipmentPartReport /></Protected>} />
                        <Route path='/reports/receipt_store' element={<Protected><EquipmentPartReport /></Protected>} />
                        <Route path='/reports/report_oil' element={<Protected><EquipmentPartReport /></Protected>} />
                        <Route path='/reports/rob_oil' element={<Protected><EquipmentPartReport /></Protected>} />
                        <Route path='/reports/receipt_oil' element={<Protected><EquipmentPartReport /></Protected>} />
                        <Route path='/reports/consumption_oil' element={<Protected><EquipmentPartReport /></Protected>} />
                        <Route path='/reports/adjustment_oil' element={<Protected><EquipmentPartReport /></Protected>} />
                        <Route path='/reports/bunker_oil' element={<Protected><EquipmentPartReport /></Protected>} />
                        {/*end- all inventry report restricted url component is inside of <EquipmentPartReport /> ,based on common component */}
                        <Route path='/safety/ncobs/auditRecordList' element={<Protected><RestrictedURL access={'view'} menuname='Safety' modulename="Audit Record" type="process"><AuditRecordList /></RestrictedURL></Protected>} />
                        <Route path='/safety/ncobs/auditInfo/:id' element={<Protected><RestrictedURL access={'view'} menuname='Safety' modulename="Audit Record" type="process"><AuditInfo /></RestrictedURL></Protected>} />
                        <Route path='/safety/ncobs/inspectionRecordList' element={<Protected><RestrictedURL access={'view'} menuname='Safety' modulename="Audit Record" type="process"><InspectionRecordList /></RestrictedURL></Protected>} />
                        <Route path='/safety/ncobs/inspectionInfo/:id' element={<Protected><RestrictedURL access={'view'} menuname='Safety' modulename="Inspection Record" type="process"><InspectionInfo /></RestrictedURL></Protected>} />
                        <Route path='/safety/meeting-record' element={<Protected><RestrictedURL access={'view'} menuname='Safety' modulename="Meeting Record" type="process"><MeetingRecord /></RestrictedURL></Protected>} />
                        <Route path='/safety/drill-record' element={<Protected><RestrictedURL access={'view'} menuname='Safety' modulename="Drill Record" type="process"><DrillRecord /></RestrictedURL></Protected>} />
                        <Route path='/safety/master/incident' element={<Protected><DamageMaster /></Protected>} />
                        <Route path='/safety/transaction/incident' element={<Protected><RestrictedURL access={'view'} menuname='Safety' modulename="Incident Record" type="process"><IncidentTrans /></RestrictedURL></Protected>} />
                        <Route path='/safety/transaction/incident/damage' element={<Protected><RestrictedURL access={'view'} menuname='Safety' modulename="Incident Record" type="process"><IncidentDamageForm /></RestrictedURL></Protected>} />
                        <Route path='/safety/transaction/incident/damage/:id' element={<Protected><RestrictedURL access={'view'} menuname='Safety' modulename="Incident Record" type="process"><IncidentDamageForm /></RestrictedURL></Protected>} />
                        <Route path='/safety/transaction/incident/injury' element={<Protected><RestrictedURL access={'view'} menuname='Safety' modulename="Incident Record" type="process"><IncidentInjuryForm /></RestrictedURL></Protected>} />
                        <Route path='/safety/transaction/incident/injury/:id' element={<Protected><RestrictedURL access={'view'} menuname='Safety' modulename="Incident Record" type="process"><IncidentInjuryForm /></RestrictedURL></Protected>} />
                        <Route path='/safety/transaction/incident/near_miss' element={<Protected><RestrictedURL access={'view'} menuname='Safety' modulename="Incident Record" type="process"><IncidentNearMissForm /></RestrictedURL></Protected>} />
                        <Route path='/safety/transaction/incident/near_miss/:id' element={<Protected><RestrictedURL access={'view'} menuname='Safety' modulename="Incident Record" type="process"><IncidentNearMissForm /></RestrictedURL></Protected>} />
                        <Route path='/profile' element={<Protected><Profile /></Protected>} />
                        <Route path='/user_roles' element={<Protected><UserRoles /></Protected>} />
                        <Route path='/add_user' element={<Protected><AddUser /></Protected>} />
                        <Route path='/invite_user' element={<Protected><InviteUser /></Protected>} />
                        <Route path='/add_user/:id' element={<Protected><AddUser /></Protected>} />
                        <Route path='/add_roles' element={<Protected><AddRoles /></Protected>} />
                        <Route path='/add_roles/:id' element={<Protected><AddRoles /></Protected>} />
                        <Route path='/vessel_type' element={<Protected><Vessel /></Protected>} />
                        <Route path='/add_vessel' element={<Protected><AddVessel /></Protected>} />
                        <Route path='/add_vessel/:id' element={<Protected><AddVessel /></Protected>} />
                        <Route path='/uom_currency' element={<Protected><UOM_CURRENCY /></Protected>} />
                        <Route path='/location_setting' element={<Protected><LocationSetting /></Protected>} />
                        <Route path='/approve_level_setting' element={<Protected><ApproveLevel /></Protected>} />
                        <Route path='/approve_level_setting/add' element={<Protected><AddApproval /></Protected>} />
                        <Route path='/approve_level_setting/:id' element={<Protected><AddApproval /></Protected>} />
                        <Route path='/company_setting' element={<Protected><CompanySetting /></Protected>} />
                        <Route path='/add_company' element={<Protected><AddCompany /></Protected>} />
                        <Route path='/add_company/:id' element={<Protected><AddCompany /></Protected>} />
                        <Route path='/reports/audit' element={<Protected><RestrictedURL access={'view'} menuname='Safety' modulename="Audit" type="report"><AuditReport /></RestrictedURL></Protected>} />
                        <Route path='/reports/inspection' element={<Protected><RestrictedURL access={'view'} menuname='Safety' modulename="Inspection" type="report"><InspectionReport /></RestrictedURL></Protected>} />
                        <Route path='/reports/meeting' element={<Protected><RestrictedURL access={'view'} menuname='Safety' modulename="Meeting" type="report"><MeetingReport /></RestrictedURL></Protected>} />
                        <Route path='/reports/blank_audit_checklist' element={<Protected><RestrictedURL access={'view'} menuname='Safety' modulename="Blank Audit" type="report"><AuditCheckListReport /></RestrictedURL></Protected>} />
                        <Route path='/reports/blank_inspection' element={<Protected><RestrictedURL access={'view'} menuname='Safety' modulename="Blank Inspection" type="report"><BlankInspectionReport /></RestrictedURL></Protected>} />
                        <Route path='/reports/audit_schedule' element={<Protected><RestrictedURL access={'view'} menuname='Safety' modulename="Audit Schedule" type="report"><AuditScheduleReport /></RestrictedURL></Protected>} />
                        <Route path='/reports/inspection_schedule' element={<Protected><RestrictedURL access={'view'} menuname='Safety' modulename="Inspection Schedule" type="report"><InspectionScheduleReport /></RestrictedURL></Protected>} />
                        <Route path='/reports/damage' element={<Protected><RestrictedURL access={'view'} menuname='Safety' modulename="Damage" type="report"><DamageReport /></RestrictedURL></Protected>} />
                        <Route path='/reports/injury' element={<Protected><RestrictedURL access={'view'} menuname='Safety' modulename="Injury" type="report"><InjuryReport /></RestrictedURL></Protected>} />
                        <Route path='/reports/near_miss' element={<Protected><RestrictedURL access={'view'} menuname='Safety' modulename="Near Miss" type="report"><NearMissReport /></RestrictedURL></Protected>} />
                        <Route path='/reports/summary' element={<Protected><RestrictedURL access={'view'} menuname='Safety' modulename="Summary" type="report"><SummaryReport /></RestrictedURL></Protected>} />
                        <Route path='/reports/drill_due_done' element={<Protected><RestrictedURL access={'view'} menuname='Safety' modulename="Drill Due Done" type="report"><DrillDueDoneReport /></RestrictedURL></Protected>} />
                        <Route path='/reports/audit_nc' element={<Protected><RestrictedURL access={'view'} menuname='Safety' modulename="Audit NC" type="report"><AuditNc /></RestrictedURL></Protected>} />
                        <Route path='/reports/inspection_nc' element={<Protected><RestrictedURL access={'view'} menuname='Safety' modulename="Inspection NC" type="report"><InspectionNC /></RestrictedURL></Protected>} />
                        <Route path='/reports/nc_obs' element={<Protected><RestrictedURL access={'view'} menuname='Safety' modulename="NC/OBS" type="report"><NCOBS /></RestrictedURL></Protected>} />
                        <Route path='/reports/work_order' element={<Protected><RestrictedURL access={'view'} menuname='Purchase' modulename="Work Order" type="report"><WorkOrderReport /></RestrictedURL></Protected>} />
                        <Route path='/saas/billing' element={<Protected><SaasBilling /></Protected>} />
                        <Route path='/saas/admin_billing' element={<Protected><AdminBilling /></Protected>} />



                        <Route path='/reset' element={<ResetPassword />} />
                        <Route path='/process' element={<DevUnderProcess />} />


                    </Route>}
                    <Route path='*' element={<NotFound />} />
                </Routes>
            </Suspense>
        </BrowserRouter>
    )
}

export default RoutesControl;
