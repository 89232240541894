import React from "react";
// import { Outlet } from 'react-router';
import { Outlet } from "react-router-dom";
import { Container } from "react-bootstrap";
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
// import SideBar from "./SideBar";
import NavMenu from "./NavMenu";
const Layout = () => {
  return (
    <>
      <NavMenu />
      <>
        {/* <SideBar /> */}
        <div className="mainContent">
          <Container fluid>
            <Outlet />
          </Container>
        </div>
      </>
    </>
  );
};

export default Layout;
