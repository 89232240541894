import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { toast } from "react-toastify"
import { GET_TYPE, SAVE_TYPE } from "../constant/CommonApiUrl"
import { API_SERVICE } from "../Services/CommonApi";

const ADD_TYPE='type/addTypeApi'
const UPDATE_TYPE = 'type/updateTypeApi'
const FETCH_TYPE='type/fetchTypeApi'
const DELETE_TYPE = 'type/deleteTypeApi'

export const addTypeApi =createAsyncThunk(ADD_TYPE,
    async(args, thunkAPI)=>{
        try{
            let response = await API_SERVICE.post(SAVE_TYPE, args['data'], "CREATE");
            response = response.data.result.data[0];
            return response;
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
})

export const updateTypeApi = createAsyncThunk(UPDATE_TYPE,
    async(args, thunkAPI)=>{
        try{
            let response = await API_SERVICE.post(SAVE_TYPE, args['data'], "UPDATE");
            response = response.data.result.data[0];
            return response;
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
})

export const deleteTypeApi = createAsyncThunk(DELETE_TYPE, 
    async (args, thunkAPI) => {
        try{
            const toDelete = thunkAPI.getState().root.type.list.find(ele => ele.id === args['id']);   
            const response = await API_SERVICE.post(SAVE_TYPE, {...toDelete, isdisabled: "Y"});
            return response.data.result.data[0];
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
    }
)

export const fetchTypeApi = createAsyncThunk(FETCH_TYPE,
    async(arg,thunkAPI)=>{
        try{
            let response = await API_SERVICE.get(GET_TYPE);
            response = response.data.result.data;
            return response
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
    },
    {
        condition: (arg, { getState, extra }) => {          
          const isUpdated = getState().root.type.isUpdated                         
          if(!isUpdated){
            console.log('enable fetch condition in reducer')
            return false
          }
        },
      }
)

const initialState={
    list:[],
    editType:{},  
    isUpdated: true  
}
export const typeSlice=createSlice({
    name:'type',
    initialState,
    reducers:{
        addType:(state,action)=>{
            state.list=[...state.list,action.payload]
        },
        delType:(state,action)=>{
            state.list=state.list.filter(x=>x.id!==action.payload)
        },
        loadEditType:(state,action)=>{
            state.editType=action.payload
        },
        clearEditType:(state,action)=>{
            state.editType={}
        },
        updateType:(state,action)=>{
            let index=state.list.findIndex(x=>x.id===action.payload.id)
            state.list[index]=action.payload
        },               
        resetType:()=>initialState
    },
    extraReducers:(builder)=>{
        builder
        .addCase(addTypeApi.fulfilled,(state,action)=>{
            state.list=[...state.list,action.payload];
            toast.success('Succesfully added');
        })
        .addCase(updateTypeApi.fulfilled,(state,action)=>{
            state.list = state.list.map(x => x.id === action.payload.id ? action.payload: x);
            toast.success('Succesfully updated');
        })
        .addCase(fetchTypeApi.fulfilled, (state, action) => {
            state.list = action.payload;
            state.isUpdated = false;
        })
        .addCase(deleteTypeApi.fulfilled, (state, action) => {
            state.list=state.list.filter(x=>x.id!==action.payload.id);
            toast.success('Succesfully deleted');
        })
    } 
})
export const {addType,delType,loadEditType,clearEditType,updateType,resetType}=typeSlice.actions
export default typeSlice.reducer