import { Button, FormControl, Table } from "react-bootstrap";
import SimpleBar from "simplebar-react";
import styled from "styled-components";

export const PMSCalendarView = styled.div`
  padding: 5px;
  .rbc-calendar {
    height: ${(props) => (props.height ? `${props.height}` : '400px')};
    padding-right: 12px;
  }
  .rbc-event {
    background: inherit;
    color: #000;
  }
  .rbc-month-header {
    background: #f3f3f3;
  }
  .rbc-header {
    color: #344468;
  }
  .due {
    padding: 5px 7px;
    color: #49d991;
    background: #dcf7e8;
    border-left: 5px solid #49d991;
  }
  .overdue {
    padding: 5px 7px;
    color: #ff778b;
    background: #ffe2e6;
    border-left: 5px solid #ff778b;
  }
  .inprogress {
    padding: 5px 7px;
    color: #ffb95a;
    background: #fff4e6;
    border-left: 5px solid #ffb95a;
  }

  /* Responsive Styles */
  @media (max-width: 1080px) {
    .rbc-calendar {
      height: 800px; /* Adjust the height for smaller screens */
    }
  }
  @media (max-width: 768px) {
    .rbc-calendar {
      height: 550px; /* Adjust the height for smaller screens */
    }
  }
`;

export const CertificateTab = styled.div`{
  .nav-link{
    color:#7D8398;
    font-size:small;
  }
  .active-link{
    color:#121212;
    border-bottom:#121212 solid 1px;
  }
}`
export const ReportDetail = styled.div`{
  th{
    color:#afafaf;
    font-weight:200
  }
  td{
    color:#002033;
    font-size:small;
    padding:inherit;
  }
  .table-label{
    color:#afafaf;
    font-weight:200
  }
}`

export const AnalyticsList = styled.ul`
  list-style-type: none;
  padding: 13px 0;
  li {
    font-size: 10px;
    padding-bottom: 5px;
  }
  .box {
    display: flex;
    .icons {
      width: 10px;
      height: 10px;
      margin-right: 3px;
      display: inline-block;
    }
    .chart-blue {
      background-color: #1F51B3;
    }
    .sky-blue {
      background-color: #43CBF0;
    }
    .orange {
      background-color: #FFC400;
    }
    .chart-light-blue {
      background-color: #1ED6FF;
    }
    .chart-dark {
      background-color: #1A2261;
    }
    .text {
      line-height: 12px;
    }
  }
`;
export const Box = styled.div`
  background:#1F51B3;
  color:#fff;
  border-radius:25px;
  width:180px;
  padding:15px;
`

export const TaskListContainer = styled.div`
  .left-icon {
    height: 500px;
    min-height: 500px;
  }
  .border {
    border-top-left-radius: 5px;
  }
  .treeTitle {
    font-size: 13px;
    margin-bottom: 0;
  }
  .tree-header {
    font-size: 13px;
  }
  .label-grey {
    color: #667985 !important;
    .form-check-label {
      font-size: 12px;
    }
  }
  .attach-btn {
    height: 30px !important;
    font-size: 12px !important;
    background: #0587DB !important;
    border-radius: 25px;
  }
  .content-grey {
    color: #667985 !important;
  }
  .col-md-1 {
    width: 4.333333% !important;
  }
  span.rc-tree-node-content-wrapper.rc-tree-node-content-wrapper-open .rc-tree-title {
    font-weight: 400 !important;
    color: #7C809C !important;
  }
  span.rc-tree-node-content-wrapper.rc-tree-node-content-wrapper-normal .rc-tree-title {
    font-weight: 400 !important;
    color: #7C809C !important;
  }
  span.rc-tree-node-content-wrapper.rc-tree-node-content-wrapper-open.rc-tree-node-selected .rc-tree-title,
  span.rc-tree-node-content-wrapper.rc-tree-node-content-wrapper-normal.rc-tree-node-selected .rc-tree-title {
    color: #17A1FA !important;
    font-weight: 600 !important;
    text-decoration: none;
  }
  span .pmsParent  {
    color: #1A2261 !important;
    font-weight: 600 !important;
    text-decoration: none;
  }
`;
export const SearchContainer = styled.div`
  background: #FFFFFF;
  border-radius: 5px;
  padding: 5px 10px 0 10px;
  margin-bottom: 15px;
  .nav-tabs {
    border: none;
  }
  .nav-tabs .nav-link.active {
    color: #051134;
    border-bottom: 1px solid #051134 !important;
  }
  .nav-tabs .nav-link {
    color: #7D8398;
    font-size: 13px !important;
  }
`;

export const CompletionContainer = styled.div`
  background: #fff;
  /* padding: 10px; */
  /* border-radius: 5px; */
  
  .main-title {
    color: #1B6A9C !important;
    font-weight: 600;
    font-size: 14px !important;
  }
  .unschedule {
    .text-muted {
      color: #00203359 !important;
    }
  }
  .label-grey {
    color: #A6B1B8 !important;
  }
  .content-grey {
    color: #667985 !important;
    font-weight: 600;
  }
  .initialism {
    color: #2FBD57 !important;
    font-weight: 600;
  }
  .status-btn {
    background: #CAFBEC !important;
    color: #2FBD57 !important;
    font-weight: 600;
  }
  .btn-danger {
    height: 30px;
    width: auto;
    min-width: 79.44px;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 11px !important;
  }
  .card {
    width: 230px;
    .card-body {
      padding: 5px 5px !important;
      .attach-btn {
        height: 20px !important;
        font-size: 8px !important;
        background: #0587DB !important;
        border-radius: 25px;
      }
    }
  }

  .attachment-box {
    min-height: 170px;
    min-width: 200px;
    position: absolute;
    top: -50px;
    right: 0;
  }
  .un-attachment-box {
    min-height: 190px;
    min-width: 190px;
    position: absolute;
    top: -5px;
    right: 0;
  }
  .send-po {
    background: #CCD9E0 !important;
    color: #264252 !important;
  }
  .nav-tabs .nav-link {
    color: #D9D9D9;
    font-weight: 600;
  }
  .nav-tabs .nav-link.active {
    color: #2F7BF5;
    border-bottom: 2px solid #2F7BF5 !important;
  }
  .nav-tabs {
    border-bottom: none;
  }
  table.completeTable {
    border: none !important;
    th {
      color: #143057 !important;
      background: #F2F6FA;
      border: none !important;
    }
    tbody {
      background: #F2F6FA;
      td {
        border: none !important;
      }
    }
  }
`;
export const CountBox = styled.div`
  h6{
    color:#1F51B3;
    font-size:12px;
  }
  span{
    color:#292797
  }
`
export const EquipContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BlueSection = styled.div`
  background-color: #056EF8;
  color: white;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  height:40px;
  border-radius:  5px 0px 0px 0px;
`;

export const WhiteSection = styled.div`
  background-color: white;
  padding: 0px;
  height:${props => props.height ? props.height : "100%"}
`;

export const PreText = styled.pre`
color: #7D8398;
font-family: Montserrat;
font-size: 13.323px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 19.984px */`

export const ReportSpan = styled.span`
color: var(--typo-secondary, rgba(0, 32, 51, 0.60));
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 150%; `

export const ReportHead = styled.span`
/* color: var(--control-default-typo-placeholder, rgba(0, 32, 51, 0.35)); */
color:#afafaf;
font-family: Inter;
font-size: 16px;
font-style: normal;
/* font-weight: 400; */
line-height: 150%; /* 24px */`

export const CustomTableAll = styled(Table)`
  font-size: 10px !important;
  border: none !important;  
  thead {
    tr {
      border: none;
    }
  }

  th {
    background-color: #FCFCFC !important;
    color: #051134 !important;    
    text-transform: uppercase !important;
    border: none !important;
    font-weight: 600 !important;
    border-bottom: 1px solid #DFDFDF !important;
    border-top: 1px solid #DFDFDF !important;
  }

  tbody {
    color: #777886;
    background-color: #fff;

    // tr:nth-of-type(odd)>* {
    //   background-color: #fff !important;
    // }
    // tr:nth-of-type(even)>* {
    //   background-color: #f7f7f7 !important;
    // }
    tr {
      border: none;
    }

    td {
      border: none;
      font-size: 11px;
      color: #777886;
      vertical-align: middle !important;
      border-bottom:  ${props => props.tdBorder ? 'none' : '1px solid rgba(167, 167, 167, 0.2);'}
    }
  }
`
export const FormControlColor = styled(FormControl)`
&&& {
color:${props => props.color ? `${props.color} !important` : ''}; 
font-weight:${props => props.weight ? `${props.weight} !important` : ''};
text-shadow: ${props => props.textShadow ? "1px 1px 2px rgba(0, 0, 0, 0.5)  !important;" : ""};
box-shadow: ${props => props.boxShadow ? "0px 2px 2px rgba(0, 0, 0, 0.2);" : "" /* Add bottom shadow */};
line-height: ${props=>props.lineHeight?props.lineHeight:""}
  }
`
export const UnderlinedButton = styled(Button)`
&&& {
  text-decoration: none; /* Remove default underline */
  background-color: #FFF; /* Set background color */
  color: ${props => props.textColor ? props.textColor : '#afa'}; /* Set text color */
  border: none; /* Remove border */
  position: relative;
  overflow: hidden;
  font-weight: ${props => props.textWeight ? props.textWeight : '200'};
}

&&&::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%; /* Set underline width to 100% */
  height: ${props => props.lineWeight ? props.lineWeight : '1'}px; /* Adjust the height of the underline */
  background-color: ${props => props.lineColor ? props.lineColor : '#afa'}; /* Color of the underline */
}
`;
export const DottedButton = styled(Button)`
  &&& {
    border: 1px dotted #666970 !important; /* Dotted border */
    background-color: transparent; /* Transparent background */
    color: #000; /* Text color */
    //padding: 0.5rem 1rem; /* Adjust padding as needed */
  }
`;
export const PmsLoginTable = styled(Table)`
  font-size: 11px !important;
  border: 1px solid #DFDFDF !important;  
  //min-height:330px; 
  background-color: #EAEAEAE !important;  
    thead{
      height:5px;       
        th {
          height:5px;
          background-color: #FCFCFC !important;
          color: #667085 !important;        
          border: none !important;
          font-weight: 200 !important;
          border-bottom: 1px solid #DFDFDF !important;
          border-top: 1px solid #DFDFDF !important;
          vertical-align: middle !important;
        }     
    }     
    tbody {
      color: #777886;
      background-color: #fff;  
      max-height: 250px; /* Set maximum height for tbody */        
      tr {
        border: none;
        td {
          height:5px;
          border: none;
          font-size: 11px;
          color: #777886;
          vertical-align: middle !important;
          border-bottom:  ${props => props.tdBorder ? 'none' : '1px solid rgba(167, 167, 167, 0.2);'}
        }
      }   
    }
    tfoot{
      tr{
        td{
          height:5px;
          background-color:#fff;
          vertical-align: middle !important;
        }         
      }
    }   
@media screen and (min-width: 1920px) { 
  font-size: 11px !important;
  border: 1px solid #DFDFDF !important;  
  //min-height:650px; 
  background-color: #EAEAEAE !important;  
    thead{
      height:5px;       
        th {
          height:5px;
          background-color: #FCFCFC !important;
          color: #667085 !important;        
          border: none !important;
          font-weight: 200 !important;
          border-bottom: 1px solid #DFDFDF !important;
          border-top: 1px solid #DFDFDF !important;
          vertical-align: middle !important;
        }     
    }     
    tbody {
      color: #777886;
      background-color: #fff;  
      max-height: 340px; /* Set maximum height for tbody */        
      tr {
        border: none;
        td {
          height:5px;
          border: none;
          font-size: 11px;
          color: #777886;
          vertical-align: middle !important;
          border-bottom:  ${props => props.tdBorder ? 'none' : '1px solid rgba(167, 167, 167, 0.2);'}
        }
      }   
    }
    tfoot{
      tr{
        td{
          height:5px;
          background-color:#fff;
          vertical-align: middle !important;
        }         
      }
    }      
  }
`;

export const ScrollableTableBody=styled(SimpleBar)`
height:310px;
@media screen and (min-width: 1920px) { 
  height:1300px;
}
`
export const FixedTableHeight=styled.div`
height:322px;
@media screen and (min-width: 1920px) { 
  height:650px;
}
`