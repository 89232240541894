import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as APIS from '../constant/CommonApiUrl';
import * as ACTIONS from '../constant/Actions'
import { API_SERVICE } from "../Services/CommonApi";
import { toast } from "react-toastify";
import { ConvertJson } from "../helper";
import moment from "moment";
const search_status=['CLOSED',
'NEW',
'NOT DELIVERED',
'PARTIAL',
'RECEIVED']

export const saveGoods=createAsyncThunk(ACTIONS.SAVE_GOODS,
    async(arg,thunkApi)=>{
        try{
            return await API_SERVICE.post(`${APIS.SAVE_GOODS}`,arg)
        }catch(err){
            thunkApi.rejectWithValue(err)
        }
    })
export const fetchGoodsList=createAsyncThunk(ACTIONS.GOODS_LIST,
    async(arg,thunkApi)=>{
        try{            
            const {id,vessel_name}=thunkApi.getState().root.common.vessel_detailByID            
            return await API_SERVICE.get(arg?`${APIS.GOODS_LIST}?${arg}&vessel_id=${vessel_name==='OFFICE'?0:id}`:`${APIS.GOODS_LIST}?vessel_id=${vessel_name==='OFFICE'?0:id}`)
            /* return await API_SERVICE.get(`${APIS.GOODS_LIST}?status=${arg}&vessel_id=${vessel_name==='OFFICE'?0:id}`) */
        }catch(err){
            thunkApi.rejectWithValue(err)
        }
    })
export const getMRViewDetails = createAsyncThunk(ACTIONS.GET_MR_VIEW,
    async(arg,thunkApi)=>{
        try{
            return await API_SERVICE.get(`${APIS.GET_MR_VIEW}?id=${arg.poId}`)
        }catch(err){
            thunkApi.rejectWithValue(err)
        }
    })
export const saveSupplierReview=createAsyncThunk(ACTIONS.SUPPLIER_RATING,
    async(arg,thunkApi)=>{
        try{            
            return await API_SERVICE.post(`${APIS.SUPPLIER_RATING}`,arg)            
        }catch(err){
            thunkApi.rejectWithValue(err)
        }
    })
export const ratingDetailbyID=createAsyncThunk(ACTIONS.SUPPLIER_RATING_DETAIL,
    async(arg,thunkApi)=>{
        try{            
            const {id,vessel_name}=thunkApi.getState().root.common.vessel_detailByID
            return await API_SERVICE.get(`${APIS.SUPPLIER_RATING_DETAIL}?id=${arg}&vessel_id=${vessel_name==='OFFICE'?0:id}`)            
        }catch(err){
            thunkApi.rejectWithValue(err)
        }
    })
export const getStatusGoodsList = createAsyncThunk(ACTIONS.GOODS_STATUS_LIST_ACTION,
    async(arg,thunkAPI)=>{
        try{
        const {id,vessel_name}=thunkAPI.getState().root.common.vessel_detailByID
        return await API_SERVICE.get(`${APIS.GOODS_LIST}?status=${arg}&vessel_id=${vessel_name==='OFFICE'?0:id}`);
        }catch(err){
        return thunkAPI.rejectWithValue(err)
        }
    }
    )
const initialState={
    goods_list:[],    
    supplier_rating_Detail:{},
    mrDetails:{},
    status_list:[],
    search_status:search_status
}
export const goodsSlice=createSlice({
    name:'Goods',
    initialState,
    reducers:({
        clearRatingDetail:(state,action)=>{
            state.supplier_rating_Detail={}
        }

    }),
    extraReducers:(builder)=>{
        (builder)
        .addCase(saveGoods.fulfilled,(state,action)=>{
            if(action.payload.data.result.status){
                toast.success(action.payload.data.result.message)
            }else{
                toast.error(action.payload.data.result.message)
            }
        })
        .addCase(saveGoods.rejected,(state,action)=>{
            if(action.payload.response.data.status===400){
                toast.error(action.payload.response.data.title)                
            }            
        })
        .addCase(fetchGoodsList.fulfilled,(state,action)=>{
            if(action.payload.data.result.status){
                state.goods_list=action.payload.data.result.data
            }else{
                toast.error(action.payload.data.result.message)
            }
        })
        .addCase(getStatusGoodsList.fulfilled,(state,action)=>{
            if(action.payload.data.result.status){
                state.status_list=action.payload.data.result.data
            }else{
                toast.error(action.payload.data.result.message)
            }
        })
        .addCase(fetchGoodsList.rejected,(state,action)=>{
            if(action.payload.response.data.status===400){
                toast.error(action.payload.response.data.title)                
            }            
        })        
        .addCase(ratingDetailbyID.fulfilled,(state,action)=>{
            if(action.payload.data.result.status){
                state.supplier_rating_Detail=action.payload.data.result.data[0]
            }else{
                toast.error(action.payload.data.result.message)
            }
        }).addCase(getMRViewDetails.fulfilled,(state,action)=>{
            if(action.payload.data.result.status){
                state.mrDetails=action.payload.data.result.data
            }else{
                toast.error(action.payload.data.result.message)
            }
        })
        .addCase(ratingDetailbyID.rejected,(state,action)=>{
            if(action.payload.response.data.status===400){
                toast.error(action.payload.response.data.title)                
            }            
        })        
    }
})
export const {clearRatingDetail}=goodsSlice.actions
export default goodsSlice.reducer