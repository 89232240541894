import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import * as APIS from '../constant/CommonApiUrl';
import {PURCHASE} from '../constant/CommonApiUrl';
import * as ACTIONS from '../constant/Actions'
import {API_SERVICE} from "../Services/CommonApi"
import {toast} from "react-toastify";
import {ConvertJson} from "../helper";

const initialState = {
    reportList:[],
    reportData: {},
    currentPage: '',
    pr_audit:[],
    UserListData:{},
    viewReport: {},
    report_prstatus:['APPROVED','Awaiting For Quote','Awating for QC','Awating for QC Approve','BULK PO Created','BULK PO Rejected','Closed','MERGED','NEW','PO APPROVED','PO CLOSED','PO Created','PO ISSUED','QC Approved','QC VERIFIED','QUOTE SPLITED','Ready for PO','READY FOR QC','REJECTED','RFQ SENT'],
}

const pageUrl = {
    equipment: `${APIS.EQUIPMENT_REPORT}`,
    parts: `${APIS.PARTS_REPORT}`,
    ['oil-product']: `${APIS.OIL_PRODUCT_REPORT}`,
    ['product-general']: `${APIS.PRODUCT_GENERAL_REPORT}`,
    store: `${APIS.STORE_REPORT}`,
}

export const fetchPurchaseRequisitionList = createAsyncThunk(ACTIONS.GET_PR_LIST,
    async(arg,thunkAPI)=>{
        try{
            const {id,vessel_name} = thunkAPI.getState().root.common.vessel_detailByID
            return API_SERVICE.get(`${APIS.GET_PURCHASE_REQUISITION_LIST}?vessel_id=${vessel_name==='OFFICE'?0:id}`)
        }catch(err){
            return thunkAPI.rejectWithValue(err)
        }
    }
)
export const getQuotationList = createAsyncThunk(ACTIONS.QUOTATION_LIST_ACTION,
    async(arg,thunkAPI)=>{
        try{
            const {id,vessel_name}=thunkAPI.getState().root.common.vessel_detailByID
            return API_SERVICE.get( `${APIS.GET_QUOTATION_LIST}?vessel_id=${vessel_name==='OFFICE'?0:id}`)
        }catch(err){
            return thunkAPI.rejectWithValue(err)
        }
    }
)
export const getCompareQuotationList = createAsyncThunk(ACTIONS.QUOTATION_COMPARE_LIST_ACTION,
    async(arg,thunkAPI)=>{
        try{
            const {id,vessel_name}=thunkAPI.getState().root.common.vessel_detailByID
            return API_SERVICE.get(`${APIS.GET_COMPARE_QUOTATION_LIST}?vessel_id=${vessel_name==='OFFICE'?0:id}` )
        }catch(err){
            return thunkAPI.rejectWithValue(err)
        }
    }
)
export const getAllPOList = createAsyncThunk(ACTIONS.PO_ALL_LIST_ACTION,
    async(arg,thunkAPI)=>{
        try{
            const {id,vessel_name}=thunkAPI.getState().root.common.vessel_detailByID
            return await API_SERVICE.get(`${APIS.GET_PURCHASE_ORDER}?vessel_id=${vessel_name==='OFFICE'?0:id}`);
        }catch(err){
            return thunkAPI.rejectWithValue(err)
        }
    }
)
export const getPRReport = createAsyncThunk(ACTIONS.PR_REPORT_LIST,
    async(arg,thunkAPI)=>{
        try{
            const {id,vessel_name}=thunkAPI.getState().root.common.vessel_detailByID
            console.log(arg.toString(),'arg');
            return await API_SERVICE.get(`${APIS.PR_REPORT_LIST}`, {...arg});
        }catch(err){
            return thunkAPI.rejectWithValue(err)
        }
    }
)
export const getWorkOrderReport = createAsyncThunk('REPORTS/GetWorkOrderCompletionData',
    async(arg,thunkAPI)=>{
        try{
            const {id,vessel_name}=thunkAPI.getState().root.common.vessel_detailByID
            return await API_SERVICE.get(`${PURCHASE}GetWorkOrderCompletionData`, {vessel_id: id});
        }catch(err){
            return thunkAPI.rejectWithValue(err)
        }
    }
)
export const getReportsData = createAsyncThunk(ACTIONS.EQUIPMENT_REPORT,
    async(arg,thunkAPI)=>{
        try{
            const {id}=thunkAPI.getState().root.common.vessel_detailByID
            const { currentPage }=thunkAPI.getState().root.REPORTS
            return await API_SERVICE.get(pageUrl[currentPage || arg?.page], {...arg.params, vessel_id: id});
        }catch(err){
            return thunkAPI.rejectWithValue(err)
        }
    }
)
export const getReportsDataById = createAsyncThunk(ACTIONS.EQUIPMENT_REPORT_BY_ID,
    async(arg,thunkAPI)=>{
        try{
            const {id}=thunkAPI.getState().root.common.vessel_detailByID
            const { currentPage }=thunkAPI.getState().root.REPORTS
            return await API_SERVICE.get(pageUrl[currentPage], {...arg.params, vessel_id: id});
        }catch(err){
            return thunkAPI.rejectWithValue(err)
        }
    }
)
export const getGoodsReport = createAsyncThunk(ACTIONS.GOODS_REPORT_LIST,
    async(arg,thunkAPI)=>{
        try{
            const {id,vessel_name}=thunkAPI.getState().root.common.vessel_detailByID
            return await API_SERVICE.get(`${APIS.GOODS_REPORT_LIST}?${arg}`);
        }catch(err){
            return thunkAPI.rejectWithValue(err)
        }
    }
)
export const getInvoiceReport = createAsyncThunk(ACTIONS.INVOICE_REPORT_LIST,
    async(arg,thunkAPI)=>{
        try{
            const {id,vessel_name}=thunkAPI.getState().root.common.vessel_detailByID
            return await API_SERVICE.get(`${APIS.INVOICE_REPORT_LIST}?${arg}`);
        }catch(err){
            return thunkAPI.rejectWithValue(err)
        }
    }
)
export const getPOReport = createAsyncThunk(ACTIONS.PO_REPORT_LIST,
    async(arg,thunkAPI)=>{
        try{
            const {id,vessel_name}=thunkAPI.getState().root.common.vessel_detailByID
            return await API_SERVICE.get(`${APIS.PO_REPORT_LIST}?${arg}`);
        }catch(err){
            return thunkAPI.rejectWithValue(err)
        }
    }
)
export const getQCReport = createAsyncThunk(ACTIONS.QC_REPORT_LIST,
    async(arg,thunkAPI)=>{
        try{
            const {id,vessel_name}=thunkAPI.getState().root.common.vessel_detailByID
            return await API_SERVICE.get(`${APIS.QC_REPORT_LIST}?${arg}`);
        }catch(err){
            return thunkAPI.rejectWithValue(err)
        }
    }
)
export const getQuotReport = createAsyncThunk(ACTIONS.QUOT_REPORT_LIST,
    async(arg,thunkAPI)=>{
        try{
            return await API_SERVICE.get(`${APIS.QUOT_REPORT_LIST}?${arg}`);
        }catch(err){
            return thunkAPI.rejectWithValue(err)
        }
    }
)
export const getPurchaseItemHistory = createAsyncThunk(ACTIONS.PURCHASE_ITEMS_REPORT_LIST,
    async(arg,thunkAPI)=>{
        try{
            return await API_SERVICE.get(arg?`${APIS.PURCHASE_ITEMS_REPORT_LIST}?${arg}`:APIS.PURCHASE_ITEMS_REPORT_LIST);
        }catch(err){
            return thunkAPI.rejectWithValue(err)
        }
    }
)
export const getPurchaseQuery = createAsyncThunk(ACTIONS.PURCHASE_QUERY_REPORT_LIST,
    async(arg,thunkAPI)=>{
        try{
            return await API_SERVICE.get(arg?`${APIS.PURCHASE_QUERY_REPORT_LIST}?${arg}`:APIS.PURCHASE_QUERY_REPORT_LIST);
        }catch(err){
            return thunkAPI.rejectWithValue(err)
        }
    }
)
export const getOutstandingPo = createAsyncThunk(ACTIONS.OUTSTANDING_PO_REPORT_LIST,
    async(arg,thunkAPI)=>{
        try{
            return await API_SERVICE.get(arg?`${APIS.OUTSTANDING_PO_REPORT_LIST}?${arg}`:`${APIS.OUTSTANDING_PO_REPORT_LIST}`);
        }catch(err){
            return thunkAPI.rejectWithValue(err)
        }
    }
)
export const getSupplierRating = createAsyncThunk(ACTIONS.SUPPLIER_RATING_REPORT_LIST,
    async(arg,thunkAPI)=>{
        try{
            return await API_SERVICE.get(`${APIS.SUPPLIER_RATING_REPORT_LIST}?${arg}`);
        }catch(err){
            return thunkAPI.rejectWithValue(err)
        }
    }
)
export const getPurchaseQueryById = createAsyncThunk(ACTIONS.PURCHASE_QUERY_BY_ID,
    async(arg,thunkAPI)=>{
        try{
            return await API_SERVICE.get(`${APIS.PURCHASE_QUERY_BY_ID}?id=${arg.id}`);
        }catch(err){
            return thunkAPI.rejectWithValue(err)
        }
    }
)

export const getRfqReport = createAsyncThunk(ACTIONS.RFQ_REPORT_LIST,
    async(arg,thunkAPI)=>{
        try{
            const {id,vessel_name}=thunkAPI.getState().root.common.vessel_detailByID
            return await API_SERVICE.get(`${APIS.RFQ_REPORT_LIST}?${arg}`);
        }catch(err){
            return thunkAPI.rejectWithValue(err)
        }
    }
)
export const getPRaudit = createAsyncThunk(ACTIONS.PR_AUDIT_HISTORY,
    async(arg,thunkAPI)=>{
        try{
            const {id,vessel_name}=thunkAPI.getState().root.common.vessel_detailByID
            return await API_SERVICE.get(`${APIS.PR_AUDIT_HISTORY}?id=${arg}&fname=Poorder`);
        }catch(err){
            return thunkAPI.rejectWithValue(err)
        }
    }
)
export const getCurrencyRpt = createAsyncThunk(ACTIONS.GET_CURENCY_RPT,
    async(arg,thunkAPI)=>{
        try{
            const {id,vessel_name}=thunkAPI.getState().root.common.vessel_detailByID
            return await API_SERVICE.get(`${APIS.GET_CURENCY_RPT}`);
        }catch(err){
            return thunkAPI.rejectWithValue(err)
        }
    }
)
export const getReportHistory = createAsyncThunk(ACTIONS.REPORT_HISTORY,
    async(arg,thunkAPI)=>{
        try{
            const {id,vessel_name}=thunkAPI.getState().root.common.vessel_detailByID
            return await API_SERVICE.get(`${APIS.EDOC_REPORT_HISTORY}`, {...arg, vessel_id: vessel_name==='OFFICE'?0:id});
        }catch(err){
            return thunkAPI.rejectWithValue(err)
        }
    }
)
export const getReportStatus = createAsyncThunk(ACTIONS.REPORT_STATUS,
    async(arg,thunkAPI)=>{
        try{
            const {id,vessel_name}=thunkAPI.getState().root.common.vessel_detailByID
            return await API_SERVICE.get(`${APIS.EDOC_REPORT_STATUS}`, {...arg, vessel_id: vessel_name==='OFFICE'?0:id});
        }catch(err){
            return thunkAPI.rejectWithValue(err)
        }
    }
)
export const getReportRequest = createAsyncThunk(ACTIONS.REPORT_REQUEST,
    async(arg,thunkAPI)=>{
        try{
            const {id,vessel_name}=thunkAPI.getState().root.common.vessel_detailByID
            return await API_SERVICE.get(`${APIS.EDOC_REPORT_REQUEST}`, {...arg, vessel_id: vessel_name==='OFFICE'?0:id});
        }catch(err){
            return thunkAPI.rejectWithValue(err)
        }
    }
)

export const getReportUserList = createAsyncThunk(ACTIONS.REPORT_USER_LIST,
    async(arg,thunkAPI)=>{
        try{
            const {id,vessel_name}=thunkAPI.getState().root.common.vessel_detailByID
            return await API_SERVICE.get(`${APIS.EDOC_REPORT_USER_LIST}`, {...arg, vessel_id: vessel_name==='OFFICE'?0:id});
        }catch(err){
            return thunkAPI.rejectWithValue(err)
        }
    }
)
export const getReportUserListById = createAsyncThunk(ACTIONS.REPORT_USER_LIST_ID,
    async(arg,thunkAPI)=>{
        try{
            const {id,vessel_name}=thunkAPI.getState().root.common.vessel_detailByID
            return await API_SERVICE.get(`${APIS.EDOC_REPORT_USER_LIST_BY_ID}`, {...arg, vessel_id: vessel_name==='OFFICE'?0:id});
        }catch(err){
            return thunkAPI.rejectWithValue(err)
        }
    }
)


export const ReportSlice = createSlice({
    name:'reports',
    initialState,
    reducers: {
        setReportsPage: (state, {payload}) => {
            state.currentPage = payload
        }
    },
    extraReducers:(builder)=>{
        (builder)
            .addCase(fetchPurchaseRequisitionList.fulfilled,(state,action)=>{
                if(action.payload.data.result.status){
                    state.reportList = action.payload.data.result.data
                }else{
                    toast.error(action.payload.data.result.message)
                }
            }).addCase(getQuotationList.fulfilled,(state,action)=>{
            if(action.payload.data.result.status){
                state.reportList = action.payload.data.result.data
            }else{
                toast.error(action.payload.data.result.message)
            }
        })
            .addCase(getCompareQuotationList.fulfilled,(state,action)=>{
                if(action.payload.data.result.status){
                    state.reportList = action.payload.data.result.data
                }else{
                    toast.error(action.payload.data.result.message)
                }
            })
            .addCase(getCurrencyRpt.fulfilled,(state,action)=>{
                if(action.payload.data.result.status){
                    state.reportList = action.payload.data.result.data
                }else{
                    toast.error(action.payload.data.result.message)
                }
            })
            .addCase(getAllPOList.fulfilled,(state,action)=>{
                if(action.payload.data.result.status){
                    state.reportList = action.payload.data.result.data
                }else{
                    toast.error(action.payload.data.result.message)
                }
            })
            .addCase(getPRReport.fulfilled,(state,action)=>{
                if(action.payload.data.result.status){
                    action.payload.data.result.data.length>0?state.reportList = action.payload.data.result.data:toast.error('Record Not Found')
                }else{
                    toast.error(action.payload.data.result.message)
                }
            }) .addCase(getWorkOrderReport.fulfilled,(state,action)=>{
                if(action.payload.data.result.status){
                    action.payload.data.result.data.length>0?state.reportList = action.payload.data.result.data:toast.error('Record Not Found')
                }else{
                    toast.error(action.payload.data.result.message)
                }
            }).addCase(getReportsData.fulfilled,(state,action)=>{
            if(action.payload.data.result.status){
               if( action.payload.data.result.data.length>0)
                   state.reportList = action.payload.data.result.data
                else {
                   // state.reportList = []
                   toast.error('Record Not Found')
               }
            }else{
                toast.error(action.payload.data.result.message)
            }
        }).addCase(getReportsDataById.fulfilled,(state,action)=>{
            if(action.payload.data.result.status){
                action.payload.data.result.data.length>0?state.reportData = action.payload.data.result.data[0]:toast.error('Record Not Found')
            }else{
                toast.error(action.payload.data.result.message)
            }
        })
            .addCase(getPRaudit.fulfilled,(state,action)=>{
                if(action.payload.data.result.status){
                    state.pr_audit=action.payload.data.result.data.map(el=>({...el,PODetail:ConvertJson(el.PODetail)}))
                }else{
                    toast.error(action.payload.data.result.message)
                }
            })
            .addCase(getGoodsReport.fulfilled,(state,action)=>{
                if(action.payload.data.result.status){
                    action.payload.data.result.data.length>0?state.reportList = action.payload.data.result.data:toast.error('Record Not Found')
                }else{
                    toast.error(action.payload.data.result.message)
                }
            })
            .addCase(getInvoiceReport.fulfilled,(state,action)=>{
                if(action.payload.data.result.status){
                    action.payload.data.result.data.length>0?state.reportList = action.payload.data.result.data:toast.error('Record Not Found')
                }else{
                    toast.error(action.payload.data.result.message)
                }
            }) .addCase(getReportHistory.fulfilled,(state,action)=>{
                if(action.payload.data.result.status){
                    action.payload.data.result.data.length>0?state.reportList = action.payload.data.result.data:toast.error('Record Not Found')
                }else{
                    toast.error(action.payload.data.result.message)
                }
            }).addCase(getReportUserList.fulfilled,(state,action)=>{
                if(action.payload.data.result.status){
                    action.payload.data.result.data.length>0?state.reportList = action.payload.data.result.data:toast.error('Record Not Found')
                }else{
                    toast.error(action.payload.data.result.message)
                }
            }).addCase(getReportUserListById.fulfilled,(state,action)=>{
                if(action.payload.data.result.status){
                    if (action.payload.data.result.data.length>0) {
                        const data = action.payload.data.result.data.map(el => (
                            {
                                ...el,
                                authorizeduser: el?.authorizeduser ? ConvertJson(el.authorizeduser) : [],
                                viewuser_list: el?.authorizeduser ? ConvertJson(el.viewuser_list) : []
                            }))
                        state.UserListData = data[0]
                    } else {
                        toast.error('Record Not Found')
                    }
                }else{
                    toast.error(action.payload.data.result.message)
                }
            }).addCase(getReportStatus.fulfilled,(state,action)=>{
                if(action.payload.data.result.status){
                    action.payload.data.result.data.length>0?state.reportList = action.payload.data.result.data:toast.error('Record Not Found')
                }else{
                    toast.error(action.payload.data.result.message)
                }
            }).addCase(getReportRequest.fulfilled,(state,action)=>{
                if(action.payload.data.result.status){
                    action.payload.data.result.data.length>0?state.reportList = action.payload.data.result.data:toast.error('Record Not Found')
                }else{
                    toast.error(action.payload.data.result.message)
                }
            })
            .addCase(getPOReport.fulfilled,(state,action)=>{
                if(action.payload.data.result.status){
                    action.payload.data.result.data.length>0?state.reportList = action.payload.data.result.data:toast.error('Record Not Found')
                }else{
                    toast.error(action.payload.data.result.message)
                }
            })
            .addCase(getQCReport.fulfilled,(state,action)=>{
                if(action.payload.data.result.status){
                    action.payload.data.result.data.length>0?state.reportList = action.payload.data.result.data:toast.error('Record Not Found')
                }else{
                    toast.error(action.payload.data.result.message)
                }
            })
            .addCase(getQuotReport.fulfilled,(state,action)=>{
                if(action.payload.data.result.status){
                    action.payload.data.result.data.length>0?state.reportList = action.payload.data.result.data:toast.error('Record Not Found')
                }else{
                    toast.error(action.payload.data.result.message)
                }
            })
            .addCase(getPurchaseItemHistory.fulfilled,(state,action)=>{
                if(action.payload.data.result.status){
                    action.payload.data.result.data.length>0?state.reportList = action.payload.data.result.data:toast.error('Record Not Found')
                }else{
                    toast.error(action.payload.data.result.message)
                }
            }).addCase(getPurchaseQuery.fulfilled,(state,action)=>{
            if(action.payload.data.result.status){
                action.payload.data.result.data.length>0?state.reportList = action.payload.data.result.data:toast.error('Record Not Found')
            }else{
                toast.error(action.payload.data.result.message)
            }
        }).addCase(getOutstandingPo.fulfilled,(state,action)=>{
            if(action.payload.data.result.status){
                action.payload.data.result.data.length>0?state.reportList = action.payload.data.result.data:toast.error('Record Not Found')
            }else{
                toast.error(action.payload.data.result.message)
            }
        }).addCase(getSupplierRating.fulfilled,(state,action)=>{
            if(action.payload.data.result.status){
                action.payload.data.result.data.length>0?state.reportList = action.payload.data.result.data:toast.error('Record Not Found')
            }else{
                toast.error(action.payload.data.result.message)
            }
        }).addCase(getPurchaseQueryById.fulfilled,(state,action)=>{
            if(action.payload.data.result.status){
                const payload = action.payload.data.result.data[0] || {}
                payload.itemdetail=payload?.itemdetail?ConvertJson(payload.itemdetail):[]
                payload.Goodreciveddetails=payload.Goodreciveddetails?ConvertJson(payload.Goodreciveddetails):[]
                payload.invoicedetails=payload.invoicedetails?ConvertJson(payload.invoicedetails):[]
                state.viewReport = payload
            }else{
                toast.error(action.payload.data.result.message)
            }
        })
    }
})
export const {setFormData, setReportsPage} = ReportSlice.actions
export default ReportSlice.reducer