import React from "react"
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const NoInternetMessage = styled.div`
  font-size: 18px;
  text-align: center;
  background-color: #ff6666;
  color: white;
  padding: 10px;
`;

const Protected = ({ children }) => {
    const navigate = useNavigate();
    const [isOnline, setIsOnline] = useState(window.navigator.onLine);
    const loginInfo = useSelector((state) => state.root.login.loginDemo)
    let path = (JSON.parse(localStorage.getItem('loginPath')))

    useEffect(() => {
        // Add an event listener to check for online/offline changes.
        const handleOnlineStatus = () => {
            setIsOnline(window.navigator.onLine);
        };
        window.addEventListener('online', handleOnlineStatus);
        window.addEventListener('offline', handleOnlineStatus);
        // Clean up the event listeners when the component unmounts.
        return () => {
            window.removeEventListener('online', handleOnlineStatus);
            window.removeEventListener('offline', handleOnlineStatus);
        };
    }, []);
    if (isOnline) {
        if (loginInfo.isLogin && path !== null && !loginInfo.is_billing_expired) {
            return children
        } else {
            path === null ? navigate('/login') : path.pathName === '/login' || path.pathName === '/' ? navigate('/login') : path.pathName === '/saas-login' ? navigate('/saas-login') : path.pathName === '/admin-login' ? navigate('/admin-login') : navigate('/login')
        }
    } else {
        return <NoInternetMessage>No internet. Please check your connection.</NoInternetMessage>
    }
}
export default Protected