import React from 'react';
import SimpleBar from "simplebar-react";
import {MdAttachFile} from "react-icons/md";
import { FaTrashAlt } from 'react-icons/fa';

function AttachmentCard({repositoryList = [], attachment_list, onDeleteFile}) {
    return (
        <>
            <div style={{width: '100%', border: '1px solid #CCD9E0', minHeight: '90px', borderRadius: '5px'}}>
                <ul style={{ listStyle: 'none', padding: '0px' }}>
                    <SimpleBar style={{ maxHeight: 60 }}>
                        {repositoryList && repositoryList?.length > 0 && repositoryList.map((el) => (
                            <li key={el.id}>
                                <div className='f-12 d-flex align-item-center'>
                                    <a href={`${el.fileurl}`} rel='noreferrer' target='_blank' download><MdAttachFile />{el.filename}</a> 
                                    <span
                                    className="text-danger"
                                    role="presentation"
                                    onClick={() => {
                                      onDeleteFile(el.id);
                                    }}
                                  >
                                    &nbsp; &nbsp;
                                    <FaTrashAlt />
                                  </span>
                                </div>
                            </li>))}
                        {attachment_list}
                    </SimpleBar>
                </ul>
            </div>
        </>
    );
}

export default AttachmentCard;