import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { toast } from "react-toastify"
import { GET_GRADE, SAVE_GRADE } from "../constant/CommonApiUrl"
import { API_SERVICE } from "../Services/CommonApi";
import { setTypeFieldsIsUpdated } from "./typeFieldsSlice";

const ADD_GRADE='grade/addGradeApi'
const UPDATE_GRADE='grade/updateGradeApi'
const FETCH_GRADE='grade/fetchGradeApi'
const DELETE_GRADE='grade/deleteGradeApi'

export const addGradeApi =createAsyncThunk(ADD_GRADE,
    async(args, thunkAPI)=>{
        try{
            let response = await API_SERVICE.post(SAVE_GRADE, args['data'], "CREATE");
            response = response.data.result.data[0];
            thunkAPI.dispatch(setTypeFieldsIsUpdated(true));
            return response;
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
})

export const updateGradeApi = createAsyncThunk(UPDATE_GRADE,
    async(args, thunkAPI)=>{
        try{
            let response = await API_SERVICE.post(SAVE_GRADE, args['data'], "UPDATE");
            response = response.data.result.data[0];
            thunkAPI.dispatch(setTypeFieldsIsUpdated(true));
            return response;
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
})

export const deleteGradeApi = createAsyncThunk(DELETE_GRADE, 
    async (args, thunkAPI) => {
        try{
            const toDelete = thunkAPI.getState().root.grade.list.find(ele => ele.id === args['id']);   
            let response = await API_SERVICE.post(SAVE_GRADE, {...toDelete, isdisabled: "Y"});
            response = response.data.result.data[0];
            thunkAPI.dispatch(setTypeFieldsIsUpdated(true));
            return response;
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
    }
)

export const fetchGradeApi = createAsyncThunk(FETCH_GRADE,
    async(arg,thunkAPI)=>{
        try{
            let response = await API_SERVICE.get(GET_GRADE);
            response = response.data.result.data;
            return response
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
    },
    {
        condition: (arg, { getState, extra }) => {          
          const isUpdated = getState().root.grade.isUpdated                         
          if(!isUpdated){
            console.log('enable fetch condition in reducer')
            return false
          }
        },
      }
)

const initialState={
    list:[],
    editGrade:{},    
    isUpdated: true
}
export const gradeSlice=createSlice({
    name:'grade',
    initialState,
    reducers:{
        addGrade:(state,action)=>{
            state.list=[...state.list,action.payload]
        },
        delGrade:(state,action)=>{
            state.list=state.list.filter(x=>x.id!==action.payload)
        },
        loadEditGrade:(state,action)=>{
            state.editGrade=action.payload
        },
        clearEditGrade:(state,action)=>{
            state.editGrade={}
        },
        updateGrade:(state,action)=>{
            let index=state.list.findIndex(x=>x.id===action.payload.id)
            state.list[index]=action.payload
        },               
        resetGrade:()=>initialState
    },
    extraReducers:(builder)=>{
        builder
        .addCase(addGradeApi.fulfilled,(state,action)=>{
            state.list=[...state.list,action.payload];
            toast.success('Succesfully added');
        })
        .addCase(updateGradeApi.fulfilled,(state,action)=>{
            state.list = state.list.map(x => x.id === action.payload.id ? action.payload: x);
            toast.success('Succesfully updated');
        })
        .addCase(fetchGradeApi.fulfilled, (state, action) => {
            state.list = action.payload;
            state.isUpdated = false;
        })
        .addCase(deleteGradeApi.fulfilled, (state, action) => {
            state.list=state.list.filter(x=>x.id!==action.payload.id);
            toast.success('Succesfully deleted');
        })
    }   
})
export const {addGrade,delGrade,loadEditGrade,clearEditGrade,updateGrade,resetGrade}=gradeSlice.actions
export default gradeSlice.reducer