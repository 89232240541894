import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ATTACHMENTS } from "../constant/CommonInitialvalue";
import { API_SERVICE } from "../Services/CommonApi";
import { MASTER } from "../constant/CommonApiUrl";

export const deleteAttachment = createAsyncThunk('DELETE_ATTACHMENT',
    async(arg,thunkAPI)=>{
        try{
            let response = await API_SERVICE.get(`${MASTER}UpdateFile`, arg.payload);
            response = response.data.result.data;
            return response
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
    }
)

const initialState={
    repository:[], 
    initial_attachment:ATTACHMENTS,   
}
export const attachmentsSlice=createSlice({
    name:'ATTACH',
    initialState,
    reducers:{
        addAttach:(state,action)=>{
            state.repository=[...state.repository,action.payload]
        },
        removeAttach:(state,action)=>{
            state.repository=state.repository.filter(el=>el.nanoID!==action.payload)
        },
        resetAttach:(state,action)=>{
            state.repository=[]
        }

    }
})
export const {addAttach,removeAttach,resetAttach}=attachmentsSlice.actions
export default attachmentsSlice.reducer