import { createAsyncThunk,createEntityAdapter, createSlice } from "@reduxjs/toolkit"
import { toast } from "react-toastify"
import { API_SERVICE } from "../Services/CommonApi"
import { GET_APPROVE_LINK, SAVE_APPROVE_LINK } from "../constant/CommonApiUrl";

const ADD_APPROVELINK='approveLink/addApproveLinkApi'
const UPDATE_APPROVELINK = 'approveLink/updateApproveLinkApi';
const FETCH_APPROVELINK='approveLink/fetchApproveLinkApi'
const DELETE_APPROVELINK = 'approveLink/deleteApproveLinkApi';

export const addApproveLinkApi =createAsyncThunk(ADD_APPROVELINK,
    async(args, thunkAPI)=>{
        try{
            let response = await API_SERVICE.post(SAVE_APPROVE_LINK, args['data'], "CREATE");
            response = response.data.result.data[0];
            return response;
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
})

export const updateApproveLinkApi = createAsyncThunk(UPDATE_APPROVELINK,
    async(args, thunkAPI)=>{
        try{
            let response = await API_SERVICE.post(SAVE_APPROVE_LINK, args['data'], "UPDATE");
            response = response.data.result.data[0]
            return response;
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
})

export const deleteApproveLinkApi = createAsyncThunk(DELETE_APPROVELINK, 
    async (args, thunkAPI) => {
        try{
            const toDelete = thunkAPI.getState().root.approveLink.list.find(ele => ele.id === args['id']);   
            const response = await API_SERVICE.post(SAVE_APPROVE_LINK, {...toDelete, isdisabled: "Y"});
            return response.data.result.data[0];
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
    }
)

export const fetchApproveLinkApi = createAsyncThunk(FETCH_APPROVELINK,
    async(arg,thunkAPI)=>{
        try{
            let response = await API_SERVICE.get(GET_APPROVE_LINK);
            response = response.data.result.data;
            return response
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
    },
    {
        condition: (arg, { getState, extra }) => {          
          const isUpdated = getState().root.approveLink.isUpdated                         
          if(!isUpdated){
            console.log('enable fetch condition in reducer')
            return false
          }
        },
      }
)

const initialState={
    list:[],
    edit:{},    
    isUpdated: true,
  isSuccess: false,
}

export const approveLinkSlice=createSlice({
    name:'approveLink',
    initialState,
    reducers:{
        addApproveLink:(state,action)=>{
            state.list=[...state.list,action.payload]
        },
        delApproveLink:(state,action)=>{
            state.list=state.list.filter(x=>x.id!==action.payload)
        },
        loadEditApproveLink:(state,action)=>{
            state.edit=action.payload
        },
        clearEditApproveLink:(state,action)=>{
            state.edit={}
        },
        updateApproveLink:(state,action)=>{
            let index=state.list.findIndex(x=>x.id===action.payload.id)
            state.list[index]=action.payload
        },               
        resetApproveLink:()=>initialState
    },
    extraReducers:(builder)=>{
        builder
        .addCase(addApproveLinkApi.fulfilled,(state,action)=>{
          if (action.payload.status === 'success'){
            state.list=[...state.list,action.payload];
            state.isSuccess = true
            toast.success('Successfully added');
          } else {
            toast.error(action.payload.status);
          }

        })
        .addCase(updateApproveLinkApi.fulfilled,(state,action)=>{
            state.list = state.list.map(x => x.id === action.payload.id ? action.payload: x);
            toast.success('Successfully updated');
        })
        .addCase(fetchApproveLinkApi.fulfilled, (state, action) => {
            state.list = action.payload;
            state.isUpdated = false;
        })
        .addCase(deleteApproveLinkApi.fulfilled, (state, action) => {
            state.list=state.list.filter(x=>x.id!==action.payload.id);
            toast.success('Succesfully deleted');
        })
    }   
})
export const {addApproveLink,delApproveLink,loadEditApproveLink,clearEditApproveLink,updateApproveLink,resetApproveLink}=approveLinkSlice.actions
export default approveLinkSlice.reducer