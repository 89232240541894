import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { toast } from "react-toastify";
import {
    GET_PRODUCT_GENERAL,
    SAVE_PRODUCT_GENERAL
} from "../constant/CommonApiUrl"
import { API_SERVICE } from "../Services/CommonApi";
import { commonPayloadFields } from "../helper/storage";
import * as ACTION from '../constant/Actions'
import * as APIS from '../constant/CommonApiUrl'
const ADD_PRODUCT_GENERAL = 'productGeneral/addProductGeneralApi'
const UPDATE_PRODUCT_GENERAL = 'productGeneral/updateProductGeneralApi'
const DELETE_PRODUCT_GENERAL = 'productGeneral/deleteProductGeneralApi'
const FETCH_PRODUCT_GENERAL = 'productGeneral/fetchProductGeneralApi'

export const addProductGeneralApi = createAsyncThunk(ADD_PRODUCT_GENERAL,
    async (args, thunkAPI) => {
        try {
            const response = await API_SERVICE.post(SAVE_PRODUCT_GENERAL, args['data'])
            thunkAPI.dispatch(fetchProductGeneralApi())
            return response
        } catch (err) {
            return thunkAPI.rejectWithValue(err.message)
        }
    })

export const updateProductGeneralApi = createAsyncThunk(UPDATE_PRODUCT_GENERAL,
    async (args, thunkAPI) => {
        try {
            const response = await API_SERVICE.post(SAVE_PRODUCT_GENERAL, args['data'])
            thunkAPI.dispatch(fetchProductGeneralApi())
            return response.data.result.data[0];
        } catch (err) {
            return thunkAPI.rejectWithValue(err.message)
        }
    })

export const deleteProductGeneralApi = createAsyncThunk(DELETE_PRODUCT_GENERAL,
    async (args, thunkAPI) => {
        try {
            const toDelete = thunkAPI.getState().root.productGeneral.list.find(ele => ele.id === args['id']);
            const response = await API_SERVICE.post(SAVE_PRODUCT_GENERAL, { ...toDelete, isdisabled: "Y", ...commonPayloadFields });
            thunkAPI.dispatch(fetchProductGeneralApi())
            return response.data.result.data[0];
        } catch (err) {
            return thunkAPI.rejectWithValue(err.message)
        }
    })

export const fetchProductGeneralApi = createAsyncThunk(FETCH_PRODUCT_GENERAL,
    async (args, thunkAPI) => {
        try {
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            const response = await API_SERVICE.get(`${GET_PRODUCT_GENERAL}?vessel_id=${vessel_name === 'OFFICE' ? 0 : id}`);
            return response.data.result.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.message)
        }
    }
)
export const getConsumptionGeneralHead = createAsyncThunk(ACTION.CONSUMPTION_GENERAL_HEAD,
    async (arg, thunkAPI) => {
        try {
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            return await API_SERVICE.get(`${APIS.GET_PRODUCT_GENERAL}?${arg}&vessel_id=${id}`)
        } catch (err) {
            return thunkAPI.rejectWithValue(err)
        }
    })
export const getDashboardGoodReceived = createAsyncThunk(ACTION.GET_DASHBOARD_GOOD_RECEIVED,
    async (arg, thunkAPI) => {
        try {
            const { id } = thunkAPI.getState().root.common.vessel_detailByID
            const payload = arg ? { vessel_id: id, ...arg } : { vessel_id: id }
            return await API_SERVICE.get(`${APIS.GET_DASHBOARD_GOOD_RECEIVED}`, payload)
        } catch (err) {
            return thunkAPI.rejectWithValue(err)
        }
    })
export const getDashboardGoodStock = createAsyncThunk(ACTION.GET_DASHBOARD_STOCK,
    async (arg, thunkAPI) => {
        try {
            const { id } = thunkAPI.getState().root.common.vessel_detailByID
            const payload = arg ? { vessel_id: id, ...arg } : { vessel_id: id }
            return await API_SERVICE.get(`${APIS.GET_DASHBOARD_STOCK}`, payload)
        } catch (err) {
            return thunkAPI.rejectWithValue(err)
        }
    })
export const getDashboard = createAsyncThunk(ACTION.GET_DASHBOARD,
    async (arg, thunkAPI) => {
        try {
            const { id } = thunkAPI.getState().root.common.vessel_detailByID
            const payload = arg ? { vessel_id: id, ...arg } : { vessel_id: id }
            return await API_SERVICE.get(`${APIS.GET_DASHBOARD}`, payload)
        } catch (err) {
            return thunkAPI.rejectWithValue(err)
        }
    })
export const getDashboardConsumption = createAsyncThunk(ACTION.GET_DASHBOARD_CONSUMPTION,
    async (arg, thunkAPI) => {
        try {
            console.log(arg)
            const { id } = thunkAPI.getState().root.common.vessel_detailByID
            const payload = arg ? { vessel_id: id, ...arg } : { vessel_id: id }
            return await API_SERVICE.get(`${APIS.GET_DASHBOARD_CONSUMPTION}`, payload)
        } catch (err) {
            return thunkAPI.rejectWithValue(err)
        }
    })
export const getDashboardMedicineExpiry = createAsyncThunk(ACTION.GET_DASHBOARD_MEDICINE_EXPIRY,
    async (arg, thunkAPI) => {
        try {
            const { id } = thunkAPI.getState().root.common.vessel_detailByID
            const payload = arg ? { vessel_id: id, ...arg } : { vessel_id: id }
            return await API_SERVICE.get(`${APIS.GET_DASHBOARD_MEDICINE_EXPIRY}`, payload)
        } catch (err) {
            return thunkAPI.rejectWithValue(err)
        }
    })
export const getDashboardGeneralReport = createAsyncThunk(ACTION.GET_DASHBOARD_GENERAL_REPORT,
    async (arg, thunkAPI) => {
        try {
            const { id } = thunkAPI.getState().root.common.vessel_detailByID
            const payload = arg ? { vessel_id: id, ...arg } : { vessel_id: id }
            return await API_SERVICE.get(`${APIS.GET_DASHBOARD_GENERAL_REPORT}`, payload)
        } catch (err) {
            return thunkAPI.rejectWithValue(err)
        }
    })
const initialState = {
    list: [],
    editProductGeneral: {},
    consumption_general_head: {},
    isUpdated: true,
    goodReceivedData: [],
    dashboardConsumptionList: [],
    medicineExpiryList: [],
    generalReportList: [],
    goodStock: [],
    inventoryDashboard: {
        dashBordForGoodreceived: [],
        dashBordInfo: [],
        dashBordReOrderLevel: []
    }
}
export const productGeneralSlice = createSlice({
    name: 'productGeneral',
    initialState,
    reducers: {
        addProductGeneral: (state, action) => {
            state.list = [...state.list, action.payload]
        },
        delProductGeneral: (state, action) => {
            state.list = state.list.filter(x => x.id !== action.payload)
        },
        loadEditProductGeneral: (state, action) => {
            state.editProductGeneral = action.payload
        },
        clearEditProductGeneral: (state, action) => {
            state.editProductGeneral = {}
        },
        updateProductGeneral: (state, action) => {
            let index = state.list.findIndex(x => x.id === action.payload.id)
            state.list[index] = action.payload
        },
        resetProductGeneral: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(addProductGeneralApi.fulfilled, (state, action) => {
                if (action.payload.data.result.status) {
                    toast.success(action.payload.data.result.message)
                } else
                    toast.error(action.payload.data.result.message);
            })
            .addCase(getConsumptionGeneralHead.fulfilled, (state, action) => {
                if (action.payload.data.result.status) {
                    state.consumption_general_head = action.payload.data.result.data[0]
                } else toast.error(action.payload.status);
            })
            .addCase(updateProductGeneralApi.fulfilled, (state, action) => {
                toast.success('Successfully updated');
            })
            .addCase(fetchProductGeneralApi.fulfilled, (state, action) => {
                state.list = action.payload;
                state.isUpdated = false;
            })
            .addCase(deleteProductGeneralApi.fulfilled, (state, action) => {
                toast.success('Successfully deleted');
            }).addCase(getDashboardGoodReceived.fulfilled, (state, { payload }) => {
                if (payload.data.result.status) {
                    if (payload.data.result.data.length > 0)
                        state.goodReceivedData = payload.data.result.data
                    else {
                        state.goodReceivedData = []
                        toast.error('Record Not Found')
                    }
                } else {
                    toast.error(payload.data.result.message)
                }
            }).addCase(getDashboardGoodStock.fulfilled, (state, { payload }) => {
                if (payload.data.result.status) {
                    payload.data.result.data ? state.goodStock = payload.data.result.data : toast.error('Record Not Found')
                } else {
                    toast.error(payload.data.result.message)
                }
            }).addCase(getDashboard.fulfilled, (state, { payload }) => {
                if (payload.data.result.status) {
                    payload.data.result?.data ? state.inventoryDashboard = payload.data.result.data : toast.error('Record Not Found')
                } else {
                    toast.error(payload.data.result.message)
                }
            }).addCase(getDashboardConsumption.fulfilled, (state, { payload }) => {
                if (payload.data.result.status) {
                    state.dashboardConsumptionList = payload.data.result.data
                } else {
                    state.dashboardConsumptionList = []
                    toast.error(payload.data.result.message)
                }
            }).addCase(getDashboardMedicineExpiry.fulfilled, (state, { payload }) => {
                if (payload.data.result.status) {
                    state.medicineExpiryList = payload.data.result.data
                } else {
                    state.medicineExpiryList = []
                    toast.error(payload.data.result.message)
                }

            }).addCase(getDashboardGeneralReport.fulfilled, (state, { payload }) => {
                if (payload.data.result.status) {
                    state.generalReportList = payload.data.result.data
                } else {
                    state.generalReportList = []
                    toast.error(payload.data.result.message)
                }
            })
    }
})
export const { addProductGeneral, delProductGeneral, loadEditProductGeneral, clearEditProductGeneral, updateProductGeneral, resetProductGeneral } = productGeneralSlice.actions
export default productGeneralSlice.reducer