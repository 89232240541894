import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { toast } from "react-toastify";
import * as APIS from "../constant/CommonApiUrl"
import { GET_OIL_PRODUCT, GET_TYPE_BASED, SAVE_OIL_PRODUCT } from "../constant/CommonApiUrl"
import { API_SERVICE } from "../Services/CommonApi";
import { commonPayloadFields } from "../helper/storage";
import * as ACTION from '../constant/Actions'

const ADD_OIL_PRODUCT = 'oilProduct/addOilProductApi'
const UPDATE_OIL_PRODUCT = 'oilProduct/updateOilProductApi'
const DELETE_OIL_PRODUCT = 'oilProduct/deleteOilProductApi'
const FETCH_OIL_PRODUCT = 'oilProduct/fetchOilProductApi'
const FETCH_TYPE_BASED = 'oilProduct/GetTypeBasedOnType'

export const addOilProductApi = createAsyncThunk(ADD_OIL_PRODUCT,
    async (args, thunkAPI) => {
        try {
            const response = await API_SERVICE.post(SAVE_OIL_PRODUCT, args['data'], "CREATE");
            thunkAPI.dispatch(fetchOilProductApi())
            return response.data.result.data[0];
        } catch (err) {
            return thunkAPI.rejectWithValue(err.message)
        }
    })
export const fetchROBType = createAsyncThunk(FETCH_TYPE_BASED,
    async (args, thunkAPI) => {
        try {
            return await API_SERVICE.get(GET_TYPE_BASED, { type: args.type }, "")
        } catch (err) {
            return thunkAPI.rejectWithValue(err.message)
        }
    })

export const updateOilProductApi = createAsyncThunk(UPDATE_OIL_PRODUCT,
    async (args, thunkAPI) => {
        try {
            const response = await API_SERVICE.post(SAVE_OIL_PRODUCT, args['data'], "UPDATE");
            thunkAPI.dispatch(fetchOilProductApi())
            return response.data.result.data[0]
        } catch (err) {
            return thunkAPI.rejectWithValue(err.message)
        }
    })

export const deleteOilProductApi = createAsyncThunk(DELETE_OIL_PRODUCT,
    async (args, thunkAPI) => {
        try {
            const toDelete = thunkAPI.getState().root.oilProduct.list.find(ele => ele.id === args['id']);
            const delObj = { ...toDelete, isdisabled: "Y", ...commonPayloadFields }
            delete delObj.repository
            const response = await API_SERVICE.post(SAVE_OIL_PRODUCT, delObj);
            thunkAPI.dispatch(fetchOilProductApi())
            return response.data.result.data[0];
        } catch (err) {
            return thunkAPI.rejectWithValue(err.message)
        }
    }
)

export const fetchOilProductApi = createAsyncThunk(FETCH_OIL_PRODUCT,
    async (arg, thunkAPI) => {
        try {
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            const response = await API_SERVICE.get(arg ? `${GET_OIL_PRODUCT}?${arg}&vessel_id=${vessel_name === 'OFFICE' ? 0 : id}` : `${GET_OIL_PRODUCT}?vessel_id=${vessel_name === 'OFFICE' ? 0 : id}`);
            return response.data.result.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.message)
        }
    }
)
export const getConsumptionOilHead = createAsyncThunk(ACTION.CONSUMPTION_OIL_HEAD,
    async (arg, thunkAPI) => {
        try {
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            return await API_SERVICE.get(`${APIS.GET_OIL_PRODUCT}?${arg}&vessel_id=${id}`)
        } catch (err) {
            return thunkAPI.rejectWithValue(err)
        }

    })

const initialState = {
    list: [],
    editOilProduct: {},
    consumption_oil_head: {},
    isUpdated: true,
    typeBasedData: []
}
export const oilProductSlice = createSlice({
    name: 'oilProduct',
    initialState,
    reducers: {
        addOilProduct: (state, action) => {
            state.list = [...state.list, action.payload]
        },
        delOilProduct: (state, action) => {
            state.list = state.list.filter(x => x.id !== action.payload)
        },
        loadEditOilProduct: (state, action) => {
            state.editOilProduct = action.payload
        },
        clearEditOilProduct: (state, action) => {
            state.editOilProduct = {}
        },
        updateOilProduct: (state, action) => {
            let index = state.list.findIndex(x => x.id === action.payload.id)
            state.list[index] = action.payload
        },
        resetOilProduct: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(addOilProductApi.fulfilled, (state, action) => {
                if (action.payload.status === 'success') {
                    toast.success('Successfully added');
                } else
                    toast.error(action.payload.status);
            })
            .addCase(updateOilProductApi.fulfilled, (state, action) => {
                toast.success('Successfully updated');
            })
            .addCase(getConsumptionOilHead.fulfilled, (state, action) => {
                if (action.payload.data.result.status) {
                    state.consumption_oil_head = action.payload.data.result.data[0]
                } else toast.error(action.payload.status);
            })
            .addCase(fetchOilProductApi.fulfilled, (state, action) => {
                state.list = action.payload;
                state.isUpdated = false;
            }).addCase(fetchROBType.fulfilled, (state, { payload }) => {
                state.typeBasedData = payload.data.result.data
            })
            .addCase(deleteOilProductApi.fulfilled, (state, action) => {
                toast.success('Successfully deleted');
            })
    }
})
export const { addOilProduct, delOilProduct, loadEditOilProduct, clearEditOilProduct, updateOilProduct, resetOilProduct } = oilProductSlice.actions
export default oilProductSlice.reducer