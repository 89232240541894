import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import * as API from "../constant/CommonApiUrl"
import {GET_VESSEL, SAVE_VESSEL} from "../constant/CommonApiUrl"
import {API_SERVICE} from "../Services/CommonApi";
import {toast} from "react-toastify"
import {dateTimeToDate} from "../helper/dateUtil";
import * as ACTION from '../constant/Actions'
import {ValidateResponse} from "../Services/Support";
import {saveVesselType} from "./THUNK/MeetingThunk";

const ADD_VESSEL = 'vessel/addVesselApi'
const UPDATE_VESSEL = 'vessel/updateVesselApi'
const FETCH_VESSEL = 'vessel/fetchVesselApi'
const FETCH_VESSEL_BY_ID = 'vessel/fetchVesselApiById'
const DELETE_VESSEL = 'vessel/deleteVesselApi'

export const addVesselApi = createAsyncThunk(ADD_VESSEL,
    async (args, thunkAPI) => {
        try {
            /* response = response.data.result.data[0];
            response.vessel_created_at = dateTimeToDate(response.vessel_created_at);
            response.vessel_updated_at = dateTimeToDate(response.vessel_updated_at); */
            return await API_SERVICE.post(SAVE_VESSEL, args['data'], "CREATE");
        } catch (err) {
            return thunkAPI.rejectWithValue(err.message)
        }
    })

export const updateVesselApi = createAsyncThunk(UPDATE_VESSEL,
    async (args, thunkAPI) => {
        try {
            let response = await API_SERVICE.post(SAVE_VESSEL, args['data'], "UPDATE");
            response = response.data.result.data[0];
            response.vessel_created_at = dateTimeToDate(response.vessel_created_at);
            response.vessel_updated_at = dateTimeToDate(response.vessel_updated_at);
            return response;
        } catch (err) {
            return thunkAPI.rejectWithValue(err.message)
        }
    })

export const deleteVesselApi = createAsyncThunk(DELETE_VESSEL,
    async (args, thunkAPI) => {
        try {
            const toDelete = thunkAPI.getState().root.vessel.list.find(ele => ele.id === args['id']);
            const response = await API_SERVICE.post(SAVE_VESSEL, { ...toDelete, isdisabled: "Y" });
            return response.data.result.data[0];
        } catch (err) {
            return thunkAPI.rejectWithValue(err.message)
        }
    }
)

export const fetchVesselApi = createAsyncThunk(FETCH_VESSEL,
    async (arg, thunkAPI) => {
        try {
            let response = await API_SERVICE.get(GET_VESSEL, {...arg});
            response = response.data.result.data;
            response = response.map(ves => ({
                ...ves,
                vessel_created_at: dateTimeToDate(ves.vessel_created_at),
                vessel_updated_at: dateTimeToDate(ves.vessel_updated_at),
            }))
            return response
        } catch (err) {
            return thunkAPI.rejectWithValue(err.message)
        }
    }
)
export const fetchVesselById = createAsyncThunk(FETCH_VESSEL_BY_ID, async (arg, thunkAPI) => {
    try {
        let response = await API_SERVICE.get(GET_VESSEL, { ...arg });
        response = response.data.result.data;
        response = response.map(ves => ({
            ...ves,
            vessel_created_at: dateTimeToDate(ves.vessel_created_at),
            vessel_updated_at: dateTimeToDate(ves.vessel_updated_at),
        }))
        return response
    } catch (err) {
        return thunkAPI.rejectWithValue(err.message)
    }
}
)
export const getVesselTypeMaster = createAsyncThunk(ACTION.GET_VESSEL_TYPE,
    async (arg, thunkAPI) => {
        try {
            return await API_SERVICE.get(API.GET_VESSEL_TYPE);
        } catch (err) {
            return thunkAPI.rejectWithValue(err.message)
        }
    })
export const getVesselType = createAsyncThunk(ACTION.VESSEL_TYPE_LIST,
    async (arg, thunkAPI) => {
        try {
            const vessel_id = thunkAPI.getState().root.common.vessel_detailByID.id;
            return await API_SERVICE.get(API.VESSEL_TYPE_LIST, { ...arg, vessel_id });
        } catch (err) {
            return thunkAPI.rejectWithValue(err.message)
        }
    })

const initialState = {
    list: [],
    editVessel: {},
    vessel_type: [],
    vesselTypeList: [],
    editTypes: {},
    isUpdated: true
}
export const vesselSlice = createSlice({
    name: 'vessel',
    initialState,
    reducers: {
        addVessel: (state, action) => {
            state.list = [...state.list, action.payload]
        },
        delVessel: (state, action) => {
            state.list = state.list.filter(x => x.id !== action.payload)
        },
        loadEditVessel: (state, action) => {
            state.editVessel = action.payload
        },
        clearEditVessel: (state, action) => {
            state.editVessel = {}
            state.editTypes = {}
        },
        updateVessel: (state, action) => {
            let index = state.list.findIndex(x => x.id === action.payload.id)
            state.list[index] = action.payload
        },
        setEditVesselType: (state, { payload }) => {
            state.editTypes = payload;
        },
        resetVessel: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(addVesselApi.fulfilled, (state, action) => {
                //state.list = [...state.list, action.payload];
                ValidateResponse(action.payload)
                //toast.success('Successfully added');
            })
            .addCase(saveVesselType.fulfilled, (state, action) => {
                //state.list = [...state.list, action.payload];
                ValidateResponse(action.payload)
                //toast.success('Successfully added');
            })           
            .addCase(getVesselTypeMaster.fulfilled, (state, action) => {
                state.vessel_type = action.payload.data.result.data;
            }).addCase(getVesselType.fulfilled, (state, action) => {
                state.vesselTypeList = action.payload.data.result.data;
            })
            .addCase(updateVesselApi.fulfilled, (state, action) => {
                toast.success('Successfully updated');
            })
            .addCase(fetchVesselApi.fulfilled, (state, action) => {
                state.list = action.payload;
                state.isUpdated = false;
            }).addCase(fetchVesselById.fulfilled, (state, action) => {
                state.editVessel = action.payload[0];
            })
            .addCase(deleteVesselApi.fulfilled, (state, action) => {
                toast.success('Successfully deleted');
            })
    }
})
export const { addVessel, delVessel, loadEditVessel,
    clearEditVessel, updateVessel, resetVessel, setEditVesselType } = vesselSlice.actions
export default vesselSlice.reducer