import { createAsyncThunk } from "@reduxjs/toolkit";
import * as ACTION from "../../constant/Actions"
import { API_SERVICE } from "../../Services/CommonApi"
import * as APIS from "../../constant/CommonApiUrl"
import { getVesselType } from "../vesselSlice";


export const ApiCallWithVesselId = (apiEndpoint, arg, thunkAPI, method) => {
    const { id: vessel_id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID;
    return API_SERVICE[method](apiEndpoint, { ...arg, vessel_id:vessel_name==='OFFICE'?0:vessel_id });
};
export const getMeetingNameMasterList = createAsyncThunk(ACTION.MEETING_NAME_MASTER_ACTION,
    async (arg, thunkAPI) => {
        try {
            return await ApiCallWithVesselId(APIS.MEETING_NAME_MASTER_LIST, arg, thunkAPI, 'get');
        } catch (err) {
            thunkAPI.rejectWithValue(err.message)
        }
    })
export const getMeetingNameById = createAsyncThunk(ACTION.MEETING_NAME_BY_ID_ACTION,
    async (arg, thunkAPI) => {
        try {
            return await ApiCallWithVesselId(APIS.MEETING_NAME_MASTER_LIST, arg, thunkAPI, 'get');
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const GetMeetingRecord = createAsyncThunk(ACTION.GET_MEETING_RECORD_ACTION,
    async (arg, thunkAPI) => {
        try {
            return await ApiCallWithVesselId(APIS.GET_MEETING_RECORD, arg, thunkAPI, 'get');
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const GetMeetingRecordById = createAsyncThunk(ACTION.GET_MEETING_RECORD_ID_ACTION,
    async (arg, thunkAPI) => {
        try {
            return await ApiCallWithVesselId(APIS.GET_MEETING_RECORD, arg, thunkAPI, 'get');
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const saveMeetingName = createAsyncThunk(ACTION.SAVE_MEETING_NAME_ACTION,
    async (arg, thunkAPI) => {
        try {
            return await ApiCallWithVesselId(APIS.SAVE_MEETING_NAME, arg, thunkAPI, 'post');
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const saveMeetingRecord = createAsyncThunk(ACTION.SAVE_MEETING_RECORD_ACTION,
    async (arg, thunkAPI) => {
        try {
            return await ApiCallWithVesselId(APIS.SAVE_MEETING_RECORD, arg, thunkAPI, 'post');
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getDrillNameMasterList = createAsyncThunk(ACTION.DRILL_NAME_MASTER_ACTION,
    async (arg, thunkAPI) => {
        try {
            return await ApiCallWithVesselId(APIS.DRILL_NAME_MASTER_LIST, arg, thunkAPI, 'get');
        } catch (err) {
            thunkAPI.rejectWithValue(err.message)
        }
    })
export const getDrillNameById = createAsyncThunk(ACTION.DRILL_NAME_BY_ID_ACTION,
    async (arg, thunkAPI) => {
        try {
            return await ApiCallWithVesselId(APIS.DRILL_NAME_MASTER_LIST, arg, thunkAPI, 'get');
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const GetDrillRecord = createAsyncThunk(ACTION.GET_DRILL_RECORD_ACTION,
    async (arg, thunkAPI) => {
        try {
            return await ApiCallWithVesselId(APIS.GET_DRILL_RECORD, arg, thunkAPI, 'get');
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
    export const GetAgendaMeetingByID = createAsyncThunk('GetAgendaMeetingByID',
        async (arg, thunkAPI) => {
            try {
                return await ApiCallWithVesselId(APIS.GET_AGENDA_MEETING_BY_ID, arg, thunkAPI, 'get');
            } catch (err) {
                thunkAPI.rejectWithValue(err)
            }
        });

export const saveDrillRecord = createAsyncThunk(ACTION.SAVE_DRILL_RECORD_ACTION,
    async (arg, thunkAPI) => {
        try {
            return await ApiCallWithVesselId(APIS.SAVE_DRILL_RECORD, arg, thunkAPI, 'post');
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const onGetDrillRecord = createAsyncThunk('GET_DRILL_RECORD_BY_NAME',
    async (arg, thunkAPI) => {
        try {
            return await ApiCallWithVesselId(APIS.GET_DRILL_RECORD_BY_NAME, arg, thunkAPI, 'get');
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })

    export const onGetMeetingRecord = createAsyncThunk('GET_MEETING_RECORD_BY_NAME',
        async (arg, thunkAPI) => {
            try {
                return await ApiCallWithVesselId(APIS.GET_MEETING_RECORD_BY_NAME, arg, thunkAPI, 'get');
            } catch (err) {
                thunkAPI.rejectWithValue(err)
            }
        })
export const GetDrillRecordById = createAsyncThunk(ACTION.GET_DRILL_RECORD_ID_ACTION,
    async (arg, thunkAPI) => {
        try {
            return await ApiCallWithVesselId(APIS.GET_DRILL_RECORD, arg, thunkAPI, 'get');
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const saveDrillName = createAsyncThunk(ACTION.SAVE_DRILL_NAME_ACTION,
    async (arg, thunkAPI) => {
        try {
            return await ApiCallWithVesselId(APIS.SAVE_DRILL_NAME, arg, thunkAPI, 'post');
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getDrillScheduledList = createAsyncThunk(ACTION.DRILL_SCHEDULE_ACTION,
    async (arg, thunkAPI) => {
        try {
            return await ApiCallWithVesselId(APIS.DRILL_SCHEDULE_MASTER_LIST, arg, thunkAPI, 'get');
        } catch (err) {
            thunkAPI.rejectWithValue(err.message)
        }
    })
export const getDrillScheduledById = createAsyncThunk(ACTION.DRILL_SCHEDULE_BY_ID_ACTION,
    async (arg, thunkAPI) => {
        try {
            return await ApiCallWithVesselId(APIS.DRILL_SCHEDULE_MASTER_LIST, arg, thunkAPI, 'get');
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const saveDrillScheduled = createAsyncThunk(ACTION.SAVE_DRILL_SCHEDULE_ACTION,
    async (arg, thunkAPI) => {
        try {
            return await ApiCallWithVesselId(APIS.SAVE_DRILL_SCHEDULE, arg, thunkAPI, 'post');
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getMeetingSCheduledList = createAsyncThunk(ACTION.MEETING_SCHEDULE_ACTION,
    async (arg, thunkAPI) => {
        try {
            return await ApiCallWithVesselId(APIS.MEETING_SCEHDULE_LIST, arg, thunkAPI, 'get');
        } catch (err) {
            thunkAPI.rejectWithValue(err.message)
        }
    })
export const getMeetingScheduledById = createAsyncThunk(ACTION.MEETING_SCHEDULE_BY_ID_ACTION,
    async (arg, thunkAPI) => {
        try {
            return await ApiCallWithVesselId(APIS.MEETING_SCEHDULE_LIST, arg, thunkAPI, 'get');
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const saveMeetingScheduled = createAsyncThunk(ACTION.SAVE_MEETING_SCHEDULE_ACTION,
    async (arg, thunkAPI) => {
        try {
            return await ApiCallWithVesselId(APIS.SAVE_MEETING_SCHEDULE, arg, thunkAPI, 'post');
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getMeetingAgendaMasterList = createAsyncThunk(ACTION.MEETING_AGENDA_MASTER_ACTION,
    async (arg, thunkAPI) => {
        try {
            return await ApiCallWithVesselId(APIS.MEETING_AGENDA_MASTER_LIST, arg, thunkAPI, 'get');
        } catch (err) {
            thunkAPI.rejectWithValue(err.message)
        }
    })
export const getMeetingAgendaMasterById = createAsyncThunk(ACTION.MEETING_AGENDA_BY_ID_ACTION,
    async (arg, thunkAPI) => {
        try {
            return await ApiCallWithVesselId(APIS.MEETING_AGENDA_MASTER_LIST, arg, thunkAPI, 'get');
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const saveMeetingAgendaMaster = createAsyncThunk(ACTION.SAVE_MEETING_AGENDA_ACTION,
    async (arg, thunkAPI) => {
        try {
            return await ApiCallWithVesselId(APIS.SAVE_MEETING_AGENDA, arg, thunkAPI, 'post');
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })

export const saveVesselType = createAsyncThunk(ACTION.SAVE_VESSEL_TYPE_ACTION,
    async (arg, thunkAPI) => {
        try {
            const response = await ApiCallWithVesselId(APIS.SAVE_VESSEL_TYPE, arg, thunkAPI, 'post');
            thunkAPI.dispatch(getVesselType())
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getVesselTypeList = createAsyncThunk(ACTION.VESSEL_TYPE_LIST_ACTION,
    async (arg, thunkAPI) => {
        try {
            return await ApiCallWithVesselId(APIS.VESSEL_TYPE_LIST, arg, thunkAPI, 'get');
        } catch (err) {
            thunkAPI.rejectWithValue(err.message)
        }
    })