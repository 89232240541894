import { createAsyncThunk } from "@reduxjs/toolkit";
import * as ACTION from "../../constant/Actions"
import * as APIS from "../../constant/CommonApiUrl";
import { API_SERVICE } from "../../Services/CommonApi";
import axios from "axios";
export const getPMSMaintenanceReport = createAsyncThunk(ACTION.PMS_MAINTENANCE_REPORT,
    async (arg, thunkAPI) => {
        try {
            let response = await API_SERVICE.post(`${APIS.PMS_MAINTENANCE_REPORT}`, arg)
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getMaintenanceReportId = createAsyncThunk(ACTION.PMS_MAINTENANCE_REPORT_ID,
    async (arg, thunkAPI) => {
        try {
            let response = await API_SERVICE.get(`${APIS.PMS_MAINTENANCE_REPORT_ID}?id=${arg}`)
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getMaintenanceReportHead = createAsyncThunk('MAINTENANCE_REPORT/head',
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            let response = await API_SERVICE.get(`${APIS.PMS_MAIN_HEAD}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getMaintenanceReportType = createAsyncThunk('MAINTENANCE_REPORT/type',
    async (arg, thunkAPI) => {
        try {
            let response = await API_SERVICE.get(`${APIS.PMS_FREQ_TYPE}`)
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getPMSCertificateReport = createAsyncThunk(ACTION.PMS_CERTIFICATE_REPORT,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(`${APIS.PMS_CERTIFICATE_REPORT}?${arg}&vessel_id=${id}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getCertificateReportGroup = createAsyncThunk(ACTION.PMS_CERTIFICATE_GROUP,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(`${APIS.PMS_CERTIFICATE_GROUP}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getCertificateReportId = createAsyncThunk(ACTION.PMS_CERTIFICATE_REPORT_ID,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(`${APIS.PMS_CERTIFICATE_REPORT_ID}?id=${arg}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })