import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import {toast} from "react-toastify"
import {API_SERVICE} from "../Services/CommonApi";
import {
    EDOC_RECENT_FOLDER, GET_EDOC_BY_ID, GET_EdocumentByID,
    GET_EQUIP_byPARTSdetail,
    GET_EQUIPMENT, GET_INVENTORY_BY_ID,
    GET_INVENTORY_BY_TYPE,
    GET_INVENTORY_TREE, SAVE_DOCUMENT,
    SAVE_EQUIPMENT,
    SAVE_INVENTORY_TREE, SEARCH_DOCUMENT
} from "../constant/CommonApiUrl"
import {GET_EQUIP_byPART_ACTION, SAVE_DOCUMENT_ACTION, SAVE_INVENTORY_TREE_ACTION} from "../constant/Actions";
import { parseFlatArray2Tree } from "../helper/treeUtil";
import {ConvertJson} from "../helper";

const ADD_EQUIPMENT = 'equipment/addEquipmentApi'
const UPDATE_EQUIPMENT = 'equipment/updateEquipmentApi'
const FETCH_EQUIPMENT = 'equipment/fetchEquipmentApi'
const DELETE_EQUIPMENT = 'equipment/deleteEquipmentApi'
const GET_INVENTORY_TREE_ACTION='equipment/fetchTreeView'
const GET_FOLDER_TREE_ACTION='equipment/folderView'
const GET_EDOC_BY_ID_ACTION='equipment/singleEdoc'
const GET_DOCUMENT_ACTION='equipment/documentView'
const GET_DOCUMENT_SEARCH='equipment/documentSearch'
const GET_DOCUMENT_BY_ID ='equipment/getDocumentByID'
const GET_EDOC_RECENT_FOLDER='equipment/recentFolder'

export const addEquipmentApi =createAsyncThunk(ADD_EQUIPMENT,
    async(args, thunkAPI)=>{
        try{
            let response = await API_SERVICE.post(SAVE_EQUIPMENT, args['data'], "CREATE");
            response = response.data.result.data[0];
            thunkAPI.dispatch(fetchEquipmentApi())
            return response;
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
})

export const updateEquipmentApi = createAsyncThunk(UPDATE_EQUIPMENT,
    async(args, thunkAPI)=>{
        try{
            let response = await API_SERVICE.post(SAVE_EQUIPMENT, args['data'], "UPDATE");
            response = response.data.result.data[0]
            thunkAPI.dispatch(fetchEquipmentApi())
            return response;
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
})

export const deleteEquipmentApi = createAsyncThunk(DELETE_EQUIPMENT, 
    async (args, thunkAPI) => {
        try{
            const toDelete = thunkAPI.getState().root.equipment.list.find(ele => ele.id === args['id']);   
            let response = await API_SERVICE.post(SAVE_EQUIPMENT, {...toDelete, isdisabled: "Y"});
            response = response.data.result.data[0]
            thunkAPI.dispatch(fetchEquipmentApi())
            return response;
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
    }
)

export const fetchEquipmentApi = createAsyncThunk(FETCH_EQUIPMENT,
    async(arg,thunkAPI)=>{
        try{
            let response = await API_SERVICE.get(GET_EQUIPMENT);
            response = response.data.result.data;
            return response
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
    }
)
export const getEquipmentByPart=createAsyncThunk(GET_EQUIP_byPART_ACTION,
    async(arg,thunkAPI)=>{
        try{
            const {id,vessel_name}=thunkAPI.getState().root.common.vessel_detailByID
            return await API_SERVICE.get(`${GET_EQUIP_byPARTSdetail}?${arg}&vessel_id=${vessel_name === 'OFFICE' ? 0 : id}`)
        }catch(err){
            thunkAPI.rejectWithValue(err.message)
        }
    })
export const saveInventoryTree = createAsyncThunk(SAVE_INVENTORY_TREE_ACTION,
    async(arg,thunkAPI)=>{
        try{
            let vessel_id=thunkAPI.getState().root.common.vessel_detailByID.id
            arg={...arg,vessel_id:vessel_id}            
            const result =  await API_SERVICE.post(`${SAVE_INVENTORY_TREE}`, arg)            
            arg.type==='e-doc'&&thunkAPI.dispatch(getFolderById({type: 'E-DOC'}))
            return result
        }catch(err){
            thunkAPI.rejectWithValue(err.message)
        }
    })
export const saveDocument = createAsyncThunk(SAVE_DOCUMENT_ACTION,
    async(arg,thunkAPI)=>{
        try{
            const result =  await API_SERVICE.post(`${SAVE_DOCUMENT}`, arg)
            thunkAPI.dispatch(getDocumentById({id: arg.inventory_id}))
            return result
        }catch(err){
            thunkAPI.rejectWithValue(err.message)
        }
    })
export const getInventoryTree=createAsyncThunk(GET_INVENTORY_TREE_ACTION,
    async(arg,thunkAPI)=>{
        try{
            let vessel_id=thunkAPI.getState().root.common.vessel_detailByID.id
            return await API_SERVICE.get(`${GET_INVENTORY_TREE}?vessel_id=${vessel_id}`);
        }catch(err){
            thunkAPI.rejectWithValue(err.message)
        }
    })
export const getFolderById = createAsyncThunk(GET_FOLDER_TREE_ACTION,
    async(arg,thunkAPI)=>{
        try{
            let vessel_id=thunkAPI.getState().root.common.vessel_detailByID.id
            return await API_SERVICE.get(`${GET_INVENTORY_BY_TYPE}?vessel_id=${vessel_id}&type=${arg.type}&user_id=${arg.user_id}`);
        }catch(err){
            thunkAPI.rejectWithValue(err.message)
        }
    })
export const getEDocById = createAsyncThunk(GET_EDOC_BY_ID_ACTION,
    async(arg,thunkAPI)=>{
        try{
            let vessel_id = thunkAPI.getState().root.common.vessel_detailByID.id
            const obj = {vessel_id: vessel_id, type: arg.type, id: arg.id}
            return await API_SERVICE.get(`${GET_EDOC_BY_ID}`, obj);
        }catch(err){
            thunkAPI.rejectWithValue(err.message)
        }
    })
export const getRecentFolders = createAsyncThunk(GET_EDOC_RECENT_FOLDER,
    async(arg,thunkAPI)=>{
        try{
            let vessel_id=thunkAPI.getState().root.common.vessel_detailByID.id
            return await API_SERVICE.get(`${EDOC_RECENT_FOLDER}?vessel_id=${vessel_id}&type=${arg.type}&user_id=${arg.user_id}`);
        }catch(err){
            thunkAPI.rejectWithValue(err.message)
        }
    })
export const getDocumentById = createAsyncThunk(GET_DOCUMENT_ACTION,
    async(arg,thunkAPI)=>{
        try{
            let vessel_id=thunkAPI.getState().root.common.vessel_detailByID.id
            return await API_SERVICE.get(`${GET_INVENTORY_BY_ID}?vessel_id=${vessel_id}&id=${arg.id}`);
        }catch(err){
            thunkAPI.rejectWithValue(err.message)
        }
    })
export const getDocumentBySearch = createAsyncThunk(GET_DOCUMENT_SEARCH,
    async(arg,thunkAPI)=>{
        try{
            let vessel_id=thunkAPI.getState().root.common.vessel_detailByID.id
            return await API_SERVICE.get(`${SEARCH_DOCUMENT}?${arg}&vessel_id=${vessel_id}`);
        }catch(err){
            thunkAPI.rejectWithValue(err.message)
        }
    })
export const getEDocumentById = createAsyncThunk(GET_DOCUMENT_BY_ID,
    async(arg,thunkAPI)=>{
        try{
            let vessel_id = thunkAPI.getState().root.common.vessel_detailByID.id
            return await API_SERVICE.get(`${GET_EdocumentByID}?vessel_id=${vessel_id}&id=${arg.id}`);
        }catch(err){
            thunkAPI.rejectWithValue(err.message)
        }
    })

const initialState={
    list:[],
    editEquipment:{},   
    isUpdated:true,
    equipmentDetail:{
        equipmentDataModel:[],
        partDataModel:[]
    },
    gData:[],
    folderList:[],
    recentFolder:[],
    folderData: {},
    documentList: [],
    selectedDocument: {},
    isTreeUpdate:false,
    eDocDetails: {}
}

export const equipmentSlice=createSlice({
    name:'equipment',
    initialState,
    reducers:{
        addEquipment:(state,action)=>{
            state.list=[...state.list,action.payload]
        },
        delEquipment:(state,action)=>{
            state.list=state.list.filter(x=>x.id!==action.payload)
        },
        loadEditEquipment:(state,action)=>{
            state.editEquipment=action.payload
        },
        clearEditEquipment:(state,action)=>{
            state.editEquipment={}
            state.equipmentDetail={}
        },
        updateEquipment:(state,action)=>{
            let index=state.list.findIndex(x=>x.id===action.payload.id)
            state.list[index]=action.payload
        },
        setFolder:(state,action)=>{
            state.folderData=action.payload
        },
        setSelectedDocument:(state, {payload})=>{
            state.selectedDocument= { ...payload,
                link_checklist: payload.link_checklist ? ConvertJson(payload.link_checklist) : [],
                vessel_list: payload.vessel_list ? ConvertJson(payload.vessel_list) : [],
                viewuser_list: payload.viewuser_list ? ConvertJson(payload.viewuser_list) : [],
                designationid: payload?.designationid ? payload.designationid : [],
                checkoutRepository: payload?.checkoutRepository && payload?.checkoutRepository.length > 0 ? ConvertJson(payload.checkoutRepository) : [],
            }
        },
        resetListData: (state, {payload}) => {
            state.documentList = []
        },
        resetEquipment:()=>initialState
    },
    extraReducers:(builder)=>{
        builder
        .addCase(addEquipmentApi.fulfilled,(state,action)=>{
            toast.success('Successfully added');
        })
        .addCase(updateEquipmentApi.fulfilled,(state,action)=>{
            toast.success('Successfully updated');
        })
        .addCase(fetchEquipmentApi.fulfilled, (state, action) => {
            state.list = action.payload;
            state.isUpdated = false;
        }).addCase(getEDocumentById.fulfilled, (state, {payload}) => {
            if (payload.data.result.data.length > 0) {
                const data = payload.data.result.data[0];
                state.selectedDocument= { ...data,
                    link_checklist: data?.link_checklist ? ConvertJson(data.link_checklist) : [],
                    vessel_list: data?.vessel_list ? ConvertJson(data.vessel_list) : [],
                    viewuser_list: data?.viewuser_list ? ConvertJson(data.viewuser_list) : [],
                    designationid: data?.designationid ? ConvertJson(data.designationid) : [],
                    repository: data?.repository ? ConvertJson(data.repository) : [],
                }
            }
        })
        .addCase(getInventoryTree.fulfilled, (state, action) => {
            if(action.payload.data.result.status){
                state.gData=parseFlatArray2Tree(action.payload.data.result.data)
            }else{
                toast.error(action.payload.data.result.message)
            }            
        }).addCase(getFolderById.fulfilled, (state, action) => {
            console.log(action.payload)
            if(action.payload.data.result.status){
                state.folderList = action.payload.data.result.data
            }else{
                toast.error(action.payload.data.result.message)
            }
        }).addCase(getRecentFolders.fulfilled, (state, action) => {
            if(action.payload.data.result.status){
                state.recentFolder = action.payload.data.result.data
            }else{
                toast.error(action.payload.data.result.message)
            }
        }).addCase(getEDocById.fulfilled, (state, action) => {
            if(action.payload.data.result.status){
                state.eDocDetails = action.payload.data.result.data[0]
            }else{
                toast.error(action.payload.data.result.message)
            }
        }).addCase(getDocumentById.fulfilled, (state, {payload}) => {
            if(payload.data.result.status){
                const data = payload.data.result.data
                let result = data.map((doc) => ({
                    ...doc,
                    checkoutRepository: doc?.initiaterequest_repository ? ConvertJson(doc.initiaterequest_repository) : [],
                    checkinRepository: doc?.checkin_repository ? ConvertJson(doc.checkin_repository) : [],
                    repository: doc.repository ? ConvertJson(doc.repository) : [],
                    designationid: doc.designationid ? ConvertJson(doc.designationid) : [],
                }))
                state.documentList = result
            }else{
                toast.error(payload.data.result.message)
            }
        }).addCase(getDocumentBySearch.fulfilled, (state, {payload}) => {
            if(payload.data.result.status){
                const data = payload.data.result.data
                let result = data.map((doc) => ({
                    ...doc,
                    repository: doc.repository ? ConvertJson(doc.repository) : [],
                    designationid: doc.designationid ? ConvertJson(doc.designationid) : [],
                }))
                state.documentList = result
            }else{
                toast.error(payload.data.result.message)
            }
        }).addCase(saveDocument.fulfilled, (state, action) => {
            if(action.payload.data.result.status){
                toast.success('Successfully added');
            }else{
                toast.error(action.payload.data.result.message)
            }
        })
        .addCase(deleteEquipmentApi.fulfilled, (state, action) => {
            toast.success('Successfully deleted');
        })
        .addCase(getEquipmentByPart.fulfilled, (state, action) => {
            if(action.payload.data.result.status){
                state.equipmentDetail=action.payload.data.result.data
            }else{
                toast.error(action.payload.data.result.message)
            }
        })
        .addCase(saveInventoryTree.fulfilled, (state, action) => {
            if(action.payload.data.result.status){
                if(action.payload.data.result.status){
                    toast.success(action.payload.data.result.message)   
                    state.isTreeUpdate=!state.isTreeUpdate                  
                }else{
                    toast.error(action.payload.data.result.message)                    
                }             
            }else{
                toast.error(action.payload.data.result.message)
            }
        })
    } 
})
export const {addEquipment,delEquipment,loadEditEquipment,clearEditEquipment,
    updateEquipment,resetEquipment, setFolder, setSelectedDocument, resetListData}=equipmentSlice.actions
export default equipmentSlice.reducer