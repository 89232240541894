import React, { useRef, useState } from 'react';
import { AreaChart, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Area } from 'recharts';
import { Col, Form, Row, Button } from "react-bootstrap";
// import { AnalyticsList } from "../STYLE/PMSStyle";
import ReactDatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { useEffect } from 'react';
import { getPMSanalysisVessel } from '../../../features/THUNK/pmsAnalysisThunk';
import { removeSearchParams } from '../../../helper/Support';
import { MonthlyCompletedSum, constructWaveResponse, getMissingMonthData } from './Support';
import { ConvertJson } from '../../../helper';
import VesselTableData from './VesselTableData';
import { toast } from 'react-toastify';
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import { getAllData } from '../../../features/globalSlice';
import { TYPE_BASED_ON_TYPE } from '../../../constant/CommonApiUrl';

function Charts(props) {
    const printRef = useRef();
    const dispatch = useDispatch()
    const [year, setYear] = useState(new Date())
    const [vessel, setVessel] = useState([])
    const [waveData, setWaveData] = useState([])
    const [pdfenable, setPdfEnable] = useState(false)
    const [activity, setActivity] = useState("")
    const vesselList = useSelector((state) => state.root.login.loginDemo?.vesselDetails) || []
    const vessel_list = vesselList.map(el => Object.assign({}, { label: el.vessel_name, value: Number(el.id) }))
    // const vessel_list = useSelector((state) => state.root.vessel.list.map(el => Object.assign({}, { label: el.vessel_name, value: el.id })))
    const { vessel_chart, department_wise_data, department_wise_data1, department_overdue_data, multiple_vessel } = useSelector(state => state.root.ANALYSIS)
    const { id: vessel_id, vessel_name } = useSelector((state) => state.root.common.vessel_detailByID);
    const activityOption = useSelector((state) => state.root.GLOBAL?.activityOption?.map(el => <option key={el.id} value={el.typecode}>{el.typename}</option>))
    useEffect(() => {
        dispatch(getAllData({ activityOption: `${TYPE_BASED_ON_TYPE}PMS_ACTIVITY` }))
        fetchData({ vessel_id })
    }, [vessel_id])

    const fetchData = (arg) => {
        setVessel(vessel_name === 'OFFICE' ? [] : vessel_list.filter(el => el.value === Number(arg.vessel_id)))
        dispatch(getPMSanalysisVessel(removeSearchParams({ year: year.getFullYear(), activity, ...arg }))).unwrap().then(res => {
            let d = ConvertJson(res.data.result.data[0].multiple_vessel)
            let a = vessel.map(el => el.label)
            let b = d.filter(el => el.vessel_name === a[0])[0]
            //console.log(ConvertJson(res.data.result.data[0].multiple_vessel), 'res')
            d.length === 2 ? setWaveData(constructWaveResponse(a, b, d)) : setWaveData(d[0]?.vessel_data.map(e => ({ ...e, month_text: e.job_month_text.substring(0, 3).toUpperCase() })))
        })
    }
    const tblContent = department_wise_data.map((el, i) => <tr key={i}>
        <td>{el.department_name}</td>
        {getMissingMonthData(el.department_data).map((el, j) => <>
            <td>{el.completed}</td>
        </>)}
    </tr>)
    const tblContent_overdue = department_overdue_data.map((el, i) => <tr key={i}>
        <td>{el.department_name}</td>
        {getMissingMonthData(el.department_data).map((el, j) => <>
            <td>{el.completed}</td>
        </>)}
    </tr>)
    const onSearch = () => {
        if (vessel.length > 0) {
            let vessel_toString = vessel.map(el => el.value).toString()
            fetchData({ vessel_id: vessel_toString })
        } else {
            toast.error('please select vessel name')
        }
    }
    const handleWave = (selected) => {
        setVessel(selected)
        if (selected.length > 0) {
            let vessel_toString = selected.map(el => el.value).toString()
            dispatch(getPMSanalysisVessel(removeSearchParams({ year: year.getFullYear(), activity, vessel_id: vessel_toString }))).unwrap().then(res => {
                let d = ConvertJson(res.data.result.data[0].multiple_vessel)
                let a = selected.map(el => el.label)
                let b = d.filter(el => el.vessel_name === a[0])[0]
                //console.log(ConvertJson(res.data.result.data[0].multiple_vessel), 'res')
                d.length === 2 ? setWaveData(constructWaveResponse(a, b, d)) : setWaveData(d[0]?.vessel_data)
            })
        }
    }
    const handleExport = () => setPdfEnable(!pdfenable)
    const exportPdf = () => {
        handleExport()
        handleExportPdf()
    }
    const handleExportPdf = async () => {
        const element = printRef.current;
        const canvas = await html2canvas(element);
        const data = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const imgProperties = pdf.getImageProperties(data);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
        const pageHeight = 295
        let heightLeft = pdfHeight
        let position = 0
        //pdf.addImage(data, 'PNG', 0, 0, 211, 250);
        pdf.addImage(data, 'PNG', 0, position, pdfWidth, pdfHeight);
        heightLeft -= pageHeight;
        while (heightLeft >= 0) {
            position += heightLeft - pdfHeight; // top padding for other pages
            pdf.addPage();
            pdf.addImage(data, 'PNG', 0, position, pdfWidth, pdfHeight);
            heightLeft -= pageHeight;
        }
        pdf.save('PMS_analysis.pdf');
        handleExport()
    }
    return (
        <>
            <Row ref={printRef}>
                <Col className={'mb-3 mt-3'} sm={12}>
                    <div className={'d-flex flex-row justify-content-between align-items-center bg-white p-3 col-md-12'}>
                        <span style={{ color: '#1A2261', fontWeight: '800' }} className={'me-2'}>PMS&nbsp;Analysis&nbsp;Report</span>
                        {/* <div style={{ fontSize: '16px', fontWeight: 500 }} className={'me-2'}>PMS&nbsp;Analysis&nbsp;Report</div> */}
                        <Row>
                            <Col sm={3}>
                                <Select
                                    isMulti
                                    options={vessel_list}
                                    value={vessel}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    isDisabled={vessel_name !== "OFFICE"}
                                    onChange={(selected) => { selected.length <= 2 ? handleWave(selected) : toast.error('You can only select up to two vessels.') }}
                                />
                            </Col>

                            <Col sm={3}>
                                <Form.Select aria-label="Default select example" style={{ width: '150px' }} onChange={(e) => setActivity(e.target.value)}>
                                    <option value={''}>Activity</option>
                                    {activityOption}

                                </Form.Select>
                            </Col>
                            <Col sm={3}>
                                <ReactDatePicker
                                    className='form-control'
                                    selected={year}
                                    onChange={(date) => {
                                        setYear(date)
                                    }}
                                    showYearPicker
                                    dateFormat="yyyy"
                                />
                            </Col>
                            <Col sm={2}>
                                <Button onClick={() => onSearch()}>Search</Button>{' '}
                            </Col>

                        </Row>
                        <div>
                            <Button onClick={() => { exportPdf() }}>Export&nbsp;Chart</Button>{' '}
                        </div>
                    </div>
                    <div className={'d-flex justify-content-between mb-2'} style={{ width: '100%', height: '300px' }}>
                        <ResponsiveContainer width={'100%'} height="100%" className={'analysisChart'}>
                            <AreaChart
                                // width={'100%'} height={250} 
                                data={waveData}
                                margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                                <defs>
                                    <linearGradient id="colorUv1" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#A6DFF8" stopOpacity={0.8} />
                                        <stop offset="95%" stopColor="#B4E6FC" stopOpacity={0} />
                                    </linearGradient>
                                    <linearGradient id="colorUv2" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#A6DFF8" stopOpacity={0.8} />
                                        <stop offset="95%" stopColor="#B4E6FC" stopOpacity={0} />
                                    </linearGradient>
                                </defs>
                                <XAxis dataKey="month_text" />
                                <YAxis />
                                <CartesianGrid strokeDasharray="3 3" horizontal={false} vertical={false} />
                                {/* <Tooltip /> */}
                                {/*<Area type="monotone" dataKey="uv" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />*/}
                                {/* {vessel.map((_,i)=>{
                                    return <Area type="monotone" dataKey={`completed${i}`} stroke="#3AB6E5" strokeWidth={5} fillOpacity={1} fill={`url(#colorUv)${i}`} dot={false} />
                                })} */}
                                <Area type="monotone" dataKey="completed" stroke="#3AB6E5" strokeWidth={2} fillOpacity={1} fill="url(#colorUv1)" dot={false} />
                                <Area type="monotone" dataKey="completed1" stroke="#FFC400" strokeWidth={2} fillOpacity={1} fill="url(#colorUv2)" dot={false} />
                                {/* <Area type="monotone" dataKey="total" stroke="#fafafa" fillOpacity={1} fill="url(#colorUv)" dot={false} /> */}
                            </AreaChart>
                        </ResponsiveContainer>
                    </div>
                </Col>
                {/* <div className='mt-5'>
                    <AnalyticsList>
                        <div className={'d-flex justify-content-between'}>
                            <div className={'box me-2'}><div className={'icons sky-blue'}></div><span className={'text'}>{department_wise_data[0]?.department_data[0]?.vessel_name}</span></div>
                        </div>
                    </AnalyticsList>
                </div> */}

                {/* <Table >
                    <thead>
                        <th style={{ color: '#3486D1' }}>Department Name</th>
                        <th>JAN</th>
                        <th>FEB</th>
                        <th>MAR</th>
                        <th>APR</th>
                        <th>MAY</th>
                        <th>JUN</th>
                        <th>JUL</th>
                        <th>AUG</th>
                        <th>SEP</th>
                        <th>OCT</th>
                        <th>NOV</th>
                        <th>DEC</th>
                    </thead>
                    <tbody>
                        {{tblContent}}
                        {vessel_chart.length > 0 && <tr>
                            <td>Total</td>
                            {
                                vessel_chart.map((el, i) => <td key={i}>{`${el.completed}(${el.percentage})`}</td>)
                            }
                        </tr>}
                    </tbody>
                </Table> */}
                {/* <Table className={'mt-5'}>
                    <thead>
                        <th style={{ color: '#3486D1' }}>Overdue Jobs</th>
                        <th>JAN</th>
                        <th>FEB</th>
                        <th>MAR</th>
                        <th>APR</th>
                        <th>MAY</th>
                        <th>JUN</th>
                        <th>JUL</th>
                        <th>AUG</th>
                        <th>SEP</th>
                        <th>OCT</th>
                        <th>NOV</th>
                        <th>DEC</th>
                    </thead>
                    <tbody>
                        {tblContent_overdue}
                        {vessel_chart.length > 0 && <tr>
                            <td>Total</td>
                            {
                                vessel_chart.map((el, i) => <td key={i}>{`${el.completed}(${el.percentage})`}</td>)
                            }
                        </tr>}
                    </tbody>
                </Table> */}
                {/* <Col sm={12} className={'mb-5'}>
                    <div className={'d-flex justify-content-between'}>
                        <AnalyticsList>
                            <div className={'d-flex justify-content-between'}>
                                <div className={'box me-2'}><div className={'icons sky-blue'}></div><span className={'text'}>MERMAID SAPPHIRE</span></div>
                                <div className={'box me-2'}><div className={'icons orange'}></div><span className={'text'}>IO3</span></div>
                            </div>
                        </AnalyticsList>
                    </div>
                    <div className={'d-flex justify-content-between mb-2'} style={{ height: '300px' }}>
                        <ResponsiveContainer width={'100%'} height="300" aspect={3}>
                            <AreaChart width={730} height={250} data={data1}
                                margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                                <defs>
                                    <linearGradient id="colorX" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#FFF4CF" stopOpacity={0.8} />
                                        <stop offset="95%" stopColor="#FFF4CF" stopOpacity={0} />
                                    </linearGradient>
                                    <linearGradient id="colorY" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#A6DFF8" stopOpacity={0.8} />
                                        <stop offset="95%" stopColor="#B4E6FC" stopOpacity={0} />
                                    </linearGradient>
                                    <linearGradient id="colorZ" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="##DCDCDC" stopOpacity={0.8} />
                                        <stop offset="95%" stopColor="#B4E6dC" stopOpacity={0} />
                                    </linearGradient>
                                </defs>
                                <XAxis dataKey="name" />
                                <YAxis />
                                <CartesianGrid strokeDasharray="3 3" horizontal={false} vertical={false} />
                                <Tooltip />
                                <Area type="monotone" dataKey="uv" stroke="#FFC400" fillOpacity={1} fill="url(#colorX)" />
                                {<Area type="monotone" dataKey="pv" stroke="#3AB6E5" fillOpacity={1} fill="url(#colorY)" dot={false} />
                                <Area type="monotone" dataKey="amt" stroke="#fafa4a" fillOpacity={1} fill="url(#colorZ)" dot={false} />}
                            </AreaChart>
                        </ResponsiveContainer>
                    </div>
                </Col>
                <Table className={'mt-5'}>
                    <thead>
                        <th style={{ color: '#3486D1' }}>Department Name</th>
                        <th>JAN</th>
                        <th>FEB</th>
                        <th>MAR</th>
                        <th>APR</th>
                        <th>MAY</th>
                        <th>JUN</th>
                        <th>JUL</th>
                        <th>AUG</th>
                        <th>SEP</th>
                        <th>OCT</th>
                        <th>NOV</th>
                        <th>DEC</th>
                    </thead>
                    <tbody>
                        <tr>
                            <td>DECK</td>
                            <td>12</td>
                            <td>12</td>
                            <td>12</td>
                            <td>12</td>
                            <td>12</td>
                            <td>12</td>
                            <td>12</td>
                            <td>12</td>
                            <td>12</td>
                            <td>12</td>
                            <td>12</td>
                            <td>12</td>
                        </tr>
                        <tr>
                            <td>DECK</td>
                            <td>12</td>
                            <td>12</td>
                            <td>12</td>
                            <td>12</td>
                            <td>12</td>
                            <td>12</td>
                            <td>12</td>
                            <td>12</td>
                            <td>12</td>
                            <td>12</td>
                            <td>12</td>
                            <td>12</td>
                        </tr>
                        <tr>
                            <td>DECK</td>
                            <td>12</td>
                            <td>12</td>
                            <td>12</td>
                            <td>12</td>
                            <td>12</td>
                            <td>12</td>
                            <td>12</td>
                            <td>12</td>
                            <td>12</td>
                            <td>12</td>
                            <td>12</td>
                            <td>12</td>
                        </tr>
                    </tbody>
                </Table> */}
            </Row>
            {
                department_wise_data1 && department_wise_data1.length > 0 && department_wise_data1.map((el, i) => <VesselTableData key={i} vesselData={el} vesselName={el.vessel_name} VesselColor={i === 0 ? 'icons sky-blue' : 'icons orange'} />)
            }
            <hr className='mb-3' />
        </>
    );
}

export default Charts;