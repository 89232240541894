import React, { useEffect, useState } from 'react'
import { Col, Image, Row, Tabs, Tab, Button } from 'react-bootstrap'
import settingsGray from '../../assets/images/settingsGray.svg'
import { HorizontalLine, MarkReadButton, NotificationMessageContainer, NotificationTabContainer, ViewAllButton } from './NotificationStyle'
import MarkRead from '../../assets/images/MarkRead.svg'
import { useDispatch } from 'react-redux'
import { getAllData } from '../../features/globalSlice'
import { SETTINGS } from '../../constant/CommonApiUrl'
import { useSelector } from 'react-redux'
import moment from 'moment'
import SimpleBar from 'simplebar-react'
import { Link } from 'react-router-dom'
function NotificationMessage() {
    const dispatch = useDispatch()
    const [activeKey, setActiveKey] = useState('view-all')
    const vessel_id = useSelector((state) => state.root.common.vessel_detailByID?.id);
    const { notificationList = [] } = useSelector((state) => state.root.GLOBAL)
    const viewMessage = (arg) => {
        dispatch(getAllData({ notificationList: `${SETTINGS}GetBrowserNotificationView?viewname=${arg.notification_name}&vessel_id=${vessel_id}` }))
    }
    return (
        <div>
            <Row className=' p-3'>
                <Col></Col>
                <Col className='text-center' style={{ color: "#002694", fontSize: "14.003px", fontWeight: 600 }}>Notifications</Col>
                <Col className='text-end'>
                    <Image src={settingsGray} alt="images" style={{ height: "15px", width: "15px" }} />
                </Col>
            </Row>
            <Row>
                <Col className='d-flex flex-row justify-content-center'>
                    <NotificationTabContainer>
                        <Tabs onSelect={(key) => setActiveKey(key)} id="tabs">
                            <Tab eventKey="view-all" title="View all">
                            </Tab>
                            <Tab eventKey="mentions" title="Mentions">                                {/* Content for Mentions tab */}
                            </Tab>
                        </Tabs>
                    </NotificationTabContainer>
                </Col>
            </Row>
            {activeKey === 'view-all' && <>
                <SimpleBar style={{ maxHeight: "310px" ,overflowX:'hidden'}}>
                    <Row >
                        {notificationList?.map((notification) =>
                            <Col md={12} className='d-flex flex-row justify-content-center' key={notification.notification_id}>
                                <NotificationMessageContainer className='mt-2 pe-auto' role={'presentation'} onClick={() => viewMessage(notification)}>
                                    <Link to={notification?.notification_link || '#'} className='MessageHeader mb-1'>{notification.notification_name}</Link>
                                    <Link to={notification?.notification_link || '#'} className='d-flex flex-row justify-content-between'>
                                        <span className='datetime'>{moment(new Date(`${notification.createddt}Z`)).format('dddd h:mm a')}</span>
                                        <span className='datetime'>{moment(new Date(`${notification.createddt}Z`)).format('DD MMM YYYY')}</span>
                                        {/* <span className='datetime'>Monday 8:30 a.m.</span>
                                <span className='datetime'>12 Feb 2024</span> */}
                                    </Link>
                                </NotificationMessageContainer>
                            </Col>
                        )}
                    </Row>
                </SimpleBar>
                <div className='position-absolute left-0 bottom-0 right-0  p-0'>
                    <HorizontalLine />
                    <div className='d-flex flex-row justify-content-between p-2'>
                        <MarkReadButton variant="primary" style={{ background: '#FFF', color: '#021F41' }}>
                            <Image src={MarkRead} alt="img" /> Mark all as read
                        </MarkReadButton>
                        <ViewAllButton>
                            View all notifications
                        </ViewAllButton>
                    </div>
                </div>
            </>}
        </div>
    )
}

export default NotificationMessage