export const prModal = {
  "id": 0,
  "purreq_id": 0,
  "transfer_id": 0,
  "product_id": 0,
  "product_code": "",
  "product_name": "",
  "unit": "",
  "net_Unit_cost": "",
  "quantity": 0,
  "vessel_id": 0,
  "department_id": "",
  "project_id": "",
  "item_tax": "",
  "tax": "",
  "discount": 0,
  "item_discount": 0,
  "subtotal": 0,
  "piDate": "2023-02-18T10:52:23.507Z",
  "piStatus": "",
  "isdisabled": "N",
  "createdby": "string",
  "createddt": "2023-02-18T10:52:23.507Z",
  "updatedby": "string",
  "updateddt": "2023-02-18T10:52:23.507Z"
}