import React from "react";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as ACTION from '../constant/Actions'
import { API_SERVICE } from '../Services/CommonApi'
import * as APIS from '../constant/CommonApiUrl'
import { toast } from "react-toastify";
import { ConvertJson } from "../helper";
import { commonPayloadFields } from "../helper/storage";
import { getPMSDashboardDefectSummary, getPMSDashboardDetail, getPMSDashboardGeneralSummary } from "./THUNK/pmsDashboardThunk";
import CalendarTooltip from "../pages/PMS/CALENDAR/CalendarTooltip";
import axios from "axios";
import { ValidateResponse } from "../Services/Support";

const DROPDOWN_FIELD_URL = {
    maintype: APIS.PMS_MAIN_TYPE,
    mainhead: APIS.PMS_MAIN_HEAD,
    frequencytype: APIS.PMS_FREQ_TYPE,
    surveytype: APIS.PMS_SURVEY_TYPE,
    vesseltype: APIS.VESSEL_TYPE,
    reshedule_reason: APIS.PMS_RESCHEDULE_REASON,
    status: APIS.PMS_COM_STATUS,
    certificate_type: APIS.PMS_CERTIFICATE_TYPE,
    certificate_group: APIS.PMS_CERTIFICATE_GROUP,
    completed_by: APIS.PMS_COMPLETEDBY,
    certificate_status: APIS.PMS_CERTIFICATE_STATUS,
    coc_status: APIS.PMS_COC_STATUS
}
const initialState = {
    fields: Object.assign({}, ...Object.keys(DROPDOWN_FIELD_URL).map(el => ({ [el]: [] }))),
    pms_vessel: [],
    maintenance_summary: [],
    maintenance_summary_backup: [],
    dashboard_task_event: [],
    general_pms_summary: [],
    defect_pms_summary: [],
    defect_pms_summary_backup: [],
    maintenance_detail: {},
    part_stock: [],
    part_stock_all_backup: [],
    oil_stock: [],
    oil_stock_all_backup: [],
    pms_transfer_vessel: [],
    pms_trans_payload: [],
    pms_complete_location: [],
    pms_completion_history: [],
    jobcard_designation: [],
    allocation_check: true,
    pms_id: "",
    pms_dashboard_detail: []
}
export const fetchPMSDropdown = createAsyncThunk(ACTION.FETCH_PMS_DROPDOWN_ACTION,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source() //cancel incomplete request
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            let keyField = Object.entries(DROPDOWN_FIELD_URL)
            let URLs = keyField.map(([key, val]) => val)
            let response = await Promise.all(URLs.map(url => API_SERVICE.get(url, "", "", { cancelToken: source.token })))
            response = response.map((res, index) => ({ [keyField[index][0]]: res.data.result.data }))
            return Object.assign({}, ...response)
        } catch (err) {
            return thunkAPI.rejectWithValue(err.message)
        }

    })
export const createPmsAPI = createAsyncThunk(ACTION.CREATE_PMS_COMPONENT,
    async (arg, thunkAPI) => {
        try {
            let response = await API_SERVICE.post(APIS.CREATE_PMS_COMPONENT, arg)
            //thunkAPI.dispatch(getPMSMaintenanceSummary(new URLSearchParams({equipment_id:arg.equipment_id})))
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getPMSMaintenanceSummary = createAsyncThunk(ACTION.PMS_MAINTENANCE_SUMMARY,
    async (arg, thunkAPI) => {
        try {
            let vessel_id = thunkAPI.getState().root.common.vessel_detailByID.id
            let response = await API_SERVICE.get(`${APIS.PMS_MAINTENANCE_SUMMARY}?${arg}&vessel_id=${vessel_id}`)
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getPMSMaintenanceID = createAsyncThunk(ACTION.GET_PMS_MAINTENANCE,
    async (arg, thunkAPI) => {
        try {
            let vessel_id = thunkAPI.getState().root.common.vessel_detailByID.id
            let response = await API_SERVICE.get(`${APIS.GET_PMS_MAINTENANCE}?id=${arg}`)
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getPMSStock = createAsyncThunk(ACTION.GET_STOCK_PMS,
    async (arg, thunkAPI) => {
        try {
            let vessel_id = thunkAPI.getState().root.common.vessel_detailByID.id
            let response = await API_SERVICE.get(`${APIS.GET_STOCK_PMS}?vessel_id=${vessel_id}&${arg}`)
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getVesselType = createAsyncThunk(ACTION.BASED_TYPE,
    async (arg, thunkAPI) => {
        try {
            let vessel_id = thunkAPI.getState().root.common.vessel_detailByID.id
            let response = await API_SERVICE.get(`${APIS.BASED_TYPE}?${arg}&vessel_id=${vessel_id}`)
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const pmsTransferSearch = createAsyncThunk(ACTION.PMS_SEARCH_TRANSFER,
    async (arg, thunkAPI) => {
        try {
            let vessel_id = thunkAPI.getState().root.common.vessel_detailByID.id
            let response = await API_SERVICE.get(`${APIS.PMS_SEARCH_TRANSFER}?${arg}&vessel_id=${vessel_id}`)
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const GetVesselForPMSTransfer = createAsyncThunk(ACTION.PMS_VESSEL_TRANSFER,
    async (arg, thunkAPI) => {
        try {
            let response = await API_SERVICE.get(`${APIS.PMS_VESSEL_TRANSFER}?${arg}`)
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const savePmsTransfer = createAsyncThunk(ACTION.SAVE_PMS_TRANSFER,
    async (arg, thunkAPI) => {
        try {
            let response = await API_SERVICE.post(`${APIS.SAVE_PMS_TRANSFER}`, arg)
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getPMSCompletionLocation = createAsyncThunk(ACTION.PMS_LOCATION_COMPLETION,
    async (arg, thunkAPI) => {
        try {
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            return API_SERVICE.get(`${APIS.PMS_LOCATION_COMPLETION}`)
        } catch (err) {
            return thunkAPI.rejectWithValue(err)
        }
    }
)
export const checkPMSallocation = createAsyncThunk(ACTION.CHECK_PMS_ALLOCATION,
    async (arg, thunkAPI) => {
        try {
            return API_SERVICE.get(`${APIS.CHECK_PMS_ALLOCATION}?${arg}`)
        } catch (err) {
            return thunkAPI.rejectWithValue(err)
        }
    }
)
export const getPMSCompletionHistory = createAsyncThunk(ACTION.COMPLETION_PMS_HISTORY,
    async (arg, thunkAPI) => {
        try {
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            return API_SERVICE.get(`${APIS.COMPLETION_PMS_HISTORY}?pms_id=${arg}&vessel_id=${id}`)
        } catch (err) {
            return thunkAPI.rejectWithValue(err)
        }
    }
)
export const getJobcardDesignation = createAsyncThunk(ACTION.GET_DESIGNATION_SHIP,
    async (arg, thunkAPI) => {
        try {
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            return API_SERVICE.get(`${APIS.GET_DESIGNATION_SHIP}`)
        } catch (err) {
            return thunkAPI.rejectWithValue(err)
        }
    }
)

export const pmsSlice = createSlice({
    name: 'PMS',
    initialState,
    reducers: {
        clearMaintenanceSummary: (state, action) => {
            state.maintenance_summary = []
            state.maintenance_summary_backup = []
        },
        clearMaintenanceDetail: (state, action) => {
            state.maintenance_detail = {}
        },
        setTransPayload: (state, action) => {
            state.pms_trans_payload = [...state.pms_trans_payload, { ...action.payload, isdisabled: 'N', ...commonPayloadFields }]
            if (action.payload.checked === false) {
                state.pms_trans_payload = state.pms_trans_payload.filter(el => el.vessel_id !== action.payload.vessel_id)
            }
        },
        filterMaintenanceSummary: (state, action) => {
            let filter_data = {
                ALL: state.maintenance_summary_backup,
                DUE: state.maintenance_summary_backup.filter(el => (el.due_status === action.payload || el.due_status === "DONE")), //karthi said list the due and done while click due
                OVERDUE: state.maintenance_summary_backup.filter(el => el.due_status === action.payload),
                IN_PROGRESS: state.maintenance_summary_backup.filter(el => el.due_status === action.payload.replace('_', ' ')),
                DONE: state.maintenance_summary_backup.filter(el => el.due_status === action.payload),
                /* UNSCHEDULED:state.maintenance_summary_backup.filter(el => el.pms_type === 'U'),
                RESCHEDULED:state.maintenance_summary_backup.filter(el => el.pms_type === 'R'), */
            }
            state.maintenance_summary = filter_data[action.payload]
        },
        addPartStockPMS: (state, action) => {
            let filterArr = [...state.part_stock_all_backup];
            let currentIndex;
            currentIndex = filterArr.findIndex(el => el.id === action.payload.id && el.location_id === action.payload.location_id);
            currentIndex !== -1 ? filterArr[currentIndex] = { ...filterArr[currentIndex], ...action.payload, qty: action.payload.qty, consumed_rob: (Number(action.payload.consumed_rob) + Number(action.payload.qty)) } : filterArr.push(action.payload);
            state.part_stock = filterArr
            state.part_stock_all_backup = filterArr
        },
        addOilStockPMS: (state, action) => {
            let filterArr = [...state.oil_stock_all_backup];
            let currentIndex;
            currentIndex = filterArr.findIndex(el => el.id === action.payload.id && el.location_id === action.payload.location_id);
            currentIndex !== -1 ? filterArr[currentIndex] = { ...filterArr[currentIndex], ...action.payload, qty: action.payload.qty, consumed_rob: (Number(action.payload.consumed_rob) + Number(action.payload.qty)) } : filterArr.push(action.payload);
            state.oil_stock = filterArr
            state.oil_stock_all_backup = filterArr
        },
        searchPartStock: (state, action) => {
            if (action.payload !== "") {
                let filterData = []
                state.part_stock_all_backup.map(el => el.item_name.toLowerCase().search(action.payload.toLowerCase()) !== -1 && filterData.push(el))
                state.part_stock = filterData
            } else {
                state.part_stock = state.part_stock_all_backup
            }
        },
        searchOilStock: (state, action) => {
            if (action.payload !== "") {
                let filterData = []
                state.oil_stock_all_backup.map(el => el.item_name.toLowerCase().search(action.payload.toLowerCase()) !== -1 && filterData.push(el))
                state.oil_stock = filterData
            } else {
                state.oil_stock = state.oil_stock_all_backup
            }
        },
        searchDefectEquipment: (state, action) => {
            if (action.payload !== "") {
                let filterData = []
                state.defect_pms_summary_backup.map(el => el.equipmentname.toLowerCase().search(action.payload.toLowerCase()) !== -1 && filterData.push(el))
                state.defect_pms_summary = filterData
            } else {
                state.defect_pms_summary = state.defect_pms_summary_backup
            }
        },
        getPMSCalendarEvent: (state, action) => {
            let event = state.dashboard_task_event.map((el, i) => ({
                ...el,
                id: el.id,
                title: <CalendarTooltip maintenanceName={el.maintenance_name} dueStatus={el.due_status} id={el.id} />,
                start: new Date(`${el.next_due_date}Z`),
                end: new Date(`${el.next_due_date}Z`),
                allDay: true
            }))
            state.dashboard_task_event = event
        },
        setCalendarPmsId: (state, action) => {
            state.pms_id = action.payload
        },
        clearCalendarPmsId: (state, action) => {
            state.pms_id = ""
        },
        clearPMStransVessel: (state, action) => {
            state.pms_transfer_vessel = []
            state.pms_trans_payload = []
        },
        clearStock: (state, action) => {
            state.part_stock = []
            state.oil_stock = []
        },
        clearMultiPmsVessel: (state, action) => {
            state.pms_vessel = []
        }
    },
    extraReducers: (builder) => {
        (builder)
            .addCase(fetchPMSDropdown.fulfilled, (state, action) => {
                state.fields = action.payload
            })
            .addCase(getVesselType.fulfilled, (state, action) => {
                if (action.payload.data.result.status) {
                    state.pms_vessel = action.payload.data.result.data
                } else {
                    toast.error(action.payload.data.result.message)
                }
            })
            .addCase(getPMSCompletionHistory.fulfilled, (state, action) => {
                if (action.payload.data.result.status) {
                    state.pms_completion_history = action.payload.data.result.data
                } else {
                    toast.error(action.payload.data.result.message)
                }
            })
            .addCase(checkPMSallocation.fulfilled, (state, action) => {
                if (action.payload.data.result.status) {
                    state.allocation_check = action.payload.data.result.data[0].is_equipment_available
                    toast.error(action.payload.data.result.data[0].message)
                } else {
                    toast.error(action.payload.data.result.message)
                }
            })
            .addCase(getJobcardDesignation.fulfilled, (state, action) => {
                if (action.payload.data.result.status) {
                    state.jobcard_designation = action.payload.data.result.data
                } else {
                    toast.error(action.payload.data.result.message)
                }
            })
            .addCase(getPMSCompletionLocation.fulfilled, (state, action) => {
                if (action.payload.data.result.status) {
                    state.pms_complete_location = action.payload.data.result.data
                } else {
                    toast.error(action.payload.data.result.message)
                }
            })
            .addCase(GetVesselForPMSTransfer.fulfilled, (state, action) => {
                if (action.payload.data.result.status) {
                    state.pms_transfer_vessel = action.payload.data.result.data
                } else {
                    toast.error(action.payload.data.result.message)
                }
            })
            .addCase(pmsTransferSearch.fulfilled, (state, action) => {
                if (action.payload.data.result.status) {
                    state.maintenance_summary = action.payload.data.result.data
                    state.maintenance_summary_backup = action.payload.data.result.data
                } else {
                    toast.error(action.payload.data.result.message)
                }
            })
            .addCase(createPmsAPI.fulfilled, (state, action) => {
                if (action.payload.data.result.status) {
                    toast.success(action.payload.data.result.message)
                } else {
                    toast.error(action.payload.data.result.message)
                }
            })
            .addCase(savePmsTransfer.fulfilled, (state, action) => {
                if (action.payload.data.result.status) {
                    toast.success(action.payload.data.result.message)
                } else {
                    toast.error(action.payload.data.result.message)
                }
            })
            .addCase(getPMSMaintenanceSummary.fulfilled, (state, action) => {
                if (action.payload.data.result.status) {
                    state.maintenance_summary = action.payload.data.result.data
                    state.maintenance_summary_backup = action.payload.data.result.data
                    state.dashboard_task_event = action.payload.data.result.data
                } else {
                    toast.error(action.payload.data.result.message)
                }
            })
            .addCase(getPMSDashboardGeneralSummary.fulfilled, (state, action) => {
                if (action.payload.data.result.status) {
                    state.general_pms_summary = action.payload.data.result.data
                } else {
                    toast.error(action.payload.data.result.message)
                }
            })
            .addCase(getPMSDashboardDefectSummary.fulfilled, (state, action) => {
                if (action.payload.data.result.status) {
                    state.defect_pms_summary = action.payload.data.result.data
                    state.defect_pms_summary_backup = action.payload.data.result.data
                } else {
                    toast.error(action.payload.data.result.message)
                }
            })
            .addCase(getPMSStock.fulfilled, (state, action) => {
                if (action.payload.data.result.status) {
                    let obj = action.payload.data.result.data[0]
                    state.part_stock = obj.part !== null ? ConvertJson(obj.part) : []
                    state.part_stock_all_backup = obj.part !== null ? ConvertJson(obj.part) : []
                    state.oil_stock = obj.oil_products !== null ? ConvertJson(obj.oil_products) : []
                    state.oil_stock_all_backup = obj.oil_products !== null ? ConvertJson(obj.oil_products) : []
                } else {
                    toast.error(action.payload.data.result.message)
                }
            })
            .addCase(getPMSMaintenanceID.fulfilled, (state, action) => {
                if (action.payload.data.result.status) {
                    let obj = action.payload.data.result.data[0]
                    obj.jobcard = obj.jobcard !== null ? ConvertJson(obj.jobcard) : []
                    obj.jobcard_repository = obj.jobcard_repository !== null ? ConvertJson(obj.jobcard_repository) : []
                    obj.repository = obj.repository !== null ? ConvertJson(obj.repository) : []
                    obj.maintenanceDetail = obj.maintenanceDetail !== null ? ConvertJson(obj.maintenanceDetail) : []
                    state.maintenance_detail = obj
                } else {
                    toast.error(action.payload.data.result.message)
                }
            })
            .addCase(getPMSDashboardDetail.fulfilled, (state, action) => {
                let obj = ValidateResponse(action.payload)[0]
                obj.pms_data = obj?.pms_data ? ConvertJson(obj.pms_data) : []
                state.pms_dashboard_detail = obj
            })
    }
})
export const { getPMSCalendarEvent, setCalendarPmsId, clearCalendarPmsId, searchDefectEquipment, setTransPayload, clearMultiPmsVessel, clearMaintenanceDetail, clearStock, clearPMStransVessel, clearMaintenanceSummary, filterMaintenanceSummary, addPartStockPMS, addOilStockPMS, searchPartStock, searchOilStock } = pmsSlice.actions
export default pmsSlice.reducer