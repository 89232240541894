const month_size = new Array(12).fill().map((_, i) => ++i)
export const getMissingMonthData = (arg) => {
    let received_month = arg.map(el => el.job_month)
    let missing_month = month_size.filter(el => !received_month.includes(el))
    missing_month = missing_month.map(el => getMissingWaveMonth(el))
    return [...arg, ...missing_month].sort((a, b) => a.job_month - b.job_month)
}
const getMissingWaveMonth = (month) => {
    switch (month) {
        case 1:
            return {
                "completed": 0,
                "job_month": 1
            }
        case 2:
            return {
                "completed": 0,
                "job_month": 2
            }
        case 3:
            return {
                "completed": 0,
                "job_month": 3
            }
        case 4:
            return {
                "completed": 0,
                "job_month": 4
            }
        case 5:
            return {
                "completed": 0,
                "job_month": 5
            }
        case 6:
            return {
                "completed": 0,
                "job_month": 6
            }
        case 7:
            return {
                "completed": 0,
                "job_month": 7
            }
        case 8:
            return {
                "completed": 0,
                "job_month": 8
            }
        case 9:
            return {
                "completed": 0,
                "job_month": 9
            }
        case 10:
            return {
                "completed": 0,
                "job_month": 10
            }
        case 11:
            return {
                "completed": 0,
                "job_month": 11
            }
        case 12:
            return {
                "completed": 0,
                "job_month": 12
            }
    }
}
export const MonthlyCompletedSum = (data) => {
    // Create an object to store the sum of completed values for each month
    const completedSumByMonth = {};

    // Iterate over the data array
    data.forEach(({ department_data }) => {
        // Iterate over the department_data array
        department_data.forEach(({ job_month_text, completed }) => {
            // If the job_month_text is not already a key in completedSumByMonth, initialize it as 0
            if (!completedSumByMonth[job_month_text]) {
                completedSumByMonth[job_month_text] = 0;
            }

            // Add the completed value to the corresponding month's sum
            completedSumByMonth[job_month_text] += completed;
        });
    });

    // Render the sum of completed values for each month
    return (completedSumByMonth);
};

export const constructWaveResponse =(a=[],b={},d=[]) => {
    //console.log(a,d,'a,d')
    //const b = { vessel_name: d[0].vessel_name, vessel_data: d[0].vessel_data };
    /* const b=d.filter(el=>el.vessel_name===a[0])[0]
    console.log(b,'b') */
    const ff = []
    a.map((v, i) => {
        d.map(({ vessel_data, vessel_name }) => {
            if (i === 0 && vessel_name === v) {
                // b.push({vessel_data, vessel_name})
            } else {
                //console.log(b?.vessel_data)
                if (vessel_name === v) {
                    //console.log(vessel_data)
                    vessel_data.map((e, index) => {
                        b?.vessel_data?.map(({ job_month, job_month_text, completed, total, pending }) => {
                            if (job_month === e.job_month) {
                                ff.push({
                                    ...e,
                                    [`completed${i}`]: completed,
                                    [`total${i}`]: total,
                                    [`pending${i}`]: pending,
                                    [`month_text`]: job_month_text.substring(0,3).toUpperCase() 
                                })
                            }
                        })
                    })
                }
            }
        })
    })
    //b.vessel_data = ff
    return ff
    //console.log(b)
};