import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import * as APIS from '../constant/CommonApiUrl';
import * as ACTIONS from '../constant/Actions'
import {API_SERVICE} from "../Services/CommonApi";
import {toast} from "react-toastify";
import {dateUTC} from "../helper/storage";
import {ConvertJson} from "../helper";

const vesselInfo = localStorage.getItem('vesselInfo') !== null ? JSON.parse(localStorage.getItem('vesselInfo')) : {}

const initialState = {
    invoiceList: [],
    invoiceData: [],
    invoiceDetails: {},
    invoiceStatusCheck:{},
    invoiceFormData: {
        invoice_no: '',
        invoice_date: new Date(),
        supplier_date: new Date(),
        contract: '',
        ponumber: '',
        recurringinvoice: '',
        supplier_id: 0,
        vessel_id: vesselInfo.vessel_id ? vesselInfo.vessel_id : 0,
    }
}
export const saveInvoice = createAsyncThunk(ACTIONS.SAVE_INVOICE,
    async (arg, thunkApi) => {
        try {
            return await API_SERVICE.post(`${APIS.SAVE_INVOICE}`, arg)
        } catch (err) {
            thunkApi.rejectWithValue(err)
        }
    })
export const getInvoiceList = createAsyncThunk(ACTIONS.INVOICE_LIST,
    async (arg, thunkApi) => {
        try {
            const { id, vessel_name } = thunkApi.getState().root.common.vessel_detailByID
            return await API_SERVICE.get(arg ? `${APIS.GET_INVOICE}?${arg}&vessel_id=${vessel_name === 'OFFICE' ? 0 : id}` : `${APIS.GET_INVOICE}?vessel_id=${vessel_name === 'OFFICE' ? 0 : id}`)
        } catch (err) {
            thunkApi.rejectWithValue(err)
        }
    })
export const getInvoiceDetails = createAsyncThunk(ACTIONS.INVOICE_DETAILS,
    async (arg, thunkApi) => {
        try {
            const { id, vessel_name } = thunkApi.getState().root.common.vessel_detailByID
            return await API_SERVICE.get(`${APIS.GET_INVOICE_BY_ID}?vessel_id=${vessel_name === 'OFFICE' ? 0 : id}&ID=${arg.id}`)
        } catch (err) {
            thunkApi.rejectWithValue(err)
        }
    })

export const getEInvoiceDetails = createAsyncThunk(ACTIONS.E_INVOICE_DETAILS,
    async (arg, thunkApi) => {
        try {
            const { id, vessel_name } = thunkApi.getState().root.common.vessel_detailByID
            return await API_SERVICE.get(`${APIS.GET_INVOICE_LIST}?${arg}&vessel_id=${vessel_name === 'OFFICE' ? 0 : id}`)
        } catch (err) {
            thunkApi.rejectWithValue(err)
        }
    })
export const getInvoiceStatusCheck = createAsyncThunk(ACTIONS.INVOICE_STATUS,
    async (arg, thunkAPI) => {
        try {
            return API_SERVICE.get(`${APIS.INVOICE_STATUS_CHECK}?${arg}`)
        } catch (err) {
            return thunkAPI.rejectWithValue(err)
        }
    }
)
export const InvoiceSlice = createSlice({
    name: 'invoice',
    initialState,
    reducers: ({
        setFormData: ((state, { payload }) => {
            state.invoiceFormData = { ...state.invoiceFormData, [payload.name]: payload.value }
        }),
        onInvoiceRowUpdate: (state, { payload }) => {
            const { is_paid, data } = payload
            const immutableArray = state.invoiceData
            const mutableArray = []
            immutableArray.map((x, i) => {
                x.id === data.id ? mutableArray.push({ ...x, is_paid: is_paid ? "Y" : "N" }) : mutableArray.push({ ...x })
            })
            state.invoiceData = mutableArray
        }, onInvoiceRowUpdateDate: (state, { payload }) => {
            const { due_date, data } = payload
            const immutableArray = state.invoiceData
            const mutableArray = []
            immutableArray.map((x, i) => {
                x.id === data.id ? mutableArray.push({ ...x, paymnet_duedate: dateUTC(due_date) }) : mutableArray.push({ ...x })
            })
            state.invoiceData = mutableArray
        }
    }),
    extraReducers: (builder) => {
        (builder)
            .addCase(saveInvoice.fulfilled, (state, action) => {
                if (action.payload.data.result.status) {
                    toast.success(action.payload.data.result.message)
                } else {
                    toast.error(action.payload.data.result.message)
                }
            })
            .addCase(saveInvoice.rejected, (state, action) => {
                if (action.payload.response.data.status === 400) {
                    toast.error(action.payload.response.data.title)
                }
            })
            .addCase(getInvoiceStatusCheck.fulfilled,(state,action)=>{
                if(action.payload.data.result.status){
                    if (action.payload.data.result.data)
                        state.invoiceStatusCheck= action.payload.data.result.data[0]
                }
            })
            .addCase(getInvoiceList.fulfilled, (state, action) => {
                if (action.payload.data.result.status) {
                    state.invoiceList = action.payload.data.result.data
                } else {
                    toast.error(action.payload.data.result.message)
                }
            })
            .addCase(getInvoiceList.rejected, (state, action) => {
                if (action.payload.response.data.status === 400) {
                    toast.error(action.payload.response.data.title)
                }
            })
            .addCase(getInvoiceDetails.fulfilled, (state, action) => {
                if (action.payload.data.result.status) {
                    let payload = action.payload.data.result.data[0]
                    payload['repository']= action.payload.data.result.data[0]?.repository ? ConvertJson(action.payload.data.result.data[0].repository) : []
                    state.invoiceDetails = payload
                } else {
                    toast.error(action.payload.data.result.message)
                }
            }).addCase(getEInvoiceDetails.fulfilled, (state, action) => {
                if (action.payload.data.result.status) {
                    state.invoiceData = action.payload.data.result.data
                } else {
                    toast.error(action.payload.data.result.message)
                }
            })
    }
})
export const { setFormData, onInvoiceRowUpdate, onInvoiceRowUpdateDate } = InvoiceSlice.actions
export default InvoiceSlice.reducer