import { dateUTC } from "../helper/storage";

export const INITIAL_VALUES = {
  Certificate: {
    id: 0,
    certificate_no: "",
    certificate_name: "",
    certificate_type_id: 0,
    certificate_group_id: 0,
    frequency_type: '',
    frequency: '',
    last_done_date: null,
    next_due_date: null,
    responsibility: "",
    permanent: "",
    issued_by: "",
    issued_at: "",
    issued_date: null,
    survey_carried_date: null,
    valid_date: null,
    status: "",
    remark: "",
    alert_value: 0,
    vessel_id: 0,
    coc_status: ""
  },
  Port: {
    country_id: '',
    port_name: '',
    port_code: ''
  },
  User: {
    ID: 0,
    first_name: "",
    last_name: "",
    dob: "",
    dept: "",
    designation: "",
    roleid: [],
    email: "",
    passwrd: "",
    budgeamount: 0,
    fromamount: 0,
    verify_from: 0,
    is_shipuser: false,
    verify_to: 0,
    vesselid: []
  },
  AddUser: {
    ID: 0,
    first_name: "",
    last_name: "",
    dept: "",
    designation: "",
    roleid: [],
    email: "",
    passwrd: "",
    vesselid: [],
    status: ""
  },
  Module: {
    moduleCode: '',
    moduleName: ''
  },
  UOM: {
    code: '',
    name: ''
  },
  Location: {
    locationcode: '',
    locationname: ''
  },
  Menu: {
    menucode: "",
    menuname: "",
    moduleId: '',
    url: "",
  },
  Roles: {
    role_id: '0',
    role_name: "",
    role_slug: "",
    role_access: "",
    designation_id: ""
  },
  RolesAccess: {
    moduleId: '',
    roleId: '',
    menuId: '',
  },
  CurrencyExchange: {
    defultcurrency_id: '',
    currency_id: '',
    exchange_rate: '',
  },
  Approval: {
    approve_Id: '',
    user_id: '',
    role_name: "",
    approve_name: "",
    aprroved_date: "",
    aprroved_update: ""
  },
  Product: {
    prodCode: '',
    prodType: ''
  },
  ApproveLevel: {
    aprlevelcode: '',
    aprlevelname: ''
  },
  ApproveLink: {
    aprname: '',
    aprlevelid: ''
  },
  Designation: {
    designation: '',
    department_id: '',
    allow_pms_des: []
    /*  allow_pms: '',
     allow_pms_dept: '' */
  },
  Department: {
    department_id: "",
    department_name: "",
    username: ""
  },
  UserAccess: {
    userid: '',
    rollid: '',
  },
  Status: {
    moduleid: '',
    menuid: '',
    statuscode: "",
    statusName: "",
  },
  Vessel: {
    id: 0,
    vessel_id: "0",
    vessel_name: "",
    vessel_created_at: dateUTC(new Date()),
    vessel_updated_at: dateUTC(new Date()),
    vessel_type: "",
    imo: "",
    budget_id: 0,
    vtype_id: 0,
    bulityear: "",
    flag: '',
    caoacity: '',
    grosstonnage: '',
    shortname: "",
    portofregister: ''
  },
  Project: {
    project_id: "",
    project_name: "",
    project_created_at: "",
    budget_id: '',
  },
  Grade: {
    grade_id: "",
    grade_name: ""
  },
  Supplier: {
    supplier_id: "",
    supplier_company: '',
    supplier_name: "",
    supplier_email: "",
    supplier_phone: "",
    supplier_address: "",
    supplier_city: "",
    supplier_state: "",
    postal_code: "",
    supplier_country: "",
    supplier_grade_id: '',
    debit_id: "",
    credit_id: "",
    port_id: '',
    currency: ''
  },
  ProductSupplier: {
    product_id: "",
    product_name: "",
    product_number: "",
    product_type: "",
    product_amount: '',
    product_quantity: '',
    product_unit_price: '',
    product_dimension: "",
    product_loss: '',
    product_damage: "",
    prdouct_alter_quantity: '',
    product_mfd: "",
    product_exp: ""
  },
  ProductGeneral: {
    pgname: "",
    pgcode: "",
    pgmode: "",
    pgedition: "",
    pgtype: "",
    pgpublisher: "",
    pgaccountcode: 0,
    pgremarks: "",
    pgfollo: "",
    pgregision: "",
    pglocationname: "",
    pgnoofchart: 0,
    unit: '',
    id: 0
  },
  Type: {
    type: "",
    typecode: "",
    typename: "",
    remarks: "",
  },
  OilProduct: {
    productCode: "",
    category: "",
    type: "",
    productName: "",
    brand: "",
    unit: "",
    recorderLevel: "",
    recorderQty: 0.,
    application: "",
    accountCode: "",
    rob: "",
    isratecontract: "N",
    robtype: '',
    ratecontract: 0
  },
  PurchaseRequisition: {
    pr_id: "",
    pr_date: "",
    supplier_id: "",
    supplier_name: "",
    vessel_id: "",
    department_id: "",
    project_id: "",
    note: "",
    pr_status: "",
    purchase_by: "",
    purchase_timestamp: "",
    approve_id: "",
    due_date: "",
    budget_id: "",
    budget_name: ""
  },
  Store: {
    storecategery: "",
    storetype: "",
    itemcode: "",
    itemname: "",
    unit: 0,
    brandmaker: "",
    reorderlevel: 0,
    consumableyn: "N",
    remarks: "",
    robquantity: 0,
    robreceiveddate: "",
    robunit: 0,
    robexpairationdate: null,
    inventoryid: 0,
    vessel_id: 0,
    location: ""
  },
  Equipment: {
    equipmententry: "",
    equipmentcode: "",
    equipmentname: "",
    equipmentposition: "",
    serialnumber: "",
    noofequipments: 0,
    yearofmanufacture: 0,
    capacity: 0,
    nonumber: 0,
    model: "",
    maker: "",
    yard: "",
    machinerylocation: "",
    displayid: "",
    critical: "",
    moredetails: "",
    otherdetails: "N",
    highvalue: "0",
    departmentid: 0,
    inventory_treeid: 0,
    minor_threshold: 0,
    major_threshold: 0
  },
  EDoc: {
    folder_type: "",
    name: "",
    // folder_apporvalby: "",
    documnetcontroller_id: '',
    authorizeduser_id: "",
    viewuser_id: "",
    remarks: ""
  },
  initiateRequest: {
    changerequest_id: '',
    remarks: ""
  },
  reviewComments: {
    review_status: "",
    review_comment: "",
    review_remarks: "",
  },
  defaultTheme: {
    id: "T_''''1",
    name: "light",
    colors: {
      body: "#FFFFFF",
      text: "#''''''''''''",
      button: {
        text: "#FFFFFF",
        background: "#''''''''''''"
      },
      link: {
        text: "teal",
        opacity: 1
      }
    },
    font: "Tinos"
  },
  e_doc: {
    doc_title: "",
    doc_category: "",
    department_id: '',
    designation_id: "",
    vessel_id: "",
    vessel_list: "",
    doc_remarks: "",
    doc_date: new Date(),
    inventory_name: "",
    link_checklist: "",
    effective_date: new Date(),
    major_version: "",
    minor_version: "",
    revision_version: "",
    view_user: ""
  },
  meetingRecord: {
    id: 0,
    meeting_type: "",
    meeting_id: '',
    meeting_date: null,
    voyage_number: "",
    other_attendees: "",
    meeting_attendance: "",
    superintendent: "",
    altervalue: '',
    remarks: "",
    is_sent_to_ship: false,
    isdisabled: 'N'
  },
  drillRecord: {
    id: 0,
    drill_id: 0,
    drill_number: "",
    location: "",
    unscheduled: false,
    location_ship: 0,
    frequency_type: 0,
    frequency: "",
    fromdate: null,
    todate: null,
    fromtime: null,
    totime: null,
    master_remarks: "",
    company_remarks: "",
    minutes_remarks: "",
    assessrisk: false,
    last_date: null,
    next_date: null,
    supervised_by: 0,
    attendance: "",
    attendess: "",
    taskitem: "",
    taskstart_time1: 0,
    taskstart_time2: 0,
    taskstart_time3: 0,
    taskfinish_time1: 0,
    taskfinish_time2: 0,
    taskfinish_time3: 0,
    responsiblity: 0,
    taskitem_procedure: "",
    taskitem_remarks: ""
  },
  profile: {
    first_name: '',
    last_name: '',
    email: '',
    change_email: '',
    password: '',
    change_password: '',
  }
}

export const MASTERS_SCREENS = ['/designation', '/department',
  '/module', '/menu', '/rolesAccess', '/approval', '/product', '/approveLink',
  '/userAccess', '/status', '/project', '/grade', '/supplier', '/productSupplier',
  '/type', '/port', '/currencyExchange', '/certificate'
]

export const MASTERS = ['designation', 'department',
  'module', 'menu', 'rolesAccess', 'approval', 'product', 'approveLink',
  'userAccess', 'status', 'project', 'grade', 'productSupplier',
  'type', 'port', 'certificate'
]

export const PartReports = [
  { menu: 'Equipment Part', id: 21, path: '/equipment_part?category=part' },
  { menu: 'ROB Part', id: 22, path: '/rob_part?category=part' },
  { menu: 'Adjustment Part', id: 23, path: '/adjustment_part?category=part' },
  { menu: 'Consumption Part', id: 24, path: '/consumption_part?category=part' },
  { menu: 'Receipt Part', id: 25, path: '/receipt_part?category=part' },
  { menu: 'Critical Part', id: 26, path: '/critical_part?category=part' }
]

export const StoreReports = [
  { menu: 'Report Store', id: 31, path: '/report_store?category=store' },
  { menu: 'ROB Store', id: 32, path: '/rob_store?category=store' },
  { menu: 'Adjustment Store', id: 33, path: '/adjustment_store?category=store' },
  { menu: 'Consumption Store', id: 34, path: '/consumption_store?category=store' },
  { menu: 'Receipt Store', id: 35, path: '/receipt_store?category=store' }
]

export const OilReports = [
  { menu: 'Report Oil', id: 41, path: '/report_oil?category=oil' },
  { menu: 'ROB Oil', id: 42, path: '/rob_oil?category=oil' },
  { menu: 'Adjustment Oil', id: 43, path: '/adjustment_oil?category=oil' },
  { menu: 'Consumption Oil', id: 44, path: '/consumption_oil?category=oil' },
  { menu: 'Receipt Oil', id: 45, path: '/receipt_oil?category=oil' },
  // { menu: 'Bunker Oil', id: 45, path: '/bunker_oil?category=oil' }
]

export const PurchaseReports = [
  { menu: 'Requisition', id: 1, path: '/requisition' },
  { menu: 'RFQ', id: 11, path: '/rfq' },
  { menu: 'Quotation', id: 2, path: '/quotation' },
  { menu: 'Purchase Order', id: 4, path: '/purchase-order' },
  { menu: 'Goods', id: 3, path: '/Goods' },
  { menu: 'Invoice', id: 5, path: '/invoice' },
  { menu: 'Work Order', id: 126, path: '/work_order' },
  { menu: 'Exchange Rate History', id: 6, path: '/currency' },
  { menu: 'Purchase Item History', id: 7, path: '/purchase-item-history' },
  { menu: 'Purchase Query', id: 8, path: '/purchase-query' },
  { menu: 'Outstanding Po', id: 9, path: '/outstanding-po' },
  { menu: 'Supplier Rating', id: 10, path: '/supplier-rating' }
]
export const EdocReports = [
  { menu: 'History Track', id: 101, path: '/history-track' },
  { menu: 'Status Track', id: 102, path: '/report-status' },
  { menu: 'User Listing', id: 103, path: '/report-user' },
  { menu: 'Request List', id: 104, path: '/report-request' }
]
export const SafetyReports = [
  { menu: 'Audit', id: 111, path: '/audit' },
  { menu: 'Inspection', id: 112, path: '/inspection' },
  { menu: 'Meeting', id: 113, path: '/meeting' },
  { menu: 'Blank Audit', id: 114, path: '/blank_audit_checklist' },
  { menu: 'Blank Inspection', id: 115, path: '/blank_inspection' },
  { menu: 'Audit Schedule', id: 116, path: '/audit_schedule' },
  { menu: 'Inspection Schedule', id: 117, path: '/inspection_schedule' },
  { menu: 'Damage', id: 118, path: '/damage' },
  { menu: 'Injury', id: 119, path: '/injury' },
  { menu: 'Near Miss', id: 120, path: '/near_miss' },
  { menu: 'Summary', id: 121, path: '/summary' },
  { menu: 'Drill Due Done', id: 122, path: '/drill_due_done' },
  { menu: 'Audit NC', id: 123, path: '/audit_nc' },
  { menu: 'Inspection NC', id: 124, path: '/inspection_nc' },
  { menu: 'NC/OBS', id: 125, path: '/nc_obs' }
]

export const REPORTS = [
  // //inventory part report menu
  // { menu: 'Equipment Part', id: 21, path: '/equipment_part?category=part' },
  // { menu: 'ROB Part', id: 22, path: '/rob_part?category=part' },
  // { menu: 'Adjustment Part', id: 23, path: '/adjustment_part?category=part' },
  // { menu: 'Consumption Part', id: 24, path: '/consumption_part?category=part' },
  // { menu: 'Receipt Part', id: 25, path: '/receipt_part?category=part' },
  // { menu: 'Critical Part', id: 26, path: '/critical_part?category=part' },
  // //inventory store report menu
  // { menu: 'Report Store', id: 31, path: '/report_store?category=store' },
  // { menu: 'ROB Store ', id: 32, path: '/rob_store?category=store' },
  // { menu: 'Adjustment Store', id: 33, path: '/adjustment_store?category=store' },
  // { menu: 'Consumption Store', id: 34, path: '/consumption_store?category=store' },
  // { menu: 'Receipt Store', id: 35, path: '/receipt_store?category=store' },
  // //inventory oil report menu
  // { menu: 'Report Oil', id: 41, path: '/report_oil?category=oil' },
  // { menu: 'ROB Oil ', id: 42, path: '/rob_oil?category=oil' },
  // { menu: 'Adjustment Oil', id: 43, path: '/adjustment_oil?category=oil' },
  // { menu: 'Consumption Oil', id: 44, path: '/consumption_oil?category=oil' },
  // { menu: 'Receipt Oil', id: 45, path: '/receipt_oil?category=oil' },
  // { menu: 'Bunker Oil', id: 45, path: '/bunker_oil?category=oil' },


  // { menu: 'Requisition', id: 1, path: '/requisition' },
  // { menu: 'RFQ', id: 11, path: '/rfq' },
  // { menu: 'Quotation', id: 2, path: '/quotation' },
  // /* {menu: 'Quotation Comparison', id: 3, path: '/qc'}, */
  // { menu: 'Purchase Order', id: 4, path: '/purchase-order' },
  // { menu: 'Goods', id: 3, path: '/Goods' },
  // { menu: 'Invoice', id: 5, path: '/invoice' },
  // { menu: 'Exchange Rate History', id: 6, path: '/currency' },
  // { menu: 'Purchase Item History', id: 7, path: '/purchase-item-history' },
  // { menu: 'Purchase Query', id: 8, path: '/purchase-query' },
  // { menu: 'Outstanding Po', id: 9, path: '/outstanding-po' },
  // { menu: 'Supplier Rating', id: 10, path: '/supplier-rating' },
  // { menu: 'Equipment', id: 11, path: '/equipment' },
  // { menu: 'Parts', id: 12, path: '/parts' },
  // { menu: 'Oil Product', id: 13, path: '/oil-product' },
  // { menu: 'Product General', id: 14, path: '/product-general' },
  // { menu: 'Store', id: 15, path: '/store' },
  // { menu: 'History Track', id: 101, path: '/history-track' },
  // { menu: 'Status Track', id: 102, path: '/report-status' },
  // { menu: 'User Listing', id: 103, path: '/report-user' },
  // { menu: 'Request List', id: 104, path: '/report-request' },

  // { menu: 'Audit', id: 111, path: '/audit' },
  // { menu: 'Inspection', id: 112, path: '/inspection' },
  // { menu: 'Meeting', id: 113, path: '/meeting' },
  // { menu: 'Blank Audit', id: 114, path: '/blank_audit_checklist' },
  // { menu: 'Blank Inspection', id: 115, path: '/blank_inspection' },
  // { menu: 'Audit Schedule', id: 116, path: '/audit_schedule' },
  // { menu: 'Inspection Schedule', id: 117, path: '/inspection_schedule' },
  // { menu: 'Damage', id: 118, path: '/damage' },
  // { menu: 'Injury', id: 119, path: '/injury' },
  // { menu: 'Near Miss', id: 120, path: '/near_miss' },
  // { menu: 'Summary', id: 121, path: '/summary' },
  // { menu: 'Drill Due Done', id: 122, path: '/drill_due_done' },
  // { menu: 'Audit NC', id: 123, path: '/audit_nc' },
  // { menu: 'Inspection NC', id: 124, path: '/inspection_nc' },
  // { menu: 'NC/OBS', id: 125, path: '/nc_obs' },
]

export const ACCOUNT_SETUP_MENU = [
  { menu: 'User/Roles', path: '/user_roles', id: 31 },
  { menu: 'Vessel list/Type', path: '/vessel_type', id: 32 },
  { menu: 'UOM/Currency Exchange', path: '/uom_currency', id: 33 },
  { menu: 'Designation', path: '/designation', id: 3 },
  { menu: 'Department', path: '/department', id: 4 },
  { menu: 'Port', path: '/port', id: 34 },
  /* { menu: 'Reset Password', path: '/reset', id: 35 } */
]
export const ACCOUNT_INVENTORY_MENU = [
  { menu: 'Location', path: '/location_setting', id: 35 },
  /* { menu: 'Category/Type', path: '/type', id: 36 }, */
]

export const ACCOUNT_PURCHASE_MENU = [
  { menu: 'General', path: '/company_setting', id: 37 },
  { menu: 'Approval Level', path: '/approve_level_setting', id: 38 },
  { menu: 'Supplier/Repairer', path: '/supplier', id: 39 },
]
export const INVENTORY_MASTER_MENU = [
  { menu: 'Oil Product', path: '/inventory/oil-product', id: 20 },
  { menu: 'Product General', path: '/inventory/productGeneral', id: 21 },
]
export const DASHBOARD_MENU = [
  { menu: 'INVENTORY', path: '/inventory', id: 74 },
  { menu: 'PMS', path: '/pms', id: 73 },
  { menu: 'PURCHASE', path: '/dashboard', id: 72 },
  { menu: 'SAFETY', path: '/safety', id: 75 },
  { menu: 'E-DOC', path: '/e-doc', id: 76 },
]

export const MASTERS_SCREENS_MENU = [
  // { menu: 'User', path: '/user', id: 1 },
  // { menu: 'Roles', path: '/roles', id: 2 },
  // { menu: 'Designation', path: '/designation', id: 3 },
  // { menu: 'Department', path: '/department', id: 4 },
  // { menu: 'Module', path: '/module', id: 5 },
  // { menu: 'Menu', path: '/menu', id: 6 },
  // { menu: 'Role Access', path: '/rolesAccess', id: 7 },
  // { menu: 'Approval', path: '/approval', id: 8 },
  // { menu: 'Product', path: '/product', id: 9 },
  // { menu: 'Approve Level', path: '/approveLevel', id: 10 },
  // { menu: 'Approve Link', path: '/approveLink', id: 11 },
  // { menu: 'User Access', path: '/userAccess', id: 12 },
  { menu: 'Status', path: '/status', id: 13 },
  // { menu: 'Vessel', path: '/vessel', id: 14 },
  { menu: 'Project', path: '/project', id: 15 },
  { menu: 'Grade', path: '/grade', id: 16 },
  //{ menu: 'Supplier', path: '/supplier', id: 17 },
  //{ menu: 'Product Supplier', path: '/productSupplier', id: 18 },
  //{ menu: 'Type', path: '/type', id: 19 },
  // { menu: 'Oil Product', path: '/oilProduct', id: 20 },
  // { menu: 'Product General', path: '/productGeneral', id: 21 },
  // { menu: 'Location', path: '/location', id: 22 },
  // { menu: 'Port', path: '/port', id: 23 },
  // { menu: 'Currency Exchange', path: '/currencyExchange', id: 24 },
  { menu: 'Certificate', path: '/certificate', id: 25 },
  // { menu: 'UOM', path: '/uom', id: 26 },
]
export const CREATE_PMS_INITIAL = {
  id: 0,
  equipment_id: '',
  equipment_name: "",
  maintenance_name: "",
  code: "",
  maintenance_type_id: '',
  maintenance_head_id: '',
  freq_type_id: '',
  frequency: '',
  dept_id: '',
  designation: "",
  pms_type: "S",
  alert_value: '',
  last_done_date: new Date(),
  survey: "",
  actual_man_hour: 0,
  status: "",
  procedure: "",
  vessel_id: 0,
  vessel_type: "",
  alloc_vessel_id: [],
  maintenanceDetail: []
}
export const PMS_JOB_CARD = {
  id: 0,
  head_id: 0,
  assign_by: "",
  assign_to: "",
  approve_by: "",
  planned_from: null,
  planned_to: null,
  remarks: "",
  designation: ""
}
export const ATTACHMENTS = {
  id: 0,
  module: "",
  screen: "",
  trans_id: 0,
  filepath: "",
  filename: "",
  attachment: "",
  format: ""
}
export const PMS_MAINTENANCE = {
  id: 0,
  maintenance_name: '',
  head_id: 0,
  completion_date: new Date(),
  location: "",
  port_name: "",
  status_id: "",
  completed_by: '',
  remarks: "",
  defect: "",
  defect_details: "",
  //below for unshedule
  dept_id: "",
  designation: "",
  maintenance_type_id: "",
  actual_man_hour: 0,
  service_detail: "",
  inv_loc_id: 0
}
export const PMS_RESCHEDULE_REASON_INITIAL = {
  id: 0,
  head_id: 0,
  reschedule_date: null,
  reschedule_reason: "",
  vessel_id: 0,
  remarks: "",
}
export const filterMaintenanceState = {
  details: {
    equipment_name: "",
    equipment_code: "",
    maintenance_name: "",
    maintenance_code: ""
  },
  maintenance_head: "",
  frequency: {
    frequency_type: "",
    frequency_from: null,
    frequency_to: null
  },
  daterange: {
    date_from: null,
    date_to: null
  },
  section: {
    department: "",
    designation: ""
  },
  others: {
    crtical_equipment: 0,
    crtical_maintenance: 0,
    survey_based: 0,
    across_oil_equipment: 0
  }
}
export const safety_checklist = {
  id: "",
  checklist_for: "",
  code: ""
}
export const safety_category = {
  id: "",
  category: "",
  category_type: "",
  code: ""
}
export const audit_master = {
  id: 0,
  audit_name: "",
  frequency_month: "",
  checklist_id: "",
  alert_value: "",
  checklist_item_group: ""
}
export const inspection_master = {
  id: 0,
  inspection_name: "",
  frequency_month: "",
  checklist_forid: "",
  alert_value: "",
  checklist_group: ""
}
export const doc_category_master = {
  id: 0,
  category_name: ""
}
export const vessel_type = {
  id: 0,
  vessel_type: ""
}
export const change_request_master = {
  id: 0,
  changerequest_name: ""
}
export const safety_sub_category = {
  id: "",
  category_id: 0,
  sub_category: "",
  category_type: ""
}
export const audit_checklist_group = {
  id: "",
  checklist_group: "",
  serial_number: "",
  department_id: 0,
  designation_id: 0,
  question: "",
  doc_ref: "",
  procedure: "",
  checklist_type: "",
  trans_id: 0
}
export const audit_checklist_transaction = {
  id: 0,
  audit_id: "",
  checklist_for_id: "",
  checklist_name: "",
  preparation_date: null,
  prepare_by_id: "",
  approve_date: null,
  approve_by_id: 0,
  effective_date: null,
  version: "",
  version_date_change: null,
  vessel_list: "",
  checklist_group_ids: "",
  status: "",
  vessel_id: 0
}
export const inspection_checklist_transaction = {
  id: 0,
  inspection_id: "",
  checklist_for_id: "",
  checklist_name: "",
  preparation_date: null,
  prepare_by_id: "",
  approve_date: null,
  approve_by_id: 0,
  effective_date: null,
  version: "",
  version_date_change: null,
  vessel_list: "",
  checklist_group_ids: "",
  status: "",
  vessel_id: 0
}
export const audit_schedule = {
  id: 0,
  checklist_trans_id: "",
  audit_type_id: "",
  frequency: "",
  alert_days: "",
  schedule_from_date: null,
  schedule_to_date: null,
  schedule_by_id: "",
  location: "",
  oil_major: "",
  audit_internal_members: "",
  audit_external_members: "",
  remarks: "",
  checklist: "",
  status: ""
}
export const inspection_schedule = {
  id: 0,
  checklist_trans_id: "",
  inspection_type_id: "",
  frequency: "",
  alert_days: "",
  schedule_from_date: null,
  schedule_to_date: null,
  schedule_by_id: "",
  location: "",
  oil_major: "",
  inspection_internal_members: "",
  inspection_external_members: "",
  remarks: "",
  checklist: "",
  status: ""
}

export const meeting_name_master = {
  id: 0,
  meeting_id: 0,
  meeting_name: ""
}

export const drill_name_master = {
  id: 0,
  drill_id: 0,
  drill_name: ""
}

export const meeting_scheduled = {
  id: 0,
  meeting_id: '',
  frequency_type: '',
  vessel_name: '',
  frequency: "",
  altervalue: "",
  scheduled_date: null,
  scheduled_by: '',
  remarks: "",
}

export const drill_scheduled = {
  id: 0,
  drill_id: '',
  frequency_type: '',
  vessel_name: "",
  frequency: "",
  altervalue: "",
  scheduled_date: null,
  scheduled_by: '',
  remarks: "",
}

export const meeting_agenda_master = {
  id: 0,
  meeting_id: 0,
  agenda_name: "",
  agenda_type: '',
}
export const audit_record_header = {
  id: 0,
  audit_name_id: "",
  ncobs_no: "",
  audit_number: "",
  scheduled_date: "",
  audit_date: null,
  audit_type_id: "",
  reference_checklist_name: "",
  audit_internal_members: "",
  audit_teamleader_id: "",
  oil_major: "",
  audit_external_member: "",
  status: "",
  remarks: "",
  voyage_no: "",
  auditing_authority: "",
  last_date: null,
  is_auditncobs: false,
  is_unscheduledaudit: false,
  is_auditclosed: false,
  reviewed_by: "",
  reviewed_date: null,
  designation_id: "",
  reviewed_remark: ""
}

export const distribution_ncobs = {
  implement_department_id: 0,
  implement_designation_id: 0,
  implement_user_id: 0,
  verification_department_id: 0,
  verification_designation_id: 0,
  verification_user_id: 0,
  review_department_id: 0,
  review_designation_id: 0,
  review_user_id: 0,
  caseclose_department_id: 0,
  caseclose_designation_id: 0,
  caseclose_user_id: 0
};
export const ncobs_for_auditRecord = {
  id: 0,
  ncobs_hid: 0,
  ncobs_id: "",
  ncobs_rno: "",
  description: "",
  ncobs_status: "",
  ncobs_type: "",
  ism_sms_reference: 0,
  doc_reference: "",
  root_cause: "",
  preventive_action_taken: "",
  suggested_corrective_action: "",
  ncobs_category: 0,
  ncobs_subcategory: 0,
  issue_by: "",
  issue_date: null,
  expected_completion_date: null,
  designation_id: 0,
  issue_to: "",
  responsibility: 0,
}
export const incident_masters = {
  id: 0,
  name: "",
  master_name: "",
  type: "",
  type_id: 0,
  ref_id: 0,
  remarks: "",
  vessel_id: 0,
}

export const incident_damage_info = {
  id: 0,
  incident_cat_id: 0,
  report_number: "",
  status: "",
  report_date: null,
  reported_by_id: 0,
  auditor_inpsector: 0,
  loss_severity_potential: 0,
  damage_date: null,
  local_time: null,
  gmt: null,
  detail_auditor_inpsector: "",
  major_minor: "",
  stoppage_time: null,
  any_issue: "",
  subject: "",
  cause_of_damage: "",
  remedial_action_date: null,
  target_date: null,
  detail_of_damage: "",
  detail_of_corrective_action: "",
  corrective_action_completed_date: "",
  probability_of_reoccurrence: "",
  vessel_id: 0
}
export const damagePlaceModel = {
  id: 0,
  head_id: 0,
  location: 0,
  location_on_Boardship: 0,
  sea_area: 0,
  type_of_contract: 0,
  type_of_damage: 0,
  investgation_done_by: "",
  conclusion: "",
  immediate_cause_of_damage_pg: "",
  immediate_cause_of_damage_pg_reason: "",
  immediate_cause_of_damage_sec: "",
  immediate_cause_of_damage_sec_reason: "",
  basic_cause_personal_factory_group: "",
  basic_cause_personal_factory_group_reason: "",
  basic_cause_job_factory_group: "",
  basic_cause_job_factory_group_reason: "",
  remarks: "",
  vessel_id: 0
}

export const damageControlActionModel = {
  id: 0,
  head_id: 0,
  deficiency_code: 0,
  details_of_item: 0,
  programme: 0,
  programme_reason: 0,
  performance_std: "",
  performance_std_reason: "",
  compliance_exit_std: "",
  compliance_exit_std_reason: "",
  machinery_breakdown: "",
  cost_of_ca: "",
  remarks: "",
  vessel_id: 0
}

export const damageOfficeRemarksModel = {
  id: 0,
  head_id: 0,
  superintendent: "",
  fleet_manager: "",
  operation_manager: "",
  dpa_remarks: "",
  dpa_action: 0,
  closed_out: 0,
  remarks: "",
  vessel_id: 0
}

export const incident_injury_info = {
  id: 0,
  incident_cat_id: 0,
  report_number: "",
  status: "",
  report_date: null,
  reported_by_id: 0,
  subject: "",
  manual_ref: 0,
  loss_severity_potential: 0,
  probability_of_reoccurrence: "",
  location: 0,
  port: 0,
  port_detail: "",
  port_depature: 0,
  port_destination: 0,
  report_prepared_by: "",
  sea_area: 0,
  arrival_date: null,
  dept_date: null,
  latitude: "",
  longitude: "",
  location_onboard_ship: "",
  onboard_date: null,
  location_time: null,
  gmt: null,
  delay_day: 0,
  delay_hour: 0,
  delay_minute: 0,
  course: "",
  speed: "",
  type_of_contract: 0,
  man_hours_to_injury: 0,
  vessel_id: 0
}

export const injuryControlAction = {
  id: 0,
  head_id: 0,
  programme: 0,
  programme_reason: 0,
  performance_std: 0,
  performance_std_reason: 0,
  compliance_exit_std: 0,
  compliance_exit_std_reason: 0,
  description_to_injury: "",
  conclusion: "",
  action_taken_to_prevent: "",
  recommendation: "",
  breach_of_regulation: "",
  name_of_master: "",
  name_of_engineer: "",
  pi_case: "",
  vessel_id: 0
}
export const injuryOfficeRemarks = {
  id: 0,
  head_id: 0,
  superintendent: "",
  fleet_manager: "",
  operation_manager: "",
  dpa_remarks: "",
  dpa_action: 0,
  closed_out: 0,
  remarks: "",
  vessel_id: 0
}
export const injuryCheckList = {
  id: 0,
  head_id: 0,
  checklist_item_group: 0,
  status: "",
  vessel_id: 0
}
export const companyDetails = {
  id: 0,
  company_name: "",
  domain: "",
  address: "",
  city: "",
  state: "",
  zip: "",
  country: 0,
  isdisabled: "N",
}

export const nearmissInfo = {
  id: 0,
  incident_cat_id: 0,
  report_number: "",
  report_date: null,
  reported_by_id: 0,
  near_miss_unsafe: 0,
  designation: 0,
  status: "",
  subject: "",
  description: "",
  loss_severity_potential: 0,
  probability_of_reoccurrence: 0,
  near_miss_date: null,
  location_onboard_ship: "",
  potential_for: 0,
  location_time: null,
  gmt: null,
  deficieny_code: 0,
  details_of_item: 0,
  immediate_cause_of_miss_primary: 0,
  immediate_cause_of_miss_primary_reason: 0,
  immediate_cause_of_miss_sec: 0,
  immediate_cause_of_miss_sec_reason: 0,
  basic_cause_of_miss_pf: 0,
  basic_cause_of_miss_pf_reason: 0,
  basic_cause_of_miss_jf: 0,
  basic_cause_of_miss_jf_reason: 0,
  root_cause: "",
  vessel_id: 0
}

export const nearMissControlAction = {
  id: 0,
  head_id: 0,
  programme: 0,
  programme_reason: 0,
  performance_std: 0,
  performance_std_reason: 0,
  compliance_exit_std: 0,
  compliance_exit_std_reason: 0,
  immedidate_action: "",
  person_involved: "",
  corrective_action_taken: "",
  recommendation: "",
  corrective_action_date: null,
  date_planned_action: null,
  date_completed_action: null,
  name_of_master: "",
  name_of_engineer: ""
}
export const nearMissOfficeRemarks = {
  id: 0,
  head_id: 0,
  superintendent: "",
  fleet_manager: "",
  operation_manager: "",
  dpa_remarks: "",
  dpa_action: 0,
  closed_out: 0,
  remarks: "",
  vessel_id: 0
}

export const vessel_inspection = {
  id: 0,
  from_date: null,
  to_date: null,
  inspection_by: 0,
  designation: 0,
  location_id: 0,
  port_id: 0,
  status_id: 0,
  comments: "",
  overall_condition: "",
  structural_condition: "",
  operational_condition: "",
  performance: "",
  ps_accommodation: "",
  ps_communication_equipment: "",
  ps_documentation: "",
  ps_eletrical: "",
  ps_environment_protection: "",
  ps_hull_desck: "",
  ps_machinery: "",
  ps_navigation_equipment: "",
  ps_safety: "",
  ps_port: "",
  ps_certificates: ""
}

export const approvalLevelValues = {
  id: 0,
  approval_from: 0,
  approval_to: 0,
  verify_from: 0,
  verify_to: 0
}
export const FinanceLabel = {
  vat: "VAT %",
  discount: "Discount %"
}