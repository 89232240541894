import { createAsyncThunk,createEntityAdapter, createSlice } from "@reduxjs/toolkit"
import { toast } from "react-toastify"
import { GET_CERTIFICATE, GET_CERTIFICATE_ID, SAVE_CERTIFICATE } from "../constant/CommonApiUrl"
import { API_SERVICE } from "../Services/CommonApi"
import { ConvertJson } from "../helper"

const ADD_CERTIFICATE='CERTIFICATE/addCERTIFICATEApi'
const UPDATE_CERTIFICATE = 'CERTIFICATE/udpateCERTIFICATEApi'
const FETCH_CERTIFICATE='CERTIFICATE/fetchCERTIFICATEApi'
const DELETE_CERTIFICATE = 'CERTIFICATE/deleteCERTIFICATEApi'
const GET_CERTIFICATE_ID_ACTION='CERTIFICATE/getCertificatebyId'

export const addCERTIFICATEApi =createAsyncThunk(ADD_CERTIFICATE,
    async(args, thunkAPI)=>{
        try{
            let response = await API_SERVICE.post(SAVE_CERTIFICATE, args['data'], "CREATE");             
            return response;
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
})

export const updateCERTIFICATEApi = createAsyncThunk(UPDATE_CERTIFICATE,
    async(args, thunkAPI)=>{
        try{
            let response = await API_SERVICE.post(SAVE_CERTIFICATE, args['data'], "UPDATE");
            response = response.data.result.data[0]
            return response;
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
})

export const deleteCERTIFICATEApi = createAsyncThunk(DELETE_CERTIFICATE,
    async (args, thunkAPI) => {
        try{
            const toDelete = thunkAPI.getState().root.CERTIFICATE.list.find(ele => ele.id === args['id']);   
            const response = await API_SERVICE.post(SAVE_CERTIFICATE, {...toDelete, isdisabled: "Y"});
            return response.data.result.data[0];
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
    }
)

export const fetchCERTIFICATEApi = createAsyncThunk(FETCH_CERTIFICATE,
    async(arg,thunkAPI)=>{
        try{
            console.log(arg)
            const {id }=thunkAPI.getState().root.common.vessel_detailByID
            let response = arg?await API_SERVICE.get(`${GET_CERTIFICATE}?vessel_id=${id}&${arg}`):await API_SERVICE.get(`${GET_CERTIFICATE}?vessel_id=${id}`)            
            return response
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
    }
)
export const getCertificatebyId = createAsyncThunk(GET_CERTIFICATE_ID_ACTION,
    async(arg,thunkAPI)=>{
        try{             
            return await API_SERVICE.get(`${GET_CERTIFICATE_ID}?id=${arg}`)
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
    }
)

const initialState={
    list:[],
    certificate_backup:[],
    editCERTIFICATE:{
        repository:[]
    },   
    isUpdated: true 
}

export const CERTIFICATESlice=createSlice({
    name:'CERTIFICATE',
    initialState,
    reducers:{
        addCERTIFICATE:(state,action)=>{
            state.list=[...state.list,action.payload]
        },
        delCERTIFICATE:(state,action)=>{
            state.list=state.list.filter(x=>x.id!==action.payload)
        },
        loadEditCERTIFICATE:(state,action)=>{
            state.editCERTIFICATE=action.payload
        },
        clearEditCERTIFICATE:(state,action)=>{
            state.editCERTIFICATE={}
        },
        updateCERTIFICATE:(state,action)=>{
            let index=state.list.findIndex(x=>x.id===action.payload.id)
            state.list[index]=action.payload
        },    
        searchCertificate:(state,action)=>{
            if(action.payload!==""){
                let filterData=[]
                state.certificate_backup.map(el=>el.certificate_name.toLowerCase().search(action.payload.toLowerCase())!==-1&&filterData.push(el))
                state.list=filterData
            }else{
                state.list=state.certificate_backup
            }
        },        
        resetCERTIFICATE:()=>initialState
    },
    extraReducers:(builder)=>{
        builder
        .addCase(getCertificatebyId.fulfilled,(state,action)=>{
            if(action.payload.data.result.status){
                let obj=action.payload.data.result.data[0]
                obj.repository = obj.repository !== null ? ConvertJson(obj.repository) : []
                state.editCERTIFICATE=obj
            }else{
                toast.error(action.payload.data.result.message)
            }
        })
        .addCase(addCERTIFICATEApi.fulfilled,(state,action)=>{
            if(action.payload.data.result.status){
                toast.success(action.payload.data.result.message)
            }else{
                toast.error(action.payload.data.result.message)
            }           
        })
        .addCase(updateCERTIFICATEApi.fulfilled,(state,action)=>{
            state.list = state.list.map(x => x.id === action.payload.id ? action.payload: x);
            toast.success('Succesfully updated');
        })
        .addCase(fetchCERTIFICATEApi.fulfilled, (state, action) => {
            if(action.payload.data.result.status){
                state.list=action.payload.data.result.data
                state.certificate_backup=action.payload.data.result.data
            }else{
                toast.error(action.payload.data.result.message)
            } 
        })
        .addCase(deleteCERTIFICATEApi.fulfilled, (state, action) => {
            state.list=state.list.filter(x=>x.id!==action.payload.id);
            toast.success('Succesfully deleted');
        })
    } 
})
export const {addCERTIFICATE,delCERTIFICATE,loadEditCERTIFICATE,clearEditCERTIFICATE,updateCERTIFICATE,resetCERTIFICATE,searchCertificate}=CERTIFICATESlice.actions
export default CERTIFICATESlice.reducer