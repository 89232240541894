/* eslint-disable no-prototype-builtins */
import React from "react"
export const JSONtoTABLE = (list, removeField = []) => {
  let head = []
  let headObj = []
  let bodyObj = [...list]
  let body = []
  head = Object.keys(list[0]).filter(el => !removeField.includes(el)).map(el => el.toUpperCase().replaceAll('_', ' '))
  Object.keys(list[0]).filter(el => !removeField.includes(el)).map((el) => {
    headObj.push({
      name: el.toUpperCase().replaceAll('_', ' '),
      // name: el.toUpperCase(),
      key: el,
    })
  })
  bodyObj = bodyObj.map(el =>
    Object.keys(el).filter(key =>
      removeField.every(el => el !== key)
    ).reduce((obj, key) => {
      obj[key] = el[key];
      return obj;
    }, {}
    )
  )
  head = head.map((x, i) => <th key={i}>{x}</th>)
  body = bodyObj.map((el, i) => Object.keys(el).map(x => el[x])).map((el, i) =>
    <tr key={i}>
      {el.map((x, i) => <td key={i}>{x}</td>)}
    </tr>)
  return { tblHead: head, tblBody: body, bodyObj, headObj }
}

export function getCapitalizedKeys(obj) {
  return Object.keys(obj).map((key) =>
    key.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase())
  );
}

const capitalize = (str) => {
  return str.replace(/_/g, ' ').split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const constructCSVData = (data, removedKeys) => {
  let response = []
  response = data.map(item => {
    const mappedItem = {};
    for (const key in item) {
      if (item.hasOwnProperty(key) && !removedKeys.includes(key)) {
        mappedItem[capitalize(key)] = item[key];
      }
    }
    return mappedItem;
  });
  return response

}

export function removeKeys(obj, keysToRemove) {
  return Object.keys(obj).reduce((acc, key) => {
    if (!keysToRemove.includes(key)) {
      acc[key] = obj[key];
    }
    return acc;
  }, {});
}

export const capitalizeWord = (str) => {
  return str.replace(/\b\w/g, (char) => char.toUpperCase());
};
export const removeKeysFromArrayofObject = (dataArray, removeKeys) => dataArray.map(obj =>
  Object.fromEntries(Object.entries(obj).filter(([key]) => !removeKeys.includes(key)))
).map(obj => {
  const newObj = {};
  for (const key in obj) {
      if (Object.hasOwnProperty.call(obj, key)) {
          const newKey = capitalizeWord(key.replace(/_/g, ' '));
          newObj[newKey] = obj[key];
      }
  }
  return newObj;
});

export const exportData = (data, fileName, type) => {
  // Create a link and download the file
  const blob = new Blob([data], { type });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
};

export const getFirstLetterOfEveryString = (str = "") => {
  let matches = str.match(/\b(\w)/g); // ['J','S','O','N']
  let acronym = matches.join(""); // JSON
  return acronym;
};