import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import {toast} from "react-toastify"
import {GET_MODULE_ON_PARENT, GET_ROLE, SAVE_ROLE} from "../constant/CommonApiUrl";
import {API_SERVICE} from "../Services/CommonApi"
import {setTypeFieldsIsUpdated} from "./typeFieldsSlice"
import {ConvertJson} from "../helper";
import {ValidateResponse} from "../Services/Support";
import {commonPayloadFields} from "../helper/storage";

const ADD_ROLES = 'roles/addRolesApi'
const UPDATE_ROLES = 'roles/updateRolesApi'
const FETCH_ROLES = 'roles/fetchRolesApi'
const FETCH_ROLES_ID = 'roles/fetchRolesApiById'
const DELETE_ROLES = 'roles/deleteRolesApi'
const MODULE_LIST_BY_PARENT = 'roles/MODULE_LIST_BY_PARENT'

export const addRolesApi =createAsyncThunk(ADD_ROLES,
    async(args, thunkAPI)=>{
        try{
            let response = await API_SERVICE.post(SAVE_ROLE, {...args.data});
            thunkAPI.dispatch(setTypeFieldsIsUpdated(true));
            console.log(response.data.result.status)
            if(response.data.result.status) {
                thunkAPI.dispatch(fetchRolesApi());
                return response
            }
            return response;
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
})

export const updateRolesApi=createAsyncThunk(UPDATE_ROLES,
    async(args, thunkAPI)=>{
        try{
            let response = await API_SERVICE.post(SAVE_ROLE, args['data'], "UPDATE");
            if(response.data.result.status) {
                thunkAPI.dispatch(fetchRolesApi());
                return response
            }
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
})

export const deleteRolesApi = createAsyncThunk(DELETE_ROLES, 
    async (args, thunkAPI) => {
        try{
            const toDelete = thunkAPI.getState().root.roles.list.find(ele => ele.id === args['id']);   
            let response = await API_SERVICE.post(SAVE_ROLE, {...toDelete, isdisabled: "Y", ...commonPayloadFields});
            if(response.data.result.status) {
                thunkAPI.dispatch(fetchRolesApi());
                response = response.data.result.data[0];
            }
            
            // This field has a reference at TypeFields, so we need to refetch it
            thunkAPI.dispatch(setTypeFieldsIsUpdated(true));
            
            return response;
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
    }
)

export const fetchRolesApi =createAsyncThunk(FETCH_ROLES,
    async(arg,thunkAPI)=>{
        try{
            const response = await API_SERVICE.get(GET_ROLE, {...arg});
            return response.data.result.data;
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
    }
)
export const fetchRolesApiById =createAsyncThunk(FETCH_ROLES_ID,
    async(arg,thunkAPI)=>{
        try{
            const response = await API_SERVICE.get(GET_ROLE, {...arg});
            return response.data.result.data;
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
    }
)
export const getModuleByParent =createAsyncThunk(MODULE_LIST_BY_PARENT,
    async(arg,thunkAPI)=>{
        try{
            const response = await API_SERVICE.get(GET_MODULE_ON_PARENT, {...arg});
            return response.data.result.data;
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
    }
)

const initialState={
    list:[],
    moduleList:{},
    editRoles:{},
    isLoading:false,
    isUpdated:true, 
}
export const rolesSlice=createSlice({
    name:'roles',
    initialState,
    reducers:{
        addRoles:(state,action)=>{
            state.list=[...state.list,action.payload]
        },
        delRoles:(state,action)=>{
            state.list=state.list.filter(x=>x.id!==action.payload)
        },
        loadEditRoles:(state,action)=>{
            state.editRoles=action.payload
        },
        clearEditRoles:(state,action)=>{
            state.editRoles={}
            state.moduleList={}
        },
        updateRoles:(state,action)=>{
            let index=state.list.findIndex(x=>x.id===action.payload.id)
            state.list[index]=action.payload
        },
        changeProcessModel:(state,{payload})=>{
            const data = state.moduleList.Process
            const values = []
            if(payload.id === 'all') {
                data.map((v) => {
                    values.push(Object.assign({}, {...v, [payload.key]: payload.checked ? 1 : 0}))
                })
            } else {
                data.map((v) => {
                    if (v.module_id === payload.id) {
                        values.push(Object.assign({}, {...v, [payload.key]: payload.checked ? 1 : 0}))
                    } else
                        values.push(Object.assign({}, {...v}))
                })
            }
            state.moduleList = {...state.moduleList, Process: values}
        },
        changeReportsModel:(state,{payload})=>{
            const data = state.moduleList.Reports
            const values = []
            if(payload.id === 'all') {
                data.map((v) => {
                    v.SubModule.map((e, i) => {
                        values.push(Object.assign({}, {...e, [payload.key]: payload.checked ? 1 : 0}))
                    })
                    v.SubModule = values
                })
            } else {
                data.map((v) => {
                    if (v.module_id === payload.id) {
                        v.SubModule[payload.index] = {...v.SubModule[payload.index], [payload.key]: payload.checked ? 1 : 0}
                    }
                })
            }
            
            state.moduleList = {...state.moduleList, Reports: data}
        },
        resetRoles:()=>initialState
    },
    
    extraReducers:(builder)=>{
        builder       
        .addCase(addRolesApi.fulfilled,(state, {payload})=>{
            if (payload.data.result.status) {
                toast.success('Roles added successfully');
            }
            ValidateResponse(payload)
        })
        .addCase(updateRolesApi.fulfilled,(state,{payload})=>{
            if (payload.data.result.status) {
                toast.success('Roles updated successfully');
            }
            ValidateResponse(payload);
        })
        .addCase(fetchRolesApi.fulfilled, (state, action) => {
            state.list = action.payload;
            state.isUpdated = false;
        }).addCase(fetchRolesApiById.fulfilled, (state, action) => {
             state.editRoles = action.payload[0]
        }).addCase(getModuleByParent.fulfilled, (state, action) => {
            const response = action.payload[0];
            if (response) {
                let Process = response?.Process ? ConvertJson(response.Process) : []
                let Reports = response?.Reports ? ConvertJson(response.Reports) : []
                response['Process'] = Process
                response['Reports'] = Reports
                state.moduleList = response
            }
            state.isUpdated = false;
        })
        .addCase(deleteRolesApi.fulfilled, (state, {payload}) => {
            if (Object.keys(payload).length) {
                toast.success('Successfully deleted');
            } else {
                toast.error(payload.data.result.message)
                return []
            }

        })     
    }        
})

export const {
    addRoles,delRoles,loadEditRoles,
    clearEditRoles,updateRoles,resetRoles,
    changeProcessModel, changeReportsModel
} = rolesSlice.actions
export default rolesSlice.reducer