import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { toast } from "react-toastify"
import { GET_PROJECT, SAVE_PROJECT } from "../constant/CommonApiUrl"
import { dateTimeToDate } from "../helper/dateUtil";
import { API_SERVICE } from "../Services/CommonApi";

const ADD_PROJECT = 'project/addProjectApi'
const UPDATE_PROJECT = 'project/updateProjectApi'
const FETCH_PROJECT = 'project/fetchProjectApi'
const DELETE_PROJECT = 'project/deleteProjectApi'

export const addProjectApi =createAsyncThunk(ADD_PROJECT,
    async(args, thunkAPI)=>{
        try{
            let response = await API_SERVICE.post(SAVE_PROJECT, args['data'], "CREATE");
            response = response.data.result.data[0];
            response.project_created_at =  dateTimeToDate(response.project_created_at); 
            return response;
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
})

export const updateProjectApi = createAsyncThunk(UPDATE_PROJECT,
    async(args, thunkAPI)=>{
        try{
            let response = await API_SERVICE.post(SAVE_PROJECT, args['data'], "UPDATE");
            response = response.data.result.data[0];
            response.project_created_at =  dateTimeToDate(response.project_created_at); 
            return response;
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
})

export const deleteProjectApi = createAsyncThunk(DELETE_PROJECT, 
    async (args, thunkAPI) => {
        try{
            const toDelete = thunkAPI.getState().root.project.list.find(ele => ele.id === args['id']);   
            const response = await API_SERVICE.post(SAVE_PROJECT, {...toDelete, isdisabled: "Y"});
            return response.data.result.data[0];
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
    }
)

export const fetchProjectApi = createAsyncThunk(FETCH_PROJECT,
    async(arg,thunkAPI)=>{
        try{
            let response = await API_SERVICE.get(GET_PROJECT);
            response = response.data.result.data;
            response = response.map(prj => ({...prj, 
                project_created_at: dateTimeToDate(prj.project_created_at)
            }))
            return response
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
    },
    {
        condition: (arg, { getState, extra }) => {          
          const isUpdated = getState().root.project.isUpdated                         
          if(!isUpdated){
            console.log('enable fetch condition in reducer')
            return false
          }
        },
      }
)

const initialState={
    list:[],
    editProject:{},
    isUpdated: true    
}
export const ProjectSlice = createSlice({
    name:'project',
    initialState,
    reducers:{
        addProject:(state,action)=>{
            state.list=[...state.list,action.payload]
        },
        delProject:(state,action)=>{
            state.list=state.list.filter(x=>x.id!==action.payload)
        },
        loadEditProject:(state,action)=>{
            state.editProject=action.payload
        },
        clearEditProject:(state,action)=>{
            state.editProject={}
        },
        updateProject:(state,action)=>{
            let index=state.list.findIndex(x=>x.id===action.payload.id)
            state.list[index]=action.payload
        },               
        resetProject:()=>initialState
    },
    extraReducers:(builder)=>{
        builder
        .addCase(addProjectApi.fulfilled,(state,action)=>{
            state.list=[...state.list,action.payload];
            toast.success('Succesfully added');
        })
        .addCase(updateProjectApi.fulfilled,(state,action)=>{
            state.list = state.list.map(x => x.id === action.payload.id ? action.payload: x);
            toast.success('Succesfully updated');
        })
        .addCase(fetchProjectApi.fulfilled, (state, action) => {
            state.list = action.payload;
            state.isUpdated = false;
        })
        .addCase(deleteProjectApi.fulfilled, (state, action) => {
            state.list=state.list.filter(x=>x.id!==action.payload.id);
            toast.success('Succesfully deleted');
        })
    }   
})
export const {addProject,delProject,loadEditProject,clearEditProject,updateProject,resetProject} = ProjectSlice.actions
export default ProjectSlice.reducer