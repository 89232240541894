import React from 'react';
import { FormControl, Image } from 'react-bootstrap';
import styled from 'styled-components';
import CalendarIcon from '../../assets/images/CalendarIcon.svg';

const InputIconsContainer = styled.div`  
  position: relative;  
`;

const Icon = styled(Image)`
    position: absolute;
    padding: 10px;
    top: 50%;
    transform: translateY(-50%);
    ${props => props.iconPosition === 'left' ? 'left: 0;' : 'right: 0;'}
`;

const InputField = styled(FormControl)`  
  width: 100%; /* Adjust width to accommodate icon */  
  ${props => props.iconPosition === 'left' ? 'padding-left:40px;' : 'padding-left:5px;'}   
`;

export const CustomDatePickerInput = ({ value, onClick, iconPosition, disabled, placeholder }) => (
  <InputIconsContainer>
    <Icon src={CalendarIcon} alt={'image'} iconPosition={iconPosition}></Icon>
    <InputField iconPosition={iconPosition} type="text" onClick={onClick} value={value} placeholder={placeholder} disabled={disabled || false} readOnly />
  </InputIconsContainer>
);
