import React from 'react';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';

export const NotificationTabContainer = styled.div`
  .nav-tabs {
    display: flex;
    justify-content: center;
    background-color: #f7fbfe;
    border-radius: 10px;
    padding: 2px;
    border:none !important;
  }

  .nav-item {
    display: flex;
    align-items: center; /* Center vertically */
  }

  .nav-item .nav-link.active {
    color: #fff;
    background: #002694;
    border-radius: 13.899px;
    font-size: 8.002px !important;
    width: 51.476px;
    height: 25.413px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.24px;
    align-items: center;
  }

  .nav-item .nav-link {
    color: #002694;
    font-size: 8.002px !important;
    width: 51.476px;
    height: 25.413px;
    border-radius: 13.899px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.24px;
  }
`;

export const HorizontalLine = styled.div`
  width: 210.047px;
  height: 1px;
  background-color: rgba(0, 38, 148, 0.50);
`;
export const ViewAllButton = styled(Button)`
&&&{
    background: #002694 !important;
    font-family: Poppins !important;
    font-size: 6.001px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: normal !important;
    letter-spacing: -0.18px !important;
}
`;
export const MarkReadButton = styled(ViewAllButton)`
&&&{
    color: #002694 !important;
    background:#fff !important;    
}
`;
export const NotificationMessageContainer = styled.div`
    padding:5px;
    width: 197.256px;
    height: 36.033px;
    flex-shrink: 0;
    border-radius: 2.059px;
    background: #F7FBFE;
    .MessageHeader{
        color: #002694;
        font-family: Poppins;
        font-size: 8.002px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.24px;
    }
    .datetime{
        color: #3EB3FF;
        font-family: Poppins;
        font-size: 6.001px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.18px;
    }
`;
