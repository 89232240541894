import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { toast } from "react-toastify"
import { GET_PORT, SAVE_PORT } from "../constant/CommonApiUrl";
import { API_SERVICE } from "../Services/CommonApi";

const ADD_PORT='port/addPortApi'
const UPDATE_PORT = 'port/updatePortApi'
const FETCH_PORT='port/fetchPortApi'
const DELETE_PORT = 'port/deletePortApi'

export const addPortApi =createAsyncThunk(ADD_PORT,
  async(args, thunkAPI)=>{
    try{
      let response = await API_SERVICE.post(SAVE_PORT, args['data'], "CREATE");      
      thunkAPI.dispatch(fetchPortApi())
      return response;
    }catch(err){
      return thunkAPI.rejectWithValue(err.message)
    }
  })

export const updatePortApi = createAsyncThunk(UPDATE_PORT,
  async(args, thunkAPI)=>{
    try{
      let response = await API_SERVICE.post(SAVE_PORT, args['data'], "UPDATE");
      thunkAPI.dispatch(fetchPortApi())     
      return response;
    }catch(err){
      return thunkAPI.rejectWithValue(err.message)
    }
  })

export const deletePortApi = createAsyncThunk(DELETE_PORT,
  async (args, thunkAPI) => {
    try{
      const toDelete = thunkAPI.getState().root.port.list.find(ele => ele.id === args['id']);
      const response = await API_SERVICE.post(SAVE_PORT, {...toDelete, isdisabled: "Y"});
      thunkAPI.dispatch(fetchPortApi())
      return response
    }catch(err){
      return thunkAPI.rejectWithValue(err.message)
    }
  }
)

export const fetchPortApi = createAsyncThunk(FETCH_PORT,
  async(arg,thunkAPI)=>{
    try{
      let response = await API_SERVICE.get(GET_PORT);      
      return response
    }catch(err){
      return thunkAPI.rejectWithValue(err.message)
    }
  }
)

const initialState={
  list:[],
  editPort:{},  
}
export const portSlice=createSlice({
  name:'port',
  initialState,
  reducers:{
    addPort:(state,action)=>{
      state.list=[...state.list,action.payload]
    },
    delPort:(state,action)=>{
      state.list=state.list.filter(x=>x.id!==action.payload)
    },
    loadEditPort:(state,action)=>{
      state.editPort=action.payload
    },
    clearEditPort:(state,action)=>{
      state.editPort={}
    },
    updatePort:(state,action)=>{
      let index=state.list.findIndex(x=>x.id===action.payload.id)
      state.list[index]=action.payload
    },
    resetPort:()=>initialState
  },
  extraReducers:(builder)=>{
    builder
      .addCase(addPortApi.fulfilled,(state,action)=>{        
        if(action.payload.data.result.status){            
            toast.success(action.payload.data.result.message);
        }else{ toast.error(action.payload.data.result.message)}        
      })      
      .addCase(updatePortApi.fulfilled,(state,action)=>{
        if(action.payload.data.result.status){            
            toast.success(action.payload.data.result.message);
        }else toast.error(action.payload.data.result.message)         
      })
      .addCase(fetchPortApi.fulfilled, (state, action) => {        
        if(action.payload.data.result.status){
            state.list =action.payload.data.result.data;                
        }else toast.error(action.payload.data.result.message)         
      })
      .addCase(deletePortApi.fulfilled, (state, action) => {
        if(action.payload.data.result.status){                
            toast.success(action.payload.data.result.message);
        }else toast.error(action.payload.data.result.message)        
      })
  }
})
export const {addPort,delPort,loadEditPort,clearEditPort,updatePort,resetPort}=portSlice.actions
export default portSlice.reducer