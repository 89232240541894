import { toast } from "react-toastify";
export const ValidateResponse = (arg) => {
    if (arg.data.result.status) {
        return arg.data.result.data?arg.data.result.data:[]
    } else {
        toast.error(arg.data.result.message)
        return []
    }
}
export const AddEditResponse = (arg) => {
    if (arg.data.result.status) {
        toast.success(arg.data.result.message)
        return arg.data.result.data
    } else {
        toast.error(arg.data.result.message)
        return []
    }
}