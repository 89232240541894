export const IncidentMasterName = {
    incident_damage: 'Damage',
    incident_type_pollution: 'Type Of Pollution',
    incident_collision_severity: 'Collision Severity Type',
    incident_fire_severity: 'Fire Severity Type',
    incident_body_injured: 'Part Of Body Injured',
    incident_nature_injured: 'Nature Of Injury',
    incident_near_unsafe: 'Near Miss Due to Unsafe',
    incident_ship_board: 'Ship Board Location',
    incident_sea_area: 'Sea Area',
    incident_checklist_item_group: 'Incident Checklist Item Group',
    incident_type_accident: 'Type Of Accident',
    incident_rank_category: 'Rank Category',
    incident_type_contact: 'Type Of Contact',
    incident_category: 'Incident Category',
    injury_due: 'Injury Due to',
    reported_by: 'Reported by',
    audit_inspect: 'Auditor / Inspector',
    deficiency: 'Deficiency Code',
    details_deficiency: 'Details of Deficiency',
    idn: 'Injury/Damage/Near Miss Checklist'
}