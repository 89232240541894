import moment from "moment";

// Utility function to parse datetime to date
export const dateTimeToDate = (dateString, format="y-m-d") => {
    const date = new Date(dateString);
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();

    if (dt < 10) dt = '0' + dt;
    if (month < 10) month = '0' + month;

    return format.replace("y", year).replace("m", month).replace("d", dt);
}

export const compareDate=(arg1,arg2)=>{
    let result=''
    if(new Date(arg1).getTime()===new Date(arg2).getTime()){
        result= 0
    }else if(new Date(arg1).getTime()<new Date(arg2).getTime()){
        result= 1
    }else if(new Date(arg1).getTime()>new Date(arg2).getTime()){
        result= -1
    }else{
        result= null
    }
    return result
}

export const ISOtoLocal=(arg)=>new Date(`${arg}Z`).toLocaleString()
export const localtoISO=(arg)=>new Date(arg.toString()).toISOString()
export const dateFormat=(arg)=>moment(`${arg}Z`).format('DD/MM/YYYY')

export const convertISOtoDatePicker = (arg) => {
    return new Date(arg).toLocaleString('en-US', {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        timeZoneName: 'short',
    })
}
export function convertLocalTimeToGMT(localTime) {
    // Create a Date object from the local time string
    const localDate = new Date(localTime);

    // Get the hours, minutes, and seconds in GMT
    const hoursGMT = localDate.getUTCHours();
    const minutesGMT = localDate.getUTCMinutes();
    const secondsGMT = localDate.getUTCSeconds();

    // Format the time as HH:mm:ss
    const formattedTime = `${hoursGMT.toString().padStart(2, '0')}:${minutesGMT.toString().padStart(2, '0')}:${secondsGMT.toString().padStart(2, '0')}`;

    return formattedTime; // Output: "06:00:00"
}
