import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { setCalendarPmsId } from '../../../features/pmsSlice'

function CalendarTooltip(props) {
    const { maintenanceName, dueStatus, id, type,handleData } = props
    const dispatch = useDispatch()
    const setScreenData = () => {
        switch (type) {
            case 'OTHER':
                handleData()
                break;
            default:
                dispatch(setCalendarPmsId(id))
        }

    }
    return (
        <>
            <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip>{maintenanceName}</Tooltip>}
            >
                <div
                    className={dueStatus === 'DUE' ? 'due' : dueStatus === 'OVERDUE' ? 'overdue' : dueStatus === 'IN PROGRESS' && 'inprogress'}
                    role="presentation"
                    onClick={() => setScreenData()}
                >
                    {maintenanceName}
                </div>
            </OverlayTrigger>
        </>
    )
}

export default CalendarTooltip