import styled from 'styled-components';

export const PmsDetailStyle=styled.div`
    position: absolute;
    top: 100px; 
    left: 10px; 
    right: 10px;
@media screen and (min-width: 1920px) {    
    /* Additional styles for 1920x1080 screens */
    position: absolute;
    top: 150px; 
    left: 10px; 
    right: 10px;
    width: 100%;
  }  
`;
export const VesselTypeStyle=styled.div`
position: absolute;
    top: 225px; 
    left: 10px; 
    right: 10px;
@media screen and (min-width: 1920px) {    
    /* Additional styles for 1920x1080 screens */
    position: absolute;
    top: 300px; 
    left: 10px; 
    right: 10px;
    width: 100%;
  }  
`