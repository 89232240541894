import {createAsyncThunk} from "@reduxjs/toolkit";
import {API_SERVICE} from "../../Services/CommonApi";
import * as APIS from "../../constant/CommonApiUrl";
import {ApiCallWithVesselId} from "./MeetingThunk";
import {getAuditInspectionScheduleReports, getNCReports} from "../../constant/CommonApiUrl";

export const fetchSafetyReports = createAsyncThunk(
    'safetyReports/fetchSafetyReports',
    async (type, thunkAPI) => {
        try {
            return await ApiCallWithVesselId(APIS.getAuditInspection(''), type, thunkAPI, 'get');
        } catch (error) {
            thunkAPI.rejectWithValue(error)
        }
    }
);
export const fetchSafetyReportsById = createAsyncThunk(
    'safetyReports/fetchSafetyReportsById',
    async (type, thunkAPI) => {
        try {
            const urlParam = type?.view ? type?.view : ''
            delete type?.view
            // console.log(APIS.getAuditInspection(urlParam), urlParam)
            return await ApiCallWithVesselId(APIS.getAuditInspection(urlParam), type, thunkAPI, 'get');
        } catch (error) {
            thunkAPI.rejectWithValue(error)
        }
    }
);

export const fetchAuditNcReport = createAsyncThunk(
    'safetyReports/fetchauditNc',
    async (type, thunkAPI) => {
        try {
            return await ApiCallWithVesselId(APIS.SAFETY_AUDIT_NC_REPORTS, type, thunkAPI, 'get');
        } catch (error) {
            thunkAPI.rejectWithValue(error)
        }
    }
);

export const fetchAuditNcReportById = createAsyncThunk(
    'safetyReports/fetchauditNcById',
    async (type, thunkAPI) => {
        try {
            const urlParam = type?.view ? type?.view : ''
            delete type?.view
            return await ApiCallWithVesselId(APIS.getAuditNCReports(urlParam), type, thunkAPI, 'get');
        } catch (error) {
            thunkAPI.rejectWithValue(error)
        }
    }
);

export const fetchInspectionNCReport = createAsyncThunk(
    'safetyReports/fetchinspectionNc',
    async (type, thunkAPI) => {
        try {
            return await ApiCallWithVesselId(APIS.getAuditInspectionScheduleReports(''), type, thunkAPI, 'get');
        } catch (error) {
            thunkAPI.rejectWithValue(error)
        }
    }
);
export const fetchInspectionNCReportById = createAsyncThunk(
    'safetyReports/fetchinspectionNcById',
    async (type, thunkAPI) => {
        try {
            const urlParam = type?.view ? type?.view : ''
            delete type?.view
            return await ApiCallWithVesselId(APIS.getAuditInspectionScheduleReports(urlParam), type, thunkAPI, 'get');
        } catch (error) {
            thunkAPI.rejectWithValue(error)
        }
    }
);

export const fetchNCobsReport = createAsyncThunk(
    'safetyReports/fetchinspectionNc',
    async (type, thunkAPI) => {
        try {
            return await ApiCallWithVesselId(APIS.getNCReports(''), type, thunkAPI, 'get');
        } catch (error) {
            thunkAPI.rejectWithValue(error)
        }
    }
);

export const fetchNCobsReportById = createAsyncThunk(
    'safetyReports/fetchinspectionNcById',
    async (type, thunkAPI) => {
        try {
            const urlParam = type?.view ? type?.view : ''
            delete type?.view
            return await ApiCallWithVesselId(APIS.getNCReports(urlParam), type, thunkAPI, 'get');
        } catch (error) {
            thunkAPI.rejectWithValue(error)
        }
    }
);