import React from "react";
import { Col, Row, Table } from "react-bootstrap";
import { getMissingMonthData } from "./Support";
import { AnalyticsList } from "../STYLE/PMSStyle";

function VesselTableData(props) {
  const { vesselData, vesselName, VesselColor } = props;
  const tblContent =
    vesselData?.vessel_data &&
    vesselData?.vessel_data.map((el, i) => (
      <tr key={i}>
        <td>{el.department_name}</td>
        {getMissingMonthData(el.department_data).map((el, j) => (
          <>
            <td>{el.completed}</td>
          </>
        ))}
      </tr>
    ));
  return (
    <>
      <Row>
        <Col className={""} sm={12}>
          <div className="bg-white p-3 col-md-12">
            <AnalyticsList>
              <div className={"d-flex justify-content-between"}>
                <div className={"box me-2"}>
                  <div className={VesselColor}></div>
                  <span className={"text"}>{vesselName}</span>
                </div>
              </div>
            </AnalyticsList>

            <Table>
              <thead>
                <th style={{ color: "#3486D1" }}>Department Name</th>
                <th>JAN</th>
                <th>FEB</th>
                <th>MAR</th>
                <th>APR</th>
                <th>MAY</th>
                <th>JUN</th>
                <th>JUL</th>
                <th>AUG</th>
                <th>SEP</th>
                <th>OCT</th>
                <th>NOV</th>
                <th>DEC</th>
              </thead>
              <tbody>
                {tblContent}
                {/* {vessel_chart.length > 0 && <tr>
                            <td>Total</td>
                            {
                                vessel_chart.map((el, i) => <td key={i}>{`${el.completed}(${el.percentage})`}</td>)
                            }
                        </tr>} */}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default VesselTableData;
