import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit"
import { toast } from "react-toastify"
import { GET_PRODUCT_TYPE, SAVE_PRODUCT } from "../constant/CommonApiUrl";
import { API_SERVICE } from "../Services/CommonApi"
import { setTypeFieldsIsUpdated } from "./typeFieldsSlice"

const ADD_PRODUCT='product/addProductApi'
const UPDATE_PRODUCT = 'product/updateProductApi'
const FETCH_PRODUCT='product/fetchProductApi'
const DELETE_PRODUCT = 'product/deleteProductApi'

export const addProductApi =createAsyncThunk(ADD_PRODUCT,
    async(args, thunkAPI)=>{
        try{
            let response = await API_SERVICE.post(SAVE_PRODUCT, args['data'], "CREATE");
            response = response.data.result.data[0];

            // This field has a reference at TypeFields, so we need to refetch it
            thunkAPI.dispatch(setTypeFieldsIsUpdated(true));

            return response;
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
})

export const updateProductApi = createAsyncThunk(UPDATE_PRODUCT,
    async(args, thunkAPI)=>{
        try{
            let response = await API_SERVICE.post(SAVE_PRODUCT, args['data'], "UPDATE");
            response = response.data.result.data[0];

            // This field has a reference at TypeFields, so we need to refetch it
            thunkAPI.dispatch(setTypeFieldsIsUpdated(true));

            return response;
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
})

export const deleteProductApi = createAsyncThunk(DELETE_PRODUCT, 
    async (args, thunkAPI) => {
        try{
            const toDelete = thunkAPI.getState().root.product.list.find(ele => ele.id === args['id']);   
            let response = await API_SERVICE.post(SAVE_PRODUCT, {...toDelete, isdisabled: "Y"});
            response = response.data.result.data[0];

            // This field has a reference at TypeFields, so we need to refetch it
            thunkAPI.dispatch(setTypeFieldsIsUpdated(true));

            return response;
        } catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
    }
)

export const fetchProductApi = createAsyncThunk(FETCH_PRODUCT,
    async(arg,thunkAPI)=>{
        try{
            let response = await API_SERVICE.get(GET_PRODUCT_TYPE);
            response = response.data.result.data;
            return response
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
    },
    {
        condition: (arg, { getState, extra }) => {          
          const isUpdated = getState().root.product.isUpdated                         
          if(!isUpdated){
            console.log('enable fetch condition in reducer')
            return false
          }
        },
      }
)

const initialState={
    list:[],
    edit:{},
    isLoading:false,
    isUpdated:true,
}

export const productSlice=createSlice({
    name:'product',
    initialState,
    reducers:{
        addProduct:(state,action)=>{
            state.list=[...state.list,action.payload]
        },
        delProduct:(state,action)=>{
            state.list=state.list.filter(x=>x.id!==action.payload)
        },
        loadEditProduct:(state,action)=>{
            state.edit=action.payload
        },
        clearEditproduct:(state,action)=>{
            state.edit={}
        },
        updateProduct:(state,action)=>{
            let index=state.list.findIndex(x=>x.id===action.payload.id)
            state.list[index]=action.payload
        },
        resetProcuct:()=>initialState
    },
    extraReducers:(builder)=>{
        builder       
        .addCase(addProductApi.fulfilled,(state,action)=>{
            state.list=[...state.list,action.payload];
            toast.success('Succesfully added');
        })
        .addCase(updateProductApi.fulfilled,(state,action)=>{
            state.list = state.list.map(x => x.id === action.payload.id ? action.payload: x);
            toast.success('Succesfully updated');
        })
        .addCase(fetchProductApi.fulfilled, (state, action) => {
            state.list = action.payload;
            state.isUpdated = false;
        })
        .addCase(deleteProductApi.fulfilled, (state, action) => {
            state.list=state.list.filter(x=>x.id!==action.payload.id);
            toast.success('Succesfully deleted');
        })
    }
})

export const {addProduct,delProduct,loadEditProduct,clearEditproduct,updateProduct}=productSlice.actions
export default productSlice.reducer