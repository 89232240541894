import { createAsyncThunk } from "@reduxjs/toolkit";
import * as ACTION from "../../constant/Actions"
import * as APIS from "../../constant/CommonApiUrl";
import { API_SERVICE } from "../../Services/CommonApi";
export const getPMSanalysisReport = createAsyncThunk(ACTION.PMS_ANALYSIS,
    async (arg, thunkAPI) => {
        try {
            let vessel_id = thunkAPI.getState().root.common.vessel_detailByID.id
            let response = await API_SERVICE.get(`${APIS.PMS_ANALYSIS}?${arg}`)
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getPMSanalysisVessel = createAsyncThunk(ACTION.PMS_ANALYSIS_VESSEL,
    async (arg, thunkAPI) => {
        try {
            let vessel_id = thunkAPI.getState().root.common.vessel_detailByID.id
            let response = await API_SERVICE.get(arg?`${APIS.PMS_ANALYSIS_VESSEL}?${arg}`:`${APIS.PMS_ANALYSIS_VESSEL}?&vessel_id=${vessel_id}`)
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })