import {createAsyncThunk} from "@reduxjs/toolkit";
import * as ACTION from "../../constant/Actions";
import axios from "axios";
import {API_SERVICE} from "../../Services/CommonApi"
import * as APIS from "../../constant/CommonApiUrl";

export const getDocCategoryMasterList = createAsyncThunk(ACTION.GET_DOC_CATEGORY,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id } = thunkAPI.getState().root.common.vessel_detailByID
            const payload = arg?.searchObj ? {vessel_id: id, ...arg.searchObj} : {vessel_id: id}
            return await API_SERVICE.get(`${APIS.GET_DOC_CATEGORY}`, payload, "", {cancelToken: source.token})
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getDocCategoryDataById = createAsyncThunk(ACTION.GET_DOC_CATEGORY_BY_ID,
    async (arg, thunkAPI) => {
        try {
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            return await API_SERVICE.get(`${APIS.GET_DOC_CATEGORY}`, { id: arg, vessel_id: id })
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const saveDocCategoryMaster = createAsyncThunk(ACTION.SAVE_DOC_CATEGORY,
    async (arg, thunkAPI) => {
        try {
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            return await API_SERVICE.post(APIS.SAVE_DOC_CATEGORY, arg)
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })

export const getChangeRequestTypeList = createAsyncThunk(ACTION.GET_CHANGE_REQUEST,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id } = thunkAPI.getState().root.common.vessel_detailByID
            const payload = arg?.searchObj ? {vessel_id: id, ...arg.searchObj} : {vessel_id: id}
            return await API_SERVICE.get(`${APIS.GET_CHANGE_REQUEST}`, payload, "", {cancelToken: source.token})
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getChangeRequestTypeById = createAsyncThunk(ACTION.GET_CHANGE_REQUEST_BY_ID,
    async (arg, thunkAPI) => {
        try {
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            return await API_SERVICE.get(`${APIS.GET_CHANGE_REQUEST}`, { id: arg, vessel_id: id })
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const saveChangeRequestType = createAsyncThunk(ACTION.SAVE_CHANGE_REQUEST,
    async (arg, thunkAPI) => {
        try {
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            return await API_SERVICE.post(APIS.SAVE_CHANGE_REQUEST, arg)
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })