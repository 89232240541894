import React, { useEffect, useState } from "react";
import {
  Badge,
  Container,
  FormSelect,
  Nav,
  Navbar,
  NavDropdown,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { putVesselDetail, toggleSubmenu } from "../../features/commonSlice";
import { fetchVesselApi } from "../../features/vesselSlice";
import Sidebars from "./SideBar";
import { BsBellFill } from "react-icons/bs";
import SassPmsList from "../../pages/PMS/LOGIN"
import NotificationMessage from "./NotificationMessage";
import { getAllData } from "../../features/globalSlice";
import { SETTINGS } from "../../constant/CommonApiUrl";
import { logout } from "../../pages/Settings/logout";

const NavMenu = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [profileToggle, setProfile] = useState(false);
  const loginDetails = useSelector((state) => state.root.login.loginDemo);
  const { notificationList = [] } = useSelector((state) => state.root.GLOBAL)
  const vessel_id = useSelector(
    (state) => state.root.common.vessel_detailByID?.id
  );
  const vessel_list = useSelector((state) => state.root.vessel.list);
  const { first_name, Designation } = useSelector(
    (state) => state.root.login.loginDemo
  );
  const vesselList = useSelector((state) => state.root.login.loginDemo?.vesselDetails) || []
  const vessel_Option = vesselList.map((x, i) => (
    <option
      key={x.id + "_" + i}
      value={x.id} /* selected={vessel_id===x.id?true:false} */
    >
      {x.vessel_name}
    </option>
  ));
  let path = (JSON.parse(localStorage.getItem('loginPath')))
  const [showNotification, setShowNotification] = useState(false);
  useEffect(() => {
    const intervalId = setInterval(() => {
      !loginDetails?.is_admin && loginDetails.isLogin && dispatch(getAllData({ notificationList: `${SETTINGS}GetBrowserNotificationList?userid=${loginDetails?.id}&vessel_id=${vessel_id}` }))
    }, 900000); // 5000 milliseconds = 5 seconds
    // Clear the interval when the component unmounts or when the dependency array changes
    return () => clearInterval(intervalId);
  }, [])
  useEffect(() => {
    loginDetails.isLogin && !loginDetails?.is_admin && dispatch(getAllData({ notificationList: `${SETTINGS}GetBrowserNotificationList?userid=${loginDetails?.id}&vessel_id=${vessel_id}` }))
    loginDetails.isLogin && dispatch(fetchVesselApi()).unwrap().then(res => {
      let vesselList = res.filter((x) => x.vessel_name === 'OFFICE');
      vessel_id === undefined && dispatch(putVesselDetail(vesselList[0]));
    })
    console.log(!loginDetails.isLogin, 'islogin')
    !loginDetails.isLogin && (path?.pathName === '/login' || path?.pathName === '/') ? navigate("/login") : !loginDetails.isLogin && (path?.pathName === '/saas-login' || path?.pathName === '/') ? navigate("/saas-login") : !loginDetails.isLogin && (path?.pathName === '/admin-login') && navigate("/admin-login")
    !loginDetails.isLogin && path === null && navigate('/login')
    /* !loginDetails.isLogin && (path.pathName === '/login' || path.pathName === '/') ? navigate("/") : (path.pathName === '/login' || path.pathName === '/') && navigate('/login');
    !loginDetails.isLogin && (path.pathName === '/saas-login' || path.pathName === '/inventory') ? navigate("/inventory") : (path.pathName === '/saas-login' || path.pathName === '/inventory') && navigate('/saas-login'); */
  }, [loginDetails]);
  const vesselChange = (arg) => {
    const vesselDetail = vessel_list.filter((x) => x.id === Number(arg));
    dispatch(putVesselDetail(vesselDetail[0]));
    // console.log('the vessel object stored into the commonSlice')
  };
  /*  const logout = () => {
     ['vesselInfo', 'loginInfo'].forEach(element => {
       localStorage.removeItem(element);
     });
     console.log('logouty', path.pathName)
     path.pathName === '/' || path.pathName === '/login' ? window.location.reload('/login') : window.location.reload('/saas-login')
     
 
   }; */
  function handleChange(e) {
    navigate(`${e.target.value}`);
  }
  const handleBellClick = () => {
    setShowNotification(!showNotification);
  };
  return (
    <div>
      <SassPmsList />
      {!loginDetails?.is_admin && <Navbar className="appHeader" expand="lg" fixed="top">
        <Container fluid>
          {/* <Navbar.Brand href="#home"><img src={logo} alt={"logo"} /> </Navbar.Brand> */}
          <Navbar.Toggle aria-controls="headerNavbarNav" />
          <Navbar.Collapse id="headerNavbarNav">
            <Nav className="ms-auto">
              <div className={'position-relative me-5'}>
                <BsBellFill
                  style={{ color: '#8798B2', cursor: 'pointer' }}
                  className={'notify me-2 align-content-center d-flex'}
                  onClick={handleBellClick}
                />
                {showNotification && !loginDetails?.is_admin && (
                  <div className={'position-fixed bg-white rounded'} style={{ marginTop: "-1px", marginLeft: "-200px", boxShadow: "0 0 10px rgba(0, 0, 0, 0.14)", width: "210px", height: "436px" }}>
                    <NotificationMessage />
                  </div>
                )}
                <span className={'position-absolute notify-count'}>{notificationList.length}</span>
              </div>
              {/* <FormSelect
                  className={'me-2 top-select'} onChange={(e) => handleChange(e)}>
                <option value="" disabled>Choose dashboard</option>
                <option value="/dashboard">PURCHASE</option>
                <option value="/pms">PMS</option>
                <option value="/inventory">INVENTORY</option>
                <option value="/safety">SAFETY</option>
              </FormSelect> */}
              {/* <Nav.Link href="#link"> */}
              {/*    <i className="fa fa-fw fa-envelope-o" /> */}
              {/* </Nav.Link> */}
              <FormSelect
                className={'me-2 top-select'}
                onChange={(e) => vesselChange(e.target.value)}
                value={vessel_id || ""}
              >
                <option value="" disabled>
                  Choose Vessel
                </option>
                {vessel_Option}
              </FormSelect>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>}
      <div className="leftFixed12">
        <Sidebars setProfile={(e) => setProfile(e)} />
        <NavDropdown
          title={
            <div className="d-flex">
              <div className="avatar">
                {
                  loginDetails?.repository && loginDetails.repository.length > 0 ? <img src={loginDetails.repository[0].fileurl} alt={'profile'} title={'user'} /> :
                    <i className="fa fa-fw fa-user" />
                }

              </div>
              {
                !profileToggle && <div className="d-flex justify-content-start flex-column" style={{ marginLeft: '5px' }}>
                  <div> {first_name}</div>
                  <span>{Designation}</span>
                </div>
              }

            </div>
          }
          align="end"
          id="headerNavDropdown"
          className="profile-menu"
          style={{ position: 'fixed', width: '180px', bottom: 0, color: '#fff' }}
        >
          <NavDropdown.Item href="#">My Profile</NavDropdown.Item>
          <NavDropdown.Item href="#action">Settings</NavDropdown.Item>
          <NavDropdown.Item href="#action">Help</NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item onClick={() => logout()}>
            Logout
          </NavDropdown.Item>
        </NavDropdown>
        {/*  </li>*/}
        {/*</ul> *!/*/}
      </div>
    </div>
  );
};

export default NavMenu;
