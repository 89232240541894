import { createSlice } from "@reduxjs/toolkit";
import { AddEditResponse, ValidateResponse } from "../Services/Support";
import { getVesselConditionStatus, getVesselInspection, getVesselInspectionCount, getVesselInspectionMaster, getVesselInspectionMasterbyId, getVesselInspectionOpen, getVesselInspectionReport, getVesselInspectionReportbyId, getVesselInspectionSub, getVesselInspectionSubbyId, getVesselInspectionbyId, saveVesselInspection, saveVesselInspectionMaster, saveVesselInspectionReport, saveVesselInspectionSub } from "./THUNK/vesselInspectionThunk";
import { ConvertJson } from "../helper";
import { commonPayloadFields } from "../helper/storage";

const initialState = {
    vessel_group: [],
    vessel_sub_group: [],
    vessel_master: [],
    editRecord: {},
    list: [],
    count: {},
    condition_status: {},
    open_item: [],
    equipmentDetails: []
}
const vesselInspectionSlice = createSlice({
    name: 'vessel_inspection',
    initialState,
    reducers: ({
        resetConditionStatus: (state, action) => {
            state.condition_status = {}
        },
        setItemDetails: (state, action) => {
            let filterArray = state.equipmentDetails
            let currentIndex = state.equipmentDetails.findIndex(el => el.id === action.payload.id)
            filterArray[currentIndex] = { ...action.payload, isdisabled: 'N', ...commonPayloadFields }
            state.equipmentDetails = filterArray
        },
        loadEquipmentDetails: (state, action) => {
            state.equipmentDetails = action.payload
        }

    }),
    extraReducers: (builder) => {
        builder
            .addCase(saveVesselInspection.fulfilled, (state, action) => {
                AddEditResponse(action.payload)
            })
            .addCase(getVesselInspection.fulfilled, (state, action) => {
                state.vessel_group = ValidateResponse(action.payload)
            })
            .addCase(getVesselInspectionbyId.fulfilled, (state, action) => {
                state.editRecord = ValidateResponse(action.payload)[0]
            })
            .addCase(saveVesselInspectionSub.fulfilled, (state, action) => {
                AddEditResponse(action.payload)
            })
            .addCase(getVesselInspectionSub.fulfilled, (state, action) => {
                state.vessel_sub_group = ValidateResponse(action.payload)
            })
            .addCase(getVesselInspectionSubbyId.fulfilled, (state, action) => {
                state.editRecord = ValidateResponse(action.payload)[0]
            })
            .addCase(saveVesselInspectionMaster.fulfilled, (state, action) => {
                AddEditResponse(action.payload)
            })
            .addCase(getVesselInspectionMaster.fulfilled, (state, action) => {
                state.vessel_master = ValidateResponse(action.payload)
            })
            .addCase(getVesselInspectionMasterbyId.fulfilled, (state, action) => {
                let obj = ValidateResponse(action.payload)[0]
                obj["vessel_listid"] = obj?.vessel_listid ? ConvertJson(obj.vessel_listid) : []
                state.editRecord = obj
            })
            .addCase(saveVesselInspectionReport.fulfilled, (state, action) => {
                AddEditResponse(action.payload)
            })
            .addCase(getVesselInspectionReport.fulfilled, (state, action) => {
                state.list = ValidateResponse(action.payload)
            })
            .addCase(getVesselInspectionReportbyId.fulfilled, (state, action) => {
                let obj = ValidateResponse(action.payload)[0]
                //obj["vessel_listid"] = obj?.vessel_listid ? ConvertJson(obj.vessel_listid) : []
                state.editRecord = obj
            })
            .addCase(getVesselInspectionCount.fulfilled, (state, action) => {
                state.count = ValidateResponse(action.payload)[0]
            })
            .addCase(getVesselInspectionOpen.fulfilled, (state, action) => {
                state.open_item = ValidateResponse(action.payload)
            })
            .addCase(getVesselConditionStatus.fulfilled, (state, action) => {
                let obj = ValidateResponse(action.payload)
                if (obj.length > 0) {
                    /* obj = { ...obj[0] }
                    obj['bridge_repository'] = obj?.bridge_repository ? ConvertJson(obj.bridge_repository)[0] : []
                    obj['cargo_repository'] = obj?.cargo_repository ? ConvertJson(obj.cargo_repository)[0] : []
                    obj['galley_repository'] = obj?.galley_repository ? ConvertJson(obj.galley_repository)[0] : [] */
                    state.condition_status = obj
                } else {
                    state.condition_status = {}
                }

            })
    }
})
export const { resetConditionStatus, setItemDetails, loadEquipmentDetails } = vesselInspectionSlice.actions
export default vesselInspectionSlice.reducer