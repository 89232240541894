import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_SERVICE } from "../Services/CommonApi";
import axios from "axios";
import { AddEditResponse, ValidateResponse } from "../Services/Support";
export const getAllData = createAsyncThunk('global/getAllData',
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            let keys = Object.keys(arg).map((el, i) => el)
            let response = await Promise.all(
                keys.map((el, i) => API_SERVICE.get(`${arg[el]}`, "", "", { cancelToken: source.token }))
            )
            let result = response.map((res, i) => ({ [keys[i]]: ValidateResponse(res) }))
            return Object.assign({}, ...result)
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }

    })
export const saveAllData = createAsyncThunk('global/saveAllData',
    async (arg, thunkApi) => {
        try {
            return await arg.method !== 'put' ? API_SERVICE.post(`${arg.url}`, arg.payload) : API_SERVICE.put(`${arg.url}`, arg.payload)
        } catch (err) {
            thunkApi.rejectWithValue(err)
        }
    })

const globalSlice = createSlice({
    name: 'global',
    initialState: {},
    reducers: {
        clearObject: (state, action) => {
            return { ...state, ...action.payload }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllData.fulfilled, (state, { payload }) => {
                return { ...state, ...payload }
            })
            .addCase(saveAllData.fulfilled, (state, { payload }) => {
                AddEditResponse(payload)
            })
    }

})
export const { clearObject } = globalSlice.actions
export default globalSlice.reducer