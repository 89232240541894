import { commonPayloadFields, dateUTC } from "./storage";
import { prModal } from "../constant/prModal";
import { localtoISO } from "./dateUtil";

const loginInfo = localStorage.getItem('loginInfo')
const regex_boolean = /^true$/i
export const userInfo = JSON.parse(loginInfo);

export const ProductGeneralPayload = (arg, isEdit = false, isSplit = false, merge = false, mergeArray = []) => {
    let payload = arg.purchaseReqItemModel && arg.purchaseReqItemModel.map(x => Object.assign({
        id: (isEdit || isSplit || x.id) ? x.id : 0,
        purreq_id: 0,
        transfer_id: 0,
        product_id: isEdit ? x.product_id : Number(x.id),
        product_code: isEdit ? x.product_code : x.product_code,
        product_name: isEdit ? x.product_name : x.product_name,
        product_type: x.product_type,
        rob: x.rob,
        net_Unit_cost: x.net_Unit_cost ? Number(x.net_Unit_cost) : 0,
        quantity: isSplit ? x.split_quantity !== '0' && x.split_quantity && x.split_quantity : Number(x.quantity),
        unit: x.unit,
        vessel_id: x.vessel_id ? x.vessel_id : arg.vessel_id,
        department_id: x.department_id ? x.department_id : '',
        project_id: x.project_id ? x.project_id : '',
        item_tax: "",
        tax: "",
        discount: 0,
        item_discount: 0,
        subtotal: x.subtotal ? x.subtotal : 0,
        piDate: dateUTC(),
        piStatus: "NEW",
        ...commonPayloadFields,
        isdisabled: x?.isdisabled === 'Y' ? 'Y' : 'N',
    }))
    const obj = {
        id: isEdit ? arg.id : 0,
        is_contract: arg.is_contract ? 1 : arg?.purchaseReqItemModel ? arg?.purchaseReqItemModel.every(el => el.is_contract === 1) ? 1 : 0 : 0,
        pr_id: isEdit ? arg.pr_id : '',
        is_bulk: arg.is_bulk ? 1 : 0,
        pr_date: dateUTC(arg.pr_date),
        pr_name: arg.pr_name,
        pr_type: arg.pr_type,
        supplier_id: arg.supplier_id ? arg.supplier_id : 1,
        supplier_name: arg.supplier_name ? arg.supplier_name : btoa(Math.random().toString()).substring(10, 15),
        vessel_id: arg.vessel_id,
        department_id: arg.department_id ? Number(arg.department_id) : 0,
        project_id: arg.project_id ? Number(arg.project_id) : 0,
        account_code: arg.account_code,
        pr_rank: arg.pr_rank,
        day_of_use: arg.day_use ? Number(arg.day_use) : 0,
        note: arg.note,
        total_value: arg.total_value ? arg.total_value : 0,
        pr_status: "NEW",
        purchase_by: arg.user_name,
        purchase_timestamp: dateUTC(),
        approve_id: 0,
        due_date: dateUTC(),
        budget_id: 0,
        budget_name: "",
        isdisabled: "N",
        ...commonPayloadFields,
        category: arg.category,
        issplit: isSplit || merge ? 1 : 0,
        pms_id: arg.pms_id,
        purchaseReqItemModel: merge ? [prModal] : payload,
        repository: arg.repository
    }

    if (isSplit || merge) {
        Object.assign(obj, {
            merge_split: isSplit ? 'SPLIT' : 'MERGE',
            parent_pr_ids: isSplit ? [{ id: arg.id }] : mergeArray
        })
    }
    return obj
}
export const ApproveProductPayload = (arg) => {
    return {
        id: 0,
        approve_pr_id: 0,
        //is_contract:regex_boolean.test(arg.is_contract)?1:0,
        is_contract: arg.is_contract ? 1 : 0,
        pr_id: arg.id,
        approve_id: arg.user_id,
        request_no: arg.pr_id,
        approve_name: arg?.first_name ? arg?.first_name : '',
        prstatus: arg.status,
        approve_timestamp: dateUTC(),
        isdisabled: "N",
        ...commonPayloadFields,
    }
}
export const rfqQuotationPayload = (arg, isEdit = false, data) => {
    let payload = arg.quotationDetailModel.map(x => Object.assign({
        id: isEdit ? x.Did : 0,
        qhid: isEdit ? x.Qhid : 0,
        itemid: x.Did,
        quantity: x.quantity,
        qrate: x.unitPrice || x.qrate,
        unit: x.unit,
        item_taxid: 0,
        taxamount: x.taxamount,
        discount: x.discount,
        item_discount: 0,
        subtotal: x.subtotal,
        isdisabled: "N",
        product_id: x.product_id || x.itemid,
        product_name: x.product_name,
        product_code: x.product_code,
        product_type: x.product_type,
        ...commonPayloadFields,

    }))
    return {
        id: isEdit ? data.id : 0,
        quote_number: isEdit ? data.Quote_number : arg.quote_number,
        pureq_id: isEdit ? data.pureq_id : arg.pureq_id,
        rfq_id: isEdit ? data.rfq_id : arg.rfq_id,
        supplier_id: arg.supplier_id,
        vessel_id: arg.vessel_id,
        quote_total: arg.total,
        expiry_date: dateUTC(arg.expiry_date),
        quote_status: "NEW",
        supply_port: arg.supply_port,
        supply_loc: arg.location ? arg.location : '',
        deliverydate: dateUTC(arg.deliverydate),
        quotedate: dateUTC(arg.quotedate),
        datetime: arg.datetime,
        lead_time: arg.datetime,
        remark: arg.remark,
        currency: arg.currency,
        isdisabled: "N",
        ...commonPayloadFields,
        quotationDetailModel: payload
    }
}

export const generatePOPayload = (arg) => {
    let payload = arg.QuotationDetail.map(x => Object.assign({},
        {
            id: 0,
            pohid: 0,
            transfer_id: "string",
            cqd_id: 0,
            product_id: x.q_itemid,
            product_code: x.item_code,
            product_name: x.item_name,
            product_type: x.product_type,
            net_unit_cost: x.rate,
            unit: x.unit,
            quantity: x.quantity,
            vessel_id: 0,
            department_id: 0,
            project_id: 0,
            item_tax: 0,
            tax: x.vat,
            discount: x.discount,
            item_discount: 0,
            subtotal: (x.quantity * x.rate),
            piDate: dateUTC(),
            piStatus: "",
            lsupplier_id: x.supplier_id ? x.supplier_id : arg.supplier_id,
            isdisabled: "N",
            ...commonPayloadFields,
        })
    )

    return {
        id: 0,
        reference_no: "",
        pureq_id: arg.pureq_id,
        rfq_id: arg.rfq_id,
        cq_id: arg.cq_id,
        supplier_id: arg.supplier_id ? arg.supplier_id : 0,
        supplier_name: arg.supplier_name ? arg.supplier_name : "NA",
        vessel_id: arg.Vessel_id,
        supplylocation: arg.supplylocation,
        supplyport: arg.supplyport,
        department_id: arg.department_id,
        currency: arg.currency,
        project_id: 0,
        note: arg.note,
        shipping: "",
        grand_total: arg.cquote_total,
        status: "NEW",
        payment_status: "",
        attachement_file: "",
        payment_team: "",
        due_date: arg.due_date ? arg.due_date : dateUTC(),
        return_id: "",
        surcharge: "",
        return_surchrge_ref: "",
        purchase_pr_id: "",
        deleivery_date: arg.deleivery_date,
        isdisabled: "N",
        ...commonPayloadFields,
        poDetailModel: payload

    }
}

export const ConvertJson = (arg) => {
    let obj = arg ? arg?.replace(/(^"|"$)/g, '') : []
    return arg ? JSON.parse(obj) : []
}

export const itemDetails = (compareArray, header, isEdit) => {
    const obj = []
    compareArray.length > 0 && compareArray.map((v, i) => {
        const supplierDetails = isEdit ? {} : v.supplier_details.find(e => e.supplier_id === Number(v.supplier_id)) //v.supplier_id
        if (v.q_itemid) {
            obj.push({
                id: isEdit ? v.id : 0,
                cqhid: isEdit ? v.cqhid : 0,
                qh_id: v.qh_id ? v.qh_id : supplierDetails.qh_id, //header id
                currency: v.currency,
                qc_id: v.qc_id ? v.qc_id : v.q_itemid, //
                q_itemid: v.product_id ? v.product_id : 0, // item id
                product_type: v.product_type,
                supplier_id: v.supplier_id ? v.supplier_id : 0,
                cquote_status: "NEW",
                isdisabled: "N",
                item_name: v.Product_name ? v.Product_name : supplierDetails.Product_name,
                item_code: v.Product_code ? v.Product_code : supplierDetails.Product_code,
                unit: v.unit,
                quantity: v.quantity ? v.quantity : supplierDetails.quantity,
                rate: v.qrate ? v.qrate : supplierDetails.qrate,
                vat: v.vat,
                discount: v.discount,
                vat_amount: 0,
                discount_amount: 0,
                ...commonPayloadFields
            })
        }
    })
    return obj
}

export const constructComparisonData = (header, compareArray = [], isEdit = false, totalValue) => {
    const detailsModal = itemDetails(compareArray, header, isEdit)
    return {
        id: isEdit ? header.id : 0,
        cquote_number: header.Quote_number,
        pureq_id: header.pureq_id,
        rfq_id: header.rfq_id,
        vessel_id: header.Vessel_id,
        // cquote_total: header.Quote_total,
        cquote_total: totalValue,
        cquote_status: "NEW",
        isdisabled: "N",
        is_quote_split: 0,
        supplylocation: header.supply_loc ? header.supply_loc : '',
        supplyport: header.supply_port ? header.supply_port : '',
        department_id: 0,
        project_id: 0,
        ...commonPayloadFields,
        compareQuotationDetailModel: detailsModal
    }
}

export const constructApprovePayload = (data) => {
    return {
        id: 0,
        pureq_id: data.pureq_id,
        rfq_id: data.rfq_id,
        cq_id: data.id, //compare_quote_id
        approve_id: data.approve_id, // user_id
        approve_name: data?.first_name ? data?.first_name : '', //user_name
        approve_Date: dateUTC(),
        cqstatus: data.status,
        cqstatusid: 1,
        isdisabled: "N",
        vessel_id: data?.vessel_id,
        ...commonPayloadFields
    }
}

export const approveRFQPayload = (data) => {
    const supplierPayload = []
    data.supplier_id.length > 0 && data.supplier_id.map((s) => {
        supplierPayload.push({
            sid: s.id,
            supplier_name: s.supplier_name,
            token: '',
            currency: s.currency,
            port_code: s.port_code
        })
    })
    return {
        id: 0,
        pureq_id: data.purReqId,
        rfq_id: data.ID,
        approve_id: data.user_id,
        approve_name: data?.first_name ? data?.first_name : '',  //user name
        approve_Date: dateUTC(),
        status: data.status,
        statusid: 0,
        isdisabled: "N",
        expiry_Date: data.Expiry_Date,
        supplier_id: supplierPayload,
        ...commonPayloadFields
    }
}

export const generatePartPayload = (arg, isEdit = false) => {
    return {
        id: isEdit ? arg.partid : 0,
        equipmentid: arg.equipmentid,
        partname: arg.partname,
        partcode: arg.partcode,
        unit: arg.unit,
        partid: 0,
        recordlevel: 0,
        alarmlevel: "",
        otherdetails: '',
        critical: arg.critical,
        drawingnumber: arg.drawingnumber,
        dimensions: "",
        asfitted: "",
        recorderquantity: 0,
        leadtime: "",
        accountcode: "",
        inventoryid: 0,
        vessel_id: arg.vessel_id,
        remarks: '',
        status: arg.partstatus,
        rob: arg.rob.toString(),
        rcddate: isEdit ? new Date(arg.rcddate) : arg.rcddate,
        locationid: arg.locationid, //int
        isdisabled: "N",
        isratecontract: arg.isratecontract ? arg.isratecontract : "N",
        ratecontract: 0,
        robtype: '',
        repository: arg.repository,
        ...commonPayloadFields
    }
}

export const generateBulkPoPayload = (arg) => {
    let poDetailModel = arg.purchaseReqItemModel && arg.purchaseReqItemModel.map(x => Object.assign({
        id: x.id,
        pohid: arg.id,
        transfer_id: "",
        cqd_id: 0,
        product_id: x.product_id,
        product_code: x.product_code,
        product_name: x.product_name,
        net_unit_cost: x.net_Unit_cost,
        quantity: x.quantity,
        vessel_id: x.vessel_id,
        department_id: x.department_id,
        project_id: 0,
        item_tax: 0,
        tax: 0,
        discount: 0,
        item_discount: 0,
        subtotal: x.subtotal,
        piDate: new Date(),
        piStatus: "NEW",
        lsupplier_id: 0,
        isdisabled: "N",
        ...commonPayloadFields
    }))
    return {
        id: 0,
        reference_no: "",
        pureq_id: arg.id,
        rfq_id: 0,
        cq_id: 0,
        supplier_id: Number(arg.supplier_id),
        supplier_name: arg.supplier_name,
        vessel_id: arg.vessel_id,
        is_bulk: 1,
        supplylocation: "bulk",
        supplyport: "bulk",
        department_id: arg.department_id,
        project_id: 0,
        note: arg.note,
        shipping: "bulk",
        grand_total: arg.total_value,
        status: "BULK",
        payment_status: "bulk",
        attachement_file: "bulk",
        payment_team: "bulk",
        due_date: new Date(),
        return_id: "bulk",
        surcharge: "bulk",
        return_surchrge_ref: "bulk",
        purchase_pr_id: arg.pr_id,
        deleivery_date: new Date(),
        isdisabled: "N",
        poDetailModel: poDetailModel,
        ...commonPayloadFields
    }
}
export const generateStorePayload = (values, vessel_id, id = 0, repo = []) => {
    return {
        ...values,
        id: id,
        storecategery: Number(values.storecategery),
        //storecategery: values.storecategery,
        storetype: Number(values.storetype),
        robunit: values.unit,
        vessel_id: vessel_id,
        is_status: Number(values?.is_status),
        robexpairationdate: values.consumableyn === 'Y' ? values.robexpairationdate : null,
        repository: repo
    }
}

export const getCurrentPathWithoutLastPart = (location) => {
    return location.pathname.slice(0, location.pathname.lastIndexOf('/'))
}

export const contractRatePayload = (arg, isEdit = false) => {
    let cR_DetailModel = arg.cR_DetailModel && arg.cR_DetailModel.map(x => ({
        id: isEdit ? x.id : 0,
        cr_head_id: isEdit ? x.cr_head_id : 0,
        item_id: x.id,
        item_code: x.product_code,
        item_name: x.product_name,
        unit: x.unit,
        qty: Number(x.qty),
        valid_from: x.fromdt,
        valid_to: x.todt,
        price: Number(x.price),
        total: Number(x.total),
        min_order: Number(x.min_order),
        port_id: Number(x.port_id),
        isdisabled: "N",
        ...commonPayloadFields
    }))
    return {
        id: arg.id ? arg.id : 0,
        category: arg.category,
        category_type: arg.category_type,
        supplier_id: Number(arg.supplier_id),
        vessel_id: Number(arg.vessel_id),
        agreement_no: arg.agreement_no,
        agreement_date: dateUTC(arg.agreement_date),
        currency: arg.currency,
        valid_from: dateUTC(arg.valid_from),
        valid_to: dateUTC(arg.valid_to),
        remarks: arg.remarks,
        grand_total: arg.grand_total,
        //port_code:arg.port_code,
        isdisabled: "N",
        cR_DetailModel: cR_DetailModel,
        repository: arg.repository,
        ...commonPayloadFields,
    }
}

export const goodsPayload = (arg, isEdit = false, update = false) => {
    const mrDetail = arg.MRDetail && arg.MRDetail.length > 0 && arg.MRDetail.map(x => ({
        id: isEdit ? x.id : 0,//edit get api id
        mrh_id: x.mrh_id ? x.mrh_id : 0,
        pod_id: x.pod_id ? x.pod_id : x.id,
        product_id: x.product_id,
        product_code: x.product_code,
        product_name: x.product_name,
        unit: x.unit,
        location_id: Number(x.location_id),
        net_unit_cost: x.net_unit_cost,
        rcd_qty: x.rcd_qty >= 0 ? x.rcd_qty : x.balance_qty,
        balance_qty: x.balance_qty,
        vessel_id: x.vessel_id,
        dept_id: x.department_id,
        project_id: x.project_id,
        expiry_date: dateUTC(x.expiry_date),
        remarks: x.remarks ? x.remarks : '',
        status_id: 0,
        status: "NEW",
        isdisabled: "N",
        ...commonPayloadFields
    }))
    console.log(arg.rcd_date, new Date(arg.rcd_date).toISOString(), 'rcd date')
    return {
        id: isEdit ? arg.id : 0,
        po_id: arg.po_id ? arg.po_id : arg.id,
        po_no: arg.reference_no,
        po_date: dateUTC(arg.createddt),
        supplier_id: arg.supplier_id,
        rating_id: 0,
        pr_id: isEdit ? arg.pr_id : arg.pureq_id,
        vessel_id: arg.vessel_id,
        //rcd_date: dateUTC(arg.rcd_date),
        //rcd_date: isEdit ? new Date(`${arg.rcd_date}Z`) : dateUTC(arg.rcd_date),
        rcd_date: isEdit && update ? localtoISO(arg.rcd_date) : isEdit ? `${arg.rcd_date}Z` : dateUTC(arg.rcd_date),
        delivery_no: arg.delivery_no,
        rcd_loc: arg.supplylocation,
        rcd_port: arg.supplyport,
        rcd_by: arg.rcd_by,
        next_rcd_date: arg.next_rcd_date ? dateUTC(arg.next_rcd_date) : dateUTC(arg.rcd_date),
        dept_id: arg.department_id,
        note: arg.note,
        project_id: arg.project_id,
        status_id: 0,
        status: "NEW",
        approved_status: isEdit ? "UPDATE" : "",
        is_next: arg.is_next ? arg.is_next : 0,
        grand_total: arg.grand_total,
        attachement_file: "",
        isdisabled: "N",
        ...commonPayloadFields,
        mrDetail: mrDetail
    }
}

export const invoicePayload = (data, isEdit = false) => {
    const modal = data.PODetail && data.PODetail.length > 0 ? data.PODetail.map(x => ({
        id: 0,
        invoce_hid: 0,
        product_id: x.product_id,
        product_type: x.product_type ? x.product_type : '',
        product_code: x.product_code,
        product_name: x.product_name,
        product_rate: x.net_unit_cost,
        product_unit: 0,
        product_quantity: x.quantity,
        product_tax: x.product_tax,
        product_taxamount: x.product_taxamount,
        product_discount: x.product_discount,
        product_discountamount: x.product_discountamount,
        product_amount: 0,
        vessel_id: x.vessel_id,
        pistatus: "",
        isdisabled: "N",
        ...commonPayloadFields
    })) : []
    return {
        id: 0,
        invoice_no: data.invoice_no,
        invoice_date: dateUTC(),
        supplier_id: data.supplier_id,
        supplier_date: dateUTC(),
        contract: data.contract,
        ponumber: data.reference_no,
        recurringinvoice: data.recurringinvoice,
        docattach: "",
        invocetotal: data.grand_total,
        pureq_id: data.pureq_id,
        rfq_id: 0,
        cq_id: 0,
        vessel_id: data.vessel_id,
        isdisabled: "N",
        ...commonPayloadFields,
        repository: data.repository,
        invoiceDetailModel: modal
    }
}

export const supplierRatingPayload = (arg) => {
    return {
        id: 0,
        supplier_id: arg.supplier_id,
        po_id: arg.po_id,
        mr_id: arg.mr_id,
        service_quality: arg.service_quality,
        reliability: arg.reliability,
        review: arg.review,
        vessel_id: arg.vessel_id,
        isdisabled: "N",
        ...commonPayloadFields
    }
}

export const splitQCpayload = (arg) => {
    console.log(arg);
    let compareQuotationDetailModel = arg.compareQuotationDetailModel.map(x => ({
        id: 0,
        cqhid: 0,
        qh_id: arg.id,
        qc_id: x.id,
        q_itemid: x.id,
        supplier_id: x.supplier_id,
        cquote_status: "NEW",
        isdisabled: "N",
        item_name: x.Product_name,
        item_code: x.Product_code,
        unit: x.unit ? x.unit : '',
        quantity: x.quantity,
        rate: x.qrate,
        ...commonPayloadFields
    }))
    return {
        id: 0,
        is_quote_split: 1,
        cquote_number: arg.Quote_number,
        pureq_id: arg.pureq_id,
        rfq_id: arg.rfq_id,
        vessel_id: arg.Vessel_id,
        cquote_total: arg.Quote_total,
        cquote_status: arg.Quote_status,
        isdisabled: "N",
        supplylocation: arg.supply_loc,
        supplyport: arg.supply_port,
        department_id: 0,
        project_id: 0,
        ...commonPayloadFields,
        compareQuotationDetailModel: compareQuotationDetailModel
    }
}

export const consumptionPartPayload = (arg) => {
    return {
        id: 0,
        type_id: Number(arg.type),
        item_id: arg.id,
        stock_type_id: arg.stock_type_id,
        item_code: arg.partcode,
        item_name: arg.partname,
        location_id: arg.location_id ? Number(arg.location_id) : 0,
        qty: Number(arg.quantity),
        unit: arg.unitname,
        // current_rob: arg.current_rob,
        current_rob: arg.ROB,
        trans_date: dateUTC(arg.pdate),
        remarks: arg.remarks,
        ref_no: " ",
        ref_id: 0,
        isdisabled: "N",
        vessel_id: arg.vessel_id,
        ...commonPayloadFields,
    }
}
export const consumptionGeneralPayload = (arg) => {
    return {
        id: 0,
        type_id: Number(arg.type),
        item_id: arg.id,
        stock_type_id: arg.stock_type_id,
        item_code: arg.pgcode,
        item_name: arg.pgname,
        location_id: arg.location_id ? Number(arg.location_id) : 0,
        qty: Number(arg.quantity),
        unit: arg.unitname,
        // current_rob: arg.current_rob,
        current_rob: arg.ROB,
        trans_date: dateUTC(arg.pdate),
        remarks: arg.remarks,
        ref_no: " ",
        ref_id: 0,
        isdisabled: "N",
        vessel_id: arg.vessel_id,
        ...commonPayloadFields,
    }
}
export const consumptionStorePayload = (arg) => {
    return {
        id: 0,
        type_id: Number(arg.type),
        item_id: arg.id,
        stock_type_id: arg.stock_type_id,
        item_code: arg.itemcode,
        item_name: arg.itemname,
        location_id: arg.location_id ? Number(arg.location_id) : 0,
        qty: Number(arg.quantity),
        unit: arg.unit_name,
        // current_rob: arg.current_rob,
        current_rob: arg.ROB,
        trans_date: dateUTC(arg.pdate),
        remarks: arg.remarks,
        ref_no: " ",
        ref_id: 0,
        isdisabled: "N",
        vessel_id: arg.vessel_id,
        ...commonPayloadFields,
    }
}
export const consumptionOilPayload = (arg) => {
    return {
        id: 0,
        type_id: Number(arg.type),
        item_id: arg.id,
        stock_type_id: arg.stock_type_id,
        item_code: arg.productCode,
        item_name: arg.productName,
        location_id: arg.location_id ? Number(arg.location_id) : 0,
        qty: Number(arg.quantity),
        unit: arg.unitname,
        // current_rob: arg.current_rob,
        current_rob: arg.ROB,
        trans_date: dateUTC(arg.pdate),
        remarks: arg.remarks,
        ref_no: " ",
        ref_id: 0,
        isdisabled: "N",
        vessel_id: arg.vessel_id,
        ...commonPayloadFields,
    }
}
export const rollbackPayload = (arg) => {
    return {
        id: 0,
        pr_id: arg.pr_id ? arg.pr_id : 0,
        rfq_id: arg.rfq_id ? arg.rfq_id : 0,
        quote_id: arg.quote_id ? arg.quote_id : 0,
        qc_id: arg.qc_id ? arg.qc_id : 0,
        po_id: arg.po_id ? arg.po_id : 0,
        mr_id: arg.mr_id ? arg.mr_id : 0,
        inv_id: arg.inv_id ? arg.inv_id : 0,
        rollback_from: arg.rollback_from,
        rollback_to: arg.rollback_to,
        remarks: arg.remarks,
        user_id: arg.user_id,
        ...commonPayloadFields
    }
}

export const groupBy = (items, key) => items.reduce(
    (result, item) => {
        //console.log(result,item,item[key],[...(result[item[key]] ),item],'----');
        return { ...result, [item[key]]: [...(result[item[key]] || []), item] }
    }, {})

export const generateSplitPoPayload = (arg) => {
    let poDetailModel = arg.PODetail && arg.PODetail.map(x => Object.assign({
        id: x.id,
        pohid: arg.id,
        transfer_id: "",
        cqd_id: 0,
        product_id: x.product_id,
        product_code: x.product_code,
        product_name: x.product_name,
        net_unit_cost: x.net_unit_cost,
        quantity: Number(x.split_quantity),
        vessel_id: x.vessel_id,
        department_id: x.department_id,
        project_id: 0,
        item_tax: 0,
        tax: 0,
        discount: 0,
        item_discount: 0,
        subtotal: x.subtotal,
        piDate: localtoISO(new Date()),
        piStatus: "NEW",
        lsupplier_id: 0,
        isdisabled: "N",
        ...commonPayloadFields
    }))
    return {
        id: arg.id,
        reference_no: "",
        pureq_id: arg.pureq_id,
        is_split: 1,
        rfq_id: 0,
        cq_id: 0,
        supplier_id: Number(arg.supplier_id),
        supplier_name: arg.supplier_name,
        vessel_id: arg.vessel_id,
        supplylocation: arg.supplylocation,
        supplyport: arg.supplyport,
        department_id: arg.department_id,
        project_id: 0,
        note: arg.note,
        shipping: "split",
        grand_total: arg.grand_total,
        status: "NEW",
        payment_status: "split",
        attachement_file: "split",
        payment_team: "split",
        due_date: localtoISO(new Date()),
        return_id: "split",
        surcharge: "split",
        return_surchrge_ref: "split",
        purchase_pr_id: arg.pr_id,
        deleivery_date: localtoISO(new Date()),
        isdisabled: "N",
        poDetailModel: poDetailModel,
        ...commonPayloadFields
    }
}
export const generatePMSStockPayload = (arg) => {
    return {
        id: 0,
        type_id: 0,
        item_id: arg.item_id,
        stock_type_id: arg.stock_type_id,
        item_code: arg.item_code,
        item_name: arg.item_name,
        qty: arg.qty,
        unit: arg.unit,
        remarks: arg.remarks ? arg.remarks : "",
        trans_date: localtoISO(new Date()),
        ref_no: String(arg.ref_no),
        ref_id: arg.ref_id,
        isdisabled: "N",
        vessel_id: arg.vessel_id,
        location_id: arg.location_id,
        ...commonPayloadFields
    }
}
export const generateUnshedulePMSPayload = (arg, isEdit = false) => {
    let maintenanceDetail = [
        {
            id: isEdit ? arg.detail_id : 0,
            head_id: isEdit ? arg.header_id : 0,
            completion_date: localtoISO(arg.completion_date),
            port_name: arg.port_name,
            status_id: arg.status_id,
            completed_by: userInfo?.designation_id ? userInfo?.designation_id : 0,//Number(arg.completed_by) designation id for completed by
            remarks: arg.remarks,
            defect: "NO",
            defect_details: "",
            /* defect: arg.defect,
            defect_details: arg.defect_details, */
            location: arg.location,
            actual_man_hour: arg.actual_man_hour,
            isdisabled: "N",
            ...commonPayloadFields
        }]
    return {
        id: isEdit ? arg.header_id : 0,
        maintenance_name: arg.maintenance_name,
        maintenance_type_id: Number(arg.maintenance_type_id),
        equipment_id: Number(arg.equipment_id),//this is treeid
        pms_type: 'U',
        last_done_date: localtoISO(arg.completion_date),
        code: "",
        maintenance_head_id: 0,
        freq_type_id: 0,
        frequency: 0,
        dept_id: Number(arg.dept_id),
        alert_value: 0,
        survey: "",
        status: "",
        designation: arg.designation,
        procedure: "",
        pms_from: arg.pms_from ? arg.pms_from : 0,//selected record id
        vessel_id: arg.vessel_id,
        maintenanceDetail: maintenanceDetail,
        isdisabled: 'N',
        ...commonPayloadFields
    }
}

/* export const editPOPayload=(arg)=>{
    let payload=arg.PODetail.map(x=>Object.assign({},
        {
            id: x.id,
            pohid: x.pohid,
            transfer_id: "string",
            cqd_id:0,
            product_id:x.product_id,
            product_code: x.product_code,
            product_name: x.product_name,
            net_unit_cost: x.rate,
            quantity: x.quantity,
            vessel_id: 0,
            department_id: 0,
            project_id: 0,
            item_tax: 0,
            tax: 0,
            discount: 0,
            item_discount: 0,
            subtotal:(x.quantity * x.rate),
            piDate: dateUTC(),
            piStatus: "",
            lsupplier_id:x.supplier_id?x.supplier_id:arg.supplier_id,
            isdisabled: "N",
            ...commonPayloadFields,
        })
    )

    return {
        id: 0,
        reference_no: "",
        pureq_id:arg.pureq_id,
        rfq_id:arg.rfq_id,
        cq_id:arg.cq_id,
        supplier_id: arg.supplier_id?arg.supplier_id:0,
        supplier_name: arg.supplier_name?arg.supplier_name:"NA",
        vessel_id: arg.Vessel_id,
        supplylocation:arg.supplylocation,
        supplyport:arg.supplyport,
        department_id:arg.department_id,
        currency:arg.currency,
        project_id: 0,
        note: arg.note,
        shipping: "",
        grand_total: arg.cquote_total,
        status: "NEW",
        payment_status: "",
        attachement_file: "",
        payment_team: "",
        due_date: arg.due_date?arg.due_date:dateUTC(),
        return_id: "",
        surcharge: "",
        return_surchrge_ref: "",
        purchase_pr_id: "",
        deleivery_date: arg.deleivery_date,
        isdisabled: "N",
        ...commonPayloadFields,
        poDetailModel: payload

    }

} */

export const colorStatus = {
    AwatingforPOCreation: 'stsAwatingforPOCreation',
    NEW: 'stsNew',
    VERIFIED: 'stsVerified',
    QUOTESPLITED: 'stsQuoteSplited',
    APPROVED: 'stsApproved',
    CLOSED: 'stsClosed',
    ISSUED: 'stsIssued',
    POInvoiced: 'stsPOInvoiced',
    PARTIAL: 'stsPartial',
    SPLIT: 'stsSplit'
}

export const IsRateContractOption = [{ label: 'YES', value: 'Y' }, { label: 'NO', value: 'N' }].map((x) => ({ value: x.value, key: x.label }))
