import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit"
import {API_SERVICE} from "../Services/CommonApi"
import * as APIS from "../constant/CommonApiUrl";
import * as ACTIONS from "../constant/Actions"
import { toast } from "react-toastify"
import {ConvertJson} from "../helper";

const initialState={
    quotation_list:[],
    quotation_compare_list:[],
    selectedQCItem:[],
    quotation_details:{},
    isUpdated:false,
    successStatus: 0,
    approvedStatus: 0,
    selectedSplit_item:[],
    editCQId: ''
}
export const getQuotationList = createAsyncThunk(ACTIONS.QUOTATION_LIST_ACTION,
  async(arg,thunkAPI)=>{
      try{
        const {id,vessel_name}=thunkAPI.getState().root.common.vessel_detailByID
          return API_SERVICE.get(arg ? `${APIS.GET_QUOTATION_LIST}?status=${arg}&vessel_id=${vessel_name==='OFFICE'?0:id}`: `${APIS.GET_QUOTATION_LIST}?vessel_id=${vessel_name==='OFFICE'?0:id}`)
      }catch(err){
          return thunkAPI.rejectWithValue(err)
      }
  }
)
export const filterQuotList = createAsyncThunk(ACTIONS.QUOTATION_FILTER_ACTION,
  async(arg,thunkAPI)=>{
      try{
        const {id,vessel_name}=thunkAPI.getState().root.common.vessel_detailByID
          return API_SERVICE.get(arg ? `${APIS.GET_QUOTATION_LIST}?${arg}&vessel_id=${vessel_name==='OFFICE'?0:id}`: `${APIS.GET_QUOTATION_LIST}?vessel_id=${vessel_name==='OFFICE'?0:id}`)
      }catch(err){
          return thunkAPI.rejectWithValue(err)
      }
  }
)
export const getCompareQuotationList = createAsyncThunk(ACTIONS.QUOTATION_COMPARE_LIST_ACTION,
  async(arg,thunkAPI)=>{
      try{
        const {id,vessel_name}=thunkAPI.getState().root.common.vessel_detailByID
          return API_SERVICE.get(arg ? `${APIS.GET_COMPARE_QUOTATION_LIST}?${arg}&vessel_id=${vessel_name==='OFFICE'?0:id}` : `${APIS.GET_COMPARE_QUOTATION_LIST}?vessel_id=${vessel_name==='OFFICE'?0:id}` )
      }catch(err){
          return thunkAPI.rejectWithValue(err)
      }
  }
)
export const filterCompareQuotationList = createAsyncThunk(ACTIONS.QUOTATION_COMPARE_FILTER_ACTION,
  async(arg,thunkAPI)=>{
      try{
        const {id,vessel_name}=thunkAPI.getState().root.common.vessel_detailByID
          return API_SERVICE.get(arg ? `${APIS.GET_COMPARE_QUOTATION_LIST}?${arg}&vessel_id=${vessel_name==='OFFICE'?0:id}` : `${APIS.GET_COMPARE_QUOTATION_LIST}?vessel_id=${vessel_name==='OFFICE'?0:id}` )
      }catch(err){
          return thunkAPI.rejectWithValue(err)
      }
  }
)

export const fetchQCItem = createAsyncThunk(ACTIONS.SINGLE_QUOTATION_ACTION,
  async(arg,thunkAPI)=>{
      try{
          return API_SERVICE.get(`${APIS.GET_COMPARE_QUOTATION_BY_ID}?${arg}`)
      }catch(err){
          return thunkAPI.rejectWithValue(err)
      }
  }
)

export const saveQuotationCompareDetails = createAsyncThunk(ACTIONS.SAVE_COMP_QUOTATION_ACTION,
  async(arg,thunkAPI)=>{
      try{
          return API_SERVICE.post(`${APIS.SAVE_COMPARE_QUOTATION}`, arg)
      }catch(err){
          return thunkAPI.rejectWithValue(err)
      }
  }
)
export const approveQuotationCompare = createAsyncThunk(ACTIONS.APPROVE_COMP_QUOTATION_ACTION,
  async(arg,thunkAPI)=>{
      try{
          const response=await API_SERVICE.post(`${APIS.APPROVE_QUOTATION_COMPARE}`, arg)
          thunkAPI.dispatch(getCompareQuotationList())
          return response
      }catch(err){
          return thunkAPI.rejectWithValue(err)
      }
  }
)

export const QuotationSlice = createSlice({
    name: "quotation",
    initialState,
    reducers: {
        setQuotationData: (state,action) => {
            if(state.selectedQCItem.every(x=>x.id !== action.payload.id)){
                state.selectedQCItem=[...state.selectedQCItem, action.payload]
            }
            else if(state.selectedQCItem.some(x=>x.id === action.payload.id)){
                state.selectedQCItem = state.selectedQCItem.filter(x=>x.id !== action.payload.id)
            }
            else{
                return state
            }
        },
        addSplitItem:(state,action)=>{            
            if(action.payload.is_splitQc){
                state.selectedSplit_item=[...state.selectedSplit_item,action.payload]
            }else{
                state.selectedSplit_item=state.selectedSplit_item.filter(el=>el.id!==action.payload.id)
            }

        },
        selectAllSupplier:(state,action)=>{
            state.quotation_details={...state.quotation_details,Itemdetails:action.payload}
        },
        selectSingleSupplier:(state,action)=>{   
            let {Itemdetails}=state.quotation_details  
            let arr=Itemdetails.map(x=>x.Product_code===action.payload.Product_code?action.payload:x)
            state.quotation_details={...state.quotation_details,Itemdetails:arr}          
        },
        resetStatus:()=>initialState
    },
    extraReducers: (builder)=>{
        builder
          .addCase(getQuotationList.fulfilled,(state, { meta, payload })=>{
              state.isLoading=false
              if(payload.data.result.status){
                  state.quotation_list =payload.data.result.data
                  state.isUpdated = true
              }
              if(payload.data.isFaulted){
                  toast.error(payload.data.result.message);
              }
          })
          .addCase(filterQuotList.fulfilled,(state, { meta, payload })=>{
              state.isLoading=false
              if(payload.data.result.status){
                  state.quotation_list =payload.data.result.data
                  state.isUpdated = true
              }
              if(payload.data.isFaulted){
                  toast.error(payload.data.result.message);
              }
          })
          .addCase(getCompareQuotationList.fulfilled, (state, { meta, payload })=>{
              state.isLoading=false
              if(payload.data.result.status){
                  state.quotation_compare_list = payload.data.result.data
                  state.isUpdated = true
              }
              if(payload.data.isFaulted){
                  toast.error(payload.data.result.message);
              }
          })
          .addCase(filterCompareQuotationList.fulfilled, (state, { meta, payload })=>{
              state.isLoading=false
              if(payload.data.result.status){
                  state.quotation_compare_list = payload.data.result.data
                  state.isUpdated = true
              }
              if(payload.data.isFaulted){
                  toast.error(payload.data.result.message);
              }
          })
          .addCase(fetchQCItem.fulfilled, (state, { meta, payload }) => {
              state.isLoading=false
              if(payload.data && payload.data.result && payload.data.result.status){
                  const response = payload.data.result.data[0]
                if (response) {
                  let itemDetails = ConvertJson(response.Itemdetails)
                  let compareDetails = ConvertJson(response.comparisiondetail)
                  let suppliers = ConvertJson(response.suppliers)
                  response['Itemdetails'] = itemDetails
                  response['comparisiondetail'] = compareDetails
                  response['suppliers'] = suppliers
                  state.quotation_details = response
                  state.isUpdated = true
                }
              }
              if(payload.data.isFaulted){
                  toast.error(payload.data.result.message);
              }
          }).addCase(saveQuotationCompareDetails.fulfilled, (state, { meta, payload }) => {
            state.isLoading=false
            if(payload.data.result.status){
              console.log(payload.data.result)
                toast.success('Quotation comparison saved successfully')
                state.successStatus = payload.data.result.messageCode
                state.editCQId = payload.data.result.data[0].id
            }
            if(payload.data.isFaulted){
                toast.error(payload.data.result.message);
            }
        }).addCase(approveQuotationCompare.fulfilled, (state, action) => {
            if(!action.payload.data.result.status)
            {
                toast.error(action.payload.data.result.message)
            }else{
                //toast.success(action.payload.data.result.message)
                if(action.payload.data.result.status&&action.payload.data.result.data[0].status==='success'){
                    state.book_qty=[]
                    toast.success(action.payload.data.result.message)
                  }else{
                      toast.error(action.payload.data.result.data[0].status)
                  }                  
            }
            if(action.payload.data.isFaulted){
            console.log(action.payload.data.result.message);
            }
        })
    }
});

export const {setQuotationData,addSplitItem,selectAllSupplier,selectSingleSupplier, resetStatus }= QuotationSlice.actions
export default QuotationSlice.reducer