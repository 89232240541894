import React from 'react'
import { Col, FormControl, FormLabel, Row, Tab, Table, Tabs } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { addOilStockPMS, addPartStockPMS } from '../../features/pmsSlice'
import { NUMBER_REGEX } from '../../constant/Regex'
import { toast } from 'react-toastify'

function WOConsumption() {
    const dispatch=useDispatch()    
    const part_stock_tblBody = useSelector((state) => state.root.PMS.part_stock.map((el, i) => <tr key={i}>
        <td></td>
        <td>{el.item_name}</td>
        <td>{el.item_code}</td>
        <td>{el.current_rob}</td>
        <td>{el.consumed_rob}</td>
        <td><FormControl onBlur={(e) => NUMBER_REGEX.test(e.target.value) && Number(el.current_rob) > Number(e.target.value) ? handleStockChange({ ...el, qty: e.target.value, screen: 'PART' }) : (toast.error('Number only allowed/consume rob must be smaller than current rob!'), e.target.value = '')} /></td>
    </tr>))
    const oil_stock_tblBody = useSelector((state) => state.root.PMS.oil_stock.map((el, i) => <tr key={i}>
        <td></td>
        <td>{el.item_name}</td>
        <td>{el.item_code}</td>
        <td>{el.current_rob}</td>
        <td>{el.consumed_rob}</td>
        <td><FormControl style={{ width: '150px'}} onBlur={(e) => NUMBER_REGEX.test(e.target.value) && Number(el.current_rob) > Number(e.target.value) ? handleStockChange({ ...el, qty: e.target.value, screen: 'OIL' }) : (toast.error('Number only allowed/consume rob must be smaller than current rob!'), e.target.value = '')} /></td>
    </tr>))
    const handleStockChange = (arg) => {
        //let obj = generatePMSStockPayload({ ...arg,vessel_id, ref_no: maintenance_detail.code, ref_id: maintenance_detail.id })
        //let obj={...arg,vessel_id,ref_no:maintenance_detail.code,ref_id:maintenance_detail.id}
        arg.screen === 'PART' && dispatch(addPartStockPMS(arg))
        arg.screen === 'OIL' && dispatch(addOilStockPMS(arg))
        //console.log(arg, 'obj');
    }
    return (
        <>
            <Row className='d-flex align-items-center mb-3'>
                <Col md={2} >
                    <FormLabel className=''>Consumption</FormLabel>
                </Col>
                <Col md={10}>
                    <hr />
                </Col>               
            </Row>
            <Row>
                <Col md={12}>
                    <Tabs defaultActiveKey="part" className="mb-3">
                        <Tab eventKey="part" title="Part">
                            <Table responsive bordered className="stripeTbl mt-3 completeTable">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Part Name</th>
                                        <th>part code</th>
                                        <th>Current ROB</th>
                                        <th>Consumed ROB</th>
                                        <th>Consume ROB</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {part_stock_tblBody}
                                </tbody>
                            </Table>
                        </Tab>
                        <Tab eventKey="oil" title="Oil">
                            <Table responsive bordered className="stripeTbl mt-3 completeTable">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Oil Name</th>
                                        <th>Oil code</th>
                                        <th>Current ROB</th>
                                        <th>Consumed ROB</th>
                                        <th>Consume ROB</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {oil_stock_tblBody}
                                </tbody>
                            </Table>

                        </Tab>
                        {/* <Tab eventKey="survey" title="Survey">
                            {maintenance_detail.survey}
                        </Tab> */}
                    </Tabs>
                </Col>
            </Row>
        </>
    )
}

export default WOConsumption