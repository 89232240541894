import React from "react";

export const MoreSelectedBadge = ({ items }) => {
  const style = {
    marginLeft: "auto",
    background: "#d4eefa",
    borderRadius: "4px",
    fontFamily: "Poppins",
    fontSize: "10px",
    padding: "2px 3px",
    order: 99,
  };

  const title = items.join(", ");
  const { length } = items;
  const label = `+ ${length} more`;

  return (
    <div style={style} title={title}>
      {label}
    </div>
  );
};
