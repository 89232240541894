import { combineReducers } from "redux";
import loginReducer from "./loginSlice"
import departmentReducer from './departmentSlice'
import designationReducer from './designationSlice'
import productReducer from './productSlice'
import rolesReducer from "./rolesSlice";
import rolesAccessReducer from "./rolesAccessSlice";
import moduleReducer from "./moduleSlice";
import menuReducer from "./MenuSlice";
import approvalReducer from "./approvalSlice";
import approveLevelReducer from "./approveLevelSlice";
import approvelLinkReducer from "./approveLinkSlice";
import userAccessReducer from "./userAccessSlice";
import statusReducer from "./statusSlice";
import vesselReducer from "./vesselSlice";
import projectReducer from "./projectSlice";
import gradeReducer from "./gradeSlice";
import typeReducer from "./typeSlice";
import oilProductReducer from "./oilProductSlice";
import productGeneralReducer from "./productGeneralSlice";
import commonReducer from './commonSlice';
import searchPurchaseReducer from "./searchPurchaseSlice";
import purchaseCodeReducer from "./purchaseCodeSlice";
import typeFieldsReducer from "./typeFieldsSlice";
import themeReducer from "./themeSlice";
import userReducer from "./userSlice";
import purchaseRequisitionReducer from "./purchaseRequisitionSlice";
import supplierReducer from "./supplierSlice";
import productSupplierReducer from "./productSupplierSlice";
import RFQReducer from "./RFQSlice";
import StoreReducer from "./storeSlice";
import POReducer from "./POSlice";
import QuotationReducer from "./QuotationSlice";
import partsReducer from "./partsSlice";
import equipmentReducer from "./equipmentSlice";
import contractRateSlice from "./contractRateSlice";
import goodsSlice from "./goodsSlice";
import InvoiceSlice from "./InvoiceSlice";
import ReportSlice from "./ReportSlice";
import LocationSlice from "./LocationSlice";
import rollbackSlice from "./rollbackSlice";
import portSlice from "./portSlice";
import pmsSlice from "./pmsSlice";
import attachmentsSlice from "./attachmentsSlice";
import CERTIFICATESlice from "./certificatePmsSlice";
import runninHourPmsSlice from "./runninHourPmsSlice";
import loaderSlice from "./loaderSlice";
import UOMSlice from "./UOMSlice";
import maintenanceReportSlice from "./maintenanceReportSlice";
import certificateReportSlice from "./certificateReportSlice";
import pmsAnalysisSlice from "./pmsAnalysisSlice";
import workOrderSlice from "./workOrderSlice";
import safetyMasterSlice from "./safetyMasterSlice";
import EDocMasterSlice from "./EDocMasterSlice";
import InventoryReportSlice from "./InventoryReportSlice";
import meetingSlice from "./meetingSlice";
import incidentMasterSlice from "./IncidentMasterSlice"
import incidentDropdownSlice from "./incidentDropdownSlice";
import vesselInspectionSlice from "./vesselInspectionSlice";
import typeMasterSlice from "./typeMasterSlice";
import safetyReportsSlice from "./safetyReportsSlice";
import globalSlice from "./globalSlice";
export const rootReducer = combineReducers({
    LOADER: loaderSlice,
    GLOBAL: globalSlice,
    login: loginReducer,
    ATTACHMENT: attachmentsSlice,
    user: userReducer,
    department: departmentReducer,
    designation: designationReducer,
    product: productReducer,
    roles: rolesReducer,
    rolesAccess: rolesAccessReducer,
    module: moduleReducer,
    location: LocationSlice,
    menu: menuReducer,
    common: commonReducer,
    approval: approvalReducer,
    approveLevel: approveLevelReducer,
    approveLink: approvelLinkReducer,
    userAccess: userAccessReducer,
    status: statusReducer,
    vessel: vesselReducer,
    project: projectReducer,
    grade: gradeReducer,
    type: typeReducer,
    oilProduct: oilProductReducer,
    productGeneral: productGeneralReducer,
    purchase: searchPurchaseReducer,
    purchaseCode: purchaseCodeReducer,
    supplier: supplierReducer,
    productSupplier: productSupplierReducer,
    typeFields: typeFieldsReducer,
    theme: themeReducer,
    pr_requisition: purchaseRequisitionReducer,
    RFQ: RFQReducer,
    store: StoreReducer,
    equipment: equipmentReducer,
    PO: POReducer,
    QO: QuotationReducer,
    PARTS: partsReducer,
    contract: contractRateSlice,
    GOODS: goodsSlice,
    INVOICE: InvoiceSlice,
    REPORTS: ReportSlice,
    rollback: rollbackSlice,
    port: portSlice,
    PMS: pmsSlice,
    CERTIFICATE: CERTIFICATESlice,
    RUNNING: runninHourPmsSlice,
    UOM: UOMSlice,
    MAINTENANCE_REPORT: maintenanceReportSlice,
    CERTIFICATE_REPORT: certificateReportSlice,
    ANALYSIS: pmsAnalysisSlice,
    WO: workOrderSlice,
    safety: safetyMasterSlice,
    EDOC: EDocMasterSlice,
    INVENTORY_REPORT: InventoryReportSlice,
    MEETING: meetingSlice,
    incident: incidentMasterSlice,
    incident_dropdown: incidentDropdownSlice,
    vessel_inspection: vesselInspectionSlice,
    typemaster: typeMasterSlice,
    safetyReportsSlice: safetyReportsSlice,
})