
import { createSlice, current } from "@reduxjs/toolkit";
import { AddEditResponse, ValidateResponse } from "../Services/Support";
//import { getAuditDropdown, getAuditMasterList, getAuditMasterbyId, getCategory, getCategorybyId, getChecklist, getChecklistbyId, getISM, getISMbyId, getNCType, getNCTypebyId, getNCcategoryList, getNCcategorybyId, getSafetyColor, getSafetyConditionList, getSafetyConditionbyId, getSafetyStatus, getSubCategory, getSubCategorybyId,  saveAuditMaster, saveCategory, saveCheckList, saveISM, saveNCType, saveNCcategory, saveSafetyCondition, saveSubCategory } from "./THUNK/safetyMasterThunk";
import {
    getAuditDropdown,
    getAuditMasterList,
    getAuditMasterbyId,
    getCategory,
    getCategorybyId,
    getChecklist,
    getChecklistbyId,
    getISM,
    getISMbyId,
    getNCType,
    getNCTypebyId,
    getNCcategoryList,
    getNCcategorybyId,
    getSafetyColor,
    getSafetyConditionList,
    getSafetyConditionbyId,
    getSafetyStatus,
    getSubCategory,
    getSubCategorybyId,
    saveAuditMaster,
    saveCategory,
    saveCheckList,
    saveISM,
    saveNCType,
    saveNCcategory,
    saveSafetyCondition,
    saveSubCategory,
    getInspectionMasterList, getInspectionMasterById, saveSafetyChecklistGroup, autoSearchChecklistGroup, saveInspectionMaster, getChecklistBaseAuditId, saveChecklistTransaction, getChecklistGroupbyId, getChecklistTransaction, getChecklistTransactionbyId, getAuditScheduleList, getAuditSchedulebyId, saveAuditSchedule, getAuditTypeDropdown, getAuditCompletionList, getAuditCompletionbyId, saveAuditCompletion, getVesselBasedSchedule, getInspectionChecklistTransaction, getInspectionChecklistTransactionbyId, saveInspectionChecklistTransaction, saveInspectionSchedule, getInspectionSchedulebyId, getInspectionScheduleList, getInspectionTypeDropdown, getInspectionCompletionList, getInspectionCompletionbyId, saveInspectionCompletion, getInspectionDropdown, getInspectionVesselBasedSchedule, getChecklistGroup, getDataBasedChecklist, saveAuditRecord, getAuditRecordList, getAuditRecordById, saveNotesFollowup, getNotesFollowup
} from "./THUNK/safetyMasterThunk";
import { ConvertJson } from "../helper";
import moment from "moment";
import { parseISO } from "date-fns";
import { distribution_ncobs, ncobs_for_auditRecord } from "../constant/CommonInitialvalue";

const initialState = {
    checklist: [],
    schedulelist: [],
    check_list: [],
    editRecord: {
        checklist: [],
    },
    audit_list: [],
    inspection_list: [],
    category_list: [],
    sub_category_list: [],
    ism_list: [],
    nc_type_list: [],
    nc_category: [],
    status_condition: [],
    status_color: [],
    audit_dropdown: [],
    checklist_group_dropdown: [],
    checklist_group: [],
    checklist_based_audit: [],
    checklist_based_audit_backup: [],
    list: [],
    editChecklistRecord: {},
    audit_type_dropdown: [],
    auditCompleteionDetail: [],
    inspectionCompleteionDetail: [],
    vesselBaselist: [],
    schedule_frequency_data: {
        checklist_name: []
    },
    implement_department: [],
    implement_designation: [],
    implement_user: [],
    verify_department: [],
    verify_designation: [],
    verify_user: [],
    review_department: [],
    review_designation: [],
    review_user: [],
    case_department: [],
    case_designation: [],
    case_user: [],
    notes_followup: []
}
const safetyMasterSlice = createSlice({
    name: 'SAFETY',
    initialState,
    reducers: {
        clearSafetyList: (state, action) => {
            state.check_list = []
        },
        clearSafetyEdit: (state, action) => {
            state.editRecord = {}
            state.editChecklistRecord = {}
            state.checklist_based_audit = []
            state.checklist_based_audit_backup = []
        },
        deleteChecklistGroup: (state, action) => {
            state.checklist_based_audit = state.checklist_based_audit.filter(el => el.id !== action.payload)
            state.checklist_based_audit_backup = state.checklist_based_audit.filter(el => el.id !== action.payload)
        },
        searchChecklistGroup: (state, action) => {
            if (action.payload !== "") {
                let filterData = []
                state.checklist_based_audit_backup.map(el => el.checklist_group.toLowerCase().search(action.payload.toLowerCase()) !== -1 && filterData.push(el))
                state.checklist_based_audit = filterData
            } else {
                state.checklist_based_audit = state.checklist_based_audit_backup
            }
        },
        setChecklistGroupDetail: (state, action) => {
            let filterArray = state.checklist_based_audit
            let currentIndex = state.checklist_based_audit.findIndex(el => el.id === action.payload.id)
            filterArray[currentIndex] = action.payload
            state.checklist_based_audit = filterArray
        },
        setCompletionDetail: (state, action) => {
            let filterArray = state.auditCompleteionDetail
            let currentIndex = state.auditCompleteionDetail.findIndex(el => el.id === action.payload.id)
            filterArray[currentIndex] = action.payload
            state.auditCompleteionDetail = filterArray
        },
        setInspectionCompletionDetail: (state, action) => {
            let filterArray = state.inspectionCompleteionDetail
            let currentIndex = state.inspectionCompleteionDetail.findIndex(el => el.id === action.payload.id)
            filterArray[currentIndex] = action.payload
            state.inspectionCompleteionDetail = filterArray
        },
        setNCOBSdistribution: (state, action) => {
            switch (action.payload?.section) {
                case 'DISTRIBUTION_DEPARTMENT':
                    state.implement_department = action.payload.data
                    state.verify_department = action.payload.data
                    state.review_department = action.payload.data
                    state.case_department = action.payload.data
                    break;
                case 'IMPLEMENT_DESIGNATION':
                    state.implement_designation = action.payload.data
                    break;
                case 'IMPLEMENT_USER':
                    state.implement_user = action.payload.data
                    break;
                case 'VERIFY_DESIGNATION':
                    state.verify_designation = action.payload.data
                    break;
                case 'VERIFY_USER':
                    state.verify_user = action.payload.data
                    break;
                case 'REVIEW_DESIGNATION':
                    state.review_designation = action.payload.data
                    break;
                case 'REVIEW_USER':
                    state.review_user = action.payload.data
                    break;
                case 'CASE_DESIGNATION':
                    state.case_designation = action.payload.data
                    break;
                case 'CASE_USER':
                    state.case_user = action.payload.data
                    break;
                case 'ALL':
                    state.implement_designation = []
                    state.implement_user = []
                    state.verify_designation = []
                    state.verify_user = []
                    state.review_designation = []
                    state.review_user = []
                    state.case_designation = []
                    state.case_user = []
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getChecklist.fulfilled, (state, action) => {
                state.check_list = ValidateResponse(action.payload)
            })
            .addCase(getChecklistbyId.fulfilled, (state, action) => {
                let response = ValidateResponse(action.payload)
                state.editRecord = response[0]
            })
            .addCase(saveCheckList.fulfilled, (state, action) => {
                AddEditResponse(action.payload)
            })
            .addCase(getAuditMasterList.fulfilled, (state, action) => {
                state.audit_list = ValidateResponse(action.payload)
            }).addCase(getInspectionMasterList.fulfilled, (state, action) => {
                console.log(action.payload)
                state.inspection_list = ValidateResponse(action.payload)
            })
            .addCase(getAuditMasterbyId.fulfilled, (state, action) => {
                let response = ValidateResponse(action.payload)
                let obj = response[0]
                obj.repository = obj.repository !== null ? ConvertJson(obj.repository) : []
                obj.checklist_item_group = obj.checklist_item_group !== null ? ConvertJson(obj.checklist_item_group) : []
                state.editRecord = obj
            }).addCase(getInspectionMasterById.fulfilled, (state, action) => {
                let response = ValidateResponse(action.payload)
                let obj = response[0]
                obj.repository = obj.repository !== null ? ConvertJson(obj.repository) : []
                state.editRecord = obj
            })
            .addCase(saveAuditMaster.fulfilled, (state, action) => {
                AddEditResponse(action.payload)
            }).addCase(saveInspectionMaster.fulfilled, (state, action) => {
                AddEditResponse(action.payload)
            })
            .addCase(getCategory.fulfilled, (state, action) => {
                state.category_list = ValidateResponse(action.payload)
            })
            .addCase(getDataBasedChecklist.fulfilled, (state, action) => {
                let obj = ValidateResponse(action.payload)
                console.log(obj)
                if (obj.length > 0) {
                    obj = ValidateResponse(action.payload)[0]
                    obj.checklist_name = obj?.checklist_name ? ConvertJson(obj.checklist_name) : []
                    obj.schedule_date = obj?.schedule_date ? ConvertJson(obj.schedule_date) : []
                    obj.checklist_completion = obj?.checklist_completion ? ConvertJson(obj.checklist_completion) : []
                    state.schedule_frequency_data = obj
                } else {
                    obj.checklist_name = []
                    obj.schedule_date = []
                    obj.checklist_completion = []
                    state.schedule_frequency_data = obj
                }
            })
            .addCase(getCategorybyId.fulfilled, (state, action) => {
                let response = ValidateResponse(action.payload)
                state.editRecord = response[0]
            })
            .addCase(saveCategory.fulfilled, (state, action) => {
                AddEditResponse(action.payload)
            })
            .addCase(getSubCategory.fulfilled, (state, action) => {
                state.sub_category_list = ValidateResponse(action.payload)
            })
            .addCase(getSubCategorybyId.fulfilled, (state, action) => {
                let response = ValidateResponse(action.payload)
                state.editRecord = response[0]
            })
            .addCase(saveSubCategory.fulfilled, (state, action) => {
                AddEditResponse(action.payload)
            })
            .addCase(getISM.fulfilled, (state, action) => {
                state.ism_list = ValidateResponse(action.payload)
            })
            .addCase(getISMbyId.fulfilled, (state, action) => {
                let response = ValidateResponse(action.payload)
                state.editRecord = response[0]
            })
            .addCase(saveISM.fulfilled, (state, action) => {
                AddEditResponse(action.payload)
            })
            .addCase(getNCType.fulfilled, (state, action) => {
                state.nc_type_list = ValidateResponse(action.payload)
            })
            .addCase(getNCTypebyId.fulfilled, (state, action) => {
                let response = ValidateResponse(action.payload)
                state.editRecord = response[0]
            })
            .addCase(saveNCType.fulfilled, (state, action) => {
                AddEditResponse(action.payload)
            })
            .addCase(getNCcategoryList.fulfilled, (state, action) => {
                state.nc_category = ValidateResponse(action.payload)
            })
            .addCase(getNCcategorybyId.fulfilled, (state, action) => {
                let response = ValidateResponse(action.payload)
                state.editRecord = response[0]
            })
            .addCase(saveNCcategory.fulfilled, (state, action) => {
                AddEditResponse(action.payload)
            })
            .addCase(getSafetyStatus.fulfilled, (state, action) => {
                state.status_condition = ValidateResponse(action.payload)
            })
            .addCase(getSafetyColor.fulfilled, (state, action) => {
                state.status_color = ValidateResponse(action.payload)
            })
            .addCase(getSafetyConditionList.fulfilled, (state, action) => {
                state.category_list = ValidateResponse(action.payload)
            })
            .addCase(getChecklistTransaction.fulfilled, (state, action) => {
                state.checklist = ValidateResponse(action.payload)
            })
            .addCase(getSafetyConditionbyId.fulfilled, (state, action) => {
                let response = ValidateResponse(action.payload)
                state.editRecord = response[0]
            })
            .addCase(saveSafetyCondition.fulfilled, (state, action) => {
                AddEditResponse(action.payload)
            })
            .addCase(saveSafetyChecklistGroup.fulfilled, (state, action) => {
                AddEditResponse(action.payload)
            })
            .addCase(saveChecklistTransaction.fulfilled, (state, action) => {
                AddEditResponse(action.payload)
            })
            .addCase(getAuditDropdown.fulfilled, (state, action) => {
                state.audit_dropdown = ValidateResponse(action.payload)
            })
            .addCase(getInspectionDropdown.fulfilled, (state, action) => {
                state.audit_dropdown = ValidateResponse(action.payload)
            })
            .addCase(getAuditTypeDropdown.fulfilled, (state, action) => {
                state.audit_type_dropdown = ValidateResponse(action.payload)
            })
            .addCase(getInspectionTypeDropdown.fulfilled, (state, action) => {
                state.audit_type_dropdown = ValidateResponse(action.payload)
            })
            .addCase(autoSearchChecklistGroup.fulfilled, (state, action) => {
                state.checklist_group_dropdown = ValidateResponse(action.payload)
            })
            .addCase(getChecklistBaseAuditId.fulfilled, (state, action) => {
                let obj = ValidateResponse(action.payload)[0]
                state.checklistForId = obj.checklist_for
                state.checklist_based_audit = obj?.checklist_item_group ? ConvertJson(obj?.checklist_item_group) : []
                state.checklist_based_audit_backup = obj?.checklist_item_group ? ConvertJson(obj?.checklist_item_group) : []
            })
            .addCase(getChecklistGroupbyId.fulfilled, (state, action) => {
                let response = ValidateResponse(action.payload)
                state.editRecord = response[0]
            })
            .addCase(getChecklistGroup.fulfilled, (state, action) => {
                state.checklist_group = ValidateResponse(action.payload)
            })
            .addCase(getChecklistTransactionbyId.fulfilled, (state, action) => {
                let obj = ValidateResponse(action.payload)[0]
                obj = {
                    ...obj,
                    preparation_date: obj?.preparation_date ? new Date(`${obj.preparation_date}Z`) : null,
                    effective_date: obj?.effective_date ? new Date(`${obj.effective_date}Z`) : null,
                    version_date_change: obj?.version_date_change ? new Date(`${obj.version_date_change}Z`) : null,
                    approve_date: obj?.approve_date ? new Date(`${obj.approve_date}Z`) : null
                }
                obj.repository = obj.repository !== null ? ConvertJson(obj.repository) : []
                obj.vessel_list = obj.vessel_list !== null ? ConvertJson(obj.vessel_list) : []
                obj.checklist_detail = obj.checklist_detail !== null ? ConvertJson(obj.checklist_detail) : []
                state.checklist_based_audit = obj.checklist_detail
                state.checklist_based_audit_backup = obj.checklist_detail
                state.editChecklistRecord = obj
            })
            .addCase(getAuditScheduleList.fulfilled, (state, action) => {
                state.schedulelist = ValidateResponse(action.payload)
            })
            .addCase(getAuditSchedulebyId.fulfilled, (state, action) => {
                let obj = ValidateResponse(action.payload)[0]
                obj.repository = obj.repository !== null ? ConvertJson(obj.repository) : []
                obj.audit_internal_members = obj.audit_internal_members !== null ? ConvertJson(obj.audit_internal_members) : []
                obj.checklist = obj.checklist !== null ? ConvertJson(obj.checklist) : []
                obj = {
                    ...obj,
                    schedule_from_date: obj?.schedule_from_date ? new Date(parseISO(obj.schedule_from_date)) : null,
                    schedule_to_date: obj?.schedule_to_date ? new Date(parseISO(obj.schedule_to_date)) : null,
                    effective_date: obj?.effective_date ? new Date(`${obj.effective_date}Z`) : null,
                }
                state.editRecord = obj
            })
            .addCase(saveAuditSchedule.fulfilled, (state, action) => {
                AddEditResponse(action.payload)
            })
            .addCase(getAuditCompletionList.fulfilled, (state, action) => {
                state.list = ValidateResponse(action.payload)
            })
            .addCase(getAuditRecordList.fulfilled, (state, action) => {
                state.list = ValidateResponse(action.payload)
            })
            .addCase(getAuditCompletionbyId.fulfilled, (state, action) => {
                let obj = ValidateResponse(action.payload)[0]
                obj.repository = obj.repository !== null ? ConvertJson(obj.repository) : []
                obj = {
                    ...obj,
                    schedule_from_date: obj?.schedule_from_date ? new Date(parseISO(obj.schedule_from_date)) : null,
                    schedule_to_date: obj?.schedule_to_date ? new Date(parseISO(obj.schedule_to_date)) : null,
                    effective_date: obj?.effective_date ? new Date(`${obj.effective_date}Z`) : null,
                    completion_date: obj?.completion_date ? new Date(`${obj.completion_date}Z`) : null,
                    auditCompleteionDetail: obj?.auditCompleteionDetail ? ConvertJson(obj.auditCompleteionDetail) : [],
                }
                state.editRecord = obj
                state.auditCompleteionDetail = obj.auditCompleteionDetail.map(el => ({ ...el, target_date: el?.target_date ? new Date(parseISO(el.target_date)) : null }))
            })
            .addCase(saveAuditCompletion.fulfilled, (state, action) => {
                AddEditResponse(action.payload)
            })
            .addCase(getVesselBasedSchedule.fulfilled, (state, action) => {
                state.vesselBaselist = ValidateResponse(action.payload)
                //state.vesselBaselist = obj.map(el => ({ ...el, vessel_data: ConvertJson(el.vessel_data) }))
            })
            .addCase(getInspectionVesselBasedSchedule.fulfilled, (state, action) => {
                state.vesselBaselist = ValidateResponse(action.payload)
                //state.vesselBaselist = obj.map(el => ({ ...el, vessel_data: ConvertJson(el.vessel_data) }))
            })
            .addCase(getInspectionChecklistTransaction.fulfilled, (state, action) => {
                state.checklist = ValidateResponse(action.payload)
            })
            .addCase(getInspectionChecklistTransactionbyId.fulfilled, (state, action) => {
                let obj = ValidateResponse(action.payload)[0]
                obj = {
                    ...obj,
                    preparation_date: obj?.preparation_date ? new Date(`${obj.preparation_date}Z`) : null,
                    effective_date: obj?.effective_date ? new Date(`${obj.effective_date}Z`) : null,
                    version_date_change: obj?.version_date_change ? new Date(`${obj.version_date_change}Z`) : null,
                    approve_date: obj?.approve_date ? new Date(`${obj.approve_date}Z`) : null
                }
                obj.repository = obj.repository !== null ? ConvertJson(obj.repository) : []
                obj.vessel_list = obj.vessel_list !== null ? ConvertJson(obj.vessel_list) : []
                obj.checklist_detail = obj.checklist_detail !== null ? ConvertJson(obj.checklist_detail) : []
                state.checklist_based_audit = obj.checklist_detail
                state.checklist_based_audit_backup = obj.checklist_detail
                state.editChecklistRecord = obj
            })
            .addCase(saveInspectionChecklistTransaction.fulfilled, (state, action) => {
                AddEditResponse(action.payload)
            })
            .addCase(getInspectionScheduleList.fulfilled, (state, action) => {
                state.schedulelist = ValidateResponse(action.payload)
            })
            .addCase(getInspectionSchedulebyId.fulfilled, (state, action) => {
                let obj = ValidateResponse(action.payload)[0]
                obj.repository = obj.repository !== null ? ConvertJson(obj.repository) : []
                obj = {
                    ...obj,
                    schedule_from_date: obj?.schedule_from_date ? new Date(parseISO(obj.schedule_from_date)) : null,
                    schedule_to_date: obj?.schedule_to_date ? new Date(parseISO(obj.schedule_to_date)) : null,
                    effective_date: obj?.effective_date ? new Date(`${obj.effective_date}Z`) : null,
                }
                obj.inspection_internal_members = obj.inspection_internal_members !== null ? ConvertJson(obj.inspection_internal_members) : []
                obj.checklist = obj.checklist !== null ? ConvertJson(obj.checklist) : []
                state.editRecord = obj
            })
            .addCase(saveInspectionSchedule.fulfilled, (state, action) => {
                AddEditResponse(action.payload)
            })
            .addCase(getInspectionCompletionList.fulfilled, (state, action) => {
                state.list = ValidateResponse(action.payload)
            })
            .addCase(getInspectionCompletionbyId.fulfilled, (state, action) => {
                let obj = ValidateResponse(action.payload)[0]
                obj.repository = obj.repository !== null ? ConvertJson(obj.repository) : []
                obj = {
                    ...obj,
                    schedule_from_date: obj?.schedule_from_date ? new Date(parseISO(obj.schedule_from_date)) : null,
                    schedule_to_date: obj?.schedule_to_date ? new Date(parseISO(obj.schedule_to_date)) : null,
                    effective_date: obj?.effective_date ? new Date(`${obj.effective_date}Z`) : null,
                    completion_date: obj?.completion_date ? new Date(`${obj.completion_date}Z`) : null,
                    inspectionCompleteionDetail: obj?.inspectionCompleteionDetail ? ConvertJson(obj.inspectionCompleteionDetail) : [],
                }
                state.editRecord = obj
                state.inspectionCompleteionDetail = obj.inspectionCompleteionDetail.map(el => ({ ...el, target_date: el?.target_date ? new Date(parseISO(el.target_date)) : null }))
            })
            .addCase(saveInspectionCompletion.fulfilled, (state, action) => {
                AddEditResponse(action.payload)
            })
            .addCase(saveAuditRecord.fulfilled, (state, action) => {
                AddEditResponse(action.payload)
            })
            .addCase(getAuditRecordById.fulfilled, (state, action) => {
                let obj = ValidateResponse(action.payload)[0]
                obj.ncobsdistribution = obj.ncobsdistribution !== null ? ConvertJson(obj.ncobsdistribution)[0] : [{ ...distribution_ncobs }]
                obj.ncobsrecord = obj.ncobsrecord !== null ? ConvertJson(obj.ncobsrecord) : [{ ...ncobs_for_auditRecord }]
                obj.repository = obj.repository !== null ? ConvertJson(obj.repository) : []
                obj.ncobs_repository = obj.ncobs_repository !== null ? ConvertJson(obj.ncobs_repository) : []
                state.editRecord = obj
            })
            .addCase(saveNotesFollowup.fulfilled, (state, action) => {
                AddEditResponse(action.payload)
            })
            .addCase(getNotesFollowup.fulfilled, (state, action) => {
                state.notes_followup = ValidateResponse(action.payload)
            })
    }
})
export const { setNCOBSdistribution, setChecklistGroupDetail, setInspectionCompletionDetail, clearSafetyList, setCompletionDetail, clearSafetyEdit, deleteChecklistGroup, searchChecklistGroup } = safetyMasterSlice.actions
export default safetyMasterSlice.reducer