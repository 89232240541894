import React, { useEffect, useState } from "react";
// import { FiChevronsLeft, FiChevronsRight } from "react-icons/fi/";
import {
    Sidebar,
    SubMenu,
    Menu,
    MenuItem
    //useProSidebar
} from "react-pro-sidebar";
import logo from "../../assets/images/logo.svg";
import { Link } from "react-router-dom";
import {
    ACCOUNT_INVENTORY_MENU,
    ACCOUNT_PURCHASE_MENU,
    ACCOUNT_SETUP_MENU, DASHBOARD_MENU, EdocReports, INVENTORY_MASTER_MENU,
    MASTERS_SCREENS_MENU, OilReports, PartReports,
    PurchaseReports, SafetyReports, StoreReports,
} from "../../constant/CommonInitialvalue";
import SimpleBar from "simplebar-react";
import { IncidentMasterName } from "../../pages/SAFETY/INCIDENT/MASTER/MasterName";
import { useSelector } from "react-redux";
import { hasPermission } from "../../helper/permissions";
import { getAllData } from "../../features/globalSlice";
import { SASS_MASTER } from "../../constant/CommonApiUrl";
// import { ConvertJson } from "../../helper";
import { useDispatch } from "react-redux";
import AdminMenu from "./AdminMenu";
import { fetchModuleApi } from "../../features/moduleSlice";

function Sidebars({ setProfile }) {
    //const { collapseSidebar } = useProSidebar();
    const dispatch = useDispatch()
    const [collapsed, setCollapsed] = useState(false);
    const [toggled, setToggled] = useState(true);
    const { Reports = [], Process = [], tenant_id, is_admin } = useSelector((state) => state.root.login.loginDemo);
    const { tenantDataApi = [] } = useSelector((state) => state.root.GLOBAL);
    const moduleList= useSelector((state) => state.root.module.list);

    const tenantResult = tenantDataApi[0]?.moudles ? tenantDataApi[0]?.moudles : " "
    useEffect(() => {
        dispatch(fetchModuleApi());
    }, [])
    useEffect(() => {
        tenant_id && dispatch(getAllData({ tenantDataApi: `${SASS_MASTER}GetMenuForSaas?tenant_id=${tenant_id}` }))
    }, [tenant_id]);
    const findPermissionProcess = (menuname, modulename) => Process.find((v) => v?.menuname.toLowerCase() === menuname?.toLowerCase() && v?.modulename?.toLowerCase() === modulename?.toLowerCase());
    const findPermissionReports = (menuname, modulename) => Reports.find((v) => v?.menuname === menuname && v?.modulename === modulename);
    const MenuItemWithPermission = ({ menuName, modulename, to, label }) => (
        hasPermission('view', findPermissionProcess(menuName, modulename)?.permission) && (
            <MenuItem icon={<span>&#8901;</span>} className='entity'>
                <Link className="dropdown-item" to={`${to}`}>
                    {label}
                </Link>
            </MenuItem>
        )
    );
    const MenuItemWithPermissionReport = ({ menuName, modulename, to, label }) => (
        hasPermission('view', findPermissionReports(menuName, modulename)?.permission) && (
            <MenuItem icon={<span>&#10070;</span>} className='entity'>
                <Link className="dropdown-item" to={`${to}`}>
                    {label}
                </Link>
            </MenuItem>
        )
    );
    const handleCollapsedChange = () => {
        !collapsed ? document.body.classList.add('sideBar') : document.body.classList.remove('sideBar');
        setCollapsed(!collapsed);
        setProfile(!collapsed);
    };
    const handleToggleSidebar = (value) => {
        setToggled(value);
    };

    return (
        <div>
            <Sidebar
                defaultCollapsed={true}
                className={`app ${toggled ? "toggled" : ""}`}

                // width: 143px;
                // font-size: 10px;
                // text-align: justify;
                // text-decoration: auto;

                style={{ height: "100%", position: "fixed", marginTop: '-46px', fontSize: "10px", marginBottom: '25px' }}
                collapsed={collapsed}
                toggled={toggled}
                handleToggleSidebar={handleToggleSidebar}
                handleCollapsedChange={handleCollapsedChange}
            >
                <main>
                    <SimpleBar style={{ maxHeight: '92vh' }} autoHide={false}>
                        <Menu>
                            <MenuItem
                                suffix={<svg className="arrowIcon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M7.96997 2V22M21.97 15V9C21.97 4 19.97 2 14.97 2H8.96997C3.96997 2 1.96997 4 1.96997 9V15C1.96997 20 3.96997 22 8.96997 22H14.97C19.97 22 21.97 20 21.97 15Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M14.97 9.43994L12.41 11.9999L14.97 14.5599" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>}
                                onClick={handleCollapsedChange}
                            >
                                <div
                                    style={{
                                        padding: "0 9px",
                                        // textTransform: "uppercase",
                                        fontWeight: "bold",
                                        // fontSize: 14,
                                        textAlign: 'center',
                                        letterSpacing: "1px"
                                    }}
                                >
                                    <img style={{ width: '70%' }} src={logo} alt="logo" />
                                </div>
                            </MenuItem>

                            {/* <hr /> */}
                        </Menu>

                        <Menu>
                            {
                                is_admin && <AdminMenu />
                            }
                            {(tenantResult.includes("DASHBOARD") && tenant_id !== null) ? <SubMenu
                                icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M2.5192 7.82274C2 8.77128 2 9.91549 2 12.2039V13.725C2 17.6258 2 19.5763 3.17157 20.7881C4.34315 22 6.22876 22 10 22H14C17.7712 22 19.6569 22 20.8284 20.7881C22 19.5763 22 17.6258 22 13.725V12.2039C22 9.91549 22 8.77128 21.4808 7.82274C20.9616 6.87421 20.0131 6.28551 18.116 5.10812L16.116 3.86687C14.1106 2.62229 13.1079 2 12 2C10.8921 2 9.88939 2.62229 7.88403 3.86687L5.88403 5.10813C3.98695 6.28551 3.0384 6.87421 2.5192 7.82274ZM11.25 18C11.25 18.4142 11.5858 18.75 12 18.75C12.4142 18.75 12.75 18.4142 12.75 18V15C12.75 14.5858 12.4142 14.25 12 14.25C11.5858 14.25 11.25 14.5858 11.25 15V18Z" fill="white" />
                                </svg>}
                                label={'Dashboard'}
                            >
                                {/*<Link to="/dashboard" className="active">*/}
                                {/*    <span>Dashboard</span>*/}
                                {/*</Link>*/}
                                <SimpleBar style={{ maxHeight: 300 }} autoHide={false}>
                                    {DASHBOARD_MENU.map((x) => (
                                        hasPermission('view', findPermissionProcess('DashBoard', x.menu)?.permission) &&
                                        <MenuItem key={`${x.id}_master_${x.menu}`} icon={<span>&#10070;</span>} className='entity'>
                                            <Link className="dropdown-item" to={x.path}>
                                                {x.menu}
                                            </Link>
                                        </MenuItem>
                                    ))}
                                </SimpleBar>
                            </SubMenu> : tenant_id === null && <SubMenu
                                icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M2.5192 7.82274C2 8.77128 2 9.91549 2 12.2039V13.725C2 17.6258 2 19.5763 3.17157 20.7881C4.34315 22 6.22876 22 10 22H14C17.7712 22 19.6569 22 20.8284 20.7881C22 19.5763 22 17.6258 22 13.725V12.2039C22 9.91549 22 8.77128 21.4808 7.82274C20.9616 6.87421 20.0131 6.28551 18.116 5.10812L16.116 3.86687C14.1106 2.62229 13.1079 2 12 2C10.8921 2 9.88939 2.62229 7.88403 3.86687L5.88403 5.10813C3.98695 6.28551 3.0384 6.87421 2.5192 7.82274ZM11.25 18C11.25 18.4142 11.5858 18.75 12 18.75C12.4142 18.75 12.75 18.4142 12.75 18V15C12.75 14.5858 12.4142 14.25 12 14.25C11.5858 14.25 11.25 14.5858 11.25 15V18Z" fill="white" />
                                </svg>}
                                label={'Dashboard'}
                            >
                                {/*<Link to="/dashboard" className="active">*/}
                                {/*    <span>Dashboard</span>*/}
                                {/*</Link>*/}
                                <SimpleBar style={{ maxHeight: 300 }} autoHide={false}>
                                    {DASHBOARD_MENU.map((x) => (
                                        hasPermission('view', findPermissionProcess('DashBoard', x.menu)?.permission) &&
                                        <MenuItem key={`${x.id}_master_${x.menu}`} icon={<span>&#10070;</span>} className='entity'>
                                            <Link className="dropdown-item" to={x.path}>
                                                {x.menu}
                                            </Link>
                                        </MenuItem>
                                    ))}
                                </SimpleBar>
                            </SubMenu>}
                            {(tenantResult.includes("INVENTORY") && tenant_id !== null) ? moduleList?.find(e => e.moduleName === "Inventory") && <SubMenu
                                label={<Link to={hasPermission('view', findPermissionProcess('DashBoard', 'Inventory')?.permission) ? "/inventory" : "#"}><span>Inventory</span> </Link>}
                                icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M17.5777 4.43152L15.5777 3.38197C13.8221 2.46066 12.9443 2 12 2C11.0557 2 10.1779 2.46066 8.42232 3.38197L6.42232 4.43152C4.64858 5.36234 3.60593 5.9095 2.95972 6.64132L12 11.1615L21.0403 6.64132C20.3941 5.9095 19.3515 5.36234 17.5777 4.43152Z" fill="white" />
                                    <path d="M21.7484 7.96436L12.75 12.4635V21.904C13.4679 21.7252 14.2848 21.2965 15.5777 20.618L17.5777 19.5685C19.7294 18.4393 20.8052 17.8748 21.4026 16.8603C22 15.8458 22 14.5833 22 12.0585V11.9415C22 10.0489 22 8.86559 21.7484 7.96436Z" fill="white" />
                                    <path d="M11.25 21.904V12.4635L2.25164 7.96436C2 8.86559 2 10.0489 2 11.9415V12.0585C2 14.5833 2 15.8458 2.5974 16.8603C3.19479 17.8748 4.27063 18.4393 6.42229 19.5685L8.42229 20.618C9.71524 21.2965 10.5321 21.7252 11.25 21.904Z" fill="white" />
                                </svg>}
                            >

                                <SubMenu className={'safety'} label={"Process"} icon={''}>
                                    <SimpleBar style={{ maxHeight: 300 }} autoHide={false}>
                                        {
                                            hasPermission('view', findPermissionProcess('Inventory', 'EQUIPMENT')?.permission) &&
                                            <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                                <Link className="dropdown-item" to="/inventory/equipment">
                                                    Equipment
                                                </Link>
                                            </MenuItem>
                                        }

                                        {
                                            hasPermission('view', findPermissionProcess('Inventory', 'PART')?.permission) && <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                                <Link className="dropdown-item" to="/inventory/partPreview">
                                                    Part
                                                </Link>
                                            </MenuItem>
                                        }

                                        {
                                            INVENTORY_MASTER_MENU.map((item) => {
                                                const menu = item.menu.toUpperCase()
                                                return hasPermission('view', findPermissionProcess('Inventory', menu)?.permission) &&
                                                    <MenuItem key={item.id} icon={<span>&#10070;</span>} className='entity'>
                                                        <Link className="dropdown-item" to={item.path}>
                                                            {item.menu}
                                                        </Link>
                                                    </MenuItem>
                                            })
                                        }
                                        {
                                            hasPermission('view', findPermissionProcess('Inventory', 'STORE')?.permission) && <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                                <Link className="dropdown-item" to="/inventory/store">
                                                    Store
                                                </Link>
                                            </MenuItem>
                                        }

                                    </SimpleBar>
                                </SubMenu>

                                <SubMenu className={'safety'} label={"Reports"} icon={''}>

                                    {hasPermission('view', findPermissionReports('Inventory', 'Equipment Report')?.permission) &&
                                        <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                            <Link className="dropdown-item" to="/reports/equipment">
                                                Equipment
                                            </Link>
                                        </MenuItem>
                                    }

                                    {
                                        hasPermission('view', findPermissionReports('Inventory', 'Part')?.permission) && <SubMenu
                                            className={'entity'}
                                            // label={<Link to="/reports/parts"><span>Part</span> </Link>}
                                            label={<span>Part</span>}
                                            icon={<span>&#10070;</span>}
                                        >
                                            <SimpleBar style={{ maxHeight: 300 }} autoHide={false}>
                                                {PartReports.map((x, i) => (
                                                    hasPermission('view', findPermissionReports('Inventory', x.menu)?.permission) && (
                                                        <MenuItem icon={<span>&#8901;</span>} className='entity' key={`${i}_report_${x.menu}`}>
                                                            <Link className="dropdown-item" to={`/reports${x.path}`}>
                                                                {x.menu}
                                                            </Link>
                                                        </MenuItem>
                                                    )
                                                ))}
                                            </SimpleBar>
                                        </SubMenu>
                                    }

                                    {
                                        hasPermission('view', findPermissionReports('Inventory', 'Oil Product')?.permission) &&
                                        <SubMenu className={'entity'} label={<Link to="/reports/oil-product"><span>Oil Product</span> </Link>} icon={<span>&#10070;</span>}>
                                            <SimpleBar style={{ maxHeight: 300 }} autoHide={false}>
                                                {OilReports.map((x, i) => (
                                                    hasPermission('view', findPermissionReports('Inventory', x.menu)?.permission) && (
                                                        <MenuItem icon={<span>&#8901;</span>} className='entity' key={`${i}_report_${x.menu}`}>
                                                            <Link className="dropdown-item" to={`/reports${x.path}`}>
                                                                {x.menu}
                                                            </Link>
                                                        </MenuItem>
                                                    )
                                                ))}
                                            </SimpleBar>
                                        </SubMenu>
                                    }


                                    {
                                        hasPermission('view', findPermissionReports('Inventory', 'Product General')?.permission) && (
                                            <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                                <Link className="dropdown-item" to="/reports/product-general">
                                                    Product General
                                                </Link>
                                            </MenuItem>
                                        )
                                    }


                                    {
                                        hasPermission('view', findPermissionReports('Inventory', 'Store')?.permission) &&
                                        <SubMenu
                                            className={'entity'}
                                            // label={<Link to="/reports/store"><span>Store</span> </Link>}
                                            label={<span>Store</span>}
                                            icon={<span>&#10070;</span>}
                                        >
                                            <SimpleBar style={{ maxHeight: 300 }} autoHide={false}>
                                                {StoreReports.map((x, i) => (
                                                    hasPermission('view', findPermissionReports('Inventory', x.menu)?.permission) && (
                                                        <MenuItem icon={<span>&#8901;</span>} className='entity' key={`${i}_report_${x.menu}`}>
                                                            <Link className="dropdown-item" to={`/reports${x.path}`}>
                                                                {x.menu}
                                                            </Link>
                                                        </MenuItem>
                                                    )
                                                ))}
                                            </SimpleBar>
                                        </SubMenu>
                                    }
                                </SubMenu>
                            </SubMenu> : tenant_id === null && moduleList?.find(e => e.moduleName === "Inventory") && <SubMenu
                                label={<Link to={hasPermission('view', findPermissionProcess('DashBoard', 'Inventory')?.permission) ? "/inventory" : "#"}><span>Inventory</span> </Link>}
                                icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M17.5777 4.43152L15.5777 3.38197C13.8221 2.46066 12.9443 2 12 2C11.0557 2 10.1779 2.46066 8.42232 3.38197L6.42232 4.43152C4.64858 5.36234 3.60593 5.9095 2.95972 6.64132L12 11.1615L21.0403 6.64132C20.3941 5.9095 19.3515 5.36234 17.5777 4.43152Z" fill="white" />
                                    <path d="M21.7484 7.96436L12.75 12.4635V21.904C13.4679 21.7252 14.2848 21.2965 15.5777 20.618L17.5777 19.5685C19.7294 18.4393 20.8052 17.8748 21.4026 16.8603C22 15.8458 22 14.5833 22 12.0585V11.9415C22 10.0489 22 8.86559 21.7484 7.96436Z" fill="white" />
                                    <path d="M11.25 21.904V12.4635L2.25164 7.96436C2 8.86559 2 10.0489 2 11.9415V12.0585C2 14.5833 2 15.8458 2.5974 16.8603C3.19479 17.8748 4.27063 18.4393 6.42229 19.5685L8.42229 20.618C9.71524 21.2965 10.5321 21.7252 11.25 21.904Z" fill="white" />
                                </svg>}
                            >

                                <SubMenu className={'safety'} label={"Process"} icon={''}>
                                    <SimpleBar style={{ maxHeight: 300 }} autoHide={false}>
                                        {
                                            findPermissionProcess('Inventory', 'EQUIPMENT')?.permission.length > 0 &&
                                            <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                                <Link className="dropdown-item" to="/inventory/equipment">
                                                    Equipment
                                                </Link>
                                            </MenuItem>
                                        }

                                        {
                                            findPermissionProcess('Inventory', 'PART')?.permission.length > 0 && <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                                <Link className="dropdown-item" to="/inventory/partPreview">
                                                    Part
                                                </Link>
                                            </MenuItem>
                                        }

                                        {
                                            INVENTORY_MASTER_MENU.map((item) => {
                                                const menu = item.menu.toUpperCase()
                                                return findPermissionProcess('Inventory', menu)?.permission.length > 0 &&
                                                    <MenuItem key={item.id} icon={<span>&#10070;</span>} className='entity'>
                                                        <Link className="dropdown-item" to={item.path}>
                                                            {item.menu}
                                                        </Link>
                                                    </MenuItem>
                                            })
                                        }
                                        {
                                            hasPermission('view', findPermissionProcess('Inventory', 'STORE')?.permission) && <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                                <Link className="dropdown-item" to="/inventory/store">
                                                    Store
                                                </Link>
                                            </MenuItem>
                                        }

                                    </SimpleBar>
                                </SubMenu>

                                <SubMenu className={'safety'} label={"Reports"} icon={''}>

                                    {hasPermission('view', findPermissionReports('Inventory', 'Equipment Report')?.permission) &&
                                        <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                            <Link className="dropdown-item" to="/reports/equipment">
                                                Equipment
                                            </Link>
                                        </MenuItem>
                                    }

                                    {
                                        hasPermission('view', findPermissionReports('Inventory', 'Part')?.permission) && <SubMenu
                                            className={'entity'}
                                            // label={<Link to="/reports/parts"><span>Part</span> </Link>}
                                            label={<span>Part</span>}
                                            icon={<span>&#10070;</span>}
                                        >
                                            <SimpleBar style={{ maxHeight: 300 }} autoHide={false}>
                                                {PartReports.map((x, i) => (
                                                    hasPermission('view', findPermissionReports('Inventory', x.menu)?.permission) && (
                                                        <MenuItem icon={<span>&#8901;</span>} className='entity' key={`${i}_report_${x.menu}`}>
                                                            <Link className="dropdown-item" to={`/reports${x.path}`}>
                                                                {x.menu}
                                                            </Link>
                                                        </MenuItem>
                                                    )
                                                ))}
                                            </SimpleBar>
                                        </SubMenu>
                                    }

                                    {
                                        hasPermission('view', findPermissionReports('Inventory', 'Oil Product')?.permission) &&
                                        <SubMenu className={'entity'} label={<span>Oil Product</span>} icon={<span>&#10070;</span>}>
                                            <SimpleBar style={{ maxHeight: 300 }} autoHide={false}>
                                                {OilReports.map((x, i) => (
                                                    hasPermission('view', findPermissionReports('Inventory', x.menu)?.permission) && (
                                                        <MenuItem icon={<span>&#8901;</span>} className='entity' key={`${i}_report_${x.menu}`}>
                                                            <Link className="dropdown-item" to={`/reports${x.path}`}>
                                                                {x.menu}
                                                            </Link>
                                                        </MenuItem>
                                                    )
                                                ))}
                                            </SimpleBar>
                                        </SubMenu>
                                    }


                                    {
                                        hasPermission('view', findPermissionReports('Inventory', 'Product General')?.permission) && (
                                            <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                                <Link className="dropdown-item" to="/reports/product-general">
                                                    Product General
                                                </Link>
                                            </MenuItem>
                                        )
                                    }


                                    {
                                        hasPermission('view', findPermissionReports('Inventory', 'Store')?.permission) &&
                                        <SubMenu
                                            className={'entity'}
                                            // label={<Link to="/reports/store"><span>Store</span> </Link>}
                                            label={<span>Store</span>}
                                            icon={<span>&#10070;</span>}
                                        >
                                            <SimpleBar style={{ maxHeight: 300 }} autoHide={false}>
                                                {StoreReports.map((x, i) => (
                                                    hasPermission('view', findPermissionReports('Inventory', x.menu)?.permission) && (
                                                        <MenuItem icon={<span>&#8901;</span>} className='entity' key={`${i}_report_${x.menu}`}>
                                                            <Link className="dropdown-item" to={`/reports${x.path}`}>
                                                                {x.menu}
                                                            </Link>
                                                        </MenuItem>
                                                    )
                                                ))}
                                            </SimpleBar>
                                        </SubMenu>
                                    }
                                </SubMenu>
                            </SubMenu>}
                            {(tenantResult.includes("PURCHASE") && tenant_id !== null) ? moduleList?.find(e => e.moduleName === "Purchase") && <SubMenu
                                /* label={<Link to="/dashboard"><span>Purchase</span> </Link>} */
                                label={<Link to={hasPermission('view', findPermissionProcess('DashBoard', 'Purchase')?.permission) ? "/dashboard" : "#"}><span>Purchase</span> </Link>}
                                icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M2.08416 2.7512C2.22155 2.36044 2.6497 2.15503 3.04047 2.29242L3.34187 2.39838C3.95839 2.61511 4.48203 2.79919 4.89411 3.00139C5.33474 3.21759 5.71259 3.48393 5.99677 3.89979C6.27875 4.31243 6.39517 4.76515 6.4489 5.26153C6.47295 5.48373 6.48564 5.72967 6.49233 6H17.1305C18.8155 6 20.3323 6 20.7762 6.57708C21.2202 7.15417 21.0466 8.02369 20.6995 9.76275L20.1997 12.1875C19.8846 13.7164 19.727 14.4808 19.1753 14.9304C18.6236 15.38 17.8431 15.38 16.2821 15.38H10.9792C8.19028 15.38 6.79583 15.38 5.92943 14.4662C5.06302 13.5523 4.99979 12.5816 4.99979 9.64V7.03832C4.99979 6.29837 4.99877 5.80316 4.95761 5.42295C4.91828 5.0596 4.84858 4.87818 4.75832 4.74609C4.67026 4.61723 4.53659 4.4968 4.23336 4.34802C3.91052 4.18961 3.47177 4.03406 2.80416 3.79934L2.54295 3.7075C2.15218 3.57012 1.94678 3.14197 2.08416 2.7512Z" fill="white" />
                                    <path d="M7.5 18C8.32843 18 9 18.6716 9 19.5C9 20.3284 8.32843 21 7.5 21C6.67157 21 6 20.3284 6 19.5C6 18.6716 6.67157 18 7.5 18Z" fill="white" />
                                    <path d="M16.5 18.0001C17.3284 18.0001 18 18.6716 18 19.5001C18 20.3285 17.3284 21.0001 16.5 21.0001C15.6716 21.0001 15 20.3285 15 19.5001C15 18.6716 15.6716 18.0001 16.5 18.0001Z" fill="white" />
                                </svg>}
                            >
                                <SubMenu className={'safety'} label={"Process"} icon={''}>
                                    <SimpleBar style={{ maxHeight: 300 }} autoHide={false}>
                                        <SimpleBar style={{ maxHeight: 300 }} autoHide={false}>
                                            {
                                                hasPermission('view', findPermissionProcess('Purchase', 'Requisition')?.permission) &&
                                                <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                                    <Link className="dropdown-item" to="/purchase_requisition">
                                                        Requisition
                                                    </Link>
                                                </MenuItem>
                                            }
                                            {
                                                hasPermission('view', findPermissionProcess('Purchase', 'Bulk Requisition')?.permission) &&
                                                <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                                    <Link
                                                        className="dropdown-item"
                                                        to="/purchase_requisition/bulk/new">
                                                        Bulk Requisition
                                                    </Link>
                                                </MenuItem>
                                            }

                                            {
                                                hasPermission('view', findPermissionProcess('Purchase', 'RFQ')?.permission) &&
                                                <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                                    <Link className="dropdown-item" to="/rfq">
                                                        RFQ
                                                    </Link>
                                                </MenuItem>
                                            }

                                            {
                                                hasPermission('view', findPermissionProcess('Purchase', 'Quotation')?.permission) &&
                                                <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                                    <Link className="dropdown-item" to="/quotation">
                                                        Quotation
                                                    </Link>
                                                </MenuItem>
                                            }

                                            {
                                                hasPermission('view', findPermissionProcess('Purchase', 'Quotation Comparison')?.permission) &&
                                                <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                                    <Link className="dropdown-item" to="/qc/comparison">
                                                        Quotation Comparison
                                                    </Link>
                                                </MenuItem>
                                            }

                                            {
                                                hasPermission('view', findPermissionProcess('Purchase', 'Order')?.permission) &&
                                                <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                                    <Link className="dropdown-item" to="/purchase-order">
                                                        Order
                                                    </Link>
                                                </MenuItem>
                                            }

                                            {
                                                hasPermission('view', findPermissionProcess('Purchase', 'Contract Rate')?.permission) &&
                                                <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                                    <Link className="dropdown-item" to="/inventory/contract_rate">
                                                        Contract Rate
                                                    </Link>
                                                </MenuItem>
                                            }

                                            {
                                                hasPermission('view', findPermissionProcess('Purchase', 'WO Completion')?.permission) &&
                                                <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                                    <Link className="dropdown-item" to="/work_order">
                                                        WO Completion
                                                    </Link>
                                                </MenuItem>
                                            }

                                            {
                                                hasPermission('view', findPermissionProcess('Purchase', 'Good Receive')?.permission) &&
                                                <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                                    <Link className="dropdown-item" to="/Goods">
                                                        Goods Receive
                                                    </Link>
                                                </MenuItem>
                                            }

                                            {
                                                hasPermission('view', findPermissionProcess('Purchase', 'Invoice')?.permission) &&
                                                <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                                    <Link className="dropdown-item" to="/invoice">
                                                        Invoice
                                                    </Link>
                                                </MenuItem>
                                            }

                                            {
                                                hasPermission('view', findPermissionProcess('Purchase', 'E-Invoice')?.permission) && <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                                    <Link className="dropdown-item" to="/e-invoice">
                                                        E-Invoice
                                                    </Link>
                                                </MenuItem>
                                            }

                                        </SimpleBar>
                                    </SimpleBar>
                                </SubMenu>

                                <SubMenu className={'safety'} label={"Reports"} icon={''}>
                                    <SimpleBar style={{ maxHeight: 300 }} autoHide={false}>
                                        {PurchaseReports.map((x, i) => {
                                            return hasPermission('view', findPermissionReports('Purchase', x.menu)?.permission) && (<MenuItem icon={<span>&#10070;</span>} className='entity'
                                                key={`${i}_report_${x.menu}`}>
                                                <Link className="dropdown-item" to={`/reports${x.path}`}>
                                                    {x.menu}
                                                </Link>
                                            </MenuItem>)
                                        })}
                                    </SimpleBar>
                                </SubMenu>

                            </SubMenu> : tenant_id === null && moduleList?.find(e => e.moduleName === "Purchase") && <SubMenu
                                /* label={<Link to="/dashboard"><span>Purchase</span> </Link>} */
                                label={<Link to={hasPermission('view', findPermissionProcess('DashBoard', 'Purchase')?.permission) ? "/dashboard" : "#"}><span>Purchase</span> </Link>}
                                icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M2.08416 2.7512C2.22155 2.36044 2.6497 2.15503 3.04047 2.29242L3.34187 2.39838C3.95839 2.61511 4.48203 2.79919 4.89411 3.00139C5.33474 3.21759 5.71259 3.48393 5.99677 3.89979C6.27875 4.31243 6.39517 4.76515 6.4489 5.26153C6.47295 5.48373 6.48564 5.72967 6.49233 6H17.1305C18.8155 6 20.3323 6 20.7762 6.57708C21.2202 7.15417 21.0466 8.02369 20.6995 9.76275L20.1997 12.1875C19.8846 13.7164 19.727 14.4808 19.1753 14.9304C18.6236 15.38 17.8431 15.38 16.2821 15.38H10.9792C8.19028 15.38 6.79583 15.38 5.92943 14.4662C5.06302 13.5523 4.99979 12.5816 4.99979 9.64V7.03832C4.99979 6.29837 4.99877 5.80316 4.95761 5.42295C4.91828 5.0596 4.84858 4.87818 4.75832 4.74609C4.67026 4.61723 4.53659 4.4968 4.23336 4.34802C3.91052 4.18961 3.47177 4.03406 2.80416 3.79934L2.54295 3.7075C2.15218 3.57012 1.94678 3.14197 2.08416 2.7512Z" fill="white" />
                                    <path d="M7.5 18C8.32843 18 9 18.6716 9 19.5C9 20.3284 8.32843 21 7.5 21C6.67157 21 6 20.3284 6 19.5C6 18.6716 6.67157 18 7.5 18Z" fill="white" />
                                    <path d="M16.5 18.0001C17.3284 18.0001 18 18.6716 18 19.5001C18 20.3285 17.3284 21.0001 16.5 21.0001C15.6716 21.0001 15 20.3285 15 19.5001C15 18.6716 15.6716 18.0001 16.5 18.0001Z" fill="white" />
                                </svg>}
                            >
                                <SubMenu className={'safety'} label={"Process"} icon={''}>
                                    <SimpleBar style={{ maxHeight: 300 }} autoHide={false}>
                                        <SimpleBar style={{ maxHeight: 300 }} autoHide={false}>
                                            {
                                                hasPermission('view', findPermissionProcess('Purchase', 'Requisition')?.permission) &&
                                                <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                                    <Link className="dropdown-item" to="/purchase_requisition">
                                                        Requisition
                                                    </Link>
                                                </MenuItem>
                                            }
                                            {
                                                hasPermission('view', findPermissionProcess('Purchase', 'Bulk Requisition')?.permission) &&
                                                <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                                    <Link
                                                        className="dropdown-item"
                                                        to="/purchase_requisition/bulk/new">
                                                        Bulk Requisition
                                                    </Link>
                                                </MenuItem>
                                            }

                                            {
                                                hasPermission('view', findPermissionProcess('Purchase', 'RFQ')?.permission) &&
                                                <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                                    <Link className="dropdown-item" to="/rfq">
                                                        RFQ
                                                    </Link>
                                                </MenuItem>
                                            }

                                            {
                                                hasPermission('view', findPermissionProcess('Purchase', 'Quotation')?.permission) &&
                                                <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                                    <Link className="dropdown-item" to="/quotation">
                                                        Quotation
                                                    </Link>
                                                </MenuItem>
                                            }

                                            {
                                                hasPermission('view', findPermissionProcess('Purchase', 'Quotation Comparison')?.permission) &&
                                                <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                                    <Link className="dropdown-item" to="/qc/comparison">
                                                        Quotation Comparison
                                                    </Link>
                                                </MenuItem>
                                            }

                                            {
                                                hasPermission('view', findPermissionProcess('Purchase', 'Order')?.permission) &&
                                                <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                                    <Link className="dropdown-item" to="/purchase-order">
                                                        Order
                                                    </Link>
                                                </MenuItem>
                                            }

                                            {
                                                hasPermission('view', findPermissionProcess('Purchase', 'Contract Rate')?.permission) &&
                                                <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                                    <Link className="dropdown-item" to="/inventory/contract_rate">
                                                        Contract Rate
                                                    </Link>
                                                </MenuItem>
                                            }

                                            {
                                                hasPermission('view', findPermissionProcess('Purchase', 'WO Completion')?.permission) &&
                                                <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                                    <Link className="dropdown-item" to="/work_order">
                                                        WO Completion
                                                    </Link>
                                                </MenuItem>
                                            }

                                            {
                                                hasPermission('view', findPermissionProcess('Purchase', 'Good Receive')?.permission) &&
                                                <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                                    <Link className="dropdown-item" to="/Goods">
                                                        Goods Receive
                                                    </Link>
                                                </MenuItem>
                                            }

                                            {
                                                hasPermission('view', findPermissionProcess('Purchase', 'Invoice')?.permission) &&
                                                <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                                    <Link className="dropdown-item" to="/invoice">
                                                        Invoice
                                                    </Link>
                                                </MenuItem>
                                            }

                                            {
                                                hasPermission('view', findPermissionProcess('Purchase', 'E-Invoice')?.permission) && <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                                    <Link className="dropdown-item" to="/e-invoice">
                                                        E-Invoice
                                                    </Link>
                                                </MenuItem>
                                            }

                                        </SimpleBar>
                                    </SimpleBar>
                                </SubMenu>

                                <SubMenu className={'safety'} label={"Reports"} icon={''}>
                                    <SimpleBar style={{ maxHeight: 300 }} autoHide={false}>
                                        {PurchaseReports.map((x, i) => {
                                            return hasPermission('view', findPermissionReports('Purchase', x.menu)?.permission) && (<MenuItem icon={<span>&#10070;</span>} className='entity'
                                                key={`${i}_report_${x.menu}`}>
                                                <Link className="dropdown-item" to={`/reports${x.path}`}>
                                                    {x.menu}
                                                </Link>
                                            </MenuItem>)
                                        })}
                                    </SimpleBar>
                                </SubMenu>

                            </SubMenu>}
                            {/*<SubMenu label={"Masters"} icon={<img className="menu-icon" src={PurchaseIc} alt="masters" />}>*/}
                            {/*    <SimpleBar style={{ maxHeight: 300 }} autoHide={false}>*/}
                            {/*        {MASTERS_SCREENS_MENU.map((x) => (*/}
                            {/*            <MenuItem key={`${x.id}_master_${x.menu}`}>*/}
                            {/*                <Link className="dropdown-item" to={x.path}>*/}
                            {/*                    {x.menu}*/}
                            {/*                </Link>*/}
                            {/*            </MenuItem>*/}
                            {/*        ))}*/}
                            {/*    </SimpleBar>*/}
                            {/*</SubMenu>*/}
                            {(tenantResult.includes("PMS") && tenant_id !== null) ? moduleList?.find(e => e.moduleName === "PMS") && <SubMenu
                                /* label={<Link to="/pms"><span>PMS</span> </Link>} */
                                label={<Link to={hasPermission('view', findPermissionProcess('DashBoard', 'PMS')?.permission) ? "/pms" : "#"}><span>PMS</span> </Link>}
                                icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M10.75 2H12.75C15.5784 2 16.9926 2 17.8713 2.87868C18.75 3.75736 18.75 5.17157 18.75 8V16C18.75 18.8284 18.75 20.2426 17.8713 21.1213C16.9926 22 15.5784 22 12.75 22H10.75C7.92157 22 6.50736 22 5.62868 21.1213C4.75 20.2426 4.75 18.8284 4.75 16V8C4.75 5.17157 4.75 3.75736 5.62868 2.87868C6.50736 2 7.92157 2 10.75 2ZM8 13C8 12.5858 8.33579 12.25 8.75 12.25H14.75C15.1642 12.25 15.5 12.5858 15.5 13C15.5 13.4142 15.1642 13.75 14.75 13.75H8.75C8.33579 13.75 8 13.4142 8 13ZM8 9C8 8.58579 8.33579 8.25 8.75 8.25H14.75C15.1642 8.25 15.5 8.58579 15.5 9C15.5 9.41421 15.1642 9.75 14.75 9.75H8.75C8.33579 9.75 8 9.41421 8 9ZM8 17C8 16.5858 8.33579 16.25 8.75 16.25H11.75C12.1642 16.25 12.5 16.5858 12.5 17C12.5 17.4142 12.1642 17.75 11.75 17.75H8.75C8.33579 17.75 8 17.4142 8 17Z" fill="white" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M1.75 4.25C2.16421 4.25 2.5 4.58579 2.5 5V19C2.5 19.4142 2.16421 19.75 1.75 19.75C1.33579 19.75 1 19.4142 1 19V5C1 4.58579 1.33579 4.25 1.75 4.25Z" fill="white" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M21.75 4.25C22.1642 4.25 22.5 4.58579 22.5 5V19C22.5 19.4142 22.1642 19.75 21.75 19.75C21.3358 19.75 21 19.4142 21 19V5C21 4.58579 21.3358 4.25 21.75 4.25Z" fill="white" />
                                </svg>}
                            >
                                <SubMenu className={'safety'} label={"Process"} icon={''}>
                                    <SimpleBar style={{ maxHeight: 300 }} autoHide={false}>
                                        {hasPermission('view', findPermissionProcess('PMS', 'Create PMS')?.permission) && <MenuItem icon={<span>&#10070;</span>} >
                                            <Link className="dropdown-item" to="/PMS/createpms">
                                                Create PMS
                                            </Link>
                                        </MenuItem>}
                                        {hasPermission('view', findPermissionProcess('PMS', 'Task List')?.permission) && <MenuItem icon={<span>&#10070;</span>} >
                                            <Link className="dropdown-item" to="/PMS/tasklist">
                                                Task List
                                            </Link>
                                        </MenuItem>}
                                        {hasPermission('view', findPermissionProcess('PMS', 'Unscheduled/Resch')?.permission) && <MenuItem icon={<span>&#10070;</span>} >
                                            <Link className="dropdown-item" to="/PMS/schedule">
                                                Unscheduled/Rescheduled
                                            </Link>
                                        </MenuItem>}
                                        {hasPermission('view', findPermissionProcess('PMS', 'Certificate List')?.permission) && <MenuItem icon={<span>&#10070;</span>}>
                                            <Link className="dropdown-item" to="/PMS/certificate">
                                                Certificate List
                                            </Link>
                                        </MenuItem>}
                                        {hasPermission('view', findPermissionProcess('PMS', 'Maintenance Hour')?.permission) && <MenuItem icon={<span>&#10070;</span>} >
                                            <Link className="dropdown-item" to="/PMS/maintenance_hour">
                                                Maintenance Hour
                                            </Link>
                                        </MenuItem>}
                                    </SimpleBar>
                                </SubMenu>

                                <SubMenu className={'safety'} label={"Reports"} icon={''}>

                                    <SimpleBar style={{ maxHeight: 300 }} autoHide={false}>
                                        {hasPermission('view', findPermissionReports('PMS', 'Certificate Report')?.permission) && <MenuItem icon={<span>&#10070;</span>} >
                                            <Link className="dropdown-item" to="pms/reports/certificate">
                                                Certificate Report
                                            </Link>
                                        </MenuItem>}
                                        {hasPermission('view', findPermissionReports('PMS', 'Maintenance Analysis')?.permission) && <MenuItem icon={<span>&#10070;</span>} >
                                            <Link className="dropdown-item" to="/analysis">
                                                Maintenance Analysis
                                            </Link>
                                        </MenuItem>}
                                        {hasPermission('view', findPermissionReports('PMS', 'Maintenance Report')?.permission) && <MenuItem icon={<span>&#10070;</span>} >
                                            <Link className="dropdown-item" to="/pms/reports/maintenance">
                                                Maintenance Report
                                            </Link>
                                        </MenuItem>}

                                        {hasPermission('view', findPermissionReports('PMS', 'PMS Analysis')?.permission) && <MenuItem icon={<span>&#10070;</span>} >
                                            <Link className="dropdown-item" to="/pms/analysis">
                                                PMS Analysis
                                            </Link>
                                        </MenuItem>}
                                    </SimpleBar>
                                </SubMenu>

                            </SubMenu> : tenant_id === null && moduleList?.find(e => e.moduleName === "PMS") && <SubMenu
                                /* label={<Link to="/pms"><span>PMS</span> </Link>} */
                                label={<Link to={hasPermission('view', findPermissionProcess('DashBoard', 'PMS')?.permission) ? "/pms" : "#"}><span>PMS</span> </Link>}
                                icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M10.75 2H12.75C15.5784 2 16.9926 2 17.8713 2.87868C18.75 3.75736 18.75 5.17157 18.75 8V16C18.75 18.8284 18.75 20.2426 17.8713 21.1213C16.9926 22 15.5784 22 12.75 22H10.75C7.92157 22 6.50736 22 5.62868 21.1213C4.75 20.2426 4.75 18.8284 4.75 16V8C4.75 5.17157 4.75 3.75736 5.62868 2.87868C6.50736 2 7.92157 2 10.75 2ZM8 13C8 12.5858 8.33579 12.25 8.75 12.25H14.75C15.1642 12.25 15.5 12.5858 15.5 13C15.5 13.4142 15.1642 13.75 14.75 13.75H8.75C8.33579 13.75 8 13.4142 8 13ZM8 9C8 8.58579 8.33579 8.25 8.75 8.25H14.75C15.1642 8.25 15.5 8.58579 15.5 9C15.5 9.41421 15.1642 9.75 14.75 9.75H8.75C8.33579 9.75 8 9.41421 8 9ZM8 17C8 16.5858 8.33579 16.25 8.75 16.25H11.75C12.1642 16.25 12.5 16.5858 12.5 17C12.5 17.4142 12.1642 17.75 11.75 17.75H8.75C8.33579 17.75 8 17.4142 8 17Z" fill="white" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M1.75 4.25C2.16421 4.25 2.5 4.58579 2.5 5V19C2.5 19.4142 2.16421 19.75 1.75 19.75C1.33579 19.75 1 19.4142 1 19V5C1 4.58579 1.33579 4.25 1.75 4.25Z" fill="white" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M21.75 4.25C22.1642 4.25 22.5 4.58579 22.5 5V19C22.5 19.4142 22.1642 19.75 21.75 19.75C21.3358 19.75 21 19.4142 21 19V5C21 4.58579 21.3358 4.25 21.75 4.25Z" fill="white" />
                                </svg>}
                            >
                                <SubMenu className={'safety'} label={"Process"} icon={''}>
                                    <SimpleBar style={{ maxHeight: 300 }} autoHide={false}>
                                        {hasPermission('view', findPermissionProcess('PMS', 'Create PMS')?.permission) && <MenuItem icon={<span>&#10070;</span>} >
                                            <Link className="dropdown-item" to="/PMS/createpms">
                                                Create PMS
                                            </Link>
                                        </MenuItem>}
                                        {hasPermission('view', findPermissionProcess('PMS', 'Task List')?.permission) && <MenuItem icon={<span>&#10070;</span>} >
                                            <Link className="dropdown-item" to="/PMS/tasklist">
                                                Task List
                                            </Link>
                                        </MenuItem>}
                                        {hasPermission('view', findPermissionProcess('PMS', 'Unscheduled/Resch')?.permission) && <MenuItem icon={<span>&#10070;</span>} >
                                            <Link className="dropdown-item" to="/PMS/schedule">
                                                Unscheduled/Rescheduled
                                            </Link>
                                        </MenuItem>}
                                        {hasPermission('view', findPermissionProcess('PMS', 'Certificate List')?.permission) && <MenuItem icon={<span>&#10070;</span>}>
                                            <Link className="dropdown-item" to="/PMS/certificate">
                                                Certificate List
                                            </Link>
                                        </MenuItem>}
                                        {hasPermission('view', findPermissionProcess('PMS', 'Maintenance Hour')?.permission) && <MenuItem icon={<span>&#10070;</span>} >
                                            <Link className="dropdown-item" to="/PMS/maintenance_hour">
                                                Maintenance Hour
                                            </Link>
                                        </MenuItem>}
                                    </SimpleBar>
                                </SubMenu>

                                <SubMenu className={'safety'} label={"Reports"} icon={''}>

                                    <SimpleBar style={{ maxHeight: 300 }} autoHide={false}>
                                        {hasPermission('view', findPermissionReports('PMS', 'Certificate Report')?.permission) && <MenuItem icon={<span>&#10070;</span>} >
                                            <Link className="dropdown-item" to="pms/reports/certificate">
                                                Certificate Report
                                            </Link>
                                        </MenuItem>}
                                        {hasPermission('view', findPermissionReports('PMS', 'Maintenance Analysis')?.permission) && <MenuItem icon={<span>&#10070;</span>} >
                                            <Link className="dropdown-item" to="/analysis">
                                                Maintenance Analysis
                                            </Link>
                                        </MenuItem>}
                                        {hasPermission('view', findPermissionReports('PMS', 'Maintenance Report')?.permission) && <MenuItem icon={<span>&#10070;</span>} >
                                            <Link className="dropdown-item" to="/pms/reports/maintenance">
                                                Maintenance Report
                                            </Link>
                                        </MenuItem>}

                                        {hasPermission('view', findPermissionReports('PMS', 'PMS Analysis')?.permission) && <MenuItem icon={<span>&#10070;</span>} >
                                            <Link className="dropdown-item" to="/pms/analysis">
                                                PMS Analysis
                                            </Link>
                                        </MenuItem>}
                                    </SimpleBar>
                                </SubMenu>

                            </SubMenu>}
                            {/* <SubMenu
                                label={"Reports"}
                                icon={<svg width="64px" height="64px" viewBox="0 0 1024 1024" className="icon"
                                    version="1.1" xmlns="http://www.w3.org/2000/svg" fill="#000000">
                                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                    <g id="SVGRepo_tracerCarrier" strokeLinecap="round"
                                        strokeLinejoin="round"></g>
                                    <g id="SVGRepo_iconCarrier">
                                        <path
                                            d="M589.3 260.9v30H371.4v-30H268.9v513h117.2v-304l109.7-99.1h202.1V260.9z"
                                            fill="#ffffff"></path>
                                        <path d="M516.1 371.1l-122.9 99.8v346.8h370.4V371.1z"
                                            fill="#ffffff"></path>
                                        <path d="M752.7 370.8h21.8v435.8h-21.8z" fill="#ffffff"></path>
                                        <path d="M495.8 370.8h277.3v21.8H495.8z" fill="#ffffff"></path>
                                        <path d="M495.8 370.8h21.8v124.3h-21.8z" fill="#ffffff"></path>
                                        <path d="M397.7 488.7l-15.4-15.4 113.5-102.5 15.4 15.4z"
                                            fill="#ffffff"></path>
                                        <path d="M382.3 473.3h135.3v21.8H382.3z" fill="#ffffff"></path>
                                        <path d="M382.3 479.7h21.8v348.6h-21.8zM404.1 806.6h370.4v21.8H404.1z"
                                            fill="#ffffff"></path>
                                        <path
                                            d="M447.7 545.1h261.5v21.8H447.7zM447.7 610.5h261.5v21.8H447.7zM447.7 675.8h261.5v21.8H447.7z"
                                            fill="#051134"></path>
                                        <path d="M251.6 763h130.7v21.8H251.6z" fill="#ffffff"></path>
                                        <path
                                            d="M251.6 240.1h21.8v544.7h-21.8zM687.3 240.1h21.8v130.7h-21.8zM273.4 240.1h108.9v21.8H273.4z"
                                            fill="#ffffff"></path>
                                        <path
                                            d="M578.4 240.1h130.7v21.8H578.4zM360.5 196.5h21.8v108.9h-21.8zM382.3 283.7h196.1v21.8H382.3zM534.8 196.5h65.4v21.8h-65.4z"
                                            fill="#ffffff"></path>
                                        <path
                                            d="M360.5 196.5h65.4v21.8h-65.4zM404.1 174.7h152.5v21.8H404.1zM578.4 196.5h21.8v108.9h-21.8z"
                                            fill="#ffffff"></path>
                                    </g>
                                </svg>}>
                                <SimpleBar style={{ maxHeight: 300 }} autoHide={false}>
                                    {REPORTS.map((x, i) => (
                                        <MenuItem key={`${i}_report_${x.menu}`}>
                                            <Link className="dropdown-item" to={`/reports${x.path}`}>
                                                {x.menu}
                                            </Link>
                                        </MenuItem>
                                    ))}
                                </SimpleBar>
                            </SubMenu> */}
                            {(tenantResult.includes("SAFETY") && tenant_id !== null) ? moduleList?.find(e => e.moduleName === "Safety") && <SubMenu
                                /* label={<Link to="/safety"><span>Safety</span> </Link>} */
                                label={<Link to={hasPermission('view', findPermissionProcess('DashBoard', 'Safety')?.permission) ? "/safety" : "#"}><span>Safety</span> </Link>}
                                icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M5.25 10.0546V8C5.25 4.27208 8.27208 1.25 12 1.25C15.7279 1.25 18.75 4.27208 18.75 8V10.0546C19.8648 10.1379 20.5907 10.348 21.1213 10.8787C22 11.7574 22 13.1716 22 16C22 18.8284 22 20.2426 21.1213 21.1213C20.2426 22 18.8284 22 16 22H8C5.17157 22 3.75736 22 2.87868 21.1213C2 20.2426 2 18.8284 2 16C2 13.1716 2 11.7574 2.87868 10.8787C3.40931 10.348 4.13525 10.1379 5.25 10.0546ZM6.75 8C6.75 5.10051 9.10051 2.75 12 2.75C14.8995 2.75 17.25 5.10051 17.25 8V10.0036C16.867 10 16.4515 10 16 10H8C7.54849 10 7.13301 10 6.75 10.0036V8Z" fill="white" />
                                </svg>}
                                className={'safety'}
                            >
                                <SubMenu className={'safety'} label={"Process"} icon={''}>
                                    <SimpleBar style={{ maxHeight: 300 }} autoHide={false}>
                                        {/*<SubMenu label={"Master"}  icon={<span>&#10070;</span>}>*/}
                                        {/*    <SimpleBar style={{ maxHeight: 300 }} autoHide={false}>*/}
                                        {/*        */}
                                        {/*    </SimpleBar>*/}
                                        {/*</SubMenu>*/}
                                        <SubMenu label={"Audit Transaction"} icon={<span>&#10070;</span>}>
                                            <SimpleBar style={{ maxHeight: 300 }} autoHide={false}>
                                                <MenuItemWithPermission menuName={"Safety"} modulename="Audit Checklist" to="/safety/audit/checklist" label="Audit Checklist" />
                                                <MenuItemWithPermission menuName={"Safety"} modulename="Audit Schedule" to="/safety/audit/schedule" label="Audit Schedule" />
                                                <MenuItemWithPermission menuName={"Safety"} modulename="Audit Planning" to="/safety/audit/plan" label="Audit Planning" />
                                                <MenuItemWithPermission menuName={"Safety"} modulename="Audit Completion" to="/safety/audit/completion" label="Audit Completion" />
                                                {/* <MenuItem icon={<span>&#8901;</span>} className='entity'>
                                                    <Link className="dropdown-item" to="/safety/audit/schedule" >
                                                        Audit Schedule
                                                    </Link>
                                                </MenuItem>
                                                <MenuItem icon={<span>&#8901;</span>} className='entity'>
                                                    <Link className="dropdown-item" to="/safety/audit/plan" >
                                                        Audit Planning
                                                    </Link>
                                                </MenuItem>
                                                <MenuItem icon={<span>&#8901;</span>} className='entity'>
                                                    <Link className="dropdown-item" to="/safety/audit/completion" >
                                                        Audit Completion
                                                    </Link>
                                                </MenuItem> */}
                                            </SimpleBar>
                                        </SubMenu>
                                        <SubMenu label={"Inspection Transaction"} icon={<span>&#10070;</span>}>
                                            <SimpleBar style={{ maxHeight: 300 }} autoHide={false}>
                                                <MenuItemWithPermission menuName="Safety" modulename="Inspection Checklist" to="/safety/inspection/checklist" label="Inspection Checklist" />
                                                <MenuItemWithPermission menuName="Safety" modulename="Inspection Schedule" to="/safety/inspection/schedule" label="Inspection Schedule" />
                                                <MenuItemWithPermission menuName="Safety" modulename="Inspection Planning" to="/safety/inspection/plan" label="Inspection Planning" />
                                                <MenuItemWithPermission menuName="Safety" modulename="Inspection Completion" to="/safety/inspection/completion" label="Inspection Completion" />
                                                {/* <MenuItem icon={<span>&#8901;</span>} className='entity'>
                                                    <Link className="dropdown-item" to="/safety/inspection/checklist" >
                                                        Inspection Checklist
                                                    </Link>
                                                </MenuItem>
                                                <MenuItem icon={<span>&#8901;</span>} className='entity'>
                                                    <Link className="dropdown-item" to="/safety/inspection/schedule" >
                                                        Inspection Schedule
                                                    </Link>
                                                </MenuItem>
                                                <MenuItem icon={<span>&#8901;</span>} className='entity'>
                                                    <Link className="dropdown-item" to="/safety/inspection/plan" >
                                                        Inspection Planning
                                                    </Link>
                                                </MenuItem>
                                                <MenuItem icon={<span>&#8901;</span>} className='entity'>
                                                    <Link className="dropdown-item" to="/safety/inspection/completion" >
                                                        Inspection Completion
                                                    </Link>
                                                </MenuItem> */}
                                            </SimpleBar>
                                        </SubMenu>
                                        <SubMenu className={'safety'} label={"Meeting Transaction"} icon={<span>&#10070;</span>}>
                                            <SimpleBar style={{ maxHeight: 300 }} autoHide={false}>
                                                <MenuItemWithPermission menuName="Safety" modulename="Meeting Record" to="/safety/meeting-record" label="Meeting Record" />
                                                <MenuItemWithPermission menuName="Safety" modulename="Drill Record" to="/safety/drill-record" label="Drill Record" />
                                                {/* <MenuItem icon={<span>&#8901;</span>} className='entity'>
                                                    <Link className="dropdown-item" to="/safety/meeting-record" >
                                                        Meeting Record
                                                    </Link>
                                                </MenuItem>
                                                <MenuItem icon={<span>&#8901;</span>} className='entity'>
                                                    <Link className="dropdown-item" to="/safety/drill-record" >
                                                        Drill Record
                                                    </Link>
                                                </MenuItem> */}
                                            </SimpleBar>
                                        </SubMenu>
                                        <SubMenu label={"NC/OBS"} icon={<span>&#10070;</span>}>
                                            <SimpleBar style={{ maxHeight: 300 }} autoHide={false}>
                                                <MenuItemWithPermission menuName="Safety" modulename="Audit Record" to="/safety/ncobs/auditRecordList" label="Audit Record" />
                                                <MenuItemWithPermission menuName="Safety" modulename="Inspection Record" to="/safety/ncobs/inspectionRecordList" label="Inspection Record" />
                                                {/* <MenuItem icon={<span>&#8901;</span>} className='entity'>
                                                    <Link className="dropdown-item" to="/safety/ncobs/auditRecordList" >
                                                        Audit Record
                                                    </Link>
                                                </MenuItem>
                                                <MenuItem icon={<span>&#8901;</span>} className='entity'>
                                                    <Link className="dropdown-item" to="/safety/ncobs/inspectionRecordList" >
                                                        Inspection Record
                                                    </Link>
                                                </MenuItem> */}
                                            </SimpleBar>
                                        </SubMenu>
                                        {hasPermission('view', findPermissionProcess('Safety', 'Incident Record')?.permission) && <MenuItem icon={<span>&#10070;</span>}>
                                            <Link className="dropdown-item" to="/safety/transaction/incident" >
                                                Incident Record
                                            </Link>
                                        </MenuItem>}


                                        {hasPermission('view', findPermissionProcess('Safety', 'Vessel Inspection Report')?.permission) && <MenuItem icon={<span>&#10070;</span>}>
                                            <Link className="dropdown-item" to='/safety/VesselInspectionList' >
                                                Vessel Inspection Report
                                            </Link>
                                        </MenuItem>}

                                    </SimpleBar>
                                </SubMenu>
                                <SubMenu className={'safety'} label={"Reports"} icon={''}>

                                    <SimpleBar style={{ maxHeight: 300 }} autoHide={false}>
                                        {SafetyReports.map((x, i) => (
                                            <MenuItemWithPermissionReport key={`${i}_report_${x.menu}`} menuName="Safety" modulename={x.menu} to={`/reports${x.path}`} label={x.menu} />
                                        ))}
                                    </SimpleBar>
                                </SubMenu>
                                {/* <MenuItem icon={<span>&#10070;</span>} className='entity' key={`${i}_report_${x.menu}`}>
                                                <Link className="dropdown-item" to={`/reports${x.path}`}>
                                                    {x.menu}
                                                </Link>
                                            </MenuItem> */}
                            </SubMenu> : tenant_id === null && moduleList?.find(e => e.moduleName === "Safety") && <SubMenu
                                /* label={<Link to="/safety"><span>Safety</span> </Link>} */
                                label={<Link to={hasPermission('view', findPermissionProcess('DashBoard', 'Safety')?.permission) ? "/safety" : "#"}><span>Safety</span> </Link>}
                                icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M5.25 10.0546V8C5.25 4.27208 8.27208 1.25 12 1.25C15.7279 1.25 18.75 4.27208 18.75 8V10.0546C19.8648 10.1379 20.5907 10.348 21.1213 10.8787C22 11.7574 22 13.1716 22 16C22 18.8284 22 20.2426 21.1213 21.1213C20.2426 22 18.8284 22 16 22H8C5.17157 22 3.75736 22 2.87868 21.1213C2 20.2426 2 18.8284 2 16C2 13.1716 2 11.7574 2.87868 10.8787C3.40931 10.348 4.13525 10.1379 5.25 10.0546ZM6.75 8C6.75 5.10051 9.10051 2.75 12 2.75C14.8995 2.75 17.25 5.10051 17.25 8V10.0036C16.867 10 16.4515 10 16 10H8C7.54849 10 7.13301 10 6.75 10.0036V8Z" fill="white" />
                                </svg>}
                                className={'safety'}
                            >
                                <SubMenu className={'safety'} label={"Process"} icon={''}>
                                    <SimpleBar style={{ maxHeight: 300 }} autoHide={false}>
                                        {/*<SubMenu label={"Master"}  icon={<span>&#10070;</span>}>*/}
                                        {/*    <SimpleBar style={{ maxHeight: 300 }} autoHide={false}>*/}
                                        {/*        */}
                                        {/*    </SimpleBar>*/}
                                        {/*</SubMenu>*/}
                                        <SubMenu label={"Audit Transaction"} icon={<span>&#10070;</span>}>
                                            <SimpleBar style={{ maxHeight: 300 }} autoHide={false}>
                                                <MenuItemWithPermission menuName={"Safety"} modulename="Audit Checklist" to="/safety/audit/checklist" label="Audit Checklist" />
                                                <MenuItemWithPermission menuName={"Safety"} modulename="Audit Schedule" to="/safety/audit/schedule" label="Audit Schedule" />
                                                <MenuItemWithPermission menuName={"Safety"} modulename="Audit Planning" to="/safety/audit/plan" label="Audit Planning" />
                                                <MenuItemWithPermission menuName={"Safety"} modulename="Audit Completion" to="/safety/audit/completion" label="Audit Completion" />
                                                {/* <MenuItem icon={<span>&#8901;</span>} className='entity'>
                                                    <Link className="dropdown-item" to="/safety/audit/schedule" >
                                                        Audit Schedule
                                                    </Link>
                                                </MenuItem>
                                                <MenuItem icon={<span>&#8901;</span>} className='entity'>
                                                    <Link className="dropdown-item" to="/safety/audit/plan" >
                                                        Audit Planning
                                                    </Link>
                                                </MenuItem>
                                                <MenuItem icon={<span>&#8901;</span>} className='entity'>
                                                    <Link className="dropdown-item" to="/safety/audit/completion" >
                                                        Audit Completion
                                                    </Link>
                                                </MenuItem> */}
                                            </SimpleBar>
                                        </SubMenu>
                                        <SubMenu label={"Inspection Transaction"} icon={<span>&#10070;</span>}>
                                            <SimpleBar style={{ maxHeight: 300 }} autoHide={false}>
                                                <MenuItemWithPermission menuName="Safety" modulename="Inspection Checklist" to="/safety/inspection/checklist" label="Inspection Checklist" />
                                                <MenuItemWithPermission menuName="Safety" modulename="Inspection Schedule" to="/safety/inspection/schedule" label="Inspection Schedule" />
                                                <MenuItemWithPermission menuName="Safety" modulename="Inspection Planning" to="/safety/inspection/plan" label="Inspection Planning" />
                                                <MenuItemWithPermission menuName="Safety" modulename="Inspection Completion" to="/safety/inspection/completion" label="Inspection Completion" />
                                                {/* <MenuItem icon={<span>&#8901;</span>} className='entity'>
                                                    <Link className="dropdown-item" to="/safety/inspection/checklist" >
                                                        Inspection Checklist
                                                    </Link>
                                                </MenuItem>
                                                <MenuItem icon={<span>&#8901;</span>} className='entity'>
                                                    <Link className="dropdown-item" to="/safety/inspection/schedule" >
                                                        Inspection Schedule
                                                    </Link>
                                                </MenuItem>
                                                <MenuItem icon={<span>&#8901;</span>} className='entity'>
                                                    <Link className="dropdown-item" to="/safety/inspection/plan" >
                                                        Inspection Planning
                                                    </Link>
                                                </MenuItem>
                                                <MenuItem icon={<span>&#8901;</span>} className='entity'>
                                                    <Link className="dropdown-item" to="/safety/inspection/completion" >
                                                        Inspection Completion
                                                    </Link>
                                                </MenuItem> */}
                                            </SimpleBar>
                                        </SubMenu>
                                        <SubMenu className={'safety'} label={"Meeting Transaction"} icon={<span>&#10070;</span>}>
                                            <SimpleBar style={{ maxHeight: 300 }} autoHide={false}>
                                                <MenuItemWithPermission menuName="Safety" modulename="Meeting Record" to="/safety/meeting-record" label="Meeting Record" />
                                                <MenuItemWithPermission menuName="Safety" modulename="Drill Record" to="/safety/drill-record" label="Drill Record" />
                                                {/* <MenuItem icon={<span>&#8901;</span>} className='entity'>
                                                    <Link className="dropdown-item" to="/safety/meeting-record" >
                                                        Meeting Record
                                                    </Link>
                                                </MenuItem>
                                                <MenuItem icon={<span>&#8901;</span>} className='entity'>
                                                    <Link className="dropdown-item" to="/safety/drill-record" >
                                                        Drill Record
                                                    </Link>
                                                </MenuItem> */}
                                            </SimpleBar>
                                        </SubMenu>
                                        <SubMenu label={"NC/OBS"} icon={<span>&#10070;</span>}>
                                            <SimpleBar style={{ maxHeight: 300 }} autoHide={false}>
                                                <MenuItemWithPermission menuName="Safety" modulename="Audit Record" to="/safety/ncobs/auditRecordList" label="Audit Record" />
                                                <MenuItemWithPermission menuName="Safety" modulename="Inspection Record" to="/safety/ncobs/inspectionRecordList" label="Inspection Record" />
                                                {/* <MenuItem icon={<span>&#8901;</span>} className='entity'>
                                                    <Link className="dropdown-item" to="/safety/ncobs/auditRecordList" >
                                                        Audit Record
                                                    </Link>
                                                </MenuItem>
                                                <MenuItem icon={<span>&#8901;</span>} className='entity'>
                                                    <Link className="dropdown-item" to="/safety/ncobs/inspectionRecordList" >
                                                        Inspection Record
                                                    </Link>
                                                </MenuItem> */}
                                            </SimpleBar>
                                        </SubMenu>
                                        {hasPermission('view', findPermissionProcess('Safety', 'Incident Record')?.permission) && <MenuItem icon={<span>&#10070;</span>}>
                                            <Link className="dropdown-item" to="/safety/transaction/incident" >
                                                Incident Record
                                            </Link>
                                        </MenuItem>}


                                        {hasPermission('view', findPermissionProcess('Safety', 'Vessel Inspection Report')?.permission) && <MenuItem icon={<span>&#10070;</span>}>
                                            <Link className="dropdown-item" to='/safety/VesselInspectionList' >
                                                Vessel Inspection Report
                                            </Link>
                                        </MenuItem>}

                                    </SimpleBar>
                                </SubMenu>
                                <SubMenu className={'safety'} label={"Reports"} icon={''}>

                                    <SimpleBar style={{ maxHeight: 300 }} autoHide={false}>
                                        {SafetyReports.map((x, i) => (
                                            <MenuItemWithPermissionReport key={`${i}_report_${x.menu}`} menuName="Safety" modulename={x.menu} to={`/reports${x.path}`} label={x.menu} />
                                        ))}
                                    </SimpleBar>
                                </SubMenu>
                                {/* <MenuItem icon={<span>&#10070;</span>} className='entity' key={`${i}_report_${x.menu}`}>
                                                <Link className="dropdown-item" to={`/reports${x.path}`}>
                                                    {x.menu}
                                                </Link>
                                            </MenuItem> */}
                            </SubMenu>}

                            {(tenantResult.includes("EDOC") && tenant_id !== null) ? moduleList?.find(e => e.moduleName === "E-Doc") && <SubMenu
                                /* label={<Link to="/e-doc"><span>E-doc</span> </Link>} */
                                label={<Link to={hasPermission('view', findPermissionProcess('DashBoard', 'E-Doc')?.permission) ? "/e-doc" : "#"}><span>E-doc</span> </Link>}
                                icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M4.72718 2.71244C5.03258 2.41324 5.46135 2.21816 6.27103 2.11151C7.10452 2.00172 8.2092 2 9.7931 2H14.2069C15.7908 2 16.8955 2.00172 17.729 2.11151C18.5387 2.21816 18.9674 2.41324 19.2728 2.71244C19.5782 3.01165 19.7773 3.43172 19.8862 4.22499C19.9982 5.04159 20 6.12387 20 7.67568V15.5135H7.34563C6.44305 15.5132 5.82716 15.513 5.29899 15.6517C4.82674 15.7756 4.38867 15.9781 4 16.2442V7.67568C4 6.12387 4.00176 5.04159 4.11382 4.225C4.22268 3.43172 4.42179 3.01165 4.72718 2.71244ZM7.58621 5.78378C7.12914 5.78378 6.75862 6.1468 6.75862 6.59459C6.75862 7.04239 7.12914 7.40541 7.58621 7.40541H16.4138C16.8709 7.40541 17.2414 7.04239 17.2414 6.59459C17.2414 6.1468 16.8709 5.78378 16.4138 5.78378H7.58621ZM6.75862 10.3784C6.75862 9.93058 7.12914 9.56757 7.58621 9.56757H13.1034C13.5605 9.56757 13.931 9.93058 13.931 10.3784C13.931 10.8262 13.5605 11.1892 13.1034 11.1892H7.58621C7.12914 11.1892 6.75862 10.8262 6.75862 10.3784Z" fill="white" />
                                    <path d="M7.47343 17.1351C6.39397 17.1351 6.01659 17.1421 5.7274 17.218C4.93367 17.4264 4.3009 18.0044 4.02954 18.7558C4.04632 19.1382 4.07261 19.4746 4.11384 19.775C4.2227 20.5683 4.42181 20.9884 4.7272 21.2876C5.0326 21.5868 5.46137 21.7818 6.27105 21.8885C7.10454 21.9983 8.20922 22 9.79312 22H14.2069C15.7908 22 16.8955 21.9983 17.729 21.8885C18.5387 21.7818 18.9674 21.5868 19.2728 21.2876C19.4894 21.0753 19.6526 20.8023 19.768 20.3784H7.58623C7.12916 20.3784 6.75864 20.0154 6.75864 19.5676C6.75864 19.1198 7.12916 18.7568 7.58623 18.7568H19.9704C19.9909 18.2908 19.9972 17.7564 19.9991 17.1351H7.47343Z" fill="white" />
                                </svg>}
                            >
                                <SubMenu className={'safety'} label={"Process"} icon={''}>
                                    <SimpleBar style={{ maxHeight: 300 }} autoHide={false}>
                                        {hasPermission('view', findPermissionProcess('E-Doc', 'Manage Document')?.permission) &&
                                            <MenuItem>
                                                <Link className="dropdown-item" to={'e-doc/manage-document'} >
                                                    Manage Document
                                                </Link>
                                            </MenuItem>
                                        } {hasPermission('view', findPermissionProcess('E-Doc', 'Initiate Request')?.permission) &&
                                            <MenuItem>
                                                <Link className="dropdown-item" to={'e-doc/initiate-request'} >
                                                    Initiate Request
                                                </Link>
                                            </MenuItem>
                                        }
                                        {hasPermission('view', findPermissionProcess('E-Doc', 'Review Request')?.permission) &&
                                            <MenuItem>
                                                <Link className="dropdown-item" to="/e-doc/review-request" >
                                                    Review Request
                                                </Link>
                                            </MenuItem>
                                        }
                                        {hasPermission('view', findPermissionProcess('E-Doc', 'Checkin')?.permission) &&
                                            <MenuItem>
                                                <Link className="dropdown-item" to="/e-doc/checkin" >
                                                    Checkin
                                                </Link>
                                            </MenuItem>
                                        }
                                        {hasPermission('view', findPermissionProcess('E-Doc', 'Checkout')?.permission) &&
                                            <MenuItem>
                                                <Link className="dropdown-item" to="/e-doc/checkout" >
                                                    Checkout
                                                </Link>
                                            </MenuItem>
                                        }
                                    </SimpleBar>
                                </SubMenu>
                                <SubMenu className={'safety'} label={"Reports"} icon={''}>

                                    <SimpleBar style={{ maxHeight: 300 }} autoHide={false}>
                                        {EdocReports.map((x, i) => {
                                            return hasPermission('view', findPermissionReports('E-Doc', x.menu)?.permission) &&
                                                <MenuItem icon={<span>&#10070;</span>} className='entity'
                                                    key={`${i}_report_${x.menu}`}>
                                                    <Link className="dropdown-item" to={`/reports${x.path}`}>
                                                        {x.menu}
                                                    </Link>
                                                </MenuItem>
                                        })}
                                    </SimpleBar>
                                </SubMenu>
                            </SubMenu> : tenant_id === null && moduleList?.find(e => e.moduleName === "E-Doc") && <SubMenu
                                /* label={<Link to="/e-doc"><span>E-doc</span> </Link>} */
                                label={<Link to={hasPermission('view', findPermissionProcess('DashBoard', 'E-Doc')?.permission) ? "/e-doc" : "#"}><span>E-doc</span> </Link>}
                                icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M4.72718 2.71244C5.03258 2.41324 5.46135 2.21816 6.27103 2.11151C7.10452 2.00172 8.2092 2 9.7931 2H14.2069C15.7908 2 16.8955 2.00172 17.729 2.11151C18.5387 2.21816 18.9674 2.41324 19.2728 2.71244C19.5782 3.01165 19.7773 3.43172 19.8862 4.22499C19.9982 5.04159 20 6.12387 20 7.67568V15.5135H7.34563C6.44305 15.5132 5.82716 15.513 5.29899 15.6517C4.82674 15.7756 4.38867 15.9781 4 16.2442V7.67568C4 6.12387 4.00176 5.04159 4.11382 4.225C4.22268 3.43172 4.42179 3.01165 4.72718 2.71244ZM7.58621 5.78378C7.12914 5.78378 6.75862 6.1468 6.75862 6.59459C6.75862 7.04239 7.12914 7.40541 7.58621 7.40541H16.4138C16.8709 7.40541 17.2414 7.04239 17.2414 6.59459C17.2414 6.1468 16.8709 5.78378 16.4138 5.78378H7.58621ZM6.75862 10.3784C6.75862 9.93058 7.12914 9.56757 7.58621 9.56757H13.1034C13.5605 9.56757 13.931 9.93058 13.931 10.3784C13.931 10.8262 13.5605 11.1892 13.1034 11.1892H7.58621C7.12914 11.1892 6.75862 10.8262 6.75862 10.3784Z" fill="white" />
                                    <path d="M7.47343 17.1351C6.39397 17.1351 6.01659 17.1421 5.7274 17.218C4.93367 17.4264 4.3009 18.0044 4.02954 18.7558C4.04632 19.1382 4.07261 19.4746 4.11384 19.775C4.2227 20.5683 4.42181 20.9884 4.7272 21.2876C5.0326 21.5868 5.46137 21.7818 6.27105 21.8885C7.10454 21.9983 8.20922 22 9.79312 22H14.2069C15.7908 22 16.8955 21.9983 17.729 21.8885C18.5387 21.7818 18.9674 21.5868 19.2728 21.2876C19.4894 21.0753 19.6526 20.8023 19.768 20.3784H7.58623C7.12916 20.3784 6.75864 20.0154 6.75864 19.5676C6.75864 19.1198 7.12916 18.7568 7.58623 18.7568H19.9704C19.9909 18.2908 19.9972 17.7564 19.9991 17.1351H7.47343Z" fill="white" />
                                </svg>}
                            >
                                <SubMenu className={'safety'} label={"Process"} icon={''}>
                                    <SimpleBar style={{ maxHeight: 300 }} autoHide={false}>
                                        {hasPermission('view', findPermissionProcess('E-Doc', 'Manage Document')?.permission) &&
                                            <MenuItem>
                                                <Link className="dropdown-item" to={'e-doc/manage-document'} >
                                                    Manage Document
                                                </Link>
                                            </MenuItem>
                                        } {hasPermission('view', findPermissionProcess('E-Doc', 'Initiate Request')?.permission) &&
                                            <MenuItem>
                                                <Link className="dropdown-item" to={'e-doc/initiate-request'} >
                                                    Initiate Request
                                                </Link>
                                            </MenuItem>
                                        }
                                        {hasPermission('view', findPermissionProcess('E-Doc', 'Review Request')?.permission) &&
                                            <MenuItem>
                                                <Link className="dropdown-item" to="/e-doc/review-request" >
                                                    Review Request
                                                </Link>
                                            </MenuItem>
                                        }
                                        {hasPermission('view', findPermissionProcess('E-Doc', 'Checkin')?.permission) &&
                                            <MenuItem>
                                                <Link className="dropdown-item" to="/e-doc/checkin" >
                                                    Checkin
                                                </Link>
                                            </MenuItem>
                                        }
                                        {hasPermission('view', findPermissionProcess('E-Doc', 'Checkout')?.permission) &&
                                            <MenuItem>
                                                <Link className="dropdown-item" to="/e-doc/checkout" >
                                                    Checkout
                                                </Link>
                                            </MenuItem>
                                        }
                                    </SimpleBar>
                                </SubMenu>
                                <SubMenu className={'safety'} label={"Reports"} icon={''}>

                                    <SimpleBar style={{ maxHeight: 300 }} autoHide={false}>
                                        {EdocReports.map((x, i) => {
                                            return hasPermission('view', findPermissionReports('E-Doc', x.menu)?.permission) &&
                                                <MenuItem icon={<span>&#10070;</span>} className='entity'
                                                    key={`${i}_report_${x.menu}`}>
                                                    <Link className="dropdown-item" to={`/reports${x.path}`}>
                                                        {x.menu}
                                                    </Link>
                                                </MenuItem>
                                        })}
                                    </SimpleBar>
                                </SubMenu>
                            </SubMenu>}
                            {(tenantResult.includes("SETTINGS") && tenant_id !== null) ? moduleList?.find(e => e.moduleName === "Settings") && <SubMenu
                                label={<span>Settings</span>}
                                icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M12.4277 2C11.3139 2 10.2995 2.6007 8.27081 3.80211L7.58466 4.20846C5.55594 5.40987 4.54158 6.01057 3.98466 7C3.42773 7.98943 3.42773 9.19084 3.42773 11.5937V12.4063C3.42773 14.8092 3.42773 16.0106 3.98466 17C4.54158 17.9894 5.55594 18.5901 7.58466 19.7915L8.27081 20.1979C10.2995 21.3993 11.3139 22 12.4277 22C13.5416 22 14.5559 21.3993 16.5847 20.1979L17.2708 19.7915C19.2995 18.5901 20.3139 17.9894 20.8708 17C21.4277 16.0106 21.4277 14.8092 21.4277 12.4063V11.5937C21.4277 9.19084 21.4277 7.98943 20.8708 7C20.3139 6.01057 19.2995 5.40987 17.2708 4.20846L16.5847 3.80211C14.5559 2.6007 13.5416 2 12.4277 2ZM8.67773 12C8.67773 9.92893 10.3567 8.25 12.4277 8.25C14.4988 8.25 16.1777 9.92893 16.1777 12C16.1777 14.0711 14.4988 15.75 12.4277 15.75C10.3567 15.75 8.67773 14.0711 8.67773 12Z" fill="white" />
                                </svg>}
                                className={'safety'}
                            >
                                {hasPermission('view', findPermissionProcess('Settings', 'General/Notification')?.permission) && <SubMenu
                                    label={<span>Your Preferences</span>}
                                    className={'safety'}
                                >
                                    <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                        <Link className="dropdown-item" to={'profile'} >
                                            General/Notification
                                        </Link>
                                    </MenuItem>
                                </SubMenu>}
                                <SubMenu
                                    label={<span>Account Setup</span>}
                                    className={'safety'}>

                                    <SimpleBar style={{ maxHeight: 300 }} autoHide={false}>
                                        {tenant_id === null ? ACCOUNT_SETUP_MENU.map((x) => (
                                            hasPermission('view', findPermissionProcess('Settings', x.menu)?.permission) ? <MenuItem key={`${x.id}_master_${x.menu}`} icon={<span>&#10070;</span>} className='entity'>
                                                <Link className="dropdown-item" to={x.path}>
                                                    {x.menu}
                                                </Link>
                                            </MenuItem> : null
                                        )) : ACCOUNT_SETUP_MENU.filter(el => el.menu === 'User/Roles' || el.menu === 'Port' || el.menu === 'Designation').map((x) => (
                                            hasPermission('view', findPermissionProcess('Settings', x.menu)?.permission) ? <MenuItem key={`${x.id}_master_${x.menu}`} icon={<span>&#10070;</span>} className='entity'>
                                                <Link className="dropdown-item" to={x.path}>
                                                    {tenantResult.includes("PMS") && x.menu === "PMS" ? x.menu : x.menu}
                                                </Link>
                                            </MenuItem> : null))
                                        }
                                        {hasPermission('view', findPermissionProcess('Settings', 'Billing')?.permission) && <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                            <Link className="dropdown-item" to={'saas/billing'} >
                                                Billing
                                            </Link>
                                        </MenuItem>}

                                        {/* <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                            <Link className="dropdown-item" to={'user_roles'} >
                                                Account Default
                                            </Link>
                                        </MenuItem> */}

                                        {/* <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                            <Link className="dropdown-item" to={'user_roles'} >
                                                User/Roles
                                            </Link>
                                        </MenuItem>
                                        <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                            <Link className="dropdown-item" to={'vessel_type'} >
                                                Vessel list/Type
                                            </Link>
                                        </MenuItem>
                                        <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                            <Link className="dropdown-item" to={'uom_currency'} >
                                                UOM/Currency Exchange
                                            </Link>
                                        </MenuItem>
                                        <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                            <Link className="dropdown-item" to={'location_setting'} >
                                                Location
                                            </Link>
                                        </MenuItem>
                                        <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                            <Link className="dropdown-item" to={'company_setting'} >
                                                General
                                            </Link>
                                        </MenuItem> */}
                                        {/* <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                            <Link className="dropdown-item" to={'approve_level_setting'} >
                                                Approval Level
                                            </Link>
                                        </MenuItem> */}

                                    </SimpleBar>
                                </SubMenu>
                                {tenantResult.includes("PMS") && <SubMenu
                                    label={<span>PMS</span>}
                                    className={'safety'}
                                >
                                    {hasPermission('view', findPermissionProcess('Settings', 'PMS Settings')?.permission) && <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                        <Link className="dropdown-item" to={'/PMS/type'} >
                                            PMS Settings
                                        </Link>
                                    </MenuItem>}
                                    {hasPermission('view', findPermissionProcess('Settings', 'Certificate')?.permission) && <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                        <Link className="dropdown-item" to={'/certificate'} >
                                            Certificate
                                        </Link>
                                    </MenuItem>}
                                </SubMenu>}
                                {tenant_id === null && <SubMenu
                                    label={<span>Safety</span>}
                                    className={'safety'}>
                                    <SimpleBar style={{ maxHeight: 300 }} autoHide={false}>
                                        {hasPermission('view', findPermissionProcess('Settings', 'Checklist for')?.permission) && <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                            <Link className="dropdown-item" to="/safety/checklist_for" >
                                                Checklist for
                                            </Link>
                                        </MenuItem>}
                                        {hasPermission('view', findPermissionProcess('Settings', 'Checklist Item Group')?.permission) && <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                            <Link className="dropdown-item" to="/safety/checklistItemGroup" >
                                                Checklist Item Group
                                            </Link>
                                        </MenuItem>}
                                        {hasPermission('view', findPermissionProcess('Settings', 'Audit Master')?.permission) && <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                            <Link className="dropdown-item" to="/safety/audit_master" >
                                                Audit Master
                                            </Link>
                                        </MenuItem>}
                                        {hasPermission('view', findPermissionProcess('Settings', 'NC/OBS Category')?.permission) && <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                            <Link className="dropdown-item" to="/safety/ncobs" >
                                                NC/OBS Category
                                            </Link>
                                        </MenuItem>}
                                        {hasPermission('view', findPermissionProcess('Settings', 'NC/OBS Sub Category')?.permission) && <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                            <Link className="dropdown-item" to="/safety/sub_ncobs" >
                                                NC/OBS Sub Category
                                            </Link>
                                        </MenuItem>
                                        }
                                        {hasPermission('view', findPermissionProcess('Settings', 'Root Cause Category')?.permission) && <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                            <Link className="dropdown-item" to="/safety/root_cause" >
                                                Root Cause Category
                                            </Link>
                                        </MenuItem>}
                                        {hasPermission('view', findPermissionProcess('Settings', 'ISM/SMS Code Reference')?.permission) && <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                            <Link className="dropdown-item" to="/safety/ism" >
                                                ISM/SMS Code Reference
                                            </Link>
                                        </MenuItem>}
                                        {hasPermission('view', findPermissionProcess('Settings', 'NC Type')?.permission) && <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                            <Link className="dropdown-item" to="/safety/nc_type" >
                                                NC Type
                                            </Link>
                                        </MenuItem>}
                                        {hasPermission('view', findPermissionProcess('Settings', 'NC Category')?.permission) && <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                            <Link className="dropdown-item" to="/safety/nc_category" >
                                                NC Category
                                            </Link>
                                        </MenuItem>}
                                        {hasPermission('view', findPermissionProcess('Settings', 'Status of Condition')?.permission) && <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                            <Link className="dropdown-item" to="/safety/status_condition" >
                                                Status of Condition
                                            </Link>
                                        </MenuItem>}
                                        {hasPermission('view', findPermissionProcess('Settings', 'Inspection Master')?.permission) && <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                            <Link className="dropdown-item" to="/safety/inspection_master" >
                                                Inspection Master
                                            </Link>
                                        </MenuItem>}
                                        {hasPermission('view', findPermissionProcess('Settings', 'Meeting Name')?.permission) && <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                            <Link className="dropdown-item" to="/safety/meeting/meeting_name_master" >
                                                Meeting Name
                                            </Link>
                                        </MenuItem>}
                                        {hasPermission('view', findPermissionProcess('Settings', 'Drill Name')?.permission) && <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                            <Link className="dropdown-item" to="/safety/meeting/drill_name_master" >
                                                Drill Name
                                            </Link>
                                        </MenuItem>}
                                        {hasPermission('view', findPermissionProcess('Settings', 'Meeting Schedule')?.permission) && <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                            <Link className="dropdown-item" to="/safety/meeting/meeting_schedule" >
                                                Meeting Schedule
                                            </Link>
                                        </MenuItem>}
                                        {hasPermission('view', findPermissionProcess('Settings', 'Drill')?.permission) && <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                            <Link className="dropdown-item" to="/safety/meeting/drill_schedule" >
                                                Drill
                                            </Link>
                                        </MenuItem>}
                                        {hasPermission('view', findPermissionProcess('Settings', 'Meeting Agenda')?.permission) && <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                            <Link className="dropdown-item" to="/safety/meeting/meeting_agenda_master" >
                                                Meeting Agenda
                                            </Link>
                                        </MenuItem>}
                                        {Object.keys(IncidentMasterName).map(el => <React.Fragment key={el}>
                                            {hasPermission('view', findPermissionProcess('Settings', IncidentMasterName[el])?.permission) && <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                                <Link className="dropdown-item" to={`/safety/master/incident?master_name=${el}`} >
                                                    {IncidentMasterName[el]}
                                                </Link>
                                            </MenuItem>}
                                        </React.Fragment>)}
                                        {hasPermission('view', findPermissionProcess('Settings', 'Vessel Item Group')?.permission) && <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                            <Link className="dropdown-item" to='/safety/vesselItemGroup' >
                                                Vessel Item Group
                                            </Link>
                                        </MenuItem>}
                                        {hasPermission('view', findPermissionProcess('Settings', 'Vessel Item Sub Group')?.permission) && <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                            <Link className="dropdown-item" to='/safety/VesselItemSubGroup' >
                                                Vessel Item Sub Group
                                            </Link>
                                        </MenuItem>}
                                        {hasPermission('view', findPermissionProcess('Settings', 'Vessel Inspection Master')?.permission) && <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                            <Link className="dropdown-item" to='/safety/VesselInspectionMaster' >
                                                Vessel Inspection Master
                                            </Link>
                                        </MenuItem>}
                                    </SimpleBar>
                                </SubMenu>}
                                {tenantResult.includes("INVENTORY") && <SubMenu
                                    label={<span>Inventory</span>}
                                    className={'safety'}>

                                    <SimpleBar style={{ maxHeight: 300 }} autoHide={false}>
                                        {ACCOUNT_INVENTORY_MENU.map((x) => (
                                            hasPermission('view', findPermissionProcess('Settings', x.menu)?.permission) && <MenuItem key={`${x.id}_master_${x.menu}`} icon={<span>&#10070;</span>} className='entity'>
                                                <Link className="dropdown-item" to={x.path}>
                                                    {x.menu}
                                                </Link>
                                            </MenuItem>
                                        ))}
                                    </SimpleBar>
                                </SubMenu>}
                                {tenant_id === null && <SubMenu
                                    label={<span>Purchase</span>}
                                    className={'safety'}>

                                    <SimpleBar style={{ maxHeight: 300 }} autoHide={false}>
                                        {ACCOUNT_PURCHASE_MENU.map((x) => (
                                            hasPermission('view', findPermissionProcess('Settings', x.menu)?.permission) && <MenuItem key={`${x.id}_master_${x.menu}`} icon={<span>&#10070;</span>} className='entity'>
                                                <Link className="dropdown-item" to={x.path}>
                                                    {x.menu}
                                                </Link>
                                            </MenuItem>
                                        ))}
                                    </SimpleBar>
                                </SubMenu>}
                                {tenant_id === null && <SubMenu
                                    label={<span>E-Doc</span>}
                                    className={'safety'}>

                                    <SimpleBar style={{ maxHeight: 300 }} autoHide={false}>
                                        {hasPermission('view', findPermissionProcess('Settings', 'Document Category')?.permission) && <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                            <Link className="dropdown-item" to="/e-doc/doc-category" >
                                                Document Category
                                            </Link>
                                        </MenuItem>}
                                        {hasPermission('view', findPermissionProcess('Settings', 'Change Request')?.permission) && <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                            <Link className="dropdown-item" to="/e-doc/change-request" >
                                                Change Request
                                            </Link>
                                        </MenuItem>}
                                    </SimpleBar>
                                </SubMenu>}
                                <SubMenu
                                    label={<span>Masters</span>}
                                    className={'safety'}>

                                    <SimpleBar style={{ maxHeight: 300 }} autoHide={false}>
                                        {tenant_id === null ? MASTERS_SCREENS_MENU.filter(el => el.menu !== 'Certificate').map((x) => (
                                            hasPermission('view', findPermissionProcess('Settings', x.menu)?.permission) && <MenuItem key={`${x.id}_master_${x.menu}`} icon={<span>&#10070;</span>} className='entity'>
                                                <Link className="dropdown-item" to={x.path}>
                                                    {x.menu}
                                                </Link>
                                            </MenuItem>
                                        )) : MASTERS_SCREENS_MENU.filter(el => el.menu !== 'Certificate').map((x) => (
                                            hasPermission('view', findPermissionProcess('Settings', x.menu)?.permission) && <MenuItem key={`${x.id}_master_${x.menu}`} icon={<span>&#10070;</span>} className='entity'>
                                                <Link className="dropdown-item" to={x.path}>
                                                    {x.menu}
                                                </Link>
                                            </MenuItem>))}
                                    </SimpleBar>
                                </SubMenu>
                            </SubMenu> : tenant_id === null && moduleList?.find(e => e.moduleName === "Settings") && <SubMenu
                                label={<span>Settings</span>}
                                icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M12.4277 2C11.3139 2 10.2995 2.6007 8.27081 3.80211L7.58466 4.20846C5.55594 5.40987 4.54158 6.01057 3.98466 7C3.42773 7.98943 3.42773 9.19084 3.42773 11.5937V12.4063C3.42773 14.8092 3.42773 16.0106 3.98466 17C4.54158 17.9894 5.55594 18.5901 7.58466 19.7915L8.27081 20.1979C10.2995 21.3993 11.3139 22 12.4277 22C13.5416 22 14.5559 21.3993 16.5847 20.1979L17.2708 19.7915C19.2995 18.5901 20.3139 17.9894 20.8708 17C21.4277 16.0106 21.4277 14.8092 21.4277 12.4063V11.5937C21.4277 9.19084 21.4277 7.98943 20.8708 7C20.3139 6.01057 19.2995 5.40987 17.2708 4.20846L16.5847 3.80211C14.5559 2.6007 13.5416 2 12.4277 2ZM8.67773 12C8.67773 9.92893 10.3567 8.25 12.4277 8.25C14.4988 8.25 16.1777 9.92893 16.1777 12C16.1777 14.0711 14.4988 15.75 12.4277 15.75C10.3567 15.75 8.67773 14.0711 8.67773 12Z" fill="white" />
                                </svg>}
                                className={'safety'}
                            >
                                {hasPermission('view', findPermissionProcess('Settings', 'General/Notification')?.permission) && <SubMenu
                                    label={<span>Your Preferences</span>}
                                    className={'safety'}
                                >
                                    <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                        <Link className="dropdown-item" to={'profile'} >
                                            General/Notification
                                        </Link>
                                    </MenuItem>
                                </SubMenu>}
                                <SubMenu
                                    label={<span>Account Setup</span>}
                                    className={'safety'}>

                                    <SimpleBar style={{ maxHeight: 300 }} autoHide={false}>
                                        {ACCOUNT_SETUP_MENU.map((x) => (
                                            hasPermission('view', findPermissionProcess('Settings', x.menu)?.permission) && <MenuItem key={`${x.id}_master_${x.menu}`} icon={<span>&#10070;</span>} className='entity'>
                                                <Link className="dropdown-item" to={x.path}>
                                                    {x.menu}
                                                </Link>
                                            </MenuItem>
                                        ))}

                                        {/* <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                            <Link className="dropdown-item" to={'user_roles'} >
                                                Account Default
                                            </Link>
                                        </MenuItem> */}

                                        {/* <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                            <Link className="dropdown-item" to={'user_roles'} >
                                                User/Roles
                                            </Link>
                                        </MenuItem>
                                        <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                            <Link className="dropdown-item" to={'vessel_type'} >
                                                Vessel list/Type
                                            </Link>
                                        </MenuItem>
                                        <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                            <Link className="dropdown-item" to={'uom_currency'} >
                                                UOM/Currency Exchange
                                            </Link>
                                        </MenuItem>
                                        <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                            <Link className="dropdown-item" to={'location_setting'} >
                                                Location
                                            </Link>
                                        </MenuItem>
                                        <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                            <Link className="dropdown-item" to={'company_setting'} >
                                                General
                                            </Link>
                                        </MenuItem> */}
                                        {/* <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                            <Link className="dropdown-item" to={'approve_level_setting'} >
                                                Approval Level
                                            </Link>
                                        </MenuItem> */}

                                    </SimpleBar>
                                </SubMenu>
                                <SubMenu
                                    label={<span>PMS</span>}
                                    className={'safety'}
                                >
                                    {hasPermission('view', findPermissionProcess('Settings', 'PMS Settings')?.permission) && <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                        <Link className="dropdown-item" to={'/PMS/type'} >
                                            PMS Settings
                                        </Link>
                                    </MenuItem>}
                                    {hasPermission('view', findPermissionProcess('Settings', 'Certificate')?.permission) && <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                        <Link className="dropdown-item" to={'/certificate'} >
                                            Certificate
                                        </Link>
                                    </MenuItem>}
                                </SubMenu>
                                <SubMenu
                                    label={<span>Safety</span>}
                                    className={'safety'}>
                                    <SimpleBar style={{ maxHeight: 300 }} autoHide={false}>
                                        {hasPermission('view', findPermissionProcess('Settings', 'Checklist for')?.permission) && <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                            <Link className="dropdown-item" to="/safety/checklist_for" >
                                                Checklist for
                                            </Link>
                                        </MenuItem>}
                                        {hasPermission('view', findPermissionProcess('Settings', 'Checklist Item Group')?.permission) && <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                            <Link className="dropdown-item" to="/safety/checklistItemGroup" >
                                                Checklist Item Group
                                            </Link>
                                        </MenuItem>}
                                        {hasPermission('view', findPermissionProcess('Settings', 'Audit Master')?.permission) && <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                            <Link className="dropdown-item" to="/safety/audit_master" >
                                                Audit Master
                                            </Link>
                                        </MenuItem>}
                                        {hasPermission('view', findPermissionProcess('Settings', 'NC/OBS Category')?.permission) && <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                            <Link className="dropdown-item" to="/safety/ncobs" >
                                                NC/OBS Category
                                            </Link>
                                        </MenuItem>}
                                        {hasPermission('view', findPermissionProcess('Settings', 'NC/OBS Sub Category')?.permission) && <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                            <Link className="dropdown-item" to="/safety/sub_ncobs" >
                                                NC/OBS Sub Category
                                            </Link>
                                        </MenuItem>
                                        }
                                        {hasPermission('view', findPermissionProcess('Settings', 'Root Cause Category')?.permission) && <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                            <Link className="dropdown-item" to="/safety/root_cause" >
                                                Root Cause Category
                                            </Link>
                                        </MenuItem>}
                                        {hasPermission('view', findPermissionProcess('Settings', 'ISM/SMS Code Reference')?.permission) && <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                            <Link className="dropdown-item" to="/safety/ism" >
                                                ISM/SMS Code Reference
                                            </Link>
                                        </MenuItem>}
                                        {hasPermission('view', findPermissionProcess('Settings', 'NC Type')?.permission) && <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                            <Link className="dropdown-item" to="/safety/nc_type" >
                                                NC Type
                                            </Link>
                                        </MenuItem>}
                                        {hasPermission('view', findPermissionProcess('Settings', 'NC Category')?.permission) && <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                            <Link className="dropdown-item" to="/safety/nc_category" >
                                                NC Category
                                            </Link>
                                        </MenuItem>}
                                        {hasPermission('view', findPermissionProcess('Settings', 'Status of Condition')?.permission) && <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                            <Link className="dropdown-item" to="/safety/status_condition" >
                                                Status of Condition
                                            </Link>
                                        </MenuItem>}
                                        {hasPermission('view', findPermissionProcess('Settings', 'Inspection Master')?.permission) && <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                            <Link className="dropdown-item" to="/safety/inspection_master" >
                                                Inspection Master
                                            </Link>
                                        </MenuItem>}
                                        {hasPermission('view', findPermissionProcess('Settings', 'Meeting Name')?.permission) && <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                            <Link className="dropdown-item" to="/safety/meeting/meeting_name_master" >
                                                Meeting Name
                                            </Link>
                                        </MenuItem>}
                                        {hasPermission('view', findPermissionProcess('Settings', 'Drill Name')?.permission) && <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                            <Link className="dropdown-item" to="/safety/meeting/drill_name_master" >
                                                Drill Name
                                            </Link>
                                        </MenuItem>}
                                        {hasPermission('view', findPermissionProcess('Settings', 'Meeting Schedule')?.permission) && <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                            <Link className="dropdown-item" to="/safety/meeting/meeting_schedule" >
                                                Meeting Schedule
                                            </Link>
                                        </MenuItem>}
                                        {hasPermission('view', findPermissionProcess('Settings', 'Drill')?.permission) && <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                            <Link className="dropdown-item" to="/safety/meeting/drill_schedule" >
                                                Drill
                                            </Link>
                                        </MenuItem>}
                                        {hasPermission('view', findPermissionProcess('Settings', 'Meeting Agenda')?.permission) && <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                            <Link className="dropdown-item" to="/safety/meeting/meeting_agenda_master" >
                                                Meeting Agenda
                                            </Link>
                                        </MenuItem>}
                                        {Object.keys(IncidentMasterName).map(el => <React.Fragment key={el}>
                                            {hasPermission('view', findPermissionProcess('Settings', IncidentMasterName[el])?.permission) && <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                                <Link className="dropdown-item" to={`/safety/master/incident?master_name=${el}`} >
                                                    {IncidentMasterName[el]}
                                                </Link>
                                            </MenuItem>}
                                        </React.Fragment>)}
                                        {hasPermission('view', findPermissionProcess('Settings', 'Vessel Item Group')?.permission) && <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                            <Link className="dropdown-item" to='/safety/vesselItemGroup' >
                                                Vessel Item Group
                                            </Link>
                                        </MenuItem>}
                                        {hasPermission('view', findPermissionProcess('Settings', 'Vessel Item Sub Group')?.permission) && <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                            <Link className="dropdown-item" to='/safety/VesselItemSubGroup' >
                                                Vessel Item Sub Group
                                            </Link>
                                        </MenuItem>}
                                        {hasPermission('view', findPermissionProcess('Settings', 'Vessel Inspection Master')?.permission) && <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                            <Link className="dropdown-item" to='/safety/VesselInspectionMaster' >
                                                Vessel Inspection Master
                                            </Link>
                                        </MenuItem>}
                                    </SimpleBar>
                                </SubMenu>
                                <SubMenu
                                    label={<span>Inventory</span>}
                                    className={'safety'}>

                                    <SimpleBar style={{ maxHeight: 300 }} autoHide={false}>
                                        {ACCOUNT_INVENTORY_MENU.map((x) => (
                                            hasPermission('view', findPermissionProcess('Settings', x.menu)?.permission) && <MenuItem key={`${x.id}_master_${x.menu}`} icon={<span>&#10070;</span>} className='entity'>
                                                <Link className="dropdown-item" to={x.path}>
                                                    {x.menu}
                                                </Link>
                                            </MenuItem>
                                        ))}
                                    </SimpleBar>
                                </SubMenu>
                                <SubMenu
                                    label={<span>Purchase</span>}
                                    className={'safety'}>

                                    <SimpleBar style={{ maxHeight: 300 }} autoHide={false}>
                                        {ACCOUNT_PURCHASE_MENU.map((x) => (
                                            hasPermission('view', findPermissionProcess('Settings', x.menu)?.permission) && <MenuItem key={`${x.id}_master_${x.menu}`} icon={<span>&#10070;</span>} className='entity'>
                                                <Link className="dropdown-item" to={x.path}>
                                                    {x.menu}
                                                </Link>
                                            </MenuItem>
                                        ))}
                                    </SimpleBar>
                                </SubMenu>
                                <SubMenu
                                    label={<span>E-Doc</span>}
                                    className={'safety'}>

                                    <SimpleBar style={{ maxHeight: 300 }} autoHide={false}>
                                        {hasPermission('view', findPermissionProcess('Settings', 'Document Category')?.permission) && <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                            <Link className="dropdown-item" to="/e-doc/doc-category" >
                                                Document Category
                                            </Link>
                                        </MenuItem>}
                                        {hasPermission('view', findPermissionProcess('Settings', 'Change Request')?.permission) && <MenuItem icon={<span>&#10070;</span>} className='entity'>
                                            <Link className="dropdown-item" to="/e-doc/change-request" >
                                                Change Request
                                            </Link>
                                        </MenuItem>}
                                    </SimpleBar>
                                </SubMenu>
                                <SubMenu
                                    label={<span>Masters</span>}
                                    className={'safety'}>

                                    <SimpleBar style={{ maxHeight: 300 }} autoHide={false}>
                                        {MASTERS_SCREENS_MENU.filter(el => el.menu !== 'Certificate').map((x) => (
                                            hasPermission('view', findPermissionProcess('Settings', x.menu)?.permission) && <MenuItem key={`${x.id}_master_${x.menu}`} icon={<span>&#10070;</span>} className='entity'>
                                                <Link className="dropdown-item" to={x.path}>
                                                    {x.menu}
                                                </Link>
                                            </MenuItem>
                                        ))}
                                    </SimpleBar>
                                </SubMenu>
                            </SubMenu>}
                        </Menu>
                    </SimpleBar>
                </main>
            </Sidebar>
        </div>
    );
}
export default Sidebars;
