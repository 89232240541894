import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import * as ACTIONS from '../constant/Actions'
import { API_SERVICE } from "../Services/CommonApi";
import * as APIS from '../constant/CommonApiUrl'
import { toast } from "react-toastify";
import { ConvertJson } from "../helper";
import { dateTimeToDate } from "../helper/dateUtil";
export const fetchContractGeneral=createAsyncThunk(ACTIONS.FETCH_CONTRACT_GENERAL,
    async(arg,thunkAPI)=>{
      try{
        const {id,vessel_name}=thunkAPI.getState().root.common.vessel_detailByID        
        return await API_SERVICE.get(`${APIS.GET_CONTRACT_PRODUCT}?${arg}&vessel_id=${vessel_name==='OFFICE'?0:id}`)
      }catch(err){
        return thunkAPI.rejectWithValue(err)
      }
    }
  )
export const saveContractApi=createAsyncThunk(ACTIONS.SAVE_CONTRACT_ACTION,
    async(arg,thunkAPI)=>{
      try{                
        const response=await API_SERVICE.post(`${APIS.SAVE_CONTRACT}`,arg) 
        thunkAPI.dispatch(listContract())       
        return response
      }catch(err){
        return thunkAPI.rejectWithValue(err)
      }
    }
  )
  export const listContract=createAsyncThunk(ACTIONS.LIST_CONTRACT_ACTION,
    async(arg,thunkAPI)=>{
      try{
        const {id,vessel_name}=thunkAPI.getState().root.common.vessel_detailByID
        return await API_SERVICE.get(`${APIS.LIST_CONTRACT}?vessel_id=${vessel_name==='OFFICE'?0:id}`)
      }catch(err){
        return thunkAPI.rejectWithValue(err)
      }
    }
  )
  export const fetchContractbyID=createAsyncThunk(ACTIONS.GET_CONTRACTbyID_ACTION,
    async(arg,thunkAPI)=>{
      try{
        const {id,vessel_name}=thunkAPI.getState().root.common.vessel_detailByID
        return await API_SERVICE.get(`${APIS.LIST_CONTRACT}?${arg}&vessel_id=${vessel_name==='OFFICE'?0:id}`)
      }catch(err){
        return thunkAPI.rejectWithValue(err)
      }
    }
  )
  export const fetchContractCatType=createAsyncThunk(ACTIONS.GET_CONTACT_CATTYPE,
    async(arg,thunkAPI)=>{
      try{
        //const {id,vessel_name}=thunkAPI.getState().root.common.vessel_detailByID
        let response={}
        const {category,type}=arg
        const vessel_id=thunkAPI.getState().root.common.vessel_detailByID.id
        switch(type){
          case 'COMMON':
            response=await API_SERVICE.get(`${APIS.GET_CONTACT_CATTYPE}?type1=CONRATECATTYPE&type2=${category}`)
            //response=response.data.result.data.map(x=>({...x,id:x.id,typename:x.typename,type:'COMMON'}))
            response={response:response,type:'COMMON'}
            break;
          case 'PART':
            response=await API_SERVICE.get(`${APIS.GET_EQUIP_DROPDOWN}?vessel_id=${vessel_id}`)
            //response=response.data.result.data.map(x=>({...x,id:x.id,typename:x.equipmentname,type:'PART'}))
            response={response:response,type:'PART'}
            break;
          default:
            return null
        }
        return response
      }catch(err){
        return thunkAPI.rejectWithValue(err)
      }
    }
  )
const initialState={
    contract_list:[],
    product_items:[],
    bulk_Total:0,
    category_type:[],
    contract_item:{},
    edit_id:''
}
export const contractRateSlice=createSlice({
    name:'contract',
    initialState,
    reducers:{
        addContractItems:(state,action)=>{
            let sum=0
            switch(action.payload.type){
              case 'QUANTITY':
                state.product_items=state.product_items.map(x=>x.id===action.payload.id?{...x,qty:Number(action.payload.quantity),total:(Number(action.payload.quantity)*Number(action.payload.price))}:x)
                break;
              case 'PRICE':
                state.product_items=state.product_items.map(x=>x.id===action.payload.id?{...x,price:Number(action.payload.net_Unit_cost),total:(Number(x.qty)*Number(action.payload.net_Unit_cost)),port_id:action.payload.port_id}:x)         
                break;
              case 'FROMDT':
                state.product_items=state.product_items.map(x=>x.id===action.payload.id?{...x,fromdt:action.payload.fromdt,todt:action.payload.todt}:x)
                break;
              case 'TODT':
                state.product_items=state.product_items.map(x=>x.id===action.payload.id?{...x,todt:action.payload.todt,fromdt:action.payload.fromdt}:x)
                break;
              case 'MINIMUM':
                state.product_items=state.product_items.map(x=>x.id===action.payload.id?{...x,min_order:action.payload.min_order}:x)
                break;
              case 'PORT':
                state.product_items=state.product_items.map(x=>x.id===action.payload.id?{...x,port_id:action.payload.port_id}:x)
                break;
              default:
                return null
            }
            state.product_items.map(x=>{x.total&&(sum=Number(x.total)+Number(sum))})      
            state.bulk_Total=sum  
          },
        editContractItems:(state,action)=>{
            let sum=0
            switch(action.payload.type){
              case 'QUANTITY':
                state.product_items=state.product_items.map(x=>x.id===action.payload.id?{...x,qty:Number(action.payload.quantity),total:(Number(action.payload.quantity)*Number(action.payload.price))}:x)
                break;
              case 'PRICE':
                state.product_items=state.product_items.map(x=>x.id===action.payload.id?{...x,price:Number(action.payload.net_Unit_cost),total:(Number(x.qty)*Number(action.payload.net_Unit_cost))}:x)         
                break;
              case 'FROMDT':
                state.product_items=state.product_items.map(x=>x.id===action.payload.id?{...x,valid_from:action.payload.fromdt,valid_to:action.payload.todt}:x)
                break;
              case 'TODT':
                state.product_items=state.product_items.map(x=>x.id===action.payload.id?{...x,valid_to:action.payload.todt,valid_from:action.payload.fromdt}:x)
                break;
              case 'MINIMUM':
                state.product_items=state.product_items.map(x=>x.id===action.payload.id?{...x,min_order:action.payload.min_order}:x)
                break;
              case 'PORT':
                state.product_items=state.product_items.map(x=>x.id===action.payload.id?{...x,port_id:action.payload.port_id}:x)
                break;
              default:
                return null
            }
            state.product_items.map(x=>{x.total&&(sum=Number(x.total)+Number(sum))})      
            state.bulk_Total=sum  
          },
          clearContract:(state,action)=>{
            state.product_items=[]
            state.bulk_Total=0
            state.contract_item={}
          }
    },
    extraReducers:(builder)=>{
        (builder)
        .addCase(fetchContractGeneral.fulfilled,(state,action)=>{
            if(action.payload.data.result.status){
                state.product_items=action.payload.data.result.data.map(x=>({...x,qty:1,min_order:x.min_order?x.min_order:1,port_id:''}))
            }else{
                toast.error(action.payload.data.result.message)
            }            
        }).addCase(fetchContractGeneral.rejected,(state,action)=>{
            if(action.payload.response.data.status===400){
                toast.error(action.payload.response.data.title)                
            }            
        })
        .addCase(saveContractApi.fulfilled,(state,action)=>{
            if(action.payload.data.result.status){
                state.edit_id=action.payload.data.result.data[0].id
                toast.success(action.payload.data.result.message)
            }else{
                toast.error(action.payload.data.result.message)
            }            
        }).addCase(saveContractApi.rejected,(state,action)=>{
            if(action.payload.response.data.status===400){
                toast.error(action.payload.response.data.title)                
            }            
        })
        .addCase(listContract.fulfilled,(state,action)=>{
            if(action.payload.data.result.status){
                state.contract_list=action.payload.data.result.data
            }else{
                toast.error(action.payload.data.result.message)
            }            
        }).addCase(listContract.rejected,(state,action)=>{
            if(action.payload.response.data.status===400){
                toast.error(action.payload.response.data.title)                
            }            
        })
        .addCase(fetchContractCatType.fulfilled,(state,action)=>{
            if(action.payload.response.data.result.status){  
              let data=[]            
              switch(action.payload.type){
                case 'COMMON':
                  data=action.payload.response.data.result.data.map(x=>({...x,type:action.payload.type}))
                  state.category_type=data
                  break;
                case 'PART':
                  data=action.payload.response.data.result.data.map(x=>({...x,typename:x.equipmentname,type:action.payload.type}))
                  state.category_type=data
                  break;
                default:
                  return null;
              }                
            }else{
                toast.error(action.payload.response.data.result.message)
            }            
        }).addCase(fetchContractCatType.rejected,(state,action)=>{
            if(action.payload.response.data.status===400){
                toast.error(action.payload.response.data.title)                
            }            
        })
        .addCase(fetchContractbyID.fulfilled,(state,action)=>{
            if(action.payload.data.result.status){
                const data = action.payload.data.result.data[0]
                let cR_DetailModel = ConvertJson(data.cR_DetailModel)
                let repository = data?.repository ? ConvertJson(data.repository) : []
                state.product_items=cR_DetailModel
                state.contract_item=Object.assign({},{...data,
                    /* agreement_date:dateTimeToDate(data.agreement_date, "y/m/d"),
                    valid_from:dateTimeToDate(data.valid_from, "y/m/d"),
                    valid_to:dateTimeToDate(data.valid_to, "y/m/d"),  */
                    agreement_date:new Date(`${data.agreement_date}Z`),
                    valid_from:new Date(`${data.valid_from}Z`),
                    valid_to:new Date(`${data.valid_to}Z`),
                    cR_DetailModel:cR_DetailModel,
                    repository
                })
                state.bulk_Total=data.grand_total
            }else{
                toast.error(action.payload.data.result.message)
            }            
        }).addCase(fetchContractbyID.rejected,(state,action)=>{
            if(action.payload.response.data.status===400){
                toast.error(action.payload.response.data.title)                
            }            
        })
    }
})
export const {addContractItems,clearContract,editContractItems}=contractRateSlice.actions
export default contractRateSlice.reducer