import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f8f9fa;
`;

const ErrorCode = styled.h1`
  font-size: 5rem;
  margin-bottom: 1rem;
`;

const ErrorMessage = styled.p`
  font-size: 1.5rem;
  color: #6c757d;
`;

export const NotFound = () => {
  return (
    <Container>
      <ErrorCode>404</ErrorCode>
      <ErrorMessage>Oops! The page you&apos;re looking for doesn&apos;t exist.</ErrorMessage>
    </Container>
  );
};

export default NotFound;
