import React from "react"
import { Link } from "react-router-dom"
import { MoreSelectedBadge } from "../components/common/MoreSelectBadge"
import { components } from "react-select";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { BiChevronDownCircle, BiChevronRightCircle } from "react-icons/bi";
export const dashboardEvent = (el) => {
  const dashboardClass = {
    PR_REJECT: <PurchaseCalendarTooltip path={'/purchase_requisition/'} id={el.id} dashboard={el.dashboard} prId={el.pr_id} />,
    QC_APPROVAL: <PurchaseCalendarTooltip path={'/qc/edit/'} id={el.id} dashboard={el.dashboard} prId={el.pr_id} />,
    PO_PENDING: <PurchaseCalendarTooltip path={'/purchase-order/create/'} id={el.id} dashboard={el.dashboard} prId={el.pr_id} />,
    PO_APPROVE: <PurchaseCalendarTooltip path={'/purchase-order/edit/'} id={el.id} dashboard={el.dashboard} prId={el.pr_id} />,
    RFQ: <PurchaseCalendarTooltip path={'/rfq/create/'} id={el.id} dashboard={el.dashboard} prId={el.pr_id} />,
    GOODS_RECEIVED: <PurchaseCalendarTooltip path={'/goods/new/'} id={el.id} dashboard={el.dashboard} prId={el.pr_id} />,
    INVOICE: <PurchaseCalendarTooltip path={'/invoice/new/'} id={el.id} dashboard={el.dashboard} prId={el.pr_id} />,
    AMENDMENT: <PurchaseCalendarTooltip path={'/purchase-order/edit/'} id={el.id} dashboard={el.dashboard} prId={el.pr_id} />,
    PR_NEW: <PurchaseCalendarTooltip path={'/purchase_requisition/'} id={el.id} dashboard={el.dashboard} prId={el.pr_id} />,
    DEFAULT: <span className="DEFAULT">{el.pr_id}</span>
  }
  return dashboardClass[el.dashboard]
}
export const PurchaseCalendarTooltip = (props) => (
  <OverlayTrigger
    placement="top"
    delay={{ show: 250, hide: 400 }}
    overlay={<Tooltip>{props.prId}</Tooltip>}
  >
    <Link to={`${props.path}${props.id}`} className={props.dashboard}>{props.prId}</Link>
  </OverlayTrigger>)

export const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result.replace(/^data:.+;base64,/, ''));
  reader.onerror = error => reject(error);
});
export const getTreeNodeKeys = (nodes) => {
  let ids = [];
  nodes?.forEach(({ key, children }) => {
    ids = [...ids, key, ...getTreeNodeKeys(children)];
  });
  return ids;
};
export const removeSearchParams = (arg) => {
  let params = new URLSearchParams(arg);
  let keysForDel = [];
  params.forEach((value, key) => {
    if (value === '') {
      keysForDel.push(key);
    }
  });
  keysForDel.forEach(key => {
    params.delete(key);
  });
  return params
}
//nested single tree search
export const singleTreeSearch = (treeArray, value) => {
  for (const el of treeArray) {
    if (el.title.toLowerCase().search(value.toLowerCase()) !== -1) {
      return el
    } else if (el.children?.length > 0) {
      const data = singleTreeSearch(el.children, value)
      return data
    }
  }
}
//nested multi tree search and return entire obj
export const fullTreeSearch = (array, search_term) => {
  const find = ({ searchLabel, children }) => searchLabel.toLowerCase().includes(search_term.toLowerCase()) || children && children.some(find);
  return array.filter(find);
}
//return search data with one parent
//start
export const searchTree = (searchQuery, obj) => {
  let searchResults = [];
  for (let i = 0; i < obj.length; i++) {
    let item = searchString(obj[i], searchQuery, obj);
    if (item) {
      searchResults.push(item);
    }
  }
  return searchResults;
}
const searchString = (obj, string, parent) => {
  if (obj.title.toLowerCase().includes(string.toLowerCase())) {
    let res = obj.parentid !== null ? parent : parent.filter(el => el.key === obj.key)
    res = Array.isArray(res) ? res[0] : res
    return res
  }
  for (let i = 0; i < obj.children.length; i++) {
    const possibleResult = searchString(obj.children[i], string, obj);
    if (possibleResult) {
      return possibleResult;
    }
  }
}
//end

export const MultiValue = ({ index, getValue, ...props }) => {
  const maxToShow = 1;
  const overflow = getValue()
    .slice(maxToShow)
    .map((x) => x.label);

  return index < maxToShow ? (
    <components.MultiValue {...props} />
  ) : index === maxToShow ? (
    <MoreSelectedBadge items={overflow} />
  ) : null;
};

export const isImage = (fileName) => {
  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif'];
  const extension = fileName.split('.').pop().toLowerCase();
  return imageExtensions.includes(extension);
};

const switcherIcon = obj => {
  if (obj.expanded) {
    return <BiChevronDownCircle />
  }
  return <BiChevronRightCircle />
  ;
};

// const inputIcon = getSvg(bubblePath);
// const clearIcon = getSvg(clearPath);
// const removeIcon = getSvg(clearPath);

export const customIcons = {
  // open: <BsArrowRightCircle />,
  // close: <BsArrowDownCircle />
  switcherIcon,
};