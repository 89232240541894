import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { GET_MODULE, SAVE_MODULE } from "../constant/CommonApiUrl";
import { API_SERVICE } from "../Services/CommonApi"
import { toast } from "react-toastify"
import { setTypeFieldsIsUpdated } from "./typeFieldsSlice"

const ADD_MODULE = 'module/addModuleApi'
const UPDATE_MODULE = 'module/updateModuleApi'
const FETCH_MODULE = 'module/fetchModuleApi'
const DELETE_MODULE = 'module/deleteModuleApi'

export const addModuleApi =createAsyncThunk(ADD_MODULE,
    async(args, thunkAPI)=>{
        try{
            let response = await API_SERVICE.post(SAVE_MODULE, args['data'], "CREATE");
            response = response.data.result.data[0];
            thunkAPI.dispatch(setTypeFieldsIsUpdated(true));
            return response;
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
})

export const updateModuleApi = createAsyncThunk(UPDATE_MODULE,
    async(args, thunkAPI)=>{
        try{
            let response = await API_SERVICE.post(SAVE_MODULE, args['data'], "UPDATE");
            response = response.data.result.data[0];
            thunkAPI.dispatch(setTypeFieldsIsUpdated(true));
            return response;
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
})

export const deleteModuleApi = createAsyncThunk(DELETE_MODULE, 
    async (args, thunkAPI) => {
        try{
            const toDelete = thunkAPI.getState().root.module.list.find(ele => ele.id === args['id']);   
            let response = await API_SERVICE.post(SAVE_MODULE, {...toDelete, isdisabled: "Y"});
            response = response.data.result.data[0];
            thunkAPI.dispatch(setTypeFieldsIsUpdated(true));
            return response;
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
    }
)

export const fetchModuleApi = createAsyncThunk(FETCH_MODULE,
    async(arg,thunkAPI)=>{
        try{
            let response = await API_SERVICE.get(GET_MODULE);
            response = response.data.result.data;
            return response
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
    }
)

const initialState={
    list:[],
    editmodule:{},    
    isUpdated: true
}
export const moduleSlice=createSlice({
    name:'module',
    initialState,
    reducers:{
        addModule:(state,action)=>{
            state.list=[...state.list,action.payload]
        },
        delModule:(state,action)=>{
            state.list=state.list.filter(x=>x.id!==action.payload)
        },
        loadEditModule:(state,action)=>{
            state.editmodule=action.payload
        },
        clearEditModule:(state,action)=>{
            state.editmodule={}
        },
        updateModule:(state,action)=>{
            let index=state.list.findIndex(x=>x.id===action.payload.id)
            state.list[index]=action.payload
        },               
        resetModule:()=>initialState
    },
    extraReducers:(builder)=>{
        builder
        .addCase(addModuleApi.fulfilled,(state,action)=>{
            state.list=[...state.list,action.payload];
            toast.success('Successfully added');
        })
        .addCase(updateModuleApi.fulfilled,(state,action)=>{
            state.list = state.list.map(x => x.id === action.payload.id ? action.payload: x);
            toast.success('Successfully updated');
        })
        .addCase(fetchModuleApi.fulfilled, (state, action) => {
            state.list = action.payload;
            state.isUpdated = false;
        })
        .addCase(deleteModuleApi.fulfilled, (state, action) => {
            state.list=state.list.filter(x=>x.id!==action.payload.id);
            toast.success('Successfully deleted');
        })
    }    
})
export const {addModule,delModule,loadEditModule,clearEditModule,updateModule,resetModule}=moduleSlice.actions
export default moduleSlice.reducer