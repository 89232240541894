import axios from "axios";
import { BASE_URL } from "../constant/CommonApiUrl";
import { commonPayloadFields } from "../helper/storage";
import { toast } from "react-toastify";
import { logout } from "../pages/Settings/logout";

const headers = () => {
  const AuthToken = JSON.parse(localStorage.getItem('loginInfo'));
  if (AuthToken?.tenant_id) {
    return {
      Accept: "application/json",
      ContentType: "application/json",
      [' X-Tenant']: AuthToken?.tenant_id,
      Authorization: `Bearer ${AuthToken?.token ? AuthToken.token : ""}`,
    };
  }
  return {
    Accept: "application/json",
    ContentType: "application/json",
    Authorization: `Bearer ${AuthToken?.token ? AuthToken.token : ""}`,
  };
};

const handleAuthenticationError = (error) => {
  if (error.response && error.response.status === 401) {
    // Dispatch a logout action when a 401 unauthorized error is encountered    
    //toast.error("Session Expired Soon")
    /* setTimeout(() => {
      ['VesselInfo', 'loginInfo'].forEach(element => {
        localStorage.removeItem(element);
      });
      let path = (JSON.parse(localStorage.getItem('loginPath')))
      window.location.reload(path.pathName);
    }, 1000);
  }
  throw error; */
  logout()
  }
};

const get = (url, payload, token = "", cancelRequest) => {
  return axios({
    method: "get",
    headers: headers(),
    url: BASE_URL + `${url}`,
    params: payload,
    ...cancelRequest
  }).catch(handleAuthenticationError);
};

const getRequest = (url, payload, token = "", cancelRequest) => {
  return axios({
    method: "get",
    headers: headers(),
    url: BASE_URL + `${url}`,
    params: payload,
    ...cancelRequest
  }).catch((e) => {
    console.log(e)
  });
};

const post = (url, payload, op = "NONE", token = "") => {
  let mutablePayload = payload;
  if (op === "CREATE" || op === "UPDATE") {
    mutablePayload = { ...mutablePayload, ...commonPayloadFields, isdisabled: 'N' };
  }
  return axios({
    headers: headers(),
    method: "post",
    url: BASE_URL + `${url}`,
    data: mutablePayload,
  }).catch(handleAuthenticationError);
};

// Uncomment the put function if you need it

const put = (url, payload, token = "") => {
  return axios({
    method: "put",
    headers: headers(),
    url: BASE_URL + `${url}`,
    data: payload,
  }).then((response) => {
    return response;
  }).catch(handleAuthenticationError);
};


export const API_SERVICE = {
  post: post,
  get: get,
  getRequest: getRequest,
  put: put,
};
