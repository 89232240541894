// export const BASE_URL = process.env.REACT_APP_API_BASE_URL //env
// export const BASE_URL = `http://13.214.170.180:86/ERP/` //dev
// export const BASE_URL=`http://13.214.170.180:80/ERP/` //QA
// export const BASE_URL=`http://45.136.237.19:80/ERP/` //LIVE/
// export const BASE_URL = `http://45.136.237.19:100/ERP/` //gateway uat
// export const BASE_URL = `http://43.229.79.223:100/ERP/` //gateway io3
export const BASE_URL = `http://45.154.26.118:100/ERP/` //marmaid uat

// export const LOGIN_URL = `http://13.214.170.180:88/api/Auth/TokenAuth`
// export const LOGIN_URL = `http://45.136.237.19:1300/api/Auth/TokenAuth` // login UAT
// export const LOGIN_URL = `http://43.229.79.223:1300/api/Auth/TokenAuth` // login io3
export const LOGIN_URL = `http://45.154.26.118:1300/api/Auth/TokenAuth` // marmaid login UAT

export const MASTER = `Masters/`
export const PURCHASE = `Purchase/`
const INVOICE = `Invoice/`
const MATERIAL = `MaterialReceipt/`
export const REPORT = `Report/`
export const PMS = `PMS/`
const FINANCE = `Finance/`
const EDOC = `EDocument/`
const PMSReport = `PMSReport/`
export const SAFETY = `Safety/`
export const SAFETY_REPORTS = `SafetyReports/`
const INSPECTION = `Inspection/`
export const MEETING = `Meeting/`
const INCIDENT = `Incident/`
export const SETTINGS = `Setting/`
export const SASS_MASTER = `SAAS_Master/`
export const SAAS_LOGIN = `SAAS_Login/`
export const TYPE_BASED_ON_TYPE = `${MASTER}GetTypeBasedOnType?type=`
export const VESSEL_INSPECTION = `VesselInspection/`
export const SECRET_KEY = `AntonyPrabu24051988`
export const SAVE_USER = `${MASTER}AddEditERPUser`
export const GET_STORE_SEARCH = `${MASTER}GetStoreSearch`
export const VESSEL_TYPE = `${MASTER}GetVesselType`
export const SAVE_LOCATION = `${MASTER}AddEditLocation`
export const SAVE_DEPARTMENT = `${MASTER}AddEditDepartment`
export const SAVE_DESIGNATION = `${MASTER}AddEditDesignation`
export const SAVE_MODULE = `${MASTER}AddEditModule`
export const SAVE_MENU = `${MASTER}AddEditMenu`
export const SAVE_ROLE = `${MASTER}AddEditRole`
export const SAVE_ROLE_ACCESS = `${MASTER}AddEditRoleAccess`
export const SAVE_APPROVE = `${MASTER}AddEditApprove`
export const SAVE_PRODUCT = `${MASTER}AddEditProductType`
export const SAVE_APPROVE_LEVEL = `${MASTER}AddEditApproveLevel`
export const SAVE_APPROVE_LINK = `${MASTER}AddEditApproveLink`
export const SAVE_USER_ACCESS = `${MASTER}AddEditUserAccess`
export const SAVE_STATUS = `${MASTER}AddEditStatus`
export const SAVE_VESSEL = `${MASTER}AddEditVessel`
export const SAVE_PROJECT = `${MASTER}AddEditProject`
export const SAVE_GRADE = `${MASTER}AddEditGrade`
export const SAVE_LEVEL = `${MASTER}AddEditLevel`
export const SAVE_PRODUCT_SUPPLIER = `${MASTER}AddEditProductSupplier`
export const SAVE_SUPPLIER = `${MASTER}AddEditSupplier`
export const SAVE_OIL_PRODUCT = `${MASTER}AddEditOilProduct`
export const SAVE_PRODUCT_GENERAL = `${MASTER}AddEditProductGeneral`
export const SAVE_TYPE = `${MASTER}AddEditType`
export const SAVE_REQUISITION_ITEMS = `${PURCHASE}AddEditPurchaseRequisition`
export const SAVE_REQUISITION_APPROVAL = `${PURCHASE}AddEditApprovedProduct`
export const SAVE_STORE = `${MASTER}AddEditStore`
export const GET_USER = `${MASTER}GetERPUser`
export const GET_DEPARTMENT = `${MASTER}GetDepartment`
export const GET_DESIGNATION = `${MASTER}GetDesignation`
export const DESIGNATION_BASED_ON_SIDE = `${MASTER}GetDesignationBasedOnSide`
export const GET_MODULE = `${MASTER}getModule`
export const GET_UOM = `${MASTER}GetUOM`
export const SAVE_UOM = `${MASTER}AddEditUOM`
export const GET_MENU = `${MASTER}getMenu`
export const GET_ROLE = `${MASTER}getRole`
export const GET_ROLE_ACCESS = `${MASTER}getRoleAccess`
export const GET_APPROVE = `${MASTER}getApprove`
export const GET_PRODUCT_TYPE = `${MASTER}getProductType`
export const GET_APPROVE_LEVEL = `${MASTER}getApproveLevel`
export const GET_APPROVE_LINK = `${MASTER}getApproveLink`
export const GET_USER_ACCESS = `${MASTER}getUserAccess`
export const GET_STATUS = `${MASTER}getStatus`
export const PMS_COM_STATUS = `${MASTER}GetTypeBasedOnType?type=PMS_COM_STATUS`
export const PMS_COC_STATUS = `${MASTER}GetTypeBasedOnType?type=COC_STATUS`
export const AUDIT_TYPE_DROPDOWN = `${MASTER}GetTypeBasedOnType?type=AUDIT_TYPE`
export const INSPECTION_TYPE_DROPDOWN = `${MASTER}GetTypeBasedOnType?type=INSPECTION_TYPE`
export const GET_VESSEL = `${MASTER}getVessel` // vessel
export const GET_PROJECT = `${MASTER}getProject`
export const GET_GRADE = `${MASTER}getGrade`
export const GET_LEVEL = `${MASTER}getLevel`
export const GET_SUPPLIER = `${MASTER}getSupplier`
export const GET_OIL_PRODUCT = `${MASTER}GetOilProduct`
export const GET_PRODUCT_SUPPLIER = `${MASTER}getProductSupplier`
export const GET_TYPE = `${MASTER}GetType`
export const GET_PRCATEGORY = `${MASTER}GetTypeBasedOnType?type=PRCATEGORY`
export const GET_TYPE_PGINTYPE = `${MASTER}GetTypeBasedOnType?type=PGINTYPE`
export const GET_TYPE_FOLIO = `${MASTER}GetTypeBasedOnType?type=FOLIO`
export const GET_TYPE_REGION = `${MASTER}GetTypeBasedOnType?type=REGION`
export const GET_TYPE_PGCHARTTYPE = `${MASTER}GetTypeBasedOnType?type=PGCHARTTYPE`
export const GET_TYPE_PGBOOKTYPE = `${MASTER}GetTypeBasedOnType?type=PGBOOKTYPE`
export const GET_TYPE_INOILCAT = `${MASTER}GetTypeBasedOnType?type=INOILCAT`
export const GET_TYPE_INOILTYPE = `${MASTER}GetTypeBasedOnType?type=INOILTYPE`
export const PMS_RESCHEDULE_REASON = `${MASTER}GetTypeBasedOnType?type=PMS_RESCHEDULE_REASON`
export const GET_TYPE_UNIT = `DropDown/GetUOMDropDown`
export const GET_TYPE_BUDGET = `${MASTER}GetTypeBasedOnType?type=Budget`
export const GET_TYPE_STORECAT = `${MASTER}GetTypeBasedOnType?type=STORECAT`
export const GET_TYPE_STORETYPE = `${MASTER}GetTypeBasedOnType?type=STORETYPE`
export const GET_TYPE_PR_TYPE = `${MASTER}GetTypeBasedOnType?type=PRTYPE`
export const GET_TYPE_ROB_UNIT = `${MASTER}GetTypeBasedOnType?type=ROBUNIT`
export const GET_LOCATION = `${MASTER}GetLocation`
export const GET_PRODUCT_GENERAL = `${MASTER}GetProductGeneral`
export const GET_DASHBOARD_GOOD_RECEIVED = `${MASTER}GetDashboardforGoodrecived`
export const GET_DASHBOARD_STOCK = `${MASTER}GetDashboardforstock`
export const GET_DASHBOARD = `${MASTER}GetDashboard`
export const GET_DASHBOARD_CONSUMPTION = `${MASTER}GetDashboardConsumption`
export const GET_DASHBOARD_MEDICINE_EXPIRY = `${MASTER}GetDashBordMedicinesExpiry`
export const GET_DASHBOARD_GENERAL_REPORT = `${MASTER}GetDashBordGeneralReport`
export const GET_PRODUCT_GENERAL_BY_TYPE = `${PURCHASE}GetPurchaseReqItems`
export const GET_PURCHASE_PRODUCT = `${PURCHASE}GetProducts`
export const GET_PURCHASE_PRODUCT_TYPE = `${MASTER}GetProductType`
export const GET_PURCHASE_REQUISITION = `${PURCHASE}GetPurchaseRequisition`
export const GET_PURCHASE_REQUISITION_LIST = `${PURCHASE}GetPurchaseRequisitionList`
export const SAVE_REQ = `${PURCHASE}AddEditRFQ`
export const APPROVE_REQ = `${PURCHASE}AddEditApprovedProductRFQ`
export const GET_REQ = `${PURCHASE}GetRFQDetail`
export const GET_ALL_REQ = `${PURCHASE}GetRFQSummary`
export const GET_ALL_REQ_LIST = `${PURCHASE}GetRFQSummaryList`
export const GET_REQUISITION_DETAIL = `${PURCHASE}GetPurchaseReqDetail`
export const GET_DEPT_DROPDOWN = `DropDown/GetDeptDropDown`
export const GET_STORE = `${MASTER}GetStore`
export const GET_PURCHASE_QUO_DETAIL = `${PURCHASE}GetQuotationDetail`
export const GET_SUPPLIER_DROPDOWN = `DropDown/GetQuotationSuppliersDropDown`
export const SAVE_QUOTATION_DETAIL = `${PURCHASE}AddEditQuotation`
export const GET_QUOTATION_LIST = `${PURCHASE}GetQuotationSummary`
export const GET_COMPARE_QUOTATION_LIST = `${PURCHASE}GetCompareQuotationSummary`
export const GET_COMPARE_QUOTATION_BY_ID = `${PURCHASE}GetDataForQuotateComparision`
export const SAVE_COMPARE_QUOTATION = `${PURCHASE}AddEditcomparequotation`
export const GET_QCLIST = `${PURCHASE}GetQuotationSummary`
export const SAVE_PO = `${PURCHASE}AddEditPurchaseorder`
export const GET_QC_SUMMARY_LIST = `${PURCHASE}GetCompareQuotationSummary` // po
export const GET_QC_SUMMARY_ID = `${PURCHASE}GetCompareQuotation` // po
export const GET_DATA_PO_CREATION = `${PURCHASE}GetDataForPOCreation` // PO
export const GET_PURCHASE_ORDER = `${PURCHASE}GetPurchaseorderList` // po
export const APPROVE_QUOTATION_COMPARE = `${PURCHASE}AddEditApprovedCompareQuotation`
export const SAVE_PARTS = `${MASTER}AddEditParts` // PARTS
export const GET_PARTS = `${MASTER}GetParts` // PARTS
export const GET_EQUIP_DROPDOWN = `DropDown/GetequipmentDropDown` // equip dropdown
export const GET_PART_DETAILS_byID = `${MASTER}GetPartsByEquipmentID` // part detail by id
export const GET_PART_UNIT = `DropDown/GetUOMDropDown` // part screen UNIT dropdown
export const GET_PART_STATUS = `${MASTER}GetTypeBasedOnType?type=PARTSTATUS` // part screen STATUS dropdown
export const GET_PART_CRITICAL = `${MASTER}GetTypeBasedOnType?type=PARTCRITICAL` // part screen CRITICAL dropdown
export const PMS_CERTIFICATE_STATUS = `${MASTER}GetTypeBasedOnType?type=PMS_CERTIFICATE_STATUS` // part screen CRITICAL dropdown
export const GET_PART_LOCATION = `${MASTER}GetLocation` // part screen LOCATION dropdown
export const LOGIN_CHECK = `${MASTER}GetUserLoginCheck` // login check
export const GET_ACCOUNT_CODE = `${MASTER}GetAccountsCode`
export const GET_PR_BULK_ITEMS = `${PURCHASE}GetPurchaseReqItemsForBulk` // bulk PR
export const GET_INVENTORY_TREE = `${MASTER}GetInventoryTree`
export const PURCHASE_DASHBOARD = `${PURCHASE}GetDashboardInfo`
export const GET_EQUIPMENT = `${MASTER}GetEquipment`
export const SAVE_EQUIPMENT = `${MASTER}AddEditEquipment`
export const GET_PARTS_BY_EQUIPMENTID = `${MASTER}GetPartsByEquipmentID`
export const BULK_PO_APPROVE = `${PURCHASE}AddEditBulkPO`
export const GET_PO_DETAILS_BY_ID = `${PURCHASE}GetPODetail`
export const GET_EQUIP_byPARTSdetail = `${MASTER}GetEquipmentByPartsDetail`
export const SAVE_INVENTORY_TREE = `${MASTER}AddEditInventoryTree`
export const GET_QUOTATION_STATUS_CHECK = `${PURCHASE}GetDataForQuoteStatusCheck`
export const INVOICE_STATUS_CHECK = `${INVOICE}GetInvoiceFromSupplierStatusCheck`
export const CURRENCY_DROPDOWN = `DropDown/GetCurrencyDropDown`
export const SAVE_CONTRACT = `${PURCHASE}AddEditContractRate` // contract
export const LIST_CONTRACT = `${PURCHASE}GetContractRate` // contract
export const GET_CONTRACT_SUPPLIER = `${PURCHASE}GetSupplierForContractPO` // po supplier detail
export const GET_CONTACT_CATTYPE = `${MASTER}GetTypeOnCondition`
export const APPROVE_PO = `${PURCHASE}AddEditApprovedPO`
export const PO_ISSUED_LIST = `${PURCHASE}GetPurchaseOrderList`
export const SAVE_INVOICE = `${INVOICE}AddEditInvoicedetails`
export const GET_INVOICE = `${INVOICE}GetInvoiceList`
export const GET_INVOICE_BY_ID = `${INVOICE}GetInvoiceByid`
export const GET_INVOICE_LIST = `${INVOICE}GetInvoiceList`
export const UPDATE_INVOICE = `${INVOICE}UpdateInvoice`
export const SAVE_GOODS = `${MATERIAL}AddEditMaterialReceipt`
export const GOODS_LIST = `${MATERIAL}GetMaterialReceiptSummary`
export const GOODS_DETAIL = `${MATERIAL}GetMaterialReceipt`
export const GET_CONTRACT_PRODUCT = `${PURCHASE}GetInventoryForConRate`
export const SUPPLIER_RATING = `${MATERIAL}AddEditSupplierRating`
export const SUPPLIER_RATING_DETAIL = `${MATERIAL}GetSupplierRating`
export const GET_MR_VIEW = `${MATERIAL}GetMRForView`
export const GET_PR_VIEW = `${PURCHASE}GetPurchaseReqForView`
export const CONSUMPTION_STOCK = `${MASTER}GetTypeBasedOnType?type=STOCKTYPE`
export const MEETING_FREQUENCY = `${MASTER}GetTypeBasedOnType?type=MEETING_FREQUENCY`
export const AGENDA_TYPE = `${MASTER}GetTypeBasedOnType?type=AGENDA_TYPE`
export const ROB_TYPE = `${MASTER}GetTypeBasedOnType?type=PARTROBTYPE`
export const VESSELFLAG = `${MASTER}GetTypeBasedOnType?type=VESSELFLAG`
export const VESSELCAOACITY = `${MASTER}GetTypeBasedOnType?type=VESSELCAOACITY`
export const VESSELGROSSTONNAGE = `${MASTER}GetTypeBasedOnType?type=VESSELGROSSTONNAGE`
export const VESSELSHORTNAME = `${MASTER}GetTypeBasedOnType?type=VESSELSHORTNAME`
export const VESSELPORTOFREGISTRY = `${MASTER}GetTypeBasedOnType?type=VESSELPORTOFREGISTRY`
export const GET_TYPE_BASED = `${MASTER}GetTypeBasedOnType`
export const SAVE_CONSUMPTION_PART = `${MATERIAL}AddEditStock`
export const LIST_CONSUMPTION_PART = `${MATERIAL}GetStockByItemBased`
export const SAVE_ROLLBACK = `${PURCHASE}AddEditRollback`
export const GET_ROB = `${MATERIAL}GetStockBylocation`
export const GET_AMENDMENT_LIST = `${PURCHASE}GetAmendmentPOList`
export const GET_AMENDMENT_ID = `${PURCHASE}GetPODetail_History`
export const GET_PORT = `${MASTER}GetPort`
export const SAVE_PORT = `${MASTER}AddEditPort`
export const GET_COUNTRY_ID = `${MASTER}GetPortBycountry`
export const COUNTRY_DROPDOWN = `DropDown/GetcountryDropDown`
export const PR_REPORT_LIST = `${REPORT}RptPurchaseRequisition`
export const EQUIPMENT_REPORT = `${REPORT}Equipments`
export const PARTS_REPORT = `${REPORT}Parts`
export const OIL_PRODUCT_REPORT = `${REPORT}OilProducts`
export const EQUIPMENT_DETAILS_REPORT = `${REPORT}ReportEquipmentViewDetail`
export const PRODUCT_GENERAL_REPORT = `${REPORT}ProductGenerals`
export const STORE_REPORT = `${REPORT}Stores`
export const GOODS_REPORT_LIST = `${REPORT}RptGoodReceived`
export const INVOICE_REPORT_LIST = `${REPORT}RptInvoice`
export const EDOC_REPORT_HISTORY = `${EDOC}ReportHistory`
export const EDOC_REPORT_STATUS = `${EDOC}ReportStatus`
export const EDOC_REPORT_USER_LIST = `${EDOC}ReportUserList`
export const EDOC_REPORT_USER_LIST_BY_ID = `${EDOC}ReportUserListByID`
export const EDOC_REPORT_REQUEST = `${EDOC}ReportRequestList`
export const PO_REPORT_LIST = `${REPORT}RptPOcreation`
export const QC_REPORT_LIST = `${REPORT}RptCompareQuotation`
export const QUOT_REPORT_LIST = `${REPORT}RptQuotation`
export const PURCHASE_ITEMS_REPORT_LIST = `${REPORT}purchase-items-history`
export const PURCHASE_QUERY_REPORT_LIST = `${REPORT}purchase-query`
export const OUTSTANDING_PO_REPORT_LIST = `${REPORT}outstanding-po`
export const SUPPLIER_RATING_REPORT_LIST = `${REPORT}supplier-rating`
export const PURCHASE_QUERY_BY_ID = `${REPORT}purchase-query-by-id`
export const RFQ_REPORT_LIST = `${REPORT}RptRFQ`
export const PR_AUDIT_HISTORY = `${REPORT}RptAudithistorywise`
export const PMS_MAIN_TYPE = `${MASTER}GetTypeBasedOnType?type=MAINTYPE`
export const PMS_MAIN_HEAD = `${MASTER}GetTypeBasedOnType?type=MAINHEAD`
export const PMS_FREQ_TYPE = `${MASTER}GetTypeBasedOnType?type=FREQUENCYTYPE`
export const PMS_SURVEY_TYPE = `${MASTER}GetTypeBasedOnType?type=SURVEYTYPE`
export const CREATE_PMS_COMPONENT = `${PMS}AddEditMaintenace`
export const PMS_MAINTENANCE_SUMMARY = `${PMS}GetMaintenaceSummary`
export const GET_PMS_MAINTENANCE = `${PMS}GetMaintenace`
export const GET_STOCK_PMS = `${PMS}GetStockDetailForPMS`
export const PMS_SEARCH_TRANSFER = `${PMS}GetPMSSearch`
export const PMS_VESSEL_TRANSFER = `${PMS}GetVesselForPMSTransfer`
export const SAVE_PMS_TRANSFER = `${PMS}AddEditPMSTransfer`
export const CHECK_PMS_ALLOCATION = `${PMS}GetEquipmentCheckForPMSAlloc`
export const COMPLETION_PMS_HISTORY = `${PMS}GetPMSHistory`
export const MAINTENANCE_HOUR_SUMMARY = `${PMS}GetMaintenaceHoursSummary`
export const MAINTENANCE_HOUR_BYID = `${PMS}GetMaintenaceHours`
export const SAVE_MAINTENANCE_HOUR = `${PMS}AddEditMaintenaceHours`
export const GET_CURRENCY_RATE = `${FINANCE}Getcurrencyrate`
export const SAVE_CURRENCY_RATE = `${FINANCE}AddEditcurrencyrate`
export const GET_CURENCY_RPT = `${REPORT}Rptcurrencyratehistory`
//export const BASED_TYPE = `${MASTER}GetVesselBasedOnType`
export const BASED_TYPE = `${PMS}GetVesselForPMSAllocation`
export const SAFETY_STATUS_CONDITION = `${MASTER}GetTypeBasedOnType?type=STATUS_CONDITION`
export const STATUS_COLOR = `${MASTER}GetTypeBasedOnType?type=STATUS_COLOR`
export const GET_WO_COMPLETION = `${PURCHASE}GetWorkOrderCompletionByID`
export const PMS_LOCATION_COMPLETION = `${MASTER}GetTypeBasedOnType?type=PMSLOCATION`
export const DEPT_BASED_DESIGNATION = `${MASTER}GetDesignationBasedOnDeptartment`
export const GET_VESSEL_TYPE = `${MASTER}GetTypeBasedOnType?type=VESSELTYPE`
export const GET_DESIGNATION_SIDE = `${MASTER}GetTypeBasedOnType?type=DESIGNATIONSIDE`
export const GET_DESIGNATION_SHIP = `${MASTER}GetDesignationBasedOnSide?side=SHIP`
export const GET_DESIGNATION_BY_ID = `DropDown/GetDesignationDropdown`
export const PURCHASE_CONTRACT_BY_SUPPLIER = `${PURCHASE}GetPurchasecontractBysupplier`
export const SAVE_CERTIFICATE = `${PMS}AddEditPMSCertificate`
export const GET_CERTIFICATE = `${PMS}GetPMSCertificateSummary`
export const GET_CERTIFICATE_ID = `${PMS}GetPMSCertificate`
export const PMS_DASHBOARD_DETAIL = `${PMS}GetPMSDashBoard`
export const PMS_COMPLETEDBY = `${MASTER}GetTypeBasedOnType?type=PMS_COMPLETED_BY`
export const PMS_CERTIFICATE_TYPE = `${MASTER}GetTypeBasedOnType?type=PMS_CERTIFICATE_TYPE`
export const PMS_CERTIFICATE_GROUP = `${MASTER}GetTypeBasedOnType?type=PMS_CERTIFICATE_GROUP`
export const GET_EDOC_VERSION_TYPE = `${MASTER}GetTypeBasedOnType?type=EDOC_VERSIONTYPE`
export const GET_USER_BASED_DESIGNATION = `${MASTER}GetUsersBasedOnDesignation`
export const DIFFERENT_TYPE = `${MASTER}GetDifferentType`
export const GET_INVENTORY_BY_TYPE = `${EDOC}GetinventoryByType`;
export const GET_INVENTORY_BY_ID = `${EDOC}GetEdocument`;
export const GET_EdocumentByID = `${EDOC}GetEdocumentByID`;
export const SAVE_DOCUMENT = `${EDOC}AddEditEDocument`;
export const SEARCH_DOCUMENT = `${EDOC}GetEdocumentSearch`;
export const EDOC_RECENT_FOLDER = `${EDOC}GetRecentfolder`;
export const PMS_MAINTENANCE_REPORT = `${PMSReport}GetMaintenaceReport`
export const PMS_MAINTENANCE_REPORT_ID = `${PMSReport}GetMaintenaceReportViewData`
export const PMS_CERTIFICATE_REPORT = `${PMSReport}GetPMSCertificateReport`
export const PMS_CERTIFICATE_REPORT_ID = `${PMSReport}GetPMSCertificateReportViewData`
export const PMS_ANALYSIS = `${PMSReport}GetPMSAnalisysReport`
export const PMS_ANALYSIS_VESSEL = `${PMSReport}GetPMSAnalisysWaveChartReport`
export const WO_LIST = `${PURCHASE}GetWorkOrderCompletionData`
export const WO_SAVE = `${PURCHASE}AddEditWorkOrderCompletion`
export const GET_CHECKLIST = `${SAFETY}GetCheckList`
export const SAVE_CHECKLIST = `${SAFETY}AddEditCheckList`
export const GET_AUDIT = `${SAFETY}GetAudit`
export const GET_INSPECTION = `${INSPECTION}GetInspectionList`
export const SAVE_AUDIT_MASTER = `${SAFETY}AddEditAudit`
export const SAVE_INSPECTION_MASTER = `${INSPECTION}AddEditInspection`
export const SAVE_CATEGORY = `${SAFETY}AddEditCategory`
export const GET_CATEGORY = `${SAFETY}GetCategory`
export const GET_SUB_CATEGORY = `${SAFETY}GetSubCategory`
export const SAVE_SUB_CATEGORY = `${SAFETY}AddEditSubCategory`
export const GET_ISM = `${SAFETY}GetReference`
export const SAVE_ISM = `${SAFETY}AddEditReference`
export const GET_NC_TYPE = `${SAFETY}GetNCType`
export const SAVE_NC_TYPE = `${SAFETY}AddEditNCType`
export const GET_NC_CATEGORY = `${SAFETY}GetNCCategory`
export const SAVE_NC_CATEGORY = `${SAFETY}AddEditNCCategory`
export const GET_STATUS_CONDITION = `${SAFETY}GetStatusOfCondition`
export const SAFETY_SAVE_CONDITION = `${SAFETY}AddEditStatusOfCondition`
export const SAFETY_AUDIT_DROPDOWN = `${SAFETY}GetAuditDropDown`
export const SAVE_CHECKLIST_GROUP = `${SAFETY}AddEditCheckListGroup`
export const GET_CHECKLIST_GROUP = `${SAFETY}GetCheckListGroup`
export const CHECKLIST_AUTOSEARCH = `${SAFETY}GetCheckListGroupDropDown`
export const CHECKLIST_BASE_AUDIT = `${SAFETY}GetCheckListGroupBaseonAudit`
export const SAVE_CHECKLIST_TRANSACTION = `${SAFETY}AddEditCheckListTransaction`
export const GET_CHECKLIST_TRANSACTION = `${SAFETY}GetCheckListTransaction`
export const SAVE_AUDIT_SCHEDULE = `${SAFETY}AddEditAuditSchedule`
export const GET_AUDIT_SCHEDULE = `${SAFETY}GetAuditSchedule`
export const SAVE_AUDIT_COMPLETION = `${SAFETY}AddEditAuditCompletion`
export const GET_AUDIT_COMPLETION = `${SAFETY}GetAuditCompletion`
export const SAVE_NOTES_FOLLOWUP = `${SAFETY}AddEditNCOBSNotesFollowup`
export const GET_NOTES_FOLLOWUP = `${SAFETY}GetNCOBSNotesFollowup`
export const GET_DOC_CATEGORY = `${EDOC}GetCategory`
export const SAVE_DOC_CATEGORY = `${EDOC}AddEditcategory`
export const GET_CHANGE_REQUEST = `${EDOC}GetChangeRequestType`
export const SAVE_CHANGE_REQUEST = `${EDOC}AddEditChangeRequestType`
export const GET_EDOC_BY_ID = `${EDOC}GetInventoryTreeByid`
export const GET_AUDIT_VESSEL_SCHEDULE = `${SAFETY}GetAuditScheduleForMultipleVessel`
export const GET_DATA_BASED_CHECKLIST = `${SAFETY}GetDataBasedOnCheckList`
export const GET_AUDIT_RECORD_LIST = `${SAFETY}GetNCOBS`
export const SAVE_AUDIT_RECORD = `${SAFETY}AddEditNCOBSHeader`
export const GET_INITIATE_REQUEST = `${EDOC}GetInitiaterequest`
export const SAVE_INITIATE_REQUEST = `${EDOC}AddEditInitiaterequest`
export const GET_COMMENTS = `${EDOC}GetCommentsandFeedback`
export const SAVE_COMMENTS = `${EDOC}AddEditCommentsandFeedback`
export const GET_CHECKLIST_INSPECTION = `${INSPECTION}GetInspectionTransaction`
export const SAVE_CHECKLIST_INSPECTION = `${INSPECTION}AddEditInspectionTransaction`
export const SAVE_INSPECTION_SCHEDULE = `${INSPECTION}AddEditInspectionSchedule`
export const GET_INSPECTION_SCHEDULE = `${INSPECTION}GetInspectionSchedule`
export const SAVE_INSPECTION_COMPLETION = `${INSPECTION}AddEditInspectionCompletion`
export const GET_INSPECTION_COMPLETION = `${INSPECTION}GetInspectionCompletion`
export const SAFETY_INSPECTION_DROPDOWN = `${INSPECTION}GetInspectionDropDown`
export const GET_INSPECTION_VESSEL_SCHEDULE = `${INSPECTION}GetInspectionScheduleForMultipleVessel`
export const SAVE_REVIEW_REQUEST = `${EDOC}AddEditReviewrequest`
export const GET_REVIEW_DOC_NO = `${EDOC}GetReviewDocumentNo`
export const GET_REVIEW_REQUEST_ID = `${EDOC}GetReviewrequestByID`
export const GET_REVIEW_REQUEST = `${EDOC}GetReviewrequest`
export const GET_PART_REPORT = `${REPORT}ReportEquipmentPart`
export const ROB_VIEW_REPORT = `${REPORT}ReportPartROBViewDetail`
export const GET_STORE_REPORT = `${REPORT}ReportStores`
export const STORE_REPORT_VIEW = `${REPORT}ReportStoresViewDetail`
export const STORE_ROB_REPORT_VIEW = `${REPORT}ReportStoresROBViewDetail`
export const GET_OIL_REPORT = `${REPORT}ReportOilProduct`
export const OIL_REPORT_VIEW = `${REPORT}ReportOilProductViewDetail`
export const GET_EDOC_DASHBOARD = `${EDOC}Getdashboardcount`
export const EDOC_DASHBOARD_HISTORY = `${EDOC}Getdashboardhistory`
export const EDOC_TYPE_LIST = `${EDOC}GetDocumentTypeSearch`
export const MEETING_NAME_MASTER_LIST = `${MEETING}GetMeetingName`
export const DRILL_NAME_MASTER_LIST = `${MEETING}GetDrillName`
export const DRILL_SCHEDULE_MASTER_LIST = `${MEETING}GetDrill`
export const MEETING_AGENDA_MASTER_LIST = `${MEETING}GetAgenda`
export const MEETING_SCEHDULE_LIST = `${MEETING}GetScheduled`
export const SAVE_MEETING_NAME = `${MEETING}AddEditMeetingName`
export const SAVE_MEETING_RECORD = `${MEETING}AddEditMeetingRecord`
export const GET_MEETING_RECORD = `${MEETING}GetMeetingRecord`
export const GET_MEETING_DASHBOARD_RECORD = `${MEETING}GetScheduleDetails`
export const SAVE_DRILL_NAME = `${MEETING}AddEditDrillName`
export const GET_DRILL_RECORD = `${MEETING}GetDrillRecord`
export const SAVE_DRILL_RECORD = `${MEETING}AddEditDrillRecord`
export const GET_AGENDA_MEETING_BY_ID = `${MEETING}GetAgendaMeetingByID`
export const GET_DRILL_RECORD_BY_NAME = `${MEETING}GetDrillScheduleByID`
export const GET_MEETING_RECORD_BY_NAME = `${MEETING}GetMeetingScheduledByID`
export const SAVE_DRILL_SCHEDULE = `${MEETING}AddEditDrill`
export const SAVE_MEETING_SCHEDULE = `${MEETING}AddEditScheduled`
export const SAVE_MEETING_AGENDA = `${MEETING}AddEditAgenda`
export const GET_INCIDENT_MASTER = `${INCIDENT}GetIncidentMasters`
export const SAVE_INCIDENT_MASTER = `${INCIDENT}AddEditIncidentMasters`
export const GET_INCIDENT_DAMAGE = `${INCIDENT}GetIncidentDamage`
export const SAVE_INCIDENT_DAMAGE = `${INCIDENT}AddEditIncidentDamage`
export const GET_INCIDENT_INJURY = `${INCIDENT}GetIncidentInjury`
export const SAVE_INCIDENT_INJURY = `${INCIDENT}AddEditIncidentInjury`
export const GET_INCIDENT_MISS = `${INCIDENT}GetIncidentNearMiss`
export const SAVE_INCIDENT_MISS = `${INCIDENT}AddEditIncidentNearMiss`
export const INCIDENT_CATEGORY_TYPE = `${MASTER}GetTypeBasedOnType?type=INCIDENT_CATEGORY`
export const NATURE_OF_INJURY_CATEGOR = `${MASTER}GetTypeBasedOnType?type=NATURE_OF_INJURY`
export const INCIDENT_REPORTED_BY_CATEGOR = `${MASTER}GetTypeBasedOnType?type=INCIDENT_REPORTED_BY`
export const INCIDENT_DEFICIENCY_TYPE = `${MASTER}GetTypeBasedOnType?type=INCIDENT_DEFICIENCY_TYPE`
export const IDN_TYPE = `${MASTER}GetTypeBasedOnType?type=INCIDENT_IDN_CHECKLIST_TYPE`
export const incident_damage_machinery_breakdown = `${MASTER}GetTypeBasedOnType?type=incident_damage_machinery_breakdown`;
export const immediate_cause_of_damage_pg = `${MASTER}GetTypeBasedOnType?type=immediate_cause_of_damage_pg`;
export const immediate_cause_of_damage_pg_reason = `${MASTER}GetTypeBasedOnType?type=immediate_cause_of_damage_pg_reason`;
export const immediate_cause_of_damage_sec = `${MASTER}GetTypeBasedOnType?type=immediate_cause_of_damage_sec`;
export const immediate_cause_of_damage_sec_reason = `${MASTER}GetTypeBasedOnType?type=immediate_cause_of_damage_sec_reason`;
export const basic_cause_personal_factory_group = `${MASTER}GetTypeBasedOnType?type=basic_cause_personal_factory_group`;
export const basic_cause_personal_factory_group_reason = `${MASTER}GetTypeBasedOnType?type=basic_cause_personal_factory_group_reason`;
export const basic_cause_job_factory_group = `${MASTER}GetTypeBasedOnType?type=basic_cause_job_factory_group`;
export const basic_cause_job_factory_group_reason = `${MASTER}GetTypeBasedOnType?type=basic_cause_job_factory_group_reason`;
export const incident_details_of_item = `${MASTER}GetTypeBasedOnType?type=incident_details_of_item`;
export const incident_damage_programme = `${MASTER}GetTypeBasedOnType?type=incident_damage_programme`;
export const incident_damage_programme_reason = `${MASTER}GetTypeBasedOnType?type=incident_damage_programme_reason`;
export const incident_damage_performance_std = `${MASTER}GetTypeBasedOnType?type=incident_damage_performance_std`;
export const incident_damage_performance_std_reason = `${MASTER}GetTypeBasedOnType?type=incident_damage_performance_std_reason`;
export const incident_damage_compliance_exit_std = `${MASTER}GetTypeBasedOnType?type=incident_damage_compliance_exit_std`;
export const incident_damage_compliance_exit_std_reason = `${MASTER}GetTypeBasedOnType?type=incident_damage_compliance_exit_std_reason`;
export const incident_damage_Loss_Severity = `${MASTER}GetTypeBasedOnType?type=incident_damage_Loss_Severity`;
export const incident_damage_dpa_action = `${MASTER}GetTypeBasedOnType?type=incident_damage_dpa_action`;
export const incident_damage_closed_out = `${MASTER}GetTypeBasedOnType?type=incident_damage_closed_out`;
export const injury_port_depature = `${MASTER}GetTypeBasedOnType?type=injury_port_depature`
export const injury_port_destination = `${MASTER}GetTypeBasedOnType?type=injury_port_destination`
export const injury_manual_code_ref = `${MASTER}GetTypeBasedOnType?type=injury_manual_code_ref`
export const injury_probability_of_reoccurrence = `${MASTER}GetTypeBasedOnType?type=injury_probability_of_reoccurrence`

export const SAVE_VESSEL_TYPE = `${SETTINGS}AddEditVesselType`;
export const VESSEL_TYPE_LIST = `${SETTINGS}GetVesselType`;
export const GET_COMPANY_DETAILS = `${SETTINGS}GetCompanyDetail`;
export const SAVE_COMPANY_DETAILS = `${SETTINGS}AddEditCompanyDetail`;
export const SAVE_NOTIFICATION = `${SETTINGS}AddEditNotification`;
export const SAVE_USER_NOTIFICATION = `${SETTINGS}AddEditUserNotification`;
export const GET_NOTIFICATION = `${SETTINGS}GetNotification`;

export const nm_unsafe = `${MASTER}GetTypeBasedOnType?type=nm_unsafe`;
export const nm_immediate_cause_primary = `${MASTER}GetTypeBasedOnType?type=nm_immediate_cause_primary`;
export const nm_immediate_cause_primary_reason = `${MASTER}GetTypeBasedOnType?type=nm_immediate_cause_primary_reason`;
export const nm_immediate_cause_secondary = `${MASTER}GetTypeBasedOnType?type=nm_immediate_cause_secondary`;
export const nm_immediate_cause_secondary_reason = `${MASTER}GetTypeBasedOnType?type=nm_immediate_cause_secondary_reason`;
export const nm_basic_cause_personal_factor = `${MASTER}GetTypeBasedOnType?type=nm_basic_cause_personal_factor`;
export const nm_basic_cause_personal_factor_reason = `${MASTER}GetTypeBasedOnType?type=nm_basic_cause_personal_factor_reason`;
export const nm_basic_cause_job_factor = `${MASTER}GetTypeBasedOnType?type=nm_basic_cause_job_factor`;
export const nm_basic_cause_job_factor_reason = `${MASTER}GetTypeBasedOnType?type=nm_basic_cause_job_factor_reason`;
export const SAVE_VESSEL_INSPECTION = `${VESSEL_INSPECTION}AddEditGroup`
export const GET_VESSEL_INSPECTION = `${VESSEL_INSPECTION}GetGroup`
export const SAVE_VESSEL_INSPECTION_SUB = `${VESSEL_INSPECTION}AddEditSubGroup`
export const GET_VESSEL_INSPECTION_SUB = `${VESSEL_INSPECTION}GetSubGroup`
export const SAVE_VESSEL_INSPECTION_MASTER = `${VESSEL_INSPECTION}AddEditInspectionMaster`
export const GET_VESSEL_INSPECTION_MASTER = `${VESSEL_INSPECTION}GetInspectionMaster`
export const SAVE_VESSEL_INSPECTION_TRANS = `${VESSEL_INSPECTION}AddEditInspectionReport`
export const GET_VESSEL_INSPECTION_TRANS = `${VESSEL_INSPECTION}GetInspectionReport`
export const GET_VESSEL_INSPECTION_COUNT = `${VESSEL_INSPECTION}Getvesselcount`
export const GET_VESSEL_INSPECTION_CONDITION = `${VESSEL_INSPECTION}Getconditionstatus`
export const GET_VESSEL_INSPECTION_OPEN = `${VESSEL_INSPECTION}GetInspectionOpenItem`
export const GET_MODULE_ON_PARENT = `${MASTER}GetModuleBasedonParent`
export const SAFETY_NC_VIEW_REPORTS = `${SAFETY_REPORTS}GetNC_view_Reports`
export const SAFETY_AUDIT_INSPECTION_SCHEDULE_REPORTS = `${SAFETY_REPORTS}GetAudit_Inspection_Schedule_Reports`;
export const SAFETY_INCIDENT_REPORTS = `${SAFETY_REPORTS}GetIncdient_Reports`;
export const SAFETY_AUDIT_NC_REPORTS = `${SAFETY_REPORTS}GetNC_Reports`;

export const getAuditInspection = (view = '') => `${SAFETY_REPORTS}GetAudit_Inspection${view}_Reports`;
export const getAuditNCReports = (view = '') => `${SAFETY_REPORTS}GetNC${view}_Reports`;
export const getAuditInspectionScheduleReports = (view = '') => `${SAFETY_REPORTS}GetAudit_Inspection_Schedule${view}_Reports`;
export const getNCReports = (view = '') => `${SAFETY_REPORTS}GetNC${view}_Reports`;