import React from "react";
import { arrayToTree } from "performant-array-to-tree";
import { FaPen, FaTrash } from "react-icons/fa";
import { STRING_ONLY } from "../constant/Regex";
import {hasPermission} from "./permissions";
// import {BsFillRecordFill} from "react-icons/bs";

export const parseFlatArray2Tree = (data = [], screen, permissions = []) => {
    // Sort tree before building based on parent and ordering
    let res = []
    data = data.sort((a, b) => a.id - b.id);
    switch (screen?.name) {
        case 'EQUIPMENT':
            data = data.map(ele => ({
                key: ele.id,
                // icon: ele.parentid !== 0 ?  <BsFillRecordFill className={'tree-child-icon'} /> : null,
                title: <>
                    <span className={`${ele?.tree_name?.replace(STRING_ONLY, "")} ${ele.parentid === 0 ? 'edocParent': ''}`}>{ele.name}</span>
                    {
                        hasPermission('edit', permissions) && <span><FaPen onClick={() => screen.onEditEquipment(ele)} className="ms-1 text-primary me-1" style={{ fontSize: '10px' }} /></span>
                    }
                    {
                        hasPermission('delete', permissions) && <span><FaTrash onClick={() => screen.onDeleteEquipment(ele)} className="text-danger" style={{ fontSize: '10px' }} /></span>
                    }

                </>,
                searchLabel: ele.tree_name,
                parentid: ele.parentid !== 0 ? ele.parentid : null
            }))
            // Setting parent as Null when it is set to 0. Retain just the neccessary fields
            // There are no nested Ids, parentId case is different & we don't want the result to be containerized
            data = arrayToTree(data, { id: "key", parentId: "parentid", nestedIds: false, dataField: null });
            return data;
        case 'STORE':
            data = data.map((ele, i) => ({
                key: ele.id,
                value: ele,
                title: ele.name,
                searchLabel: ele.name,
                parentid: ele.parentid !== 0 ? ele.parentid : null
            }))
            // Setting parent as Null when it is set to 0. Retain just the neccessary fields
            // There are no nested Ids, parentId case is different & we don't want the result to be containerized
            data = arrayToTree(data, { id: "key", parentId: "parentid", nestedIds: false, dataField: null });
            return data;
        case 'CREATE_PMS':
            data = data.map(ele => ({
                key: ele.id,
                //title: ele.name,
                title: <span className={`${ele?.tree_name?.replace(STRING_ONLY, "")} ${ele.parentid === 0 ? 'pmsParent': ''}`}>{ele.name}</span>,
                searchLabel: ele.tree_name,
                parentid: ele.parentid !== 0 ? ele.parentid : null
            }))
            data = arrayToTree(data, { id: "key", parentId: "parentid", nestedIds: false, dataField: null });
            return data
        case 'EDOC':
            data = data.map(ele => ({
                key: ele.id,
                //title: ele.name,
                title: <span className={ele?.name?.replace(STRING_ONLY, "")}>{ele.name}</span>,
                searchLabel: ele.name,
                parentid: ele.parentid !== 0 ? ele.parentid : null
            }))
            data = arrayToTree(data, { id: "key", parentId: "parentid", nestedIds: false, dataField: null });
            return data
        case 'VESSEL_INSPECTION':
            res = data.map(ele => ({
                key: ele.id,
                //title: ele.name,
                title: <span className={ele?.name?.replace(STRING_ONLY, "")}>{ele.name}</span>,
                searchLabel: ele.name,
                parentid: ele.parentid !== 0 ? ele.parentid : null
            }))
            res = arrayToTree(res, { id: "key", parentId: "parentid", nestedIds: false, dataField: null });
            return res
        default:
            data = data.map(ele => ({
                key: ele.id,
                //title: ele.name,
                title: <span className={`${ele?.tree_name?.replace(STRING_ONLY, "")} ${ele.parentid === 0 ? 'pmsParent': ''}`}>{ele.name}</span>,
                searchLabel: ele.tree_name,
                parentid: ele.parentid !== 0 ? ele.parentid : null
            }))
            data = arrayToTree(data, { id: "key", parentId: "parentid", nestedIds: false, dataField: null });
            return data
    }
}

// Recursively find path to the node
export const getPath2Node = (id, nestedTree) => {
    for (const node of nestedTree) {
        if (node['key'] === id) {
            return [node];
        } else if (node['children'].length > 0) {
            const path = getPath2Node(id, node['children']);
            if (path) {
                return [node, ...path];
            }
        }
    }
}

export const sortIcon = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
<g clipPath="url(#clip0_523_2445)">
  <path d="M6.33757 3.83241L4.08152 1.74072M4.08152 1.74072L1.82547 3.83241M4.08152 1.74072V11.8618M8.21761 9.77009L10.4737 11.8618M10.4737 11.8618L12.7297 9.77009M10.4737 11.8618V1.74072" stroke="#B3B3B3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</g>
<defs>
  <clipPath id="clip0_523_2445">
    <rect width="14.5551" height="13.4947" fill="white" transform="translate(0 0.0539551)"/>
  </clipPath>
</defs>
</svg>