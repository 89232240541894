import { createAsyncThunk } from "@reduxjs/toolkit";
import * as ACTION from "../../constant/Actions"
import * as APIS from "../../constant/CommonApiUrl";
import { API_SERVICE } from "../../Services/CommonApi";
export const getPMSDashboardGeneralSummary = createAsyncThunk(ACTION.PMS_GENERAL_DASHBOARD,
    async (arg, thunkAPI) => {
        try {
            let vessel_id = thunkAPI.getState().root.common.vessel_detailByID.id
            let response = await API_SERVICE.get(`${APIS.PMS_MAINTENANCE_SUMMARY}?${arg}&vessel_id=${vessel_id}`)
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getPMSDashboardDefectSummary = createAsyncThunk(ACTION.PMS_DEFECT_DASHBOARD,
    async (arg, thunkAPI) => {
        try {
            let vessel_id = thunkAPI.getState().root.common.vessel_detailByID.id
            let response = await API_SERVICE.get(`${APIS.PMS_MAINTENANCE_SUMMARY}?${arg}&vessel_id=${vessel_id}`)
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getPMSDashboardDetail = createAsyncThunk(ACTION.PMS_DASHBOARD_DETAIL,
    async (arg, thunkAPI) => {
        try {
            let { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(arg ? `${APIS.PMS_DASHBOARD_DETAIL}?${arg}&vessel_id=${id}` : `${APIS.PMS_DASHBOARD_DETAIL}?vessel_id=${id}`)
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })