import React, { useEffect, useState } from 'react';
import { Col, FormCheck, FormSelect, Row } from "react-bootstrap";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import Tree from 'rc-tree';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer
} from "recharts";
import NormalPieChart from "./PieChart";
import { parseFlatArray2Tree } from '../../../helper/treeUtil';
import { GET_INVENTORY_TREE } from '../../../constant/CommonApiUrl';
import { API_SERVICE } from '../../../Services/CommonApi';
import { TbLayoutSidebarLeftExpand, TbLayoutSidebarRightExpand } from 'react-icons/tb';
import SimpleBar from 'simplebar-react';
import { useDispatch, useSelector } from 'react-redux';
import { getPMSanalysisReport } from '../../../features/THUNK/pmsAnalysisThunk';
import ReactDatePicker from 'react-datepicker';
import { getTreeNodeKeys, removeSearchParams } from '../../../helper/Support';
import { localtoISO } from '../../../helper/dateUtil';
import { CustomTooltip } from './CustomTooltip';
import { AnalyticsList, CountBox, TaskListContainer } from '../STYLE/PMSStyle'
am4core.useTheme(am4themes_animated);
let treeData_backup = []
function Analysis(props) {
    const dispatch = useDispatch()
    const [state, setState] = useState({ from_date: null, to_date: null })
    const [categories_menu, setCategories] = useState(true)
    const [treeList, setTreeList] = useState([]);
    const [gData, setGData] = useState([])
    const [expandCheck, setExpandCheck] = useState(false)
    const [defaultCheckedKeys, setDefaultCheckedKeys] = useState([])
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [cylinderDate, setcylinderDate] = useState(new Date());
    const [check, setCheck] = useState(false)
    const vessel_id = useSelector((state) => state.root.common.vessel_detailByID.id);
    const vessel_name = useSelector((state) => state.root.common.vessel_detailByID.vessel_name);
    const [vessel, setVessel] = useState("");
    const vesselList = useSelector((state) => state.root.login.loginDemo?.vesselDetails) || []
    const vessel_list = vesselList.map((x, i) => (
        <option
            key={x.id + "_" + i}
            value={x.id} 
        >
          {x.vessel_name}
        </option>
    ));
    // const vessel_list = useSelector((state) => state.root.vessel.list.map(el => <option key={el.id} value={el.id}>{el.vessel_name}</option>))
    const { completion_details, completion_summary,
        unscheduled_summary, maintenance_weekly_details,
        maintenance_weekly_summary, unscheduled_weekly_details,
        unscheduled_weekly_summary } = useSelector(state => state.root.ANALYSIS)
    useEffect(() => {
        if (vessel_name !== 'OFFICE') {
            setVessel(vessel_id)
            /* onSearch({ ...search, qtn_fromdate, qtn_todate, pofromdate, potodate, vesselid: id }) */
        } else {
            setVessel(0)
        }
    }, [vessel_name])
    useEffect(() => {
        let chart = am4core.create("chartdiv", am4charts.XYChart);
        // chart.titles.create().text = "Crude oil reserves";
        chart.paddingTop = 0;
        chart.paddingRight = 0;
        chart.paddingBottom = 0;
        chart.paddingLeft = -30;

        if (chart.logo) {
            chart.logo.disabled = true;
        }

        // Add data
        //chart.data = cylinderData;        
        chart.data = completion_details;

        // Create axes
        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "category";
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.grid.template.strokeOpacity = 0;
        categoryAxis.fontSize = "8"
        categoryAxis.dataFields.category = "category";

        categoryAxis.renderer.minGridDistance = 20


        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.grid.template.strokeOpacity = 0;
        valueAxis.min = -10;
        valueAxis.max = 110;
        valueAxis.strictMinMax = true;
        valueAxis.renderer.baseGrid.disabled = true;
        valueAxis.fontSize = "8"
        valueAxis.renderer.labels.template.adapter.add("text", function (text) {
            if ((text > 100) || (text < 0)) {
                return "";
            }
            else {
                /* return text + "%"; */
                return "";
            }
        })

        // Create series
        let series1 = chart.series.push(new am4charts.ConeSeries());
        series1.dataFields.valueY = "value1";
        series1.dataFields.categoryX = "category";
        series1.columns.template.width = am4core.percent(50);
        series1.columns.template.fillOpacity = 0.9;
        series1.columns.template.strokeWidth = 2;
        series1.columns.template.strokeOpacity = 0.7;

        let series2 = chart.series.push(new am4charts.ConeSeries());
        series2.dataFields.valueY = "value2";
        series2.dataFields.categoryX = "category";
        series2.stacked = true;
        series2.columns.template.width = am4core.percent(50);
        series2.columns.template.fillOpacity = 0.1;
        series2.columns.template.strokeOpacity = 0.2;
        series2.columns.template.strokeWidth = 2;

        let columnTemplate = series2.columns.template;
        columnTemplate.adapter.add("fill", function (fill, target) {
            return columnTemplate.fill = am4core.color(target.dataItem._dataContext.color)
        })

        columnTemplate.adapter.add("stroke", function (stroke, target) {
            // return chart.colors.getIndex(target.dataItem.index);
            return columnTemplate.stroke = am4core.color(target.dataItem._dataContext.lightColor)
        })
        let columnTemplate1 = series1.columns.template;
        columnTemplate1.adapter.add("fill", function (fill, target) {
            return columnTemplate1.fill = am4core.color(target.dataItem._dataContext.color)
        })

        columnTemplate1.adapter.add("stroke", function (stroke, target) {
            // return chart.colors.getIndex(target.dataItem.index);
            return columnTemplate.stroke = am4core.color(target.dataItem._dataContext.lightColor)
        })
        chart.maskBullets = false;
        chart.paddingTop = 25;
        const labelBullet = series1.bullets.push(new am4charts.LabelBullet());
        labelBullet.label.text = "{valueY}%";
        labelBullet.label.fontSize = "8";
        labelBullet.adapter.add("y", function (y) {
            return -5;
        });

    }, [completion_details, vessel]);
    useEffect(() => {
        const getInventoryTree = async () => {
            //setequipmentId("")
            let payload = new URLSearchParams({ vessel_id: vessel })
            let response = await API_SERVICE.get(`${GET_INVENTORY_TREE}?${payload}`);
            setTreeList(response.data.result.data);
            const inventoryTreeParsed = parseFlatArray2Tree(response.data.result.data);
            treeData_backup = inventoryTreeParsed
            setGData(inventoryTreeParsed);
            getChartData()
        }
        getInventoryTree();
    }, [/* vessel_id, */ vessel])
    useEffect(() => {
        getChartData({ equipment_id: defaultCheckedKeys })
    }, [cylinderDate, state])
    useEffect(() => {
        expandCheck ? setExpandedKeys(getTreeNodeKeys(gData)) : setExpandedKeys([])
    }, [expandCheck])

    const onExpand = (expandedKeys) => {
        setExpandedKeys(expandedKeys);
    };
    const onCheckedAll = (e) => {
        setCheck(e.target.checked)
        e.target.checked ? (
            setDefaultCheckedKeys(getTreeNodeKeys(gData)),
            getChartData({ equipment_id: getTreeNodeKeys(gData).toString() })
        ) : (
            setDefaultCheckedKeys([]),
            getChartData()
        )
    }
    const getChartData = (arg) => {
        dispatch(getPMSanalysisReport(removeSearchParams(new URLSearchParams({ ...arg, vessel_id: vessel_name === 'OFFICE' ? 0 : vessel, year: cylinderDate.getFullYear(), from_date: state.from_date ? localtoISO(state.from_date) : "", to_date: state.to_date ? localtoISO(state.to_date) : "" }))))
    }
    const handleDateRange = (val) => {
        const [from_date, to_date] = val
        setState((state) => ({ ...state, from_date, to_date }))
    }
    const handleShowCategories = () => {
        setCategories(!categories_menu)
    }

    /* const renderCustomizedLabel = (props) => {
        const { x, y, width, value } = props;
        const radius = 10;

        return (
            <g>
                <circle cx={x + width / 2} cy={y - radius} r={radius} fill="#8884d8" />
                <text
                    x={x + width / 2}
                    y={y - radius}
                    fill="#fff"
                    textAnchor="middle"
                    dominantBaseline="middle"
                >
                    {value.split(" ")[1]}
                </text>
            </g>
        );
    }; */

    return (
        <>
            <Row className='mb-3 align-items-center'>
                <Col md={4} className='d-flex flex-row gap-3'>
                    <span className='topFilterSec h5'>Analysis</span>
                    <FormSelect value={vessel} onChange={(e) => setVessel(e.target.value)} disabled={vessel_name !== 'OFFICE'}>
                        <option>select</option>
                        {vessel_list}
                    </FormSelect>
                </Col>
                <Col md={8} className='d-flex justify-content-end gap-2'>
                    {/*<Button disabled>Export as CSV</Button>*/}
                    {/*<Button disabled>Export as PDF</Button>*/}
                </Col>
            </Row>
            <Row>
                {categories_menu ? <Col sm={3}>
                    <div className='border p-2 bg-primary text-light d-flex flex-row justify-content-between align-items-center'>
                        <h6 className='mt-2'>Categories</h6>
                        <TbLayoutSidebarRightExpand className='fa-2x pe-auto' role='presentation' onClick={handleShowCategories} />
                    </div>
                    <SimpleBar style={{ minHeight: 906 }} autoHide={false} className='border p-1 whiteWrapper'>
                        <div className='d-flex flex-row justify-content-between'>
                            <FormCheck type='checkbox' label='Show all' checked={check} onChange={(e) => onCheckedAll(e)} />
                            <span className='text-primary pe-auto' role='presentation' onClick={() => setExpandCheck(!expandCheck)} >Expand</span>
                        </div>
                        <TaskListContainer>
                            <Tree
                                className="myCls"
                                selectable={false}
                                defaultCheckedKeys={defaultCheckedKeys}
                                defaultExpandedKeys={expandedKeys}
                                expandedKeys={expandedKeys}
                                checkedKeys={defaultCheckedKeys}
                                onExpand={onExpand}
                                showIcon={false}
                                checkable={true}
                                onCheck={(checkedKeys) => {
                                    setDefaultCheckedKeys(checkedKeys)
                                    getChartData({ equipment_id: checkedKeys.toString() })
                                    //setequipmentId(checkedKeys.toString())
                                }}
                                treeData={gData}
                            />
                        </TaskListContainer>
                    </SimpleBar>
                </Col> : <Col sm={1} style={{ minHeight: '600px', width: '3%', padding: '0px', borderRadius: '5px', backgroundColor: 'white' }}>
                    <TbLayoutSidebarLeftExpand style={{ borderRadius: '5px', fontSize: '34px', textAlign: 'center' }} className='fa-2x text-light bg-primary rounded-left pe-auto mb-3' role='presentation' onClick={handleShowCategories} />
                </Col>}

                <Col sm={categories_menu ? 9 : 11} style={!categories_menu ? { width: '96%' } : {}}>

                    <div className={'d-flex justify-content-between mb-2'}>

                        <Col sm={9} style={{ background: "#fff", marginRight: 5 }}>
                            <div className='d-flex justify-content-between mb-2  p-3'>
                                <span>Maintenance Analysis</span>
                                <span>
                                    <ReactDatePicker
                                        className='form-control'
                                        selected={cylinderDate}
                                        onChange={(date) => {
                                            setcylinderDate(date)
                                        }}
                                        showYearPicker
                                        dateFormat="yyyy"
                                    />
                                </span>
                            </div>
                            <div id="chartdiv" style={{ width: "100%", height: "300px", marginBottom: '25px' }}></div>
                        </Col>
                        <Col sm={3} style={{ padding: 5, background: '#ffffff' }}>
                            <div className="d-flex flex-column">
                                <div className={''} style={{ height: '250px' }}>
                                    <div className={'d-flex justify-content-between w-100'}>
                                        <h6 style={{ fontSize: '10px' }}>Maintenance Completion Analysis</h6>
                                        {/* <Form.Control type={'text'} style={{ width: '60px', height: '25px' }} /> */}
                                        <ReactDatePicker
                                            className='form-control'
                                            selectsRange={true}
                                            startDate={state.from_date}
                                            endDate={state.to_date}
                                            //maxDate={new Date()}
                                            onChange={(update) => {
                                                console.log(update, 'update')
                                                handleDateRange(update);
                                            }}
                                            withPortal
                                            isClearable={true}
                                            placeholderText='From/To Date'
                                        />
                                    </div>
                                    <div className={'d-flex flex-column w-100'}>
                                        <div className={'w-100'}>
                                            <NormalPieChart totalJobs={completion_summary?.total_jobs} pendingJobs={completion_summary?.pending_jobs} completedJobs={completion_summary?.completed_jobs} />
                                        </div>
                                        <div className={'w-100 align-items-center'} style={{ minHeight: '100vh' }}>
                                            <AnalyticsList>
                                                <li>
                                                    <div className={'box'}><span className={'text'}>Total No. of Jobs:   <b>{completion_summary?.total_jobs}</b></span></div>
                                                </li>
                                                <li>
                                                    <div className={'box'}><div className={'icons chart-blue'}></div><span className={'text'}>Total No. of Jobs Completed:   <b>{completion_summary?.completed_jobs}</b></span></div>
                                                </li>
                                                <li>
                                                    <div className={'box'}><div className={'icons chart-light-blue'}></div><span className={'text'}>Total No. of Jobs Pending:   <b>{completion_summary?.pending_jobs}</b></span></div>
                                                </li>
                                                {/*  <li>
                                                    <div className={'box'}><div className={'icons chart-dark'}></div><span className={'text'}>Maintenance Completed:   <b>{`${completion_summary?.completed_percentage}%`}</b></span></div>
                                                </li> */}
                                            </AnalyticsList>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </Col>
                    </div>
                    <div className={'d-flex justify-content-between'}>
                        <Col sm={9} style={{ marginRight: 5 }}>
                            <div className="d-flex flex-column">
                                <div style={{ padding: 5, background: '#ffffff' }} className={'mb-2'}>
                                    <div className="bg-light d-flex justify-content-between mb-3">
                                        <div style={{ fontSize: '10px' }}>Maintenance Weekly Analysis</div>
                                        <div>{maintenance_weekly_summary.date_range}</div>
                                    </div>
                                    <div className='d-flex flex-row'>
                                        <ResponsiveContainer height={200} width={categories_menu ? "85%" : "100%"}>
                                            <BarChart
                                                data={maintenance_weekly_details}
                                                margin={{
                                                    top: 5,
                                                    right: 30,
                                                    left: 20,
                                                    bottom: 5
                                                }}
                                            >
                                                <defs>
                                                    <linearGradient
                                                        id={'colorUv'}
                                                        x1="1"
                                                        y1="1"
                                                        x2="0"
                                                        y2="0"
                                                    >
                                                        <stop offset="30%" stopColor={'#1D1B89'} />
                                                        <stop offset="95%" stopColor={'#8280FF'} />
                                                    </linearGradient>
                                                    <linearGradient
                                                        id={'second'}
                                                        x1="1"
                                                        y1="1"
                                                        x2="0"
                                                        y2="0"
                                                    >
                                                        <stop offset="30%" stopColor={'#A3071C'} />
                                                        <stop offset="95%" stopColor={'#FF0000'} />
                                                    </linearGradient>
                                                </defs>
                                                <CartesianGrid vertical={false} horizontal={false} strokeDasharray="3 3" />
                                                <XAxis dataKey="name" axisLine={false} interval={0} tick={{ fontSize: 8 }} />
                                                <YAxis axisLine={false} hide />
                                                <Tooltip content={<CustomTooltip />} />
                                                {/* <Legend /> */}
                                                <Bar dataKey="pending" fill="url(#colorUv)" barSize={8} /*  minPointSize={5}  */ radius={[10, 10, 0, 0]}>
                                                    {/* <LabelList dataKey="name" content={renderCustomizedLabel} /> */}
                                                </Bar>
                                                <Bar dataKey="completed" fill="url(#second)" barSize={8}  /* minPointSize={10} */ radius={[10, 10, 0, 0]} />
                                                {/* <Bar dataKey="bala" fill="url(#second)" barSize={20}  minPointSize={10} radius={[10, 10, 0, 0]} /> */}
                                            </BarChart>
                                        </ResponsiveContainer>
                                        <div className='d-flex flex-column'>
                                            <CountBox>
                                                <h6>Total No.of Job</h6>
                                                <span className='display-6 text-left'>{maintenance_weekly_summary.total ? maintenance_weekly_summary.total : 0}</span>
                                            </CountBox>
                                            <CountBox >
                                                <h6>Total No.of Job Completed</h6>
                                                <span className='display-6'>{maintenance_weekly_summary.completed ? maintenance_weekly_summary.completed : 0}</span>
                                            </CountBox>
                                            <CountBox>
                                                <h6>Total No.of Job Pending</h6>
                                                <span className='display-6'>{maintenance_weekly_summary.pending ? maintenance_weekly_summary.pending : 0}</span>
                                            </CountBox>
                                        </div>
                                    </div>


                                </div>
                                <div style={{ padding: 5, background: '#ffffff' }}>
                                    <div className="bg-light d-flex justify-content-between mb-3">
                                        <div style={{ fontSize: '10px' }}>Re-Scheduled maintenance</div>
                                        <div>{unscheduled_weekly_summary.date_range}</div>
                                    </div>
                                    <div className='d-flex flex-row'>
                                        <ResponsiveContainer height={200} width={categories_menu ? "85%" : "100%"}>
                                            <BarChart
                                                data={unscheduled_weekly_details}
                                                margin={{
                                                    top: 5,
                                                    right: 30,
                                                    left: 20,
                                                    bottom: 5
                                                }}
                                            >
                                                <defs>
                                                    <linearGradient
                                                        id={'colorUv'}
                                                        x1="1"
                                                        y1="1"
                                                        x2="0"
                                                        y2="0"
                                                    >
                                                        <stop offset="30%" stopColor={'#1D1B89'} />
                                                        <stop offset="95%" stopColor={'#8280FF'} />
                                                    </linearGradient>
                                                    <linearGradient
                                                        id={'second'}
                                                        x1="1"
                                                        y1="1"
                                                        x2="0"
                                                        y2="0"
                                                    >
                                                        <stop offset="30%" stopColor={'#A3071C'} />
                                                        <stop offset="95%" stopColor={'#FF0000'} />
                                                    </linearGradient>
                                                </defs>
                                                <CartesianGrid vertical={false} horizontal={false} strokeDasharray="3 3" />
                                                <XAxis dataKey="name" axisLine={false} interval={0} tick={{ fontSize: 8 }} />
                                                <YAxis axisLine={false} hide />
                                                <Tooltip content={<CustomTooltip />} />
                                                {/* <Legend /> */}
                                                <Bar dataKey="pending" fill="url(#colorUv)" barSize={8} /*  minPointSize={5}  */ radius={[10, 10, 0, 0]}>
                                                    {/* <LabelList dataKey="name" content={renderCustomizedLabel} /> */}
                                                </Bar>
                                                <Bar dataKey="completed" fill="url(#second)" barSize={8}  /* minPointSize={10} */ radius={[10, 10, 0, 0]} />
                                                {/* <Bar dataKey="bala" fill="url(#second)" barSize={20}  minPointSize={10} radius={[10, 10, 0, 0]} /> */}
                                            </BarChart>
                                        </ResponsiveContainer>
                                        <div>
                                            <CountBox>
                                                <h6>Total No.of Rescheduled Job</h6>
                                                <span className='display-6'>{unscheduled_weekly_summary?.total ? unscheduled_weekly_summary.total : 0}</span>
                                            </CountBox>
                                            <CountBox>
                                                <h6>Total No.of Rescheduled Job Done</h6>
                                                <span className='display-6'>{unscheduled_weekly_summary.completed ? unscheduled_weekly_summary.completed : 0}</span>
                                            </CountBox>
                                            <CountBox>
                                                <h6>Total No.of Job Pending</h6>
                                                <span className='display-6'>{unscheduled_weekly_summary.pending ? unscheduled_weekly_summary.pending : 0}</span>
                                            </CountBox>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col sm={3} style={{ padding: 5, background: '#ffffff' }}>
                            <div className={''} style={{ height: '350px' }}>
                                <div className={'d-flex justify-content-between w-100'}>
                                    <h6 style={{ fontSize: '10px' }}>Un-scheduled Maintenance</h6>
                                    {/* <Form.Control type={'text'} style={{ width: '60px', height: '25px' }} /> */}
                                    <ReactDatePicker
                                        className='form-control'
                                        selectsRange={true}
                                        startDate={state.from_date}
                                        endDate={state.to_date}
                                        //maxDate={new Date()}
                                        onChange={(update) => {
                                            handleDateRange(update);
                                        }}
                                        withPortal
                                        isClearable={true}
                                        placeholderText='From/To Date'
                                    />
                                </div>
                                <div className={'d-flex flex-column w-100'}>
                                    <div className={'w-100'}>
                                        <NormalPieChart totalJobs={unscheduled_summary?.totaljobs} completedJobs={unscheduled_summary?.completed_jobs} pendingJobs={unscheduled_summary?.pending_jobs} />
                                    </div>
                                    <div className={'w-100 align-items-center'} style={{ minHeight: '100vh' }}>
                                        <AnalyticsList>
                                            <li>
                                                <span className={'text'}>Total No.of.Jobs:   <b>{`${unscheduled_summary?.totaljobs}`}</b></span>
                                            </li>
                                            <li>
                                                <div className={'box'}><div className={'icons chart-light-blue'}></div><span className={'text'}>Sea:   <b>{unscheduled_summary?.completed_jobs}</b></span></div>
                                            </li>
                                            <li>
                                                <div className={'box'}><div className={'icons chart-blue'}></div><span className={'text'}>Port/Shore:   <b>{unscheduled_summary?.pending_jobs}</b></span></div>
                                            </li>
                                        </AnalyticsList>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </div>

                </Col>

            </Row>
        </>
    );
}

export default Analysis;