import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as ACTION from '../constant/Actions'
import { API_SERVICE } from '../Services/CommonApi'
import * as APIS from '../constant/CommonApiUrl';
import { toast } from "react-toastify";
import { dateTimeToDate } from "../helper/dateUtil";
import { ROB_TYPE } from "../constant/CommonApiUrl";

const DROPDOWN_FIELD_URL = {
    UNIT: APIS.GET_PART_UNIT,
    CRITICAL: APIS.GET_PART_CRITICAL,
    STATUS: APIS.GET_PART_STATUS,
    LOCATION: APIS.GET_PART_LOCATION,
    EQUIPMENT: APIS.GET_EQUIP_DROPDOWN,
    CONSUMPTION_TYPE: APIS.CONSUMPTION_STOCK,
    ROB_TYPE: APIS.ROB_TYPE
}
const initialState = {
    localPartsDetail: [],
    equipmentDropdown: [],
    consumption_part_head: {},
    consumption_part_list: [],
    fields: Object.assign({}, ...Object.keys(DROPDOWN_FIELD_URL).map(el => ({ [el]: [] })))
}
export const fetchPartsDropdown = createAsyncThunk(ACTION.FETCH_PART_DROPDOWN_ACTION,
    async (arg, thunkAPI) => {
        try {
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let keyField = Object.entries(DROPDOWN_FIELD_URL)
            let URLs = keyField.map(([key, val]) => key === 'LOCATION' ? `${val}?vessel_id=${id}` : val)
            let response = await Promise.all(URLs.map(url => API_SERVICE.get(url)))
            response = response.map((res, index) => ({ [keyField[index][0]]: res.data.result.data }))
            return Object.assign({}, ...response)
        } catch (err) {
            return thunkAPI.rejectWithValue(err.message)
        }

    })
export const savePartAPI = createAsyncThunk(ACTION.SAVE_PARTS_ACTION,
    async (arg, thunkAPI) => {
        try {
            let response = null
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            switch (arg.action) {
                case 'SAVE':
                    response = await API_SERVICE.post(APIS.SAVE_PARTS, arg.data)
                    thunkAPI.dispatch(getPartsbyIDAPI(`vessel_id=${id}&equipmentId=${arg.data.equipmentid}`))
                    return response
                default:
                    return null
            }

        } catch (err) {
            return thunkAPI.rejectWithValue(err)
        }
    })
export const saveConsumptionPart = createAsyncThunk(ACTION.SAVE_CONSUMPTION_PART,
    async (arg, thunkAPI) => {
        try {
            let response = await API_SERVICE.post(APIS.SAVE_CONSUMPTION_PART, arg)
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })
export const getPartListAPI = createAsyncThunk(ACTION.GET_PARTS_ACTION,
    async (arg, thunkAPI) => {
        try {
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            return await API_SERVICE.get(arg ? `${APIS.GET_PARTS}?${arg}&vessel_id=${id}` : `${APIS.GET_PARTS}?vessel_id=${id}`)
        } catch (err) {
            return thunkAPI.rejectWithValue(err)
        }
    })
export const getConsumptionPartList = createAsyncThunk(ACTION.LIST_CONSUMPTION_PART,
    async (arg, thunkAPI) => {
        try {
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            return await API_SERVICE.get(`${APIS.LIST_CONSUMPTION_PART}?${arg}&vessel_id=${id}`)
        } catch (err) {
            return thunkAPI.rejectWithValue(err)
        }
    })
export const getEquipmentDropdown = createAsyncThunk(ACTION.GET_EQUIPMENT_LIST,
    async (arg, thunkAPI) => {
        try {
            return await API_SERVICE.get(`${APIS.GET_EQUIP_DROPDOWN}?vessel_id=${arg}`)
        } catch (err) {
            return thunkAPI.rejectWithValue(err)
        }
    })
export const getPartsbyIDAPI = createAsyncThunk(ACTION.GET_PART_DETAILS_byID_ACTION,
    async (arg, thunkAPI) => {
        try {
            return await API_SERVICE.get(`${APIS.GET_PART_DETAILS_byID}?${arg}`)
        } catch (err) {
            return thunkAPI.rejectWithValue(err)
        }

    })
export const getConsumptionPartHead = createAsyncThunk(ACTION.CONSUMPTION_PART_HEAD,
    async (arg, thunkAPI) => {
        try {
            return await API_SERVICE.get(`${APIS.GET_PARTS}?${arg}`)
        } catch (err) {
            return thunkAPI.rejectWithValue(err)
        }

    })
export const partsSlice = createSlice({
    name: 'PARTS',
    initialState,
    reducers: {
        addPartsLocal: (state, action) => {
            state.localPartsDetail = [...state.localPartsDetail, action.payload]
        },
        deletePartsLocal: (state, action) => {
            state.localPartsDetail = state.localPartsDetail.filter(x => x.drawingno !== action.payload)
        },
        editPartsLocal: (state, action) => {
            state.localPartsDetail = state.localPartsDetail.map(x => x.drawingno === action.payload.drawingno ? action.payload : x)
        },
        clearPartDetail: (state, action) => {
            state.localPartsDetail = []
        }
    },
    extraReducers: (builder) => {
        (builder)
            .addCase(fetchPartsDropdown.fulfilled, (state, action) => {
                state.fields = action.payload
            }).addCase(getEquipmentDropdown.fulfilled, (state, action) => {
                if (action.payload.data.result.status)
                    state.equipmentDropdown = action.payload.data.result.data
            })
            .addCase(savePartAPI.fulfilled, (state, action) => {
                if (action.payload.data.result.data[0].status === 'success') {
                    toast.success(action.payload.data.result.message)
                } else {
                    toast.error(action.payload.data.result.data[0].status)
                }
            })
            .addCase(saveConsumptionPart.fulfilled, (state, action) => {
                if (action.payload.data.result.status) {
                    toast.success(action.payload.data.result.message)
                } else {
                    toast.error(action.payload.data.result.message)
                }
            })
            .addCase(getConsumptionPartHead.fulfilled, (state, action) => {
                if (action.payload.data.result.status) {
                    state.consumption_part_head = action.payload.data.result.data[0]
                } else {
                    toast.error(action.payload.data.result.message)
                }
            })
            .addCase(getConsumptionPartList.fulfilled, (state, action) => {
                if (action.payload.data.result.status) {
                    state.consumption_part_list = action.payload.data.result.data
                } else {
                    toast.error(action.payload.data.result.message)
                }
            })
            .addCase(getPartListAPI.fulfilled, (state, action) => {
                if (action.payload.data.result.status) {
                    state.localPartsDetail = action.payload.data.result.data.map(el => (
                        { ...el, createddt: dateTimeToDate(el.createddt, "y/m/d"), rcddate: dateTimeToDate(el.rcddate, "y/m/d") }))
                } else {
                    toast.success(action.payload.data.result.message)
                }
            })
            .addCase(getPartsbyIDAPI.fulfilled, (state, action) => {
                if (action.payload.data.result.status) {
                    action.payload.data.result.data.length > 0 ?
                        state.localPartsDetail = action.payload.data.result.data.map(el => (
                            { ...el, createddt: dateTimeToDate(el.createddt, "y/m/d"), rcddate: dateTimeToDate(el.rcddate, "y/m/d") }))
                        : (toast.warning(action.payload.data.result.message),
                            state.localPartsDetail = [])
                } else {
                    toast.success(action.payload.data.result.message)
                }
            })
            .addCase(fetchPartsDropdown.rejected, (state, action) => {
                toast.error(action.payload)
            })
            .addCase(savePartAPI.rejected, (state, action) => {
                if (action.payload.response.data.status === 400) {
                    toast.error(action.payload.response.data.title)
                }
            })
            .addCase(getPartListAPI.rejected, (state, action) => {
                if (action.payload.response.data.status === 400) {
                    toast.error(action.payload.response.data.title)
                }
            })
            .addCase(getPartsbyIDAPI.rejected, (state, action) => {
                if (action.payload.response.data.status === 400) {
                    toast.error(action.payload.response.data.title)
                }
            })
            .addCase(getConsumptionPartHead.rejected, (state, action) => {
                console.log(action.payload);
            })
            .addCase(saveConsumptionPart.rejected, (state, action) => {
                if (action.payload.response.data.status === 400) {
                    toast.error(action.payload.response.data.title)
                }
            })
            .addCase(getConsumptionPartList.rejected, (state, action) => {
                if (action.payload.response.status === 400) {
                    toast.error(action.payload.message)
                }
            })
    }
})
export const { addPartsLocal, deletePartsLocal, editPartsLocal, clearPartDetail } = partsSlice.actions
export default partsSlice.reducer