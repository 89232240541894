import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { API_SERVICE } from "../Services/CommonApi";
import * as APIS from '../constant/CommonApiUrl';
import * as ACTIONS from '../constant/Actions';
import { toast } from "react-toastify";
import { dateTimeToDate } from "../helper/dateUtil";
import { ConvertJson } from "../helper";

export const getAllPOList = createAsyncThunk(ACTIONS.PO_ALL_LIST_ACTION,
  async (arg, thunkAPI) => {
    try {
      const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
      return await API_SERVICE.get(arg ? `${APIS.GET_PURCHASE_ORDER}?${arg}&vessel_id=${vessel_name === 'OFFICE' ? 0 : id}` : `${APIS.GET_PURCHASE_ORDER}?vessel_id=${vessel_name === 'OFFICE' ? 0 : id}`);
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  }
)
export const getStatusPOList = createAsyncThunk(ACTIONS.PO_STATUS_LIST_ACTION,
  async (arg, thunkAPI) => {
    try {
      const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
      return await API_SERVICE.get(`${APIS.GET_PURCHASE_ORDER}?status=${arg}&vessel_id=${vessel_name === 'OFFICE' ? 0 : id}`);
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  }
)
export const getAllQCSummary = createAsyncThunk(ACTIONS.GET_QC_SUMMARY_LIST_ACTION,
  async (arg, thunkAPI) => {
    try {
      const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
      return await API_SERVICE.get(`${APIS.GET_QC_SUMMARY_LIST}?status=${arg}&vessel_id=${vessel_name === 'OFFICE' ? 0 : id}`);
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  }
)
export const getQCSummaryByID = createAsyncThunk(ACTIONS.GET_QC_SUMMARY_ID_ACTION,
  async (arg, thunkAPI) => {
    try {
      return await API_SERVICE.get(`${APIS.GET_QC_SUMMARY_ID}?ID=${arg}`);
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  }
)
export const getPoDetails = createAsyncThunk(ACTIONS.GET_PO_DETAILS_ACTION,
  async (arg, thunkAPI) => {
    try {
      thunkAPI.dispatch(clearOrderDetails())
      return await API_SERVICE.get(`${APIS.GET_PO_DETAILS_BY_ID}?id=${arg}`);
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  }
)
export const getPOcreationData = createAsyncThunk(ACTIONS.GET_PO_CREATION_DATA_ACTION,
  async (arg, thunkAPI) => {
    try {
      const { is_contract, category } = thunkAPI.getState().root.PO.contract_detail
      const cat = arg?.category || category
      arg?.is_contract || is_contract ? thunkAPI.dispatch(getContractPoSupplier(cat)) : null
      return await API_SERVICE.get(`${APIS.GET_DATA_PO_CREATION}?id=${arg.id}&${new URLSearchParams({ is_contract: arg.is_contract ? arg.is_contract : 0 })}`);
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  }
)
export const getContractPoSupplier = createAsyncThunk(ACTIONS.PO_CONTRACT_SUPPLIER,
  async (arg, thunkAPI) => {
    try {
      const { id } = thunkAPI.getState().root.common.vessel_detailByID
      return await API_SERVICE.get(`${APIS.GET_CONTRACT_SUPPLIER}?${new URLSearchParams({ category: arg })}&vessel_id=${id}`)
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  })
export const savePO = createAsyncThunk(ACTIONS.SAVE_PO_ACTION,
  async (arg, thunkAPI) => {
    try {
      return await API_SERVICE.post(APIS.SAVE_PO, arg);
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  }
)
export const approveBulkPO = createAsyncThunk(ACTIONS.BULK_PO_APPROVE_ACTION,
  async (arg, thunkAPI) => {
    try {
      const { id } = thunkAPI.getState().root.login.loginDemo
      const response = await API_SERVICE.post(`${APIS.BULK_PO_APPROVE}?status=${arg.status}&userid=${id}`, arg.data);
      thunkAPI.dispatch(getAllPOList())
      return response
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  }
)
export const approvePO = createAsyncThunk(ACTIONS.APPROVE_PO,
  async (arg, thunkAPI) => {
    try {
      //const {id}=thunkAPI.getState().root.login.loginDemo
      const response = await API_SERVICE.post(`${APIS.APPROVE_PO}`, arg);
      thunkAPI.dispatch(getAllPOList())
      return response
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  }
)
export const getIssuedList = createAsyncThunk(ACTIONS.PO_ISSUED_LIST,
  async (arg, thunkAPI) => {
    try {
      const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
      return await API_SERVICE.get(`${APIS.PO_ISSUED_LIST}?status=${arg}&vessel_id=${vessel_name === 'OFFICE' ? 0 : id}`)
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  }
)
export const getGoodsDetailbyId = createAsyncThunk(ACTIONS.GOODS_DETAIL,
  async (arg, thunkApi) => {
    try {
      const { id, vessel_name } = thunkApi.getState().root.common.vessel_detailByID
      return await API_SERVICE.get(`${APIS.GOODS_DETAIL}?id=${arg}&vessel_id=${vessel_name === 'OFFICE' ? 0 : id}`)
    } catch (err) {
      thunkApi.rejectWithValue(err)
    }
  })
export const getAmendmetList = createAsyncThunk(ACTIONS.GET_AMENDMENT_LIST,
  async (arg, thunkApi) => {
    try {
      const { id, vessel_name } = thunkApi.getState().root.common.vessel_detailByID
      return await API_SERVICE.get(`${APIS.GET_AMENDMENT_LIST}?vessel_id=${vessel_name === 'OFFICE' ? 0 : id}`)
    } catch (err) {
      thunkApi.rejectWithValue(err)
    }
  })
export const getAmendmentByID = createAsyncThunk(ACTIONS.GET_AMENDMENT_ID,
  async (arg, thunkApi) => {
    try {
      const { id, vessel_name } = thunkApi.getState().root.common.vessel_detailByID
      return await API_SERVICE.get(`${APIS.GET_AMENDMENT_ID}?id=${arg}`)
    } catch (err) {
      thunkApi.rejectWithValue(err)
    }
  })

const initialState = {
  POList: [],
  qcSummaryList: [],
  qcSummaryList_backup: [],
  is_sent: 0,
  qcDetailbyId: {},
  isSaved: false,
  contract_detail: {},
  orderDetails: {},
  PODetail: [],
  issued_list: [],
  contract_supplier: [],
  status_list: [],
  status_list_backup: [],
  qcDetailModel: []
}
export const POSlice = createSlice({
  name: 'PO',
  initialState,
  reducers: {
    invoiceSent: (state, action) => {
      state.is_sent = action.payload
    },
    clearSelectedItem: (state, action) => {
      state.selectedApprovedItem = []
      state.successStatus = 0
    },
    setContract: (state, action) => {
      state.contract_detail = action.payload
    },
    changeDetails: (state, action) => {
      const { name, value } = action.payload
      console.log(name, value)
      state.orderDetails = { ...state.orderDetails, [name]: value }
    },
    clearPoIndex: (state, action) => {
      state.qcDetailbyId = {}
      state.isSaved = false
      state.contract_detail = []
      state.orderDetails = {}
      state.PODetail = []
      state.issued_list = []
      state.contract_supplier = []
    },
    clearOrderDetails: (state, action) => {
      state.PODetail = {}
    },
    splitPOQuantity: (state, action) => {
      const regex = /^\d*[0-9]\d*$/;
      let splitQty = action.payload.quantity ? action.payload.quantity : "0";
      const currentBookQty = state.orderDetails.PODetail.find(ele => ele.id === action.payload.id);

      if (regex.test(splitQty) && currentBookQty && Number(splitQty) >= 0 && Number(currentBookQty.quantity) >= Number(splitQty)) {
        const updatedQty = String(currentBookQty.quantity - splitQty);
        state.PODetail = state.PODetail.map(ele => ele.id === action.payload.id ? { ...ele, quantity: updatedQty, split_quantity: splitQty } : ele);
      } else if (splitQty) {
        toast.error("Entered split amount is invalid");
        state.PODetail = state.PODetail.map(ele => ele.id === action.payload.id ? { ...ele, quantity: currentBookQty.quantity } : ele);
      }

      return state;

    },
    splitCheckPO: (state, action) => {
      if (action.payload.quantity === 0) {
        state.PODetail = state.PODetail.map(el => el.id === action.payload.id ? Object.assign({}, { ...action.payload, quantity: action.payload.split_quantity, split_quantity: 0 }) : el)
      } else {
        state.PODetail = state.PODetail.map(el => el.id === action.payload.id ? Object.assign({}, { ...action.payload, quantity: 0, split_quantity: action.payload.quantity }) : el)
      }
    },
    editPOQuantity: (state, action) => {
      let sum = 0
      if (action.payload.quantity !== 0) {
        state.PODetail = state.PODetail.map(el => el.id === action.payload.id ? Object.assign({}, {
          ...action.payload,
          quantity: action.payload.quantity,
          subtotal: (Number(el.net_unit_cost) * action.payload.quantity),
          ex_sub_total: (Number(el.ex_price) * action.payload.quantity)
        }) : el)
        state.PODetail.map(el => sum = el.subtotal + sum)
        state.orderDetails = { ...state.orderDetails, grand_total: sum }
      } else {
        toast.error('You cannot enter zero')
      }
    },
    onChangeQuantityForItem: (state, { payload }) => {
      state.qcDetailbyId = { ...state.qcDetailbyId, comparisiondetail: payload }
    },
    bulkPODetail: (state, action) => {
      switch (action.payload.type) {
        case 'QUANTITY':
          state.PODetail = state.PODetail.map(x => x.id === action.payload.id ? { ...x, rcd_qty: Number(action.payload.rcd_qty) } : x)
          break;
        case 'EXPIRY':
          state.PODetail = state.PODetail.map(x => x.id === action.payload.id ? { ...x, expiry_date: action.payload.expiry_date } : x)
          break;
        case 'REMARK':
          state.PODetail = state.PODetail.map(x => x.id === action.payload.id ? { ...x, remarks: action.payload.remarks } : x)
          break;
        case 'LOCATION':
          state.PODetail = state.PODetail.map(x => x.id === action.payload.id ? { ...x, location_id: action.payload.location_id } : x)
          break;
        default:
          return null
      }
    },
    setVatData: (state, action) => {
      switch (action.payload.type) {
        case 'VAT':
          state.qcDetailModel = state.qcDetailModel.map(x => x.id === action.payload.id ? { ...x, vat: Number(action.payload.vat) } : x)
          break;
        case 'DISCOUNT':
          state.qcDetailModel = state.qcDetailModel.map(x => x.id === action.payload.id ? { ...x, discount: Number(action.payload.discount) } : x)
          break;
        default:
          state.qcDetailModel = action.payload
      }
    },
    searchQCList: (state, action) => {
      switch (action.payload.type) {
        case 'PO':
          if (action.payload !== '') {
            let searchResult = []
            state.qcSummaryList_backup.map(el => ((el.pr_name.toLowerCase().search(action.payload.term.toLowerCase()) !== -1) || (el.cquote_number.toLowerCase().search(action.payload.term.toLowerCase()) !== -1)) && searchResult.push(el))
            state.qcSummaryList = searchResult
          } else {
            state.qcSummaryList = state.qcSummaryList_backup
          }
          break;
        case 'GOODS':
          if (action.payload !== '') {
            let searchResult = []
            state.issued_list_backup.map(el => ((el.pr_name.toLowerCase().search(action.payload.term.toLowerCase()) !== -1) || (el.reference_no.toLowerCase().search(action.payload.term.toLowerCase()) !== -1)) && searchResult.push(el))
            state.issued_list = searchResult
          } else {
            state.issued_list = state.issued_list_backup
          }
          break;
        case 'INVOICE':
          if (action.payload !== '') {
            let searchResult = []
            state.issued_list_backup.map(el => ((el.pr_name.toLowerCase().search(action.payload.term.toLowerCase()) !== -1) || (el.pr_id.toLowerCase().search(action.payload.term.toLowerCase()) !== -1)) && searchResult.push(el))
            state.issued_list = searchResult
          } else {
            state.issued_list = state.issued_list_backup
          }
          break;
        case 'PO APPROVE':
          if (action.payload !== '') {
            let searchResult = []
            state.status_list_backup.map(el => (el.pr_name.toLowerCase().search(action.payload.term.toLowerCase()) !== -1) && searchResult.push(el))
            state.status_list = searchResult
          } else {
            state.issued_list = state.status_list_backup
          }
          break;
        default:
          return null
      }
    }
  },
  extraReducers: (builder) => {
    (builder)
      .addCase(savePO.fulfilled, (state, action) => {
        //console.log(action.payload);
        if (action.payload.data.result.status) {
          state.isSaved = !state.isSaved
          toast.success(action.payload.data.result.message)
        }
        else {
          toast.error(action.payload.data.result.message)
        }
      }).addCase(approveBulkPO.fulfilled, (state, action) => {
        if (action.payload.data.result.status) {
          let { status } = action.payload.data.result.data[0]
          status === 'success' ? toast.success(action.payload.data.result.data[0].status) : toast.error(action.payload.data.result.data[0].status)
        }
        if (action.payload.data.isFaulted) {
          toast.error(action.payload.data.result.message)
        }
      }).addCase(getAllQCSummary.fulfilled, (state, action) => {
        if (action.payload.data.isCompletedSuccessfully) {
          state.qcSummaryList = action.payload.data.result.data
          state.qcSummaryList_backup = action.payload.data.result.data
        }
        if (action.payload.data.isFaulted) {
          toast.error(action.payload.data.result.message)
        }
      }).addCase(getAmendmetList.fulfilled, (state, action) => {
        if (action.payload.data.result.status) {
          state.status_list = action.payload.data.result.data
          state.status_list_backup = action.payload.data.result.data
        }
        else {
          toast.error(action.payload.data.result.message)
        }
      }).addCase(getQCSummaryByID.fulfilled, (state, action) => {
        if (action.payload.data.isCompletedSuccessfully) {
          let data = action.payload.data.result.data[0]
          console.log(data);/// can't get data for approved status
          data.QuotationDetail = action.payload.data.result.data[0].QuotationDetail ? ConvertJson(action.payload.data.result.data[0].QuotationDetail) : []
          data.comparisiondetail = action.payload.data.result.data[0].comparisiondetail ? ConvertJson(action.payload.data.result.data[0].comparisiondetail) : []
          data.suppliers = action.payload.data.result.data[0].suppliers ? ConvertJson(action.payload.data.result.data[0].suppliers) : []
          state.qcDetailbyId = data
        }
        if (action.payload.data.isFaulted) {
          toast.error(action.payload.data.result.message)
        }
      }).addCase(getPOcreationData.fulfilled, (state, { payload }) => {
        if (payload.data.isCompletedSuccessfully) {
          if (payload.data.result.data.length > 0) {
            let data = payload.data.result.data[0]
            data.QuotationDetail = payload.data.result.data[0].QuotationDetail ? ConvertJson(payload.data.result.data[0].QuotationDetail) : []
            data.Quotationremark = payload.data.result.data[0]?.Quotationremark && payload.data.result.data[0]?.Quotationremark ? ConvertJson(payload.data.result.data[0].Quotationremark) : []
            state.qcDetailbyId = data
          }
        }
        if (payload.data.isFaulted) {
          toast.error(payload.data.result.message)
        }
      }).addCase(getAllPOList.fulfilled, (state, action) => {
        state.loading = false
        if (action.payload.data.isCompletedSuccessfully) {
          state.POList = action.payload.data.result.data.map(ele => ({
            ...ele,
            due_date: dateTimeToDate(ele.due_date, "d/m/y"),
            deleivery_date: dateTimeToDate(ele.deleivery_date, "d/m/y"),
          }));
        }
        if (action.payload.data.isFaulted) {
          toast.error(action.payload.data.result.message)
        }
      }).addCase(getPoDetails.fulfilled, (state, { payload, meta }) => {
        if (payload.data.result.status) {
          let convertModal = ConvertJson(payload.data.result.data[0].PODetail)
          let Quotationremark = payload.data.result.data[0]?.Quotationremark ? ConvertJson(payload.data.result.data[0].Quotationremark) : []
          state.PODetail = convertModal
          state.orderDetails = { ...payload.data.result.data[0], PODetail: convertModal, Quotationremark: Quotationremark }
        }
      }).addCase(getAmendmentByID.fulfilled, (state, { payload, meta }) => {
        if (payload.data.result.status) {
          let convertModal = ConvertJson(payload.data.result.data[0].PODetail)
          state.PODetail = convertModal
          state.orderDetails = { ...payload.data.result.data[0], PODetail: convertModal }
          //state.orderDetails=payload.data.result.data[0]
        }
      }).addCase(getContractPoSupplier.fulfilled, (state, action) => {
        if (action.payload.data.isCompletedSuccessfully) {
          state.contract_supplier = action.payload.data.result.data
        }
        if (action.payload.data.isFaulted) {
          toast.error(action.payload.data.result.message)
        }
      })
      .addCase(getIssuedList.fulfilled, (state, action) => {
        if (action.payload.data.isCompletedSuccessfully) {
          state.issued_list = action.payload.data.result.data
          state.issued_list_backup = action.payload.data.result.data
        }
        if (action.payload.data.isFaulted) {
          toast.error(action.payload.data.result.message)
        }
      })
      .addCase(getStatusPOList.fulfilled, (state, action) => {
        if (action.payload.data.isCompletedSuccessfully) {
          state.status_list = action.payload.data.result.data
          state.status_list_backup = action.payload.data.result.data
        }
        if (action.payload.data.isFaulted) {
          toast.error(action.payload.data.result.message)
        }
      })
      .addCase(getIssuedList.rejected, (state, action) => {
        console.log(action.payload);
      })
      .addCase(getGoodsDetailbyId.fulfilled, (state, { payload }) => {
        if (payload.data.result.status) {
          let convertModal = ConvertJson(payload.data.result.data[0].MRDetail)
          convertModal = convertModal.length > 0 && convertModal.map(x => (
            { ...x, expiry_date: new Date(`${x.expiry_date}Z`) }
          ))
          let repository = payload.data.result.data[0]?.repository ? ConvertJson(payload.data.result.data[0].repository) : []
          state.PODetail = convertModal
          state.orderDetails = { ...payload.data.result.data[0], PODetail: convertModal, repository }
        }
        else {
          toast.error(payload.data.result.message)
        }
      })
      .addCase(getGoodsDetailbyId.rejected, (state, action) => {
        if (action.payload.response.data.status === 400) {
          toast.error(action.payload.response.data.title)
        }
      })
      .addCase(approvePO.fulfilled, (state, action) => {
        if (!action.payload.data.result.status) {
          toast.error(action.payload.data.result.message)
        }
      })

  }
})
export const { clearSelectedItem, setVatData, searchQCList, invoiceSent, changeDetails, clearOrderDetails, splitCheckPO, editPOQuantity, splitPOQuantity, onChangeQuantityForItem, clearPoIndex, setContract, bulkPODetail } = POSlice.actions
export default POSlice.reducer