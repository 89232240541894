import { createAsyncThunk } from "@reduxjs/toolkit";
import * as ACTION from "../../constant/Actions";
import * as APIS from "../../constant/CommonApiUrl";
import { API_SERVICE } from "../../Services/CommonApi";
import axios from "axios";

export const getPartReport = createAsyncThunk(
  ACTION.GET_PART_REPORT,
  async (arg, thunkAPI) => {
    try {
      const source = axios.CancelToken.source();
      thunkAPI.signal.addEventListener("abort", () => {
        source.cancel();
      });
      const { id, vessel_name } =
        thunkAPI.getState().root.common.vessel_detailByID;
      let response = await API_SERVICE.post(
        `${APIS.GET_PART_REPORT}`,
        { ...arg, vessel_id: vessel_name === "OFFICE" ? 0 : id },
        "",
        { cancelToken: source.token }
      );
      return response;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);
export const getROBViewbyId = createAsyncThunk(
  ACTION.ROB_VIEW_REPORT,
  async (arg, thunkAPI) => {
    try {
      const source = axios.CancelToken.source();
      thunkAPI.signal.addEventListener("abort", () => {
        source.cancel();
      });
      const { id, vessel_name } =
        thunkAPI.getState().root.common.vessel_detailByID;
      let response = await API_SERVICE.get(
        `${APIS.ROB_VIEW_REPORT}?id=${arg}&vessel_id=${vessel_name === "OFFICE" ? 0 : id
        }`,
        "",
        "",
        { cancelToken: source.token }
      );
      return response;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);
export const getStoreReport = createAsyncThunk(
  ACTION.GET_STORE_REPORT,
  async (arg, thunkAPI) => {
    try {
      const source = axios.CancelToken.source();
      thunkAPI.signal.addEventListener("abort", () => {
        source.cancel();
      });
      const { id, vessel_name } =
        thunkAPI.getState().root.common.vessel_detailByID;
      let response = await API_SERVICE.get(
        `${APIS.GET_STORE_REPORT}?${arg}&vessel_id=${vessel_name === "OFFICE" ? 0 : id
        }`,
        "",
        "",
        { cancelToken: source.token }
      );
      return response;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);
export const getStoreROBViewbyId = createAsyncThunk(
  ACTION.STORE_REPORT_VIEW,
  async (arg, thunkAPI) => {
    try {
      const source = axios.CancelToken.source();
      thunkAPI.signal.addEventListener("abort", () => {
        source.cancel();
      });
      const { id, vessel_name } =
        thunkAPI.getState().root.common.vessel_detailByID;
      let response = await API_SERVICE.get(
        `${APIS.STORE_REPORT_VIEW}?id=${arg}&vessel_id=${vessel_name === "OFFICE" ? 0 : id
        }`,
        "",
        "",
        { cancelToken: source.token }
      );
      return response;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);
export const getStoreReportViewbyId = createAsyncThunk(
  ACTION.STORE_REPORT_VIEW,
  async (arg, thunkAPI) => {
    try {
      const source = axios.CancelToken.source();
      thunkAPI.signal.addEventListener("abort", () => {
        source.cancel();
      });
      const { id, vessel_name } =
        thunkAPI.getState().root.common.vessel_detailByID;
      let response = await API_SERVICE.get(
        `${APIS.STORE_REPORT_VIEW}?id=${arg}&vessel_id=${vessel_name === "OFFICE" ? 0 : id
        }`,
        "",
        "",
        { cancelToken: source.token }
      );
      return response;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);
export const getStoreRobReportViewbyId = createAsyncThunk(
  ACTION.STORE_ROB_REPORT_VIEW,
  async (arg, thunkAPI) => {
    try {
      const source = axios.CancelToken.source();
      thunkAPI.signal.addEventListener("abort", () => {
        source.cancel();
      });
      const { id, vessel_name } =
        thunkAPI.getState().root.common.vessel_detailByID;
      let response = await API_SERVICE.get(
        `${APIS.STORE_ROB_REPORT_VIEW}?id=${arg}&vessel_id=${vessel_name === "OFFICE" ? 0 : id
        }`,
        "",
        "",
        { cancelToken: source.token }
      );
      return response;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);
export const getOilReport = createAsyncThunk(
  ACTION.GET_OIL_REPORT,
  async (arg, thunkAPI) => {
    try {
      const source = axios.CancelToken.source();
      thunkAPI.signal.addEventListener("abort", () => {
        source.cancel();
      });
      const { id, vessel_name } =
        thunkAPI.getState().root.common.vessel_detailByID;
      let response = await API_SERVICE.get(
        `${APIS.GET_OIL_REPORT}?${arg}&vessel_id=${vessel_name === "OFFICE" ? 0 : id
        }`,
        "",
        "",
        { cancelToken: source.token }
      );
      return response;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);
export const getEqipmentReport = createAsyncThunk(
  "GET_EQUIPMENT_REPORTS",
  async (arg, thunkAPI) => {
    try {
      const source = axios.CancelToken.source();
      thunkAPI.signal.addEventListener("abort", () => {
        source.cancel();
      });
      if (arg?.url) {
        const { id, vessel_name } =
          thunkAPI.getState().root.common.vessel_detailByID;
        let response = await API_SERVICE.get(
          `${arg?.url}?${arg?.params}&vessel_id=${vessel_name === "OFFICE" ? 0 : id}`, "", "", { cancelToken: source.token }
        );
        return response;
      }
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

export const getEqipmentReportById = createAsyncThunk(
  "GET_EQUIPMENT_REPORTS_BY_ID",
  async (arg, thunkAPI) => {
    try {
      const source = axios.CancelToken.source();
      thunkAPI.signal.addEventListener("abort", () => {
        source.cancel();
      });
      if (arg?.url) {
        const { id, vessel_name } =
          thunkAPI.getState().root.common.vessel_detailByID;
        let response = await API_SERVICE.get(
          `${arg?.url}?id=${arg?.id}&vessel_id=${vessel_name === "OFFICE" ? 0 : id}`, "", "", { cancelToken: source.token }
        );
        return response;
      }
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

export const getOilReportViewbyId = createAsyncThunk(
  ACTION.OIL_REPORT_VIEW,
  async (arg, thunkAPI) => {
    try {
      const source = axios.CancelToken.source();
      thunkAPI.signal.addEventListener("abort", () => {
        source.cancel();
      });
      const { id, vessel_name } =
        thunkAPI.getState().root.common.vessel_detailByID;
      let response = await API_SERVICE.get(
        `${APIS.OIL_REPORT_VIEW}?id=${arg}&vessel_id=${vessel_name === "OFFICE" ? 0 : id
        }`,
        "",
        "",
        { cancelToken: source.token }
      );
      return response;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);
export const getOilROBViewbyId = createAsyncThunk(
  ACTION.OIL_ROB_VIEW,
  async (arg, thunkAPI) => {
    try {
      const source = axios.CancelToken.source();
      thunkAPI.signal.addEventListener("abort", () => {
        source.cancel();
      });
      const { id, vessel_name } =
        thunkAPI.getState().root.common.vessel_detailByID;
      let response = await API_SERVICE.get(
        `${APIS.OIL_REPORT_VIEW}?id=${arg}&vessel_id=${vessel_name === "OFFICE" ? 0 : id
        }`,
        "",
        "",
        { cancelToken: source.token }
      );
      return response;
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);
