import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { GET_LOCATION, SAVE_LOCATION } from "../constant/CommonApiUrl";
import { API_SERVICE } from "../Services/CommonApi"
import { toast } from "react-toastify"
import { setTypeFieldsIsUpdated } from "./typeFieldsSlice"

const ADD_LOCATION = 'location/addLocationApi'
const UPDATE_LOCATION = 'location/updateLocationApi'
const FETCH_LOCATION = 'location/fetchLocationApi'
const DELETE_LOCATION = 'location/deleteLocationApi'

export const addLocationApi =createAsyncThunk(ADD_LOCATION,
    async(args, thunkAPI)=>{
        try{
            let response = await API_SERVICE.post(SAVE_LOCATION, args['data']);
            thunkAPI.dispatch(fetchLocationApi())
            thunkAPI.dispatch(setTypeFieldsIsUpdated(true));
            return response;
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
    })

export const updateLocationApi = createAsyncThunk(UPDATE_LOCATION,
    async(args, thunkAPI)=>{
        try{
            let response = await API_SERVICE.post(SAVE_LOCATION, args['data']);
            thunkAPI.dispatch(setTypeFieldsIsUpdated(true));
            thunkAPI.dispatch(fetchLocationApi())
            return response.data.result.data[0];
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
    })

export const deleteLocationApi = createAsyncThunk(DELETE_LOCATION,
    async (args, thunkAPI) => {
        try{
            const toDelete = thunkAPI.getState().root.location.list.find(ele => ele.id === args['id']);
            let response = await API_SERVICE.post(SAVE_LOCATION, {...toDelete, isdisabled: "Y"});
            thunkAPI.dispatch(setTypeFieldsIsUpdated(true));
            thunkAPI.dispatch(fetchLocationApi())
            return response.data.result.data[0];
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
    }
)

export const fetchLocationApi = createAsyncThunk(FETCH_LOCATION,
    async(arg,thunkAPI)=>{
        try{
            const {id,vessel_name} = thunkAPI.getState().root.common.vessel_detailByID
            //console.log('test')
            let response = await API_SERVICE.get(`${GET_LOCATION}?vessel_id=${vessel_name==='OFFICE'?0:id}`);
            return response.data.result.data;
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
    }
)

const initialState={
    list:[],
    editLocation:{},
    isUpdated: true
}
export const LocationSlice=createSlice({
    name:'location',
    initialState,
    reducers:{
        addLocation:(state,action)=>{
            state.list=[...state.list,action.payload]
        },
        delLocation:(state,action)=>{
            state.list=state.list.filter(x=>x.id!==action.payload)
        },
        loadEditLocation:(state,action)=>{
            state.editLocation=action.payload
        },
        clearEditLocation:(state,action)=>{
            state.editLocation={}
        },
        updateLocation:(state,action)=>{
            let index=state.list.findIndex(x=>x.id===action.payload.id)
            state.list[index]=action.payload
        },
        resetLocation:()=>initialState
    },
    extraReducers:(builder)=>{
        builder
            .addCase(addLocationApi.fulfilled,(state,action)=>{
                if(action.payload.data.result.status){
                    toast.success('Successfully added');
                }else{
                    toast.error(action.payload.data.result.message)
                }

            })
            .addCase(updateLocationApi.fulfilled,(state,action)=>{
                toast.success('Successfully updated');
            })
            .addCase(fetchLocationApi.fulfilled, (state, action) => {
                state.list = action.payload;
                state.isUpdated = false;
            })
            .addCase(deleteLocationApi.fulfilled, (state, action) => {
                toast.success('Successfully deleted');
            })
    }
})
export const { addLocation, delLocation, loadEditLocation, clearEditLocation, updateLocation, resetLocation } = LocationSlice.actions
export default LocationSlice.reducer