import { createSlice } from "@reduxjs/toolkit";
import { getEqipmentReport, getEqipmentReportById, getOilROBViewbyId, getOilReport, getOilReportViewbyId, getPartReport, getROBViewbyId, getStoreReport, getStoreReportViewbyId, getStoreRobReportViewbyId } from "./THUNK/InventoryReportThunk";
import { ValidateResponse } from "../Services/Support";
import { ConvertJson } from "../helper";
const initialState = {
    list: [],
    item_details: {},
    sorting: false,
}
export const InventoryReportSlice = createSlice({
    name: "INVENTORY_REPORT",
    initialState,
    reducers: {
        ascSortInventoryReport: (state, action) => {
            state.list = state.list.sort((a, b) => {
                if (typeof a[action.payload] === 'number' && typeof b[action.payload] === 'number') {
                    return a[action.payload] - b[action.payload]
                } else {
                    return a[action.payload].localeCompare(b[action.payload])
                }
            })
            state.sorting = true
        },
        descSortInventoryReport: (state, action) => {
            state.list = state.list.sort((a, b) => {
                if (typeof a[action.payload] === 'number' && typeof b[action.payload] === 'number') {
                    return b[action.payload] - a[action.payload]
                } else {
                    return b[action.payload]?.localeCompare(a[action.payload])
                }
            })
            state.sorting = false
        },
        clearInventoryReport: (state, action) => {
            state.list = []
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPartReport.fulfilled, (state, action) => {
                state.list = ValidateResponse(action.payload)
            })
            .addCase(getEqipmentReportById.fulfilled, (state, action) => {
                let obj = ValidateResponse(action.payload)[0]
                obj.rob_history = obj?.rob_history ? ConvertJson(obj.rob_history) : []
                state.item_details = obj
                
            })
            .addCase(getROBViewbyId.fulfilled, (state, action) => {
                let obj = ValidateResponse(action.payload)[0]
                obj.rob_history = obj?.rob_history ? ConvertJson(obj.rob_history) : []
                state.item_details = obj
            })
            .addCase(getStoreReport.fulfilled, (state, action) => {
                state.list = ValidateResponse(action.payload)
            })
            .addCase(getOilReport.fulfilled, (state, action) => {
                state.list = ValidateResponse(action.payload)
            })
            .addCase(getEqipmentReport.fulfilled, (state, action) => {
                console.log(action.payload)
                state.list = ValidateResponse(action.payload)
            })
            .addCase(getStoreReportViewbyId.fulfilled, (state, action) => {
                let obj = ValidateResponse(action.payload)[0]
                obj.rob_history = obj?.rob_history ? ConvertJson(obj.rob_history) : []
                state.item_details = obj
            })
            .addCase(getStoreRobReportViewbyId.fulfilled, (state, action) => {
                let obj = ValidateResponse(action.payload)[0]
                obj.rob_history = obj?.rob_history ? ConvertJson(obj.rob_history) : []
                state.item_details = obj
            })
            .addCase(getOilReportViewbyId.fulfilled, (state, action) => {
                let obj = ValidateResponse(action.payload)[0]
                obj.rob_history = obj?.rob_history ? ConvertJson(obj.rob_history) : []
                state.item_details = obj
            })
            .addCase(getOilROBViewbyId.fulfilled, (state, action) => {
                let obj = ValidateResponse(action.payload)[0]
                obj.rob_history = obj?.rob_history ? ConvertJson(obj.rob_history) : []
                state.item_details = obj
            })
    }
})
export const { ascSortInventoryReport, descSortInventoryReport, clearInventoryReport } = InventoryReportSlice.actions
export default InventoryReportSlice.reducer