import React from "react";
import { Box } from "../STYLE/PMSStyle";
export const CustomTooltip = ({ active, payload, label }) => {    
    if (active && payload && payload.length) {        
        return (
            <Box className="custom-tooltip">
                <div className='d-flex justify-content-between'>
                    <span>Total</span>
                    <span>{payload[0].payload.total}</span>
                </div>
                <div className='d-flex justify-content-between'>
                    <span>Completed</span>
                    <span>{payload[0].payload.completed}</span>
                </div>
                <div className='d-flex justify-content-between'>
                    <span>Pending</span>
                    <span>{payload[0].payload.pending}</span>
                </div>
            </Box>
        );
    }

    return null;
};