export const getMissingCylinderMonth = (month) => {    
    switch (month) {
        case 1:
            return {
                "category": "January",
                "value1": 0,
                "value2": 100,
                "color": "#17A1FA",
                "lightColor": "#72b3ff",
                "job_month":1
            }
        case 2:
            return {
                "category": "February",
                "value1": 0,
                "value2": 100,
                "color": "#1ED6FF",
                "lightColor": "#66d6ef",
                "job_month":2
            }
        case 3:
            return {
                "category": "March",
                "value1": 0,
                "value2": 100,
                "color": "#3DFFDC",
                "lightColor": "#81ffe8",
                "job_month":3
            }
        case 4:
            return {
                "category": "April",
                "value1": 0,
                "value2": 100,
                "color": "#36F097",
                "lightColor": "#7df5bc",
                "job_month":4
            }
        case 5:
            return {
                "category": "May",
                "value1": 0,
                "value2": 100,
                "color": "#17A1FA",
                "lightColor": "#72b3ff",
                "job_month":5
            }
        case 6:
            return {
                "category": "June",
                "value1": 0,
                "value2": 100,
                "color": "#1ED6FF",
                "lightColor": "#66d6ef",
                "job_month":6
            }
        case 7:
            return {
                "category": "July",
                "value1": 0,
                "value2": 100,
                "color": "#3DFFDC",
                "lightColor": "#81ffe8",
                "job_month":7
            }
        case 8:
            return {
                "category": "August",
                "value1": 0,
                "value2": 100,
                "color": "#36F097",
                "lightColor": "#7df5bc",
                "job_month":8
            }
        case 9:
            return {
                "category": "September",
                "value1": 0,
                "value2": 100,
                "color": "#17A1FA",
                "lightColor": "#72b3ff",
                "job_month":9
            }
        case 10:
            return {
                "category": "October",
                "value1": 0,
                "value2": 100,
                "color": "#1ED6FF",
                "lightColor": "#66d6ef",
                "job_month":10
            }
        case 11:
            return {
                "category": "November",
                "value1": 0,
                "value2": 100,
                "color": "#3DFFDC",
                "lightColor": "#81ffe8",
                "job_month":11
            }
        case 12:
            return {
                "category": "December",
                "value1": 0,
                "value2": 100,
                "color": "#36F097",
                "lightColor": "#7df5bc",
                "job_month":12
            }
    }
}
const daysOfWeek = [
    { name: "Sunday", completed: 0, total: 0, pending: 0, week_day: 1 },
    { name: "Monday", completed: 0, total: 0, pending: 0, week_day: 2 },
    { name: "Tuesday", completed: 0, total: 0, pending: 0, week_day: 3 },
    { name: "Wednesday", completed: 0, total: 0, pending: 0, week_day: 4 },
    { name: "Thursday", completed: 0, total: 0, pending: 0, week_day: 5 },
    { name: "Friday", completed: 0, total: 0, pending: 0, week_day: 6 },
    { name: "Saturday", completed: 0, total: 0, pending: 0, week_day: 7 }
  ];
export const getMissingWeek=(arg)=>{      
    let all=[...daysOfWeek,...arg]
    const uniqueDaysOfWeek = Array.from(
        all.reduce((map, day) => {
          if (!map.has(day.week_day) || day.total > map.get(day.week_day).total) {
            map.set(day.week_day, day);
          }
          return map;
        }, new Map()).values()
      );
    return uniqueDaysOfWeek
}