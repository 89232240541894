import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit"
import { toast } from "react-toastify"
import { API_SERVICE } from "../Services/CommonApi"
const {post,get}=API_SERVICE
const ADD_PURCHASE='purchase/addPurchaseApi'
const FETCH_PURCHASE='purchase/fetchPurchaseApi'
export const addPurchaseApi=createAsyncThunk(ADD_PURCHASE,
    async(arg,thunkAPI)=>{
        try{
            const response=post('/purchase_request',arg)            
            return response
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
    }
)
export const fetchPurchaseApi=createAsyncThunk(FETCH_PURCHASE,
    async(arg,thunkAPI)=>{
        try{
            const response=get(`/purchase_request`) 
            return response
        }catch(err){            
            return thunkAPI.rejectWithValue(err)
        }
    },
    {
        condition: (arg, { getState, extra }) => {          
          const isUpdated = getState().root.purchase.isUpdated                         
          if(isUpdated){
            console.log('enable fecth condition in reducer')
            return false
          }
        },
      }
)
/* function isRejectedAction(action) {
    return action.type.endsWith('rejected')
  }
function isPendingAction(action) {
return action.type.endsWith('pending')
} */
export const purchaseAdapter = createEntityAdapter({
    
  })
const initialState=purchaseAdapter.getInitialState({    
    isLoading:false,
    isUpdated:false,
})
export const purchaseSlice=createSlice({
    name:'purchase',
    initialState,
    reducers:{ 
       
    },
    extraReducers:(builder)=>{
        builder       
        .addCase('purchase/addPurchaseApi/fulfilled',(state,action)=>{
            //note: u can create fullfiled function comonly and get action.type is matched let update the state using switch
            state.isLoading=false
            state.isUpdated=false            
            purchaseAdapter.upsertOne(state,action.payload.data)
            toast.success('successfully added')
        })
        .addCase('purchase/fetchPurchaseApi/fulfilled',(state,action)=>{
            //note: u can create fullfiled function comonly and get action.type is matched let update the state using switch
            state.isLoading=false
            state.isUpdated=true            
            //toast.success('successfuly fetched')            
            purchaseAdapter.upsertMany(state,action.payload.data)
        })
        /* .addMatcher(isPendingAction,(state, action) => {
            state.isLoading=true            
        })            
        .addMatcher(isRejectedAction,(state, action) => {
            state.isLoading=false
            //purchaseAdapter.upsertMany(state,[{id:1,name:'mouse',type:'animal'},{id:2,name:'mous2e',type:'ani2mal'}])   
            console.log(action.payload,'error');                  
            toast.error('failed')
        })  */       
    }        
})
export const {selectIds,selectEntities,selectAll:purchaseList}=purchaseAdapter.getSelectors((state) => state.root.purchase)
export const {searchPurchase}=purchaseSlice.actions
export default purchaseSlice.reducer