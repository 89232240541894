import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import {toast} from "react-toastify"
import {SAVE_APPROVE_LEVEL, SETTINGS} from "../constant/CommonApiUrl";
import {API_SERVICE} from "../Services/CommonApi"
import {setTypeFieldsIsUpdated} from "./typeFieldsSlice"
import {ConvertJson} from "../helper";

const ADD_APPROVELEVEL='approveLevel/addApproveLevelApi'
const UPDATE_APPROVELEVEL = 'approveLevel/updateApproveLevelApi'
const FETCH_APPROVELEVEL='approveLevel/fetchApproveLevelApi'
const DELETE_APPROVELEVEL='approveLevel/deleteApproveLevelApi'

export const addApproveLevelApi =createAsyncThunk(ADD_APPROVELEVEL,
    async(args, thunkAPI)=>{
        try{
            let response = await API_SERVICE.post(SAVE_APPROVE_LEVEL, args['data'], "CREATE");
            response = response.data.result.data[0];
            thunkAPI.dispatch(setTypeFieldsIsUpdated(true));
            return response;
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
})

export const updateApproveLevelApi = createAsyncThunk(UPDATE_APPROVELEVEL,
    async(args, thunkAPI)=>{
        try{
            let response = await API_SERVICE.post(SAVE_APPROVE_LEVEL, args['data'], "UPDATE");
            response = response.data.result.data[0];
            thunkAPI.dispatch(setTypeFieldsIsUpdated(true));
            return response;
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
})

export const deleteApproveLevelApi = createAsyncThunk(DELETE_APPROVELEVEL, 
    async (args, thunkAPI) => {
        try{
            const toDelete = thunkAPI.getState().root.approveLevel.list.find(ele => ele.id === args['id']);   
            let response = await API_SERVICE.post(SAVE_APPROVE_LEVEL, {...toDelete, isdisabled: "Y"});
            response = response.data.result.data[0]
            thunkAPI.dispatch(setTypeFieldsIsUpdated(true));
            return response;
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
    }
)

export const fetchApproveLevelApi = createAsyncThunk(FETCH_APPROVELEVEL,
    async(arg,thunkAPI)=>{
        try{
            let response = await API_SERVICE.get(`${SETTINGS}GetApprovallevel`);
            response = response.data.result.data;
            return response
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
    }
)

const initialState={
    list:[],
    edit:{},    
    isUpdated: true
}

export const approveLevelSlice=createSlice({
    name:'approveLevel',
    initialState,
    reducers:{
        addApproveLevel:(state,action)=>{
            state.list=[...state.list,action.payload]
        },
        delApproveLevel:(state,action)=>{
            state.list=state.list.filter(x=>x.id!==action.payload)
        },
        loadEditApproveLevel:(state,action)=>{
            state.edit=action.payload
        },
        clearEditApproveLevel:(state,action)=>{
            state.edit={}
        },
        updateApproveLevel:(state,action)=>{
            let index=state.list.findIndex(x=>x.id===action.payload.id)
            state.list[index]=action.payload
        },               
        resetApproveLevel:()=>initialState
    },
    extraReducers:(builder)=>{
        builder
        .addCase(addApproveLevelApi.fulfilled,(state,action)=>{
            state.list=[...state.list,action.payload];
            toast.success('Successfully added');
        })
        .addCase(updateApproveLevelApi.fulfilled,(state,action)=>{
            state.list = state.list.map(x => x.id === action.payload.id ? action.payload: x);
            toast.success('Successfully updated');
        })
        .addCase(fetchApproveLevelApi.fulfilled, (state, action) => {
            const data = action.payload
            state.list = data.map((doc) => ({
                ...doc,
                vessel_id: doc.vessel_id ? ConvertJson(doc.vessel_id) : [],
                approveldetail: doc.approveldetail ? ConvertJson(doc.approveldetail) : [],
            }))
            state.isUpdated = false;
        })
        .addCase(deleteApproveLevelApi.fulfilled, (state, action) => {
            state.list=state.list.filter(x=>x.id!==action.payload.id);
            toast.success('Successfully deleted');
        })
    }   
})
export const {addApproveLevel,delApproveLevel,loadEditApproveLevel,clearEditApproveLevel,updateApproveLevel,resetApproveLevel}=approveLevelSlice.actions
export default approveLevelSlice.reducer