import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as ACTION from '../constant/Actions';
import * as APIS from '../constant/CommonApiUrl'
import { toast } from "react-toastify";
import { ConvertJson } from "../helper";
import axios from "axios";
export const loginValidate = createAsyncThunk(ACTION.LOGIN_CHECK_ACTION,
    async (arg, thunkApi) => {
        try {
            let response = await axios({
                method: "get",
                headers: {
                    Accept: "application/json",
                    ContentType: "application/json",
                    Authorization: `Bearer`
                },
                url: APIS.LOGIN_URL,
                params: arg,
            })
            toast.success(response.data.result.message)
            //console.log(response, 'login response')
            return { ...response.data.result.data[0], token: response.data.result.token }
        } catch (err) {
            thunkApi.rejectWithValue(err)
        }
    })
/* export const loginValidate=createAsyncThunk(ACTION.LOGIN_CHECK_ACTION,
    async(arg,thunkApi)=>{
        try{
            let response=await API_SERVICE.get(`${APIS.LOGIN_CHECK}?${arg}`)
            return response.data.result.data[0]
        }catch(err){
            thunkApi.rejectWithValue(err)
        }
    }) */


const loginDemo = localStorage.getItem('loginInfo') !== null ? JSON.parse(localStorage.getItem('loginInfo')) : {}
//initialstate
const initialState = {
    logininfo: { username: 'root@root.com', password: 'Test@123' },
    loginDemo: loginDemo,
    loading: false,
    // isUpdatedState: false 
}

const loginSlice = createSlice({
    name: "login",
    initialState,
    reducers: {
        addLoginInfo: (state, action) => {
            state.loginDemo = action.payload
            localStorage.setItem("loginInfo", JSON.stringify(state.loginDemo))
        },
        loginStatus: (state, action) => {
            state.isLogin = action.payload
        },
        resetLogin: () => initialState
        // resetState:()=>{
        //     return initialState // reset current state
        // }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginValidate.fulfilled, (state, action) => {
                if (action.payload) {
                    let { status, id, is_admin, is_billing_expired, billing_email, first_name, Process, Reports, message, designation_name, department_name, Email, department_id, designation_id, repository, token, vesselDetails, tenant_id = null, saas_status = 'inactive', saas_login_detail } = action.payload
                    if ((/true/).test(status)) {
                        repository = repository ? ConvertJson(repository) : []
                        Process = Process ? ConvertJson(Process) : []
                        Reports = Reports ? ConvertJson(Reports) : []
                        saas_login_detail = saas_login_detail ? ConvertJson(saas_login_detail) : []
                        vesselDetails = vesselDetails ? ConvertJson(vesselDetails) : []
                        state.loginDemo = Object.assign({}, { id, first_name, Designation: designation_name, Department: department_name, Email, billing_email, department_id, designation_id, is_billing_expired, is_admin, isLogin: true, token, repository, vesselDetails, Process, Reports, tenant_id, saas_status, saas_login_detail })
                        localStorage.setItem("loginInfo", JSON.stringify(state.loginDemo))
                        vesselDetails.length > 0 ? localStorage.setItem("vesselInfo", JSON.stringify({ ...vesselDetails[0], vessel_id: vesselDetails[0].id })) : localStorage.setItem("vesselInfo", {});
                        toast.success(message)
                    } else {
                        toast.error(message)
                    }
                }
            })
            .addCase(loginValidate.rejected, (state, action) => {
                console.log(action.payload);
            })
    }
});


export const { addLoginInfo, resetLogin, loginStatus } = loginSlice.actions
export default loginSlice.reducer;