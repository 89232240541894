import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit"
import { toast } from "react-toastify"
import { API_SERVICE } from "../Services/CommonApi"
const {post,get}=API_SERVICE
const ADD_PURCHASE_Code='purchase/addPurchaseCodeApi'
const FETCH_PURCHASE_Code='purchase/fetchPurchaseCodeApi'
export const addPurchaseCodeApi=createAsyncThunk(ADD_PURCHASE_Code,
    async(arg,thunkAPI)=>{
        try{
            const response=post('/purchase_code',arg)            
            return response
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
    }
)
export const fetchPurchaseCodeApi=createAsyncThunk(FETCH_PURCHASE_Code,
    async(arg,thunkAPI)=>{
        try{
            const response=get(`/purchase_code`) 
            return response
        }catch(err){            
            return thunkAPI.rejectWithValue(err)
        }
    },
    {
        condition: (arg, { getState, extra }) => {          
          const isUpdated = getState().root.purchaseCode.isUpdated                         
          if(isUpdated){
            console.log('enable fecth condition in reducer')
            return false
          }
        },
      }
)
/* function isRejectedAction(action) {
    return action.type.endsWith('rejected')
  }
function isPendingAction(action) {
return action.type.endsWith('pending')
} */
export const purchaseCodeAdapter = createEntityAdapter({
    
  })
const initialState=purchaseCodeAdapter.getInitialState({    
    isLoading:false,
    isUpdated:false,
})
export const purchaseCodeSlice=createSlice({
    name:'purchaseCode',
    initialState,
    reducers:{ 
       
    },
    extraReducers:(builder)=>{
        builder       
        .addCase('purchase/addPurchaseCodeApi/fulfilled',(state,action)=>{
            //note: u can create fullfiled function comonly and get action.type is matched let update the state using switch
            state.isLoading=false
            state.isUpdated=false            
            purchaseCodeAdapter.upsertOne(state,action.payload.data)
            toast.success('successfully added')
        })
        .addCase('purchase/fetchPurchaseCodeApi/fulfilled',(state,action)=>{
            //note: u can create fullfiled function comonly and get action.type is matched let update the state using switch
            state.isLoading=false
            state.isUpdated=true            
            //toast.success('successfuly fetched')            
            purchaseCodeAdapter.upsertMany(state,action.payload.data)
        })
       /*  .addMatcher(isPendingAction,(state, action) => {
            state.isLoading=true            
        })            
        .addMatcher(isRejectedAction,(state, action) => {
            state.isLoading=false
            //purchaseCodeAdapter.upsertMany(state,[{id:1,name:'mouse',type:'animal'},{id:2,name:'mous2e',type:'ani2mal'}])   
            console.log(action.payload,'error');                  
            toast.error('failed')
        }) */        
    }        
})
export const {selectIds,selectEntities,selectAll:purchaseCodeList}=purchaseCodeAdapter.getSelectors((state) => state.root.purchaseCode)
export const {searchPurchaseCode}=purchaseCodeSlice.actions
export default purchaseCodeSlice.reducer