// import exportFromJSON from "export-from-json"
import jsPDF from "jspdf"
import { toast } from "react-toastify";
import Papa from 'papaparse';
import autoTable from 'jspdf-autotable';
import { constructCSVData, exportData, getCapitalizedKeys, removeKeys } from "../../../helper/JSONtoTable";
const csvHeader = {
    ['partList']: ['id', 'vessel_id', 'repository'],
  }
export const exportCsv = (arg, filename) => {
        const data = arg.length > 0 && arg[0];
        const newObj = removeKeys(data, csvHeader[filename] || []);
        const head = getCapitalizedKeys(newObj);
        if (arg.length > 0 && head.length > 0) {
          const csvData = Papa.unparse({
            fields: head,
            data: constructCSVData(arg, csvHeader[filename] || []),
            transformHeader: (header) => `<b>${header}</b>`,
          });
          exportData(csvData, `${filename}.csv`, "text/csv;charset=utf-8;");
        }  else toast.error("No Record Found")    
}
export const exportPdf = (data, filename) => {
    if (data.length > 0) {
        let temp = []
        data.forEach((el) => {
            let arr = Object.keys(data[0]).map(x => el[x])
            temp.push(arr)
        })
        let doc = new jsPDF({
            orientation: 'l',
            unit: 'in',
            format: [30, 40]
        })
        autoTable(doc, {
            head: [Object.keys(data[0]).map(el => el.toUpperCase())],
            body: temp,
            /* theme: 'grid', */
        })
        doc.save(`${filename}.pdf`)
    } else toast.error('Please pass table data')
}