import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { GET_UOM, SAVE_UOM } from "../constant/CommonApiUrl";
import { API_SERVICE } from "../Services/CommonApi"
import { toast } from "react-toastify"
import { setTypeFieldsIsUpdated } from "./typeFieldsSlice"

const ADD_UOM = 'UOM/addUOMApi'
const UPDATE_UOM = 'UOM/updateUOMApi'
const FETCH_UOM = 'UOM/fetchUOMApi'
const DELETE_UOM = 'UOM/deleteUOMApi'

export const addUOMApi =createAsyncThunk(ADD_UOM,
    async(args, thunkAPI)=>{
        try{
            let response = await API_SERVICE.post(SAVE_UOM, args['data'], "CREATE");
            response = response.data.result.data[0];
            thunkAPI.dispatch(setTypeFieldsIsUpdated(true));
            return response;
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
    })

export const updateUOMApi = createAsyncThunk(UPDATE_UOM,
    async(args, thunkAPI)=>{
        try{
            let response = await API_SERVICE.post(SAVE_UOM, args['data'], "UPDATE");
            response = response.data.result.data[0];
            thunkAPI.dispatch(setTypeFieldsIsUpdated(true));
            return response;
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
    })

export const deleteUOMApi = createAsyncThunk(DELETE_UOM,
    async (args, thunkAPI) => {
        try{
            const toDelete = thunkAPI.getState().root.UOM.list.find(ele => ele.id === args['id']);
            let response = await API_SERVICE.post(SAVE_UOM, {...toDelete, isdisabled: "Y"});
            response = response.data.result.data[0];
            thunkAPI.dispatch(setTypeFieldsIsUpdated(true));
            return response;
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
    }
)

export const fetchUOMApi = createAsyncThunk(FETCH_UOM,
    async(arg,thunkAPI)=>{
        try{
            let response = await API_SERVICE.get(GET_UOM);
            response = response.data.result.data;
            return response
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
    }
)

const initialState={
    list:[],
    editUOM:{},
    isUpdated: true
}
export const UOMSlice=createSlice({
    name:'UOM',
    initialState,
    reducers:{
        addUOM:(state,action)=>{
            state.list=[...state.list,action.payload]
        },
        delUOM:(state,action)=>{
            state.list=state.list.filter(x=>x.id!==action.payload)
        },
        loadEditUOM:(state,action)=>{
            state.editUOM=action.payload
        },
        clearEditUOM:(state,action)=>{
            state.editUOM={}
        },
        updateUOM:(state,action)=>{
            let index=state.list.findIndex(x=>x.id===action.payload.id)
            state.list[index]=action.payload
        },
        resetUOM:()=>initialState
    },
    extraReducers:(builder)=>{
        builder
            .addCase(addUOMApi.fulfilled,(state,action)=>{
                state.isUpdated = true;
                state.list=[...state.list,action.payload];
                toast.success('Successfully added');
            })
            .addCase(updateUOMApi.fulfilled,(state,action)=>{
                state.isUpdated = true;
                state.list = state.list.map(x => x.id === action.payload.id ? action.payload: x);
                toast.success('Successfully updated');
            })
            .addCase(fetchUOMApi.fulfilled, (state, action) => {
                state.list = action.payload;
                state.isUpdated = false;
            })
            .addCase(deleteUOMApi.fulfilled, (state, action) => {
                state.list=state.list.filter(x=>x.id!==action.payload.id);
                toast.success('Successfully deleted');
            })
    }
})
export const {addUOM,delUOM,loadEditUOM,clearEditUOM,updateUOM,resetUOM}=UOMSlice.actions
export default UOMSlice.reducer