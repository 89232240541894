import { createAsyncThunk } from "@reduxjs/toolkit";
import * as APIS from "../../constant/CommonApiUrl"
import { API_SERVICE } from "../../Services/CommonApi";
import axios from "axios";
export const getTypeBasedOnType = createAsyncThunk('typemaster/getTypeBaseOnType',
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            let response = await Promise.all(
                arg.map(el => API_SERVICE.get(`${APIS.TYPE_BASED_ON_TYPE}${el}`, "", "", { cancelToken: source.token }))
            )
            let result = response.map((res, i) => ({ [arg[i]]: res.data.result.data }))
            return Object.assign({}, ...result)
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })

