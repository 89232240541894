// import {userInfo} from "./index";
// import {toBase64} from "./Support";
import {API_SERVICE} from "../Services/CommonApi";
import exportFromJSON from "export-from-json";
import { exportCsv } from "../pages/PMS/Dashborad/Support";
const loginDetails = localStorage.getItem('loginInfo')!==null?JSON.parse(localStorage.getItem('loginInfo')):{}

export const setToLS = (key, value) => {
    window.localStorage.setItem(key, JSON.stringify(value));
}

export const getFromLS = key => {
    const value = window.localStorage.getItem(key);

    if (value) {
        return JSON.parse(value);
    }
}

export const ConvertJson=(arg)=>{
    let obj=arg.replace(/(^"|"$)/g, '')
    return JSON.parse(obj)
}

export const dateUTC = (date = new Date()) => new Date(new Date(date).toUTCString()).toISOString()

export const commonPayloadFields = {
    createdby: loginDetails?.first_name ? loginDetails.first_name : 'supplierAdmin',
    createddt: dateUTC(new Date()),
    updatedby: loginDetails?.first_name ? loginDetails.first_name : 'supplierAdmin',
    updateddt: dateUTC(new Date())
}

export const constructRFQdata = (prDetails, supplier = [], expDate, data, id = 0, details, edit = false,vessel) => {
    // debugger // eslint-disable-line
    const supplierPayload = []
    supplier.length > 0 && supplier.map((s) => {
        supplierPayload.push({sid: s.value})
    })
    const items = []
    data.length > 0 && data.map((v) => {
        items.push({
            id: id ? v.Did?v.Did:v.id: 0,
            rfQ_headid: edit ? v.RFQ_headid : 0,
            prDid: edit ? v.PRDid : v.id,
            product_id:v.product_id,
            unit:v.unit,
            product_type:v.product_type,
            item_status: edit ? v.Item_status : v.pistatus,
            isdisabled: "N",
            product_price: v.price,
            remarks: v.remarks ? v.remarks : '',
            ...commonPayloadFields
        })
    })
    return {
        id: id,
        rfqNo: id ? details.RFQNo : '',
        purReqId: prDetails.id?prDetails.id:prDetails.purReqId,
        supplier_id: supplierPayload,
        rfQ_status: "RFQ SENT",
        expiry_Date: dateUTC(expDate),
        unit:prDetails.unit,
        vessel_id: vessel,
        isdisabled: "N",
        ...commonPayloadFields,
        rfqDetailModel: items.length > 0 ? items : []
    }
}

export function _delete(obj, prop) {
    if (obj[prop] && ! obj[prop].length) delete obj[prop];
}

export const changeItemModalRFQ = (data) => {
    return data && data.length > 0 ? data.map((c) => {
        return Object.assign({}, {
            ...c,
            quantity: c.Quantity,
            unitPrice: c.q_rate,
            subtotal: c.Subtotal
        })
    }) : []
}

export const dashBoardData = [
    {
        "fromColor": "#057C73",
        "toColor": "#20F1BA",
        "id": "pr_new",
        "key": "purchase_request",
        "label": "PR"
    },
    {
        "fromColor": "#d2122e",
        "toColor": "#f48888",
        "id": "approve",
        "key": "pr_rejected",
        "label": "PR Rejected"
    },{
        "fromColor": "#224176",
        "toColor": "#1590E9",
        "id": "pr",
        "key": "po",
        "label": "QC Approval"
    },{
        "fromColor": "#018749",
        "toColor": "#50C878",
        "id": "pending",
        "key": "po_approve",
        "label": "Po Approve"
    },
    {
        "fromColor": "#223476",
        "toColor": "#6E92F0",
        "id": "rfq",
        "key": "rfq",
        "label": "RFQ"
    },
    {
        "fromColor": "#007fff",
        "toColor": "#73c2fb",
        "id": "Goods",
        "key": "mr",
        "label": "Goods Received"
    },
    {
        "fromColor": "#ffd700",
        "toColor": "#fff8cc",
        "id": "invoice",
        "key": "invoice",
        "label": "Invoice"
    },
    {
        "fromColor": "#057C73",
        "toColor": "#20F1BA",
        "id": "amendment",
        "key": "amendment",
        "label": "Amendment"
    }
]

export const constructCurrencyData = (data) => {
    const values = []
    data.countryid.map((v) => {
        if (v.country_code !== data.country_code) {
            values.push(
                {
                    id: 0,
                    defultcurrency_id: data.id,
                    currency_id: v.currency_id,
                    exchange_rate: Number(v.exchange_rate),
                    is_latest: "Y",
                    ...commonPayloadFields,
                }
            )
        } else {
            values.push(
                {
                    id: 0,
                    defultcurrency_id: data.id,
                    currency_id: data.id,
                    exchange_rate: 1,
                    is_latest: "Y",
                    ...commonPayloadFields,
                }
            )
        }
    })
    return {currencyrateModel :values }
}

export const constructFolderPayload = (data) => {
    return {
        id: data?.id ? data?.id : 0,
        inventory_id: data.inventory_id,
        doc_title: data.doc_title,
        doc_category: data.doc_category,
        department_id: Number(data.department_id),
        designation_id: data.designation_id.toString(),
        designationid: data.designation_id.toString(),
        vessel_list: data.vessel_list.toString(),
        view_user: data.view_user.toString(),
        link_checklist: data.link_checklist.toString(),
        doc_remarks: data.doc_remarks,
        isdisabled: "N",
        inventory_name: data.inventory_name,
        doc_date: dateUTC(data.doc_date),
        effective_date: dateUTC(data.effective_date),
        major_version: data?.major_version,
        minor_version: data?.minor_version,
        revision_version: data?.revision_version,
        ...commonPayloadFields
    }
}
export const constructEDOCPayload = (data) => {
    return {
        id: 0,
        type: 'EDOCUMENT',
        levelid: 0,
        sublevelid: 0,
        parentid: data?.parentid,
        subparentid: 0,
        name: data?.name,
        vessel_id: data?.vessel_id,
        index_id: 0,
        isdisabled: "N",
        folder_type: data?.folder_type,
        folder_apporvalby: data?.folder_apporvalby,
        documnetcontroller_id: data?.documnetcontroller_id,
        authorizeduser_id: data?.authorizeduser_id.toString(),
        viewuser_id: data?.viewuser_id.toString(),
        remarks: data?.remarks,
        ...commonPayloadFields
    }
}

export function getExtension(filepath){
    return filepath.split("?")[0].split("#")[0].split('.').pop();
}
const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

export function randomText (length) {
    let result = ' ';
    const charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
}

export function stringCapitalize(str='') {
    str = str.replace('-', ' ')
    const words = str.split(" ");

    return words.map((word) => {
        return word[0].toUpperCase() + word.substring(1);
    }).join(" ");
}

export function removeEmptyKeys(obj = {}) {
    // const cleanEmpty = obj => Object.entries(obj)
    //     .map(([k,v])=>[k,v && typeof v === "object" ? cleanEmpty(v) : v])
    //     .reduce((a,[k,v]) => (v == null ? a : (a[k]=v, a)), {});

    return Object.entries(obj).reduce((a,[k,v]) => (v ? (a[k]=v, a) : a), {});
}

export const constructInitialRequest = (data) => {
    return {
        id: data?.id ? data?.id : 0,
        inventory_id: data?.inventory_id ? data?.inventory_id : 0, //tree id
        document_id: data?.document_id ? data?.document_id : 0, //doc row id
        request_no: data?.request_no ? data?.request_no : '', //db
        request_date: dateUTC(), //db
        user_id: data?.user_id ? data?.user_id : 0, // user_id
        request_status: data?.request_status ? data?.request_status : 'pending',
        changerequest_id: data?.changerequest_id,
        review_status: data?.review_status ? data?.review_status : '', //update
        review_comment: data?.review_comment ? data?.review_comment : "",
        review_remarks: data?.review_remarks ? data?.review_remarks : "",
        review_date: dateUTC(), //update
        remarks: data?.remarks,
        vessel_id: 0,
        isdisabled: "N",
        ...commonPayloadFields
    }
}

export const constructComments = (data) => {
    return {
        id: 0,
        screen_refno: data?.screen_refno ? data?.screen_refno : '',
        screen_refid: data?.id ? data?.id : 0,
        module: "E-DOC",
        sub_module: "DOCUMENT",
        comments: data?.comments ? data?.comments : '',
        user_id: data?.user_id,
        tag_name: "",
        vessel_id: 0,
        isdisabled: "N",
        ...commonPayloadFields,
        repository: []
    }
}

export const constructCheckInOutPayload = (data) => {
    return {
        id: data?.id ? data?.id : 0,
        inventory_id: data?.inventory_id ? data?.inventory_id : 0,
        document_id: data?.document_id ? data?.document_id : 0,
        request_no: data?.request_no ? data?.request_no : '',
        request_date: dateUTC(),
        user_id: data?.user_id,
        review_status: data?.review_status ? data?.review_status : 'Change in progress',
        remarks: data?.request_remarks ? data?.request_remarks : '',
        isdisabled: data?.isdisabled === 'Y' ? data?.isdisabled : "N",
        checkinout_status: data?.checkinout_status,
        checkinout_date: dateUTC(),
        version_no: data?.version_no,
        version_type: data?.version_type ? Number(data?.version_type) : 0,
        file_status: data?.file_status ? data?.file_status : '',
       ...commonPayloadFields
    }
}

export const downloadFile = (fileUrl, name) => {
    // Create a temporary anchor element
    const link = document.createElement('a');
    link.href = fileUrl;
    link.target = '_blank';
    link.download = name;
    link.click();
    link.remove();
}

export function removeIdFromArray(array, id) {
    const index = array.indexOf(id);
    if (index > -1) {
        array.splice(index, 1);
    }
}

export const exportCSVAPI = async (payload, url, fileName) => {
    await API_SERVICE.get(url, payload).then((res) => {
        if (res.data.result.status) {
            // const exportType = exportFromJSON.types.csv
            let data = [...res.data.result.data]
            exportCsv(data, fileName)
        }
    });
}

export const imageFormat = ['image/webp', 'image/jpeg', 'image/gif', 'image/png', 'image/avif']