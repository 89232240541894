import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import {toast} from "react-toastify"
import {GET_SUPPLIER, SAVE_SUPPLIER} from "../constant/CommonApiUrl"
import {API_SERVICE} from "../Services/CommonApi";
import {ConvertJson} from "../helper";

const ADD_SUPPLIER = 'supplier/addSupplierApi'
const UPDATE_SUPPLIER = 'supplier/updateSupplierApi'
const FETCH_SUPPLIER = 'supplier/fetchSupplierApi'
const DELETE_SUPPLIER = 'supplier/deleteSupplierApi'
const GET_SUPPLIER_ID='supplier/getSupplierbyId'

export const addSupplierApi =createAsyncThunk(ADD_SUPPLIER,
    async(args, thunkAPI)=>{
        try{
            let response = await API_SERVICE.post(SAVE_SUPPLIER, args['data'], "CREATE");
            response = response.data.result.data[0];
            thunkAPI.dispatch(fetchSupplierApi())
            return response;
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
})

export const updateSupplierApi = createAsyncThunk(UPDATE_SUPPLIER,
    async(args, thunkAPI)=>{
        try{
            let response = await API_SERVICE.post(SAVE_SUPPLIER, args['data'], "UPDATE");
            response = response.data.result.data[0];
            thunkAPI.dispatch(fetchSupplierApi())
            return response;
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
})

export const deleteSupplierApi = createAsyncThunk(DELETE_SUPPLIER, 
    async (args, thunkAPI) => {
        try{
            let toDelete = thunkAPI.getState().root.supplier.list.find(ele => ele.id === args['id']);           
            const response = await API_SERVICE.post(SAVE_SUPPLIER, {...toDelete,port_id:ConvertJson(toDelete.port_id),isdisabled: "Y"});
            thunkAPI.dispatch(fetchSupplierApi())
            return response.data.result.data[0];
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
    }
)

export const fetchSupplierApi = createAsyncThunk(FETCH_SUPPLIER,
    async(arg,thunkAPI)=>{
        try{
            let response = await API_SERVICE.get(GET_SUPPLIER);
            response = response.data.result.data;
            return response
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
    }
)
export const getSupplierbyId = createAsyncThunk(GET_SUPPLIER_ID,
    async(arg,thunkAPI)=>{
        try{
            return await API_SERVICE.get(`${GET_SUPPLIER}?ID=${arg}`)
        }catch(err){
            return thunkAPI.rejectWithValue(err.message)
        }
    }
)

const initialState={
    list:[],
    editSupplier:{},
    supplier_detail:{},
    portid:[],
    isUpdated: true    
}
export const SupplierSlice = createSlice({
    name:'supplier',
    initialState,
    reducers:{
        loadEditSupplier:(state,action)=>{
            console.log(action.payload)
            state.editSupplier={...action.payload, port_id:ConvertJson(action.payload.port_id)}
        },
        clearEditSupplier:(state,action)=>{
            state.editSupplier={}
        },         
        resetSupplier:()=>initialState
    },
    extraReducers:(builder)=>{
        builder
        .addCase(addSupplierApi.fulfilled,(state,action)=>{
            state.list=[...state.list,action.payload];
            toast.success('Succesfully added');
        })
        .addCase(updateSupplierApi.fulfilled,(state,action)=>{
            state.list = state.list.map(x => x.id === action.payload.id ? action.payload: x);
            toast.success('Succesfully updated');
        })
        .addCase(fetchSupplierApi.fulfilled, (state, action) => {
            state.list = action.payload;
            state.isUpdated = false;
        })
        .addCase(getSupplierbyId.fulfilled, (state, action) => {
            if(action.payload.data.result.status){
                let obj=action.payload.data.result.data[0]                             
                state.supplier_detail={...obj,portid:ConvertJson(obj.portid)}
                state.portid=ConvertJson(obj.portid)
            }else toast.error(action.payload.data.result.message)
        })
        .addCase(deleteSupplierApi.fulfilled, (state, action) => {
            state.list=state.list.filter(x=>x.id!==action.payload.id);
            toast.success('Succesfully deleted');
        })
    }   
})
export const {loadEditSupplier, clearEditSupplier, resetSupplier} = SupplierSlice.actions
export default SupplierSlice.reducer