import React, { useEffect, useState } from 'react';
import { Button, Col, Form, FormControl, FormLabel, FormSelect, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { changeDetails, editPOQuantity, getPoDetails } from '../../features/POSlice';
import { dateFormat, dateTimeToDate, localtoISO } from '../../helper/dateUtil'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addDays } from 'date-fns';
import { commonPayloadFields, dateUTC } from '../../helper/storage';
import { toast } from 'react-toastify';
import returnImg from "../../assets/images/return.svg";
import { resetAttach } from "../../features/attachmentsSlice";
import { fetchCountryDropdown, getCountryById } from "../../features/commonSlice";
import { getWObyId, saveWO } from '../../features/THUNK/workOrderThunk';
import WOConsumption from './WOConsumption';
import { getPMSStock } from '../../features/pmsSlice';
import { removeSearchParams } from '../../helper/Support';
import { generatePMSStockPayload } from '../../helper';
import WOCompletion from './WOCompletion';
import { PMS_MAINTENANCE } from '../../constant/CommonInitialvalue';
import {hasPermission} from "../../helper/permissions";

function WOForm(props) {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const params = useParams()
    const [searchParams, _] = useSearchParams()
    const [state, setState] = useState(PMS_MAINTENANCE)
    const [podate, setPODate] = useState(new Date())
    const [location, setLocation] = useState()
    const [location_id, setLocationId] = useState()
    const [supplier, setSupplier] = useState()
    const [due, setDue] = useState(addDays(new Date(), 1))
    const [port, setPort] = useState('')
    const [portDisabled, setPortDisabled] = useState(true)
    const [note, setRemark] = useState()
    const [searchData, setSearchData] = useState([])
    const [searchText, setSearchText] = useState('')
    const vessel_id = useSelector((state) => state.root.common.vessel_detailByID.id)
    const { contract_supplier } = useSelector((state) => state.root.PO) //AFTER valid data getting to map item details
    const { orderDetails } = useSelector((state) => state.root.WO)
    const { PODetail } = orderDetails
    const is_contract = useSelector((state) => state.root.PO.contract_detail.is_contract) //AFTER valid data getting to map item details
    const contract_supplierOption = useSelector((state) => state.root.PO.contract_supplier.map(x => <option key={x.supplier_id} value={x.supplier_id}>{x.supplier_name}</option>))
    const { id } = useSelector((state) => state.root.login.loginDemo)
    const portList = useSelector((state) => state.root.common.country_byId)
    const country_list = useSelector((state) => state.root.common.country_list)
    const { repository } = useSelector((state) => state.root.ATTACHMENT)
    const { part_stock, oil_stock } = useSelector((state) => state.root.PMS)
    const {Process} = useSelector((state) => state.root.login.loginDemo);
    const permissions = Process.find((v) => v.menuname === 'Purchase' && v.modulename === 'WO Completion')
    const port_option = portList && portList.length > 0 && portList.map((x, i) => <option
        key={i} value={x.id}>{x.port_name}</option>)
    const country_option = country_list && country_list.length > 0 && country_list.map(x => <option key={x.id} value={x.id}>{x.country_name}</option>)

    useEffect(() => {
        if (Object.keys(orderDetails).length > 0) {
            dispatch(getCountryById(orderDetails.location_id)).unwrap()
                .then((res) => {
                    setLocation(orderDetails.supplylocation)
                    setLocationId(orderDetails.location_id)
                    setPODate(`${orderDetails.deleivery_date}Z`)
                    setPort(orderDetails.portid)
                    setRemark(orderDetails.note)
                });
            dispatch(getPMSStock(removeSearchParams({ equipment_id: orderDetails?.equipment_id, pms_id: orderDetails?.pms_id, location_id: 0 /* orderDetails?.location_id */ })))
        }
    }, [orderDetails]);

    const repositoryReset = () => {
        setState({ ...state, repository: [] })
    }

    useEffect(() => {
        //dispatch(getQCSummaryByID(params.id))
        dispatch(fetchCountryDropdown());
        /* if (searchParams.get('page') === 'amendment') {
            setPage('amendment')
            dispatch(getAmendmentByID(params.id))
        } else { */
        dispatch(getWObyId(params.id))
        //}

    }, [])

    const onChangeOrderDetails = (name, value) => {
        dispatch(changeDetails({ name, value }))
    }

    const onChangePort = (e) => {
        setPort(e.target.value)
        const val = portList.find(v => v.id === Number(e.target.value));
        onChangeOrderDetails('supplyport', val.port_name)
        onChangeOrderDetails('portid', Number(e.target.value))
    }
    const onChangeLocation = (e) => {
        setLocationId(e.target.value)
        setPortDisabled(false)
        const val = country_list?.length > 0 && country_list.find(el => el.id === Number(e.target.value))
        onChangeOrderDetails('supplylocation', val.country_name)
        onChangeOrderDetails('location_id', Number(e.target.value))
    }

    useEffect(() => {
        if (PODetail && PODetail.length > 0) {
            searchText ? onSearchField(searchText) : setSearchData(PODetail)
        }
    }, [PODetail])

    const onSearchField = (value) => {
        setSearchText(value)
        const res = PODetail.filter((obj) => JSON.stringify(obj).toLowerCase().includes(value.toLowerCase()))
        setSearchData(res)
    }
    useEffect(() => {
        if (location_id) {
            dispatch(getCountryById(location_id))
        }
    }, [location_id])

    const onSave = () => {
        let payload = { ...orderDetails, status: 'CLOSED', supplylocation: location, supplyport: port.toString(), note: note, PODetailModel: PODetail, isdisabled: 'N', ...commonPayloadFields }
        delete payload.PODetail
        let stock = [...part_stock, ...oil_stock]
        stock = stock.filter(x => x.qty > 0).map(el => generatePMSStockPayload({ ...el, item_id: el.id, vessel_id, ref_no: orderDetails.pureq_id, ref_id: orderDetails.pms_id, remarks: "PMS COMPLETION" }))
        let maintenanceDetail = [{ ...state, id: 0, head_id: params.id, status_id: "DONE", defect: "YES", completion_date: localtoISO(state.completion_date), isdisabled: "N", ...commonPayloadFields }]
        dispatch(saveWO({ ...payload, maintenanceDetail, stock, repository })).unwrap().then(res => {
            if (res.data.result.status) {
                dispatch(resetAttach())
                navigate('/work_order')
            } else {
                toast.error(res.data.result.message)
            }
        })
        //console.log({ ...payload, maintenanceDetail, stock, repository },'payload');
    }
    const totalValue = searchData.reduce((n, { ex_sub_total }) => n + ex_sub_total, 0)

    return (
        <div >
            <Row className='mb-3'>
                <Col md={4} className="contentWrapper">
                    <Button variant="primary" className='btn blueOval'>Work Order</Button>{' '}
                    {/*<span className='qcTitle'>{page!=='amendment'?'Edit PO':'View PO'}</span>*/}
                </Col>
                <Col md={8} className='d-flex justify-content-end'>
                    {orderDetails?.status !== 'CLOSED' && hasPermission('add', permissions?.permission)  && <Button variant="primary" className='btnSave' onClick={() => onSave()} >Completion</Button>}
                    <img src={returnImg} style={{ cursor: 'pointer' }} role='presentation' alt={'share'} className='returnBtn' onClick={() => navigate('/work_order')} />
                </Col>
            </Row>
            <div className="container-box">
                <div className="left">
                <div className='whiteWrapper border-0 p-2 rfq-page'>
                    <Row className='mt-3 align-items-center'>
                        <Col md={5}><FormLabel className='me-3 textGray-md'>PO No</FormLabel></Col>
                        <Col md={7}><div className='textDarkBlue-lt'>{orderDetails.reference_no}</div></Col>
                    </Row>
                    <Row className='mt-3 align-items-center'>
                        <Col md={5}><FormLabel className='me-3 textGray-md'>PO Date <span className={'required'}>*</span></FormLabel></Col>
                        <Col md={7}>
                            <div className='textLightBlue'>
                                {/* <FormControl disabled defaultValue={moment(new Date(orderDetails.deleivery_date)).format('DD/MM/yyyy')}/> */}
                                {/*{orderDetails.deleivery_date&&<DatePicker*/}
                                {/*    selected={new Date(podate)}*/}
                                {/*    className='form-control'*/}
                                {/*    onChange={(val)=>setPODate(localtoISO(val))}*/}
                                {/*    disabled*/}
                                {/*/>}*/}
                                {orderDetails.deleivery_date && <div className='textLightBlue'>{dateFormat(new Date(podate))}</div>}
                                {/*  <input type={'date'} onChange={(e)=>setPODate(e.target.value)}/> */}
                            </div>
                        </Col>
                    </Row>
                    <Row className='mt-3 align-items-center'>
                        <Col md={5}><FormLabel className='me-3 textGray-md'>Status</FormLabel></Col>
                        <Col md={7}><div className='textBlue-md'><span className={'status stsNew badge rounded-pill bg-primary'}>{orderDetails.status}</span></div></Col>
                    </Row>
                    <Row className='mt-3 align-items-center'>
                        <Col md={5}><FormLabel className='me-3 textGray-md'>Exchange Rate</FormLabel></Col>
                        <Col md={7}><div className='textBlue-md '><FormControl type={'text'} disabled={true} defaultValue={orderDetails.ex_rate} /></div></Col>
                    </Row>
                    <Row className='mt-3 align-items-center'>
                        <Col md={5}><FormLabel className='me-3 textGray-md'>Currency</FormLabel></Col>
                        <Col md={7}><div className='textBlue-md '>{orderDetails.currency}</div></Col>
                    </Row>
                    {is_contract ?
                        <Row className='mt-3 align-items-center'>
                            <Col md={6}  >
                                <Form.Group className="me-2 textGray-dr" controlId="formBasicEmail">
                                    <Form.Label>Supplier Name <span className={'required'}>*</span></Form.Label>
                                    <FormSelect onChange={(e) => setSupplier(e.target.value)} disabled>
                                        <option value=''>select</option>
                                        {contract_supplierOption}
                                    </FormSelect>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="me-2 textGray-dr" controlId="formBasicEmail">
                                    <Form.Label>Due date</Form.Label>
                                    <DatePicker
                                        selected={due}
                                        minDate={addDays(new Date(), 1)}
                                        //maxDate={new Date()}
                                        onChange={(date) => setDue(date)}
                                        dateFormat="dd/MM/yyyy"
                                        className='form-control me-2'
                                        disabled
                                    />
                                </Form.Group>
                            </Col>
                        </Row> : ''}
                    <Row className='mt-3 align-items-center'>
                        <Col md={6} >
                            <Form.Group className="me-2 textGray-dr" controlId="formBasicEmail">
                                <Form.Label>Supply Location <span className={'required'}>*</span></Form.Label>
                                <FormSelect name='location' value={location_id} className={'form-control'} onChange={(e) => { onChangeLocation(e) }}>
                                    <option>select</option>
                                    {country_option}
                                </FormSelect>
                                {/*<Form.Control  onChange={(e)=>setLocation(e.target.value)} defaultValue={location} />*/}
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="me-2 textLightBlue" controlId="formBasicEmail">
                                <Form.Label>Supply Port</Form.Label>
                                {
                                    portDisabled ? <Form.Control disabled defaultValue={orderDetails?.supplyport} /> : <FormSelect name='supply_port' value={port} className={'form-control'} onChange={(e) => { onChangePort(e) }}>
                                        <option>select</option>
                                        {port_option}
                                    </FormSelect>
                                }

                            </Form.Group>
                        </Col>
                    </Row>
                    {/* hide remarks column 
                    <Row className={'mt-3 align-items-center'}>
                        <Col md={12}>
                            <Form.Group className="" controlId="exampleForm.ControlTextarea1">
                                <Form.Label><b>Supplier Remarks</b></Form.Label>
                            </Form.Group>
                            <div>{orderDetails?.qoremark && orderDetails?.qoremark}</div>
                        </Col>
                    </Row>
                    <Row className='mt-3 align-items-center'>
                        <Col md={12} className=''>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Remarks</Form.Label>
                                <Form.Control as="textarea" rows={3} onChange={(e) => setRemark(e.target.value)} defaultValue={note} />
                            </Form.Group>
                        </Col>
                    </Row> */}
                    <hr className='hr textGray-md mb-3' />
                    <Row className='mt-3 align-items-center'>
                        <Col md={5}><FormLabel className='me-3 textGray-md'>Quotation No</FormLabel></Col>
                        <Col md={7}><div className='textBlue-lt '>{orderDetails.quote_number}</div></Col>
                    </Row>
                    <Row className='mt-3 align-items-center'>
                        <Col md={5}><FormLabel className='me-3 textGray-md'>Purchase Request Code</FormLabel></Col>
                        <Col md={7}><div className='textBlue-lt btn-link'>{orderDetails.pr_id}</div></Col>
                    </Row>
                    <Row className='mt-3 align-items-center'>
                        <Col md={5}><FormLabel className='me-3 textGray-md'>Purchase Request name</FormLabel></Col>
                        <Col md={7}><div className='textBlue-md '>{orderDetails.pr_name}</div></Col>
                    </Row>
                    <Row className='mt-3 align-items-center'>
                        <Col md={5}><FormLabel className='me-3 textGray-md'>PR Date</FormLabel></Col>
                        <Col md={7}><div><span className={'oval'}>{dateTimeToDate(orderDetails.pr_date)}</span></div></Col>
                    </Row>
                    <Row className='mt-3 align-items-center'>
                        <Col md={6}>
                            <Row>
                                <Col md={6}><FormLabel className='me-3 textGray-md'>Category</FormLabel></Col>
                                <Col md={6}><FormLabel className='me-3 textBlue-md'>{orderDetails.category}</FormLabel></Col>
                            </Row>
                        </Col>
                        <Col md={6}>
                            <Row>
                                <Col md={6}><FormLabel className='me-3 textGray-md'>PR Type</FormLabel></Col>
                                <Col md={6}><FormLabel className='me-3 textBlue-md'>{orderDetails.pr_type}</FormLabel></Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className='mt-3 align-items-center'>
                        <Col md={5}><FormLabel className='me-3 textGray-md'>Account/Budget Code</FormLabel></Col>
                        <Col md={7}><span className='me-3 textBlue-lt'>{orderDetails.account_name}</span></Col>
                    </Row>

                </div>
                </div>
                <div className="right">
                    <div className='whiteWrapper border-0 pt-3'>
                        <div className='mx-3'>
                            <Row>
                                <Col md={6}> {/** items details not getting GetCompareQuotation */}
                                    <Button varient="light" className='btnLightBlue btnRounded'>Items details</Button>{' '}
                                </Col>
                                <Col md={6}>
                                    <input type={'text'} className={'form-control'} placeholder={'search...'} onChange={(e) => onSearchField(e.target.value)} />
                                </Col>
                            </Row>
                        </div>
                        <Table responsive className="stripeTbl itemTable mt-3 comparisonTable">
                            <thead>
                                <tr >
                                    <th />
                                    <th>Item_name</th>
                                    <th>type</th>
                                    <th >code</th>
                                    <th >unit</th>
                                    <th className='text-center'>quantity</th>
                                    <th className='text-center'>unit price</th>
                                    <th className='text-right'>total price</th>
                                    <th className='text-right'>Base price</th>
                                    <th className='text-right'>Base total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {searchData.map((x, i) => <tr key={i}>
                                    <td>{++i}</td>
                                    <td>{x.product_name}</td>
                                    <td>{x.product_type}</td>
                                    <td>{x.product_code}</td>
                                    <td>{x.unit}</td>
                                    <td className='text-center' style={{ width: '100px' }}>
                                        <FormControl disabled defaultValue={x.quantity} onBlur={(e) => dispatch(editPOQuantity({ ...x, quantity: e.target.value }))} />
                                    </td>
                                    <td className='text-center'>{x.net_unit_cost}</td>
                                    <td className='text-right'>{x.subtotal/* Number(x.net_unit_cost)*Number(x.quantity) */}</td>
                                    <td className='text-right'>{x.ex_price}</td>
                                    <td className='text-right'>{x.ex_sub_total}</td>
                                </tr>)}

                            </tbody>
                        </Table>
                    </div>
                    <Col >
                        <Row className='mt-4 align-items-center'>
                            <Col md={9} className='d-flex justify-content-end textPrime'>Total</Col>
                            <Col md={3} className='d-flex justify-content-start textPrime'>
                                <FormControl className='text-right' value={totalValue} disabled />
                            </Col>
                        </Row>
                    </Col>
                    <div className='mt-3 p-2'>
                        <WOCompletion poId={params.id} state={state} setState={setState} repositoryReset={repositoryReset} />
                    </div>
                    <div className='mt-3 p-2'>
                        <WOConsumption />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WOForm;