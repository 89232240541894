import { createAsyncThunk } from "@reduxjs/toolkit";
import * as ACTION from "../../constant/Actions"
import * as APIS from "../../constant/CommonApiUrl";
import { API_SERVICE } from "../../Services/CommonApi";
import axios from "axios";
export const getWOList = createAsyncThunk(ACTION.WO_LIST,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(arg ? `${APIS.WO_LIST}?${arg}&vessel_id=${vessel_name === 'OFFICE' ? 0 : id}` : `${APIS.WO_LIST}?vessel_id=${vessel_name === 'OFFICE' ? 0 : id}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })

export const saveWO = createAsyncThunk(ACTION.WO_SAVE,
    async (arg, thunkAPI) => {
        try {
            return API_SERVICE.post(APIS.WO_SAVE, arg);
        } catch (err) {
            return thunkAPI.rejectWithValue(err)
        }
    }
)
export const getWObyId = createAsyncThunk(ACTION.GET_WO_COMPLETION,
    async (arg, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const { id, vessel_name } = thunkAPI.getState().root.common.vessel_detailByID
            let response = await API_SERVICE.get(`${APIS.GET_WO_COMPLETION}?id=${arg}&vessel_id=${id}`, "", "", { cancelToken: source.token })
            return response
        } catch (err) {
            thunkAPI.rejectWithValue(err)
        }
    })