import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Modal, Row, Image, FormControl, Form, FormCheck } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import FridayMarine from '../../../assets/images/FridayMarine.svg'
import { useDispatch } from 'react-redux';
import { getAllData, saveAllData } from '../../../features/globalSlice';
import { SASS_MASTER } from '../../../constant/CommonApiUrl';
import { DottedButton, FixedTableHeight, FormControlColor, PmsLoginTable, ScrollableTableBody, UnderlinedButton } from '../STYLE/PMSStyle';
import PlusIcon from '../../../assets/images/Plus.svg';
import CirclePlusIcon from '../../../assets/images/CirclePlusGray.svg';
import ReactDatePicker from 'react-datepicker';
import { CustomDatePickerInput } from '../../SAFETY/Support';
import { FaPen, FaTrash } from 'react-icons/fa';
import PMSPagination from './PMSPagination';
import SimpleBar from 'simplebar-react';
import useDebounce from '../../../hooks/useDebounce';
import { toast } from 'react-toastify';
import { nonIdenticalObjects } from './Support';
import { PmsDetailStyle, VesselTypeStyle } from './Style';

function index() {
    const dispatch = useDispatch()
    const loginDetails = useSelector((state) => state.root.login.loginDemo);
    const { vessel_name, vessel_id, vessel_type, vessel_typeId } = useSelector((state) => state.root.common.vessel_detailByID);
    const { saas_login_detail = [] } = loginDetails
    //const [show, setShow] = useState(true)
    const [show, setShow] = useState(saas_login_detail[0]?.is_first_login ? true : false)
    const [partdata, setPartData] = useState([])
    const [partdataBackup, setPartDataBackup] = useState([])
    const [partdataList, setPartDataList] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState("")
    const [totalPages, setTotalPages] = useState(0)
    const [checkedAll, setCheckedAll] = useState(false)
    const [critical, setCritical] = useState(true)
    const [allDate, setAllDate] = useState(null)
    useEffect(() => {
        saas_login_detail.length > 0 && dispatch(getAllData({ partList: `${SASS_MASTER}GetPMSDataAfterFirstLogin?tenant_id=${saas_login_detail[0]?.tenant_id}&rowPerpage=${rowsPerPage}&currentPage=${currentPage}` })).unwrap().then(res => {
            let result = [...res.partList]/* .map(el => ({ ...el, checked: checkedAll })) */
            setPartDataBackup(result)
            setPartDataList(result)
            setTotalPages(result[0]?.total_pages)
            setAllDate(null)
        })
    }, [rowsPerPage, currentPage])
    useEffect(() => {
        if (critical) {
            const filteredData = partdataBackup.filter(el => el.critical === "Yes");
            setPartDataList(filteredData);
        } else {
            setPartDataList(partdataBackup);
        }
    }, [critical])

    useDebounce(() => onPartSearch(), 500, [searchTerm])
    const handleClose = () => setShow(!show)
    const skipStage = () => {
        dispatch(getAllData({ statusCheck: `${SASS_MASTER}GetUserFirstLoginStatusUpdate?email=${loginDetails?.Email}` })).unwrap().then(res => handleClose())
    }
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        //setCheckedAll(false)
    };
    const handleRowsPerPageChange = (perPage) => {
        setRowsPerPage(perPage);
        //setCheckedAll(false)
    };
    const handlePartData = (arg) => {

        let filterArr = [...partdata];
        let currentIndex;
        console.log(arg, 'handlePartData', filterArr.findIndex(el => el.id === arg.id))
        currentIndex = filterArr.findIndex(el => el.id === arg.id);
        currentIndex !== -1 ? filterArr[currentIndex] = { ...filterArr[currentIndex], ...arg } : filterArr.push(arg);
        setPartData([...filterArr]);
    };
    const onPartSearch = () => {
        if (searchTerm !== '') {
            const filteredData = partdataBackup.filter(el => el.maintenance_name.toLowerCase().includes(searchTerm.toLowerCase()));
            setPartDataList(filteredData);
        } else {
            setPartDataList(partdataBackup);
        }
    };
    const onCriticalSearch = () => setCritical(!critical)
    const handleCheckAll = (checked) => {
        /* let pdlIndex = partdataList.map(el => el.id)
        let pdIndex = partdata.map(el => el.id)
        console.log(pdlIndex, pdIndex, pdlIndex.every(el => pdIndex.includes(el))) */
        if (checked) {
            let partdataListArray = [...partdataList].map(el => ({ ...el, checked, initial_date: allDate }))
            //setPartDataList(partdataListArray)
            let filterArray = nonIdenticalObjects(partdataListArray, partdata).map(el => ({ ...el, checked }))
            console.log(filterArray, partdata, 'filter part')
            setPartData([...partdata, ...filterArray]);
        } else {
            setPartData(partdataBackup)
        }
        setCheckedAll(checked)
    }
    const handleAllDate = (val) => {
        let partdataListArray = [...partdata].map(el => ({ ...el, initial_date: val }))
        setPartData(partdataListArray);
        setAllDate(val)
    }
    const confirmPMS = () => {
        let payload = partdata.filter(el => el.checked === true)
        if (payload.every(el => el.checked === true && el?.initial_date)) {
            console.log(payload, 'befor')
            payload = payload.map(el => (
                {
                    "tenant_id": saas_login_detail[0]?.tenant_id,
                    "email": loginDetails?.Email,
                    "id": 0,
                    "maintenance_name": el.maintenance_name,
                    "equipment_name": el.equipment_name,
                    "inv_tree_id": el.inv_tree_id,
                    "frequency": el.frequency,
                    "frequency_type": el.frequency_type,
                    "critical": el.critical,
                    "last_done_date": el.initial_date?.toISOString(),
                    "created_id": loginDetails?.id,
                    "procedure": el.procedure,
                    "designation": el.designation
                }
            ))
            payload.length === 0 && toast.error("Please select atleast one PMS")
            //console.log(payload)
            payload.length > 0 && dispatch(saveAllData({ url: `${SASS_MASTER}AddEditPMSAfterFirstLogin`, payload })).unwrap().then(res => {
                if (res.data.result.status) {
                    const loginDemo = localStorage.getItem('loginInfo') !== null ? JSON.parse(localStorage.getItem('loginInfo')) : {}
                    let saas_login_detail = loginDemo?.saas_login_detail.map(el => ({ ...el, is_first_login: false }))
                    let storageLoginDemo = { ...loginDemo, saas_login_detail }
                    localStorage.setItem("loginInfo", JSON.stringify(storageLoginDemo))
                    handleClose()
                }
            })
        } else toast.error('Please select checkbox/initial date');
    }
    return (
        <div>
            <Modal show={show} onHide={handleClose} centered backdrop="static" keyboard={false} dialogClassName='modal-90w' >
                <Modal.Body style={{ backgroundColor: '#F5F5F5' }} className='p-0'>
                    <div className='d-flex flex-row justify-content-between gap-1'>
                        <Card style={{ width: '40%' }} className='border-0'>
                            <Card.Body className="d-flex flex-column justify-content-center align-items-center vh-50">
                                <div className='p-3'>
                                    <div className='p-4' style={{ position: 'absolute', top: 0, left: 0, right: 0 }}>
                                        <Image src={FridayMarine} alt='image' />
                                    </div>
                                    <PmsDetailStyle className='p-3 d-flex flex-column' >
                                        <span className='mb-2' style={{ color: "#252525", fontWeight: 500, /* textShadow: "1px 1px 2px rgba(0, 0, 0, 0.5)" */ }}>PMS Details</span>
                                        <FormControlColor lineHeight={'40px'} color='#0F172A' /* weight='800' */ /* textShadow boxShadow */ value={vessel_name} readOnly />
                                    </PmsDetailStyle>
                                    <VesselTypeStyle className='p-3 d-flex flex-column' >
                                        <span className='mb-2 text-muted'>Vessel Type</span>
                                        <FormControlColor lineHeight={'40px'} color='#0F172A' value={vessel_type} readOnly />
                                    </VesselTypeStyle>
                                    <div className='p-4' style={{ position: 'absolute', bottom: 0, left: 0, right: 0 }}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Button variant="primary" onClick={skipStage} style={{ background: '#FFF', color: '#021F41' }}>
                                                Skip this Stage
                                            </Button>
                                            <Button variant="primary" onClick={confirmPMS} style={{ background: '#003BC6' }}>
                                                Confirm PMS List
                                            </Button>
                                        </div>
                                    </div>

                                </div>
                            </Card.Body>
                        </Card>
                        <Card style={{ width: '60%', height: '80vh', boxShadow: "-10px 0 10px -10px rgba(40, 134, 245, 0.14)" }} className='border-0'>
                            <Card.Body >
                                <Row className='p-3'>
                                    <Col xs={12} className='d-flex flex-row justify-content-between align-items-center'>
                                        <UnderlinedButton textColor="#0F172A" lineColor="#68ADFE" textWeight={600} lineWeight={2} >
                                            {vessel_name}
                                        </UnderlinedButton>
                                        {/* <UnderlinedButton textColor="#0F172A" lineColor="#68FE77" lineWeight={2}>
                                            <Image src={PlusIcon} alt="img" /> Add PMS
                                        </UnderlinedButton> */}
                                    </Col>
                                    <Col xs={12} className='mt-3 d-flex flex-row  align-items-center gap-3'>
                                        <FormControlColor size='sm' style={{ width: '250px' }}
                                            placeholder="Search Maintenance Name"
                                            onChange={(e) => setSearchTerm(e.target.value)} />
                                        <DottedButton size="sm" variant="none" onClick={() => { onCriticalSearch() }}>
                                            <Image src={CirclePlusIcon} alt="img" /> Critical
                                        </DottedButton>
                                        {checkedAll && <span style={{ width: '150px' }}>
                                            <ReactDatePicker
                                                className='form-control'
                                                selected={allDate}
                                                onChange={(val) => handleAllDate(val)}
                                                customInput={<CustomDatePickerInput iconPosition="left" />}
                                                dateFormat={'dd/MM/yyyy'}
                                                isClearable={true}
                                            />
                                        </span>}
                                    </Col>
                                    <Col xs={12} className='mt-3 '>
                                        
                                            <FixedTableHeight >
                                            <ScrollableTableBody>
                                                <PmsLoginTable responsive>
                                                    <thead className='text-center'>
                                                        <th><FormCheck checked={checkedAll} onChange={(e) => handleCheckAll(e.target.checked)} /></th>
                                                        <th>Maintenance Name</th>
                                                        <th>Equipment Name</th>
                                                        <th>Frequency</th>
                                                        <th>Initial Date</th>
                                                        <th>Critical</th>
                                                        <th>Options</th>
                                                    </thead>
                                                    <tbody className='text-center'>
                                                        {
                                                            partdataList?.map(el => <tr key={el.id}>
                                                                <td><FormCheck checked={partdata.some(v => v.id === el.id && v.checked)} /* disabled={checkedAll} */ onChange={(e) => handlePartData({ ...el, checked: e.target.checked })} /></td>
                                                                <td style={{ color: '#101828' }}>{el.maintenance_name}</td>
                                                                <td>{el.equipment_name}</td>
                                                                <td>{el.frequency} {el.frequency_type}</td>
                                                                <td style={{ width: '150px' }}>
                                                                    <ReactDatePicker
                                                                        className='form-control'
                                                                        selected={partdata.filter(v => v.id === el.id)[0]?.initial_date}
                                                                        onChange={(val) => handlePartData({ ...el, initial_date: val, checked: partdata.some(v => v.id === el.id && v.checked) })}
                                                                        customInput={<CustomDatePickerInput iconPosition="left" />}
                                                                        dateFormat={'dd/MM/yyyy'}
                                                                        isClearable={true}
                                                                    />
                                                                </td>
                                                                <td>{el.critical}</td>
                                                                <td>
                                                                    <div className='d-flex flex-row justify-content-center gap-2'>
                                                                        <FaPen />
                                                                        <FaTrash />
                                                                    </div>
                                                                </td>
                                                            </tr>)
                                                        }
                                                    </tbody>
                                                </PmsLoginTable>  
                                                </ScrollableTableBody>                                              
                                            </FixedTableHeight>
                                            <PMSPagination
                                                    currentPage={currentPage}
                                                    totalPages={totalPages}
                                                    rowsPerPage={rowsPerPage}
                                                    setCheckedAll={setCheckedAll}
                                                    onPageChange={handlePageChange}
                                                    onRowsPerPageChange={handleRowsPerPageChange} />
                                        
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default index