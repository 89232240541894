import { createSlice } from "@reduxjs/toolkit";
import { getMaintenanceReportHead, getMaintenanceReportId, getMaintenanceReportType, getPMSMaintenanceReport } from "./THUNK/pmsReportThunk";
import { ValidateResponse } from "../Services/Support";
import { ConvertJson } from "../helper";
const initialState = {
    maintenance_list_backup:[],
    maintenance_list: [],
    maintenance_detail: {
        maintenanceDetail: [],
        unschedule_maintenanceDetail: [],
        jobcard: [],
        jobcard_repository: [],
        maintenanceDetail_repository: [],
        repository: [],
        part_required: [],
        oil_required: []
    },
    filter_type:[],
    filter_head:[],
}
const maintenanceReportSlice = createSlice({
    name: 'MAINTENANCE_REPORT',
    initialState,
    reducers: {        
        clearMaintenanceReport: (state, action) => {
            state.maintenance_list = []
        },
        searchEquipmentName: (state, action) => {
            if (action.payload !== "") {
                let filterData = []
                state.maintenance_list_backup.map(el => el.equipmentname.toLowerCase().search(action.payload.toLowerCase()) !== -1 && filterData.push(el))
                state.maintenance_list = filterData
            } else {
                state.maintenance_list = state.maintenance_list_backup
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPMSMaintenanceReport.fulfilled, (state, action) => {
                state.maintenance_list = ValidateResponse(action.payload)
                state.maintenance_list_backup = ValidateResponse(action.payload)
            })
            .addCase(getMaintenanceReportHead.fulfilled, (state, action) => {
                state.filter_head = ValidateResponse(action.payload)
            })
            .addCase(getMaintenanceReportType.fulfilled, (state, action) => {
                state.filter_type = ValidateResponse(action.payload)
            })
            .addCase(getMaintenanceReportId.fulfilled, (state, action) => {
                let response = ValidateResponse(action.payload)
                let obj = response[0]
                obj.maintenanceDetail = obj.maintenanceDetail ? ConvertJson(obj.maintenanceDetail) : []
                obj.unschedule_maintenanceDetail = obj.unschedule_maintenanceDetail ? ConvertJson(obj.unschedule_maintenanceDetail) : []
                obj.jobcard = obj.jobcard ? ConvertJson(obj.jobcard) : []
                obj.jobcard_repository = obj.jobcard_repository ? ConvertJson(obj.jobcard_repository) : []
                obj.maintenanceDetail_repository = obj.maintenanceDetail_repository ? ConvertJson(obj.maintenanceDetail_repository) : []
                obj.repository = obj.repository ? ConvertJson(obj.repository) : []
                obj.part_required = obj.part_required ? ConvertJson(obj.part_required) : []
                obj.oil_required = obj.oil_required ? ConvertJson(obj.oil_required) : []
                obj.rescheduled = obj.rescheduled ? ConvertJson(obj.rescheduled) : []
                obj.unscheduled = obj.unscheduled ? ConvertJson(obj.unscheduled) : []
                obj.defect_detail = obj.defect_detail ? ConvertJson(obj.defect_detail) : []
                state.maintenance_detail = obj
            })
        /* .addCase(saveRunningHour.fulfilled, (state, action) => {
            AddEditResponse(action.payload)
        }) */
    }
})
export const { clearMaintenanceReport,searchEquipmentName} = maintenanceReportSlice.actions
export default maintenanceReportSlice.reducer