import { createAsyncThunk } from "@reduxjs/toolkit";
import * as ACTION from "../../constant/Actions"
import * as APIS from "../../constant/CommonApiUrl";
import { API_SERVICE } from "../../Services/CommonApi";
export const getMaintenanceHour = createAsyncThunk(ACTION.MAINTENANCE_HOUR_SUMMARY,
    async (arg, thunkApi) => {
        try {
            const { id, vessel_name } = thunkApi.getState().root.common.vessel_detailByID
            return await API_SERVICE.get(arg ? `${APIS.MAINTENANCE_HOUR_SUMMARY}?${arg}` : `${APIS.MAINTENANCE_HOUR_SUMMARY}?vessel_id=${id}`)
        } catch (err) {
            thunkApi.rejectWithValue(err)
        }
    })
export const getMaintenanceHourbyID = createAsyncThunk(ACTION.MAINTENANCE_HOUR_BYID,
    async (arg, thunkApi) => {
        try {
            const { id, vessel_name } = thunkApi.getState().root.common.vessel_detailByID
            return await arg?.hours_id ? API_SERVICE.get(`${APIS.MAINTENANCE_HOUR_BYID}?hours_id=${arg.hours_id}`) : API_SERVICE.get(`${APIS.MAINTENANCE_HOUR_BYID}?id=${arg}`)
        } catch (err) {
            thunkApi.rejectWithValue(err)
        }
    })
export const saveRunningHour = createAsyncThunk(ACTION.SAVE_MAINTENANCE_HOUR,
    async (arg, thunkApi) => {
        try {
            const { id, vessel_name } = thunkApi.getState().root.common.vessel_detailByID
            return await API_SERVICE.post(APIS.SAVE_MAINTENANCE_HOUR, arg)
        } catch (err) {
            thunkApi.rejectWithValue(err)
        }
    })