import React, { useEffect, useRef, useState } from 'react'
import { Col, FormGroup, FormLabel, Modal, ModalBody, ModalHeader, ModalTitle, Row, Button, FormCheck, FormControl } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { clearRatingDetail, ratingDetailbyID, saveSupplierReview } from '../../features/goodsSlice'
import { supplierRatingPayload } from '../../helper'
import { getPoDetails } from '../../features/POSlice'
import { getWObyId } from '../../features/THUNK/workOrderThunk'
import {hasPermission} from "../../helper/permissions";
const initialState = { service_quality: '', reliability: '', review: '' }
function WOSupplierRate(props) {
    const { show, handleShow } = props
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [state, setState] = useState(initialState)
    const { supplier_name, supplier_id, supplier_code, po_id, id, vessel_id, supplier_rating_id } = useSelector((state) => state.root.WO.orderDetails)
    const { service_quality, reliability, review } = useSelector((state) => state.root.GOODS.supplier_rating_Detail)
    const {Process} = useSelector((state) => state.root.login.loginDemo);
    const permissions = Process.find((v) => v.menuname === 'Purchase' && v.modulename === 'WO Completion')
    useEffect(() => {
        dispatch(clearRatingDetail())
        supplier_rating_id && dispatch(ratingDetailbyID(supplier_rating_id))
    }, [supplier_rating_id])
    const onCancel = () => {
        handleShow()
    }
    const submitReview = () => {
        if (state) {
            let payload = supplierRatingPayload({ ...state, supplier_name, supplier_id, supplier_code, po_id:id, mr_id: 0, vessel_id })
            //console.log(payload, "payload")
            dispatch(saveSupplierReview(payload)).unwrap().then(res=>{
                try{
                    if(res.data.result.status){
                        toast.success(res.data.result.message)
                        setState(initialState)    
                        dispatch(getWObyId(id))  
                        handleShow()
                        navigate('/work_order')
                    }else{
                        toast.success(res.data.result.message)
                    }
                }catch(err){
                    toast.error(err.message)
                }                
            }) 
        } else {
            toast.error('Must fill all field')
        }
    }

    return (

        <Modal show={show} onHide={handleShow} backdrop='static' keyboard={false} >
            <ModalBody>
                <Row>
                    <Col md={6} className='h5' >Supplier Rating</Col>
                    <Col md={6} className='d-flex justify-content-end'>
                        <Button className='btnReject me-2' onClick={() => onCancel()}>Return</Button>
                        {supplier_rating_id === 0 && hasPermission('add', permissions?.permission) && <Button className='btnSave' disabled={!(state.reliability || state.service_quality)} onClick={() => submitReview()}>save</Button>}
                    </Col>
                </Row>
                <hr />
                <Row className="">
                    <Col md={6}>
                        <FormGroup className="" controlId="">
                            <FormLabel className=''>Supplier Name</FormLabel><br />
                            <FormLabel className='fw-400 mb-0'>{supplier_name}</FormLabel>
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup className="" controlId="">
                            <FormLabel className=''>Supplier Code</FormLabel><br />
                            <FormLabel className='fw-400 mb-0'>{supplier_code}</FormLabel>
                        </FormGroup>
                    </Col>
                </Row>
                <hr />
                {supplier_rating_id && <Row>
                    <Col md={12}>
                        <FormGroup className="mb-3" controlId="">
                            <FormLabel className=''>A. Product/Service Quality</FormLabel>

                            {['radio'].map((type) => (
                                <div key={`inline-${type}`} className="d-flex justify-content-between ratingBox">
                                    <FormCheck
                                        inline
                                        label="A"
                                        name="group1"
                                        className={service_quality === 'A' && "ratingSelected"}
                                        type={type}
                                        id={`inline-${type}-1`}
                                        value='A'
                                        checked={service_quality === 'A'}
                                        disabled={!!service_quality}
                                        onChange={(e) => setState({ ...state, service_quality: e.target.value })}
                                    />
                                    <FormCheck
                                        inline
                                        label="B"
                                        name="group1"
                                        type={type}
                                        id={`inline-${type}-2`}
                                        value='B'
                                        className={service_quality === 'B' && "ratingSelected r_green_yellow"}
                                        checked={service_quality === 'B'}
                                        disabled={!!service_quality}
                                        onChange={(e) => setState({ ...state, service_quality: e.target.value })}
                                    />
                                    <FormCheck
                                        inline
                                        label="C"
                                        name="group1"
                                        type={type}
                                        id={`inline-${type}-3`}
                                        value='C'
                                        className={service_quality === 'C' && "ratingSelected r_yellow"}
                                        checked={service_quality === 'C'}
                                        disabled={!!service_quality}
                                        onChange={(e) => setState({ ...state, service_quality: e.target.value })}
                                    />
                                    <FormCheck
                                        inline
                                        label="D"
                                        name="group1"
                                        type={type}
                                        id={`inline-${type}-4`}
                                        value='D'
                                        className={service_quality === 'D' && "ratingSelected r_yellow_red"}
                                        checked={service_quality === 'D'}
                                        disabled={!!service_quality}
                                        onChange={(e) => setState({ ...state, service_quality: e.target.value })}
                                    />
                                    <FormCheck
                                        inline
                                        label="E"
                                        name="group1"
                                        type={type}
                                        id={`inline-${type}-5`}
                                        value='E'
                                        className={service_quality === 'E' && "ratingSelected red"}
                                        checked={service_quality === 'E'}
                                        disabled={!!service_quality}
                                        onChange={(e) => setState({ ...state, service_quality: e.target.value })}
                                    />
                                </div>
                            ))}

                            <Row>
                                <Col className="d-flex justify-content-between">
                                    <FormLabel className='fw-400 mb-0 text-success'>Fully Satisfied</FormLabel>
                                    <FormLabel className='fw-400 mb-0 text-warning'>Generally Satisfied</FormLabel>
                                    <FormLabel className='fw-400 mb-0 text-danger'>Poor Performance</FormLabel>
                                </Col>
                            </Row>
                        </FormGroup>
                    </Col>
                </Row>}
                {supplier_rating_id && <Row>
                    <Col md={12}>
                        <FormGroup className="mb-3" controlId="">
                            <FormLabel className=''>B. Consistency and Reliability</FormLabel>

                            {['radio'].map((type) => (
                                <div key={`inline-${type}`} className="d-flex justify-content-between ratingBox">
                                    <FormCheck
                                        inline
                                        label="A"
                                        name="group11"
                                        type={type}
                                        id={`inline-${type}-1`}
                                        value='A'
                                        className={reliability === 'A' && "ratingSelected "}
                                        disabled={!!reliability}
                                        checked={reliability === 'A'}
                                        onChange={(e) => setState({ ...state, reliability: e.target.value })}
                                    />
                                    <FormCheck
                                        inline
                                        label="B"
                                        name="group11"
                                        type={type}
                                        id={`inline-${type}-2`}
                                        value='B'
                                        className={reliability === 'B' && "ratingSelected r_green_yellow"}
                                        disabled={!!reliability}
                                        checked={reliability === 'B'}
                                        onChange={(e) => setState({ ...state, reliability: e.target.value })}
                                    />
                                    <FormCheck
                                        inline
                                        label="C"
                                        name="group11"
                                        type={type}
                                        id={`inline-${type}-3`}
                                        value='C'
                                        className={reliability === 'C' && "ratingSelected r_yellow"}
                                        disabled={!!reliability}
                                        checked={reliability === 'C'}
                                        onChange={(e) => setState({ ...state, reliability: e.target.value })}
                                    />
                                    <FormCheck
                                        inline
                                        label="D"
                                        name="group11"
                                        type={type}
                                        id={`inline-${type}-4`}
                                        value='D'
                                        className={reliability === 'D' && "ratingSelected r_yellow_red"}
                                        disabled={!!reliability}
                                        checked={reliability === 'D'}
                                        onChange={(e) => setState({ ...state, reliability: e.target.value })}
                                    />
                                    <FormCheck
                                        inline
                                        label="E"
                                        name="group11"
                                        type={type}
                                        id={`inline-${type}-5`}
                                        value='E'
                                        className={reliability === 'E' && "ratingSelected red"}
                                        disabled={!!reliability}
                                        checked={reliability === 'E'}
                                        onChange={(e) => setState({ ...state, reliability: e.target.value })}
                                    />
                                </div>
                            ))}

                            <Row>
                                <Col className="d-flex justify-content-between">
                                    <FormLabel className='fw-400 mb-0 text-success'>Fully Satisfied</FormLabel>
                                    <FormLabel className='fw-400 mb-0 text-warning'>Generally Satisfied</FormLabel>
                                    <FormLabel className='fw-400 mb-0 text-danger'>Poor Performance</FormLabel>
                                </Col>
                            </Row>
                        </FormGroup>
                    </Col>
                </Row>}

                {supplier_rating_id === 0 && <Row>
                    <Col md={12}>
                        <FormGroup className="mb-3" controlId="">
                            <FormLabel className=''>A. Product/Service Quality</FormLabel>

                            {['radio'].map((type) => (
                                <div key={`inline-${type}`} className="d-flex justify-content-between ratingBox">
                                    <FormCheck
                                        inline
                                        label="A"
                                        name="group1"
                                        className={state.service_quality === 'A' && "ratingSelected"}
                                        type={type}
                                        id={`inline-${type}-1`}
                                        value='A'
                                        onChange={(e) => setState({ ...state, service_quality: e.target.value })}
                                    />
                                    <FormCheck
                                        inline
                                        label="B"
                                        name="group1"
                                        type={type}
                                        id={`inline-${type}-2`}
                                        className={state.service_quality === 'B' && "ratingSelected r_green_yellow"}
                                        value='B'
                                        onChange={(e) => setState({ ...state, service_quality: e.target.value })}
                                    />
                                    <FormCheck
                                        inline
                                        label="C"
                                        name="group1"
                                        type={type}
                                        id={`inline-${type}-3`}
                                        className={state.service_quality === 'C' && "ratingSelected r_yellow"}
                                        value='C'
                                        onChange={(e) => setState({ ...state, service_quality: e.target.value })}
                                    />
                                    <FormCheck
                                        inline
                                        label="D"
                                        name="group1"
                                        type={type}
                                        id={`inline-${type}-4`}
                                        value='D'
                                        className={state.service_quality === 'D' && "ratingSelected r_yellow_red"}
                                        onChange={(e) => setState({ ...state, service_quality: e.target.value })}
                                    />
                                    <FormCheck
                                        inline
                                        label="E"
                                        name="group1"
                                        type={type}
                                        id={`inline-${type}-5`}
                                        value='E'
                                        className={state.service_quality === 'E' && "ratingSelected r_red"}
                                        onChange={(e) => setState({ ...state, service_quality: e.target.value })}
                                    />
                                </div>
                            ))}

                            <Row>
                                <Col className="d-flex justify-content-between">
                                    <FormLabel className='fw-400 mb-0 text-success'>Fully Satisfied</FormLabel>
                                    <FormLabel className='fw-400 mb-0 text-warning'>Generally Satisfied</FormLabel>
                                    <FormLabel className='fw-400 mb-0 text-danger'>Poor Performance</FormLabel>
                                </Col>
                            </Row>
                        </FormGroup>
                    </Col>
                </Row>}
                {supplier_rating_id === 0 && <Row>
                    <Col md={12}>
                        <FormGroup className="mb-3" controlId="">
                            <FormLabel className=''>B. Consistency and Reliability</FormLabel>

                            {['radio'].map((type) => (
                                <div key={`inline-${type}`} className="d-flex justify-content-between ratingBox">
                                    <FormCheck
                                        inline
                                        label="A"
                                        name="group11"
                                        type={type}
                                        id={`inline-${type}-1`}
                                        value='A'
                                        className={state.reliability === 'A' && "ratingSelected "}
                                        onChange={(e) => setState({ ...state, reliability: e.target.value })}
                                    />
                                    <FormCheck
                                        inline
                                        label="B"
                                        name="group11"
                                        type={type}
                                        id={`inline-${type}-2`}
                                        value='B'
                                        className={state.reliability === 'B' && "ratingSelected r_green_yellow"}
                                        onChange={(e) => setState({ ...state, reliability: e.target.value })}
                                    />
                                    <FormCheck
                                        inline
                                        label="C"
                                        name="group11"
                                        type={type}
                                        id={`inline-${type}-3`}
                                        value='C'
                                        className={state.reliability === 'C' && "ratingSelected r_yellow"}
                                        onChange={(e) => setState({ ...state, reliability: e.target.value })}
                                    />
                                    <FormCheck
                                        inline
                                        label="D"
                                        name="group11"
                                        type={type}
                                        id={`inline-${type}-4`}
                                        value='D'
                                        className={state.reliability === 'D' && "ratingSelected r_yellow_red"}
                                        onChange={(e) => setState({ ...state, reliability: e.target.value })}
                                    />
                                    <FormCheck
                                        inline
                                        label="E"
                                        name="group11"
                                        type={type}
                                        id={`inline-${type}-5`}
                                        value='E'
                                        className={state.reliability === 'E' && "ratingSelected r_red"}
                                        onChange={(e) => setState({ ...state, reliability: e.target.value })}
                                    />
                                </div>
                            ))}

                            <Row>
                                <Col className="d-flex justify-content-between">
                                    <FormLabel className='fw-400 mb-0 text-success'>Fully Satisfied</FormLabel>
                                    <FormLabel className='fw-400 mb-0 text-warning'>Generally Satisfied</FormLabel>
                                    <FormLabel className='fw-400 mb-0 text-danger'>Poor Performance</FormLabel>
                                </Col>
                            </Row>
                        </FormGroup>
                    </Col>
                </Row>}


                <Row>
                    <Col md={12}>
                        <FormGroup className="" controlId="">
                            <FormLabel className=''>Write a review</FormLabel>
                            <FormControl type="text" as='textarea' defaultValue={review ? review : ''} disabled={(service_quality || reliability)} placeholder="" style={{ height: '100px' }} onChange={(e) => setState({ ...state, review: e.target.value })} />
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    )
}

export default WOSupplierRate