import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {AddEditResponse, ValidateResponse} from "../Services/Support";
import {
    getChangeRequestTypeById,
    getChangeRequestTypeList,
    getDocCategoryDataById,
    getDocCategoryMasterList,
    saveChangeRequestType,
    saveDocCategoryMaster
} from "./THUNK/EdocThunk";
import {
    EDOC_DASHBOARD_HISTORY_ACTION, EDOC_TYPE_LIST_ACTION,
    GET_COMMENTS_ACTION, GET_EDOC_DASHBOARD_ACTION,
    GET_INITIAL_REQUEST,
    GET_REVIEW_DOC_ACTION,
    GET_REVIEW_REQUEST_ACTION,
    GET_REVIEW_REQUEST_LIST_ACTION,
    SAVE_COMMENTS_ACTION,
    SAVE_E_DOC_TREE_ACTION,
    SAVE_INITIAL_REQUEST,
    SAVE_REVIEW_REQUEST_ACTION
} from "../constant/Actions";
import {API_SERVICE} from "../Services/CommonApi";
import {
    GET_COMMENTS,
    GET_INITIATE_REQUEST, GET_REVIEW_DOC_NO, GET_REVIEW_REQUEST_ID, GET_REVIEW_REQUEST,
    SAVE_COMMENTS,
    SAVE_INITIATE_REQUEST,
    SAVE_INVENTORY_TREE, SAVE_REVIEW_REQUEST, GET_EDOC_DASHBOARD, EDOC_DASHBOARD_HISTORY, EDOC_TYPE_LIST
} from "../constant/CommonApiUrl";
import {ConvertJson} from "../helper";
import {toast} from "react-toastify";

const initialState = {
    editRecord: {},
    categoryList: [],
    changeRequestList: [],
    initiateRequestData: [],
    reviewRequestList: [],
    dashboardHistory: [],
    dashboardData: {},
    documentList: [],
    comments: [],
    viewData: {},
    docDetails: {},
    reviewRequestData: {}
}

const getApiWithVesselId = (apiEndpoint, arg, thunkAPI, method) => {
    const vessel_id = thunkAPI.getState().root.common.vessel_detailByID.id;
    return API_SERVICE[method](apiEndpoint, { ...arg, vessel_id });
};

export const saveEDocTree = createAsyncThunk(SAVE_E_DOC_TREE_ACTION,
    async(arg,thunkAPI)=>{
        try{
            return await getApiWithVesselId(SAVE_INVENTORY_TREE, arg, thunkAPI, 'post');
        }catch(err){
            thunkAPI.rejectWithValue(err.message)
        }
    })
export const saveInitialRequest = createAsyncThunk(SAVE_INITIAL_REQUEST,
    async(arg,thunkAPI)=>{
        try{
            return await getApiWithVesselId(SAVE_INITIATE_REQUEST, arg, thunkAPI, 'post');
        }catch(err){
            thunkAPI.rejectWithValue(err.message)
        }
    })
export const saveReviewRequest = createAsyncThunk(SAVE_REVIEW_REQUEST_ACTION,
    async(arg,thunkAPI)=>{
        try{
            return await getApiWithVesselId(SAVE_REVIEW_REQUEST, arg, thunkAPI, 'post');
        }catch(err){
            thunkAPI.rejectWithValue(err.message)
        }
    })
export const getInitiateRequestById = createAsyncThunk(GET_INITIAL_REQUEST,
    async(arg,thunkAPI)=>{
        try{
            const payload = arg?.id ? { id: arg.id} : {...arg }
            return await getApiWithVesselId(GET_INITIATE_REQUEST, payload, thunkAPI, 'get');
        }catch(err){
            thunkAPI.rejectWithValue(err.message)
        }
    })
export const getCommentsById = createAsyncThunk(GET_COMMENTS_ACTION,
    async(arg,thunkAPI)=>{
        try{
            const payload = {screen_refno: arg.id}
            return await getApiWithVesselId(GET_COMMENTS, payload, thunkAPI, 'get');
        }catch(err){
            thunkAPI.rejectWithValue(err.message)
        }
    })
export const saveComments = createAsyncThunk(SAVE_COMMENTS_ACTION,
    async(arg,thunkAPI)=>{
        try{
            return await getApiWithVesselId(SAVE_COMMENTS, arg, thunkAPI, 'post');
        }catch(err){
            thunkAPI.rejectWithValue(err.message)
        }
    })
export const getReviewDocument = createAsyncThunk(GET_REVIEW_DOC_ACTION,
    async(arg,thunkAPI)=>{
        try{
            return await getApiWithVesselId(GET_REVIEW_DOC_NO, {}, thunkAPI, 'get');
        }catch(err){
            thunkAPI.rejectWithValue(err.message)
        }
    })
export const getReviewRequest = createAsyncThunk(GET_REVIEW_REQUEST_ACTION,
    async(arg,thunkAPI)=>{
        try{
            return await getApiWithVesselId(GET_REVIEW_REQUEST_ID, { id: arg.id}, thunkAPI, 'get');
        }catch(err){
            thunkAPI.rejectWithValue(err.message)
        }
    })
export const getReviewRequestList = createAsyncThunk(GET_REVIEW_REQUEST_LIST_ACTION,
    async(arg,thunkAPI)=>{
        try{
            const payload = arg?.id ? { id: arg.id} : arg
            return await getApiWithVesselId(GET_REVIEW_REQUEST, payload, thunkAPI, 'get');
        }catch(err){
            thunkAPI.rejectWithValue(err.message)
        }
    })
export const getEdocDashboard = createAsyncThunk(GET_EDOC_DASHBOARD_ACTION,
    async(arg,thunkAPI)=>{
        try{
            return await getApiWithVesselId(GET_EDOC_DASHBOARD, {}, thunkAPI, 'get');
        }catch(err){
            thunkAPI.rejectWithValue(err.message)
        }
    })
export const getDashboardHistory = createAsyncThunk(EDOC_DASHBOARD_HISTORY_ACTION,
    async(arg,thunkAPI)=>{
        try{
            return await getApiWithVesselId(EDOC_DASHBOARD_HISTORY, arg, thunkAPI, 'get');
        }catch(err){
            thunkAPI.rejectWithValue(err.message)
        }
    })
export const getEDocDocumentList = createAsyncThunk(EDOC_TYPE_LIST_ACTION,
    async(arg,thunkAPI)=>{
        try{
            return await getApiWithVesselId(EDOC_TYPE_LIST, arg, thunkAPI, 'get');
        }catch(err){
            thunkAPI.rejectWithValue(err.message)
        }
    })
const EDocMasterSlice = createSlice({
    name: 'EDOC',
    initialState,
    reducers: {
        clearEDocList: (state, action) => {
            state.categoryList = []
            state.initiateRequestData = []
            state.reviewRequestList = []
        },
        clearEDocEdit: (state, action) => {
            state.editRecord = {}
        },
        setViewDocDetails: (state, {payload}) => {
            state.viewData = payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getDocCategoryMasterList.fulfilled, (state, action) => {
                state.categoryList = ValidateResponse(action.payload)
            })
            .addCase(getDocCategoryDataById.fulfilled, (state, action) => {
                let response = ValidateResponse(action.payload)
                state.editRecord = response[0]
            })
            .addCase(saveDocCategoryMaster.fulfilled, (state, action) => {
                AddEditResponse(action.payload)
            })
            .addCase(getChangeRequestTypeList.fulfilled, (state, action) => {
                state.changeRequestList = ValidateResponse(action.payload)
            }).addCase(getChangeRequestTypeById.fulfilled, (state, action) => {
            let response = ValidateResponse(action.payload)
            state.editRecord = response[0]
        }).addCase(getReviewDocument.fulfilled, (state, action) => {
            let response = ValidateResponse(action.payload)
            state.docDetails = response[0]
        }).addCase(getReviewRequest.fulfilled, (state, action) => {
            let response = ValidateResponse(action.payload);
            let result = response.map((doc) => ({
                ...doc,
                repository: doc.repository ? ConvertJson(doc.repository) : []
            }))
            state.reviewRequestData = result[0]
        }).addCase(getReviewRequestList.fulfilled, (state, action) => {
            const data = ValidateResponse(action.payload)
            let result = data.map((doc) => ({
                ...doc,
                repository: doc.repository ? ConvertJson(doc.repository) : [],
                designationid: doc.designationid ? ConvertJson(doc.designationid) : [],
            }))
            state.reviewRequestList = result
        }).addCase(getEdocDashboard.fulfilled, (state, action) => {
            let response = ValidateResponse(action.payload)
            state.dashboardData = response[0]
        }).addCase(getDashboardHistory.fulfilled, (state, action) => {
            state.dashboardHistory = ValidateResponse(action.payload)
        }).addCase(getEDocDocumentList.fulfilled, (state, action) => {
            state.documentList = ValidateResponse(action.payload)
        }).addCase(saveChangeRequestType.fulfilled, (state, action) => {
                AddEditResponse(action.payload)
            })
            .addCase(saveEDocTree.fulfilled, (state, action) => {
                AddEditResponse(action.payload)
            })
            .addCase(saveInitialRequest.fulfilled, (state, action) => {
                AddEditResponse(action.payload)
            }) .addCase(saveReviewRequest.fulfilled, (state, action) => {
                AddEditResponse(action.payload)
            })
            .addCase(getCommentsById.fulfilled, (state, {payload}) => {
                if (payload.data.result.status) {
                    state.comments = payload.data.result.data
                } else {
                    toast.error(payload.data.result.message)
                }
            }).addCase(saveComments.fulfilled, (state, action) => {
            AddEditResponse(action.payload)
        }).addCase(getInitiateRequestById.fulfilled, (state,  {payload}) => {
            if(payload.data.result.status){
                const data = payload.data.result.data
                state.initiateRequestData = data.map((doc) => ({
                    ...doc,
                    repository: doc.repository ? ConvertJson(doc.repository) : [],
                    designationid: doc.designationid ? ConvertJson(doc.designationid) : [],
                }))
            }else{
                toast.error(payload.data.result.message)
            }
        })
    }
})
export const { clearEDocList, clearEDocEdit, setViewDocDetails } = EDocMasterSlice.actions
export default EDocMasterSlice.reducer