import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { ThemeProvider } from "styled-components";
import { setTheme } from '../features/themeSlice';
import { GlobalStyles } from './GlobalStyles';
const Container = styled.div`
  margin: 5px auto 5px auto;
`;

function Test() {
  const dispatch=useDispatch()
  const globalTheme=useSelector((state)=>state.root.theme.selectedTheme)  
  const themes = useSelector((state)=>state.root.theme.allThemes)
  useEffect(() => { 
    //for rerender after theme select  
   }, [globalTheme]);  
  return (
    <>
    {
      <ThemeProvider theme={ globalTheme}>
        <GlobalStyles/>
        <Container>
          <h1>Theme Builder</h1>
          <p>
            This is a theming system with a Theme Switcher and Theme Builder.
            Do you want to see the source code? Click here.
          </p>
           <button onClick={()=>dispatch(setTheme(themes.dark))}>dark</button>   
           <button onClick={()=>dispatch(setTheme(themes.light))}>default</button>   
           <button onClick={()=>dispatch(setTheme(themes.green))}>green</button>   
           <button onClick={()=>dispatch(setTheme(themes.seaWave))}>seaWave</button>
          </Container>        
      </ThemeProvider>
    }
    </>
  );
}

export default Test;